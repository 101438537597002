import { defaultAlerterFilter, Id } from 'app/core/persistence';
import { ImportedAlerter } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class AlerterImporterService {
    public import(projectId: Id, items: ImportedAlerter[]) {
        const alerters = items.map((item) => ({
            ...mapToItemEntity(projectId, item, item.piaId),
            properties: {
                alerter: {
                    filter: defaultAlerterFilter,
                },
            },
        }));

        const accessoryEntities = getImportedAccessories(projectId, items);

        return [...alerters, ...accessoryEntities];
    }
}
