import { injectable } from 'inversify';
import { saveAs } from 'file-saver';
import type { IEntity, Id } from '../../userDataPersistence';
import { ProjectRepository } from '../../userDataPersistence';
import type { IExportFileV1 } from './fileFormats/IExportFileV1';
import { FloorPlanService } from '../FloorPlan.service';
import { containsFloorPlanOrMapLocation } from './containsFloorPlanOrMapLocation';

@injectable()
export class ProjectExportService {
    constructor(
        private projectRepository: ProjectRepository,
        private floorPlanService: FloorPlanService,
    ) {}

    public async exportProject(projectId: Id): Promise<void> {
        return this.export(projectId, false);
    }

    public async exportProjectWithoutFloorPlans(projectId: Id): Promise<void> {
        return this.export(projectId, true);
    }

    private async export(projectId: Id, excludeFloorPlanMaps: boolean): Promise<void> {
        const project = await this.projectRepository.get(projectId);
        const fileContents = await this.getFileContents(projectId, excludeFloorPlanMaps);

        const fileBlob = new Blob([fileContents], {
            type: 'text/plain;charset=utf-8',
        });

        saveAs(fileBlob, `${project.name}.asdpx`);
    }

    private async getExportableEntities(descendants: IEntity[], projectId: Id): Promise<IEntity[]> {
        return Promise.all(
            descendants.map((entity): Promise<IEntity> => {
                if (FloorPlanService.isFloorPlanMapType(entity)) {
                    return this.floorPlanService.getExportableFloorPlan(entity, projectId);
                }
                return Promise.resolve(entity);
            }),
        );
    }

    private async getFileContents(
        projectId: Id,
        shouldExcludeFloorPlanMaps: boolean,
    ): Promise<string> {
        const data = await this.projectRepository.getProjectAndAllDescendants(projectId);
        // do not export genetecProjectId since we only want one Genetec project per Site Designer project
        data.project.genetecProjectId = undefined;

        if (shouldExcludeFloorPlanMaps) {
            data.descendants = await FloorPlanService.filterOutFloorPlanMaps(data.descendants);
            const hasFloorPlanOrMapLocation = containsFloorPlanOrMapLocation(data.descendants);
            const updatedProject = { ...data.project, hasFloorPlanOrMapLocation };
            data.project = updatedProject;
        }

        const fileContents: IExportFileV1 = {
            fileVersion: 1,
            fileType: 'exportedProject',
            application: 'SiteDesigner2',
            project: data.project,
            children: await this.getExportableEntities(data.descendants, projectId),
        };

        return JSON.stringify(fileContents);
    }
}
