import { injectable } from 'inversify';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    deviceTypeCheckers,
    IBodyWornCameraItemEntity,
    ICameraExtensionItemEntity,
    Id,
    IDockingStationItemEntity,
    IItemEntity,
    isDeviceSpecified,
    ISystemControllerItemEntity,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import { PiaItemService, IPiaDevice, IPiaBodyWornCamera } from 'app/core/pia';
import {
    ProfileOverrideService,
    ProfileSupportService,
    BandwidthCalculatorService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import type {
    IExportedBodyWornCamera,
    IExportablePersistedEntity,
    IExportedDockingStation,
    IExportedSystemController,
    IExportedCameraExtension,
} from '../../../models/items';

@injectable()
export class BodyWornExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaDevice>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedBodyWornCameras = async (
        items: IExportablePersistedEntity[],
        projectId: Id,
    ): Promise<IExportedBodyWornCamera[]> => {
        const exportedBodyWornCameras = items.reduce((bwCameras, item) => {
            if (!this.isBodyWornCameraEntity(item)) return bwCameras;

            const bwCamera = this.mapItemToExportedBodyWornCamera(item, projectId);
            return [...bwCameras, bwCamera];
        }, new Array<Promise<IExportedBodyWornCamera>>());

        return Promise.all(exportedBodyWornCameras);
    };

    public mapItemsToExportedDockingStations = async (
        items: IExportablePersistedEntity[],
        projectId: Id,
    ): Promise<IExportedDockingStation[]> => {
        const exportedDockingStations = items.reduce((dockingStations, item) => {
            if (!this.isDockingStationEntity(item)) return dockingStations;

            const dockingStation = this.mapItemToExportedDockingStation(item, projectId);
            return [...dockingStations, dockingStation];
        }, new Array<Promise<IExportedDockingStation>>());

        return Promise.all(exportedDockingStations);
    };

    public mapItemsToExportedSystemControllers = async (
        items: IExportablePersistedEntity[],
        projectId: Id,
    ): Promise<IExportedSystemController[]> => {
        const exportedSystemControllers = items.reduce((systemControllers, item) => {
            if (!this.isSystemControllerEntity(item)) return systemControllers;

            const systemController = this.mapItemToExportedSystemController(item, projectId);
            return [...systemControllers, systemController];
        }, new Array<Promise<IExportedSystemController>>());

        return Promise.all(exportedSystemControllers);
    };

    public mapItemsToExportedCameraExtensions = async (
        items: IExportablePersistedEntity[],
        projectId: Id,
    ): Promise<IExportedSystemController[]> => {
        const exportedCameraExtensions = items.reduce((cameraExtensions, item) => {
            if (!this.isCameraExtensionEntity(item)) return cameraExtensions;

            const cameraExtension = this.mapItemToExportedCameraExtension(item, projectId);
            return [...cameraExtensions, cameraExtension];
        }, new Array<Promise<IExportedSystemController>>());

        return Promise.all(exportedCameraExtensions);
    };

    private mapItemToExportedBodyWornCamera = async (
        bodyWornCamera: IExportablePersistedEntity &
            IBodyWornCameraItemEntity & { productId: number },
        projectId: string,
    ): Promise<IExportedBodyWornCamera> => {
        const profile = bodyWornCamera.properties.bodyWornCamera.profile;
        const baseItem = await this.mapItemToExportedItemBase(bodyWornCamera, projectId);
        const piaDevice = this.getPiaDevice(bodyWornCamera.productId) as IPiaBodyWornCamera;
        return {
            ...baseItem,
            piaId: piaDevice.id,
            modelName: piaDevice.name,
            productVariantName: piaDevice.name,
            modelShortName: piaDevice.name,
            profile: {
                sceneId: profile.sceneId,
                resolution: profile.resolution,
                retentionTime: profile.retentionTimeInDays,
                scheduleId: profile.scheduleId,
                activeRecordingInPercent: profile.activeRecordingInPercent,
            },
        } satisfies IExportedBodyWornCamera;
    };

    private mapItemToExportedDockingStation = async (
        dockingStations: IExportablePersistedEntity &
            IDockingStationItemEntity & { productId: number },
        projectId: string,
    ): Promise<IExportedDockingStation> => {
        const baseItem = await this.mapItemToExportedItemBase(dockingStations, projectId);
        const piaDevice = this.getPiaDevice(dockingStations.productId) as IPiaDevice;
        return {
            ...baseItem,
            piaId: piaDevice.id,
            modelName: piaDevice.name,
            productVariantName: piaDevice.name,
            modelShortName: piaDevice.name,
        } satisfies IExportedDockingStation;
    };

    private mapItemToExportedSystemController = async (
        systemControllers: IExportablePersistedEntity &
            ISystemControllerItemEntity & { productId: number },
        projectId: string,
    ): Promise<IExportedSystemController> => {
        const baseItem = await this.mapItemToExportedItemBase(systemControllers, projectId);
        const piaDevice = this.getPiaDevice(systemControllers.productId) as IPiaDevice;
        return {
            ...baseItem,
            piaId: piaDevice.id,
            modelName: piaDevice.name,
            productVariantName: piaDevice.name,
            modelShortName: piaDevice.name,
        } satisfies IExportedSystemController;
    };

    private mapItemToExportedCameraExtension = async (
        systemControllers: IExportablePersistedEntity &
            ICameraExtensionItemEntity & { productId: number },
        projectId: string,
    ): Promise<IExportedCameraExtension> => {
        const baseItem = await this.mapItemToExportedItemBase(systemControllers, projectId);
        const piaDevice = this.getPiaDevice(systemControllers.productId) as IPiaDevice;
        return {
            ...baseItem,
            piaId: piaDevice.id,
            modelName: piaDevice.name,
            productVariantName: piaDevice.name,
            modelShortName: piaDevice.name,
        } satisfies IExportedCameraExtension;
    };

    private isBodyWornCameraEntity(
        item: IItemEntity,
    ): item is IBodyWornCameraItemEntity & { productId: number } {
        return deviceTypeCheckers.isBodyWornCamera(item) && isDeviceSpecified(item);
    }

    private isDockingStationEntity(
        item: IItemEntity,
    ): item is IDockingStationItemEntity & { productId: number } {
        return deviceTypeCheckers.isDockingStation(item) && isDeviceSpecified(item);
    }

    private isSystemControllerEntity(
        item: IItemEntity,
    ): item is ISystemControllerItemEntity & { productId: number } {
        return deviceTypeCheckers.isSystemController(item) && isDeviceSpecified(item);
    }

    private isCameraExtensionEntity(
        item: IItemEntity,
    ): item is ICameraExtensionItemEntity & { productId: number } {
        return deviceTypeCheckers.isCameraExtension(item) && isDeviceSpecified(item);
    }
}
