import type { Id, IItemEntity } from 'app/core/persistence';
import { getDevicePaletteColor } from './getDevicePaletteColor';
import { defaultColors } from 'app/core/common';
import type { Colors } from 'app/styles';
import type { PiaId } from 'app/core/pia';
import { getImportedItemNetworkSettings } from './getImportedItemNetworkSettings';
import type { ImportedItemBase } from '../../../../models';

export const mapToItemEntity = (
    projectId: Id,
    item: ImportedItemBase,
    productId: PiaId | null = null,
    defaultColor: Colors = defaultColors.DEFAULT_DEVICE_COLOR,
): Omit<IItemEntity, 'properties'> => {
    const networkSettings = getImportedItemNetworkSettings(item.networkSettings);
    return {
        name: item.name,
        type: 'item',
        description: item.description,
        notes: item.notes,
        quantity: 1,
        color: getDevicePaletteColor(item.color) ?? defaultColor,
        productId,
        networkSettings,
        path: [projectId, item.id],
        linkedId: item.linkedId,
        archived: false,
    };
};
