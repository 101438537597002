import { createSelector } from 'reselect';
import type { IProjectActionService } from '../models/IProjectActionService';
import { LocalProjectsActionService } from '../localProjects';
import { UserProjectsActionService } from '../userProjects';
import { SharedProjectsActionService } from '../sharedProjects';
import { ServiceLocator } from 'app/ioc';
import { getCurrentView } from './getCurrentView';

export const getProjectActionService = createSelector(
    [getCurrentView],
    (currentView): IProjectActionService | undefined => {
        if (currentView === 'localprojects') {
            return ServiceLocator.get(LocalProjectsActionService);
        } else if (currentView === 'userprojects') {
            return ServiceLocator.get(UserProjectsActionService);
        } else if (currentView === 'sharedprojects') {
            return ServiceLocator.get(SharedProjectsActionService);
        }
        return undefined;
    },
);
