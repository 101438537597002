import * as leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import { clamp, merge } from 'lodash-es';
import {
    convert,
    distanceFromOrigo,
    offset,
    rotate,
    trigonometry,
    type Point,
} from 'axis-webtools-util';
import {
    calculateCameraFovHandlePoint,
    calculateVisibleArea,
    getResolutionGuidePolygon,
    calculateConeOutline,
    getShadowPolygons,
    rotatePolygon,
    getAnalyticGuidePolygon,
    getAnalyticZoneLimits,
    getAnalyticAPDMaxValues,
} from '../../../utils';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { LeafletMap } from '../LeafletMap';
import type {
    ILatLng,
    PanoramaModes,
    IItemEntity,
    IInstallationPointModel,
    IInstallationPointSensorModel,
    IDeviceAnalyticRange,
    PolyLine,
    IInstallationPoint,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    DistanceUnit,
    distanceUnitShortText,
    isCustomCamera,
} from 'app/core/persistence';
import {
    calculateTiltAngleFromRadians,
    calculateVerticalFov,
    estimateVerticalFOV,
    diffMultiplePolygons,
    diffPolygons,
    intersectPolygons,
    calculate,
    getPixelDensityForSensor,
    getCategoryName,
    isVirtualProductSensor,
} from 'app/modules/common';
import type { IPiaCamera } from 'app/core/pia';
import { t } from 'app/translate';
import type { IBlockerShadowParams } from './BaseCone';
import { BaseCone } from './BaseCone';
import {
    doriPixelOpacity,
    doriPixelLimitsFeet,
    doriPixelLimitsMeter,
    convertDensityToMeters,
    convertDensityToDisplayUnit,
} from 'app/core/common';
import { IconStyle } from 'app/components';
import type { IconTypes } from './utils/iconConstants';
import type { IAnalyticsPopupContent } from '../../../models/IAnalyticsPopupContent';
import {
    HUMAN_ICON_WIDTH,
    VEHICLE_ICON_WIDTH,
    BLUR_OPACITY,
    FOCUS_OPACITY,
    ICON_OFFSET,
    HUMAN_ICON_HEIGHT,
    VEHICLE_ICON_HEIGHT,
} from './utils/iconConstants';

import { getIconMaxPosPixels, getOffsetIconPosLatLng, showRangeIcon } from './utils';

const doriPixelPolygonStyle = {
    stroke: false,
    fill: true,
    bubblingMouseEvents: false,
};

const analyticPixelPolygonStyle = {
    opacity: FOCUS_OPACITY,
    stroke: true,
    weight: 1,
    fill: false,
    bubblingMouseEvents: false,
    dashArray: [4],
};

const doriBlurOpacity = {
    fillOpacity: 0.2,
};

/*
 * Convert the angle to the cone's coordinate system. The cone assumes 0 is to the
 * right, whereas the map assumes 0 is down, so we need to add 90 degrees to the angle.
 */
const convertMapAngleToConeAngle = (angle: number): number => angle + 90;

/* Check if the sensor belongs to a device with multiple sensor types */
const hasMultipleSensorTypes = (sensor: IInstallationPointSensorModel) =>
    isVirtualProductSensor(sensor) || (sensor.parentPiaDevice?.categories?.length ?? 1) > 1;

const getSensorTypeText = (sensor: IInstallationPointSensorModel) => {
    const category =
        sensor.parentPiaDevice?.properties.virtualProductCategory?.[0] ??
        sensor.parentPiaDevice?.category;

    return getCategoryName(category);
};

export class CameraCone extends BaseCone {
    public horizontalAngle: number;
    protected targetDistance: number;
    private targetHeight: number;
    private cameraHeight: number;
    private requiredPixelDensity!: number;
    private horizontalFov: number;
    private verticalFov!: number;
    private panoramaMode!: PanoramaModes;
    private maxHorizontalFov!: number;
    private minHorizontalFov!: number;
    private corridorFormat: boolean;
    private maxVerticalFov!: number;
    private minVerticalFov!: number;
    private maxVideoResolutionHorizontal!: number;
    private identificationGuidePolygon: leaflet.Polygon;
    private recognitionGuidePolygon: leaflet.Polygon;
    private observationGuidePolygon: leaflet.Polygon;
    private detectionGuidePolygon: leaflet.Polygon;
    private analyticGuidePolygonPerson: leaflet.Polygon;
    private analyticGuidePolygonVehicle: leaflet.Polygon;
    private isTiltable: boolean;
    private fovHandleLatLng: ILatLng | undefined;
    private tiltOffset;

    constructor(
        public sensor: IInstallationPointSensorModel,
        map: LeafletMap,
        color: Colors,
        private hasExternalIlluminator: boolean,
        protected distanceUnit: DistanceUnit,
        protected doriPixelsOn: boolean,
        protected installationPoint: IInstallationPointModel,
        private analyticRange?: IDeviceAnalyticRange,
    ) {
        super(map, leaflet.latLng(sensor.location.lat, sensor.location.lng), color);
        const piaCamera = sensor.parentPiaDevice as IPiaCamera;
        this.targetHeight = sensor.target.height;
        this.targetDistance = sensor.target.distance;
        this.corridorFormat = sensor.settings.corridorFormat;
        this.horizontalFov = sensor.settings.horizontalFov;
        this.horizontalAngle = sensor.target.horizontalAngle;
        this.cameraHeight = sensor.height;
        this.tiltOffset = sensor.settings.tiltOffset ?? 0;
        this.isTiltable = true;

        this.identificationGuidePolygon = leaflet.polygon([], {
            ...doriPixelPolygonStyle,
            color: ColorsEnum[this.color],
        });
        this.recognitionGuidePolygon = leaflet.polygon([], {
            ...doriPixelPolygonStyle,
            color: ColorsEnum[this.color],
        });
        this.observationGuidePolygon = leaflet.polygon([], {
            ...doriPixelPolygonStyle,
            color: ColorsEnum[this.color],
        });
        this.detectionGuidePolygon = leaflet.polygon([], {
            ...doriPixelPolygonStyle,
            color: ColorsEnum[this.color],
        });

        this.analyticGuidePolygonPerson = leaflet.polygon([], {
            ...analyticPixelPolygonStyle,
            color: ColorsEnum[this.color],
        });
        this.analyticGuidePolygonVehicle = leaflet.polygon([], {
            ...analyticPixelPolygonStyle,
            color: ColorsEnum[this.color],
        });

        this.identificationGuidePolygon
            .on('click', () => this.onConeClick(this.sensor?.sensorId))
            .on('mousedown', this.stopPropagation);
        this.recognitionGuidePolygon
            .on('click', () => this.onConeClick(this.sensor?.sensorId))
            .on('mousedown', this.stopPropagation);
        this.observationGuidePolygon
            .on('click', () => this.onConeClick(this.sensor?.sensorId))
            .on('mousedown', this.stopPropagation);
        this.detectionGuidePolygon
            .on('click', () => this.onConeClick(this.sensor?.sensorId))
            .on('mousedown', this.stopPropagation);
        this.analyticGuidePolygonPerson
            .on('click', () => this.onConeClick(this.sensor?.sensorId))
            .on('mousedown', this.stopPropagation);
        this.analyticGuidePolygonVehicle
            .on('click', () => this.onConeClick(this.sensor?.sensorId))
            .on('mousedown', this.stopPropagation);
        this.setCameraProperties(piaCamera, sensor.parentDevice);
    }

    public getHasTargetLine = () => {
        return false;
    };

    public getTargetPopupContent = (_targetDistance: number) => {
        const distanceAbbreviation = distanceUnitShortText(this.distanceUnit);

        const isGenericCamera =
            this.sensor.parentPiaDevice === null && !isCustomCamera(this.sensor.parentDevice);

        const pxPerMeter = getPixelDensityForSensor(
            this.sensor,
            this.sensor.sensorId,
            this.installationPoint,
        );

        const pixelDensityToDisplay = convertDensityToDisplayUnit(pxPerMeter, this.distanceUnit);

        const distance =
            this.distanceUnit === DistanceUnit.Feet
                ? convert.metersToFeet(this.sensor.target.distance)
                : this.sensor.target.distance;

        const pixelPerMeterDisplay = isGenericCamera
            ? ''
            : `<tr>
                    <td style="text-align: right">${pixelDensityToDisplay}</td>
                    <td style="color: ${ColorsEnum.grey4};">${
                        t.abbreviationsGROUP.pixel
                    }/${distanceAbbreviation}</td>
                <tr>`;

        const sensorTypeText = this.getSensorTypePopupContent();

        return `
            <table>
                ${sensorTypeText}
                <tr>
                    <td rowSpan="2">
                        <i
                            class="${IconStyle}"
                            style="color: ${ColorsEnum.grey4};"
                            width="25px">accessibility
                        </i>
                    </td>
                    <td style="text-align: right" data-test-id="distance_to_target_txt">${distance.toFixed(
                        1,
                    )}</td>
                    <td style="color: ${
                        ColorsEnum.grey4
                    };" data-test-id="distance_to_target_unit_txt">${distanceAbbreviation}</td>
                </tr>
                ${pixelPerMeterDisplay}
            </table>
        `;
    };

    public getFovPopupContent = (angle: number) => {
        const sensorTypeText = this.getSensorTypePopupContent();

        return `
            <table>
                ${sensorTypeText}
                <tr>
                    <td>
                        <i
                            class="${IconStyle}"
                            style="color: ${ColorsEnum.grey4};"
                            width="25px">angle
                        </i>
                    </td>
                    <td style="text-align: right" data-test-id="field_of_view_txt">${angle.toFixed(
                        1,
                    )}</td>
                    <td style="color: ${ColorsEnum.grey4};">°</td>
                </tr>
            </table>
        `;
    };

    public removeFromMap() {
        super.removeFromMap();
        this.identificationGuidePolygon.removeFrom(this.map);
        this.recognitionGuidePolygon.removeFrom(this.map);
        this.observationGuidePolygon.removeFrom(this.map);
        this.detectionGuidePolygon.removeFrom(this.map);
        this.analyticGuidePolygonPerson.removeFrom(this.map);
        this.analyticGuidePolygonVehicle.removeFrom(this.map);
    }

    public setColor(color: Colors, useTinyIcons = false) {
        super.setColor(color, useTinyIcons);
        const newColor = useTinyIcons ? ColorsEnum.grey4 : ColorsEnum[this.color];
        this.detectionGuidePolygon.setStyle({
            color: newColor,
        });
        this.observationGuidePolygon.setStyle({
            color: newColor,
        });
        this.recognitionGuidePolygon.setStyle({
            color: newColor,
        });
        this.identificationGuidePolygon.setStyle({
            color: newColor,
        });
        this.analyticGuidePolygonPerson.setStyle({
            color: ColorsEnum[this.color],
        });
        this.analyticGuidePolygonVehicle.setStyle({
            color: ColorsEnum[this.color],
        });
    }

    public focus = () => {
        super.focus();
        this.visibleAreaPolygon.setStyle({
            fillOpacity: 0.1,
        });
        this.resolutionGuidePolygon.setStyle({
            opacity: 1,
        });
        this.detectionGuidePolygon.setStyle({
            fillOpacity: doriPixelOpacity.DORI_OPACITY_DETECT,
        });
        this.observationGuidePolygon.setStyle({
            fillOpacity: doriPixelOpacity.DORI_OPACITY_OBSERVE,
        });
        this.recognitionGuidePolygon.setStyle({
            fillOpacity: doriPixelOpacity.DORI_OPACITY_RECOGNIZE,
        });
        this.identificationGuidePolygon.setStyle({
            fillOpacity: doriPixelOpacity.DORI_OPACITY_IDENTIFY,
        });
        this.analyticGuidePolygonPerson.setStyle({ opacity: FOCUS_OPACITY });
        this.analyticGuidePolygonVehicle.setStyle({ opacity: FOCUS_OPACITY });
    };

    public blur = () => {
        super.blur();
        this.resolutionGuidePolygon.setStyle({
            opacity: 0.2,
        });
        this.identificationGuidePolygon.setStyle(doriBlurOpacity);
        this.recognitionGuidePolygon.setStyle(doriBlurOpacity);
        this.observationGuidePolygon.setStyle(doriBlurOpacity);
        this.detectionGuidePolygon.setStyle(doriBlurOpacity);
        this.analyticGuidePolygonPerson.setStyle({ opacity: BLUR_OPACITY });
        this.analyticGuidePolygonVehicle.setStyle({ opacity: BLUR_OPACITY });
    };

    public updateDoriPixelsOn(blockers: PolyLine[] | undefined, isOn?: boolean) {
        this.doriPixelsOn = isOn !== undefined ? isOn : !this.doriPixelsOn;
        this.reDraw(blockers, {});
    }

    public toggleTinyIcons(blockers: PolyLine[] | undefined, isOn?: boolean) {
        this.useTinyIcons = isOn !== undefined ? isOn : !this.useTinyIcons;

        this.reDraw(blockers, {});
    }

    public updateRangeAnalytics(
        blockers: PolyLine[] | undefined,
        analyticRange?: IDeviceAnalyticRange,
    ) {
        this.analyticRange = analyticRange;
        if (!this.analyticRange || !this.analyticRange?.applicationId) {
            this.analyticGuidePolygonPerson.removeFrom(this.map);
            this.analyticGuidePolygonVehicle.removeFrom(this.map);
        } else {
            this.reDraw(blockers, {});
        }
    }

    public getAnalyticsPopupContent() {
        if (!this.analyticRange) {
            return null;
        }

        const renderedVerticalFovRad = trigonometry.toRadians(
            this.corridorFormat ? this.horizontalFov : this.verticalFov,
        );
        const tiltAngle = trigonometry.toDegrees(this.getTiltAngleRad(renderedVerticalFovRad));

        const analyticRangeLimits = getAnalyticZoneLimits(
            this.analyticRange,
            this.verticalFov,
            tiltAngle,
            this.sensor.height,
            this.sensor.parentPiaDevice,
        );

        if (analyticRangeLimits === undefined) {
            return null;
        }

        // For APD we have a max value for detection depending on weather and light conditions,
        // we can never detect farther away than this limit
        const limitValue = getAnalyticAPDMaxValues(
            this.analyticRange,
            this.sensor.parentPiaDevice,
            this.hasExternalIlluminator,
        );

        const humanMax =
            limitValue !== undefined
                ? Math.min(limitValue, analyticRangeLimits.human.max)
                : analyticRangeLimits.human.max;

        const vehicleMax =
            limitValue !== undefined
                ? Math.min(limitValue, analyticRangeLimits.vehicle.max)
                : analyticRangeLimits.vehicle.max;

        const personResolutionDistance = calculate.trueDistance(
            this.cameraHeight,
            humanMax,
            this.targetHeight,
        );

        const vehicleResolutionDistance = calculate.trueDistance(
            this.cameraHeight,
            vehicleMax,
            this.targetHeight,
        );

        const personPxPerMeter =
            this.maxVideoResolutionHorizontal /
            (personResolutionDistance * trigonometry.toRadians(this.horizontalFov));

        const vehiclePxPerMeter =
            this.maxVideoResolutionHorizontal /
            (vehicleResolutionDistance * trigonometry.toRadians(this.horizontalFov));

        return {
            person: {
                rangeLimit: humanMax,
                pxPerMeter: personPxPerMeter,
            },
            vehicle: {
                rangeLimit: vehicleMax,
                pxPerMeter: vehiclePxPerMeter,
            },
        } as IAnalyticsPopupContent;
    }

    public getIconInfo() {
        if (!this.verticalFov) {
            return undefined;
        }
        const rotationAngleRad = trigonometry.toRadians(
            convertMapAngleToConeAngle(this.horizontalAngle),
        );
        const iconInfo = {} as Record<IconTypes, { latLng: ILatLng; visible: boolean }>;

        let offsetHumanIcon = 0;
        let offsetVehicleIcon = 0;

        if (this.analyticRange) {
            const renderedVerticalFovRad = trigonometry.toRadians(
                this.corridorFormat ? this.horizontalFov : this.verticalFov,
            );
            const tiltAngle = trigonometry.toDegrees(this.getTiltAngleRad(renderedVerticalFovRad));
            const analyticRangeLimits = getAnalyticZoneLimits(
                this.analyticRange,
                this.verticalFov,
                tiltAngle,
                this.sensor.height,
                this.sensor.parentPiaDevice,
            );
            if (
                this.analyticRange.activeTypes.includes('person') &&
                this.analyticRange.activeTypes.includes('vehicle')
            ) {
                if (analyticRangeLimits === undefined) {
                    return undefined;
                }

                // For APD we have a max value for detection depending on weather and light conditions,
                // we can never detect farther away than this limit
                const limitValue = getAnalyticAPDMaxValues(
                    this.analyticRange,
                    this.sensor.parentPiaDevice,
                    this.hasExternalIlluminator,
                );

                const humanMax =
                    limitValue !== undefined
                        ? Math.min(limitValue, analyticRangeLimits.human.max)
                        : analyticRangeLimits.human.max;

                const vehicleMax =
                    limitValue !== undefined
                        ? Math.min(limitValue, analyticRangeLimits.vehicle.max)
                        : analyticRangeLimits.vehicle.max;

                const humanIconPosPixels = getIconMaxPosPixels(
                    humanMax,
                    this.targetDistance,
                    this.map,
                    this.latLng,
                );
                const vehicleIconPosPixels = getIconMaxPosPixels(
                    vehicleMax,
                    this.targetDistance,
                    this.map,
                    this.latLng,
                );

                if (humanMax > this.targetDistance && vehicleMax > this.targetDistance) {
                    offsetVehicleIcon = ICON_OFFSET;
                    offsetHumanIcon = -ICON_OFFSET;
                } else if (vehicleIconPosPixels.x - VEHICLE_ICON_HEIGHT <= humanIconPosPixels.x) {
                    offsetVehicleIcon = ICON_OFFSET;
                    offsetHumanIcon = -ICON_OFFSET;
                }
            }
            if (this.analyticRange.activeTypes.includes('person')) {
                if (analyticRangeLimits === undefined) {
                    return undefined;
                }

                // For APD we have a max value for detection depending on weather and light conditions,
                // we can never detect farther away than this limit
                const limitValue = getAnalyticAPDMaxValues(
                    this.analyticRange,
                    this.sensor.parentPiaDevice,
                    this.hasExternalIlluminator,
                );

                const humanMax =
                    limitValue !== undefined
                        ? Math.min(limitValue, analyticRangeLimits.human.max)
                        : analyticRangeLimits.human.max;

                const targetRotationOffset = this.getTargetRotationOffset();
                // Human icon
                const iconPosWithOffset = getOffsetIconPosLatLng(
                    HUMAN_ICON_HEIGHT,
                    humanMax,
                    rotationAngleRad,
                    this.cameraHeight,
                    this.targetHeight,
                    this.targetDistance,
                    targetRotationOffset,
                    this.latLng,
                    this.map,
                    offsetHumanIcon,
                );

                const visible = showRangeIcon(
                    {
                        cameraHeight: this.cameraHeight,
                        targetHeight: this.targetHeight,
                        rangeLimit: humanMax,
                        targetDistance: this.targetDistance,
                        rotationAngleRad: rotationAngleRad,
                        targetRotationOffset: targetRotationOffset,
                    },
                    {
                        resolutionGuidePolygon: this.resolutionGuidePolygon,
                        visibleAreaPolygon: this.visibleAreaPolygon,
                        detectionGuidePolygon: this.detectionGuidePolygon,
                        observationGuidePolygon: this.observationGuidePolygon,
                        recognitionGuidePolygon: this.recognitionGuidePolygon,
                        identificationGuidePolygon: this.identificationGuidePolygon,
                    },
                    {
                        iconHeight: HUMAN_ICON_HEIGHT,
                        iconWidth: HUMAN_ICON_WIDTH,
                        offsetY: offsetHumanIcon,
                    },
                    this.latLng,
                    this.map,
                    this.map.getZoom(),
                    true,
                    this.doriPixelsOn,
                );
                iconInfo['human'] = { latLng: iconPosWithOffset, visible };
            }
            if (this.analyticRange && this.analyticRange.activeTypes.includes('vehicle')) {
                if (!analyticRangeLimits) {
                    return undefined;
                }

                // For APD we have a max value for detection depending on weather and light conditions,
                // we can never detect farther away than this limit
                const limitValue = getAnalyticAPDMaxValues(
                    this.analyticRange,
                    this.sensor.parentPiaDevice,
                    this.hasExternalIlluminator,
                );

                const vehicleMax =
                    limitValue !== undefined
                        ? Math.min(limitValue, analyticRangeLimits.vehicle.max)
                        : analyticRangeLimits.vehicle.max;

                const targetRotationOffset = this.getTargetRotationOffset();

                const iconPosWithOffset = getOffsetIconPosLatLng(
                    VEHICLE_ICON_HEIGHT,
                    vehicleMax,
                    rotationAngleRad,
                    this.cameraHeight,
                    this.targetHeight,
                    this.targetDistance,
                    targetRotationOffset,
                    this.latLng,
                    this.map,
                    offsetVehicleIcon,
                );

                const visible = showRangeIcon(
                    {
                        cameraHeight: this.cameraHeight,
                        targetHeight: this.targetHeight,
                        rangeLimit: vehicleMax,
                        targetDistance: this.targetDistance,
                        rotationAngleRad: rotationAngleRad,
                        targetRotationOffset: targetRotationOffset,
                    },
                    {
                        resolutionGuidePolygon: this.resolutionGuidePolygon,
                        visibleAreaPolygon: this.visibleAreaPolygon,
                        detectionGuidePolygon: this.detectionGuidePolygon,
                        observationGuidePolygon: this.observationGuidePolygon,
                        recognitionGuidePolygon: this.recognitionGuidePolygon,
                        identificationGuidePolygon: this.identificationGuidePolygon,
                    },
                    {
                        iconHeight: VEHICLE_ICON_HEIGHT,
                        iconWidth: VEHICLE_ICON_WIDTH,
                        offsetY: offsetVehicleIcon,
                    },
                    this.latLng,
                    this.map,
                    this.map.getZoom(),
                    true,
                    this.doriPixelsOn,
                );

                iconInfo['vehicle'] = { latLng: iconPosWithOffset, visible };
            }
        }

        return Object.keys(iconInfo).length > 0 ? iconInfo : undefined;
    }

    public update(
        ip: IInstallationPointModel,
        blockers: PolyLine[] | undefined,
        sensorId?: number,
    ) {
        if (!sensorId) return;

        this.sensor = ip.sensors[sensorId - 1];
        if (!this.sensor) return;

        // Properties that might have changed due to change device
        const piaCamera = this.sensor.parentPiaDevice as IPiaCamera;
        this.setCameraProperties(piaCamera, this.sensor.parentDevice);

        this.maxHorizontalFov = this.sensor.fovLimits.horizontal.max;
        this.minHorizontalFov = this.sensor.fovLimits.horizontal.min;
        this.reDraw(blockers, {
            corridorFormat: this.sensor.settings.corridorFormat,
            targetHeight: this.sensor.target.height,
            horizontalFov: this.sensor.settings.horizontalFov,
            targetDistance: this.sensor.target.distance,
            horizontalAngle: this.sensor.target.horizontalAngle,
            cameraHeight: ip.height,
            location: leaflet.latLng(ip.location.lat, ip.location.lng),
        });
    }

    public getRotatedTarget(
        installationPoint: IInstallationPoint,
        rotationOffsetInDeg: number,
    ): DeepPartial<IInstallationPoint> {
        if (!installationPoint.sensors.length) {
            return installationPoint;
        }

        const sensors = installationPoint.sensors;
        const sensorIndex = sensors.findIndex((sensor) => sensor.sensorId === this.sensor.sensorId);
        const sensorToRotate = sensors[sensorIndex];
        sensors[sensorIndex] = merge(sensorToRotate, {
            target: {
                horizontalAngle: sensorToRotate.target.horizontalAngle + rotationOffsetInDeg,
            },
        });

        return {
            sensors,
        };
    }

    public reDraw(
        blockers: PolyLine[] | undefined,
        {
            horizontalFov = this.horizontalFov,
            targetDistance = this.targetDistance,
            cameraHeight = this.cameraHeight,
            targetHeight = this.targetHeight,
            corridorFormat = this.corridorFormat,
            horizontalAngle = this.horizontalAngle,
            location = this.latLng,
            requiredPixelDensity = this.requiredPixelDensity,
            panoramaMode = this.panoramaMode,
            tiltOffset = this.tiltOffset,
            doriPixelsOn = this.doriPixelsOn,
        },
    ) {
        if (!this.sensor) return;
        return this.memoizedRedraw(blockers, {
            horizontalFov,
            targetDistance,
            cameraHeight,
            targetHeight,
            corridorFormat,
            horizontalAngle,
            location,
            requiredPixelDensity,
            panoramaMode,
            tiltOffset,
            doriPixelsOn,
        });
    }

    /**
     * Avoid re-rendering the cone if the arguments are the same as the last time.
     * This is an expensive operation and should be avoided if possible.
     * The logic is similar to lodash.memoize but it only keeps track of the last call.
     */
    private lastRedrawArgs = '';
    private memoizedRedraw(...args: Parameters<typeof this.internalRedraw>) {
        const argsString = JSON.stringify(args);
        if (argsString !== this.lastRedrawArgs) {
            this.internalRedraw(...args);
            this.lastRedrawArgs = argsString;
        }
    }

    private internalRedraw(
        blockers: PolyLine[] | undefined,
        {
            horizontalFov,
            targetDistance,
            cameraHeight,
            targetHeight,
            corridorFormat,
            horizontalAngle,
            location,
            requiredPixelDensity,
            panoramaMode,
            tiltOffset,
            doriPixelsOn,
        }: Required<Parameters<typeof CameraCone.prototype.reDraw>[1]>,
    ) {
        try {
            this.horizontalFov = clamp(horizontalFov, this.minHorizontalFov, this.maxHorizontalFov);
            this.targetDistance = targetDistance;
            this.cameraHeight = cameraHeight;
            this.targetHeight = targetHeight;
            this.corridorFormat = corridorFormat;
            this.horizontalAngle = horizontalAngle;
            this.latLng = location;
            this.requiredPixelDensity = requiredPixelDensity;
            this.panoramaMode = this.getHasPanoramaMode(panoramaMode);
            this.doriPixelsOn = doriPixelsOn;

            const customCameraProperties = isCustomCamera(this.sensor.parentDevice)
                ? this.sensor.parentDevice.properties.camera.customCameraProperties
                : undefined;
            const aspectRatio = customCameraProperties
                ? customCameraProperties.resolutionHorizontal /
                  customCameraProperties.resolutionVertical
                : undefined;

            this.verticalFov = this.sensor.parentDevice.productId
                ? calculateVerticalFov(
                      this.horizontalFov,
                      this.maxHorizontalFov,
                      this.minHorizontalFov,
                      this.maxVerticalFov,
                      this.minVerticalFov,
                  )
                : estimateVerticalFOV(this.horizontalFov, aspectRatio);
            this.cameraHeight = cameraHeight;
            this.targetHeight = targetHeight;
            this.panoramaMode = this.getHasPanoramaMode(panoramaMode);
            this.requiredPixelDensity = requiredPixelDensity;

            // Add 90 degrees since the cone assumes 0 is to the right (it is down in our map)
            const rotationAngleRad = trigonometry.toRadians(
                convertMapAngleToConeAngle(this.horizontalAngle),
            );

            // Get the fov to render in the map (ie. top-down)
            const renderedHorizontalFov = this.corridorFormat
                ? this.verticalFov
                : this.horizontalFov;

            const renderedVerticalFovRad = trigonometry.toRadians(
                this.corridorFormat ? this.horizontalFov : this.verticalFov,
            );

            // Do not allow the camera to point "backwards" below or above itself
            // (ie. allow maximum tilt angle to be 90 degrees down)
            const renderedTargetDistance = this.getRenderedTargetDistance(renderedVerticalFovRad);

            // FOV handle
            const fovHandlePoint = calculateCameraFovHandlePoint(
                renderedHorizontalFov,
                renderedVerticalFovRad,
                this.cameraHeight,
                this.targetHeight,
                renderedTargetDistance,
                tiltOffset,
            );

            this.fovHandleLatLng =
                fovHandlePoint && offset(this.latLng)(rotate(rotationAngleRad)(fovHandlePoint));

            // Camera specific calculation of visible area
            const visibleArea = calculateVisibleArea(
                renderedHorizontalFov,
                renderedVerticalFovRad,
                this.cameraHeight,
                this.targetHeight,
                renderedTargetDistance,
                this.panoramaMode,
                this.isTiltable,
                tiltOffset,
            );

            // Get the maximum angle from the camera to any point in the resolution guide
            const maxResolutionGuideAngle = trigonometry.toDegrees(
                (visibleArea.regions[0] ?? []).reduce(
                    (max, point) => Math.max(max, Math.atan2(point[1], point[0])),
                    trigonometry.toRadians(renderedHorizontalFov),
                ),
            );

            // make sure the resolution guide angle is great enough to be fully visible
            // with the current visible area. We add 10 degrees to make sure the resolution guide
            // is fully visible. We multiply by 2 since the resolution guide is bilateral.
            const resolutionGuideAngle =
                maxResolutionGuideAngle < 90 ? 2 * maxResolutionGuideAngle + 10 : 360;

            const cameraConeOutline = calculateConeOutline(
                renderedHorizontalFov,
                visibleArea,
                this.isTiltable,
                fovHandlePoint,
            );

            const rotatedCameraConeOutline = rotatePolygon(cameraConeOutline, rotationAngleRad);

            const rotatedVisibleArea = rotatePolygon(visibleArea, rotationAngleRad);

            const resolutionGuideLimit =
                this.maxVideoResolutionHorizontal /
                (this.requiredPixelDensity * trigonometry.toRadians(this.horizontalFov));

            const resolutionGuidePolygon = getResolutionGuidePolygon(
                this.cameraHeight,
                this.targetHeight,
                resolutionGuideLimit,
                resolutionGuideAngle,
                convertMapAngleToConeAngle(this.horizontalAngle),
            );

            const targetPos: Point = [this.targetDistance, 0];
            const renderHorizon = distanceFromOrigo(fovHandlePoint ?? targetPos);

            this.applyBlockerShadows(blockers, {
                renderHorizon,
                visibleArea: rotatedVisibleArea,
                outline: rotatedCameraConeOutline,
                resolutionGuide: resolutionGuidePolygon,
                resolutionGuideAngle,
            });
        } catch (error) {
            console.error(error);
        }
    }

    public getIsFovAdjustable() {
        const panoramaMode = !this.isTiltable
            ? 'vertical'
            : (this.sensor.parentDevice.properties.camera?.filter.panoramaMode ??
              this.sensor.parentDevice.properties.sensorUnit?.filter.panoramaMode);
        return panoramaMode ? false : this.maxHorizontalFov !== this.minHorizontalFov;
    }

    public getIsTargetAdjustable() {
        return true;
    }

    public getTargetRotationOffset() {
        return this.horizontalFov >= 180 &&
            this.verticalFov < 180 &&
            this.panoramaMode === 'horizontal'
            ? Math.PI / 2
            : 0;
    }

    public getTargetCoords(): ILatLng {
        // Add 90 degrees since the cone assumes 0 is to the right (it is down in our map)
        const rotationAngleRad = trigonometry.toRadians(
            convertMapAngleToConeAngle(this.horizontalAngle),
        );

        const targetPos: Point = [this.targetDistance, 0];
        const rotatedPos = rotate(rotationAngleRad - this.getTargetRotationOffset())(targetPos);
        return offset(this.latLng)(rotatedPos);
    }

    public getHasDirectionalArrow() {
        return this.panoramaMode !== 'horizontal';
    }

    public getFovHandleLatLng() {
        return this.fovHandleLatLng;
    }

    /**
     * Get popup content for the sensor type
     * Used in the target- and fov handle popups
     */
    private getSensorTypePopupContent = () => {
        const hasMultipleTypes = hasMultipleSensorTypes(this.sensor);
        const sensorTypeText = getSensorTypeText(this.sensor);

        return hasMultipleTypes
            ? `
            <tr>
                <td>
                    ${sensorTypeText}
                </td>
            </tr>
        `
            : '';
    };

    private getHasPanoramaMode(panoramaMode: PanoramaModes) {
        // panoramaMode is set in the parentDevice filter, but if it is virtual sensor we need the sensor's piaDevice panoramaMode
        return this.sensor.isVirtual ? this.panoramaMode : panoramaMode;
    }

    private getTiltAngleRad = (renderedVerticalFovRad: number) => {
        return this.isTiltable && this.panoramaMode === false
            ? -trigonometry.toRadians(
                  calculateTiltAngleFromRadians(
                      this.cameraHeight,
                      this.targetHeight,
                      this.targetDistance,
                      renderedVerticalFovRad,
                      this.tiltOffset,
                  ),
              )
            : 0;
    };

    private getRenderedTargetDistance = (renderedVerticalFovRad: number) => {
        const tiltAngleRad = this.getTiltAngleRad(renderedVerticalFovRad);
        return tiltAngleRad >= Math.PI / 2
            ? Math.abs(this.cameraHeight - this.targetHeight) *
                  Math.tan(renderedVerticalFovRad / 2 - this.tiltOffset)
            : this.targetDistance;
    };

    private getResolutionDistance = (pxPerDistance: number) => {
        return (
            this.maxVideoResolutionHorizontal /
            (pxPerDistance * trigonometry.toRadians(this.horizontalFov))
        );
    };

    private setCameraProperties = (piaDevice: IPiaCamera | null, parentDevice: IItemEntity) => {
        if (!this.sensor) {
            return;
        }

        this.analyticRange = parentDevice.analyticRange;
        // if the sensor is virtual and is panoramic we cannot use the parentDevice camera filter prop for panoramaMode
        // CameraCone will then be drawn wrong (if parent is not panoramic)
        this.panoramaMode = this.sensor.isVirtual
            ? piaDevice
                ? piaDevice.properties.defaultPanoramaMode
                : false
            : (parentDevice.properties.camera ?? parentDevice.properties.sensorUnit)?.filter
                  .panoramaMode || false;
        this.requiredPixelDensity =
            (parentDevice.properties.camera ?? parentDevice.properties.sensorUnit)?.filter
                .pixelDensity || 125;

        this.maxHorizontalFov = this.sensor.fovLimits.horizontal.max;
        this.minHorizontalFov = this.sensor.fovLimits.horizontal.min;

        const nbrOfChannels = piaDevice?.properties.channels ?? 1;

        const customCameraProperties = isCustomCamera(parentDevice)
            ? parentDevice.properties.camera.customCameraProperties
            : undefined;

        const maxVideoResolution =
            piaDevice?.properties.maxVideoResolutionHorizontal ??
            customCameraProperties?.resolutionHorizontal ??
            16;

        this.maxVideoResolutionHorizontal =
            maxVideoResolution *
            (piaDevice?.properties.panCameraType === 'Multisensor' ? nbrOfChannels : 1);

        this.maxVerticalFov = this.sensor.fovLimits.vertical.max;
        this.minVerticalFov = this.sensor.fovLimits.vertical.min;

        this.verticalFov = parentDevice.productId
            ? calculateVerticalFov(
                  this.horizontalFov,
                  this.maxHorizontalFov,
                  this.minHorizontalFov,
                  this.maxVerticalFov,
                  this.minVerticalFov,
              )
            : estimateVerticalFOV(this.horizontalFov);

        this.isTiltable = !deviceTypeCheckers.isDoorStation(parentDevice);
    };

    protected applyBlockerShadows(
        blockers: PolyLine[] | undefined,
        {
            renderHorizon,
            visibleArea,
            resolutionGuide,
            outline,
            resolutionGuideAngle,
        }: IBlockerShadowParams,
    ) {
        const shadows = getShadowPolygons(this.latLng, blockers ?? [], renderHorizon);
        const horizontalAngle = convertMapAngleToConeAngle(this.horizontalAngle);

        let visibleAreaPolygon;

        // visible area polygon
        if (visibleArea) {
            visibleAreaPolygon = diffMultiplePolygons(visibleArea, shadows);
        }

        // outline polygon
        if (outline) {
            const outlinePolygon = diffMultiplePolygons(outline, shadows);
            this.setPolygon(this.outlinePolygon, outlinePolygon);
        }

        if (this.doriPixelsOn) {
            const identificationGuide = getResolutionGuidePolygon(
                this.cameraHeight,
                this.targetHeight,
                this.getResolutionDistance(
                    this.distanceUnit === DistanceUnit.Feet
                        ? convertDensityToMeters(
                              doriPixelLimitsFeet.DORI_LIMIT_IDENTIFY,
                              DistanceUnit.Feet,
                          )
                        : doriPixelLimitsMeter.DORI_LIMIT_IDENTIFY,
                ),
                resolutionGuideAngle,
                horizontalAngle,
            );

            const recognitionGuide = getResolutionGuidePolygon(
                this.cameraHeight,
                this.targetHeight,
                this.getResolutionDistance(
                    this.distanceUnit === DistanceUnit.Feet
                        ? convertDensityToMeters(
                              doriPixelLimitsFeet.DORI_LIMIT_RECOGNIZE,
                              DistanceUnit.Feet,
                          )
                        : doriPixelLimitsMeter.DORI_LIMIT_RECOGNIZE,
                ),
                resolutionGuideAngle,
                horizontalAngle,
            );

            const observationGuide = getResolutionGuidePolygon(
                this.cameraHeight,
                this.targetHeight,
                this.getResolutionDistance(
                    this.distanceUnit === DistanceUnit.Feet
                        ? convertDensityToMeters(
                              doriPixelLimitsFeet.DORI_LIMIT_OBSERVE,
                              DistanceUnit.Feet,
                          )
                        : doriPixelLimitsMeter.DORI_LIMIT_OBSERVE,
                ),
                resolutionGuideAngle,
                horizontalAngle,
            );

            const detectionGuide = getResolutionGuidePolygon(
                this.cameraHeight,
                this.targetHeight,
                this.getResolutionDistance(
                    this.distanceUnit === DistanceUnit.Feet
                        ? convertDensityToMeters(
                              doriPixelLimitsFeet.DORI_LIMIT_DETECT,
                              DistanceUnit.Feet,
                          )
                        : doriPixelLimitsMeter.DORI_LIMIT_DETECT,
                ),
                resolutionGuideAngle,
                horizontalAngle,
            );

            if (
                identificationGuide &&
                recognitionGuide &&
                observationGuide &&
                detectionGuide &&
                visibleAreaPolygon &&
                resolutionGuide
            ) {
                this.identificationGuidePolygon.addTo(this.map);
                this.recognitionGuidePolygon.addTo(this.map);
                this.observationGuidePolygon.addTo(this.map);
                this.detectionGuidePolygon.addTo(this.map);

                const identificationGuidePolygon = intersectPolygons(
                    identificationGuide,
                    visibleAreaPolygon,
                );

                const recognitionGuidePolygon = intersectPolygons(
                    recognitionGuide,
                    visibleAreaPolygon,
                );
                const diffRecognition = diffPolygons(
                    recognitionGuidePolygon,
                    identificationGuidePolygon,
                );

                const observationGuidePolygon = intersectPolygons(
                    observationGuide,
                    visibleAreaPolygon,
                );
                const diffObservation = diffPolygons(
                    observationGuidePolygon,
                    recognitionGuidePolygon,
                );

                const detectionGuidePolygon = intersectPolygons(detectionGuide, visibleAreaPolygon);
                const diffDetection = diffPolygons(detectionGuidePolygon, observationGuidePolygon);

                this.setPolygon(this.identificationGuidePolygon, identificationGuidePolygon);
                this.setPolygon(this.recognitionGuidePolygon, diffRecognition);
                this.setPolygon(this.observationGuidePolygon, diffObservation);
                this.setPolygon(this.detectionGuidePolygon, diffDetection);

                const diffVisible = diffPolygons(visibleAreaPolygon, detectionGuidePolygon);

                this.visibleAreaPolygon.setStyle({
                    stroke: false,
                    weight: this.useTinyIcons ? 1 : 3,
                });

                this.setPolygon(this.visibleAreaPolygon, diffVisible);
                this.resolutionGuidePolygon.setStyle({
                    fill: false,
                    stroke: true,
                    weight: this.useTinyIcons ? 1 : 3,
                });
                const resolutionGuidePolygon = intersectPolygons(
                    resolutionGuide,
                    visibleAreaPolygon,
                );
                this.setPolygon(this.resolutionGuidePolygon, resolutionGuidePolygon);
            }
        } else {
            this.identificationGuidePolygon.removeFrom(this.map);
            this.recognitionGuidePolygon.removeFrom(this.map);
            this.observationGuidePolygon.removeFrom(this.map);
            this.detectionGuidePolygon.removeFrom(this.map);

            if (resolutionGuide) {
                // resolution guide polygon
                this.resolutionGuidePolygon.setStyle({
                    fill: true,
                    stroke: this.useTinyIcons ? true : false,
                });
                const resolutionGuidePolygon = visibleAreaPolygon
                    ? intersectPolygons(resolutionGuide, visibleAreaPolygon)
                    : diffMultiplePolygons(resolutionGuide, shadows);
                this.setPolygon(this.resolutionGuidePolygon, resolutionGuidePolygon);
            }

            if (visibleArea && visibleAreaPolygon) {
                // visible area polygon
                this.visibleAreaPolygon.setStyle({
                    stroke: this.useTinyIcons ? true : false,
                });
                this.setPolygon(this.visibleAreaPolygon, visibleAreaPolygon);
            }
        }

        if (this.analyticRange?.applicationId) {
            const renderedVerticalFovRad = trigonometry.toRadians(
                this.corridorFormat ? this.horizontalFov : this.verticalFov,
            );
            const tiltAngle = trigonometry.toDegrees(this.getTiltAngleRad(renderedVerticalFovRad));
            const analyticRangeLimits = getAnalyticZoneLimits(
                this.analyticRange,
                this.verticalFov,
                tiltAngle,
                this.sensor.height,
                this.sensor.parentPiaDevice,
            );
            if (this.analyticRange.activeTypes.includes('person')) {
                if (!analyticRangeLimits) {
                    return undefined;
                }

                // For APD we have a max value for detection depending on weather and light conditions,
                // we can never detect farther away than this limit
                const limitValue = getAnalyticAPDMaxValues(
                    this.analyticRange,
                    this.sensor.parentPiaDevice,
                    this.hasExternalIlluminator,
                );

                const humanMax =
                    limitValue !== undefined
                        ? Math.min(limitValue, analyticRangeLimits.human.max)
                        : analyticRangeLimits.human.max;

                const humanMin =
                    limitValue !== undefined
                        ? Math.min(limitValue, analyticRangeLimits.human.min)
                        : analyticRangeLimits.human.min;

                const analyticGuidePerson =
                    this.analyticRange.zone !== undefined
                        ? getAnalyticGuidePolygon(humanMin, humanMax)
                        : getResolutionGuidePolygon(
                              this.cameraHeight,
                              this.targetHeight,
                              humanMax,
                              resolutionGuideAngle,
                              horizontalAngle,
                          );
                const analyticGuidePolygonPerson = visibleAreaPolygon
                    ? intersectPolygons(analyticGuidePerson, visibleAreaPolygon)
                    : diffMultiplePolygons(analyticGuidePerson, shadows);

                this.analyticGuidePolygonPerson.addTo(this.map);
                this.setPolygon(this.analyticGuidePolygonPerson, analyticGuidePolygonPerson);
            }

            if (this.analyticRange && this.analyticRange.activeTypes.includes('vehicle')) {
                if (!analyticRangeLimits) {
                    return undefined;
                }

                // For APD we have a max value for detection depending on weather and light conditions,
                // we can never detect farther away than this limit
                const limitValue = getAnalyticAPDMaxValues(
                    this.analyticRange,
                    this.sensor.parentPiaDevice,
                    this.hasExternalIlluminator,
                );

                const vehicleMax =
                    limitValue !== undefined
                        ? Math.min(limitValue, analyticRangeLimits.vehicle.max)
                        : analyticRangeLimits.vehicle.max;

                const vehicleMin =
                    limitValue !== undefined
                        ? Math.min(limitValue, analyticRangeLimits.vehicle.min)
                        : analyticRangeLimits.vehicle.min;

                const analyticGuideVehicle =
                    this.analyticRange.zone !== undefined
                        ? getAnalyticGuidePolygon(vehicleMin, vehicleMax)
                        : getResolutionGuidePolygon(
                              this.cameraHeight,
                              this.targetHeight,
                              vehicleMax,
                              resolutionGuideAngle,
                              horizontalAngle,
                          );

                const analyticGuidePolygonVehicle = visibleAreaPolygon
                    ? intersectPolygons(analyticGuideVehicle, visibleAreaPolygon)
                    : diffMultiplePolygons(analyticGuideVehicle, shadows);

                this.analyticGuidePolygonVehicle.addTo(this.map);
                this.setPolygon(this.analyticGuidePolygonVehicle, analyticGuidePolygonVehicle);
            }
        }

        if (this.analyticRange && this.onIconPositionsChanged) {
            this.onIconPositionsChanged(this.getIconInfo());
        }
    }
}
