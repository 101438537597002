import { BillOfMaterialsService } from './BillOfMaterials.service';
import { injectable } from 'inversify';
import { ActionCreator, IStoreState, ThunkAction } from 'app/store';
import { saveAs } from 'file-saver';
import { getBidconExportFileContent } from '../selectors';
import {
    getCurrentProjectId,
    getCurrentProjectLocation,
    getCurrentProjectName,
    IProduct,
} from 'app/modules/common';
import { ProjectCustomerInfoService } from 'app/core/persistence';
import { ProjectPricingService } from './ProjectPricing.service';
import { t } from 'app/translate';
import { toaster } from 'app/toaster';

@injectable()
export class BillOfMaterialsActionService {
    constructor(
        private projectCustomerInfoService: ProjectCustomerInfoService,
        private billOfMaterialsService: BillOfMaterialsService,
        private projectPricingService: ProjectPricingService,
    ) {}

    @ActionCreator()
    public generateBidconExportFile(): ThunkAction {
        return (_dispatch, getState) => {
            const state = getState();
            const content = getBidconExportFileContent(state);
            const projectName = getCurrentProjectName(state);

            const fileBlob = new Blob([content.join('\r\n')], {
                type: 'text/plain;charset=utf-8',
            });
            const filename = `${projectName}.bidcon.csv`;

            saveAs(fileBlob, filename);
        };
    }

    @ActionCreator()
    public generateBomFile(products: IProduct[]): ThunkAction {
        return async (_dispatch, getState) => {
            const state = getState();
            const projectName = getCurrentProjectName(state);
            const fileBlob = await this.getFileContent(state, products);
            const filename = t.billOfMaterialsExcelFileName(projectName) + '.json';
            saveAs(fileBlob, filename);
        };
    }

    @ActionCreator()
    public openProjectPricingPage(products: IProduct[]): ThunkAction {
        return async (_dispatch, getState) => {
            const state = getState();
            const toast = toaster.progress(t.sendingProjectToPricingTool);
            try {
                const fileBlob = await this.getFileContent(state, products);
                await this.projectPricingService.openProjectPricingUrl(fileBlob);
                toast.setSuccess(t.sentToProjectToPricingTool);
            } catch {
                toast.setError(t.errorFetchProjectPricingUrl);
            }
        };
    }

    private async getFileContent(storeState: IStoreState, products: IProduct[]): Promise<Blob> {
        const projectId = getCurrentProjectId(storeState);
        const location = getCurrentProjectLocation(storeState);
        const customerInfo = await this.projectCustomerInfoService.getCustomerInfo(projectId);
        const fileBlob = this.billOfMaterialsService.getBillOfMaterials(
            projectId,
            customerInfo,
            location?.countryCode,
            products,
        );
        return fileBlob;
    }
}
