import type {
    IInstallationPointEntity,
    IPersistence,
    IItemEntity,
    IItemRelationEntity,
    IInstallationPointModel,
} from 'app/core/persistence';
import { getParentId } from 'app/core/persistence';
import type { IPiaRelationReference } from 'app/core/pia';
import { PiaItemCameraCategory, PiaItemService } from 'app/core/pia';
import { ServiceLocator } from 'app/ioc';
import { isDefined } from 'axis-webtools-util';
import { curry } from 'lodash';
import { calculateFOVLimits } from '../piaDevices/selectors';

const clamp = curry((min: number, max: number, val: number) => Math.max(min, Math.min(max, val)));

export const mapInstallationEntityToModel = (
    ip: IInstallationPointEntity,
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    relations: IPersistence<IItemRelationEntity>[],
): IInstallationPointModel | undefined => {
    const piaService = ServiceLocator.get(PiaItemService);

    const parentId = getParentId(ip);

    if (!parentId) {
        return undefined;
    }

    const parentDevice = items[parentId];

    if (!parentDevice) {
        return undefined;
    }

    const parentPiaDevice = parentDevice.productId
        ? piaService.get(parentDevice.productId).first()
        : null;

    // For marketing reasons some "PTZ" cameras such as Q6010 and Q6100 aren't really
    // ptz so we have to take panCameraType into account as well
    const isReallyPtz =
        parentPiaDevice?.categories.includes(PiaItemCameraCategory.PTZ) &&
        parentPiaDevice?.properties.panCameraType !== 'Multidirectional';

    // default panRange if not set
    const panRange =
        !ip.panRange && isReallyPtz
            ? {
                  target: {
                      distance: ip.sensors[0].target.distance * 0.5,
                      horizontalAngle: ip.sensors[0].target.horizontalAngle,
                  },
              }
            : ip.panRange;

    // make sure that the selected horizontal fov is within bounds of camera and lens
    let lenses: IPersistence<IItemEntity>[] = [];
    const sensors = ip.sensors.map((sensor) => {
        const sensorParentPiaDevice = sensor.parentPiaDeviceId
            ? piaService.get(sensor.parentPiaDeviceId!).first()
            : parentPiaDevice;
        if (parentPiaDevice?.id === sensorParentPiaDevice?.id) {
            lenses = relations
                .filter(
                    (relation) =>
                        relation.relationType === 'lenses' && relation.parentId === parentId,
                )
                .map((lensRelation) => items[lensRelation.childId])
                .filter(isDefined);
        }
        const lensItemEntity = lenses?.find((lens) => {
            // sensorIndex starts at 0 and sensorId starts at 1.
            const sensorIndex = lens.properties.lens?.sensorIndex ?? 0;
            return sensorIndex + 1 === sensor.sensorId;
        });

        const lensRelation = sensorParentPiaDevice?.relations.find(
            (relation: IPiaRelationReference) => relation.id === lensItemEntity?.productId,
        );

        const fovLimits = calculateFOVLimits(
            sensorParentPiaDevice,
            lensRelation?.relationProperties,
            parentDevice,
        );

        const horizontalFov = clamp(
            sensor.settings.horizontalFov,
            fovLimits.horizontal.min,
            fovLimits.horizontal.max,
        );

        return {
            ...sensor,
            settings: {
                ...sensor.settings,
                horizontalFov,
            },
            fovLimits,
            height: ip.height,
            location: ip.location,
            parentPiaDevice: sensorParentPiaDevice,
            parentDevice,
            installationPointId: ip._id,
        };
    });

    const model = {
        ...ip,
        sensors,
        panRange,
        parentDevice,
        parentPiaDevice,
        lenses,
    };

    return model;
};
