import { createSelector } from 'reselect';
import { UnreachableCaseError } from 'axis-webtools-util';
import type { IProjectListItem, ProjectsSortOrder } from '../models';
import {
    creationDateComparator,
    creationDateReverseComparator,
    nameComparator,
    nameComparatorReverse,
    updatedDateComparator,
    updatedDateComparatorReverse,
} from 'app/utils';
import type { IStoreState, ProjectStoreState } from 'app/store';
import { getShowArchivedProjects } from './getShowArchivedProjects';
import { getUserProjectNameFilter } from './getUserProjectNameFilter';
import { getUserProjects } from './getUserProjects';
import { getSorting } from './getSorting';

export const getVisibleUserProjects = createSelector(
    [
        (state: IStoreState, property: ProjectStoreState) =>
            getShowArchivedProjects(state, property),
        (state: IStoreState, property: ProjectStoreState) =>
            getUserProjectNameFilter(state, property),
        (state: IStoreState, property: ProjectStoreState) => getUserProjects(state, property),
        (state: IStoreState, property: ProjectStoreState) => getSorting(state, property),
    ],
    (showArchivedProjects, userProjectNameFilter, userProjects, sortOrder) => {
        return userProjects
            .filter((userProject) => {
                if (!showArchivedProjects && userProject.archived) {
                    return false;
                }

                return userProject.name.toLowerCase().includes(userProjectNameFilter.toLowerCase());
            })
            .sort(getSortFunction(sortOrder));
    },
);

function getSortFunction(sortProperty: ProjectsSortOrder) {
    switch (sortProperty.sort) {
        case 'none':
            return (_a: IProjectListItem, _b: IProjectListItem) => 0;
        case 'name':
            if (sortProperty.direction === 'ascending') return nameComparator;
            else return nameComparatorReverse;
        case 'updated':
            if (sortProperty.direction === 'ascending') return updatedDateComparator;
            else return updatedDateComparatorReverse;

        case 'created':
            if (sortProperty.direction === 'ascending') return creationDateComparator;
            else return creationDateReverseComparator;
        case 'devices':
            if (sortProperty.direction === 'ascending')
                return (a: IProjectListItem, b: IProjectListItem) =>
                    b.devicesQuantity - a.devicesQuantity;
            else
                return (a: IProjectListItem, b: IProjectListItem) =>
                    a.devicesQuantity - b.devicesQuantity;
        default:
            throw new UnreachableCaseError(sortProperty.sort);
    }
}
