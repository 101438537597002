import { injectable } from 'inversify';
import { EventEmitter } from 'events';
export type EventEmitterType =
    | 'userprojects'
    | 'sharedprojects'
    | 'localprojects'
    | 'memory'
    | 'standalone';

@injectable()
export class EventEmitterService {
    private userProjectsEventEmitter = new EventEmitter();
    private sharedProjectsEventEmitter = new EventEmitter();
    private localProjectsEventEmitter = new EventEmitter();
    private memoryEventEmitter = new EventEmitter();

    public getEventEmitter(type: EventEmitterType): EventEmitter {
        if (type === 'userprojects') {
            return this.userProjectsEventEmitter;
        }

        if (type === 'sharedprojects') {
            return this.sharedProjectsEventEmitter;
        }

        if (type === 'localprojects') {
            return this.localProjectsEventEmitter;
        }

        // type === 'memory' and type === 'standalone' default to memoryEventEmitter
        return this.memoryEventEmitter;
    }
}
