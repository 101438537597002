import { injectable } from 'inversify';
import {
    PiaAccessoryCategory,
    PiaItemRecorderCategory,
    isSingleQuantityCategory,
} from 'app/core/pia';
import type { IPiaItem, PiaId, IPiaSystemComponentProperties } from 'app/core/pia';
import type {
    CameraStationType,
    CameraStationCenterType,
    IIdRev,
    IItem,
    IItemEntity,
    IPartnerSystemComponent,
    IPersistence,
    RecordingSolutionType,
    SelectedVendorType,
} from 'app/core/persistence';
import {
    ItemService,
    CurrentProjectService,
    ProjectService,
    deviceTypeCheckers,
    needsLicense,
} from 'app/core/persistence';
import type {
    IRecordingSolution,
    IRecordingSolutionItem,
    ISelectedVmsByLicenseTypes,
    IVmsByLicenseType,
    vmsLicenseType,
} from '../selectors';
import { getLicenseNameWithoutType } from '../selectors';
import {
    AccessoryPersistenceService,
    ProductQueriesService,
} from 'app/modules/accessorySelector/services';
import { flatMap, uniq } from 'lodash-es';
import { ModalService } from 'app/modal';
import { t } from 'app/translate';
import { PartnerSystemItemCategory } from 'app/core/partner';
import { eventTracking } from 'app/core/tracking';
import type { IPartnerRecordingListItem, IRecordingListItem } from '../models/IRecordingListItem';
import { CENTER_LICENSE_PIAID_FIVE_YEAR, CENTER_LICENSE_PIAID_ONE_YEAR } from '../constants';

@injectable()
export class RecordingSelectorService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private productQueriesService: ProductQueriesService,
        private accessoryPersistenceService: AccessoryPersistenceService,
        private modalService: ModalService,
        private projectService: ProjectService,
    ) {}

    public add = async (
        productId: PiaId,
        quantity = 1,
        accessories: IRecordingSolutionItem[] = [],
    ) => {
        // added getRecordingDeviceFromProject to use instead of getRecordingDeviceFromCurrentProject.
        // Added since when switching recommended solution to a solution containing the same device as
        // the one we switched from, we first clear current solution
        // which means we remove items from repository and current project is updated slightly later.
        // when checking if current project contains the item to add, the item we just deleted in the repository
        // was not removed from current project, but when increasing the quantity, it was removed and we got error
        // database (not found)

        const item = await this.itemService.addToCurrentProject({
            name: '',
            description: '',
            notes: '',
            productId,
            quantity,
            properties: {
                systemComponent: {},
            },
        });

        await Promise.all(
            accessories.map(async (accessory) => {
                const addedAccessory = await this.itemService.addByParentId(
                    item._id,
                    this.getAccessoryBaseItemModel(accessory),
                );
                return this.itemService.addItemRelation(item._id, addedAccessory._id, 'accessory');
            }),
        );
        eventTracking.logUserEvent('System Components', 'Add recording item', `${productId}`);
    };

    public bulkAdd = async (
        items: {
            productId: PiaId;
            quantity: number;
            accessories: IRecordingSolutionItem[] | undefined;
        }[],
    ) => {
        const addedItems = await this.itemService.addItemsToCurrentProject(
            items.map((item) => ({
                name: '',
                description: '',
                notes: '',
                productId: item.productId,
                quantity: item.quantity,
                properties: {
                    systemComponent: {},
                },
            })),
        );

        items.forEach((item, index) => {
            if (!item.accessories) {
                return;
            }
            Promise.all(
                item.accessories.map(async (accessory) => {
                    const addedAccessory = await this.itemService.addByParentId(
                        addedItems[index]._id,
                        this.getAccessoryBaseItemModel(accessory),
                    );
                    return this.itemService.addItemRelation(
                        addedItems[index]._id,
                        addedAccessory._id,
                        'accessory',
                    );
                }),
            );
        });
    };

    /**
     * @param isSolutionEmpty - boolean telling if recording solution is empty
     * @param selectedRecordingSolutionType - Selected recording solution type "AxisS1X" | "AxisS2X" | "AxisS30" | "AxisS1XPro" | "AxisS2XPro" | "AxisS30Pro" | "AxisCompanionS30" | "AxisCompanionSDCard" | "Milestone" | "Genetec"
     * @param selectedVendorName - Selected recording solution vendor 'Axis', 'Genetec', 'Milestone' or undefined if none selected
     * @returns true, if ok, to quick add a recording product, i.e either if recording solution already was Axis or was a network switch (that is also available for both Milestone and Genetec)
     *  or if user confirmed clearing existing 'Milestone' or 'Genetec' solution.
     */
    public async confirmQuickAddProduct(
        isSolutionEmpty: boolean,
        selectedRecordingSolutionType: RecordingSolutionType,
        selectedVendorName: SelectedVendorType | undefined,
        recordingItem: IPiaItem,
    ): Promise<boolean> {
        const needToClearCurrentSolution =
            !isSolutionEmpty &&
            selectedVendorName !== 'Axis' &&
            recordingItem.category !== PiaAccessoryCategory.NETWORKSWITCHES;

        if (needToClearCurrentSolution) {
            const userAcceptedClearAction = await this.modalService.createConfirmDialog({
                header: t.replaceRecordingSolutionHeading,
                body: t.replaceRecordingSolutionMessage,
                confirmButtonText: t.proceed,
                cancelButtonText: t.cancel,
            })();
            if (userAcceptedClearAction) {
                await this.clearCurrentSolution(selectedRecordingSolutionType);
                await this.projectService.clearRecordingSolutionType();
                await this.projectService.updateCurrentProject({ selectedRecordingVendor: 'Axis' });
                return true;
            } else {
                // Abort add (user chose cancel)
                return false;
            }
        }
        return true;
    }

    public async addItemWithVendorConsistency(
        item: IRecordingListItem,
        isSolutionEmpty: boolean,
        selectedRecordingSolutionType: RecordingSolutionType,
        selectedVendorName: SelectedVendorType | undefined,
        viewingAcsType: CameraStationType | undefined,
    ): Promise<void> {
        const needToClearCurrentSolution =
            !isSolutionEmpty &&
            !this.ensureVendorConsistency(
                selectedRecordingSolutionType,
                selectedVendorName,
                viewingAcsType,
            );

        if (
            needToClearCurrentSolution &&
            !(await this.askToClearSolution(selectedRecordingSolutionType))
        ) {
            // Abort add (user chose cancel)
            return;
        }

        // Add PIA product
        if (item.piaId) {
            this.add(item.piaId);
            return this.updateRecordingSolutionType(isSolutionEmpty, item, viewingAcsType);
        }

        // Add Partner product
        if (this.isPartnerItem(item)) {
            this.addPartnerItem(item);
            const newRecordingSolutionType = item.vendor === 'genetec' ? 'Genetec' : 'Milestone';
            const recordingSolutionTypeHasChanged =
                this.currentProjectService.getCurrentRecordingSolutionType() !==
                newRecordingSolutionType;

            // Only update if the recording solution type has changed
            recordingSolutionTypeHasChanged &&
                (await this.projectService.updateCurrentProject({
                    recordingSolutionType: item.vendor === 'genetec' ? 'Genetec' : 'Milestone',
                }));
        }
    }

    public addOrUpdateCenterLicense = async (
        quantity = 1,
        centerChoice: CameraStationCenterType,
    ) => {
        const item = await this.getRecordingDeviceFromProject(
            centerChoice === 'Center1year'
                ? CENTER_LICENSE_PIAID_ONE_YEAR
                : CENTER_LICENSE_PIAID_FIVE_YEAR,
        );

        if (item) {
            if (item.quantity === quantity) {
                return item;
            }
            return this.itemService.updateItem(item._id, { quantity });
        } else {
            return this.itemService.addToCurrentProject({
                name: '',
                description: '',
                notes: '',
                productId:
                    centerChoice === 'Center1year'
                        ? CENTER_LICENSE_PIAID_ONE_YEAR
                        : CENTER_LICENSE_PIAID_FIVE_YEAR,
                quantity,
                properties: {
                    systemComponent: {},
                },
            });
        }
    };

    public deleteCenterLicenses = () => {
        const deleteIds: IIdRev[] = [];
        const centerItemOneYear = this.getRecordingDeviceFromCurrentProject(
            CENTER_LICENSE_PIAID_ONE_YEAR,
        );
        const centerItemFiveYear = this.getRecordingDeviceFromCurrentProject(
            CENTER_LICENSE_PIAID_FIVE_YEAR,
        );
        if (centerItemOneYear) {
            deleteIds.push({ _id: centerItemOneYear._id, _rev: centerItemOneYear._rev });
        }
        if (centerItemFiveYear) {
            deleteIds.push({ _id: centerItemFiveYear._id, _rev: centerItemFiveYear._rev });
        }
        return this.itemService.deleteItems(deleteIds);
    };

    public addPartnerSystemComponents = async (components: IPartnerSystemComponent[]) => {
        const items = components.map((component) => this.toIItem(component));
        this.itemService.addItemsToCurrentProject(items);
    };

    public addPartnerItem = async (item: IPartnerRecordingListItem) => {
        const componentToAdd: IPartnerSystemComponent = {
            name: item.name,
            quantity: 1,
            vendorName: item.vendor,
            category: item.category,
            dataSheetUrl: item.dataSheetUrl,
            imageUrl: item.imageUrl,
            maxCameraCount: item.nrOfChannels,
            maxRecordingBandwidthBits: item.bandwidth,
            maxRecordingStorageMegaBytes: item.storage,
        };
        const itemToAdd = this.toIItem(componentToAdd);
        await this.itemService.addItemsToCurrentProject([itemToAdd]);
        eventTracking.logUserEvent(
            'System Components',
            `Add ${item.vendor} recording item`,
            `${item.name}`,
        );
    };

    public updateQuantity = async (productId: PiaId, quantity: number) => {
        const item = await this.getRecordingDeviceFromProject(productId);

        if (item) {
            this.itemService.updateItem(item._id, { quantity });
        }
    };

    public updatePartnerQuantity = async (name: string, quantity: number) => {
        const item = await this.getRecordingPartnerDeviceFromProject(name);

        if (item) {
            this.itemService.updateItem(item._id, { quantity });
        }
    };

    public replaceDevice = async (productId: PiaId, replacementPiaId: PiaId) => {
        const currentItem = this.getRecordingDeviceFromCurrentProject(productId);
        if (!currentItem) {
            return;
        }

        // Delete the item being replaced
        await this.itemService.deleteItem(currentItem._id);

        // add the new item with the same quantity as device we are replacing (1)
        return this.add(replacementPiaId, currentItem.quantity);
    };

    public updateELicensePreference = (
        preference: vmsLicenseType,
        selectedLicensesByType: ISelectedVmsByLicenseTypes,
        vmsLicensesByType: Record<string, IVmsByLicenseType>,
    ) => {
        const licensesToChange =
            preference === 'eLicense'
                ? selectedLicensesByType.paperLicenses
                : selectedLicensesByType.eLicenses;

        for (const license of licensesToChange) {
            const replacementLicense =
                vmsLicensesByType[getLicenseNameWithoutType(license.name)][preference];
            if (replacementLicense) {
                this.replaceDevice(license.id, replacementLicense.id);
            }
        }
    };

    public delete = (productId: PiaId) => {
        const item = this.getRecordingDeviceFromCurrentProject(productId);

        if (item) {
            return this.itemService.deleteItem(item._id);
        }

        return Promise.reject(`Could not find item with PiaId ${productId}`);
    };

    public deletePartnerItem = async (name: string) => {
        const item = await this.getRecordingPartnerDeviceFromProject(name);

        if (item) {
            return this.itemService.deleteItem(item._id);
        }

        return Promise.reject(`Could not find item with partner name ${name}`);
    };

    public clearCurrentSolution = async (
        currentSolutionId: RecordingSolutionType | undefined,
        newSolutionId?: RecordingSolutionType,
    ) => {
        // Remove existing sd-cards if moving from an sd-card solution, or existing cards when choosing the sd-card solution.
        if (
            newSolutionId === 'AxisCompanionSDCard' ||
            currentSolutionId === 'AxisCompanionSDCard'
        ) {
            await this.removeSDCardFromDevices();
        }

        const items = this.getCurrentSolutionItems();
        const itemIds = items.map(({ _id, _rev }) => ({
            _id,
            _rev,
        }));
        await this.itemService.deleteItems(itemIds);
        if (newSolutionId === 'Milestone' || newSolutionId === 'Genetec') {
            await this.projectService.updateCurrentProject({
                selectedCameraStationCenterType: 'NoCenter',
            });
        }
    };

    private toIItem = (component: IPartnerSystemComponent): IItem => {
        const item: IItem = {
            name: component.name,
            description: '',
            notes: '',
            productId: null,
            quantity: component.quantity,
            properties: {
                partnerSystemComponent: {
                    vendorName: component.vendorName,
                    category: component.category,
                    imageUrl: component.imageUrl,
                    dataSheetUrl: component.dataSheetUrl,
                    maxCameraCount: component.maxCameraCount,
                    maxRecordingBandwidthBits: component.maxRecordingBandwidthBits,
                    maxRecordingStorageMegaBytes: component.maxRecordingStorageMegaBytes,
                },
            },
        };
        return item;
    };

    private ensureVendorConsistency = (
        currentRecordingSolutionType: RecordingSolutionType,
        selectedVendorName: SelectedVendorType | undefined,
        viewingAcsType: CameraStationType | undefined,
    ) => {
        const selectedAcsType =
            this.currentProjectService.getProjectEntity().selectedCameraStationType;
        if (
            selectedVendorName === 'Axis' &&
            (currentRecordingSolutionType === 'Genetec' ||
                currentRecordingSolutionType === 'Milestone')
        ) {
            return false;
        }
        if (selectedVendorName === 'Genetec' && currentRecordingSolutionType !== 'Genetec') {
            return false;
        }
        if (selectedVendorName === 'Milestone' && currentRecordingSolutionType !== 'Milestone') {
            return false;
        }
        if (
            selectedVendorName === 'Axis' &&
            currentRecordingSolutionType !== 'Genetec' &&
            currentRecordingSolutionType !== 'Milestone'
        ) {
            if (selectedAcsType !== viewingAcsType) {
                return false;
            }
        }
        return true;
    };

    public replaceSolution = async (
        newSolution: IRecordingSolution,
        currentSolutionId: RecordingSolutionType | undefined,
        viewingAcsType: CameraStationType | undefined,
    ) => {
        const centerChoice =
            this.currentProjectService.getProjectEntity().selectedCameraStationCenterType;
        await this.clearCurrentSolution(currentSolutionId, newSolution.id);
        await this.projectService.updateCurrentProject({
            selectedCameraStationType: viewingAcsType,
        });
        if (newSolution.id === 'AxisCompanionSDCard') {
            const persistedCards = this.addSDCardsToDevices(newSolution.items);
            const itemsToAdd: {
                productId: number;
                quantity: number;
                accessories: IRecordingSolutionItem[] | undefined;
            }[] = [];

            const updatedSolution = newSolution.items
                .filter((item) => !this.isSDCard(item.piaId))
                .map((item) => {
                    const piaItem = this.productQueriesService.getPiaItem(item.piaId);
                    if (isSingleQuantityCategory(piaItem?.category)) {
                        return this.spreadQuantity(item).map((spreadItem) =>
                            itemsToAdd.push({
                                productId: spreadItem.piaId,
                                quantity: 1,
                                accessories: spreadItem.accessories,
                            }),
                        );
                    } else {
                        return itemsToAdd.push({
                            productId: item.piaId,
                            quantity: item.quantity,
                            accessories: item.accessories,
                        });
                    }
                });

            await this.bulkAdd(itemsToAdd);

            return Promise.all([...persistedCards, ...flatMap(updatedSolution)]);
        } else {
            const addItems = [];
            for (const item of newSolution.items) {
                const piaItem = this.productQueriesService.getPiaItem(item.piaId);
                if (isSingleQuantityCategory(piaItem?.category)) {
                    this.spreadQuantity(item).map((spreadItem) =>
                        addItems.push({
                            productId: spreadItem.piaId,
                            quantity: 1,
                            accessories: spreadItem.accessories,
                        }),
                    );
                } else {
                    addItems.push({
                        productId: item.piaId,
                        quantity: item.quantity,
                        accessories: item.accessories,
                    });
                }
            }
            await this.bulkAdd(addItems);
        }

        if (
            viewingAcsType === 'CameraStationPro' &&
            (centerChoice === 'Center1year' || centerChoice === 'Center5years')
        ) {
            const nbrOfLicenses = this.currentProjectService
                .getAllEntitiesOfType('item')
                .filter((item) => {
                    const piaItem = item.productId
                        ? this.productQueriesService.getPiaItem(item.productId)
                        : undefined;
                    return needsLicense(item, piaItem ?? undefined);
                })
                .reduce((totalCount, { quantity }) => (totalCount += quantity), 0);
            await this.addOrUpdateCenterLicense(nbrOfLicenses, centerChoice);
        }
    };

    public hasExistingSDCards = async () => {
        return (await this.getExistingSDCardsInAccessories()).length > 0;
    };

    private getNonSpeakerItems = () => {
        return this.currentProjectService
            .getAllEntitiesOfType('item')
            .filter((item) => !deviceTypeCheckers.isSpeaker(item));
    };

    private addSDCardsToDevices = (
        items: IRecordingSolutionItem[],
    ): Promise<IPersistence<IItemEntity>>[] => {
        const addCardsPromise: Promise<IPersistence<IItemEntity>>[] = [];
        const sdCards = items.filter((item) => this.isSDCard(item.piaId));
        // The SD-card items should have an array with non-speaker deviceIds who are supposed to get this card.
        sdCards.map((cardInfo) => {
            if (!cardInfo.deviceIds || cardInfo.deviceIds.length < 1) {
                Promise.reject();
            }
            uniq(cardInfo.deviceIds!).forEach((deviceId) => {
                addCardsPromise.push(
                    this.accessoryPersistenceService.setItem(deviceId, cardInfo.piaId, 'accessory'),
                );
            });
        });
        return addCardsPromise;
    };

    private isSDCard = (productId: PiaId) => {
        const piaItem = this.productQueriesService.getPiaItem(productId);
        return (
            piaItem &&
            piaItem.category === PiaAccessoryCategory.STORAGE &&
            (piaItem.properties as IPiaSystemComponentProperties).memoryCardSize
        );
    };

    private spreadQuantity = (item: IRecordingSolutionItem) => new Array(item.quantity).fill(item);

    /**
     * Checks if any item has an sd-card as accessory.
     * Speakers are excluded since they might have a sd-card for audio and not video storage.
     */
    private getExistingSDCardsInAccessories = async () => {
        const sdCards: IIdRev[] = [];
        const items = this.getNonSpeakerItems();
        items.map((item) => {
            const accessories = this.currentProjectService.getDeviceChildren(item._id, 'accessory');
            accessories.map(async (accessory) => {
                if (accessory.productId && this.isSDCard(accessory.productId)) {
                    sdCards.push({ _id: accessory._id, _rev: accessory._rev });
                }
            });
        });
        return sdCards;
    };

    private removeSDCardFromDevices = async () => {
        const existingSDCards = await this.getExistingSDCardsInAccessories();
        await this.itemService.deleteItems(existingSDCards);
    };

    private isPartnerItem(item: IRecordingListItem): item is IPartnerRecordingListItem {
        return (
            (item.vendor === 'genetec' || item.vendor === 'milestone') &&
            (item.category === PartnerSystemItemCategory.SERVER ||
                item.category === PartnerSystemItemCategory.MISC)
        );
    }

    private async askToClearSolution(selectedRecordingSolutionType: RecordingSolutionType) {
        const acceptClearSolution = await this.modalService.createConfirmDialog({
            header: t.replaceRecordingSolutionHeading,
            body: t.replaceRecordingSolutionMessage,
            confirmButtonText: t.proceed,
            cancelButtonText: t.cancel,
        })();
        if (acceptClearSolution) {
            await this.clearCurrentSolution(selectedRecordingSolutionType);
            await this.projectService.clearRecordingSolutionType();
            return true;
        }
        return false;
    }

    private async updateRecordingSolutionType(
        isSolutionEmpty: boolean,
        item: IRecordingListItem,
        viewingAcsType: CameraStationType | undefined,
    ) {
        if (
            !isSolutionEmpty &&
            item.piaItem?.category === PiaItemRecorderCategory.RECORDERS2 &&
            item.vendor === 'Axis'
        ) {
            // Adding an Axis server should clear the selected solution type
            await this.projectService.clearRecordingSolutionType();
        }

        if (item.vendor === 'Axis') {
            const selectedAcsType =
                this.currentProjectService.getProjectEntity().selectedCameraStationType;
            if (selectedAcsType !== viewingAcsType) {
                await this.projectService.updateCurrentProject({
                    selectedCameraStationType: viewingAcsType,
                });
            }
        }

        if (item.vendor === 'Milestone') {
            // Adding a Milestone item should set selected solution type to Milestone
            await this.projectService.updateCurrentProject({
                recordingSolutionType: 'Milestone',
                selectedCameraStationCenterType: 'NoCenter',
            });
        }

        if (item.vendor === 'Genetec') {
            // Adding a Genetec item should set selected solution type to Genetec
            await this.projectService.updateCurrentProject({
                recordingSolutionType: 'Genetec',
                selectedCameraStationCenterType: 'NoCenter',
            });
        }
    }

    private getAccessoryBaseItemModel(accessoryItem: IRecordingSolutionItem): IItem {
        return {
            name: accessoryItem.name,
            description: '',
            notes: '',
            productId: accessoryItem.piaId,
            quantity: accessoryItem.quantity,
            properties: {
                accessory: {},
            },
        };
    }

    private getCurrentSolutionItems = () =>
        this.currentProjectService
            .getAllEntitiesOfType('item')
            .filter(
                (item) => item.properties.systemComponent || item.properties.partnerSystemComponent,
            );

    private getRecordingDeviceFromCurrentProject = (productId: PiaId) => {
        return this.currentProjectService
            .getAllEntitiesOfType('item')
            .sort((itemA, itemB) =>
                // Sort by ascending date to get latest added/updated item
                new Date(itemA.updatedDate) > new Date(itemB.updatedDate) ? -1 : 1,
            )
            .find((item) => item.productId === productId);
    };

    // to use instead of getRecordingDeviceFromCurrentProject when time is critical
    // i.e when current project is not yet updated with correct state
    private getRecordingDeviceFromProject = async (productId: PiaId) => {
        const projectItems = await this.itemService.getAllProjectItems(
            this.currentProjectService.getProjectId(),
        );
        return projectItems.find((item) => item.productId === productId);
    };

    // to use instead of getRecordingDeviceFromCurrentProject when time is critical
    // i.e when current project is not yet updated with correct state
    private getRecordingPartnerDeviceFromProject = async (name: string) => {
        const projectItems = await this.itemService.getAllProjectItems(
            this.currentProjectService.getProjectId(),
        );
        return projectItems.find((item) => item.name === name);
    };
}
