import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { ProposalSummaryItem } from './ProposalSummaryItem.component';
import { Box, Border, Text, Spacer, Stack, Currency, CutCorner } from 'app/components';
import type { IProduct } from 'app/modules/common';
import {
    getCurrencyFromCountryCode,
    getLocaleFromCountryCode,
    getCurrentProjectLocation,
    getProductsWithPrices,
    getProductsExcludeAxisIncludedAcaps,
} from 'app/modules/common';
import type { ProposalCategory } from '../selectors/ProposalCategory.enum';
import {
    getProposalDeviceSummary,
    getInstallationServicesPrice,
    getProductTotalPrice,
    getAccessoriesTotalPrice,
    getSoftwareTotalPrice,
} from '../selectors/getProposalSummary';
import { t } from 'app/translate';
import { css } from '@emotion/css';
import { Headline } from './Headline.component';
import { ProposalSummaryCostLine } from './ProposalSummaryCostLine.component';
import { ReportHeader } from '../../components/ReportHeader.container';

const tableStyle = css`
    border-spacing: 0;

    tr {
        page-break-inside: avoid;
        border-collapse: collapse;
    }

    tr td {
        padding: 0;
        margin: 0;
    }

    tbody tr:first-of-type {
        page-break-after: avoid;
    }

    tbody tr td {
        padding-inline-end: 75px;
    }
`;

interface IProposalSummaryProps {
    summaryDevices: Array<{ proposalCategory: ProposalCategory; products: IProduct[] }>;
    currency: string;
    totalProductCost: number;
    locale: string;
    servicesCost: number;
    accessoriesCost: number;
    softwareCost: number;
}

const mapStateToProps = (storeState: IStoreState): IProposalSummaryProps => {
    const productsWithPrices = getProductsWithPrices(storeState);
    const productsExcludeAxisIncludedAcaps = getProductsExcludeAxisIncludedAcaps(
        storeState,
        productsWithPrices,
    );
    const summaryDevices = getProposalDeviceSummary(storeState, productsExcludeAxisIncludedAcaps);
    const location = getCurrentProjectLocation(storeState);
    const currency = getCurrencyFromCountryCode(location?.countryCode);
    const locale = getLocaleFromCountryCode(location?.countryCode);
    const servicesCost = getInstallationServicesPrice(storeState);
    const accessoriesCost = getAccessoriesTotalPrice(storeState);
    const softwareCost = getSoftwareTotalPrice(storeState, productsWithPrices);
    const totalProductCost = getProductTotalPrice(storeState);

    return {
        summaryDevices,
        currency,
        locale,
        servicesCost,
        totalProductCost,
        accessoriesCost,
        softwareCost,
    };
};

const ProposalSummaryContainer: React.FunctionComponent<IProposalSummaryProps> = ({
    summaryDevices,
    totalProductCost,
    currency,
    locale,
    servicesCost,
    accessoriesCost,
    softwareCost,
}) => {
    const translatedCategories = t.proposalSummaryCategoriesGROUP;

    return (
        <table className={tableStyle} width="100%">
            <thead>
                <tr>
                    <td>
                        <ReportHeader title={t.systemProposal} />
                    </td>
                </tr>
            </thead>
            {summaryDevices.map(
                (summaryItem, deviceIndex) =>
                    summaryItem.products.length > 0 && (
                        <tbody key={deviceIndex}>
                            <tr>
                                <td>
                                    <Headline
                                        text={translatedCategories[summaryItem.proposalCategory]}
                                    />
                                </td>
                            </tr>
                            {summaryItem.products.map((item, index) => (
                                <tr key={`${index}_${item.id}`}>
                                    <td>
                                        <ProposalSummaryItem product={item} />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td>
                                    <Spacer />
                                </td>
                            </tr>
                        </tbody>
                    ),
            )}
            <tbody>
                <tr>
                    <td>
                        <Stack vertical spacing="none">
                            <Headline text={t.proposalSummaryCategoriesGROUP.summary} />
                            <Border bottomWidth={1} color="warmGrey4">
                                <Stack vertical spacing="none">
                                    <ProposalSummaryCostLine
                                        text={t.quotationProducts}
                                        cost={totalProductCost}
                                    />
                                    {accessoriesCost > 0 && (
                                        <ProposalSummaryCostLine
                                            text={`${t.proposalSummaryCategoriesGROUP.hardwareAccessories} *`}
                                            cost={accessoriesCost}
                                        />
                                    )}
                                    {softwareCost > 0 && (
                                        <ProposalSummaryCostLine
                                            text={`${t.proposalSummaryCategoriesGROUP.software} *`}
                                            cost={softwareCost}
                                        />
                                    )}
                                    {servicesCost > 0 && (
                                        <ProposalSummaryCostLine
                                            text={`${t.quotationServices} *`}
                                            cost={servicesCost}
                                        />
                                    )}
                                </Stack>
                            </Border>
                            <ProposalSummaryCostLine
                                text={t.total}
                                cost={
                                    totalProductCost + accessoriesCost + softwareCost + servicesCost
                                }
                            />
                            <Box direction="column" alignItems="end" paddingTop="doublePanel">
                                <CutCorner color="yellow">
                                    <Box
                                        paddingLeft="doublePanel"
                                        paddingX="panel"
                                        paddingY="panel"
                                        minWidth={500}
                                        justifyContent="between"
                                        direction="column"
                                    >
                                        <Border bottomWidth={1} color="blackOpacity">
                                            <Stack justifyContent="between">
                                                <Text style="headline" color="grey9">
                                                    {t.total}:
                                                </Text>
                                                <Text style="headline" color="grey9">
                                                    <Currency
                                                        value={
                                                            totalProductCost +
                                                            accessoriesCost +
                                                            softwareCost +
                                                            servicesCost
                                                        }
                                                        locale={locale}
                                                        currency={currency}
                                                        showCurrency
                                                    />
                                                </Text>
                                            </Stack>
                                        </Border>
                                        <Spacer spacing="half" />
                                    </Box>
                                </CutCorner>
                                <Box
                                    direction="column"
                                    minWidth={500}
                                    paddingLeft="panel"
                                    paddingTop="base"
                                >
                                    <Text style="heading" color="warmGrey6">
                                        {t.quotationExcludingTaxes(currency)}
                                    </Text>
                                    <Text style="heading" color="warmGrey6">
                                        {t.salesQuoteDetails}
                                    </Text>
                                </Box>
                            </Box>
                        </Stack>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export const ProposalSummary = connect(mapStateToProps)(ProposalSummaryContainer);

ProposalSummary.displayName = 'ProposalSummary';
