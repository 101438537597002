/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import 'leaflet-rotatedmarker';
import 'leaflet-textpath';
import 'leaflet-draw';
import { useModifierKeysDown } from '../../hooks';
import { css } from '@emotion/css';
import { MapButton } from './MapButton';
import { MapScalingTool } from '../mapScalingTool';
import { MapMeasureTool } from '../mapMeasureTool';
import { AppConstants } from 'app/AppConstants';
import { Box, MessageModal } from 'app/components';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../services';
import { getItemLocalStorage, type Id } from 'app/core/persistence';
import {
    getIsMeasureToolActive,
    getErrorMessage,
    getSelectedMapOrDefault,
    getShowGeoLocationTool,
    getDefaultGeoMap,
} from '../../selectors';
import { InstallationPointSensor3dView } from './InstallationPointSensor3dView';
import { LeafletMenu } from './LeafletMenu';
import { MapRadarWarning } from '../mapInfoMessage';
import { FollowMouseText } from './cone/FollowMouseText';
import { InstallationPoints } from './InstallationPoints';
import { Blockers } from './Blockers';
import { FloorPlans, GeolocationTool } from './geoLocatedFloorPlans';
import { FreeTexts } from './FreeTexts';
import { MapProvider } from '../context';
import { t } from 'app/translate';
import { useMount } from 'app/hooks';
import { MapMenu } from '../mapMenu';
import { getIsScalingToolEnabled } from '../../selectors/getIsScalingToolEnabled';
import { MapWelcome } from '../mapWelcome/MapWelcome';
import { isGeoLocated, isGeoMap } from 'app/modules/common';
import { MapLocations } from '../mapLocations/MapLocations';
import { FloorPlanConfigOverlayPanel } from '../overlaypanel/FloorPlanConfigOverlayPanel';
import { MapTabs } from '../mapTabs/MapTabs';
import { SetGeoLocationModal } from './geoLocatedFloorPlans/SetGeoLocationModal';
import { eventTracking } from 'app/core/tracking';
import { MapTypeButton } from './MapTypeButton';
import { LayersButton } from '../layers/LayersButton';
import { ActiveLayerFilters } from '../layers/ActiveLayerFilters';

const mapUIStyle = css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: ${AppConstants.mapUIDepth};
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    & * {
        pointer-events: all;
    }

    @media print {
        display: none;
    }
`;

interface IMapComponentProps {
    showWelcome: boolean;
    /** Sets the map to read only
     * Hides controls and menu.
     * Disables interaction with markers and cones.
     * Labels are still possible to move.
     */
    readOnly?: boolean;
}

export const Map: React.FunctionComponent<IMapComponentProps> = ({
    showWelcome: showWelcomeScreen,
    readOnly,
}) => {
    const mapsActionService = useService(MapsActionService);

    const currentFloorPlan = useSelector(getSelectedMapOrDefault);
    const defaultGeoMap = useSelector(getDefaultGeoMap);
    const selectedFloorPlan = currentFloorPlan ?? defaultGeoMap;
    /** Hook up the modifier key listening */
    useModifierKeysDown();

    const [showWelcome, setShowWelcome] = React.useState<boolean>(showWelcomeScreen);
    const [showingGeoLocationModal, setShowingGeoLocationModal] = React.useState<boolean>(false);
    const [mapIdToGeoLocate, setMapIdToGeoLocate] = React.useState<Id | undefined>();

    const errorMessage = useSelector(getErrorMessage);
    const isMeasureToolActive = useSelector(getIsMeasureToolActive);
    const enableScalingTool = useSelector(getIsScalingToolEnabled);
    const enableGeoLocationTool = useSelector(getShowGeoLocationTool);

    const closeMapWelcome = () => {
        setShowWelcome(false);
    };

    const showGeoLocationModal = () => {
        if (!showingGeoLocationModal && defaultGeoMap?._id) {
            setMapIdToGeoLocate(currentFloorPlan?._id);
            mapsActionService.setSelectedMap(defaultGeoMap?._id);
            setShowingGeoLocationModal(true);
            eventTracking.logUserEvent('Maps', 'Geolocate floor plan');
        }
    };

    const goToGeolocation = () => {
        setShowingGeoLocationModal(false);
    };

    const cancelSetGeoLocation = () => {
        mapIdToGeoLocate && mapsActionService.setSelectedMap(mapIdToGeoLocate);
        setShowingGeoLocationModal(false);
        setMapIdToGeoLocate(undefined);
    };

    useMount(
        () => {
            const versionShown = Number(getItemLocalStorage('MapsHelpVideoShownVersion'));
            !showWelcomeScreen &&
                (Number.isNaN(versionShown) || versionShown < 1) &&
                mapsActionService.toggleGetToKnowMaps(true);
        },
        () => {
            mapsActionService.close3dView();
            mapsActionService.toggleMeasureTool(false);
            mapsActionService.toggleDeviceOverlayPanel(false);
        },
    );
    const hasNoImageBounds = selectedFloorPlan?.image && !selectedFloorPlan.image?.bounds;
    const isFloorPlanGeoLocated = selectedFloorPlan && isGeoLocated(selectedFloorPlan);

    const mapTopBar = (
        <Box noPointerEvents paddingTop="base" spacing="half" direction="column" maxWidth="100%">
            <Box testId="test_buttons" noPointerEvents spacing="half" justifyContent="center">
                {!isGeoMap(selectedFloorPlan) && !readOnly && (
                    <>
                        <MapButton
                            key="geolocation"
                            text={isFloorPlanGeoLocated ? t.goToGeolocation : t.setGeolocation}
                            icon="satellite"
                            disabled={enableScalingTool || hasNoImageBounds}
                            onClick={() =>
                                isFloorPlanGeoLocated
                                    ? mapsActionService.goToGeolocation(selectedFloorPlan._id)
                                    : showGeoLocationModal()
                            }
                        />
                        <MapButton
                            key="settings"
                            text={t.settings}
                            icon="settings"
                            onClick={() => mapsActionService.toggleFloorPlanConfigOverlayPanel()}
                        />
                    </>
                )}
                {selectedFloorPlan?.isDefault && <MapLocations hideAddButton={readOnly} />}
            </Box>

            <Box
                noPointerEvents
                spacing="half"
                justifyContent="center"
                __htmlAttributes={{ style: { paddingInline: '75px' } }}
            >
                <ActiveLayerFilters />
            </Box>
        </Box>
    );

    return (
        <>
            {errorMessage && (
                <MessageModal
                    title={t.projectLocked}
                    message={t.addNotAllowed}
                    buttonText={t.close}
                    onClose={() => mapsActionService.setErrorMessage(false)}
                />
            )}

            {!readOnly && !showWelcome && <MapMenu />}

            <FloorPlanConfigOverlayPanel />

            <Box
                display="grid"
                position="relative"
                grid={{ gridTemplateRows: 'min-content 1fr' }}
                width="100%"
                height="100%"
            >
                {readOnly ? <Box /> : <MapTabs />}
                <MapProvider floorPlan={selectedFloorPlan} readOnly={readOnly}>
                    {showingGeoLocationModal && mapIdToGeoLocate && (
                        <SetGeoLocationModal
                            mapId={mapIdToGeoLocate}
                            onCancel={cancelSetGeoLocation}
                            onGoToBounds={goToGeolocation}
                        />
                    )}
                    <div className={mapUIStyle}>
                        <Box
                            direction="column"
                            justifyContent="between"
                            alignItems="center"
                            flex="grow"
                            maxWidth="100%"
                            noPointerEvents
                        >
                            {mapTopBar}

                            {showWelcome && <MapWelcome onClose={closeMapWelcome} />}

                            <Box paddingBottom="doublePanel">
                                {(enableScalingTool || hasNoImageBounds) && <MapScalingTool />}
                                {enableGeoLocationTool && (
                                    <GeolocationTool onCancel={cancelSetGeoLocation} />
                                )}
                                <MapRadarWarning />
                            </Box>
                        </Box>
                    </div>

                    {/* Leaflet elements */}
                    {!enableGeoLocationTool && (
                        <>
                            <InstallationPoints />
                            <FloorPlans />
                            <Blockers />
                            <FreeTexts />
                        </>
                    )}
                    <LeafletMenu readOnly={readOnly} />
                    {/* End of leaflet elements */}

                    {/* Absolute positioned elements */}
                    {isMeasureToolActive && !enableScalingTool && <MapMeasureTool showLabel />}
                    <MapTypeButton />
                    <LayersButton />
                    <InstallationPointSensor3dView />
                    <FollowMouseText />
                    {/* End of absolute positioned elements */}
                </MapProvider>
            </Box>
        </>
    );
};

Map.displayName = 'Map';
