import type { Colors } from 'app/styles';
import type { DeviceType } from 'app/core/persistence';

export function isDeviceFilteredOut(
    deviceType: DeviceType | undefined,
    deviceColor: Colors | undefined,
    filteredDeviceTypes: DeviceType[],
    filteredDeviceColors: Colors[],
) {
    const isTypeFilteredOut = deviceType !== undefined && filteredDeviceTypes.includes(deviceType);
    const isColorFilteredOut =
        deviceColor !== undefined && filteredDeviceColors.includes(deviceColor);

    return isTypeFilteredOut || isColorFilteredOut;
}
