import type { SpeakerPlacement } from '../../../models/item/SpeakerPlacement';

export interface ISpeakerPropertiesFilterEntity {
    placement: SpeakerPlacement | undefined;
    outdoor: boolean;
    basicSolution: boolean;
    installationHeight: number;
    listeningArea?: number;
    wallLength?: number;
    isFilterChanged: boolean;
}

export const defaultSpeakerFilter: ISpeakerPropertiesFilterEntity = {
    placement: undefined,
    outdoor: false,
    basicSolution: true,
    installationHeight: 3,
    listeningArea: undefined,
    wallLength: undefined,
    isFilterChanged: false,
};
