import { injectable } from 'inversify';
import type * as leaflet from 'leaflet';
import { AppStore } from 'app/store';
import { eventTracking } from 'app/core/tracking';
import { PiaItemPagingConsoleCategory, PiaItemService } from 'app/core/pia';
import type { IPiaItem, PiaId } from 'app/core/pia';
import {
    CamerasService,
    ChildItemService,
    desiredCameraUtils,
    DoorControllerService,
    EncodersService,
    getNewDeviceNameFromCategory,
    isGeoLocated,
    productFilters,
    OtherDevicesService,
    MainUnitsService,
    SpeakersService,
    TwoNDevicesService,
} from 'app/modules/common';
import type {
    IItemEntity,
    ILatLng,
    IFloorPlanEntity,
    IPersistence,
    Id,
} from 'app/core/persistence';
import {
    defaultMainUnitFilter,
    InstallationPointService,
    getDefaultProfileOverrideEntity,
    CurrentProjectService,
    ItemService,
    FloorPlanRepository,
    defaultEncoderFilter,
    getDefaultSpeakerFilterEntity,
    isRelayExpansionModule,
} from 'app/core/persistence';
import { MapsActionService } from './MapsAction.service';
import { defaultColors } from 'app/core/common';
import { t } from 'app/translate';
import type { IMapsDragDropData, DropType } from '../models/IDropData';
import { isDefined } from 'axis-webtools-util';
import { toRecord } from 'app/core/persistence/services/serviceUtils';
import { MapDeviceService } from './MapDevice.service';
import { isSupportedDevice } from '../utils';
import { getRotationTransform, getOriginFilter } from '../selectors';

@injectable()
export class ItemDropService {
    constructor(
        private itemService: ItemService,
        private floorPlanRepository: FloorPlanRepository,
        private currentProjectService: CurrentProjectService,
        private camerasService: CamerasService,
        private mainUnitsService: MainUnitsService,
        private encodersService: EncodersService,
        private speakersService: SpeakersService,
        private otherDevicesService: OtherDevicesService,
        private twoNDevicesService: TwoNDevicesService,
        private doorControllerService: DoorControllerService,
        private mapsActionService: MapsActionService,
        private installationPointService: InstallationPointService,
        private mapDeviceService: MapDeviceService,
        private childItemService: ChildItemService,
        private piaItemService: PiaItemService<IPiaItem>,
        private appStore: AppStore,
    ) {}

    public async handleItemDropped(
        dragDropData: IMapsDragDropData,
        geoLocation: leaflet.LatLng,
        selectedFloorPlanId: Id,
        mapImageRotationDegrees: number,
    ) {
        const shouldCreateNewDevice = !dragDropData.id;

        // Check device id or create one
        if (shouldCreateNewDevice) {
            this.checkIsLocked();
            switch (dragDropData.type) {
                case 'camera':
                    dragDropData.id = (await this.addCamera(dragDropData.piaId)) || '';
                    break;
                case 'mainUnit':
                    dragDropData.id = (await this.addMainUnit(dragDropData.piaId)) || '';
                    break;
                case 'encoder':
                    dragDropData.id = (await this.addEncoder(dragDropData.piaId)) || '';
                    break;
                case 'sensorUnit':
                case 'analogCamera':
                case 'door':
                case 'relayexpmodules':
                    // We do nothing here, but a generic sensor/analog camera unit will be created before creating the installation point.
                    // If creating one here, there is no way to determine if it is a new generic or another instance of a generic sensor unit
                    // when creating the installation point.
                    break;
                case 'speaker':
                    dragDropData.id = (await this.addSpeaker(dragDropData.piaId)) || '';
                    break;
                case 'radardetector':
                    dragDropData.id = (await this.addRadarDetector(dragDropData.piaId)) || '';
                    break;
                case 'doorcontroller':
                    dragDropData.id = (await this.addDoorController(dragDropData.piaId)) || '';
                    break;
                case 'peopleCounter':
                    dragDropData.id =
                        (await this.addDevice(t.newPeopleCounter, dragDropData.piaId)) || '';
                    break;
                case 'alerter':
                    dragDropData.id =
                        (await this.addDevice(t.newAudioVisualAlerter, dragDropData.piaId)) || '';
                    break;
                case 'connectivitydevice':
                    dragDropData.id =
                        (await this.addDevice(t.newConnectivityDevice, dragDropData.piaId)) || '';
                    break;
                case 'pagingconsole':
                    dragDropData.id =
                        (await this.addDevice(t.newPagingConsole, dragDropData.piaId)) || '';
                    break;
                case 'decoder':
                    dragDropData.id =
                        (await this.addDevice(t.newDecoder, dragDropData.piaId)) || '';
                    break;
                case 'systemController':
                    dragDropData.id =
                        (await this.addDevice(t.newSystemController, dragDropData.piaId)) || '';
                    break;
                case 'dockingStation':
                    dragDropData.id =
                        (await this.addDevice(t.newDockingStation, dragDropData.piaId)) || '';
                    break;
                case 'pac':
                case 'doorstation':
                    const piaItem =
                        dragDropData.piaId && this.piaItemService.get(dragDropData.piaId).first();
                    const newName = piaItem
                        ? getNewDeviceNameFromCategory(piaItem.category)
                        : t.newAccessServer;
                    dragDropData.id = (await this.addDevice(newName, dragDropData.piaId)) || '';
                    break;
                default:
                    throw new Error(
                        `Generic device of type: ${dragDropData.type} is not supported in maps`,
                    );
            }
        }

        eventTracking.logUserEvent(
            'Maps',
            'Add installation point',
            dragDropData.id
                ? `Existing device: ${dragDropData.type}`
                : `Generic device: ${dragDropData.type}`,
        );

        if (dragDropData.type === 'floorPlan') {
            await this.addFloorPlan(dragDropData, {
                lat: geoLocation.lat,
                lng: geoLocation.lng,
            });
        } else {
            // Save as new installation point
            await this.addInstallationPoint(
                dragDropData,
                {
                    lat: geoLocation.lat,
                    lng: geoLocation.lng,
                },
                selectedFloorPlanId,
                mapImageRotationDegrees,
            );
        }
    }

    private async addCamera(piaId?: PiaId) {
        const camera = await this.camerasService.addCameraWithDefaults(piaId);
        return camera._id;
    }

    private async addMainUnit(piaId?: PiaId) {
        const addedMainUnit = await this.mainUnitsService.addOrUpdateDevice(
            piaId ?? null,
            defaultMainUnitFilter,
            undefined,
            {
                name: t.mainUnitSelectorNewMainUnit,
                quantity: 1,
                color: defaultColors.DEFAULT_DEVICE_COLOR,
            },
        );
        return addedMainUnit && addedMainUnit._id;
    }

    private async addEncoder(piaId?: PiaId) {
        const defaultProfileId = this.currentProjectService.getProjectDefaultProfile();
        const addedEncoder = await this.encodersService.addOrUpdateDevice(
            piaId ?? null,
            defaultEncoderFilter,
            defaultProfileId,
            undefined,
            undefined,
            {
                name: t.encoderSelectorNewEncoder,
                quantity: 1,
                color: defaultColors.DEFAULT_DEVICE_COLOR,
            },
        );
        return addedEncoder?._id;
    }

    private async addSpeaker(piaId?: PiaId) {
        const currentProjectDefaultSpeakerFilter = getDefaultSpeakerFilterEntity(
            this.currentProjectService.getProjectCustomInstallationHeight(),
        );
        const addedSpeaker = await this.speakersService.addSpeaker(
            piaId ?? null,
            {
                name: t.speakerSelectorNewSpeaker,
                quantity: 1,
                color: defaultColors.DEFAULT_SPEAKER_COLOR,
            },
            currentProjectDefaultSpeakerFilter,
        );
        return addedSpeaker?._id;
    }

    private async addRadarDetector(piaId?: PiaId) {
        const addedRadarDetector = await this.otherDevicesService.addRadar(
            piaId ?? null,
            1,
            t.newRadar,
        );
        return addedRadarDetector?._id;
    }

    private async addDoorController(piaId?: PiaId) {
        const addedDoorController = await this.doorControllerService.addOrUpdateDevice(
            piaId ?? null,
            undefined,
            {
                name: t.newDoorController,
                quantity: 1,
                color: defaultColors.DEFAULT_DOOR_CONTROLLER_COLOR,
            },
            false,
        );

        return addedDoorController?._id;
    }

    private async addDevice(name: string, piaId?: PiaId): Promise<Id | undefined> {
        const piaItem = piaId && this.piaItemService.get(piaId).first();
        if (!piaItem) return;

        if (productFilters.isVendorAxis(piaItem.properties.vendor)) {
            const addedDevice = await this.otherDevicesService.addOrUpdateDevice(
                piaId,
                piaItem.category,
                piaItem.categories,
                this.currentProjectService.getProjectDefaultProfile(),
                [],
                undefined,
                {
                    name,
                    quantity: 1,
                    color:
                        piaItem.category === PiaItemPagingConsoleCategory.PAGINGCONSOLE
                            ? defaultColors.DEFAULT_SPEAKER_COLOR
                            : 'devicePalette7',
                },
            );
            return addedDevice?._id;
        } else if (productFilters.isVendor2N(piaItem.properties.vendor)) {
            const addedDevice = await this.twoNDevicesService.addOrUpdateDevice(
                piaId,
                piaItem.category,
                undefined,
                { name, quantity: 1, color: 'devicePalette7' },
            );
            return addedDevice?._id;
        }
    }

    private async addInstallationPoint(
        droppedItemData: IMapsDragDropData,
        rawLocation: ILatLng,
        mapId: Id,
        mapImageRotationDegrees: number,
    ): Promise<void> {
        const state = this.appStore.Store.getState();
        const transform = getRotationTransform(state);
        const originFilter = getOriginFilter(state);
        const location = transform(rawLocation);

        let item = await this.getDroppedItem(droppedItemData.id);

        const map = await this.floorPlanRepository.get(mapId);

        const isMapGeolocated = isGeoLocated(map);

        if (droppedItemData.type === 'floorPlan') {
            return;
        }

        const mapOrigin = originFilter ?? mapId;

        if (!item) {
            // This is a new generic child unit not yet created.
            if (!droppedItemData.parentId) {
                throw new Error('No parentId provided when adding new generic child unit');
            }

            const id = await this.addChildUnit(
                droppedItemData.parentId,
                droppedItemData.type,
                droppedItemData.piaId,
            );

            item = await this.itemService.getItem(id);

            await this.installationPointService.addInstallationPointFromType(
                item,
                mapOrigin,
                location,
                droppedItemData.type,
                {
                    global: isMapGeolocated,
                },
                mapImageRotationDegrees,
            );
        } else {
            const result = await this.canIncreaseQuantity(item);
            if (!result) {
                return;
            }

            let lenses;
            if (item.properties.camera) {
                const relations = this.currentProjectService.getAllEntitiesOfType('itemRelation');
                const currentProjectItems = toRecord(
                    this.currentProjectService.getAllEntitiesOfType('item'),
                    '_id',
                );
                lenses = relations
                    .filter(
                        (relation) =>
                            relation.relationType === 'lenses' && relation.parentId === item?._id,
                    )
                    .map((lensRelation) => currentProjectItems[lensRelation.childId])
                    .filter(isDefined);
            }

            await this.installationPointService.addInstallationPointFromType(
                item,
                mapOrigin,
                location,
                droppedItemData.type,
                {
                    global: isMapGeolocated,
                    lenses,
                },
                mapImageRotationDegrees,
            );
        }
    }

    private async addFloorPlan(
        droppedItemData: IMapsDragDropData,
        location: ILatLng,
    ): Promise<void> {
        if (droppedItemData.type !== 'floorPlan') {
            return;
        }

        const floorPlanItem = (await this.getDroppedItem(
            droppedItemData.id,
        )) as unknown as IFloorPlanEntity; //TODO investigate if there is a better way!

        if (floorPlanItem) {
            this.mapsActionService.setFloorPlanLocation(floorPlanItem, location);
        }
    }

    private async getDroppedItem(id: Id): Promise<IPersistence<IItemEntity> | undefined> {
        try {
            return await this.itemService.getItem(id);
        } catch (e: any) {
            if (e.message === 'missing') {
                return undefined;
            }
            throw e;
        }
    }

    private async addChildUnit(parentId: Id, type: DropType, piaId?: PiaId) {
        let childUnit;
        switch (type) {
            case 'sensorUnit':
                childUnit = await this.addGenericSensorUnit(parentId);
                break;
            case 'analogCamera':
                childUnit = await this.childItemService.addAnalogCamera(parentId);
                break;
            case 'door':
                childUnit = await this.childItemService.addDoor(parentId);
                break;
            case 'relayexpmodules':
                childUnit = await this.childItemService.addRelayExpansionModule(parentId, piaId);
                break;
            default:
                throw new Error(`Unsupported generic child unit: ${type}`);
        }

        return childUnit._id;
    }

    private async addGenericSensorUnit(parentId: string) {
        const desiredCamera = desiredCameraUtils.convertPropertiesToDesiredCamera(
            this.currentProjectService.getProjectDefaultCameraFilter(),
        );
        const sensorUnit = await this.itemService.addByParentId(parentId, {
            productId: null,
            name: t.cameraSelectorNewSensorUnit,
            description: '',
            notes: '',
            quantity: 1,
            properties: {
                sensorUnit: {
                    filter: desiredCameraUtils.convertDesiredCameraToFilterProperties(
                        desiredCamera,
                        this.currentProjectService.getProjectCustomInstallationHeight(),
                        null,
                        this.currentProjectService.getProjectUnitSystem(),
                    ),
                    associatedProfile: this.currentProjectService.getProjectDefaultProfile(),
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
            },
        });

        await this.itemService.addItemRelation(parentId, sensorUnit._id, 'sensorUnit');
        return sensorUnit;
    }

    private async canIncreaseQuantity(device: IPersistence<IItemEntity>): Promise<boolean> {
        const isLocked = this.currentProjectService.getIsProjectLocked();
        const piaItem = device.productId
            ? this.piaItemService.get(device.productId).first()
            : undefined;

        if (isSupportedDevice(device, piaItem)) {
            const projectInstallationPoints =
                this.currentProjectService.getAllEntitiesOfType('installationPoint');
            const result = await this.mapDeviceService.canIncreaseDeviceQuantity(
                device,
                projectInstallationPoints,
                isLocked,
            );
            if (!result) {
                this.mapsActionService.setErrorMessage(true);
            }
            return result;
        } else if (
            device.properties.sensorUnit ||
            device.properties.analogCamera ||
            isRelayExpansionModule(piaItem)
        ) {
            if (!isLocked) {
                return this.mapDeviceService.increaseChildItemQuantity(device);
            } else {
                this.mapsActionService.setErrorMessage(true);
            }
        } else {
            throw new Error(`Device not supported: ${device}`);
        }
        return false;
    }

    private checkIsLocked() {
        const isLocked = this.currentProjectService.getIsProjectLocked();
        if (isLocked) {
            this.mapsActionService.setErrorMessage(true);
            throw new Error('Cannot add items to locked project');
        }
    }
}
