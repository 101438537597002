import { injectable } from 'inversify';
import PouchDB from 'pouchdb-browser';
import { eventTracking } from 'app/core/tracking';
import { ResourceGroupId, Arn } from '../../models';
import { PersistenceDatabaseRepository, IEntity, IIdRev } from '../../userDataPersistence';
import { CleanupService } from '../Cleanup.service';
import { IPersistenceConfigurationService } from '../IPersistenceConfigurationService';
import { ProjectModelService } from '../ProjectModel.service';
import { StorageMigrationService } from '../storageMigration';
import { SharedReplicationService } from './SharedReplication.service';
import { EventEmitter } from 'events';
import { EventEmitterService } from '../EventEmitter.service';

@injectable()
export class SharedPersistenceConfigurationService implements IPersistenceConfigurationService {
    private localDb: PouchDB.Database | undefined;
    private emitter: EventEmitter;
    private selectedResourceGroupId: ResourceGroupId | undefined;
    private changesEventListener: PouchDB.Core.Changes<{}> | null = null;

    constructor(
        private storageMigrationService: StorageMigrationService,
        private replicationService: SharedReplicationService,
        private persistenceRepository: PersistenceDatabaseRepository,
        private projectModelService: ProjectModelService,
        private cleanupService: CleanupService,
        private eventEmitterService: EventEmitterService,
    ) {
        this.emitter = this.eventEmitterService.getEventEmitter('sharedprojects');
    }

    public getDbOrigin() {
        return this.localDb?.name as ResourceGroupId;
    }

    public async initialize(resourceGroupId: ResourceGroupId) {
        await this.storageMigrationService.triggerMigrationIfRequired();
        this.localDb = await this.initDatabase(resourceGroupId);

        const oldDataWasCleared = await this.cleanupService.clearData(resourceGroupId);
        if (oldDataWasCleared) {
            // Re-initialize since cleanup removed the database
            await this.initialize(resourceGroupId);
            return;
        }

        await this.replicationService.setupReplication(this.localDb, this.emitter);
        const deletedDocsCount = await this.persistenceRepository.localDbIndexCleanup();
        if (deletedDocsCount > 0) {
            eventTracking.logApplicationEvent(
                'Persistence',
                'Local db cleanup',
                'Deleted indexes',
                deletedDocsCount,
            );
        }
    }

    public async initDatabase(resourceGroupId: Arn) {
        const currentDb = this.persistenceRepository.getDbName();
        if (resourceGroupId === currentDb && this.localDb) {
            return this.localDb;
        }

        if (this.localDb) {
            // Clear the current database before initializing a new one
            await this.clearLocalDatabase();
        }

        this.localDb = new PouchDB(resourceGroupId, {
            auto_compaction: true,
        });
        this.addChangesEventListener();
        await this.persistenceRepository.initialize(this.localDb, this.emitter);
        this.selectedResourceGroupId = resourceGroupId;
        return this.localDb;
    }

    public async syncAll() {
        return this.replicationService.syncAll();
    }

    /**
     * Clear the local database
     * @returns a promise that resolves when the database is cleared
     */
    public async clearLocalDatabase() {
        if (!this.localDb) {
            return;
        }
        await this.localDb.destroy();
        this.removeChangesEventListener();
        this.localDb = undefined;
        return this.persistenceRepository.clear();
    }

    private addChangesEventListener() {
        if (!this.localDb) {
            return;
        }
        // Remove existing listener
        this.removeChangesEventListener();

        this.changesEventListener = this.localDb
            .changes({ live: true, include_docs: true, since: 'now' })
            .on('change', (change) => {
                if (!change.doc) {
                    return;
                }

                if (change.deleted) {
                    this.projectModelService.handleEntityDelete(
                        change.doc as IIdRev,
                        this.selectedResourceGroupId,
                    );
                } else {
                    this.projectModelService.handleEntityUpdate(change.doc as IEntity);
                }
            });
    }

    private removeChangesEventListener() {
        if (this.changesEventListener) {
            this.changesEventListener.cancel();
            this.changesEventListener = null;
        }
    }
}
