import { countUses, creationDateReverseComparator, idComparator } from 'app/utils';
import { injectable } from 'inversify';
import type {
    PersistenceMemoryRepository,
    IEntity,
    IProjectEntity,
    Id,
    IScheduleEntity,
    ITimeSerieEntity,
    IProfileEntity,
    IItemEntity,
    IPersistence,
    IItemPropertiesEntity,
    IProfileOverridePropertiesEntity,
    IRecordingSettingsEntity,
    IScenarioEntity,
    IItemRelationEntity,
    ICameraPropertiesFilterEntity,
    IIdRev,
    ICustomItemEntity,
    IBaseEntity,
    IProjectQuotationEntity,
    IFloorPlanEntity,
    IInstallationPointEntity,
    IFloorPlanExportEntity,
    IFreeTextPointEntity,
    ICustomCameraPropertiesEntity,
    ProjectDbOrigin,
    IPartnerItemEntity,
    IPartnerItemPropertiesEntity,
    IBlockerEntity,
    IMapLocationEntity,
    ICameraItemEntity,
} from '../../userDataPersistence';
import {
    defaultDockingStationFilter,
    defaultCameraExtensionFilter,
    ProjectRepository,
    ProfileRepository,
    ScheduleRepository,
    FreeTextPointRepository,
    TimeSerieRepository,
    ItemRepository,
    PersistenceDatabaseRepository,
    ItemRelationRepository,
    CustomItemRepository,
    RepairService,
    ProjectQuotationRepository,
    FloorPlanRepository,
    InstallationPointRepository,
    QuotationProgress,
    PartnerItemRepository,
    BlockerRepository,
    MapLocationRepository,
} from '../../userDataPersistence';

import { deviceTypeCheckers, getParentId } from '../../utils';
import { FloorPlanService } from '../FloorPlan.service';
import { IP_LABEL_OFFSET } from '../InstallationPointDevice.service';
import { CurrentProjectService } from '../CurrentProject.service';
import { ItemService } from '../item';
import { isEqual, omit } from 'lodash-es';

import { toRecord } from '../serviceUtils';
import { ProjectService } from '../Project.service';
import { IPv4, Validator } from 'ip-num';
import { NameGenerationService } from '../NameGeneration.service';
import { AppConstants } from 'app/AppConstants';

type IdMap = Record<Id, IIdRev>;
type ImportEntity = IPersistence<IBaseEntity>;

@injectable()
export class EntityImportService {
    constructor(
        private projectRepository: ProjectRepository,
        private projectQuotationRepository: ProjectQuotationRepository,
        private profileRepository: ProfileRepository,
        private scheduleRepository: ScheduleRepository,
        private timeSerieRepository: TimeSerieRepository,
        private itemRepository: ItemRepository,
        private customItemRepository: CustomItemRepository,
        private databaseRepository: PersistenceDatabaseRepository,
        private itemRelationRepository: ItemRelationRepository,
        private partnerItemRepository: PartnerItemRepository,
        private repairService: RepairService,
        private floorPlanRepository: FloorPlanRepository,
        private freeTextPointRepository: FreeTextPointRepository,
        private installationPointRepository: InstallationPointRepository,
        private floorPlanService: FloorPlanService,
        private blockerRepository: BlockerRepository,
        private mapLocationRepository: MapLocationRepository,
        private currentProjectService: CurrentProjectService,
        private itemService: ItemService,
        private projectService: ProjectService,
        private nameGenerationService: NameGenerationService,
    ) {}

    /**
     * Imports a project and all of its entities. Before importing all data will be migrated
     * to the latest version if required.
     *
     * @param importProjectId the id of the project to import, stored in the provided memory repository.
     * @param currentProjectToImportTo the current project to import into. If undefined it is an ordinary import and no merge
     * @param memoryRepository repository containing the project and all of its children and related entities.
     * @param projectName the name to set to the imported project.
     */
    public async importProject(
        importProjectId: Id,
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
        memoryRepository: PersistenceMemoryRepository,
        projectName: string,
        newProjectDbOrigin?: ProjectDbOrigin,
    ): Promise<Id> {
        memoryRepository.clearAllRevs();

        const importProject: IPersistence<IProjectEntity> = (await memoryRepository.get(
            importProjectId,
        )) as IPersistence<IProjectEntity>;

        if (!importProject) {
            throw Error(`Project missing during import`);
        }

        await this.repairService.checkAndRepairProject(importProjectId, memoryRepository);

        /**
         * During the import references need to be updated from old ids to new ids.
         * The idMap contains all mappings for resolving an old id into a new id.
         */
        const idMap: IdMap = {};

        try {
            const importedProjectId = await this.importProjectAndChildren(
                importProject,
                currentProjectToImportTo,
                memoryRepository,
                projectName,
                idMap,
                newProjectDbOrigin,
            );
            // update device count and hasFloorPlan (needed for import settings and import into project)
            await this.itemService.updateProjectDeviceCount(importedProjectId);
            await this.projectService.updateHasFloorPlanOrMapLocation(importedProjectId);
            return importedProjectId;
        } catch (error) {
            /*
             * The import flow will be improved in future versions since right now
             * we have a slim chance that corruption can occur which won't be
             * rollbacked properly. Much of this is related to the importing being
             * forced to add entities in multiple transactions since we can't
             * generate ids outside of repositories.
             *
             * If we re-design the id generation a bit we could enable the import
             * to be performed in one single transaction which would prevent the
             * persisted data from being in a corrupt state using the bulkUpdate
             * method.
             *
             * Currently, if we encounter any errors we delete all entities that
             * we know are persisted using a single transaction. If this also fails,
             * we have no good way to recover from the state we are in.
             */
            try {
                await this.rollbackImport(idMap);
            } catch (rollbackImportError) {
                console.error('rollbackImport', rollbackImportError);
            }

            throw error;
        }
    }

    private async importProjectAndChildren(
        importProject: IPersistence<IProjectEntity>,
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
        memoryRepository: PersistenceMemoryRepository,
        projectName: string,
        idMap: IdMap,
        newProjectDbOrigin?: ProjectDbOrigin,
    ): Promise<Id> {
        /**
         * The import first obtains an id for the new project. This is then used
         * when constructing the path of the remaining project entities.
         *
         * When all entities except the project itself have been successfully created,
         * we create the project in an atomic operation. The benefit of this approach
         * is that when a project is imported, we either get the entire project or
         * (in the case of a failed import) nothing. If the rollback also fails we
         * may get orphan entities in the database but no corrupted projects.
         */

        // importedItemIpAddresses are used when merging projects to avoid giving new items the same ip address
        const importedItemIpAddresses: IPv4[] = [];

        const newProjectId = currentProjectToImportTo
            ? currentProjectToImportTo._id
            : this.projectRepository.createNewEntityId();

        idMap[importProject._id] = { _id: newProjectId, _rev: '' };

        const projectRootPath = [newProjectId];

        const schedules: IEntity[] = await memoryRepository.getAll('schedule');
        await this.importScheduleEntities(
            memoryRepository,
            idMap,
            projectRootPath,
            schedules as Array<IPersistence<IScheduleEntity>>,
            currentProjectToImportTo,
        );

        const profiles: IEntity[] = await memoryRepository.getAll('profile');
        await this.importProfileEntities(
            idMap,
            projectRootPath,
            profiles as Array<IPersistence<IProfileEntity>>,
            currentProjectToImportTo,
        );

        const items: IEntity[] = await memoryRepository.getAll('item');
        await this.importItems(
            idMap,
            importedItemIpAddresses,
            items as Array<IPersistence<IItemEntity>>,
            currentProjectToImportTo,
        );

        const customItems: IEntity[] = await memoryRepository.getAll('customItem');
        await this.importItems(
            idMap,
            importedItemIpAddresses,
            customItems as Array<IPersistence<ICustomItemEntity>>,
        );

        const partnerItems: IEntity[] = await memoryRepository.getAll('partnerItem');
        await this.importItems(
            idMap,
            importedItemIpAddresses,
            partnerItems as Array<IPersistence<IPartnerItemEntity>>,
        );

        // Importing project quotations needs to be done after importing 'customItem' and 'partnerItem'
        // since when merging with another project, key (which is the id of the customItem/partnerItem)
        // for quotation needs to be updated with the new added id
        const quotations: IEntity[] = await memoryRepository.getAll('quotation');

        const importProjectPiaLocationId = importProject.installationPiaLocationId;
        const currentProjectPiaLocationId = currentProjectToImportTo?.installationPiaLocationId;

        await this.importProjectQuotationEntities(
            idMap,
            projectRootPath,
            quotations as Array<IPersistence<IProjectQuotationEntity>>,
            currentProjectToImportTo,
            importProjectPiaLocationId === currentProjectPiaLocationId,
        );

        const itemsRelations = (await memoryRepository.getAll('itemRelation')) as Array<
            IPersistence<IItemRelationEntity>
        >;

        await this.importItemRelations(idMap, importedItemIpAddresses, itemsRelations);

        const mapLocations: IEntity[] = await memoryRepository.getAll('mapLocation');
        const sortedMapLocations = mapLocations.sort(creationDateReverseComparator);
        await this.importItems(
            idMap,
            importedItemIpAddresses,
            sortedMapLocations as Array<IPersistence<IMapLocationEntity>>,
        );

        const floorPlans: IPersistence<IFloorPlanEntity>[] = (
            await memoryRepository.getAll('floorPlan')
        ).filter((floorPlan) => FloorPlanService.isFloorPlanEntity(floorPlan));

        const importDefaultMap = floorPlans.find((floorPlan) => floorPlan.isDefault);

        const currentProjectDefaultMapId =
            importDefaultMap &&
            currentProjectToImportTo &&
            this.currentProjectService
                .getAllEntitiesOfType('floorPlan')
                .find((floorPlan) => floorPlan.isDefault)?._id;

        // If merging projects (i.e currentProjectToImportTo is defined), we don't want to import the default map from the project being imported
        // therefore we filter out the default map from the floorPlans array (floorplans to import)
        const sortedFloorPlans = floorPlans
            .filter((floorPlan) =>
                currentProjectDefaultMapId && importDefaultMap
                    ? floorPlan._id !== importDefaultMap._id
                    : true,
            )
            .sort(creationDateReverseComparator);

        await this.importItemsSequentially(
            idMap,
            importedItemIpAddresses,
            sortedFloorPlans as Array<IPersistence<IFloorPlanEntity>>,
        );

        const blockers: IEntity[] = await memoryRepository.getAll('blocker');
        await this.importItems(
            idMap,
            importedItemIpAddresses,
            blockers as Array<IPersistence<IBlockerEntity>>,
        );

        const freeTextPoints: IEntity[] = await memoryRepository.getAll('freeTextPoint');
        await this.importItems(
            idMap,
            importedItemIpAddresses,
            freeTextPoints as Array<IPersistence<IFreeTextPointEntity>>,
        );

        const installationPoints: IEntity[] = await memoryRepository.getAll('installationPoint');
        const sortedInstallationPoints = installationPoints
            .sort(idComparator)
            .sort(creationDateReverseComparator);
        const importedInstallationPoints = await this.importItems(
            idMap,
            importedItemIpAddresses,
            sortedInstallationPoints as Array<IPersistence<IInstallationPointEntity>>,
        );

        this.updateInstallationPointRelations(
            importedInstallationPoints as Array<IPersistence<IInstallationPointEntity>>,
            idMap,
        );

        // if merging projects, we add the installation points to the current project and don't create a new project
        if (currentProjectToImportTo) {
            return newProjectId;
        }

        const newProject = await this.importProjectEntity(
            idMap,
            importProject,
            newProjectId,
            projectName,
            newProjectDbOrigin,
        );

        return newProject._id;
    }

    private updateInstallationPointRelations(
        installationPoints: IPersistence<IInstallationPointEntity>[],
        idMap: IdMap,
    ) {
        const children = installationPoints.filter(
            (installationPoint) => installationPoint.parentId,
        );
        Promise.all(
            children.map(async (child) => {
                child.parentId = idMap[child.parentId!]._id;
                await this.installationPointRepository.update(child, false);
            }),
        );
    }

    /**
     * Performs a rollback of the import by deleting all entities already
     * persisted in the database.
     * @param idMap the map of ids that are persisted
     */
    private rollbackImport(idMap: IdMap) {
        const idsOfImportedEntities: IIdRev[] = Object.values(idMap);
        return this.databaseRepository.bulkDelete(idsOfImportedEntities);
    }

    private importProjectEntity(
        idMap: IdMap,
        importProject: IProjectEntity,
        newProjectId: Id,
        newProjectName: string,
        newProjectDbOrigin?: ProjectDbOrigin,
    ): Promise<IPersistence<IProjectEntity>> {
        return this.projectRepository.addWithId(
            {
                ...importProject,
                locked: false,
                path: [],
                name: newProjectName,
                state: QuotationProgress.Designing,
                projectDbOrigin: newProjectDbOrigin,
                defaultProfile:
                    importProject.defaultProfile !== ''
                        ? this.resolveIdOrThrow(idMap, importProject.defaultProfile)
                        : '',
            },
            newProjectId,
            false,
        );
    }

    /**
     * Update custom item prices (other partner products) and partner item prices references (i.e Genetec products).
     * These item prices consists of references and needs to be updated after customItems (customItems:<id>) and partnerItems (item:<id>)
     * @param idMap
     * @param itemPrices item prices for importing project
     * @returns updated item prices
     */
    private updateItemPricesReferences(
        idMap: IdMap,
        itemPrices: Record<Id, number>,
    ): Record<Id, number> {
        const updatedItemPrices: Record<Id, number> = {};

        Object.keys(itemPrices).map((key) => {
            // 3rd party products are saved with pia id - eg: 55555
            if (!isNaN(Number(key))) {
                updatedItemPrices[key] = itemPrices[key];
            } else {
                const updatedId = this.resolveIdOrNull(idMap, key);

                if (updatedId) {
                    updatedItemPrices[updatedId] = itemPrices[key];
                }
            }
        });

        return updatedItemPrices;
    }

    private async importScheduleEntities(
        memoryRepository: PersistenceMemoryRepository,
        idMap: IdMap,
        path: Id[],
        schedules: Array<IPersistence<IScheduleEntity>>,
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
    ): Promise<void> {
        // First we add schedules and their timeseries without inverse,
        // then we can use the idMap to get the correct path for the inverted ones,
        // since schedules have an original schedule as parent, and timeseries may point to an original timeserie.

        const schedulesWithoutInverse = schedules.filter((a) => {
            const parentId = getParentId(a);
            return schedules.find((b) => b._id === parentId) === undefined;
        });

        await this.addNonInvertedSchedules(
            memoryRepository,
            idMap,
            path,
            schedulesWithoutInverse,
            currentProjectToImportTo,
        );

        const invertedSchedules = schedules.filter((c) => {
            const invParentId = getParentId(c);
            return schedules.find((d) => d._id === invParentId) !== undefined;
        });

        await this.addInvertedSchedules(
            memoryRepository,
            idMap,
            path,
            invertedSchedules,
            currentProjectToImportTo,
        );
    }

    /**
     * Removes possible duplicated schedules from the current project and update references to duplicated schedules. (only used when merging projects)
     * @param importedSchedule The schedule to compare with
     */
    private async removeDuplicatedSchedule(importedSchedule: IPersistence<IScheduleEntity>) {
        const currentProjectSchedules = this.currentProjectService.getAllEntitiesOfType('schedule');

        let duplicatedSchedule = currentProjectSchedules.find((currentSchedule) =>
            isEqual(
                omit(currentSchedule, ['creationDate', 'updatedDate', 'path', '_id', '_rev']),
                omit(importedSchedule, ['creationDate', 'updatedDate', 'path', '_id', '_rev']),
            ),
        );

        // this check is needed to handle when system defined "Always" was created in different languages.
        // The projects are not considered as duplicates with different names, but we cannot have multiple default schedules.
        // Remove the current system defined schedule and update the imported schedule with the name of the current system defined
        if (!duplicatedSchedule && importedSchedule.systemDefined) {
            duplicatedSchedule = currentProjectSchedules.find((schedule) => schedule.systemDefined);
            if (duplicatedSchedule) {
                importedSchedule.name = duplicatedSchedule.name;
                await this.scheduleRepository.update(importedSchedule, false);
            }
        }

        if (duplicatedSchedule) {
            const profiles = this.currentProjectService.getAllEntitiesOfType('profile');

            // update all profiles that reference the duplicated schedule with the imported schedule id
            // (we don't keep duplicated currentProjectToImportTo schedule, since items not yet imported might use the duplicated schedule.
            // More complicate to keep track of and update reference for not yet imported profile items.
            // Easier to just remove the duplicated schedule from current project)
            profiles.forEach(async (profile) => {
                let profileUpdated = false;
                if (profile.continuousRecording.schedule === duplicatedSchedule._id) {
                    profile.continuousRecording.schedule = duplicatedSchedule._id;
                    profileUpdated = true;
                }
                if (profile.liveView.schedule === duplicatedSchedule._id) {
                    profile.liveView.schedule = importedSchedule._id;
                    profileUpdated = true;
                }
                if (profile.triggeredRecording.schedule === duplicatedSchedule._id) {
                    profile.triggeredRecording.schedule = importedSchedule._id;
                    profileUpdated = true;
                }
                if (profileUpdated) {
                    await this.profileRepository.update(profile, false);
                }
            });

            // remove the duplicated schedule
            await this.scheduleRepository.delete(
                duplicatedSchedule._id,
                duplicatedSchedule._rev,
                false,
            );
        }
    }

    private async addNonInvertedSchedules(
        memoryRepository: PersistenceMemoryRepository,
        idMap: IdMap,
        path: Id[],
        schedules: Array<IPersistence<IScheduleEntity>>,
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
    ): Promise<void> {
        for (const originalSchedule of schedules) {
            const importedSchedule = await this.scheduleRepository.add(
                {
                    type: 'schedule',
                    name: originalSchedule.name,
                    systemDefined: originalSchedule.systemDefined,
                    path: [...path],
                    locked: false,
                    archived: originalSchedule.archived,
                },
                false,
            );

            // if we merge projects we want to remove duplicated schedules
            if (currentProjectToImportTo) {
                await this.removeDuplicatedSchedule(importedSchedule);
            }

            idMap[originalSchedule._id] = this.getIdRevObject(importedSchedule);

            const timeSeries = (await memoryRepository.getDescendants(originalSchedule._id)).filter(
                (descendant) => descendant.type === 'timeSerie',
            ) as Array<IPersistence<ITimeSerieEntity>>;
            const nonInvertedTimeSeries = timeSeries
                .filter((timeSerie) => getParentId(timeSerie) === originalSchedule._id)
                .sort((serieA, serieB) => {
                    return serieA.creationDate > serieB.creationDate
                        ? 1
                        : serieA.creationDate < serieB.creationDate
                          ? -1
                          : 0;
                });

            for (const originalTimeSerie of nonInvertedTimeSeries) {
                const importedTimeSerie = await this.timeSerieRepository.add(
                    {
                        type: 'timeSerie',
                        days: originalTimeSerie.days,
                        start: originalTimeSerie.start,
                        end: originalTimeSerie.end,
                        path: [...importedSchedule.path],
                        locked: false,
                        archived: originalTimeSerie.archived,
                    },
                    false,
                );

                idMap[originalTimeSerie._id] = this.getIdRevObject(importedTimeSerie);
            }
        }
    }

    private async addInvertedSchedules(
        memoryRepository: PersistenceMemoryRepository,
        idMap: IdMap,
        path: Id[],
        schedules: Array<IPersistence<IScheduleEntity>>,
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
    ) {
        for (const invertedSchedule of schedules) {
            const newPath = [...path];
            // parentId should exist in the idMap since this is an inverse
            const parentId = getParentId(invertedSchedule);
            if (parentId) {
                newPath.push(idMap[parentId]._id);
            }

            const importedSchedule = await this.scheduleRepository.add(
                {
                    type: 'schedule',
                    name: invertedSchedule.name,
                    systemDefined: invertedSchedule.systemDefined,
                    path: [...newPath],
                    locked: false,
                    archived: invertedSchedule.archived,
                },
                false,
            );

            // if we merge projects we want to remove duplicated schedules
            if (currentProjectToImportTo) {
                await this.removeDuplicatedSchedule(importedSchedule);
            }

            idMap[invertedSchedule._id] = this.getIdRevObject(importedSchedule);

            const invertedTimeSeries = (await memoryRepository.getDescendants(invertedSchedule._id))
                .filter((descendant) => descendant.type === 'timeSerie')
                .sort((serieA, serieB) => {
                    return serieA.creationDate > serieB.creationDate
                        ? 1
                        : serieA.creationDate < serieB.creationDate
                          ? -1
                          : 0;
                }) as Array<IPersistence<ITimeSerieEntity>>;

            for (const invertedTimeSerie of invertedTimeSeries as Array<
                IPersistence<ITimeSerieEntity>
            >) {
                const importedTimeSerie = await this.timeSerieRepository.add(
                    {
                        type: 'timeSerie',
                        days: invertedTimeSerie.days,
                        start: invertedTimeSerie.start,
                        end: invertedTimeSerie.end,
                        path: [...importedSchedule.path],
                        locked: false,
                        archived: invertedTimeSerie.archived,
                        originalId: invertedTimeSerie.originalId
                            ? idMap[invertedTimeSerie.originalId]._id
                            : undefined,
                    },
                    false,
                );

                idMap[invertedTimeSerie._id] = this.getIdRevObject(importedTimeSerie);
            }
        }
    }

    private async importProjectQuotationEntities(
        idMap: IdMap,
        path: Id[],
        projectQuotations: Array<IPersistence<IProjectQuotationEntity>>,
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
        sameCountry: boolean,
    ): Promise<Array<IPersistence<IProjectQuotationEntity>>> {
        const importedProjectQuotations: Array<IPersistence<IProjectQuotationEntity>> = [];
        const currentProjectQuotations = this.currentProjectService.getCurrentProjectQuotations();

        // if merging projects, we want to update the current project quotations with the imported project quotations
        // Note: there actually only exist one project quotation, but getting entities always return an array
        if (currentProjectToImportTo && currentProjectQuotations) {
            for (const importProjectQuotation of projectQuotations) {
                // if price exists in current project for the part number, keep current project price
                // if price does not exist in current project and projects have same installation location,
                // import price by adding to current project pricesByPartNumber PriceMap
                const priceKeys = Object.keys(importProjectQuotation.pricesByPartNumber);
                priceKeys.forEach((key) => {
                    if (
                        currentProjectQuotations.pricesByPartNumber[key] === undefined &&
                        sameCountry
                    ) {
                        currentProjectQuotations.pricesByPartNumber[key] =
                            importProjectQuotation.pricesByPartNumber[key];
                    }
                });

                const customPriceKeys = Object.keys(importProjectQuotation.customItemPrices);
                customPriceKeys.forEach((key) => {
                    if (
                        currentProjectQuotations.customItemPrices[key] === undefined &&
                        sameCountry
                    ) {
                        const resolvedId = this.resolveIdOrKeep(idMap, key.toString());
                        const newKey = resolvedId ? resolvedId : key;
                        currentProjectQuotations.customItemPrices[newKey] =
                            importProjectQuotation.customItemPrices[key];
                    }
                });

                const partnerItemPriceKeys = importProjectQuotation.partnerItemPrices
                    ? Object.keys(importProjectQuotation.partnerItemPrices)
                    : [];
                partnerItemPriceKeys.forEach((key) => {
                    if (
                        currentProjectQuotations.partnerItemPrices[key] === undefined &&
                        sameCountry
                    ) {
                        const resolvedId = this.resolveIdOrKeep(idMap, key.toString());
                        const newKey = resolvedId ? resolvedId : key;
                        currentProjectQuotations.partnerItemPrices[newKey] =
                            importProjectQuotation.partnerItemPrices[key];
                    }
                });
            }

            await this.projectQuotationRepository.update(currentProjectQuotations, false);
            importedProjectQuotations.push(currentProjectQuotations);
        } else {
            for (const importProjectQuotation of projectQuotations) {
                this.updateProjectQuotationReferences(idMap, importProjectQuotation);
                const importedProjectQuotation = await this.projectQuotationRepository.add(
                    {
                        type: 'quotation',
                        path: [...path],
                        locked: false,
                        archived: importProjectQuotation.archived,
                        pricesByPartNumber: importProjectQuotation.pricesByPartNumber,
                        customItemPrices: importProjectQuotation.customItemPrices,
                        partnerItemPrices: importProjectQuotation.partnerItemPrices,
                        header: importProjectQuotation.header,
                        footer: importProjectQuotation.footer,
                        msrpToQuoteMargin: importProjectQuotation.msrpToQuoteMargin,
                        validUntilDate: importProjectQuotation.validUntilDate,
                    },
                    false,
                );

                idMap[importProjectQuotation._id] = this.getIdRevObject(importedProjectQuotation);

                importedProjectQuotations.push(importedProjectQuotation);
            }
        }

        return importedProjectQuotations;
    }

    private updateProjectQuotationReferences(
        idMap: IdMap,
        importedProjectQuotation: IPersistence<IProjectQuotationEntity>,
    ) {
        importedProjectQuotation.customItemPrices = this.updateItemPricesReferences(
            idMap,
            importedProjectQuotation.customItemPrices,
        );
        if (importedProjectQuotation?.partnerItemPrices) {
            importedProjectQuotation.partnerItemPrices = this.updateItemPricesReferences(
                idMap,
                importedProjectQuotation.partnerItemPrices,
            );
        }
    }

    private async updateCameraItemWithDuplicatedProfileId(
        item: IPersistence<ICameraItemEntity>,
        duplicatedProfileId: string,
    ) {
        item.properties.camera.associatedProfile = duplicatedProfileId;
        await this.itemRepository.update(item, false);
    }

    private async updateEncoderItemWithDuplicatedProfileId(
        item: IPersistence<IItemEntity>,
        items: Record<string, IPersistence<IItemEntity> | undefined>,
        relations: Array<IPersistence<IItemRelationEntity>>,
        duplicatedProfileId: string,
        profileId: string,
    ) {
        const itemRelations = relations.filter((relation) => relation.parentId === item._id);
        itemRelations.forEach(async (itemRelation) => {
            const analogItem = items[itemRelation.childId];
            if (
                itemRelation.relationType === 'analogCamera' &&
                analogItem?.properties.analogCamera?.associatedProfile === profileId
            ) {
                analogItem.properties.analogCamera.associatedProfile = duplicatedProfileId;
                await this.itemRepository.update(analogItem, false);
            }
        });
    }

    private async updateMainUnitItemWithDuplicatedProfileId(
        item: IPersistence<IItemEntity>,
        items: Record<string, IPersistence<IItemEntity> | undefined>,
        relations: Array<IPersistence<IItemRelationEntity>>,
        duplicatedProfileId: string,
        profileId: string,
    ) {
        const itemRelations = relations.filter((relation) => relation.parentId === item._id);
        itemRelations.forEach(async (itemRelation) => {
            const mainUnitItem = items[itemRelation.childId];
            if (
                itemRelation.relationType === 'sensorUnit' &&
                mainUnitItem?.properties.sensorUnit?.associatedProfile === profileId
            ) {
                mainUnitItem.properties.sensorUnit.associatedProfile = duplicatedProfileId;
                await this.itemRepository.update(mainUnitItem, false);
            }
        });
    }

    /**
     * Update cameras, analogCameras and sensorUnits with the new profile id.
     * @param profileId profileId that is a duplicate and will be removed
     * @param items items to check if they should be updated with new profileId
     * @param relations relations for the items
     * @param duplicatedProfileId the duplicated profile id that will be kept
     */
    private async updateItemWithDuplicatedProfileId(
        profileId: string,
        items: Record<string, IPersistence<IItemEntity> | undefined>,
        relations: Array<IPersistence<IItemRelationEntity>>,
        duplicatedProfileId: string,
    ): Promise<void> {
        Object.values(items).forEach(async (item) => {
            if (
                item &&
                deviceTypeCheckers.isCamera(item satisfies IItemEntity) &&
                item?.properties.camera?.associatedProfile === profileId
            ) {
                await this.updateCameraItemWithDuplicatedProfileId(
                    item as IPersistence<ICameraItemEntity>,
                    duplicatedProfileId,
                );
            } else if (item && deviceTypeCheckers.isEncoder(item satisfies IItemEntity)) {
                this.updateEncoderItemWithDuplicatedProfileId(
                    item,
                    items,
                    relations,
                    duplicatedProfileId,
                    profileId,
                );
            } else if (item && deviceTypeCheckers.isMainUnit(item satisfies IItemEntity)) {
                this.updateMainUnitItemWithDuplicatedProfileId(
                    item,
                    items,
                    relations,
                    duplicatedProfileId,
                    profileId,
                );
            }
        });
    }

    /**
     * Removes the duplicated profile if it exists from current project
     * and if removed profile is default profile, update the default profile to the new id
     * @param importedProfile The imported profile that will be kept
     */
    private async removeDuplicatedProfile(
        importedProfile: IPersistence<IProfileEntity>,
        defaultProfileId: string | undefined,
    ) {
        const currentProjectProfiles = this.currentProjectService.getAllEntitiesOfType('profile');

        const currentProjectDuplicatedProfile = currentProjectProfiles?.find((currentProfile) =>
            isEqual(
                omit(currentProfile, ['creationDate', 'updatedDate', 'path', '_id', '_rev']),
                omit(importedProfile, ['creationDate', 'updatedDate', 'path', '_id', '_rev']),
            ),
        );
        if (!currentProjectDuplicatedProfile) {
            return this.updateProfileWithSameName(importedProfile);
        }

        const currentProjectItems = this.currentProjectService.getAllEntitiesOfType('item');
        const currentProjectRelations =
            this.currentProjectService.getAllEntitiesOfType('itemRelation');

        const duplicatedProfileUnused =
            currentProjectDuplicatedProfile &&
            currentProjectItems &&
            currentProjectRelations &&
            countUses(
                currentProjectDuplicatedProfile._id,
                toRecord(currentProjectItems, '_id'),
                currentProjectRelations,
            ) === 0;
        // If the default profile is set to the duplicated profile, update the default profile to the imported profile
        if (defaultProfileId === currentProjectDuplicatedProfile._id) {
            const currentProject = this.currentProjectService.getProjectEntity();
            await this.projectRepository.update(
                {
                    ...currentProject,
                    defaultProfile: importedProfile._id,
                },
                false,
            );
        }
        if (duplicatedProfileUnused) {
            await this.profileRepository.delete(
                currentProjectDuplicatedProfile._id,
                currentProjectDuplicatedProfile._rev,
                false,
            );
        } else {
            await this.updateItemWithDuplicatedProfileId(
                currentProjectDuplicatedProfile._id,
                toRecord(currentProjectItems, '_id'),
                currentProjectRelations,
                importedProfile._id,
            );
            await this.profileRepository.delete(
                currentProjectDuplicatedProfile._id,
                currentProjectDuplicatedProfile._rev,
                false,
            );
        }
    }

    /**
     * Update the imported profile with index if profile with same name already exists
     * @param importedProfile The imported profile
     */
    private async updateProfileWithSameName(importedProfile: IPersistence<IProfileEntity>) {
        const currentProjectProfiles = this.currentProjectService.getAllEntitiesOfType('profile');

        const profilesWithSameName = currentProjectProfiles
            ?.filter((currentProfile) => currentProfile.name === importedProfile.name)
            .map(({ name }) => name);

        profilesWithSameName.map((name) => {
            const newName = this.nameGenerationService.getName(
                name,
                profilesWithSameName,
                AppConstants.profileNameMaxLength,
            );
            this.profileRepository.update({ ...importedProfile, name: newName }, false);
        });
    }

    private async importProfileEntities(
        idMap: IdMap,
        path: Id[],
        profiles: Array<IPersistence<IProfileEntity>>,
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
    ): Promise<void> {
        for (const originalProfile of profiles) {
            const importedProfile = await this.profileRepository.add(
                {
                    type: 'profile',
                    path: [...path],
                    locked: false,
                    archived: originalProfile.archived,
                    name: originalProfile.name,
                    scenario: this.getImportableScenarioSettings(originalProfile.scenario),
                    liveView: this.getImportableRecordingSettings(idMap, originalProfile.liveView),
                    continuousRecording: this.getImportableRecordingSettings(
                        idMap,
                        originalProfile.continuousRecording,
                    ),
                    triggeredRecording: this.getImportableRecordingSettings(
                        idMap,
                        originalProfile.triggeredRecording,
                    ),
                    storage: {
                        retentionTime: originalProfile.storage.retentionTime,
                        useProjectSetting: originalProfile.storage.useProjectSetting,
                    },
                    audio: {
                        liveViewEnabled: originalProfile.audio.liveViewEnabled,
                        recordingEnabled: originalProfile.audio.recordingEnabled,
                    },
                    zipstream: {
                        zipStrength: originalProfile.zipstream.zipStrength,
                        zipProfile: originalProfile.zipstream.zipProfile,
                        gopDefault: originalProfile.zipstream.gopDefault,
                        gopMax: originalProfile.zipstream.gopMax,
                        gopMode: originalProfile.zipstream.gopMode,
                        fpsMode: originalProfile.zipstream.fpsMode,
                        useProjectSetting: originalProfile.zipstream.useProjectSetting,
                        minDynamicFps: originalProfile.zipstream.minDynamicFps,
                    },
                },
                false,
            );

            // if we merge projects we want to remove duplicated profiles
            if (currentProjectToImportTo && profiles.length > 0) {
                await this.removeDuplicatedProfile(
                    importedProfile,
                    currentProjectToImportTo?.defaultProfile,
                );
            }

            idMap[originalProfile._id] = this.getIdRevObject(importedProfile);
        }
    }

    private getImportableScenarioSettings(originalSettings: IScenarioEntity): IScenarioEntity {
        return {
            lightEnd: originalSettings.lightEnd,
            lightStart: originalSettings.lightStart,
            nightLighting: originalSettings.nightLighting,
            scenarioId: originalSettings.scenarioId,
            sceneDetails: originalSettings.sceneDetails,
        };
    }

    private getImportableRecordingSettings(
        idMap: IdMap,
        originalSettings: IRecordingSettingsEntity,
    ): IRecordingSettingsEntity {
        if (originalSettings.useAverageBitrate !== undefined) {
            return {
                compression: originalSettings.compression,
                dayMotion: originalSettings.dayMotion,
                dayTriggerTime: originalSettings.dayTriggerTime,
                frameRate: originalSettings.frameRate,
                nightMotion: originalSettings.nightMotion,
                nightTriggerTime: originalSettings.nightTriggerTime,
                resolution: originalSettings.resolution,
                schedule: this.resolveNullableId(idMap, originalSettings.schedule),
                videoEncoding: originalSettings.videoEncoding,
                useAverageBitrate: originalSettings.useAverageBitrate,
            };
        } else {
            return {
                compression: originalSettings.compression,
                dayMotion: originalSettings.dayMotion,
                dayTriggerTime: originalSettings.dayTriggerTime,
                frameRate: originalSettings.frameRate,
                nightMotion: originalSettings.nightMotion,
                nightTriggerTime: originalSettings.nightTriggerTime,
                resolution: originalSettings.resolution,
                schedule: this.resolveNullableId(idMap, originalSettings.schedule),
                videoEncoding: originalSettings.videoEncoding,
            };
        }
    }

    private getCameraPropertiesFilterEntity(
        originalFilter: ICameraPropertiesFilterEntity,
    ): ICameraPropertiesFilterEntity {
        return {
            cameraTypes: originalFilter.cameraTypes,
            sensorUnitTypes: originalFilter.sensorUnitTypes,
            corridorFormat: originalFilter.corridorFormat,
            distanceToTarget: originalFilter.distanceToTarget,
            horizontalFov: originalFilter.horizontalFov,
            installationHeight: originalFilter.installationHeight,
            lightConditions: originalFilter.lightConditions,
            outdoor: originalFilter.outdoor,
            pixelDensity: originalFilter.pixelDensity,
            targetHeight: originalFilter.targetHeight,
            panoramaMode: originalFilter.panoramaMode,
            applications: originalFilter.applications,
            isSceneFilterActive: originalFilter.isSceneFilterActive,
            isFilterChanged: originalFilter.isFilterChanged,
        };
    }

    private getCustomCameraPropertiesEntity(
        originalCustomCamera: ICustomCameraPropertiesEntity | undefined,
    ): ICustomCameraPropertiesEntity | undefined {
        if (!originalCustomCamera) {
            return undefined;
        }
        return {
            activated: originalCustomCamera.activated,
            bandwidth: originalCustomCamera.bandwidth,
            cameraType: originalCustomCamera.cameraType,
            horizontalFovMax: originalCustomCamera.horizontalFovMax,
            horizontalFovMin: originalCustomCamera.horizontalFovMin,
            modelName: originalCustomCamera.modelName,
            powerConsumption: originalCustomCamera.powerConsumption,
            resolutionHorizontal: originalCustomCamera.resolutionHorizontal,
            resolutionVertical: originalCustomCamera.resolutionVertical,
            poe: originalCustomCamera.poe,
        };
    }

    /**
     * Keep a map with id:s of the original (to-be imported) items
     * and the id:s of the newly imported copies of these.
     */
    private addEntityToIdMap(oldId: Id, newEntity: ImportEntity, idMap: IdMap) {
        idMap[oldId] = this.getIdRevObject(newEntity);
        return idMap[oldId];
    }

    /**
     * Take a path of old (to-be imported) id:s and return a new path with the id:s of the same imported entities.
     * Uses the idMap to return the imported id. If an entity in the path is not yet imported, import it and
     * all of its paths.
     */
    private importPath = async (
        path: Id[],
        idMap: IdMap,
        importedItemIpAddresses: IPv4[],
        entities: ImportEntity[],
    ) =>
        Promise.all(
            path.map(async (id) => {
                if (!idMap[id]) {
                    const entity = this.findEntityToImportById(id, entities);

                    if (!entity) {
                        throw new Error(`Item with id: ${id} not found in import data`);
                    }

                    const newItem = await this.importEntityWithEntirePath(
                        entity,
                        idMap,
                        importedItemIpAddresses,
                        entities,
                    );

                    return this.addEntityToIdMap(id, newItem, idMap)._id;
                }
                return idMap[id]._id;
            }),
        );

    // updates the entity with new network settings if dhcp is turned off
    private getUpdatedItemWithNewNetworkSettings(
        entity: IPersistence<IItemEntity>,
        importedItemIpAddresses: IPv4[],
    ): IPersistence<IItemEntity> {
        const dhcpTurnedOff = entity.networkSettings?.[0].dhcp !== true;
        if (dhcpTurnedOff) {
            entity.networkSettings = this.itemService.getMergedItemNetworkSettings(
                entity,
                importedItemIpAddresses,
            );
        }
        return entity;
    }

    /**
     * Import an item or relation and all of its path.
     * First add the entity, then recursively follow the entity's path
     * and import entities not yet imported.
     * Update the entity with the new path that contains the new imported
     * entity id:s.
     */
    private async importEntityWithEntirePath(
        entity: ImportEntity,
        idMap: IdMap,
        importedItemIpAddresses: IPv4[],
        entities: ImportEntity[],
        currentProjectToImportTo?: IPersistence<IProjectEntity> | undefined,
    ): Promise<ImportEntity> {
        const newEntity = await this.getEntity(
            entity,
            idMap,
            importedItemIpAddresses,
            currentProjectToImportTo,
        );

        this.addEntityToIdMap(entity._id, newEntity, idMap);

        const newPath = await this.importPath(
            entity.path,
            idMap,
            importedItemIpAddresses,
            entities,
        );

        return entity.type === 'itemRelation'
            ? this.itemRelationRepository.update(
                  {
                      ...(newEntity as IPersistence<IItemRelationEntity>),
                      path: newPath,
                  },
                  false,
              )
            : this.itemRepository.update(
                  {
                      ...(newEntity as IPersistence<IItemEntity>),
                      path: newPath,
                  },
                  false,
              );
    }

    private getEntity = async (
        entity: IBaseEntity,
        idMap: IdMap,
        importedItemIpAddresses: IPv4[],
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
    ) => {
        switch (entity.type) {
            case 'itemRelation':
                return this.addRelationToRepository(
                    entity as IPersistence<IItemRelationEntity>,
                    [],
                    idMap,
                );
            case 'customItem':
                return this.addCustomItemToRepository(
                    entity as IPersistence<ICustomItemEntity>,
                    [],
                    idMap,
                );
            case 'item':
                const entityWithNetworksettings = currentProjectToImportTo
                    ? this.getUpdatedItemWithNewNetworkSettings(
                          entity as IPersistence<IItemEntity>,
                          importedItemIpAddresses,
                      )
                    : entity;
                if (
                    currentProjectToImportTo &&
                    (entity as IPersistence<IItemEntity>).networkSettings
                ) {
                    const networkSettings = (entity as IPersistence<IItemEntity>).networkSettings;
                    // add the new ip addresses to the importedItemIpAddresses list to keep track of new ip addresses
                    // and prevent imported items from getting the same ip address
                    if (networkSettings) {
                        networkSettings.forEach(({ addresses }) =>
                            addresses.forEach((address) => {
                                if (Validator.isValidIPv4String(address)[0]) {
                                    importedItemIpAddresses.push(
                                        IPv4.fromDecimalDottedString(address),
                                    );
                                }
                            }),
                        );
                    }
                }
                return this.addItemToRepository(
                    entityWithNetworksettings as IPersistence<IItemEntity>,
                    [],
                    idMap,
                );
            case 'floorPlan':
                // if we merge projects, and we import floorplan of type 'FloorPlan' from another project,
                // the image is not imported as a blob.
                // We need to get the image from imageStore to copy it to the new project so the key is needed
                const importableFloorPlan = await this.floorPlanService.getFloorPlanFromExportable(
                    entity as IFloorPlanExportEntity,
                    (entity as IFloorPlanEntity).image?.key,
                );
                return this.addFloorPlanToRepository(
                    importableFloorPlan as IPersistence<IFloorPlanEntity>,
                    [],
                );
            case 'mapLocation':
                return this.addMapLocationToRepository(
                    entity as IPersistence<IMapLocationEntity>,
                    [],
                    idMap,
                );
            case 'blocker':
                return this.addBlockerToRepository(
                    entity as IPersistence<IBlockerEntity>,
                    [],
                    idMap,
                );
            case 'installationPoint':
                return this.addInstallationPointToRepository(
                    entity as IPersistence<IInstallationPointEntity>,
                    [],
                    idMap,
                );
            case 'freeTextPoint':
                return this.addFreeTextToRepository(
                    entity as IPersistence<IFreeTextPointEntity>,
                    [],
                    idMap,
                );
            case 'partnerItem':
                return this.addPartnerItemToRepository(
                    entity as IPersistence<IPartnerItemEntity>,
                    [],
                );
            default:
                throw new Error(`Entity type ${entity.type} not importable`);
        }
    };

    private importItems = async (
        idMap: IdMap,
        importedItemIpAddresses: IPv4[],
        items: Array<IPersistence<IBaseEntity>>,
        currentProjectToImportTo?: IPersistence<IProjectEntity> | undefined,
    ) =>
        Promise.all(
            items.map(async (item) =>
                this.importEntityWithEntirePath(
                    item,
                    idMap,
                    importedItemIpAddresses,
                    items,
                    currentProjectToImportTo,
                ),
            ),
        );

    /**
     * Import items sequentially. This ensures the preservation of chronological order
     */
    private importItemsSequentially = async (
        idMap: IdMap,
        importedItemIpAddresses: IPv4[],
        items: Array<IPersistence<IBaseEntity>>,
    ) =>
        items.reduce(
            (promise, item) =>
                promise.then(async () => {
                    await this.importEntityWithEntirePath(
                        item,
                        idMap,
                        importedItemIpAddresses,
                        items,
                    );
                }),
            Promise.resolve(),
        );

    private importItemRelations = async (
        idMap: IdMap,
        importedItemIpAddresses: IPv4[],
        relations: Array<IPersistence<IItemRelationEntity>>,
    ) =>
        Promise.all(
            relations.map(async (relation) =>
                this.importEntityWithEntirePath(
                    relation,
                    idMap,
                    importedItemIpAddresses,
                    relations,
                ),
            ),
        );

    private findEntityToImportById = (id: Id, entities: ImportEntity[]) =>
        entities.find((entity) => entity._id === id);

    private addItemToRepository = async (
        item: IPersistence<IItemEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.itemRepository.add(
            {
                type: 'item',
                path,
                locked: false,
                archived: item.archived,
                name: item.name,
                color: item.color,
                description: item.description,
                notes: item.notes,
                productId: item.productId,
                quantity: item.quantity,
                properties: this.getImportableItemProperties(idMap, item.properties),
                replaceWithBareboneId: item.replaceWithBareboneId,
                networkSettings: item.networkSettings,
                analyticRange: item.analyticRange,
            },
            false,
        );

    private addPartnerItemToRepository = async (
        item: IPersistence<IPartnerItemEntity>,
        path: Id[],
    ) =>
        this.partnerItemRepository.add(
            {
                type: 'partnerItem',
                path,
                locked: false,
                archived: item.archived,
                name: item.name,
                productId: item.productId,
                quantity: item.quantity,
                vendor: item.vendor,
                url: item.url,
                vendorId: item.vendorId,
                properties: this.getImportablePartnerItemProperties(item.properties),
            },
            false,
        );

    private addFloorPlanToRepository = async (
        floorPlan: IPersistence<IFloorPlanEntity>,
        path: Id[],
    ) =>
        this.floorPlanRepository.add(
            {
                type: 'floorPlan',
                path,
                locked: false,
                archived: floorPlan.archived,
                name: floorPlan.name,
                image: floorPlan.image,
                mapType: floorPlan.mapType,
                location: floorPlan.location,
                isDefault: floorPlan.isDefault,
            },
            false,
        );

    private addFreeTextToRepository = async (
        freeText: IPersistence<IFreeTextPointEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.freeTextPointRepository.add(
            {
                type: 'freeTextPoint',
                path,
                locked: false,
                archived: freeText.archived,
                text: freeText.text,
                size: freeText.size,
                textColor: freeText.textColor,
                backgroundColor: freeText.backgroundColor,
                location: freeText.location,
                mapOrigin: this.resolveIdOrKeep(idMap, freeText.mapOrigin),
            },
            false,
        );

    private addBlockerToRepository = async (
        blocker: IPersistence<IBlockerEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.blockerRepository.add(
            {
                type: 'blocker',
                path,
                locked: false,
                archived: blocker.archived,
                floorLevel: blocker.floorLevel,
                latLngs: blocker.latLngs,
                mapOrigin: this.resolveIdOrKeep(idMap, blocker.mapOrigin),
            },
            false,
        );

    private addMapLocationToRepository = async (
        item: IPersistence<IMapLocationEntity>,
        path: Id[],
        _idMap: IdMap,
    ) =>
        this.mapLocationRepository.add(
            {
                type: 'mapLocation',
                path,
                locked: false,
                name: item.name,
                bounds: item.bounds,
                archived: item.archived,
            },
            false,
        );

    private addInstallationPointToRepository = async (
        installationPoint: IPersistence<IInstallationPointEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.installationPointRepository.add(
            {
                type: 'installationPoint',
                path,
                locked: false,
                archived: installationPoint.archived,
                location: installationPoint.location,
                mapOrigin: this.resolveIdOrKeep(idMap, installationPoint.mapOrigin),
                labelOffset: installationPoint.labelOffset || IP_LABEL_OFFSET,
                height: installationPoint.height,
                sensors: installationPoint.sensors,
                speaker: installationPoint.speaker,
                radar: installationPoint.radar,
                panRange: installationPoint.panRange,
                floorPlanId: installationPoint.floorPlanId
                    ? this.resolveIdOrThrow(idMap, installationPoint.floorPlanId)
                    : undefined,
                parentId: installationPoint.parentId,
                name: installationPoint.name,
                serialNumber: undefined,
            },
            false,
        );

    private addCustomItemToRepository = async (
        item: IPersistence<ICustomItemEntity>,
        path: Id[],
        _idMap: IdMap,
    ) =>
        this.customItemRepository.add(
            {
                category: item.category,
                type: item.type,
                path,
                locked: false,
                archived: item.archived,
                name: item.name,
                partnerProductId: item.partnerProductId,
                partNumber: item.partNumber,
                quantity: item.quantity,
                vendor: item.vendor,
            },
            false,
        );

    private addRelationToRepository = async (
        relation: IPersistence<IItemRelationEntity>,
        path: Id[],
        idMap: IdMap,
    ) =>
        this.itemRelationRepository.add(
            {
                childId: this.resolveIdOrThrow(idMap, relation.childId),
                parentId: this.resolveIdOrThrow(idMap, relation.parentId),
                path,
                locked: false,
                archived: relation.archived,
                relationType: relation.relationType,
                type: 'itemRelation',
            },
            false,
        );

    private getImportableItemProperties(
        idMap: IdMap,
        properties: IItemPropertiesEntity,
    ): IItemPropertiesEntity {
        const importableProperties: IItemPropertiesEntity = {};

        if (properties.analogCamera) {
            importableProperties.analogCamera = {
                associatedProfile: properties.analogCamera.associatedProfile
                    ? this.resolveIdOrThrow(idMap, properties.analogCamera.associatedProfile)
                    : '',
                profileOverride: this.getImportableProfileOverride(
                    idMap,
                    properties.analogCamera.profileOverride,
                ),
            };
        }

        if (properties.camera) {
            const { filter, associatedProfile, profileOverride, customCameraProperties } =
                properties.camera;

            importableProperties.camera = {
                filter: this.getCameraPropertiesFilterEntity(filter),
                associatedProfile: associatedProfile
                    ? this.resolveIdOrThrow(idMap, associatedProfile)
                    : '',
                profileOverride: this.getImportableProfileOverride(idMap, profileOverride),
                customCameraProperties:
                    this.getCustomCameraPropertiesEntity(customCameraProperties),
            };
        }

        if (properties.sensorUnit) {
            const { filter, associatedProfile, profileOverride } = properties.sensorUnit;

            importableProperties.sensorUnit = {
                filter: this.getCameraPropertiesFilterEntity(filter),
                associatedProfile: associatedProfile
                    ? this.resolveIdOrThrow(idMap, associatedProfile)
                    : '',
                profileOverride: this.getImportableProfileOverride(idMap, profileOverride),
            };
        }

        if (properties.virtualProduct) {
            const { associatedProfile, profileOverride } = properties.virtualProduct;
            importableProperties.virtualProduct = {
                associatedProfile: associatedProfile
                    ? this.resolveIdOrThrow(idMap, associatedProfile)
                    : '',
                profileOverride: this.getImportableProfileOverride(idMap, profileOverride),
            };
        }

        if (properties.encoder) {
            const filter = properties.encoder.filter;

            importableProperties.encoder = {
                filter: {
                    blade: filter.blade,
                    channels: filter.channels,
                    twoWayAudio: filter.twoWayAudio,
                    outdoor: filter.outdoor,
                },
            };
        }

        if (properties.mainUnit) {
            const filter = properties.mainUnit.filter;

            importableProperties.mainUnit = {
                filter: {
                    channels: filter.channels,
                    twoWayAudio: filter.twoWayAudio,
                    WDRTechnology: filter.WDRTechnology,
                    ruggedizedEN50155: filter.ruggedizedEN50155,
                    alarmInputsOutputs: filter.alarmInputsOutputs,
                    outdoor: filter.outdoor,
                },
            };
        }

        if (properties.doorController) {
            importableProperties.doorController = { filter: properties.doorController.filter };
        }

        if (properties.door) {
            importableProperties.door = {
                nbrOfLocks: properties.door.nbrOfLocks,
            };
        }

        if (properties.alerter) {
            importableProperties.alerter = { filter: properties.alerter.filter };
        }

        if (properties.speaker) {
            const filter = properties.speaker.filter;

            importableProperties.speaker = {
                filter: {
                    placement: filter.placement,
                    outdoor: filter.outdoor,
                    basicSolution: filter.basicSolution,
                    installationHeight: filter.installationHeight,
                    listeningArea: filter.listeningArea,
                    wallLength: filter.wallLength,
                    isFilterChanged: filter.isFilterChanged,
                },
            };
        }

        if (properties.systemComponent) {
            importableProperties.systemComponent = {};
        }

        if (properties.partnerSystemComponent) {
            const {
                vendorName,
                category,
                dataSheetUrl,
                imageUrl,
                maxCameraCount,
                maxRecordingBandwidthBits,
                maxRecordingStorageMegaBytes,
            } = properties.partnerSystemComponent;

            importableProperties.partnerSystemComponent = {
                vendorName,
                category,
                dataSheetUrl,
                imageUrl,
                maxCameraCount,
                maxRecordingBandwidthBits,
                maxRecordingStorageMegaBytes,
            };
        }

        if (properties.environment) {
            importableProperties.environment = {};
        }

        if (properties.pac) {
            importableProperties.pac = { filter: properties.pac.filter };
        }

        if (properties.radarDetector) {
            importableProperties.radarDetector = { filter: properties.radarDetector.filter };
        }

        if (properties.decoder) {
            importableProperties.decoder = { filter: properties.decoder.filter };
        }

        if (properties.systemAccessory) {
            importableProperties.systemAccessory = {};
        }

        if (properties.accessory) {
            importableProperties.accessory = properties.accessory;
        }

        if (properties.application) {
            importableProperties.application = properties.application;
        }

        if (properties.peopleCounter) {
            importableProperties.peopleCounter = { filter: properties.peopleCounter.filter };
        }

        if (properties.lens) {
            const { sensorIndex } = properties.lens;
            importableProperties.lens = { sensorIndex };
        }

        if (properties.bodyWornCamera) {
            importableProperties.bodyWornCamera = {
                profile: {
                    sceneId: properties.bodyWornCamera.profile.sceneId,
                    resolution: properties.bodyWornCamera.profile.resolution,
                    retentionTimeInDays: properties.bodyWornCamera.profile.retentionTimeInDays,
                    scheduleId: properties.bodyWornCamera.profile.scheduleId
                        ? this.resolveIdOrThrow(idMap, properties.bodyWornCamera.profile.scheduleId)
                        : '',
                    activeRecordingInPercent:
                        properties.bodyWornCamera.profile.activeRecordingInPercent,
                },
                filter: properties.bodyWornCamera.filter,
            };
        }

        if (properties.cameraExtension) {
            importableProperties.cameraExtension = { filter: defaultCameraExtensionFilter };
        }

        if (properties.dockingStation) {
            importableProperties.dockingStation = { filter: defaultDockingStationFilter };
        }

        if (properties.systemController) {
            importableProperties.systemController = {};
        }

        if (properties.connectivityDevice) {
            importableProperties.connectivityDevice = {
                filter: properties.connectivityDevice.filter,
            };
        }

        if (properties.pagingConsole) {
            importableProperties.pagingConsole = {
                filter: properties.pagingConsole.filter,
            };
        }

        return importableProperties;
    }

    private getImportableProfileOverride(
        idMap: IdMap,
        profileOverride: IProfileOverridePropertiesEntity,
    ): IProfileOverridePropertiesEntity {
        const {
            scenario,
            liveView,
            continuousRecording,
            triggeredRecording,
            storage,
            customBandwidth,
            audio,
            zipstream,
        } = profileOverride;

        const importableOverride: IProfileOverridePropertiesEntity = {
            scenario: {
                lightEnd: scenario.lightEnd,
                lightStart: scenario.lightStart,
                nightLighting: scenario.nightLighting,
                scenarioId: scenario.scenarioId,
                sceneDetails: scenario.sceneDetails,
            },
            liveView: this.getImportableProfileOverrideRecordingSettings(idMap, liveView),
            continuousRecording: this.getImportableProfileOverrideRecordingSettings(
                idMap,
                continuousRecording,
            ),
            triggeredRecording: this.getImportableProfileOverrideRecordingSettings(
                idMap,
                triggeredRecording,
            ),
            storage: {
                retentionTime: storage.retentionTime,
                useProjectSetting: storage.useProjectSetting,
            },
            audio: {
                liveViewEnabled: audio.liveViewEnabled,
                recordingEnabled: audio.recordingEnabled,
            },
            zipstream: {
                gopDefault: zipstream.gopDefault,
                gopMax: zipstream.gopMax,
                gopMode: zipstream.gopMode,
                minDynamicFps: zipstream.minDynamicFps,
                zipStrength: zipstream.zipStrength,
                fpsMode: zipstream.fpsMode,
                useProjectSetting: zipstream.useProjectSetting,
            },
            customBandwidth,
        };

        if (profileOverride.liveView.schedule) {
            importableOverride.liveView.schedule = this.resolveUndefinableOrNullId(
                idMap,
                profileOverride.liveView.schedule,
            );
        }

        if (profileOverride.triggeredRecording.schedule) {
            importableOverride.triggeredRecording.schedule = this.resolveUndefinableOrNullId(
                idMap,
                profileOverride.triggeredRecording.schedule,
            );
        }

        if (profileOverride.continuousRecording.schedule) {
            importableOverride.continuousRecording.schedule = this.resolveUndefinableOrNullId(
                idMap,
                profileOverride.continuousRecording.schedule,
            );
        }

        return importableOverride;
    }

    private getImportableProfileOverrideRecordingSettings(
        idMap: IdMap,
        settings: Partial<IRecordingSettingsEntity>,
    ): Partial<IRecordingSettingsEntity> {
        return {
            compression: settings.compression,
            dayMotion: settings.dayMotion,
            dayTriggerTime: settings.dayTriggerTime,
            frameRate: settings.frameRate,
            nightMotion: settings.nightMotion,
            nightTriggerTime: settings.nightTriggerTime,
            resolution: settings.resolution,
            schedule: this.resolveUndefinableOrNullId(idMap, settings.schedule),
            useAverageBitrate: settings.useAverageBitrate,
            videoEncoding: settings.videoEncoding,
        };
    }

    private getImportablePartnerItemProperties(
        properties: IPartnerItemPropertiesEntity,
    ): IPartnerItemPropertiesEntity {
        if (properties.application) {
            properties.application = {};
        }
        return properties;
    }

    private resolveIdOrThrow(idMap: IdMap, id: Id): Id {
        const resolvedId = idMap[id];

        if (!resolvedId) {
            throw Error(
                `Tried to resolve id using original id '${id}', but no id was mapped to it.`,
            );
        }

        return resolvedId._id;
    }

    private resolveNullableId(idMap: IdMap, id?: Id | null): Id | null {
        if (id) {
            return this.resolveIdOrThrow(idMap, id);
        }

        return null;
    }

    // in the case of profile overrides, null and undefined means different things
    // `undefined` means "not overridden"
    // `null` means "explicitly unset"
    private resolveUndefinableOrNullId(idMap: IdMap, id?: Id | null): Id | undefined | null {
        if (id === null) {
            return null;
        } else {
            return this.resolveNullableId(idMap, id) || undefined;
        }
    }

    private resolveIdOrNull(idMap: IdMap, id: Id): Id | null {
        const resolvedId = idMap[id];

        if (!resolvedId) {
            return null;
        }
        return resolvedId._id;
    }

    private resolveIdOrKeep(idMap: IdMap, id: Id): Id {
        try {
            return this.resolveIdOrThrow(idMap, id);
        } catch {
            return id;
        }
    }

    private getIdRevObject(entity: IPersistence<IEntity>): IIdRev {
        return {
            _id: entity._id,
            _rev: entity._rev,
        };
    }
}
