import { t } from 'app/translate';
import * as React from 'react';
import { Box, Button, Modal } from 'app/components';
import { getError, getLoaded, getProjectActionService } from 'app/modules/userProjects';
import { useSelector } from 'react-redux';
import { getImageQuota, getIsProjectOperationInProgress } from 'app/modules/common';
import { SelectProjectList } from './SelectProjectList';
import type { IStoreState, ProjectStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import { useNavigate } from 'react-router-dom';

interface ISelectProjectListModalProps {
    backupProject: boolean;
    onClose: () => void;
    projectStoreState: ProjectStoreState;
}

export const SelectProjectListModal: React.FC<ISelectProjectListModalProps> = ({
    backupProject,
    onClose,
    projectStoreState,
}) => {
    const projectLoaded = useSelector((state: IStoreState) => getLoaded(state, projectStoreState));
    const projectError = useSelector((state: IStoreState) => getError(state, projectStoreState));
    const imageQuota = useSelector(getImageQuota);
    const projectOperationInProgress = useSelector(getIsProjectOperationInProgress);
    const projectsActionService = useSelector(getProjectActionService);
    const [highlightedProjectId, setHighlightedProjectId] = React.useState<Id | null>(null);
    const navigate = useNavigate();

    const mergeSelectedProject = async (projectId: Id) => {
        eventTracking.logUserEvent('Merge project', 'from project');
        if (projectsActionService) {
            projectsActionService.mergeIntoProject(
                projectId,
                backupProject,
                navigate,
                imageQuota.usedBytes,
                imageQuota.totalBytes,
            );
        }

        onClose();
    };

    const onMergeSelectedClick = () => {
        if (projectsActionService) {
            projectsActionService.userProjectFilterChange('');
        }

        if (!highlightedProjectId) {
            return;
        }
        mergeSelectedProject(highlightedProjectId);
    };

    const onCloseClicked = () => {
        if (projectsActionService) {
            projectsActionService.userProjectFilterChange('');
        }

        onClose();
    };

    return (
        projectsActionService && (
            <Modal
                title={t.projectImportMerge}
                onClose={onClose}
                color="white"
                width="60%"
                maxWidth="650px"
            >
                <Box alignItems="start" spacing="base">
                    <SelectProjectList
                        actionService={projectsActionService}
                        state={projectStoreState}
                        loaded={projectLoaded}
                        error={projectError}
                        selectedId={highlightedProjectId}
                        onProjectSelected={(projectId) => setHighlightedProjectId(projectId)}
                    />
                </Box>
                <Box justifyContent="end" spacing="half">
                    <Button text onClick={onCloseClicked} disabled={projectOperationInProgress}>
                        {t.cancel}
                    </Button>
                    <Button primary onClick={onMergeSelectedClick} disabled={!highlightedProjectId}>
                        {t.selectProject}
                    </Button>
                </Box>
            </Modal>
        )
    );
};

SelectProjectListModal.displayName = 'MergeProject';
