import { ServiceLocator } from 'app/ioc';
import type { IApplicationItemEntity, Id, IItemRelationEntity } from 'app/core/persistence';
import { CreateEntityService } from 'app/core/persistence';
import type {
    ImportedItemBase,
    ImportedApplication,
} from '../../../../models/ImportedProjectSettingTypes';
import { createRelationEntity } from './createRelationEntity';

type ApplicationItem = IApplicationItemEntity | IItemRelationEntity;
/**
 * Creates an application item entity.
 * @param ancestors The ancestors of the application.
 * @param importedApplication The imported application.
 * @returns The created application.
 */
const createApplication = (
    ancestors: Id[],
    { id, name, description, notes, piaId, properties }: ImportedApplication,
): IApplicationItemEntity => {
    return {
        type: 'item',
        path: [...ancestors, id],
        name: name,
        description: description,
        notes: notes,
        archived: false,
        productId: piaId,
        quantity: 1,
        properties: { application: properties },
    };
};

export const getApplications = (ancestors: Id[], items: ImportedItemBase[]): ApplicationItem[] => {
    const createEntityService = ServiceLocator.get(CreateEntityService);
    return items.flatMap((item) => {
        const itemId = item.id;
        const path = [...ancestors, itemId];

        const applicationItems = (item.applications ?? []).flatMap((application) => {
            const relationId = createEntityService.generateDatabaseId('itemRelation');
            return [
                createApplication(path, application),
                createRelationEntity(relationId, path, application.id, application.relationType),
            ];
        });

        return [...applicationItems];
    });
};

export const getImportedApplications = (
    projectId: Id,
    items: ImportedItemBase[],
): ApplicationItem[] => {
    return getApplications([projectId], items);
};
