import * as React from 'react';
import type { IAutoTestable, IOptionProps } from 'app/components';
import { Select } from 'app/components';
import type { BandwidthVersion } from 'app/core/persistence';
import { ProjectService, VideoEncoding } from 'app/core/persistence';
import { ServiceLocator } from 'app/ioc';
import { eventTracking } from 'app/core/tracking';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { getCurrentProjectBandwidthVersion } from 'app/modules/common/project';

export interface IVideoEncodingProps extends IAutoTestable {
    videoEncoding: VideoEncoding;
    disabled?: boolean;
    onVideoEncodingChange(value: VideoEncoding): void;
}

const videoEncodingOptions: IOptionProps[] = [
    { text: 'H.264', value: VideoEncoding.h264 },
    { text: 'H.265', value: VideoEncoding.h265 },
    { text: 'MJPEG', value: VideoEncoding.mjpeg },
    { text: 'AV1', value: VideoEncoding.av1 },
];

export const VideoEncodingSelector: React.FC<IVideoEncodingProps> = (props) => {
    const [projectService] = React.useState(ServiceLocator.get(ProjectService));
    const projectBandwidthVersion = useSelector<IStoreState, BandwidthVersion>(
        getCurrentProjectBandwidthVersion,
    );

    const onVideoEncodingChange = async (videoEncoding: VideoEncoding) => {
        if (videoEncoding === VideoEncoding.mjpeg && projectBandwidthVersion < 2) {
            const confirm = await projectService.showUpgradeBandwidthDialog();
            if (!confirm) {
                eventTracking.logUserEvent('Application', 'Bandwidth update rejected - MJPEG');
                return;
            }
            eventTracking.logUserEvent('Application', 'Bandwidth update accepted - MJPEG');
        }

        props.onVideoEncodingChange(videoEncoding);
    };

    return (
        <Select
            testIdChild={`${props.testId}_video_encoding_selected`}
            value={props.videoEncoding}
            options={videoEncodingOptions}
            onChange={onVideoEncodingChange}
            disabled={props.disabled}
        />
    );
};
