import {
    Border,
    Box,
    Clickable,
    Label,
    Select,
    Spinner,
    Text,
    UploadFile,
    Positioned,
} from 'app/components';
import { eventTracking } from 'app/core/tracking';
import { t } from 'app/translate';
import React from 'react';
import * as pdfjsLib from 'pdfjs-dist';
// This is a workaround to fix the issue with the workerSrc not being set correctly
const workerSrc = require('pdfjs-dist/build/pdf.worker.min.mjs').default;
pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

const pdfFileWorker = new pdfjsLib.PDFWorker();

interface IPdfViewer {
    objectUrl: string;
    contentWidth: string;
    maxHeight: string;
    imageTypes: string;
    selectedDPI: number;
    selectedPage: number;
    onFileUploaded(file: File): void;
    setSelectedDPI(dpi: number): void;
    setSelectedPage(page: number): void;
}

const DPI_OPTIONS = [72, 150, 300];
type PdfFileLoadingState = 'no file selected' | 'loading' | 'loaded' | 'error';

export const PdfViewer: React.FC<IPdfViewer> = ({
    objectUrl,
    contentWidth,
    maxHeight,
    imageTypes,
    selectedDPI,
    selectedPage,
    onFileUploaded,
    setSelectedDPI,
    setSelectedPage,
}) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const [pageCount, setPageCount] = React.useState(1);
    const [pdfLoadingState, setPdfLoadingState] =
        React.useState<PdfFileLoadingState>('no file selected');

    const pages = React.useMemo(
        () =>
            Array.from(Array(pageCount)).map((_, val) => ({
                text: String(val + 1),
                value: val + 1,
            })),
        [pageCount],
    );

    React.useEffect(() => {
        const renderPDF = async () => {
            const canvas = canvasRef.current;
            if (!canvas) return;
            setPdfLoadingState('loading');
            const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D; // Ensure context is of type CanvasRenderingContext2D

            try {
                const pdfDocument = await pdfjsLib.getDocument({
                    url: objectUrl,
                    worker: pdfFileWorker,
                }).promise;
                setPageCount(pdfDocument.numPages);
                await renderPdfDocumentOnCanvas(
                    selectedDPI,
                    pdfDocument,
                    selectedPage,
                    canvas,
                    canvasContext,
                );
                setPdfLoadingState('loaded');
            } catch {
                setPdfLoadingState('error');
            }
        };

        renderPDF();
    }, [objectUrl, selectedPage, selectedDPI]);

    return (
        <Box direction="column" height="100%" spacing="base">
            <UploadFile accept={imageTypes} onFileUploaded={onFileUploaded}>
                <Clickable>
                    <Border color="grey4" radius="4px">
                        <Box
                            position="relative"
                            justifyContent="center"
                            alignItems="center"
                            width={contentWidth}
                            overflow="hidden"
                            maxHeight={maxHeight}
                        >
                            <canvas
                                ref={canvasRef}
                                style={{
                                    visibility: pdfLoadingState === 'loaded' ? 'visible' : 'hidden',
                                    maxHeight: maxHeight,
                                    maxWidth: contentWidth,
                                }}
                            />
                            {pdfLoadingState === 'loading' && (
                                <Positioned centerHorizontally position="absolute" top="40%">
                                    <Box>
                                        <Spinner />
                                    </Box>
                                </Positioned>
                            )}
                            {pdfLoadingState === 'error' && (
                                <Positioned centerHorizontally position="absolute" top="40%">
                                    <Box>
                                        <Text>{t.projectImportErrorsFileContentsUnreadable}</Text>
                                    </Box>
                                </Positioned>
                            )}
                        </Box>
                    </Border>
                </Clickable>
            </UploadFile>
            <Box spacing="base">
                {pageCount > 1 && (
                    <Select
                        label={t.selectPage}
                        options={pages}
                        onChange={(newVal) => {
                            eventTracking.logUserEvent('Maps', 'Select page');
                            setSelectedPage(Number(newVal));
                        }}
                        value={selectedPage}
                        openInPortal
                        fillWidth
                    />
                )}

                <Box direction="column" flex="fullWidth">
                    <Box spacing="halfCell">
                        <Label opaque>{t.resolution}</Label>
                        <Text style="small">{`(${t.pixelsPerInch})`}</Text>
                    </Box>

                    <Select
                        fillWidth
                        options={DPI_OPTIONS.map((val) => ({
                            text: String(val),
                            value: val,
                        }))}
                        onChange={(newVal) => {
                            eventTracking.logUserEvent('Maps', 'Change DPI', String(newVal));
                            setSelectedDPI(Number(newVal));
                        }}
                        value={selectedDPI}
                        openInPortal
                    />
                </Box>
            </Box>
        </Box>
    );
};

/** Helper function to render PDF document on canvas  */
async function renderPdfDocumentOnCanvas(
    selectedDPI: number,
    pdfDocument: pdfjsLib.PDFDocumentProxy,
    selectedPage: number,
    canvas: HTMLCanvasElement,
    canvasContext: CanvasRenderingContext2D,
) {
    const page = await pdfDocument.getPage(selectedPage);
    const scaledViewport = getScaledViewport(page, selectedDPI, canvas);
    const renderContext = {
        canvasContext: canvasContext,
        viewport: scaledViewport,
    };

    await page.render(renderContext).promise;
}

/** Helper function to get scaled viewport */
function getScaledViewport(
    page: pdfjsLib.PDFPageProxy,
    selectedDPI: number,
    canvas: HTMLCanvasElement,
) {
    const dpiFactor = selectedDPI / 72;
    const viewport = page.getViewport({ scale: dpiFactor });
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const scale =
        Math.min(canvas.width / viewport.width, canvas.height / viewport.height) * dpiFactor;
    const scaledViewport = page.getViewport({ scale });
    return scaledViewport;
}
