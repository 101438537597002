import type {
    IPersistence,
    IItemEntity,
    ItemRelationType,
    IItemRelationEntity,
} from 'app/core/persistence';
import { getPiaItemsRecord } from '../../../../piaDevices/selectors/getPiaDevices';

import { isDefined } from 'axis-webtools-util';
import { groupBy } from 'lodash-es';
import { createSelector } from 'reselect';
import type { ProductWithAccessory } from '../types';
import {
    getCurrentProjectItemRelationsArray,
    getCurrentProjectItems,
    getCurrentProjectItemsArray,
} from '../../../../project/selectors/getCurrentProject';
import { getCurrentProjectRelationsRecord } from '../../../../relations/selectors/getCurrentProjectRelations';
import { nameComparator } from 'app/utils';

const getSubAccessories = (
    accessories: IPersistence<IItemEntity>[],
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    types: ItemRelationType[],
    relationsRecord?: Record<string, IItemRelationEntity[]>,
): IPersistence<IItemEntity>[] => {
    if (accessories.length === 0) {
        return [];
    }
    const subRelations = relationsRecord
        ? accessories.flatMap((accessory) =>
              (relationsRecord[accessory._id] || []).filter((relation) =>
                  types.includes(relation.relationType),
              ),
          )
        : [];

    const metaAccessoryItems = subRelations.map((rel) => items[rel.childId]).filter(isDefined);
    const subAccessories = getSubAccessories(metaAccessoryItems, items, types, relationsRecord);
    return [...accessories, ...subAccessories];
};

export const getSelectedAcapsByProduct = createSelector(
    [
        getCurrentProjectItemsArray,
        getCurrentProjectItems,
        getPiaItemsRecord,
        getCurrentProjectRelationsRecord,
        getCurrentProjectItemRelationsArray,
    ],
    (itemsArray, items, piaItemsRecord, relationsRecord, relations): ProductWithAccessory => {
        const accessoriesByProduct: ProductWithAccessory = {};
        const accessoryRelationTypes: ItemRelationType[] = ['acap'];

        itemsArray.map((item) => {
            // If the item is not a product or the product is an acap, return
            if (
                !item.productId ||
                (item.productId && piaItemsRecord[item.productId]?.category === 'acap')
            ) {
                return;
            }

            const accessoryRelations = relations
                .filter(
                    (relation) =>
                        accessoryRelationTypes.includes(relation.relationType) &&
                        relation.parentId === item._id &&
                        relation.path.includes(item._id),
                )
                .map((relation) => items[relation.childId])
                .filter(isDefined);

            if (!accessoryRelations) {
                return;
            }
            // create empty accessories array for all items.
            accessoriesByProduct[item.productId] = [];

            // Recursively fetches sub accessories and adds them to accessoryItems
            const accessories = getSubAccessories(
                accessoryRelations,
                items,
                accessoryRelationTypes,
                relationsRecord,
            );

            accessoriesByProduct[item.productId] = Object.values(groupBy(accessories, 'productId'))
                .map((acc) => {
                    const productId = acc[0].productId;
                    return productId ? piaItemsRecord[productId] : undefined;
                })
                .filter(isDefined)
                .sort(nameComparator);
        });

        return accessoriesByProduct;
    },
);
