import {
    type Id,
    type IScheduleEntity,
    type ITimeSerieEntity,
    ScheduleModelService,
} from 'app/core/persistence';
import { ImportedSchedule, ImportedTimeSerie } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { CreateEntityService } from 'app/core/persistence/userDataPersistence';

enum Days {
    monday = 0b1000000, // 64
    tuesday = 0b0100000, // 32
    wednesday = 0b0010000, // 16
    thursday = 0b0001000, // 8
    friday = 0b0000100, // 4
    saturday = 0b0000010, // 2
    sunday = 0b0000001, // 1
}

@injectable()
export class ScheduleImporterService {
    constructor(private createEntityService: CreateEntityService) {}

    public importSchedules(
        projectId: Id,
        schedules: ImportedSchedule[],
    ): (IScheduleEntity | ITimeSerieEntity)[] {
        if (!schedules.length) {
            // Create default schedules if no schedules are imported
            const defaultEntities = this.getDefaultSchedules(projectId);
            return defaultEntities;
        }

        const scheduleEntities = this.filterDuplicatedAlwaysSchedules(
            schedules.map((schedule) => this.mapToScheduleEntity(projectId, schedule)),
        );

        const timeSerieEntities = schedules.flatMap((schedule) =>
            this.mapToTimeSerieEntities(projectId, schedule),
        );
        return [...scheduleEntities, ...timeSerieEntities];
    }

    private getDefaultSchedules(projectId: string) {
        const scheduleModels = ScheduleModelService.getDefaultScheduleModels();
        const defaultEntities = scheduleModels.reduce(
            (entities, schedule) => {
                const id = this.createEntityService.generateDatabaseId('schedule');
                const timeSerieIds = schedule.timeSeries.map(() =>
                    this.createEntityService.generateDatabaseId('timeSerie'),
                );

                const scheduleEntity = ScheduleModelService.mapToScheduleEntity(
                    projectId,
                    schedule,
                    id,
                );
                const timeSeriesEntities = ScheduleModelService.mapToTimeSeriesEntities(
                    scheduleEntity,
                    schedule.timeSeries,
                    timeSerieIds,
                );

                return [...entities, scheduleEntity, ...timeSeriesEntities];
            },
            [] as (IScheduleEntity | ITimeSerieEntity)[],
        );
        return defaultEntities;
    }

    private mapToScheduleEntity(projectId: Id, schedule: ImportedSchedule): IScheduleEntity {
        const isAlwaysSchedule = this.checkIfAlwaysSchedule(schedule);
        return {
            name: schedule.name,
            type: 'schedule',
            path: [projectId, schedule.id],
            systemDefined: isAlwaysSchedule,
            archived: false,
        } satisfies IScheduleEntity;
    }

    private checkIfAlwaysSchedule(schedule: ImportedSchedule): boolean {
        const timeData = schedule.timeData;
        const hasTimeData = Object.values(timeData).some((day) => day.length > 0);

        const isAlwaysSchedule =
            timeData.monday.every((times) => times.start === '00:00' && times.end === '24:00') &&
            timeData.tuesday.every((times) => times.start === '00:00' && times.end === '24:00') &&
            timeData.wednesday.every((times) => times.start === '00:00' && times.end === '24:00') &&
            timeData.thursday.every((times) => times.start === '00:00' && times.end === '24:00') &&
            timeData.friday.every((times) => times.start === '00:00' && times.end === '24:00') &&
            timeData.saturday.every((times) => times.start === '00:00' && times.end === '24:00') &&
            timeData.sunday.every((times) => times.start === '00:00' && times.end === '24:00');
        return hasTimeData && isAlwaysSchedule;
    }

    private mapToTimeSerieEntities(projectId: Id, schedule: ImportedSchedule): ITimeSerieEntity[] {
        const timeData = schedule.timeData;
        const mondayTimeSeries = timeData.monday.map((segment) =>
            this.mapToTimeSerieEntity(projectId, schedule.id, segment, Days.monday),
        );
        const tuesdayTimeSeries = timeData.tuesday.map((segment) =>
            this.mapToTimeSerieEntity(projectId, schedule.id, segment, Days.tuesday),
        );
        const wednesdayTimeSeries = timeData.wednesday.map((segment) =>
            this.mapToTimeSerieEntity(projectId, schedule.id, segment, Days.wednesday),
        );
        const thursdayTimeSeries = timeData.thursday.map((segment) =>
            this.mapToTimeSerieEntity(projectId, schedule.id, segment, Days.thursday),
        );
        const fridayTimeSeries = timeData.friday.map((segment) =>
            this.mapToTimeSerieEntity(projectId, schedule.id, segment, Days.friday),
        );
        const saturdayTimeSeries = timeData.saturday.map((segment) =>
            this.mapToTimeSerieEntity(projectId, schedule.id, segment, Days.saturday),
        );
        const sundayTimeSeries = timeData.sunday.map((segment) =>
            this.mapToTimeSerieEntity(projectId, schedule.id, segment, Days.sunday),
        );
        return this.mergeSimilarTimeSeries([
            ...mondayTimeSeries,
            ...tuesdayTimeSeries,
            ...wednesdayTimeSeries,
            ...thursdayTimeSeries,
            ...fridayTimeSeries,
            ...saturdayTimeSeries,
            ...sundayTimeSeries,
        ]);
    }

    private mapToTimeSerieEntity(
        projectId: Id,
        scheduleId: Id,
        segment: ImportedTimeSerie,
        day: Days,
    ): ITimeSerieEntity {
        const timeSerieId = this.createEntityService.generateDatabaseId('timeSerie');
        return {
            start: segment.start,
            end: segment.end,
            type: 'timeSerie',
            path: [projectId, scheduleId, timeSerieId],
            days: day,
            archived: false,
        } satisfies ITimeSerieEntity;
    }

    private filterDuplicatedAlwaysSchedules(schedules: IScheduleEntity[]): IScheduleEntity[] {
        let foundAlwaysSchedule = false;
        return schedules.filter((schedule) => {
            if (schedule.systemDefined && foundAlwaysSchedule) {
                return false;
            }

            if (schedule.systemDefined) {
                foundAlwaysSchedule = true;
            }

            return true;
        });
    }

    private mergeSimilarTimeSeries(timeSeries: ITimeSerieEntity[]): ITimeSerieEntity[] {
        const mergedTimeSeries = timeSeries.reduce((accTimeSeries, timeSerie) => {
            const similarTimeSerie = accTimeSeries.find((merged) =>
                this.areTimeSeriesSimilar(merged, timeSerie),
            );

            if (similarTimeSerie) {
                similarTimeSerie.days |= timeSerie.days;
                return accTimeSeries;
            }

            return [...accTimeSeries, timeSerie];
        }, [] as ITimeSerieEntity[]);
        return mergedTimeSeries;
    }

    private areTimeSeriesSimilar(
        timeSerie1: ITimeSerieEntity,
        timeSerie2: ITimeSerieEntity,
    ): boolean {
        return timeSerie1.start === timeSerie2.start && timeSerie1.end === timeSerie2.end;
    }
}
