import { injectable } from 'inversify';
import type { Id, IScheduleModel } from 'app/core/persistence';
import { ScheduleModelService, Time24 } from 'app/core/persistence';
import type { IExportedSchedule, IScheduleTimeData, ITimeSerie } from '../../../models';
import { ScheduleAggregationService } from 'app/modules/common';
import type { IAggregatedScheduleModel, ISegmentModel } from 'app/modules/common';

@injectable()
export class ScheduleExporterService {
    constructor(
        private scheduleModelService: ScheduleModelService,
        private scheduleAggregationService: ScheduleAggregationService,
    ) {}

    public async getExportedSchedules(projectId: Id): Promise<IExportedSchedule[]> {
        const schedules = await this.scheduleModelService.getProjectSchedules(projectId);
        return schedules.map((schedule) => this.mapScheduleToExportedSchedule(schedule));
    }

    private mapScheduleToExportedSchedule = (schedule: IScheduleModel): IExportedSchedule => {
        const aggregatedSchedule = this.scheduleAggregationService.getAggregatedSchedule(schedule);

        return {
            id: schedule.id,
            revision: schedule.rev,
            name: schedule.name,
            timeData: this.mapAggregatedToExportedTimeData(aggregatedSchedule),
        };
    };

    private mapAggregatedToExportedTimeData = (
        aggregatedSchedule: IAggregatedScheduleModel,
    ): IScheduleTimeData => {
        return {
            monday: aggregatedSchedule.monday.map(this.mapSegmentToExportedTimeSerie),
            tuesday: aggregatedSchedule.tuesday.map(this.mapSegmentToExportedTimeSerie),
            wednesday: aggregatedSchedule.wednesday.map(this.mapSegmentToExportedTimeSerie),
            thursday: aggregatedSchedule.thursday.map(this.mapSegmentToExportedTimeSerie),
            friday: aggregatedSchedule.friday.map(this.mapSegmentToExportedTimeSerie),
            saturday: aggregatedSchedule.saturday.map(this.mapSegmentToExportedTimeSerie),
            sunday: aggregatedSchedule.sunday.map(this.mapSegmentToExportedTimeSerie),
        };
    };

    private mapSegmentToExportedTimeSerie = (segment: ISegmentModel): ITimeSerie => {
        return {
            start: new Time24(segment.start).toString(),
            end: new Time24(segment.end).toString(),
        };
    };
}
