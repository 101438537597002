import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState, ProjectStoreState } from 'app/store';
import { t } from 'app/translate';
import { getCurrentProjectId, TableHeaderSort } from 'app/modules/common';
import {
    LoadingIndicator,
    Box,
    Icon,
    Text,
    LazyRender,
    Opacity,
    SearchField,
    Border,
    Spacer,
    Positioned,
} from 'app/components';
import { SelectableProjectItem } from './SelectableProjectItem';
import { eventTracking } from 'app/core/tracking';

import { isCustomPouchError } from 'app/utils';

import { getMigrationProgress } from 'app/modules/application';
import type { IProjectActionService, IProjectListSort } from 'app/modules/userProjects';
import {
    getSorting,
    getUserProjectNameFilter,
    getVisibleUserProjects,
} from 'app/modules/userProjects';
import { ProjectProgressBar } from 'app/modules/userProjects/components';
import { type Id } from 'app/core/persistence';
import { useMount } from 'app/hooks';

interface IProjectListProps {
    actionService: IProjectActionService;
    state: ProjectStoreState;
    loaded: boolean;
    error: Error | null;
    selectedId: Id | null;
    onProjectSelected: (projectId: string) => void;
}

export const SelectProjectList: React.FC<IProjectListProps> = ({
    state,
    actionService,
    loaded,
    error,
    selectedId,
    onProjectSelected,
}) => {
    const visibleProjects = useSelector((storeState: IStoreState) =>
        getVisibleUserProjects(storeState, state),
    );
    const selectedSorting = useSelector((storeState: IStoreState) => getSorting(storeState, state));
    const projectsFilter = useSelector((storeState: IStoreState) =>
        getUserProjectNameFilter(storeState, state),
    );
    const progress = useSelector((storeState: IStoreState) =>
        getMigrationProgress(storeState, state),
    );

    const currentProjectId = useSelector(getCurrentProjectId);

    useMount(() => {
        // If visible projects are empty, it could depend on that projects are not loaded in state, reload the projects
        if (visibleProjects.length === 0) {
            actionService.reloadProjects();
        }
    });

    const onSortingChanged = (sortProperty: IProjectListSort['sort']) => {
        if (
            !selectedSorting.sort.includes(sortProperty) ||
            selectedSorting.direction === 'descending'
        )
            actionService.setSortOrder({
                sort: sortProperty,
                direction: 'ascending',
            });
        else
            actionService.setSortOrder({
                sort: sortProperty,
                direction: 'descending',
            });

        eventTracking.logUserEvent('Merge project', 'Change Sort Order', sortProperty);
    };

    const updateProjectNameFilter = (value: string) => {
        actionService.userProjectFilterChange(value);
    };

    const isReplicating = progress > 0 && progress < 1;

    if (error) {
        return <div>{isCustomPouchError(error) ? error.reason : error.message}</div>;
    }
    if (visibleProjects.length < 1 && !loaded && !isReplicating) {
        return <LoadingIndicator message={t.projectWaitWhileLoadingProjects} />;
    }

    return (
        <Box direction="column" flex="fullWidth" paddingBottom="doublePanel">
            <Box justifyContent="end" paddingBottom="panel">
                <SearchField value={projectsFilter} onValueChange={updateProjectNameFilter} />
            </Box>
            <Box direction="column" position="relative">
                <Border color="grey3" bottomWidth={1}>
                    <Box spacing="half" alignItems="center">
                        <Spacer horizontal customSpacing="40px" />
                        <Box flex="shrinkAndGrow">
                            <TableHeaderSort
                                onClick={() => onSortingChanged('name')}
                                direction={
                                    selectedSorting.sort === 'name'
                                        ? selectedSorting.direction
                                        : 'none'
                                }
                            >
                                <Text color="grey6">{t.name} </Text>
                            </TableHeaderSort>
                        </Box>
                        <Box flex="none" width="180px">
                            <TableHeaderSort
                                onClick={() => onSortingChanged('updated')}
                                direction={
                                    selectedSorting.sort === 'updated'
                                        ? selectedSorting.direction
                                        : 'none'
                                }
                            >
                                <Text color="grey6">{t.changed} </Text>
                            </TableHeaderSort>
                        </Box>
                        <Positioned position="absolute" right={0}>
                            <Box>
                                <Opacity
                                    alpha={isReplicating ? 1 : 0}
                                    animation="opacity 200ms ease-in 1s"
                                >
                                    <Text color="grey4" align="right" whiteSpace="nowrap">
                                        {`${Math.round(progress * 100)} %`}
                                    </Text>
                                </Opacity>
                            </Box>
                        </Positioned>
                    </Box>
                </Border>
                <ProjectProgressBar progress={progress} />
            </Box>
            {visibleProjects.length === 0 && loaded && (
                <Box padding="half" maxHeight="400px" minHeight="400px" alignItems="start">
                    <Box spacing="half" alignItems="center">
                        <Icon icon="list" size="lg" color="grey6" />
                        <Text semiBold color="grey6">
                            {t.emptyList}
                        </Text>
                    </Box>
                </Box>
            )}
            {(visibleProjects.length > 0 || isReplicating) && (
                <Box
                    direction="column"
                    lineBetweenColor="grey1"
                    flex="fullWidth"
                    overflowY="scroll"
                    maxHeight="400px"
                    minHeight="400px"
                >
                    {visibleProjects
                        .filter((project) => project.id !== currentProjectId)
                        .map((project, index) => (
                            <LazyRender
                                key={project.id}
                                initiallyVisible={index < 20}
                                heightEstimate={56}
                            >
                                <SelectableProjectItem
                                    project={project}
                                    onProjectSelected={(projectId) => onProjectSelected(projectId)}
                                    selectedId={selectedId}
                                />
                            </LazyRender>
                        ))}
                </Box>
            )}
        </Box>
    );
};
