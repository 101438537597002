import { t } from 'app/translate';
import { injectable } from 'inversify';
import type {
    IProjectEntity,
    IPersistence,
    Id,
    IProject,
    BandwidthVersion,
} from 'app/core/persistence';
import {
    ProjectService,
    DuplicationService,
    FloorPlanService,
    ProjectDbOrigin,
    ProjectDbOriginAsdLocalUserData,
} from 'app/core/persistence';
import { toaster } from 'app/toaster';

import { LocationQueriesService } from './LocationQueries.service';
import {
    ProjectDefaultsService,
    CommonActionService,
    getUserCountryCode,
    getBaseProjectEntity,
} from 'app/modules/common';
import { AppConstants } from 'app/AppConstants';
import type { IProjectListItem } from '../models';
import { mapGeneratedTokenToProjectSettingToken } from '../selectors/getProjectSettingToken';

@injectable()
export class UserProjectsService {
    constructor(
        private projectService: ProjectService,
        private locationQueryService: LocationQueriesService,
        private projectDefaultsService: ProjectDefaultsService,
        private duplicationService: DuplicationService,
        private floorPlanService: FloorPlanService,
        private commonActionService: CommonActionService,
    ) {}

    public async initialize(): Promise<IProjectListItem[]> {
        const projects = await this.projectService.getAllProjectEntities();
        return projects.map(this.mapToUserProjectsListItem);
    }

    /**
     * @deprecated
     * use getProjectWithEntity instead
     */
    public async getProject(id: Id): Promise<IProjectListItem> {
        const project = await this.projectService.get(id);
        return this.mapToUserProjectsListItemWithProjectEntity(project);
    }

    public async getProjectBandwidthVersion(id: Id): Promise<BandwidthVersion> {
        const project = await this.projectService.get(id);
        return project.bandwidthVersion;
    }

    public async getProjectWithEntity(id: Id): Promise<IProjectListItem> {
        const project = await this.projectService.get(id);
        return this.mapToUserProjectsListItemWithProjectEntity(project);
    }

    public async getProjectsWithEntity(ids: Id[]): Promise<IProjectListItem[]> {
        const projects = await Promise.all(ids.map((id) => this.projectService.get(id)));
        return Promise.all(
            projects.map((project) => this.mapToUserProjectsListItemWithProjectEntity(project)),
        );
    }

    public async addProjectListItemWithEntity(
        userAccountCountryCode: string | null,
        projectDbOrigin: ProjectDbOrigin,
    ): Promise<IProjectListItem> {
        const baseProjectEntity = await this.newBaseProject(
            userAccountCountryCode,
            projectDbOrigin,
        );

        let project = await this.projectService.add(baseProjectEntity);
        try {
            project = await this.projectDefaultsService.addProjectDefaults(project._id);
        } catch (e) {
            await this.projectService.deleteProject(project._id, project._rev);
            throw e;
        }
        const mapped = this.mapToUserProjectsListItemWithProjectEntity(project);
        return mapped;
    }

    public async duplicateProject(
        id: Id,
        projectNameSuffix?: string,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<IProjectListItem> {
        const newProjectId = await this.duplicationService.duplicateProject(
            id,
            projectNameSuffix,
            usedImageQuota,
            totalImageQuota,
        );
        this.commonActionService.getUserImageQuota();
        return this.getProjectWithEntity(newProjectId);
    }

    public async duplicateProjectWithoutFloorPlans(
        id: Id,
        projectNameSuffix?: string,
    ): Promise<IProjectListItem> {
        const newProjectId = await this.duplicationService.duplicateProjectWithoutFloorPlans(
            id,
            projectNameSuffix,
        );
        return this.getProjectWithEntity(newProjectId);
    }

    public async deleteProject(id: Id, rev: string): Promise<string> {
        await this.floorPlanService.removeFloorPlanImages(id);
        this.commonActionService.getUserImageQuota();
        return this.projectService.deleteProject(id, rev);
    }

    public async updateProjectTitle(modifiedProjectTitle: string, id: Id) {
        return this.projectService.update(id, { name: modifiedProjectTitle });
    }

    public async updateInstallationReportText(text: string, id: Id) {
        return this.projectService.update(id, { installationReportNotes: text });
    }

    public async setArchived(id: Id, archived: boolean): Promise<void> {
        await this.projectService.updateArchivedStatus(id, archived);

        const project = await this.projectService.get(id);
        if (project.projectDbOrigin === ProjectDbOriginAsdLocalUserData) {
            // don't show any toast for local projects
            return;
        }

        if (archived) {
            toaster.success(t.projectWasArchivedHeader, t.projectWasArchivedMessage);
        } else {
            toaster.success(t.projectWasRestoredHeader, t.projectWasRestoredMessage);
        }
    }

    public async updateExportDate(id: Id): Promise<IProjectListItem> {
        const updated = await this.projectService.update(id, {
            lastExportedDate: new Date().toISOString(),
        });
        return this.mapToUserProjectsListItemWithProjectEntity(updated);
    }

    private async newBaseProject(
        userAccountCountryCode: string | null,
        projectDbOrigin: ProjectDbOrigin,
    ): Promise<Omit<IProject, 'bandwidthVersion'>> {
        const piaLocation = this.locationQueryService
            .getByCountryCode(
                userAccountCountryCode ||
                    (await getUserCountryCode()) ||
                    AppConstants.defaultLocation,
            )
            .first();
        return getBaseProjectEntity(piaLocation, projectDbOrigin);
    }

    // TODO: We want to change IUserProjectsListItem to use IIdRev instead of IIdRevModel.
    // TODO: Once this is done, id: project._id and rev: project._rev should be removed from this methods return
    private mapToUserProjectsListItemWithProjectEntity = async (
        project: IPersistence<IProjectEntity>,
    ): Promise<IProjectListItem> => {
        // we need to update the rev, since getLatestChangedWithItemEntities will do an update
        // of the project entity if date is changed and a writeEntity is done.
        // normally the PersistenceActions.PROJECT_UPDATED in UserProjects reducer will handle this
        // but during import/duplicate the new project is not yet added.
        const { latestChanged, projectUpdateRev } =
            await this.projectService.getLatestChangedWithItemEntities(project._id);

        return {
            ...project,
            archived: Boolean(project.archived),
            id: project._id,
            rev: projectUpdateRev,
            creationDate: new Date(project.creationDate),
            shareToken: mapGeneratedTokenToProjectSettingToken(project.shareToken),
            updatedDate: latestChanged,
            lastExportedDate: new Date(project.lastExportedDate),
            state: project.state,
        };
    };

    /**
     * Map a project entity to a list item
     */
    private mapToUserProjectsListItem = (
        project: IPersistence<IProjectEntity>,
    ): IProjectListItem => {
        return {
            ...project,
            archived: Boolean(project.archived),
            id: project._id,
            rev: project._rev,
            creationDate: new Date(project.creationDate),
            shareToken: mapGeneratedTokenToProjectSettingToken(project.shareToken),
            updatedDate: new Date(project.updatedDate),
            lastExportedDate: new Date(project.lastExportedDate),
            state: project.state,
        };
    };
}
