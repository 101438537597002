import { defaultSpeakerFilter, type ISpeakerPropertiesFilterEntity } from '../userDataPersistence';

export function getDefaultSpeakerFilterEntity(
    installationHeight: number,
): ISpeakerPropertiesFilterEntity {
    return {
        ...defaultSpeakerFilter,
        installationHeight,
    };
}
