import type { Id, IItemRelationEntity, ItemRelationType } from 'app/core/persistence';

export const createRelationEntity = (
    id: Id,
    parentPath: Id[],
    childId: Id,
    relationType: ItemRelationType,
): IItemRelationEntity => {
    return {
        type: 'itemRelation',
        relationType,
        childId: childId,
        parentId: parentPath[parentPath.length - 1],
        path: [...parentPath, id],
        archived: false,
    } satisfies IItemRelationEntity;
};
