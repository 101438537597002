import { optional, type Schema } from 'app/modules/common';
import { MAP_SCHEMA } from './mapsSchema';
import { ITEMS_SCHEMA } from './itemsSchema';

import { dateValidator, timeValidator, fileVersionValidator } from './customValidators';

const TIME_RANGE_SCHEMA: Schema = {
    start: timeValidator,
    end: timeValidator,
};

const SCHEDULE_SCHEMA: Schema = {
    id: optional('string'),
    revision: optional('string'),
    name: 'string',
    timeData: {
        monday: [TIME_RANGE_SCHEMA],
        tuesday: [TIME_RANGE_SCHEMA],
        wednesday: [TIME_RANGE_SCHEMA],
        thursday: [TIME_RANGE_SCHEMA],
        friday: [TIME_RANGE_SCHEMA],
        saturday: [TIME_RANGE_SCHEMA],
        sunday: [TIME_RANGE_SCHEMA],
    },
};

const ZONE_SCHEMA: Schema = {
    id: 'string',
    revision: 'string',
    name: 'string',
};

const SETTINGS_SCHEMA: Schema = {
    id: optional('string'),
    linkedProjectId: optional('string'),
    revision: optional('string'),
    exportDate: optional(dateValidator),
    name: 'string',
    notes: optional('string'),
    schedules: optional([SCHEDULE_SCHEMA]),
    zones: optional([ZONE_SCHEMA]),
    items: optional(ITEMS_SCHEMA),
    maps: optional([MAP_SCHEMA]),
};

export const PROJECT_SCHEMA: Schema = {
    fileVersion: fileVersionValidator,
    fileType: 'string',
    settings: SETTINGS_SCHEMA,
};
