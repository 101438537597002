import { t } from 'app/translate';
import { injectable } from 'inversify';
import { IFlagsmithFeature } from 'flagsmith/types';
import { BannerVisibility } from '../models';
import { ApplicationActionService } from '../../services';
import * as moment from 'moment';

export enum NotificationBannerStatus {
    LoginMessage = 1,
    TechninalIssues = 2,
    Maintenance = 3,
    ScheduledMaintenance = 4,
}

/**
 * Interface representing the information for a FlagSmith notification.
 */
export interface IFlagSmithNotificationBannerInfo {
    /**
     * [Boolean - IFlagsmithFeature.enabled] - The overall notification feature itself. We check if this is enabled or not.
     */
    feature: IFlagsmithFeature;
    /**
     * [Number - IFlagsmithFeature.value] - The status/mode of the banner associated with the notification e.g. what prepared message to show.
     * The value should be one of the NotificationBannerStatus enum values.
     */
    status: IFlagsmithFeature;
    /**
     * [Boolean - IFlagsmithFeature.value] - Indicates whether the notification is mandatory. If false the user can choose to hide the notification banner.
     */
    mandatory?: IFlagsmithFeature;
    /**
     * [String - IFlagsmithFeature.value] - A date and time in UTC that will be part of the message in the banner.
     * IMPORTANT: dateTimeUTC should be written on the format 'YYYY-MM-DD HH:mm' with UTC timezone (https://time.is/sv/UTC).
     * Only used for when scheduling a maintenance e.g when bannerStatus has the value of 4.
     * Example: If we would like to start maintenance at 12:00 local swedish time, in winter, we can check by using the link that the UTC time is 1 hour behind,
     * so we would write '2024-11-18 11:00' in the dateTimeUTC field.
     */
    dateTimeUTC?: IFlagsmithFeature;
}

@injectable()
export class NotificationBannerService {
    constructor(private applicationActionService: ApplicationActionService) {}

    // If any information in the flags has changed, we show the banner even if it was hidden by the user unless feature disabled of course.
    // Returns the message to show in the banner or null if the banner should not be shown.
    public checkNotificationBannerFlags(flags: IFlagSmithNotificationBannerInfo): string | null {
        if (!flags.feature.enabled) {
            // If the feature is not enabled anymore, update visibility so no banner or icon is visible.
            this.setNotificationBannerVisibility('feature_disabled');
            return null;
        }
        // We don't show the banner if the message is not accurate.
        const validMessage = this.getValidBannerMessage(flags);
        if (!validMessage) {
            this.setNotificationBannerVisibility('feature_disabled');
            return null;
        }

        this.setNotificationBannerVisibility('visible', Boolean(flags.mandatory?.value));
        return validMessage;
    }

    public setNotificationBannerVisibility(
        visibility: BannerVisibility,
        mandatory?: boolean,
    ): void {
        // Mandatory overrides the visible property
        const value = visibility !== 'feature_disabled' && mandatory ? 'visible' : visibility;
        this.applicationActionService.setNotificationBannerVisibility(value);
    }

    private getValidBannerMessage(flags: IFlagSmithNotificationBannerInfo): string | null {
        switch (flags.status.value) {
            case NotificationBannerStatus.LoginMessage:
                return t.notificationBannerLoginMessage;
            case NotificationBannerStatus.TechninalIssues:
                return t.notificationBannerTechnicalIssues;
            case NotificationBannerStatus.Maintenance:
                return t.notificationBannerMaintenance;
            case NotificationBannerStatus.ScheduledMaintenance:
                const dateTime = this.getValidDateTimeUTC(flags.dateTimeUTC?.value);
                return dateTime ? t.notificationBannerScheduledMaintenance(dateTime) : null;
            default:
                return null;
        }
    }

    private getValidDateTimeUTC(
        dateTime: string | number | boolean | null | undefined,
        validFormats: moment.MomentFormatSpecification = 'YYYY-MM-DD',
    ): string | null {
        const dateTimeString = moment.utc(dateTime as string);

        return this.isDateTimeValid(dateTimeString, validFormats)
            ? this.getValidDateTime(dateTimeString)
            : null;
    }

    private isDateTimeValid(
        dateTimeString: moment.Moment,
        validFormats: moment.MomentFormatSpecification,
    ): boolean {
        return (
            moment(dateTimeString, validFormats, true).isValid() &&
            moment(dateTimeString).isAfter(moment.now())
        );
    }

    private getValidDateTime(dateTime: moment.Moment) {
        return dateTime.local().format('LLLL');
    }
}
