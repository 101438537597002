import * as React from 'react';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { MainPanel } from 'app/modules/application';
import { CommonActionService, getCurrentProject } from 'app/modules/common';
import { ProjectRoutes } from './components/ProjectRoutes.component';
import { LoadingIndicator } from 'app/components';
import { ProjectMainMenu } from './components/ProjectMainMenu.component';
import { ProjectError } from './components/ProjectMissing.component';
import { ValidationTrigger, isRecordingSolutionValid } from 'app/modules/recordingSelector';
import { POUCHDB_ERROR_REASON_QUOTA_EXCEEDED } from 'app/errorReporterMiddleware';
import { isCustomPouchError } from 'app/utils';
import { useService } from 'app/ioc';
import { ModalService } from 'app/modal';
import { DocumentationActionService } from 'app/modules/documentation';
import { MapsActionService } from 'app/modules/maps';
import { ProjectDevicesActionService } from 'app/modules/projectDevices';
import { RecordingSelectorActionService } from 'app/modules/recordingSelector/services';
import { useCoreLicenseUpdate } from './useCoreLicenseUpdate.hook';
import { getSelectedResourceGroup, SharedProjectsActionService } from 'app/modules/userProjects';
import type { ISharedProjectInfoLocalStorage } from 'app/core/persistence';
import { getItemLocalStorageJSON } from 'app/core/persistence';
import { useParams } from 'react-router-dom';

interface IProjectProps {
    isSharedProject?: boolean;
}
export const Project: React.FC<IProjectProps> = ({ isSharedProject = false }) => {
    const namespace = useSelector<IStoreState, ApplicationNamespace>(
        (state) => state.app.appSettings.namespace,
    );
    const validRecordingSolution = useSelector(isRecordingSolutionValid);

    // hooked called to update core-licenses in recording solution
    useCoreLicenseUpdate();
    const brokenProject = useSelector<IStoreState, boolean>(
        (state) => state.currentProject.projectBroken,
    );
    const loading = useSelector<IStoreState, boolean>(
        (state) => !state.currentProject.projectLoaded,
    );
    const error = useSelector<IStoreState, Error | null>(
        (state) => state.currentProject.projectError,
    );
    const project = useSelector(getCurrentProject);
    const commonActionService = useService(CommonActionService);
    const mapsActionService = useService(MapsActionService);
    const recordingActionService = useService(RecordingSelectorActionService);
    const projectDeviceActionsService = useService(ProjectDevicesActionService);
    const documentationActionService = useService(DocumentationActionService);
    const modalService = useService(ModalService);
    const sharedProjectsActionService = useService(SharedProjectsActionService);
    const currentResourceGroup = useSelector(getSelectedResourceGroup);

    const projectId = useParams().projectId ?? '/';

    React.useEffect(() => {
        if (brokenProject) {
            const showModal = async () => {
                await modalService
                    .createConfirmDialog({
                        header: t.repairProjectHeader,
                        body: t.repairProjectBody,
                        confirmButtonText: t.gotIt,
                    })()
                    .then(() => commonActionService.confirmProjectBroken(projectId));
                return;
            };
            showModal();
        }
    }, [brokenProject, commonActionService, modalService, projectId]);

    React.useEffect(() => {
        if (isSharedProject) {
            let resourceGroupArn = currentResourceGroup?.arn;
            if (!resourceGroupArn) {
                const sharedProjectInfoLocalStorage =
                    getItemLocalStorageJSON<ISharedProjectInfoLocalStorage>(
                        'SharedProjectInfo',
                        {},
                    );
                if (sharedProjectInfoLocalStorage.current?.resourceGroup.arn) {
                    resourceGroupArn = sharedProjectInfoLocalStorage.current?.resourceGroup.arn;
                }
            }
            if (resourceGroupArn) {
                sharedProjectsActionService.initializeStorage(resourceGroupArn);
                sharedProjectsActionService.changeProjectDbOrigin(resourceGroupArn);
            }
        }

        commonActionService.loadCurrentProject(projectId);
        return () => {
            mapsActionService.resetToInitialState();
            projectDeviceActionsService.resetToInitialState();
            documentationActionService.clearSelectedDevices();
            recordingActionService.resetToInitialState();
        };
    }, [
        commonActionService,
        currentResourceGroup?.arn,
        documentationActionService,
        isSharedProject,
        mapsActionService,
        projectDeviceActionsService,
        projectId,
        recordingActionService,
        sharedProjectsActionService,
    ]);

    const renderError = () => {
        let heading = t.projectNotFoundHeader;
        let message = t.projectNotFoundInfo;

        if (isCustomPouchError(error) && error.reason === POUCHDB_ERROR_REASON_QUOTA_EXCEEDED) {
            heading = t.errorBoundaryHeader;
            message = t.applicationQuotaExceededError;
        }

        return <ProjectError heading={heading} message={message} />;
    };

    const renderLoading = () => {
        return <LoadingIndicator message={t.projectLoading} />;
    };

    const renderRoutes = () => {
        return (
            <MainPanel
                sidebarContent={
                    <ProjectMainMenu
                        namespace={namespace}
                        basePath={
                            isSharedProject
                                ? `/sharedproject/${projectId}/`
                                : `/project/${projectId}/`
                        }
                        validRecordingSolution={validRecordingSolution}
                    />
                }
            >
                {loading ? (
                    renderLoading()
                ) : (
                    <ProjectRoutes
                        projectId={projectId}
                        projectLocked={project.locked}
                        isSharedProject
                    />
                )}
                <ValidationTrigger />
            </MainPanel>
        );
    };

    if (error) {
        return renderError();
    }
    return renderRoutes();
};

Project.displayName = 'Project';
