import { Module } from 'app/ioc';
import { installationReportReducer } from './installationReport/InstallationReport.reducer';
import { mapsReportReducer, MapsReportsActionService } from './mapsReport/state';
import {
    InstallationReportService,
    InstallationReportActionService,
    BaseDeviceService,
    CameraDeviceService,
    DecoderDeviceService,
    DoorStationDeviceService,
    EncoderDeviceService,
    FSeriesDeviceService,
    PacDeviceService,
    RadarDetectorDeviceService,
    SpeakerDeviceService,
    AggregatedScheduleToQuartersService,
    InstallationReportScheduleService,
    InstallationDetailsService,
    PeopleCounterDeviceService,
    WearablesDeviceService,
    DoorControllerDeviceService,
    AlerterDeviceService,
    ConnectivityDeviceService,
    PagingConsoleService,
} from './installationReport/services';
import {
    BillOfMaterialsActionService,
    BillOfMaterialsService,
    ProjectPricingApiCommunicator,
    ProjectPricingService,
} from './billOfMaterials/services';

@Module({
    binder: (binder) => {
        binder.bindService(InstallationReportService);
        binder.bindService(InstallationReportActionService);
        binder.bindService(BaseDeviceService);
        binder.bindService(CameraDeviceService);
        binder.bindService(DecoderDeviceService);
        binder.bindService(DoorStationDeviceService);
        binder.bindService(EncoderDeviceService);
        binder.bindService(FSeriesDeviceService);
        binder.bindService(PacDeviceService);
        binder.bindService(RadarDetectorDeviceService);
        binder.bindService(SpeakerDeviceService);
        binder.bindService(WearablesDeviceService);
        binder.bindService(AggregatedScheduleToQuartersService);
        binder.bindService(InstallationReportScheduleService);
        binder.bindService(InstallationDetailsService);
        binder.bindService(PeopleCounterDeviceService);
        binder.bindService(MapsReportsActionService);
        binder.bindService(BillOfMaterialsActionService);
        binder.bindService(DoorControllerDeviceService);
        binder.bindService(AlerterDeviceService);
        binder.bindService(ConnectivityDeviceService);
        binder.bindService(PagingConsoleService);
        binder.bindService(ProjectPricingService);
        binder.bindService(ProjectPricingApiCommunicator);
        binder.bindService(BillOfMaterialsService);
    },
    reducers: {
        installationReport: installationReportReducer,
        mapsReport: mapsReportReducer,
    },
})
export class ReportsModule {}
