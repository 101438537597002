import { injectable } from 'inversify';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import { AppStore } from 'app/store';
import { toRecord } from 'app/core/persistence';

@injectable()
export class PiaDevicesService {
    private piaItemsRecord: Record<PiaId, IPiaItem> | null = null;

    public constructor(
        private piaItemService: PiaItemService<IPiaItem>,
        private appStore: AppStore,
    ) {}

    /**
     * Gets all pia items. Used to populate the common state with pia items.
     */
    public getPiaDevices(): IPiaItem[] {
        return this.piaItemService.getAll().toList();
    }

    /**
     * Gets a pia item from the state. If not found in the state tries to get it from the database instead.
     * @param piaId The id of the pia item to get.
     */
    public getPiaDevice(piaId: PiaId): IPiaItem | null {
        if (!this.piaItemsRecord) {
            this.piaItemsRecord = toRecord(this.appStore.Store.getState().common.piaItems, 'id');
        }

        return this.piaItemsRecord[piaId] ?? this.piaItemService.get(piaId).first() ?? null;
    }
}
