import { injectable } from 'inversify';
import type {
    Id,
    IScheduleModel,
    IBaseProfileModel,
    IPersistence,
    IItemEntity,
    ICameraPropertiesEntity,
    IAnalogCameraPropertiesEntity,
    BandwidthVersion,
} from 'app/core/persistence';
import {
    isCamera,
    ProjectZipType,
    VideoEncoding,
    Resolution,
    getDefaultCameraFilterEntity,
} from 'app/core/persistence';
import type { IPiaCamera } from 'app/core/pia';
import {
    ProfileSupportService,
    ProjectDefaultsService,
    ProjectProfileService,
} from '../../profile/services';
import { Frequency } from '../../models';
import { PiaDevicesService } from '../../piaDevices';
import { CameraEstimateService } from './CameraEstimate.service';

export interface IProductEstimate {
    piaModel: string;
    piaId: number;
    bandwidth264: number | undefined;
    hasH264ScalingCost: boolean;
    bandwidth265: number | undefined;
    hasH265ScalingCost: boolean;
    bandwidthAV1?: number;
    hasAV1ScalingCost?: boolean;
}

@injectable()
export class GenericEstimatesService {
    constructor(
        private cameraEstimateService: CameraEstimateService,
        private projectDefaultsService: ProjectDefaultsService,
        private projectProfileService: ProjectProfileService,
        private piaDevicesService: PiaDevicesService,
    ) {}

    public async getGenericEstimates(
        projectId: Id,
        projectBandwidthVersion: BandwidthVersion,
    ): Promise<IProductEstimate[]> {
        const piaCameras = this.piaDevicesService
            .getPiaDevices()
            .filter((piaDevice) => isCamera(piaDevice) && !piaDevice.name.includes('Barebone'))
            .sort((a, b) => a.name.localeCompare(b.name));

        const alwaysSchedule = await this.projectProfileService.getAlwaysSchedule(projectId);

        const defaultProfileSettings = this.projectDefaultsService.getDefaultProfileSettings(
            'Default',
            alwaysSchedule,
            'retail',
        );

        const getDefaultProfile1080H264 = (
            _id: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ): IBaseProfileModel => ({
            ...defaultProfileSettings,
            triggeredRecording: {
                ...defaultProfileSettings.triggeredRecording,
                resolution: new Resolution('1920x1080'),
            },
        });

        const getDefaultProfile1080H265 = (
            _id: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ): IBaseProfileModel => ({
            ...defaultProfileSettings,
            triggeredRecording: {
                ...defaultProfileSettings.triggeredRecording,
                resolution: new Resolution('1920x1080'),
                videoEncoding: VideoEncoding.h265,
            },
        });

        const getDefaultProfile1080AV1 = (
            _id: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ): IBaseProfileModel => ({
            ...defaultProfileSettings,
            triggeredRecording: {
                ...defaultProfileSettings.triggeredRecording,
                resolution: new Resolution('1920x1080'),
                videoEncoding: VideoEncoding.av1,
            },
        });

        const projectFrequency = Frequency.Hz50;

        const estimates = piaCameras.map((camera) => {
            const piaCameraProps = (camera as IPiaCamera).properties;
            // Override max resolution to get a fair comparison for all cameras
            piaCameraProps.maxVideoResolutionHorizontal = 1920;
            piaCameraProps.maxVideoResolutionVertical = 1080;

            const encodingSupportH264 = ProfileSupportService.videoEncodingSupport(
                VideoEncoding.h264,
                piaCameraProps,
            );
            const encodingSupportH265 = ProfileSupportService.videoEncodingSupport(
                VideoEncoding.h265,
                piaCameraProps,
            );
            const encodingSupportAV1 = ProfileSupportService.videoEncodingSupport(
                VideoEncoding.av1,
                piaCameraProps,
            );

            const artificialItem: IPersistence<IItemEntity> = {
                _id: '',
                _rev: '',
                creationDate: '',
                updatedDate: '',
                entityVersion: 1,
                type: 'item',
                path: [],
                archived: false,
                name: camera.name,
                description: '',
                notes: '',
                quantity: 1,
                productId: camera.id,
                properties: {
                    camera: {
                        associatedProfile: '',
                        profileOverride: {
                            scenario: {},
                            triggeredRecording: {},
                            storage: {},
                            audio: {},
                            continuousRecording: {},
                            liveView: {},
                            zipstream: {},
                        },
                        filter: getDefaultCameraFilterEntity('metric', 3),
                    },
                },
            };

            const getDefaultSchedule = (_id: Id): IScheduleModel => alwaysSchedule;

            const estimatesForCameraH264 = this.cameraEstimateService.getCameraEstimate(
                ProjectZipType.low,
                artificialItem,
                piaCameraProps,
                projectFrequency,
                getDefaultSchedule,
                getDefaultProfile1080H264,
                projectBandwidthVersion,
            );

            const estimatesForCameraH265 = this.cameraEstimateService.getCameraEstimate(
                ProjectZipType.low,
                artificialItem,
                piaCameraProps,
                projectFrequency,
                getDefaultSchedule,
                getDefaultProfile1080H265,
                projectBandwidthVersion,
            );

            const estimatesForCameraAV1 = this.cameraEstimateService.getCameraEstimate(
                ProjectZipType.low,
                artificialItem,
                piaCameraProps,
                projectFrequency,
                getDefaultSchedule,
                getDefaultProfile1080AV1,
                projectBandwidthVersion,
            );

            return {
                piaModel: camera.name,
                piaId: camera.id,
                bandwidth264: encodingSupportH264.supported
                    ? estimatesForCameraH264?.total.bandwidthInBps
                    : undefined,
                hasH264ScalingCost: piaCameraProps.scalingCost !== undefined,
                bandwidth265: encodingSupportH265.supported
                    ? estimatesForCameraH265?.total.bandwidthInBps
                    : undefined,
                hasH265ScalingCost: piaCameraProps.scalingCostH265 !== undefined,
                bandwidthAV1: encodingSupportAV1.supported
                    ? estimatesForCameraAV1?.total.bandwidthInBps
                    : undefined,
                hasAV1ScalingCost: piaCameraProps.scalingCostAV1 !== undefined,
            };
        });

        return estimates;
    }
}
