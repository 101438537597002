import { ImportedProjectSettingsFile } from './../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';

import { validateSchema } from 'app/modules/common';
import { PROJECT_SCHEMA } from './schemas';

@injectable()
export class ProjectSettingsValidator {
    public validateV1(projectSettingsFile: ImportedProjectSettingsFile) {
        return validateSchema(projectSettingsFile, PROJECT_SCHEMA);
    }
}
