import { t } from 'app/translate';
import * as React from 'react';
import type { IProduct } from 'app/modules/common';
import {
    getShouldShowProjectPricing,
    VendorEnum,
    CategoryEnum,
    quotationFilter,
} from 'app/modules/common';
import { BillOfMaterialsSection } from './BillOfMaterialsSection.component';
import { Text, PrintPanel, IconButton, Alert, IconText } from 'app/components';
import { Link } from 'react-router-dom';
import { eventTracking } from 'app/core/tracking';
import { ReportHeader } from '../../components';
import { BillOfMaterialsExportBidcon } from './BillOfMaterialsExportBidcon.container';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { isFeatureEnabled } from 'app/featureFlags';
import { generateExcelFile } from 'app/utils/generateExcelFile';
import { ProjectPricingModal } from './ProjectPricingModal.component';
import { RequestProjectPricingModal } from './RequestProjectPricing.modal';
import { BillOfMaterialsExportToBOMFile } from './BillOfMaterialsExportToBOMFile.component';

export interface IBillOfMaterialsComponentProps {
    projectId: string;
    projectName: string;
    products: IProduct[];
    locationName: string;
    excludedProductsMessage?: string;
}

export const BillOfMaterialsComponent: React.FunctionComponent<IBillOfMaterialsComponentProps> = (
    props,
) => {
    const shouldShowProjectPricing = useSelector<IStoreState, boolean>(getShouldShowProjectPricing);
    const [showGetPricingModal, setShowPricingModal] = React.useState(false);

    const showPricingModal = () => {
        eventTracking.logUserEvent('Reports', 'Request project pricing', 'Bill Of Materials');
        setShowPricingModal(true);
    };

    const closePricingModal = () => {
        setShowPricingModal(false);
    };

    const exportToExcel = () => {
        eventTracking.logUserEvent('Reports', 'Export to Excel', 'Bill Of Materials');
        eventTracking.pushToGA4('SiteDesignerClick', 'Export', 'Bill Of Materials', 'Excel');
        const dataTitles: string[] = [
            `${t.billOfMaterialsModel}`,
            `${t.billOfMaterialsPartNumber}`,
            `${t.billOfMaterialsQuantity}`,
        ];
        const dataToExport: string[][] = [];
        props.products.forEach((product: IProduct) => {
            const model = product.name || '';
            const partNumber = product.partNumber || '-';
            const quantity = product.quantity?.toString() || '-';
            dataToExport.push([model, partNumber, quantity]);
        });
        // Add an empty row after the products
        dataToExport.push(['', '', '', '']);
        // Add a row of country information
        dataToExport.push(['', '', '', `${t.billOfMaterialsExcelCountryInfo(props.locationName)}`]);

        generateExcelFile(
            t.billOfMaterialsExcelFileName(props.projectName),
            t.billOfMaterialsExcelSheetTitle,
            dataTitles,
            dataToExport,
        );
    };

    const axisSection = (
        <BillOfMaterialsSection
            sectionHeader={t.quotationAxisProducts}
            products={props.products
                .filter(quotationFilter.byVendor(VendorEnum.Axis))
                .filter(
                    quotationFilter.byCategories(
                        CategoryEnum.Camera,
                        CategoryEnum.Encoder,
                        CategoryEnum.FSeries,
                        CategoryEnum.RecordingAndNetwork,
                        CategoryEnum.Speaker,
                        CategoryEnum.Software,
                        CategoryEnum.Pac,
                        CategoryEnum.DoorControllers,
                        CategoryEnum.Decoder,
                        CategoryEnum.RadarDetector,
                        CategoryEnum.Miscellaneous,
                        CategoryEnum.DoorStation,
                        CategoryEnum.Accessory,
                        CategoryEnum.PeopleCounters,
                        CategoryEnum.Wearables,
                        CategoryEnum.Alerters,
                        CategoryEnum.ConnectivityDevice,
                        CategoryEnum.PagingConsole,
                    ),
                )}
        />
    );

    const otherSection = (
        <BillOfMaterialsSection
            sectionHeader={t.quotationOther}
            products={props.products
                .filter(
                    quotationFilter.byVendors(
                        VendorEnum.Other,
                        VendorEnum.twoN,
                        VendorEnum.Genetec,
                    ),
                )
                .filter(
                    quotationFilter.byCategories(
                        CategoryEnum.Camera,
                        CategoryEnum.Encoder,
                        CategoryEnum.FSeries,
                        CategoryEnum.RecordingAndNetwork,
                        CategoryEnum.Speaker,
                        CategoryEnum.Software,
                        CategoryEnum.Pac,
                        CategoryEnum.Decoder,
                        CategoryEnum.RadarDetector,
                        CategoryEnum.Miscellaneous,
                        CategoryEnum.DoorStation,
                        CategoryEnum.Accessory,
                        CategoryEnum.Alerters,
                    ),
                )}
        />
    );

    return (
        <>
            {showGetPricingModal && !isFeatureEnabled('project_pricing') && (
                // Remove ProjectPricingModal component when feature flag is removed
                <ProjectPricingModal
                    products={props.products}
                    onClose={closePricingModal}
                    trackBOMExport={trackExportToBOM}
                />
            )}
            {showGetPricingModal && isFeatureEnabled('project_pricing') && (
                <RequestProjectPricingModal products={props.products} onClose={closePricingModal} />
            )}
            <PrintPanel
                testId="panel_reports_bill_of_materials_panel"
                printable
                onPrint={trackPrintEvent}
                buttons={
                    <>
                        {shouldShowProjectPricing && (
                            <IconButton
                                icon="question_answer"
                                text={t.projectPricingGROUP.requestProjectPricing}
                                onClick={showPricingModal}
                            />
                        )}
                        <IconButton
                            icon="file_download"
                            text={t.quotationExportToExcel}
                            onClick={exportToExcel}
                        />

                        {!shouldShowProjectPricing && (
                            <BillOfMaterialsExportToBOMFile
                                products={props.products}
                                trackBOMExport={trackExportToBOM}
                            >
                                <IconText
                                    iconProps={{ opaque: true, size: 'ms' }}
                                    textProps={{ style: 'semibold' }}
                                    icon="file_download"
                                    color="blue"
                                    testId="export_to_bom_file"
                                >
                                    {t.quotationExportToBOM}
                                </IconText>
                            </BillOfMaterialsExportToBOMFile>
                        )}
                        {isFeatureEnabled('export_bidcon') && <BillOfMaterialsExportBidcon />}
                    </>
                }
            >
                <ReportHeader title={t.projectReportsBillOfMaterialsHeading} />
                {axisSection}
                {otherSection}
                <Text bold align="right">
                    {`${t.quotationCountryInfo}: `}
                    <Link to={`/project/${props.projectId}/info`}>{props.locationName}</Link>
                </Text>
                {props.products.some(
                    (p) => p.vendor === VendorEnum.Other || p.vendor === VendorEnum.Genetec,
                ) && (
                    <Alert
                        heading={t.partnerProductsFromOtherVendorsHeader}
                        text={t.partnerProductsFromOtherVendorsText}
                        level="info"
                        color="blue"
                    />
                )}
                {props.products.some((p) => p.allowlisted === false) && (
                    <IconText
                        align="start"
                        icon="feedback"
                        iconProps={{ color: 'blue' }}
                        textProps={{ italic: true, color: 'black' }}
                    >
                        {props.excludedProductsMessage}
                    </IconText>
                )}
            </PrintPanel>
        </>
    );
};

function trackExportToBOM() {
    eventTracking.logUserEvent('Reports', 'Export to BOM file', 'Bill Of Materials');
    eventTracking.pushToGA4('SiteDesignerClick', 'Export', 'Bill Of Materials', 'BOM file');
}

function trackPrintEvent() {
    eventTracking.logUserEvent('Reports', 'Print', 'Bill Of Materials');
    eventTracking.pushToGA4('SiteDesignerClick', 'Print', 'Bill Of Materials');
}

BillOfMaterialsComponent.displayName = 'BillOfMaterialsComponent';
