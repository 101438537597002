import { injectable } from 'inversify';
import type { IUserPreferences } from '../models';
import {
    defaultUserPreferences,
    getItemLocalStorageJSON,
    removeItemLocalStorage,
    setItemLocalStorage,
} from '../utils';

@injectable()
export class UserPreferencesService {
    public set(values: Partial<IUserPreferences>): void {
        let userPreferences = this.get();
        userPreferences = {
            leftMenuExpanded:
                values.leftMenuExpanded !== undefined
                    ? values.leftMenuExpanded
                    : userPreferences.leftMenuExpanded,
            addProductExpanded:
                values.addProductExpanded !== undefined
                    ? values.addProductExpanded
                    : userPreferences.addProductExpanded,
            showOnlyDevicesOnMap:
                values.showOnlyDevicesOnMap !== undefined
                    ? values.showOnlyDevicesOnMap
                    : userPreferences.showOnlyDevicesOnMap,
            customItemListSorting:
                values.customItemListSorting !== undefined
                    ? values.customItemListSorting
                    : userPreferences.customItemListSorting,
            deviceListSorting:
                values.deviceListSorting !== undefined
                    ? values.deviceListSorting
                    : userPreferences.deviceListSorting,
            customizeSolutionRecordingCategory:
                values.customizeSolutionRecordingCategory !== undefined
                    ? values.customizeSolutionRecordingCategory
                    : userPreferences.customizeSolutionRecordingCategory,
            projectSettings:
                values.projectSettings !== undefined
                    ? values.projectSettings
                    : userPreferences.projectSettings,
            selectedView:
                values.selectedView !== undefined
                    ? values.selectedView
                    : userPreferences.selectedView,
        };
        setItemLocalStorage('UserPreferences', JSON.stringify(userPreferences));
    }

    public get(): IUserPreferences {
        const userPreferences = getItemLocalStorageJSON(
            'UserPreferences',
            {} as Partial<IUserPreferences>,
        );
        return userPreferences === null
            ? defaultUserPreferences
            : {
                  ...defaultUserPreferences,
                  ...userPreferences,
              };
    }

    public clear(): void {
        removeItemLocalStorage('UserPreferences');
    }
}
