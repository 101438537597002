import { injectable } from 'inversify';
import { POUCHDB_ERROR_REASON_QUOTA_EXCEEDED } from 'app/errorReporterMiddleware';
import type { IExportFileV1 } from './fileFormats/IExportFileV1';
import type { ImportErrorReason } from './ImportError';
import { ImportError } from './ImportError';
import { EntityImportService } from './EntityImport.service';
import type { IEntity, Id, IProjectEntity, IPersistence } from '../../userDataPersistence';
import {
    ProjectDbOrigin,
    PersistenceMemoryRepository,
    MigrationService,
    EntitySettings,
    ProjectRepository,
    ProjectDbOriginAsdLocalUserData,
} from '../../userDataPersistence';

import { eventTracking } from 'app/core/tracking';
import { NameGenerationService } from '../NameGeneration.service';
import { AppConstants } from 'app/AppConstants';
import { FloorPlanService } from '../FloorPlan.service';
import { toErrorMessage } from '../../utils';
import { isCustomPouchError } from 'app/utils';
import { EventEmitterType } from '../EventEmitter.service';
import { isFeatureEnabled } from 'app/featureFlags';
import { IImportData } from './fileFormats';
import { containsFloorPlanOrMapLocation } from './containsFloorPlanOrMapLocation';
import { OfflineService } from '../Offline.service';
import { UserService } from '../user';

export const SETTINGS_FILE_EXTENSION = '.asds';
export const ACCEPTED_PROJECT_FILE_EXTENSIONS = isFeatureEnabled('import_settings_file')
    ? ['.aedp', '.asdpx', SETTINGS_FILE_EXTENSION]
    : ['.aedp', '.asdpx'];

@injectable()
export class ProjectImportService {
    constructor(
        private migrationService: MigrationService,
        private entityImportService: EntityImportService,
        private entitySettings: EntitySettings,
        private projectRepository: ProjectRepository,
        private nameGenerationService: NameGenerationService,
        private persistenceMemoryRepository: PersistenceMemoryRepository,
        private offlineService: OfflineService,
        private userService: UserService,
    ) {}

    public importProject(
        importData: IImportData,
        currentProject: IPersistence<IProjectEntity> | undefined,
        excludeFloorPlanMaps: boolean,
        newProjectDbOrigin: ProjectDbOrigin,
        eventEmitterType: EventEmitterType,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<Id> {
        return this.importAsd2Project(
            importData,
            currentProject,
            excludeFloorPlanMaps,
            newProjectDbOrigin,
            eventEmitterType,
            usedImageQuota,
            totalImageQuota,
        );
    }

    public async importSettingsProject(
        fileData: IExportFileV1,
        projectName: string,
        newProjectDbOrigin: ProjectDbOrigin,
        eventEmitterType: EventEmitterType,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<Id> {
        const importData: IImportData = {
            projectId: fileData.project._id,
            projectName,
            data: [fileData.project, ...fileData.children],
        };

        const canCopyFloorPlans = await this.canCopyFloorPlans(newProjectDbOrigin);
        if (!canCopyFloorPlans) {
            throw new ImportError('cannotCopyFloorPlans');
        }

        return this.importAsd2Project(
            importData,
            undefined,
            false,
            newProjectDbOrigin,
            eventEmitterType,
            usedImageQuota,
            totalImageQuota,
        );
    }

    public async hasFloorPlans(file: File): Promise<boolean> {
        if (this.isAsd1Project(file.name)) {
            return Promise.resolve(false);
        } else {
            try {
                // need to catch invalid files here
                const fileData = await this.parseFile(file);
                return (
                    Array.isArray(fileData.children) &&
                    FloorPlanService.includesFloorPlans(fileData.children)
                );
            } catch (error) {
                throw this.getFileReadError(file.name);
            }
        }
    }

    private async canCopyFloorPlans(newProjectDbOrigin: ProjectDbOrigin): Promise<boolean> {
        const isLocalProject = this.isLocalProject(newProjectDbOrigin);
        if (isLocalProject) {
            return true;
        }
        return this.isOnlineAndSignedIn();
    }

    private isLocalProject(newProjectDbOrigin: ProjectDbOrigin) {
        return newProjectDbOrigin === ProjectDbOriginAsdLocalUserData;
    }

    private async isOnlineAndSignedIn(): Promise<boolean> {
        const isOnline = await this.offlineService.isOnline();
        const isSignedIn = (await this.userService.getUser()) !== null;
        return isOnline && isSignedIn;
    }

    private isAsd1Project(filename: string): boolean {
        return filename.endsWith('.asdp');
    }

    private async importAsd2Project(
        importData: IImportData,
        currentProjectToImportTo: IPersistence<IProjectEntity> | undefined,
        excludeFloorPlanMaps: boolean,
        newProjectDbOrigin: ProjectDbOrigin,
        eventEmitterType: EventEmitterType,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<Id> {
        if (importData.projectId && importData.data.length > 0) {
            let descendants = importData.data;
            if (excludeFloorPlanMaps) {
                descendants = await FloorPlanService.filterOutFloorPlanMaps(descendants);
                const hasFloorPlanOrMapLocation = containsFloorPlanOrMapLocation(descendants);
                const projectIndex = descendants.findIndex((entity) => entity.type === 'project');
                const updatedProject = { ...descendants[projectIndex], hasFloorPlanOrMapLocation };
                descendants[projectIndex] = updatedProject;
            }

            const entities = [...descendants];
            this.persistenceMemoryRepository.initialize(
                entities,
                this.entitySettings,
                eventEmitterType,
            );

            // check if floorplans included and calculate total size of images in that case
            if (newProjectDbOrigin !== ProjectDbOriginAsdLocalUserData) {
                const floorPlans: IEntity[] =
                    await this.persistenceMemoryRepository.getAll('floorPlan');
                if (
                    floorPlans.length &&
                    totalImageQuota != undefined &&
                    usedImageQuota != undefined
                ) {
                    const totalImageSize = FloorPlanService.getApproximateSizeInBytes(floorPlans);
                    const notEnoughImageSpace =
                        totalImageQuota - usedImageQuota - totalImageSize < 0;
                    if (notEnoughImageSpace) {
                        return this.handleImportError(
                            'ASD2',
                            'imageQuotaFull',
                            'There is not enough space on the image server',
                        );
                    }
                }
            }

            const importedProjectName = await this.importedProjectName(importData.projectName);
            try {
                await this.migrationService.migrateAll(this.persistenceMemoryRepository);
                return await this.entityImportService.importProject(
                    importData.projectId,
                    currentProjectToImportTo,
                    this.persistenceMemoryRepository,
                    importedProjectName,
                    newProjectDbOrigin,
                );
            } catch (error) {
                if (isCustomPouchError(error)) {
                    if (error.reason === POUCHDB_ERROR_REASON_QUOTA_EXCEEDED) {
                        return this.handleImportError(
                            'ASD2',
                            'diskSpaceFull',
                            toErrorMessage(error),
                        );
                    }
                }
                return this.handleImportError('ASD2', 'entityImportFailed', toErrorMessage(error));
            }
        } else {
            return this.handleImportError(
                'ASD2',
                'invalidFileFormat',
                'The file is not recognized as a Site Designer 2 file',
            );
        }
    }

    private async parseFile(file: File): Promise<IExportFileV1> {
        const fileData: string = await this.getFileContents(file);
        return JSON.parse(fileData) as IExportFileV1;
    }

    private getFileContents(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.result && typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject(this.getFileReadError(file.name));
                }
            };

            reader.onerror = () => {
                reject(this.getFileReadError(file.name));
            };

            reader.readAsText(file);
        });
    }

    private getFileReadError(filename: string): ImportError {
        const errorMessage = `Unable to read from the file '${filename}'`;
        return new ImportError('fileContentsUnreadable', errorMessage);
    }

    private getFilenameWithoutSuffix(filename: string): string {
        if (this.hasSuffix(filename)) {
            return filename.split('.').slice(0, -1).join('.');
        }
        return filename;
    }

    private hasSuffix(filename: string): boolean {
        return filename.includes('.');
    }

    private async importedProjectName(name: string, noSuffix: boolean = false): Promise<string> {
        const projectName = noSuffix ? name : this.getFilenameWithoutSuffix(name);
        const existingProjects = await this.projectRepository.getAll();
        const existingNames = existingProjects.map((existingProject) => existingProject.name);
        return this.nameGenerationService.getName(
            projectName,
            existingNames,
            AppConstants.projectNameMaxLength,
        );
    }

    private handleImportError(
        importType: 'ASD1' | 'ASD2',
        reason: ImportErrorReason,
        message: string,
    ): never {
        eventTracking.logError(
            `Failed to import ${importType === 'ASD1' ? 'ASD 1' : 'ASD 2'} project - ${message}`,
            'ProjectImportService',
        );
        throw new ImportError(reason, message);
    }
}
