import { useEffect } from 'react';
import flagsmith from 'flagsmith';
import { useFlags } from 'flagsmith/react';

export const useFlagsmith = (flagNames: readonly string[], interval: number) => {
    const flags = useFlags(flagNames);

    useEffect(() => {
        flagsmith.startListening(interval);

        return () => {
            flagsmith.stopListening();
        };
    }, [flags, interval]);

    return flags;
};
