import type { SolutionType, UseCaseSubType, UseCaseType } from 'app/modules/common';
import {
    getIsStandalone,
    allUseCases,
    getPiaItemsRecord,
    getIsOtherSelectorOpen,
} from 'app/modules/common';
import { t } from 'i18next';
import { memoize, sortBy } from 'lodash-es';
import { createSelector } from 'reselect';
import type { IPartnerProductFilter } from '../models';
import { getSelectedProductTypePartnerProducts } from './getSelectedProductTypePartnerProducts';
import { getPartnerFilter } from './getPartnerFilter';
import { getFilteredPartnerItemsWithBypass, getUseCaseTranslationKey } from '../utils';

const USE_CASE = 'useCase';
const SOLUTION_TYPE: keyof IPartnerProductFilter = 'solutionType';

const getUseCaseRecord = createSelector(
    [getSelectedProductTypePartnerProducts],
    (partnerProducts): Record<UseCaseType, UseCaseSubType[]> => {
        const categoryLookupRecord = getUseCaseCategoryLookupRecord();

        return partnerProducts.reduce(
            (categoryRecord, partnerProduct) => {
                partnerProduct.properties.useCases.forEach((useCase) => {
                    const category = categoryLookupRecord[useCase];
                    // Add main category if missing
                    if (!categoryRecord[category]) {
                        categoryRecord = { ...categoryRecord, [category]: [] };
                    }

                    if (!categoryRecord[category].includes(useCase)) {
                        // Add use case
                        categoryRecord[category].push(useCase);
                    }
                });
                return categoryRecord;
            },
            {} as Record<UseCaseType, UseCaseSubType[]>,
        );
    },
);

export const getCategorizedSortedPartnerUseCases = createSelector(
    [getUseCaseRecord],
    (useCaseRecord) => {
        const useCases = { ...useCaseRecord };
        Object.entries(useCaseRecord).forEach(
            ([mainCategory, useCaseGroup]) =>
                (useCases[mainCategory as UseCaseType] = sortBy(useCaseGroup, (useCase) =>
                    t(getUseCaseTranslationKey(useCase), { ns: 'partnerProducts' }),
                )),
        );
        return useCases;
    },
);

export const getPartnerProductsUseCaseAmounts = createSelector(
    [getSelectedProductTypePartnerProducts, getPartnerFilter, getPiaItemsRecord],
    (partnerProducts, partnerFilter, piaItemsRecord) => {
        const filteredProducts = getFilteredPartnerItemsWithBypass(
            partnerProducts,
            partnerFilter,
            USE_CASE,
            piaItemsRecord,
        );

        const useCaseAmountRecord = filteredProducts.reduce(
            (useCaseAmounts, acap) => {
                acap.properties.useCases.forEach((useCase) => {
                    if (useCaseAmounts[useCase]) {
                        useCaseAmounts[useCase] += 1;
                    } else {
                        useCaseAmounts[useCase] = 1;
                    }
                });
                return useCaseAmounts;
            },
            {} as Record<UseCaseSubType | 'anyUseCase', number>,
        );
        useCaseAmountRecord['anyUseCase'] = filteredProducts.length;
        return useCaseAmountRecord;
    },
);

export const getSolutionTypeAmounts = createSelector(
    [getSelectedProductTypePartnerProducts, getPartnerFilter, getPiaItemsRecord],
    (partnerProduct, filter, piaItemsRecord) => {
        const filteredPartnerProducts = getFilteredPartnerItemsWithBypass(
            partnerProduct,
            filter,
            SOLUTION_TYPE,
            piaItemsRecord,
        );
        return filteredPartnerProducts.reduce(
            (solutionTypeAmounts, partnerItem) => {
                partnerItem.properties.solutionType.forEach(
                    (solutionType) => (solutionTypeAmounts[solutionType] += 1),
                );
                return solutionTypeAmounts;
            },
            {
                anyAcapArchitecture: filteredPartnerProducts.length,
                Hybrid: 0,
                'Stand-alone': 0,
            } as Record<SolutionType, number>,
        );
    },
);

/**
 * Only show solution type if the partner product type is 'ACAP application' for stand-alone or in ACAP selector within Site Designer
 * and there are items to show. Hide if the "other selector" is open.
 */
export const getShowSolutionTypeOptions = createSelector(
    [getSolutionTypeAmounts, getPartnerFilter, getIsStandalone, getIsOtherSelectorOpen],
    (solutionTypeAmounts, partnerFilter, isStandAlone, isOtherSelectorOpen): boolean => {
        if (isOtherSelectorOpen) return false;
        const itemsForSolutionType = Object.values(solutionTypeAmounts).some(
            (amount) => amount > 0,
        );
        const acapApplicationSelected = partnerFilter.partnerProductType === 'ACAP application';
        return (!isStandAlone || acapApplicationSelected) && itemsForSolutionType;
    },
);

export const getShowResetFilterButton = createSelector(
    [getPartnerFilter],
    (filter): boolean =>
        !!filter.searchFilter ||
        !!filter.solutionType ||
        !!filter.useCase ||
        !!filter.partnerProductType,
);

export const getSortedPartnerProductCategories = createSelector(
    [getUseCaseRecord],
    (useCaseCategories): UseCaseType[] =>
        sortBy(Object.keys(useCaseCategories) as UseCaseType[], (useCase) => [
            t(useCase, { ns: 'partnerProducts' }),
        ]),
);

/** Reverses allUseCases record so that we can look up the main category of a use case. */
const getUseCaseCategoryLookupRecord = memoize((): Record<UseCaseSubType, UseCaseType> => {
    return Object.entries(allUseCases).reduce(
        (lookupRecord, [category, useCases]) => {
            useCases.forEach((useCase) => {
                lookupRecord[useCase] = category as UseCaseType;
            });
            return lookupRecord;
        },
        {} as Record<UseCaseSubType, UseCaseType>,
    );
});
