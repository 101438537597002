import { Module } from 'app/ioc';
import { LoginService } from './authentication/services';
import { PiaDevicesService, PiaQueriesService } from './piaDevices/services';
import { ProjectFrequencyService } from './project/services';
import {
    CommonActionService,
    CameraUspService,
    LocationService,
    InitializeDataStorageService,
    TokenService,
    InitializeSharedDataStorageService,
} from './services';
import {
    ScenarioService,
    ProfileOverrideService,
    ProfileSupportService,
    ProjectProfileService,
    ProjectDefaultsService,
} from './profile/services';
import { commonReducer } from './CommonReducer';
import { AccessoryService } from './accessory/services';
import { MsrpService } from './prices/services';
import {
    CamerasService,
    DoorControllerService,
    EncodersService,
    MainUnitsService,
    OtherDevicesService,
    SpeakersService,
    TwoNDevicesService,
    VirtualProductService,
} from './devices/services';
import { currentProjectReducer } from './currentProjectReducer';
import {
    BandwidthCalculatorService,
    BandwidthStorageEstimateService,
    LightHoursCalculationService,
    ScheduleAggregationService,
    StorageCalculationService,
    ProfileBandwidthStorageEstimateService,
    CameraEstimateService,
    MainUnitEstimateService,
    EncoderEstimateService,
    BodyWornCameraEstimateService,
    GenericEstimatesService,
    VirtualCameraEstimateService,
    BandwidthEstimateCalculatorService,
} from './bandwidth/services';
import { userCustomItemReducer } from './UserCustomItemsReducer';
import { ChildItemService } from './item/services';

@Module({
    binder: (binder) => {
        binder.bindSingleton(ProjectFrequencyService);
        binder.bindService(CommonActionService);
        binder.bindService(ProjectProfileService);
        binder.bindService(LoginService);
        binder.bindService(PiaDevicesService);
        binder.bindService(ProfileOverrideService);
        binder.bindService(ProfileSupportService);
        binder.bindService(ScenarioService);
        binder.bindService(CameraUspService);
        binder.bindService(AccessoryService);
        binder.bindService(LocationService);
        binder.bindService(MsrpService);
        binder.bindService(PiaQueriesService);
        binder.bindService(ProjectDefaultsService);
        binder.bindService(EncoderEstimateService);
        binder.bindService(BandwidthCalculatorService);
        binder.bindService(BandwidthStorageEstimateService);
        binder.bindService(LightHoursCalculationService);
        binder.bindService(ScheduleAggregationService);
        binder.bindService(StorageCalculationService);
        binder.bindService(ProfileBandwidthStorageEstimateService);
        binder.bindService(CameraEstimateService);
        binder.bindService(VirtualCameraEstimateService);
        binder.bindService(GenericEstimatesService);
        binder.bindService(MainUnitEstimateService);
        binder.bindService(BodyWornCameraEstimateService);
        binder.bindService(InitializeDataStorageService);
        binder.bindService(InitializeSharedDataStorageService);
        binder.bindService(BandwidthEstimateCalculatorService);
        binder.bindService(TokenService);
        binder.bindService(CamerasService);
        binder.bindService(ChildItemService);
        binder.bindService(DoorControllerService);
        binder.bindService(EncodersService);
        binder.bindService(MainUnitsService);
        binder.bindService(OtherDevicesService);
        binder.bindService(SpeakersService);
        binder.bindService(TwoNDevicesService);
        binder.bindService(VirtualProductService);
    },
    reducers: {
        common: commonReducer,
        currentProject: currentProjectReducer,
        userCustomItems: userCustomItemReducer,
    },
})
export class CommonModule {}
