import type { IStoreState, ProjectStoreState } from 'app/store';
import type { IUserProjectsState } from '../userProjects';
import type { ISharedProjectsState } from '../sharedProjects';

export const getError = (state: IStoreState, property: ProjectStoreState) => {
    const userProjects = state[property] as IUserProjectsState;
    const sharedProjects = state[property] as ISharedProjectsState;

    if (userProjects) {
        return userProjects.error;
    }

    if (sharedProjects) {
        return sharedProjects.error;
    }

    return null;
};
