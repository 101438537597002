import { offset } from 'axis-webtools-util';
import { injectable } from 'inversify';
import { LatLng } from 'leaflet';
import { constant, flatMap, times } from 'lodash-es';
import type { DeviceAndSubType, ILatLng } from '../models';
import type {
    Id,
    IInstallationPointEntity,
    IItemEntity,
    IPersistence,
} from '../userDataPersistence';
import { InstallationPointRepository, ItemRepository } from '../userDataPersistence';
import { getParentId } from '../utils';
import { CurrentProjectService } from './CurrentProject.service';
import { DuplicationService } from './Duplication.service';
import { InstallationPointDeviceService, IP_LABEL_OFFSET } from './InstallationPointDevice.service';

const CHILD_RADIUS = 3; // Radius in meter between parent and child

@injectable()
export class ParentChildInstallationPointService {
    constructor(
        private installationPointRepository: InstallationPointRepository,
        private itemRepository: ItemRepository,
        private currentProjectService: CurrentProjectService,
        private installationPointDeviceService: InstallationPointDeviceService,
        private duplicationService: DuplicationService,
    ) {}

    public async addParentUnitWithChildren(
        item: IPersistence<IItemEntity>,
        mapId: Id,
        location: ILatLng,
        parentType: DeviceAndSubType,
        options: {
            global?: boolean;
            installationPointName?: string;
        },
        mapImageRotationDegrees?: number,
    ): Promise<IInstallationPointEntity> {
        if (
            parentType !== 'mainUnit' &&
            parentType !== 'encoder' &&
            parentType !== 'doorcontroller' &&
            parentType !== 'iorelay'
        ) {
            throw new Error(`Parent device type unknown: ${parentType}`);
        }

        const parentIP = await this.addParentUnitInstallationPoint(
            item,
            options.installationPointName ?? '',
            mapId,
            location,
            options,
        );
        const children = await this.getChildUnits(item._id);
        if (children.length > 0) {
            const quantity = this.getChildUnitQuantity(children);
            const locations = this.getChildLocations(parentIP.location, quantity);
            let locationIndex = 0;
            children.map(async (child) => {
                for (let childIndex = 0; childIndex < child.quantity; childIndex++) {
                    if (parentType === 'mainUnit') {
                        await this.installationPointDeviceService.addCameraInstallationPoint(
                            child,
                            mapId,
                            locations[locationIndex++],
                            {
                                ...options,
                                parentId: parentIP._id,
                            },
                            mapImageRotationDegrees,
                        );
                    } else if (parentType === 'encoder') {
                        await this.installationPointDeviceService.addAnalogCameraInstallationPoint(
                            child,
                            mapId,
                            locations[locationIndex++],
                            {
                                ...options,
                                parentId: parentIP._id,
                            },
                            mapImageRotationDegrees,
                        );
                    } else if (parentType === 'doorcontroller') {
                        await this.installationPointDeviceService.addDoorInstallationPoint(
                            child,
                            mapId,
                            locations[locationIndex++],
                            {
                                ...options,
                                parentId: parentIP._id,
                            },
                        );
                    } else if (parentType === 'iorelay') {
                        await this.installationPointDeviceService.addIORelayExpansionModulePoint(
                            child,
                            mapId,
                            locations[locationIndex++],
                            {
                                ...options,
                                parentId: parentIP._id,
                            },
                        );
                    }
                }
            });
        }
        return parentIP;
    }

    public async createChildrenAfterDuplicate(
        installationPoint_A: IInstallationPointEntity,
        installationPoint_B: IInstallationPointEntity,
    ): Promise<Id[]> {
        // Check original ip for children
        const installationPoints =
            await this.currentProjectService.getAllEntitiesOfType('installationPoint');
        const children = installationPoints.filter(
            (installationPoint) => installationPoint.parentId === installationPoint_A._id,
        );

        // Check if duplicated or newInstance
        const newParentDevice =
            installationPoint_A.path.slice(-2, -1)[0] !== installationPoint_B.path.slice(-2, -1)[0];

        if (newParentDevice) {
            return this.createChildrenAfterDuplicateToNewDevice(children, installationPoint_B);
        }

        return Promise.all(
            children.map((child) => {
                return this.duplicationService.duplicateInstallationPoint(
                    child._id,
                    installationPoint_B._id,
                    this.getChildLocation(installationPoint_B.location),
                );
            }),
        );
    }

    public async addChildInstallationPoints(
        parents: IPersistence<IInstallationPointEntity>[],
        child: IPersistence<IItemEntity>,
        mapImageRotationDegrees?: number,
    ) {
        if (
            !child.properties.sensorUnit &&
            !child.properties.analogCamera &&
            !child.properties.door &&
            !child.properties.pac
        ) {
            throw new Error(`Unexpected child device type: ${child}`);
        }

        const childToAdd = parents.map((parentIP) => {
            const parentMapId = parentIP.floorPlanId ?? parentIP.mapOrigin;
            const makeGlobal = parentIP.floorPlanId === undefined;
            const location = this.getChildLocation(parentIP.location);
            if (child.properties.sensorUnit) {
                return this.installationPointDeviceService.addCameraInstallationPoint(
                    child,
                    parentMapId,
                    location,
                    {
                        global: makeGlobal,
                        parentId: parentIP._id,
                    },
                    mapImageRotationDegrees,
                );
            } else if (child.properties.analogCamera) {
                return this.installationPointDeviceService.addAnalogCameraInstallationPoint(
                    child,
                    parentMapId,
                    location,
                    {
                        global: makeGlobal,
                        parentId: parentIP._id,
                    },
                    mapImageRotationDegrees,
                );
            } else if (child.properties.door) {
                return this.installationPointDeviceService.addDoorInstallationPoint(
                    child,
                    parentMapId,
                    location,
                    {
                        global: makeGlobal,
                        parentId: parentIP._id,
                    },
                );
            } else if (child.properties.pac) {
                return this.installationPointDeviceService.addIORelayExpansionModulePoint(
                    child,
                    parentMapId,
                    location,
                    {
                        global: makeGlobal,
                        parentId: parentIP._id,
                    },
                );
            }
        });
        return Promise.all(childToAdd);
    }

    public async addChildUnitToAllInstallationPointParents(
        item: IPersistence<IItemEntity>,
        location: ILatLng,
        type: DeviceAndSubType,
        mapImageRotationDegrees?: number,
    ): Promise<IInstallationPointEntity[]> {
        // When adding a sensor unit or analog camera, all instances of the parent must have this new installation point.

        const parentDeviceId = item.path.slice(-2, -1);
        const installationPoints = await this.installationPointRepository.getDescendants(
            parentDeviceId[0],
        );
        const parentInstallationPoints = installationPoints.descendants.filter(
            (installationPoint) => !installationPoint.parentId,
        );
        const relativePosition: ILatLng = this.getParentRelativePosition(
            parentInstallationPoints,
            location,
        );

        return Promise.all(
            parentInstallationPoints.map((parentIP) => {
                const parentMapId = parentIP.floorPlanId ?? parentIP.mapOrigin;
                const makeGlobal = parentIP.floorPlanId === undefined;
                const position = {
                    lat: parentIP.location.lat - relativePosition.lat,
                    lng: parentIP.location.lng - relativePosition.lng,
                };
                switch (type) {
                    case 'sensorUnit':
                        return this.installationPointDeviceService.addCameraInstallationPoint(
                            item,
                            parentMapId,
                            position,
                            {
                                global: makeGlobal,
                                parentId: parentIP._id,
                            },
                            mapImageRotationDegrees,
                        );
                    case 'analogCamera':
                        return this.installationPointDeviceService.addAnalogCameraInstallationPoint(
                            item,
                            parentMapId,
                            position,
                            {
                                global: makeGlobal,
                                parentId: parentIP._id,
                            },
                            mapImageRotationDegrees,
                        );
                    case 'door':
                        return this.installationPointDeviceService.addDoorInstallationPoint(
                            item,
                            parentMapId,
                            position,
                            {
                                global: makeGlobal,
                                parentId: parentIP._id,
                            },
                        );
                    case 'relayexpmodules':
                        return this.installationPointDeviceService.addIORelayExpansionModulePoint(
                            item,
                            parentMapId,
                            position,
                            {
                                global: makeGlobal,
                                parentId: parentIP._id,
                            },
                        );
                    default:
                        throw new Error('Unsupported parent device type');
                }
            }),
        );
    }

    public async addChildItemToMap(
        childId: Id,
        quantity: number,
        parentId: Id,
        mapImageRotationDegrees?: number,
    ) {
        const { descendants: parentAndChildIps } =
            await this.installationPointRepository.getDescendants(parentId);

        const parentIps = Object.values(parentAndChildIps).filter(
            (ip) => ip.parentId === undefined,
        );

        if (parentIps.length > 0) {
            const itemEntity = this.currentProjectService.getEntity(childId, 'item');
            for (let i = 0; i < quantity; ++i) {
                await this.addChildInstallationPoints(
                    parentIps,
                    itemEntity,
                    mapImageRotationDegrees,
                );
            }
        }
    }

    private async createChildrenAfterDuplicateToNewDevice(
        existingInstallationPointChildren: IInstallationPointEntity[],
        newParentInstallationPoint: IInstallationPointEntity,
    ): Promise<Id[]> {
        const parentDeviceId = getParentId(newParentInstallationPoint);
        if (!parentDeviceId) {
            return [];
        }
        const device = this.currentProjectService.getEntity(parentDeviceId, 'item');
        // Find all child devices e.g sensorUnits, analogCameras, expansion modules, and doors
        const { descendants } = await this.itemRepository.getDescendants(parentDeviceId);
        const deviceChildren = descendants.filter(
            (descendant) =>
                descendant.properties.sensorUnit ||
                descendant.properties.analogCamera ||
                descendant.properties.door ||
                descendant.properties.pac,
        );

        if (deviceChildren.length > 0) {
            // Sort both arrays so we match the installation point settings for the correct model.
            const sortedExisting = existingInstallationPointChildren.sort(
                this.sortInstallationPointsByPiaId,
            );

            const sortedDeviceChildren = this.getSortedDeviceChildren(deviceChildren);
            return Promise.all(
                sortedDeviceChildren.map((child, index) => {
                    const path = [...child.path];
                    return this.duplicationService.duplicateInstallationPointChildToDevice(
                        sortedExisting[index],
                        path,
                        newParentInstallationPoint._id,
                        this.getChildLocation(newParentInstallationPoint.location),
                    );
                }),
            );
        }
        return [device._id];
    }

    private getSortedDeviceChildren(children: IPersistence<IItemEntity>[]) {
        const sortedChildren = children.sort(this.sortDevicesByPiaId);
        return flatMap(sortedChildren, (child) => times(child.quantity, constant(child)));
    }

    private async addParentUnitInstallationPoint(
        item: IItemEntity,
        installationPointName: string,
        mapId: Id,
        location: ILatLng,
        options: {
            global?: boolean;
        },
    ) {
        return this.installationPointRepository.add({
            type: 'installationPoint',
            path: [...item.path],
            location,
            labelOffset: IP_LABEL_OFFSET,
            height: this.currentProjectService.getProjectCustomInstallationHeight(),
            sensors: [],
            floorPlanId: options.global ? undefined : mapId,
            mapOrigin: mapId,
            locked: item.locked,
            archived: item.archived,
            name: installationPointName,
            serialNumber: undefined,
            linkedId: item.linkedId,
        });
    }

    private async getChildUnits(parentId: Id): Promise<IItemEntity[]> {
        const { descendants } = await this.itemRepository.getDescendants(parentId);
        return descendants.filter(
            (descendant) =>
                descendant.properties.sensorUnit ||
                descendant.properties.analogCamera ||
                descendant.properties.door ||
                descendant.properties.pac,
        );
    }

    private getChildUnitQuantity(sensorUnits: IItemEntity[]) {
        const quantity = sensorUnits.reduce((sum, cur) => {
            return sum + cur.quantity;
        }, 0);
        return quantity;
    }

    private getChildLocations(parentLocation: ILatLng, noOfChildren: number): ILatLng[] {
        const defaultRotation = Math.PI * 1.25;
        const angle = (Math.PI * 2) / noOfChildren;
        const locations = [];

        for (let index = 1; index <= noOfChildren; index++) {
            locations.push(this.getChildLocation(parentLocation, angle * index + defaultRotation));
        }
        return locations;
    }

    /**
     * Returns a point on a circle around the parent with radius of 3 meters
     * @param desiredAngle - if not provided a random point will be returned
     */
    private getChildLocation(parentLocation: ILatLng, desiredAngle?: number): ILatLng {
        const angle = desiredAngle ?? Math.random() * Math.PI * 2;
        const radius = CHILD_RADIUS;

        const x = Math.cos(angle) * radius;
        const y = Math.sin(angle) * radius;
        return offset(parentLocation)([x, y]);
    }

    private getParentRelativePosition(
        parentInstallationPoints: IPersistence<IInstallationPointEntity>[],
        location: ILatLng,
    ) {
        let closestParent = parentInstallationPoints[0];
        let minDistance = Number.POSITIVE_INFINITY;
        parentInstallationPoints.forEach((parentIP) => {
            const parentDistance = new LatLng(
                parentIP.location.lat,
                parentIP.location.lng,
            ).distanceTo(new LatLng(location.lat, location.lng));
            if (parentDistance < minDistance) {
                minDistance = parentDistance;
                closestParent = parentIP;
            }
        });
        const relativePosition: ILatLng = {
            lat: closestParent.location.lat - location.lat,
            lng: closestParent.location.lng - location.lng,
        };
        return relativePosition;
    }

    private sortInstallationPointsByPiaId = (
        prev: IInstallationPointEntity,
        next: IInstallationPointEntity,
    ) => {
        const prevDevice = this.currentProjectService.getEntity(prev.path[2], 'item');
        const nextDevice = this.currentProjectService.getEntity(next.path[2], 'item');

        return this.sortDevicesByPiaId(prevDevice, nextDevice);
    };

    private sortDevicesByPiaId = (prevDevice: IItemEntity, nextDevice: IItemEntity) => {
        const prevValue = prevDevice === null ? '' : '' + prevDevice.productId;
        const nextValue = nextDevice === null ? '' : '' + nextDevice.productId;

        return prevValue > nextValue ? 1 : prevValue === nextValue ? 0 : -1;
    };
}
