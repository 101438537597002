import type { ProductWithAccessory } from '../types';

/**
 * A function that removes all the acaps that are included in products.
 * @param selectedAcapsByProduct - acaps that are selected
 * @param includedAcapsByProduct - acaps that are included
 * @returns acaps that are not included
 */
export const removeAllIncludedAcaps = (
    selectedAcapsByProduct: ProductWithAccessory,
    includedAcapsByProduct: ProductWithAccessory,
): ProductWithAccessory => {
    // If the selected acaps or the included acaps are empty, return an empty object
    if (
        Object.keys(selectedAcapsByProduct).length === 0 ||
        Object.keys(includedAcapsByProduct).length === 0
    ) {
        return {};
    }

    // Compare the selected acaps with the included acaps
    return Object.keys(selectedAcapsByProduct).reduce((acc, key) => {
        // Get the selected acaps and the included acaps
        const selectedAcaps = selectedAcapsByProduct[Number(key)] ?? [];
        // Get the included acaps
        const includedAcaps = includedAcapsByProduct[Number(key)] ?? [];
        // Get the acaps that are not included
        const diff = selectedAcaps.filter(
            // Check if the acap is not included in the included acaps
            (selectedAcap) =>
                !includedAcaps.some((includedAcap) => includedAcap.id === selectedAcap.id),
        );
        // Assign the difference to the result object
        acc[Number(key)] = diff;

        return acc;
    }, {} as ProductWithAccessory);
};
