import type { Id } from '..';
import type { UnitSystem, PowerCalcMethod } from 'app/core/persistence';
import type { IProjectOriginEntity } from './IProjectOriginEntity';
import type { ProjectState } from './ProjectState';
import type { IProjectShareToken } from './IProjectShareToken';
import type {
    BandwidthVersion,
    ResourceGroupId,
    TemperatureScale,
} from 'app/core/persistence/models';
import type { IProjectNetworkSettings } from './IProjectNetworkSettings';
import type { IBounds } from '../../../models/maps/IBounds';

export type RecordingSolutionType =
    | 'AxisS1X'
    | 'AxisS2X'
    | 'AxisS30'
    | 'AxisS1XPro'
    | 'AxisS2XPro'
    | 'AxisS30Pro'
    | 'AxisCompanionS30'
    | 'AxisCompanionSDCard'
    | 'Milestone'
    | 'Genetec';

export type SelectedVendorType = 'Axis' | 'Genetec' | 'Milestone';

export type CameraStationType = 'CameraStation5' | 'CameraStationPro';

export type CameraStationCenterType = 'NoCenter' | 'Center1year' | 'Center5years';

export enum ProjectZipType {
    off = 0,
    low = 1,
    medium = 2,
    high = 3,
    max = 4,
}

/**
 * Project database origin for user projects.
 */
export const ProjectDbOriginAsdUserData = 'asd-user-data';

/**
 * Project database origin for local projects.
 */
export const ProjectDbOriginAsdLocalUserData = 'asd-local-user-data';

/**
 * Project database origin.
 * This is used to determine where the project is stored.
 * If the project is stored in a shared database, the origin will be the resource group id
 * else the origin will be 'asd-user-data' or 'asd-local-user-data'.
 */
export type ProjectDbOrigin =
    | typeof ProjectDbOriginAsdUserData
    | typeof ProjectDbOriginAsdLocalUserData
    | ResourceGroupId;

export interface IProject {
    archived: boolean;
    defaultProfile: Id;
    installationPiaLocationId: number;
    location?: { lat: number; lng: number };
    bounds?: IBounds;
    locationName?: string;
    name: string;
    notes: string;
    origin?: IProjectOriginEntity;
    powerCalcMethod: PowerCalcMethod;
    shareToken?: IProjectShareToken;
    state: ProjectState;
    unitSystem: UnitSystem;
    temperatureScale: TemperatureScale;
    customInstallationHeight: number;
    networkSettings?: IProjectNetworkSettings;
    bandwidthVersion: BandwidthVersion;
    projectZipSetting: ProjectZipType;
    recordingRetentionTimeInDays: number;
    recordingSolutionType?: RecordingSolutionType;
    projectDbOrigin?: ProjectDbOrigin;
    lastExportedDate: string;
    devicesQuantity: number;
    hasFloorPlanOrMapLocation: boolean;
    installationReportNotes?: string;
    genetecProjectId?: string | null;
    genetecRecommendationType?: number;
    selectedRecordingVendor?: SelectedVendorType;
    selectedCameraStationType?: CameraStationType;
    selectedCameraStationCenterType?: CameraStationCenterType;
    powerIlluminatorTimeInHours?: number;
    /**
     * Id that makes it possible to track this project between applications.
     * Use this field to set your custom Id to link this project to your own.
     */
    linkedProjectId?: string;
}
