import { injectable } from 'inversify';
import PouchDB from 'pouchdb-browser';
import { ReplicationService } from '../userDataPersistence/replication/Replication.service';
import { PersistenceDatabaseRepository } from '../userDataPersistence/repositories/persistence/PersistenceDatabase.repository';
import { StorageMigrationService } from './storageMigration';
import { ProjectDbOrigin, ProjectDbOriginAsdUserData } from '../userDataPersistence';
import type { IEntity, IIdRev } from '../userDataPersistence';
import { ProjectModelService } from './ProjectModel.service';
import { eventTracking } from 'app/core/tracking';
import { CleanupService } from './Cleanup.service';
import type { IPersistenceConfigurationService } from 'app/core/persistence/services/IPersistenceConfigurationService';
import { EventEmitter } from 'events';
import { EventEmitterService } from './EventEmitter.service';

@injectable()
export class PersistenceConfigurationService implements IPersistenceConfigurationService {
    private localDb: PouchDB.Database | undefined;
    private emitter: EventEmitter;

    constructor(
        private storageMigrationService: StorageMigrationService,
        private replicationService: ReplicationService,
        private persistenceRepository: PersistenceDatabaseRepository,
        private projectModelService: ProjectModelService,
        private cleanupService: CleanupService,
        private eventEmitterService: EventEmitterService,
    ) {
        this.emitter = this.eventEmitterService.getEventEmitter('userprojects');
    }

    public getDbOrigin(): ProjectDbOrigin {
        return ProjectDbOriginAsdUserData;
    }

    public async initialize() {
        await this.storageMigrationService.triggerMigrationIfRequired();
        this.localDb = await this.initDatabase();

        const oldDataWasCleared = await this.cleanupService.clearData(ProjectDbOriginAsdUserData);
        if (oldDataWasCleared) {
            // If old user data was cleared, the user has changed and we need to clear local database
            await this.clearLocalDatabase();
            // Re-initialize since cleanup removed the database
            await this.initialize();
            return;
        }

        await this.replicationService.setupReplication(this.localDb);
        const deletedDocsCount = await this.persistenceRepository.localDbIndexCleanup();
        if (deletedDocsCount > 0) {
            eventTracking.logApplicationEvent(
                'Persistence',
                'Local db cleanup',
                'Deleted indexes',
                deletedDocsCount,
            );
        }

        this.emitter.on('change', (update: IEntity) => {
            this.projectModelService.handleEntityUpdate(update);
        });

        this.emitter.on('delete', (deleted: IIdRev) => {
            this.projectModelService.handleEntityDelete(deleted);
        });
    }

    public async initDatabase() {
        this.localDb = new PouchDB(ProjectDbOriginAsdUserData, {
            auto_compaction: true,
        });
        await this.persistenceRepository.initialize(this.localDb, this.emitter);

        return this.localDb;
    }

    public async syncAll() {
        return this.replicationService.syncAll();
    }

    public async clearLocalDatabase() {
        if (!this.localDb) {
            return;
        }
        await this.localDb.destroy();
        this.localDb = undefined;
    }
}
