import { Box, Border, Text, Modal, Button, Persona } from 'app/components';
import type { IContactInfo } from 'app/core/persistence';
import * as React from 'react';
import { t } from 'app/translate';

interface IMemberInfo {
    members: IContactInfo[];
    title: string;
    onClose(): void;
}

export const MemberInfoDialog: React.FC<IMemberInfo> = ({ members, title, onClose }) => {
    return (
        <Modal title={title} onClose={onClose}>
            <Box alignItems="start" direction="column" spacing="half" paddingX="half">
                <Text color="grey8">{t.members(members.length)}</Text>
                <Box spacing="half" display="grid" grid={{ gridTemplateColumns: '1fr 1fr' }}>
                    {members.map((member, index) => {
                        return (
                            <Border
                                key={index}
                                width={0}
                                radius="8px"
                                shadow="0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)"
                            >
                                <Box
                                    color="grey2"
                                    alignItems="center"
                                    justifyContent="start"
                                    padding="half"
                                >
                                    <Persona
                                        primaryText={member.name ?? t.pending}
                                        secondaryText={member.email}
                                        icon={!member.name ? 'schedule' : undefined}
                                    />
                                </Box>
                            </Border>
                        );
                    })}
                </Box>
            </Box>
            <Box justifyContent="end" spacing="base" paddingTop="base">
                <Button text onClick={onClose}>
                    {t.close}
                </Button>
            </Box>
        </Modal>
    );
};
