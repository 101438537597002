import * as React from 'react';
import { t as i18nextT } from 'i18next';
import { coerce } from 'semver';
import { css, cx } from '@emotion/css';
import {
    Box,
    Button,
    Clickable,
    DropDown,
    Icon,
    Linkable,
    Spacer,
    Stack,
    Text,
} from 'app/components';
import { t } from 'app/translate';
import { AppConstants } from 'app/AppConstants';
import { ColorsEnum, SpacingsEnum } from 'app/styles';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { getPartnerConfigHeaderStyle } from 'app/modules/common';
import { getItemLocalStorage, setItemLocalStorage } from 'app/core/persistence';

const badgeStyle = css`
    position: absolute;
    top: -4px;
    right: -4px;
    border-radius: 50%;
    height: 14px;
    width: 14px;
`;

const listStyle = css`
    overflow-y: auto;
    padding-right: ${SpacingsEnum.panel};
    color: ${ColorsEnum.grey7};
`;

/**
 * NOTE: 'bullets' section in releaseNotes.json MUST be updated to match EVERY release.
 * 'videoUrl' should only reside in english file so we don't have to update every single translated file.
 *
 * See WT-4086
 * Component for notifying users that a new version is available as well as showing new features in the current release.
 */
export const VersionUpdateNotifier: React.FC = () => {
    const updateAvailable = useSelector((state: IStoreState) => state.app.updateAvailable);
    // coerce ignores build version and only compares major, minor and patch (X.X.X) so we don't trigger release notes for our 'patch releases'.
    const thisVersion = coerce(AppConstants.appVersion);
    const storedVersion = coerce(getItemLocalStorage('ReleaseVersion') ?? '0.0.0');
    const foregroundColor = useSelector(getPartnerConfigHeaderStyle)?.foregroundColor;

    // Get translated release notes and link to video.
    const releaseNotes: string[] = i18nextT('bullets', {
        ns: 'releaseNotes',
        returnObjects: true,
    }) as string[];
    const videoUrl = i18nextT('videoUrl', {
        ns: 'releaseNotes',
    });

    const [needRefresh, setNeedRefresh] = React.useState<boolean>(updateAvailable);
    const hasNewVersion = thisVersion?.version !== storedVersion?.version;

    // If we have a different version compared to localStorage, we have not seen the new release notes,
    // and must reset the localstorage values, so icon and badge becomes visible, and also update to new version.
    // NOTE: Only if we actually provide any release notes.
    if (hasNewVersion && thisVersion) {
        const value = releaseNotes.length > 0 ? 'false' : 'true';

        setItemLocalStorage('ReleaseNotesShown', value);
        setItemLocalStorage('ReleaseNotesAccepted', value);
        setItemLocalStorage('ReleaseVersion', thisVersion.version);
    }

    const releaseNotesOpened = getItemLocalStorage('ReleaseNotesShown');
    const releaseNotesAccepted = getItemLocalStorage('ReleaseNotesAccepted');
    const [hasOpenedNotes, setHasOpenedNotes] = React.useState<boolean>(
        releaseNotesOpened ? releaseNotesOpened === 'true' : false,
    );
    const [hasAcceptedNotes, setHasAcceptedNotes] = React.useState<boolean>(
        releaseNotesAccepted ? releaseNotesAccepted === 'true' : false,
    );

    const [showUpdateIcon, setShowUpdateIcon] = React.useState<boolean>(
        needRefresh || !hasAcceptedNotes,
    );
    const [showUpdateBadge, setShowUpdateBadge] = React.useState<boolean>(
        needRefresh || !hasOpenedNotes,
    );

    React.useEffect(() => {
        setNeedRefresh(updateAvailable);
    }, [updateAvailable]);

    React.useEffect(() => {
        setShowUpdateIcon(needRefresh || !hasAcceptedNotes);
        setShowUpdateBadge(needRefresh || !hasOpenedNotes);
    }, [needRefresh, hasOpenedNotes, hasAcceptedNotes]);

    // This is triggered when clicking the icon, this could be due to needRefresh or hasNewVersion,
    // so only set RELEASE_NOTES_OPENED if new version.
    const setNotesOpened = () => {
        if (!needRefresh) {
            setItemLocalStorage('ReleaseNotesShown', 'true');
            setHasOpenedNotes(true);
        }
    };

    const triggerElement = showUpdateIcon ? (
        <>
            <Clickable onClick={setNotesOpened}>
                <Icon
                    color="yellowText"
                    colorOverride={foregroundColor}
                    icon="updates"
                    opaque
                    size="md"
                />
            </Clickable>
            {showUpdateBadge && (
                <div
                    className={cx(
                        badgeStyle,
                        css`
                            background-color: ${ColorsEnum.yellowText};
                        `,
                    )}
                >
                    <Icon color="white" icon="error" size="sm" opaque />
                </div>
            )}
        </>
    ) : undefined;

    const onReload = () => {
        location.reload();
    };

    const onGotIt = () => {
        setItemLocalStorage('ReleaseNotesAccepted', 'true');
        setHasAcceptedNotes(true);
    };

    const needRefreshContent = (
        <Stack spacing="none" vertical>
            <Box justifyContent="center" paddingY="base" paddingX="quart" color="grey1">
                <Text style="heading" color="grey7">
                    {t.updateAvailable}
                </Text>
            </Box>
            <Box direction="column" padding="base" alignItems="center" color="white">
                <Text>{t.updateAvailableText}</Text>
                <Box paddingTop="base">
                    <Button primary onClick={onReload}>
                        {t.reload}
                    </Button>
                </Box>
            </Box>
        </Stack>
    );

    const versionInfoContent = (
        <Box direction="column" alignItems="center" color="white">
            <Box
                width="100%"
                justifyContent="center"
                alignItems="center"
                padding="base"
                color="grey2"
            >
                <Text style="heading" color="grey7">
                    {t.whatsNew}
                </Text>
            </Box>
            <Box paddingTop="half">
                <ul className={listStyle}>
                    {releaseNotes.map((bullet) => (
                        <li key={bullet}>
                            {bullet}
                            <Spacer />
                        </li>
                    ))}
                </ul>
            </Box>
            {videoUrl.length > 0 && videoUrl !== 'videoUrl' && (
                <Box justifyContent="center">
                    <Linkable external link={videoUrl}>
                        <Clickable>
                            <Text color="blue" style="semibold">
                                {t.learnMoreOnYouTube}
                            </Text>
                        </Clickable>
                    </Linkable>
                </Box>
            )}
            <Box paddingY="base" justifyContent="center">
                <Button primary onClick={onGotIt}>
                    {t.gotIt}
                </Button>
            </Box>
        </Box>
    );

    return showUpdateIcon ? (
        <DropDown
            trigger={triggerElement}
            minWidth={220}
            maxHeight={700}
            contents={needRefresh ? needRefreshContent : versionInfoContent}
        />
    ) : null;
};
