import { createSelector } from 'reselect';
import { getCurrentProjectId } from './getCurrentProject';
import type { IStoreState } from 'app/store';

const getCurrentView = (state: IStoreState) => state.app.currentView;

export const getCurrentProjectBasePath = createSelector(
    [getCurrentProjectId, getCurrentView],
    (projectId, currentView) =>
        currentView === 'sharedprojects'
            ? `/sharedproject/${projectId}/`
            : `/project/${projectId}/`,
);
