import { UserService } from './user/services/User.service';
import type { IPiaLocation } from 'app/core/pia';
import { PiaLocationService } from 'app/core/pia';
import { injectable } from 'inversify';
import type { IEntity, IIdRev, ProjectDbOrigin } from '../userDataPersistence';
import { ProjectZipType, EntitySettings, QuotationProgress } from '../userDataPersistence';

import { ProjectModelService } from './ProjectModel.service';
import { ProjectService } from './Project.service';

import { DEFAULT_RETENTION_TIME_IN_DAYS, defaultInstallationHeightMetric } from '../utils';
import type { IPersistenceConfigurationService } from './IPersistenceConfigurationService';
import { EventEmitterService } from './EventEmitter.service';

const LOCATION_API_URL = 'https://www.axis.com/api/geo/location/sitedesigner';
const USA_PIA_LOCATION_ID = 230;
@injectable()
export class MemoryPersistenceConfigurationService implements IPersistenceConfigurationService {
    private emitter: EventEmitter;

    constructor(
        private projectModelService: ProjectModelService,
        private projectService: ProjectService,
        private entitySettings: EntitySettings,
        private piaLocationService: PiaLocationService<IPiaLocation>,
        private userService: UserService,
        private eventEmitterService: EventEmitterService,
    ) {
        this.emitter = this.eventEmitterService.getEventEmitter('memory');
    }

    public getDbOrigin(): ProjectDbOrigin | undefined {
        // not used;
        return undefined;
    }

    public async initialize() {
        this.entitySettings.isStandalone = true;
        const piaLocation = this.piaLocationService
            .getByCountryCode((await this.getUserCountryCode()) || 'us')
            .first();
        const project = await this.projectService.add({
            name: '',
            archived: false,
            defaultProfile: '',
            installationPiaLocationId: piaLocation ? piaLocation.id : USA_PIA_LOCATION_ID,
            notes: '',
            powerCalcMethod: 'poeClass',
            state: QuotationProgress.Designing,
            unitSystem: 'metric',
            temperatureScale: 'celsius',
            customInstallationHeight: defaultInstallationHeightMetric,
            projectZipSetting: ProjectZipType.medium,
            recordingRetentionTimeInDays: DEFAULT_RETENTION_TIME_IN_DAYS,
            lastExportedDate: '',
            devicesQuantity: 0,
            hasFloorPlanOrMapLocation: false,
        });

        this.emitter.on('change', (update: IEntity) => {
            this.projectModelService.handleEntityUpdate(update);
        });

        this.emitter.on('delete', (deleted: IIdRev) => {
            this.projectModelService.handleEntityDelete(deleted);
        });

        return project;
    }

    public async syncAll() {
        // No-op
    }

    public async initDatabase() {
        // No-op
    }

    public async clearLocalDatabase() {
        // No-op
    }

    private async getUserCountryCode(): Promise<string | null> {
        try {
            const user = await this.userService.getUser();
            if (user) {
                return user.country;
            }
            const response = await fetch(LOCATION_API_URL);
            const location = await response.json();
            return location.country_code;
        } catch (e) {
            return null;
        }
    }
}
