import { createSelector } from 'reselect';
import { getProductAllowlist } from '../../piaDevices';
import { CategoryEnum } from '../Category.enum';
import type { IProduct } from '../IProduct';
import { getProducts } from './getProducts';

export const getProductsWithAllowlistProperty = createSelector(
    [getProducts, getProductAllowlist],
    (products, allowlist): IProduct[] => {
        const filteredProducts = products.filter(
            (product) => product.category !== CategoryEnum.InstallationService,
        );

        if (!allowlist) {
            return filteredProducts;
        }

        return filteredProducts.map((product) => {
            if (!product.piaId) {
                return product;
            }

            product.allowlisted =
                allowlist.recommendedProducts.includes(product.piaId) ||
                allowlist.otherProducts.includes(product.piaId);

            return product;
        });
    },
);
