import { injectable } from 'inversify';
import type {
    IEntity,
    IProfileEntity,
    IProjectEntity,
    IMainUnitItemEntity,
    ISensorUnitItemEntity,
    Id,
    IBaseEntity,
    IExportFileV1,
    UnitSystem,
    IImportableFileData,
} from 'app/core/persistence';
import { TimestampProviderService, toRecord, ImportError } from 'app/core/persistence';
import { EntitySettings } from 'app/core/persistence/userDataPersistence';
import {
    ImportedItemBase,
    ImportedItems,
    ImportedMap,
    ImportedPiaProduct,
    ImportedProjectSettingsFile,
} from '../models/ImportedProjectSettingTypes';
import {
    CamerasImporterService,
    ProfileImporterService,
    ProjectImporterService,
    ScheduleImporterService,
    SpeakersImporterService,
    MainUnitImporterService,
    PeopleCounterImporterService,
    AlerterImporterService,
    RadarImporterService,
    EncoderImporterService,
    SystemControllerImporterService,
    DoorStationsImporterService,
    PacImporterService,
    CameraExtensionImporterService,
    BodyWornCameraImporterService,
    ConnectivityDeviceImporterService,
    PagingConsoleImporterService,
    DockingStationImporterService,
    DecoderImporterService,
    FloorPlanImporterService,
} from './importers';
import { ProjectSettingsValidator } from './ProjectSettingsValidator.service';
import { readFileToJson } from 'app/modules/common';

@injectable()
export class ProjectSettingsImporterService {
    constructor(
        private entitySettings: EntitySettings,
        private projectImporterService: ProjectImporterService,
        private timestampProvider: TimestampProviderService,
        private scheduleImporterService: ScheduleImporterService,
        private profileImporterService: ProfileImporterService,
        private alertersImporterService: AlerterImporterService,
        private bodyWornCameraImporterService: BodyWornCameraImporterService,
        private cameraExtensionImporterService: CameraExtensionImporterService,
        private camerasImporterService: CamerasImporterService,
        private decodersImporterService: DecoderImporterService,
        private dockingStationsImporterService: DockingStationImporterService,
        private connectivityDevicesImporterService: ConnectivityDeviceImporterService,
        private speakersImporterService: SpeakersImporterService,
        private mainUnitsImporterService: MainUnitImporterService,
        private peopleCounterImporterService: PeopleCounterImporterService,
        private radarsImporterService: RadarImporterService,
        private encodersImporterService: EncoderImporterService,
        private systemControllerImporterService: SystemControllerImporterService,
        private doorStationImporterService: DoorStationsImporterService,
        private pacImporterService: PacImporterService,
        private pagingConsolesImporterService: PagingConsoleImporterService,
        private floorPlanImporterService: FloorPlanImporterService,
        private projectSettingsValidator: ProjectSettingsValidator,
    ) {}

    public async getImportableProjectFromFile(file: File): Promise<IImportableFileData> {
        const json = await readFileToJson(file);
        const { data: importedSettingsFile } = this.validateJson(json);
        const importableFileData = await this.convertSettingsToProject(importedSettingsFile);

        return {
            fileData: importableFileData,
            projectName: importedSettingsFile.settings.name,
        } satisfies IImportableFileData;
    }

    private validateJson(json: any) {
        const validationResult = this.projectSettingsValidator.validateV1(json);

        if (!validationResult.valid) {
            console.error('Validation errors: ' + validationResult.errors);
            throw new ImportError('invalidFileFormat');
        }

        return validationResult;
    }

    private async convertSettingsToProject(
        settings: ImportedProjectSettingsFile,
    ): Promise<IExportFileV1> {
        const projectEntity = await this.getProjectEntity(settings);
        const childrenEntities = await this.getImportableEntities(
            settings,
            projectEntity.unitSystem,
        );

        const projectEntityWithDefaultProfile = {
            ...projectEntity,
            defaultProfile: childrenEntities.find((entity) => entity.type === 'profile')?._id ?? '',
        };

        const importData: IExportFileV1 = {
            fileVersion: 1,
            fileType: 'exportedProject',
            application: 'SiteDesigner2',
            project: this.toIEntity(projectEntityWithDefaultProfile),
            children: childrenEntities,
        };

        return importData;
    }

    private async getProjectEntity(
        settings: ImportedProjectSettingsFile,
    ): Promise<Omit<IProjectEntity, 'defaultProfile'>> {
        const projectEntity = await this.projectImporterService.getImportedProject(settings);
        return projectEntity;
    }

    private async getImportableEntities(
        settingsFile: ImportedProjectSettingsFile,
        unitSystem: UnitSystem,
    ): Promise<IEntity[]> {
        const {
            id: projectId,
            items: importedItems,
            schedules: importedSchedules,
            maps: importedMaps,
        } = settingsFile.settings;

        const schedules = this.scheduleImporterService.importSchedules(
            projectId,
            importedSchedules,
        );
        const alwaysSchedule = schedules.find(
            (schedule) => 'systemDefined' in schedule && schedule.systemDefined,
        );

        if (!alwaysSchedule) throw new Error('No always schedule found');

        const profiles = this.profileImporterService.importProfiles(
            projectId,
            importedItems,
            alwaysSchedule.path[alwaysSchedule.path.length - 1],
        );

        const cameras = this.camerasImporterService.import(
            projectId,
            importedItems.cameras,
            profiles,
            unitSystem,
        );

        const speakers = this.speakersImporterService.import(
            projectId,
            importedItems.speakers,
            unitSystem,
        );
        const pacs = this.pacImporterService.import(
            projectId,
            importedItems.physicalAccessControllers,
        );

        const mainUnits = this.mainUnitsImporterService.import(
            projectId,
            importedItems.mainUnits,
            profiles,
            unitSystem,
        );

        const mainUnitEntities = mainUnits.map(
            (mainUnit) => mainUnit as IMainUnitItemEntity | ISensorUnitItemEntity,
        );

        const peopleCounters = this.peopleCounterImporterService.import(
            projectId,
            importedItems.peopleCounters,
        );

        const radars = this.radarsImporterService.import(projectId, importedItems.radars);
        const alerters = this.alertersImporterService.import(projectId, importedItems.alerters);

        const encoders = this.encodersImporterService.import(
            projectId,
            importedItems.encoders,
            profiles,
        );

        const systemControllers = this.systemControllerImporterService.import(
            projectId,
            importedItems.systemControllers,
        );

        const doorStations = this.doorStationImporterService.import(
            projectId,
            importedItems.doorStations,
            profiles,
            unitSystem,
        );

        const bodyWornCameras = this.bodyWornCameraImporterService.import(
            projectId,
            importedItems.bodyWornCameras,
        );

        const connectivityDevices = this.connectivityDevicesImporterService.import(
            projectId,
            importedItems.connectivityDevices,
        );

        const pagingConsoles = this.pagingConsolesImporterService.import(
            projectId,
            importedItems.pagingConsoles,
        );

        const cameraExtensions = this.cameraExtensionImporterService.import(
            projectId,
            importedItems.cameraExtensions,
        );

        const dockingStations = this.dockingStationsImporterService.import(
            projectId,
            importedItems.dockingStations,
        );

        const decoders = this.decodersImporterService.import(projectId, importedItems.decoders);

        const profileEntities = profiles.map((profile) => profile as IProfileEntity);
        const items = [
            ...cameras,
            ...profileEntities,
            ...schedules,
            ...speakers,
            ...mainUnitEntities,
            ...peopleCounters,
            ...radars,
            ...alerters,
            ...encoders,
            ...systemControllers,
            ...doorStations,
            ...pacs,
            ...bodyWornCameras,
            ...connectivityDevices,
            ...pagingConsoles,
            ...dockingStations,
            ...cameraExtensions,
            ...dockingStations,
            ...decoders,
        ];

        const piaProducts = this.createIdToProductRecord(importedItems);
        const mapEntities = this.floorPlanImporterService.import(
            projectId,
            importedMaps as ImportedMap[],
            piaProducts,
        );

        const children = [...items, ...mapEntities].map((item) => this.toIEntity(item));

        return children;
    }

    private toIEntity<T extends IBaseEntity>(entity: T): IEntity {
        return {
            ...entity,
            creationDate: this.timestampProvider.getIsoTimestamp(),
            updatedDate: this.timestampProvider.getIsoTimestamp(),
            _id: entity.path[entity.path.length - 1],
            _rev: '',
            entityVersion: this.entitySettings.version,
        };
    }

    private createIdToProductRecord(importedItems: ImportedItems): Record<Id, ImportedPiaProduct> {
        return toRecord(
            (
                Object.values(importedItems) as Array<Array<ImportedPiaProduct & ImportedItemBase>>
            ).reduce(
                (products, productItems) => products.concat(productItems),
                new Array<ImportedPiaProduct & { id: Id }>(),
            ),
            'id',
        );
    }
}
