import * as React from 'react';
import {
    Border,
    Box,
    Clickable,
    DropDownMenuButton,
    Icon,
    Positioned,
    Title,
    Text,
    Spacer,
} from 'app/components';
import type { TransitionStatus } from 'react-transition-group';
import { Transition } from 'react-transition-group';
import type { Property } from 'csstype';
import { css } from '@emotion/css';
import type { IPlaceSearchResult } from 'app/modules/common';
import { PlaceSearch } from 'app/modules/common';
import { useService } from 'app/ioc';
import { useSelector } from 'react-redux';
import { MapsActionService } from '../../services';
import { getCurrentMapLocation } from '../../selectors/location';
import { isPageRTL, t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { getCurrentMapViewBounds } from '../../selectors';

const animatedDivStyle = (width: string) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: stretch;
    width: ${width};
    transition: width 200ms ease-in-out;
`;

const widthAnimationStates: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '0px',
    entering: '250px',
    entered: '250px',
    exiting: '0px',
    exited: '0px',
};

export const AddLocationButton: React.FC = () => {
    const isRtl = isPageRTL();
    const mapsActionService = useService(MapsActionService);

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [searchText, setSearchText] = React.useState<string>('');

    const currentMapLocation = useSelector(getCurrentMapLocation);
    const currentMapBounds = useSelector(getCurrentMapViewBounds);

    const addMapLocation = (searchResult: IPlaceSearchResult, useCurrentPosition?: boolean) => {
        eventTracking.logUserEvent(
            'Maps',
            'Add map location',
            useCurrentPosition ? 'From search' : 'From current position',
        );
        mapsActionService.addMapLocationBySearchResult(searchResult);

        setExpanded(false);
        setSearchText('');
    };

    React.useEffect(() => {
        !expanded && setSearchText('');
    }, [expanded]);

    return (
        <Positioned position="relative">
            <Box alignItems="center">
                <Clickable onClick={() => setExpanded((isExpanded) => !isExpanded)}>
                    <Border
                        color="grey4"
                        shadow={expanded ? '0 0 8px rgba(0 0 0 / 25%)' : undefined}
                    >
                        <Positioned position="relative" bringToFront>
                            <Title title={t.addNewMapLocation}>
                                <Box
                                    borderRadius="round"
                                    width={31}
                                    height={31}
                                    alignItems="center"
                                    justifyContent="center"
                                    color={expanded ? 'blue' : 'white'}
                                    hoverColor={expanded ? 'blue' : 'grey1'}
                                >
                                    <Icon
                                        color={expanded ? 'white' : 'blue'}
                                        opaque
                                        icon="add_place"
                                        size="ms"
                                        testId="add_map_location"
                                    />
                                </Box>
                            </Title>
                        </Positioned>
                    </Border>
                </Clickable>
                <Transition
                    in={expanded}
                    timeout={{
                        appear: 0,
                        enter: 200,
                        exit: 200,
                    }}
                    mountOnEnter
                    unmountOnExit
                    nodeRef={React.useRef(null)}
                >
                    {(state) => (
                        <Positioned
                            position="absolute"
                            right={isRtl ? 15 : undefined}
                            left={isRtl ? undefined : 15}
                        >
                            <Border
                                radius={isRtl ? '25px 0 0 25px' : '0 25px 25px 0'}
                                width={1}
                                color="grey4"
                            >
                                <Box color="white">
                                    <div className={animatedDivStyle(widthAnimationStates[state])}>
                                        <Box
                                            display="grid"
                                            grid={{
                                                gridTemplateColumns: 'min-content 1fr',
                                            }}
                                            justifyContent="center"
                                            spacing="quart"
                                            paddingRight="half"
                                        >
                                            <Spacer horizontal spacing="base" />
                                            <PlaceSearch
                                                noIcon
                                                noBorder
                                                placeHolderHint={t.typeToAddLocation}
                                                onSelect={addMapLocation}
                                                onSearchTextChange={setSearchText}
                                                onClear={() => setExpanded(false)}
                                            >
                                                {searchText && (
                                                    <DropDownMenuButton
                                                        icon="satellite_off"
                                                        testId="add_current_location"
                                                        width={300}
                                                        onClick={() =>
                                                            currentMapBounds &&
                                                            currentMapLocation &&
                                                            addMapLocation(
                                                                {
                                                                    address: searchText,
                                                                    bounds: currentMapBounds,
                                                                    location: currentMapLocation,
                                                                },
                                                                true,
                                                            )
                                                        }
                                                    >
                                                        <Text style="semibold">
                                                            {t.addLocationAs(searchText)}
                                                        </Text>
                                                    </DropDownMenuButton>
                                                )}
                                            </PlaceSearch>
                                        </Box>
                                    </div>
                                </Box>
                            </Border>
                        </Positioned>
                    )}
                </Transition>
            </Box>
        </Positioned>
    );
};

AddLocationButton.displayName = 'AddLocationButton';
