import type { IPiaSpeaker } from 'app/core/pia';
import type { SpeakerPlacement, ISustainabilityFilter } from 'app/core/persistence';
import { isCeilingCompatible, isWallCompatible } from 'app/modules/common';
import { defaultSustainabilityFilter, defaultSpeakerFilter } from 'app/core/persistence';
import { meetsSustainabilityRequirements } from './SustainabilityFilter';
export interface ISpeakerFilter extends ISustainabilityFilter {
    placement: SpeakerPlacement | undefined;
    outdoor: boolean;
    basicSolution: boolean;
    installationHeight: number;
    listeningArea?: number;
    wallLength?: number;
    isFilterChanged: boolean;
}

export function getDefaultSpeakerFilter(customInstallationHeight: number): ISpeakerFilter {
    return {
        ...defaultSpeakerFilter,
        ...defaultSustainabilityFilter,
        installationHeight: customInstallationHeight,
    };
}
export class SpeakerFilter {
    public static filter(
        speakerFilter: ISpeakerFilter | null,
        allSpeakers: IPiaSpeaker[],
        searchFilter: string,
    ): IPiaSpeaker[] {
        const lowerCaseSearchFilter = searchFilter.toLowerCase();
        return allSpeakers.filter((speaker) => {
            if (!speaker.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }
            if (speakerFilter === null) {
                return true;
            }
            if (speakerFilter.outdoor && !speaker.properties.outdoorReady) {
                return false;
            }
            if (speakerFilter.placement === 'wall' && !isWallCompatible(speaker)) {
                return false;
            }
            if (speakerFilter.placement === 'ceiling' && !isCeilingCompatible(speaker)) {
                return false;
            }
            if (!meetsSustainabilityRequirements(speakerFilter, speaker)) {
                return false;
            }

            return this.supportsMountingHeight(speaker, speakerFilter.installationHeight);
        });
    }

    private static supportsMountingHeight(
        speaker: IPiaSpeaker,
        installationHeight: number,
    ): boolean {
        const { minRecommendedMountingHeight, maxRecommendedMountingHeight } = speaker.properties;

        return (
            installationHeight >= minRecommendedMountingHeight &&
            installationHeight <= Math.min(maxRecommendedMountingHeight, 20)
        );
    }
}
