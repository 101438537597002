import { defaultBodyWornFilter, Id } from 'app/core/persistence';
import { ImportedBodyWornCamera } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class BodyWornCameraImporterService {
    public import(projectId: Id, items: ImportedBodyWornCamera[]) {
        const bodyWornCameras = items.map((item) => ({
            ...mapToItemEntity(projectId, item, item.piaId),
            properties: {
                bodyWornCamera: {
                    profile: {
                        sceneId: item.profile.sceneId,
                        resolution: item.profile.resolution,
                        retentionTimeInDays: item.profile.retentionTime,
                        scheduleId: item.profile.scheduleId,
                        activeRecordingInPercent: item.profile.activeRecordingInPercent,
                    },
                    filter: defaultBodyWornFilter,
                },
            },
        }));

        const accessoryEntities = getImportedAccessories(projectId, items);
        return [...bodyWornCameras, ...accessoryEntities];
    }
}
