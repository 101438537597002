import { Modal, Box, Heading, Text, IconButton, Button, IconText } from 'app/components';
import type { IProduct } from 'app/modules/common';
import { t } from 'app/translate';
import React from 'react';
import { css } from '@emotion/css';
import { BillOfMaterialsExportToBOMFile } from './BillOfMaterialsExportToBOMFile.component';
import { useService } from 'app/ioc';
import { BillOfMaterialsActionService } from '../services';
import { ColorsEnum } from 'app/styles';
import { eventTracking } from 'app/core/tracking';

const listStyle = css`
    width: 80%;
    padding: 0;
    margin: 0;

    & > li {
        display: flex;
        margin-top: 1rem;
    }

    & > li::before {
        --spacing: 0.5rem;
        counter-increment: list-item;
        content: counter(list-item);
        font-size: 1rem;
        padding-inline-end: var(--spacing);
        color: ${ColorsEnum.blue};
        display: flex;
        margin-inline-end: var(--spacing);
        margin-inline-start: calc(var(--spacing) * 2);
    }
`;

interface IProjectPricingModalProps {
    products: IProduct[];
    onClose(): void;
}

export const RequestProjectPricingModal: React.FC<IProjectPricingModalProps> = ({
    products,
    onClose,
}) => {
    const trackBOMExport = (type: 'file' | 'webpage') => {
        type === 'file'
            ? eventTracking.logUserEvent('Reports', 'Export to BOM file', 'Bill Of Materials')
            : eventTracking.logUserEvent('Reports', 'Request project pricing', 'Bill Of Materials');
    };

    const openProjectPricingPage = () => {
        trackBOMExport('webpage');
        actions.openProjectPricingPage(products);
        onClose();
    };
    const actions = useService(BillOfMaterialsActionService);
    return (
        <Modal onClose={onClose} title={t.projectPricingGROUP.mainHeadline} maxWidth="600px">
            <Box direction="column" spacing="panel" testId="project_pricing_modal">
                <Text>{t.projectPricingGROUP.intro}</Text>
                <Box direction="column" spacing="quart" alignItems="start">
                    <Heading style="subheader">
                        {t.projectPricingGROUP.requestProjectPricingWeb}
                    </Heading>
                    <Text>{t.projectPricingGROUP.requestProjectPricingWebInfo}</Text>
                    <IconButton
                        iconRight
                        icon="exit_to_app"
                        text={t.projectPricingGROUP.openProjectPricingTool}
                        onClick={openProjectPricingPage}
                    />
                </Box>
                <Box direction="column" spacing="quart" alignItems="start">
                    <Heading style="subheader">
                        {t.projectPricingGROUP.requestPricingDistributor}
                    </Heading>
                    <Text>{t.projectPricingGROUP.alternativeDistributerPricing}</Text>
                    <ol className={listStyle}>
                        <li>
                            <Box
                                direction="column"
                                spacing="base"
                                alignItems="start"
                                justifyContent="center"
                            >
                                <BillOfMaterialsExportToBOMFile
                                    trackBOMExport={() => trackBOMExport('file')}
                                    products={products}
                                >
                                    <IconText
                                        testId="project_pricing_bom_file"
                                        alignIconRight
                                        iconProps={{ opaque: true }}
                                        textProps={{ style: 'semibold' }}
                                        icon="download_doc"
                                        color="blue"
                                    >
                                        {t.projectPricingGROUP.downloadBOMFile}
                                    </IconText>
                                </BillOfMaterialsExportToBOMFile>
                            </Box>
                        </li>
                        <li>
                            <Text>{t.projectPricingGROUP.includeBOMFileInRequest}</Text>
                        </li>
                    </ol>
                </Box>
                <Box direction="column" alignItems="start" spacing="quart">
                    <Heading style="subheader">{t.projectPricingGROUP.waitForConfirmation}</Heading>
                    <Text>{t.projectPricingGROUP.awaitConfirmedPricing}</Text>
                </Box>
                <Box justifyContent="end" spacing="base">
                    <Button onClick={onClose}>{t.close}</Button>
                    <Button primary onClick={openProjectPricingPage}>
                        {t.projectPricingGROUP.openProjectPricingTool}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

RequestProjectPricingModal.displayName = 'RequestProjectPricingModal';
