import type { ProjectDbOrigin, IProject } from 'app/core/persistence';
import {
    defaultInstallationHeightMetric,
    defaultInstallationHeightImperial,
    QuotationProgress,
    ProjectZipType,
    DEFAULT_RETENTION_TIME_IN_DAYS,
} from 'app/core/persistence';
import type { IPiaLocation } from 'app/core/pia';
import { t } from 'app/translate';
import {
    getTemperatureScaleByCountryCode,
    getUnitSystemByCountryCode,
} from '../locations/services';
// Hard coded installation location to USA for now
const USA_PIA_LOCATION_ID = 230;

export const getBaseProjectEntity = (
    piaLocation: IPiaLocation | null,
    projectDbOrigin: ProjectDbOrigin,
): Omit<IProject, 'bandwidthVersion'> => {
    const unitSystem = piaLocation
        ? getUnitSystemByCountryCode(piaLocation.countryCode)
        : 'imperial';
    return {
        name: t.userNewProjectName,
        notes: '',
        unitSystem,
        // Since we do not want to have projects without profiles and at the same time
        // are required to add a project before adding a profile due to how path is
        // created in the persisted data we have to create the project first without a default profile.
        defaultProfile: '',
        temperatureScale: getTemperatureScaleByCountryCode(piaLocation),
        powerCalcMethod: 'maxPower',
        customInstallationHeight:
            unitSystem === 'metric'
                ? defaultInstallationHeightMetric
                : defaultInstallationHeightImperial,
        installationPiaLocationId: piaLocation ? piaLocation.id : USA_PIA_LOCATION_ID,
        archived: false,
        state: QuotationProgress.Designing,
        projectZipSetting: ProjectZipType.medium,
        recordingRetentionTimeInDays: DEFAULT_RETENTION_TIME_IN_DAYS,
        projectDbOrigin,
        lastExportedDate: new Date().toISOString(),
        devicesQuantity: 0,
        hasFloorPlanOrMapLocation: false,
    };
};
