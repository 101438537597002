/**
 * Get file extension in lower case
 * e.g. .pdf, .jpg, .png
 * @param file
 */
export const getFileExtension = (file: File): string => {
    if (!file.name.includes('.')) {
        return '';
    }
    return file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
};
