import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import { IPersistence, IPersistenceRepository } from '../../repositories';
import {
    IEntity,
    IFloorPlanEntity,
    IItemEntity,
    IItemRelationEntity,
    IProjectEntity,
} from '../../entities';
import { DeviceCountService } from '../../../services/DeviceCount.service';

interface IEntityRecord {
    items: IEntity[];
    relations: IEntity[];
    floorPlansOrMapLocations: IEntity[];
}

/**
 * Migration that will add devicesQuantity and hasFloorPlanOrMapLocation to projects
 */
@injectable()
export class Migration50To51 extends MigrationProviderBase {
    public from: number = 50;
    public to: number = 51;

    constructor(private deviceCountService: DeviceCountService) {
        super();
    }

    public provideMigration(repository: IPersistenceRepository) {
        return async (entity: any): Promise<any> => {
            if (entity.type === 'project') {
                const descendants = await repository.getDescendants(entity._id);
                const entityRecord = descendants.reduce(
                    (record, descendant) => {
                        if (descendant.type === 'item') {
                            record.items.push(descendant);
                        } else if (descendant.type === 'itemRelation') {
                            record.relations.push(descendant);
                        } else if (
                            (this.isFloorPlan(descendant) && !descendant.isDefault) ||
                            descendant.type === 'mapLocation'
                        ) {
                            record.floorPlansOrMapLocations.push(descendant);
                        }

                        return record;
                    },
                    { items: [], relations: [], floorPlansOrMapLocations: [] } as IEntityRecord,
                );

                const devicesQuantity = this.deviceCountService.getTotalDevicesCount(
                    this.deviceCountService.getDeviceCount(
                        entityRecord.items as IPersistence<IItemEntity>[],
                        entityRecord.relations as IPersistence<IItemRelationEntity>[],
                    ),
                );
                const hasFloorPlanOrMapLocation = entityRecord.floorPlansOrMapLocations.length > 0;

                const updatedProject: IProjectEntity = {
                    ...entity,
                    devicesQuantity,
                    hasFloorPlanOrMapLocation,
                };
                return updatedProject;
            }
            return entity;
        };
    }

    private isFloorPlan(entity: IEntity): entity is IPersistence<IFloorPlanEntity> {
        return entity.type === 'floorPlan';
    }
}
