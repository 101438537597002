import { eventTracking } from 'app/core/tracking';
import { ServiceLocator } from 'app/ioc';
import { AppStore } from 'app/store';
import { injectable } from 'inversify';
import type { IFloorPlanImage, IUploadImageOptions } from '../../models';
import type { Id } from '../../userDataPersistence';
import { toErrorMessage } from '../../utils';
import { CurrentProjectService } from '../CurrentProject.service';
import { LocalImageService } from '../LocalImage.service';
import { ProjectService } from '../Project.service';
import { convertPdfToPngBlob } from './convertPdfToPngBlob';
import { readExifOrientation } from './exifOrientation';
import { ImageStoreCommunicator } from './ImageStore.communicator';

export const IMAGE_LOAD_ERROR = 'Failed to load or parse image.';
export const MAX_IMAGE_SIZE_BYTES = 10 * 1024 * 1024;

@injectable()
export class ImageService {
    constructor(
        private localImageService: LocalImageService,
        private imageCommunicator: ImageStoreCommunicator,
        private currentProjectService: CurrentProjectService,
        private projectService: ProjectService,
    ) {}

    public async addImage(options: IUploadImageOptions, logEvent = true) {
        const isLocalProject = this.currentProjectService.getIsLocalProject();
        const image = await this.getImageBlob(options);

        if (isLocalProject) {
            return this.addLocalImage(image, options);
        } else {
            if (logEvent) {
                eventTracking.logUserEvent(
                    'Maps',
                    'Add floor plan',
                    `File size: ${options.file.size}`,
                );
            }
            return this.addCloudImage(image);
        }
    }

    public async deleteImage(key: string, throwOnError = true, projectId?: Id) {
        const isLocalProject = projectId
            ? await this.projectService.getIsLocalProject(projectId)
            : this.currentProjectService.getIsLocalProject();
        if (isLocalProject) {
            return this.localImageService.deleteLocalImage(key, throwOnError);
        }
        eventTracking.logApplicationEvent(
            'Application',
            'Deleting image',
            `ProjectId: ${projectId} Key: ${key}`,
        );
        return this.imageCommunicator.deleteImage(key, throwOnError);
    }

    public async copyImageByKey(floorPlanName: string, imageKey: string): Promise<string> {
        const isLocalProject = this.currentProjectService.getIsLocalProject();
        if (isLocalProject) {
            eventTracking.logUserEvent('Maps', 'Copy local floor plan');
            return this.localImageService.copyLocalImage(imageKey);
        } else {
            const base64Image = await this.imageCommunicator.getImageAsBase64(imageKey);
            const file = this.getFileFromDataURL(base64Image, floorPlanName);
            const uploadedImage = await this.imageCommunicator.uploadImageFile(file);

            eventTracking.logUserEvent('Maps', 'Copy floor plan');
            return uploadedImage.key;
        }
    }

    /**
     * Function called when importing project and need to copy floorPlan images from original project
     */
    public async copyBase64Image(base64Image: string, floorPlanName: string): Promise<string> {
        const file = this.getFileFromDataURL(base64Image, floorPlanName);
        // We need to find out where this new project will reside and store images according to that.
        const appStore = ServiceLocator.get(AppStore);
        const state = appStore.Store.getState();
        const isLocalProject = state.app.currentView === 'localprojects';

        if (isLocalProject) {
            eventTracking.logUserEvent('Maps', 'Copy local imported floor plan');
            return this.localImageService.addLocalImage(file);
        } else {
            const uploadedImage = await this.imageCommunicator.uploadImageFile(file);

            eventTracking.logUserEvent('Maps', 'Copy imported floor plan');
            return uploadedImage.key;
        }
    }

    public async getImageUrlAsBase64(key: string) {
        const isLocalProject = this.currentProjectService.getIsLocalProject();
        if (isLocalProject) {
            return this.localImageService.getLocalImageUrlAsBase64(key);
        } else {
            return this.imageCommunicator.getImageUrlAsBase64(key);
        }
    }

    public async getLogoUrlAsBase64(key: string) {
        return this.getImageUrlAsBase64(key);
    }

    public async isEXIFRotated(key: string): Promise<boolean> {
        const isLocalProject = this.currentProjectService.getIsLocalProject();
        let fileBlob;
        try {
            if (isLocalProject) {
                fileBlob = await this.localImageService.getLocalImageAsBlob(key);
            } else {
                fileBlob = await this.imageCommunicator.getImageAsBlob(key);
            }
            return await readExifOrientation(fileBlob);
        } catch (error) {
            eventTracking.logError(
                `Failed to get EXIF rotation of image ${toErrorMessage(error)}`,
                'ImageService',
            );
            return false;
        }
    }
    public async getImageAsBase64(key: string, projectId: Id) {
        const isLocalProject = await this.projectService.getIsLocalProject(projectId);
        if (isLocalProject) {
            return this.localImageService.getLocalImageUrlAsBase64(key);
        } else {
            return this.imageCommunicator.getImageAsBase64(key);
        }
    }

    public async getPresignedUrl(key: string) {
        return this.imageCommunicator.getPresignedUrl(key);
    }

    public async getUserImageQuota() {
        return this.imageCommunicator.getUserImageQuota();
    }

    public async getOrphanImages(floorPlanIds: string[]): Promise<string[]> {
        return this.imageCommunicator.getOrphanImages(floorPlanIds);
    }

    private async addCloudImage(image: Blob) {
        const uploadedImage = await this.imageCommunicator.uploadImage(image);
        const imageUrl = await this.imageCommunicator.getImageUrlAsBase64(uploadedImage.key);
        const dimensions = await this.getImageDimensions(imageUrl);

        return {
            key: uploadedImage.key,
            dimensions,
        };
    }

    private async addLocalImage(image: Blob, options: IUploadImageOptions) {
        const key = await this.localImageService.addLocalImage(image);
        const url = await this.localImageService.getLocalImageUrlAsBase64(key);
        const dimensions = await this.getImageDimensions(url);

        eventTracking.logUserEvent(
            'Maps',
            'Add local floor plan',
            `File size: ${options.file.size}`,
        );

        return {
            key: key,
            dimensions,
        };
    }

    private getImageDimensions(imageUrl: string): Promise<IFloorPlanImage['dimensions']> {
        const image: HTMLImageElement = new Image();
        image.src = imageUrl;

        return new Promise((resolve, reject) => {
            image.onload = (event) => {
                if (this.hasImageDimensions(event.currentTarget)) {
                    return resolve({ width: image.width, height: image.height });
                }
                reject();
            };
            image.onerror = () => {
                reject(new Error(IMAGE_LOAD_ERROR));
            };
        });
    }

    private async getImageBlob(options: IUploadImageOptions) {
        // If we have a PDF we want convert it to a PNG blob before uploading
        if (options.file.type === 'application/pdf') {
            return convertPdfToPngBlob(options);
        }
        return options.file;
    }

    private getFileFromDataURL = (dataURL: string, name: string): File => {
        // replacement for fetch(dataURL) for IE11 compatibility
        const arr = dataURL.split(',');
        const type = (arr[0].match(/:(.*?);/) || [])[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        // workaround for missing File constructor in some browsers
        try {
            return new File([u8arr], name, { type });
        } catch (error) {
            const blob = new Blob([u8arr], { type });
            const lastModifiedDate = new Date();
            const lastModified = lastModifiedDate.getTime();
            // tslint:disable-next-line:prefer-object-spread
            const file = Object.assign(blob, lastModified, lastModifiedDate, name) as File;

            return file;
        }
    };

    private hasImageDimensions(target: any): target is IFloorPlanImage['dimensions'] {
        return target && target.width !== undefined && target.height !== undefined;
    }
}
