import * as React from 'react';
import { useSelector } from 'react-redux';
import { css, cx } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import { useService } from 'app/ioc';
import { Box, Columns, Icon, IconButton, Text } from 'app/components';
import { getNotificationBannerVisibility } from '../selectors';
import { NotificationBannerService } from '../services';
import { useFlagsmith } from 'app/hooks';

const notificationBannerStyle = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: ${ColorsEnum.pink};
    transition: max-height 0.6s ease;

    @media print {
        display: none;
    }
`;

// Two lines of text when needed
const showBannerStyle = css`
    max-height: 50px;
`;

const hideBannerStyle = css`
    max-height: 0px;
`;

const FLAG_POLLING_INTERVAL_MS = 60000;

// NOTE: These flags should be defined in Flagsmith. Only lower case letters and underscores are allowed in Flagsmith.
// More information about Flagsmith can be found here: https://confluence.se.axis.com/display/WEBTOOLS/Flagsmith
const FLAG_NAMES = [
    'notification_banner',
    'notification_banner_status',
    'notification_banner_mandatory',
    'notification_banner_datetime_utc',
];

export const NotificationBanner: React.FC = () => {
    const flags = useFlagsmith(FLAG_NAMES, FLAG_POLLING_INTERVAL_MS);
    const visibility = useSelector(getNotificationBannerVisibility);
    const notificationBannerService = useService(NotificationBannerService);
    const [bannerText, setBannerText] = React.useState<string | null>(null);

    React.useEffect(() => {
        const message = notificationBannerService.checkNotificationBannerFlags({
            feature: flags.notification_banner,
            status: flags.notification_banner_status,
            mandatory: flags.notification_banner_mandatory,
            dateTimeUTC: flags.notification_banner_datetime_utc,
        });
        setBannerText(message);
    }, [flags, notificationBannerService]);

    const hideBanner = () => {
        notificationBannerService.setNotificationBannerVisibility('hidden');
    };

    if (!bannerText || visibility === 'feature_disabled') {
        return null;
    }

    return (
        <div
            className={cx(
                notificationBannerStyle,
                visibility === 'visible' ? showBannerStyle : hideBannerStyle,
            )}
        >
            <Box width="100%" paddingX="base" paddingY="half">
                <Columns justify widths={[5, 90, 5]}>
                    <div />
                    <Box spacing="half" alignItems="center">
                        <Icon icon="info" color="red" />
                        <Text style="semibold" large color="red">
                            {bannerText}
                        </Text>
                    </Box>
                    <IconButton
                        disabled={flags['notification_banner_mandatory'].enabled}
                        color="grey9"
                        icon="close"
                        onClick={hideBanner}
                    />
                </Columns>
            </Box>
        </div>
    );
};

NotificationBanner.displayName = 'NotificationBanner';
