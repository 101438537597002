import * as React from 'react';
import { css } from '@emotion/css';
import { useWindowSize } from 'app/hooks';
import { Box, PrintFix } from 'app/components';
import { UserProjectsLandingMenu, UserProjectsRoutes } from './components';

const windowSizeBreakpoint = 1100;
const menuExpandedStyle = css`
    display: grid;
    grid-template-columns: 1fr minmax(0, ${windowSizeBreakpoint}px) 1fr;
    width: 100%;
    & > div {
        grid-column: 2 / 3;
    }
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0;
`;
const menuCollapsedStyle = css`
    ${menuExpandedStyle}
    @media screen and (max-width: ${windowSizeBreakpoint}px) {
        padding-left: 64px;
    }
`;

export const UserProjectsLandingView: React.FunctionComponent = () => {
    const location = window.location.pathname;
    const windowSize = useWindowSize();
    const [isProjectsMenuExpanded, setIsProjectsMenuExpanded] = React.useState(true);

    const closeOnSmallScreens = () => {
        if (window.innerWidth <= windowSizeBreakpoint) {
            setIsProjectsMenuExpanded(false);
        }
    };

    // Close menu when resizing to less than windowSizeBreakpoint
    React.useEffect(closeOnSmallScreens, [windowSize]);

    // Close menu if loaded on small screen
    // Close menu when navigating
    React.useEffect(closeOnSmallScreens, [location]);

    const onProjectsMenuChange = () => {
        setIsProjectsMenuExpanded(!isProjectsMenuExpanded);
    };

    return (
        <PrintFix>
            <Box width="100%" height="100%">
                <UserProjectsLandingMenu
                    isProjectsMenuExpanded={isProjectsMenuExpanded}
                    onProjectsMenuChange={onProjectsMenuChange}
                />
                <div className={isProjectsMenuExpanded ? menuExpandedStyle : menuCollapsedStyle}>
                    <UserProjectsRoutes />
                </div>
            </Box>
        </PrintFix>
    );
};
UserProjectsLandingView.displayName = 'UserProjectsLandingView';
