import {
    IAccessoryItemEntity,
    Id,
    IEnvironmentItemEntity,
    IItemRelationEntity,
    ISystemControllerItemEntity,
} from 'app/core/persistence';
import { ImportedSystemController } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class SystemControllerImporterService {
    public import(
        projectId: Id,
        items: ImportedSystemController[],
    ): (
        | ISystemControllerItemEntity
        | IAccessoryItemEntity
        | IEnvironmentItemEntity
        | IItemRelationEntity
    )[] {
        const systemControllers = items.map((item) => ({
            ...mapToItemEntity(projectId, item, item.piaId),
            properties: {
                systemController: {},
            },
        }));

        const accessoryEntities = getImportedAccessories(projectId, items);
        return [...systemControllers, ...accessoryEntities];
    }
}
