import { AppSettings } from 'app/AppSettings';
import { eventTracking } from 'app/core/tracking';
import { injectable } from 'inversify';

interface IProjectPricingResponse {
    url: string;
}

@injectable()
export class ProjectPricingApiCommunicator {
    constructor(private appSettings: AppSettings) {}

    /** Fetches project pricing URL for the provided bill of materials file */
    public async fetchProjectPricingUrl(bomFileBlob: any): Promise<string> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const response = await fetch(this.appSettings.projectPricingEndpoint, {
            method: 'POST',
            headers,
            credentials: 'include',
            cache: 'no-cache',
            body: bomFileBlob,
        });

        if (response.ok) {
            const data = (await response.json()) as IProjectPricingResponse;
            return data.url;
        }

        this.logHttpErrors(response);
        throw new Error('Response not ok');
    }

    /**
     * Log any http errors except 403 Forbidden since that is used
     * if the auth cookie is missing.
     */
    private logHttpErrors = (response: Response): void => {
        if (response.status === 403) {
            return;
        }
        eventTracking.logError(
            response.statusText,
            this.appSettings.projectPricingEndpoint,
            response.status,
        );
    };
}
