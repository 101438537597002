import { injectable } from 'inversify';
import { t } from 'app/translate';
import type { IAction } from 'app/store';
import { AppStore } from 'app/store';
import { ModalService } from 'app/modal';
import type {
    ICurrentProjectRepository,
    IPersistenceConfigurationService,
} from 'app/core/persistence';
import {
    getItemLocalStorageJSON,
    MemoryPersistenceConfigurationService,
    ProjectDbOrigin,
    ProjectModelService,
    ReplicationService,
    ProjectRepository,
    PersistenceConfigurationServiceFactory,
} from 'app/core/persistence';

import { CommonActions } from '../actions';
import { UnreachableCaseError } from 'axis-webtools-util';
import {
    EVENT_PROGRESS,
    EVENT_UPDATE_AVAILABLE,
    ProjectDbOriginAsdLocalUserData,
    ProjectDbOriginAsdUserData,
} from 'app/core/persistence/userDataPersistence';
import type * as couchDBCompaction from '../couchDBCompaction';
import { CouchDBCompactionService } from '../couchDBCompaction';

@injectable()
export class InitializeDataStorageService {
    private persistenceConfigurationService: IPersistenceConfigurationService | undefined;
    constructor(
        private memoryPersistenceConfigurationService: MemoryPersistenceConfigurationService,
        private persistenceConfigurationServiceFactory: PersistenceConfigurationServiceFactory,
        private projectModelService: ProjectModelService,
        private projectRepository: ProjectRepository,
        private replicationService: ReplicationService,
        private modalService: ModalService,
        private couchDBCompactionService: CouchDBCompactionService,
        private appStore: AppStore,
    ) {}

    public async initializeStorage(
        isStandalone: boolean,
        currentDbOrigin: ProjectDbOrigin,
    ): Promise<void> {
        if (isStandalone) {
            const project = await this.memoryPersistenceConfigurationService.initialize();

            this.appStore.Store.dispatch<IAction<Promise<ICurrentProjectRepository>>>({
                type: CommonActions.GetCurrentProject,
                payload: this.projectModelService.getProjectModelsAndSync(project._id),
            });
        } else {
            this.persistenceConfigurationService =
                await this.persistenceConfigurationServiceFactory.createPersistenceConfigurationService(
                    currentDbOrigin,
                );
            this.replicationService.on(EVENT_UPDATE_AVAILABLE, this.updateApp);
            let dbOrigin = this.getValidDbOrigin();
            // Block database access if ongoing compaction (multi-user case).
            // Block creation of new database when database is in 'SeriousError' after failed compaction.
            const status = await this.couchDBCompactionService.getCompactStatus();
            // Update store to make Optimize button appear if status is 'Available'.
            this.appStore.Store.dispatch<
                IAction<Promise<couchDBCompaction.CompactionStatus | null>>
            >({
                type: CommonActions.UpdateCompactStatus,
                payload: Promise.resolve(status),
            });
            if (status !== 'Initiated' && status !== 'SeriousError') {
                await this.persistenceConfigurationService.initialize();
            } else {
                dbOrigin = ProjectDbOriginAsdLocalUserData;
            }

            // Set proper dataSource based on state of userDataDB and last project view user selected.
            await this.switchDataRepository(dbOrigin);
        }
    }

    public switchDataRepository(
        dbOrigin: ProjectDbOrigin,
    ): Promise<void | PouchDB.Database> | undefined {
        // Early return if dbOrigin is not one of the known values in ProjectDbOrigin
        if (![ProjectDbOriginAsdLocalUserData, ProjectDbOriginAsdUserData].includes(dbOrigin)) {
            throw new UnreachableCaseError(
                dbOrigin as never,
                `Unknown ProjectDbOrigin ${dbOrigin}`,
            );
        }

        const currentDb = this.projectRepository.getDbName();
        // If we are already using the correct database, do nothing
        if (currentDb === dbOrigin) return;

        // Check if we need to switch to a different configuration service
        const dbName = this.persistenceConfigurationService?.getDbOrigin();
        if (dbName !== dbOrigin) {
            this.persistenceConfigurationService =
                this.persistenceConfigurationServiceFactory.createPersistenceConfigurationService(
                    dbOrigin,
                );
        }
        return this.persistenceConfigurationService?.initDatabase();
    }

    public async syncAll(): Promise<void> {
        return this.persistenceConfigurationService?.syncAll();
    }

    public onProgress(fn: (progress: number) => any) {
        this.replicationService.on<number>(EVENT_PROGRESS, fn);
    }

    public clearLocalDatabase() {
        const persistenceConfigurationService =
            this.persistenceConfigurationServiceFactory.createPersistenceConfigurationService(
                ProjectDbOriginAsdUserData,
            );
        return persistenceConfigurationService.clearLocalDatabase();
    }

    private updateApp = async () => {
        await this.modalService.createConfirmDialog(t.updateAvailableGROUP)();
        window.location.reload();
    };

    private getValidDbOrigin = (): ProjectDbOrigin => {
        const lastProjectView = getItemLocalStorageJSON(
            'LatestProjectView',
            ProjectDbOriginAsdUserData,
        );
        return [ProjectDbOriginAsdLocalUserData, ProjectDbOriginAsdUserData].includes(
            lastProjectView,
        )
            ? lastProjectView
            : ProjectDbOriginAsdUserData;
    };
}
