import * as React from 'react';
import type { LeafletMap } from '../map';
import { ColorsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { useService } from 'app/ioc';
import { ItemDropService, MapsActionService } from '../../services';
import { useDrop, useMap } from '../../hooks';
import type { IFloorPlanEntity, ILatLng } from 'app/core/persistence';
import { FloorPlanService } from 'app/core/persistence';
import { toaster } from 'app/toaster';
import type { IMapsDragDropData } from '../../models';
import { getDropPosition } from '../../utils/getDropPosition';
import * as leaflet from 'leaflet';
import { t } from 'app/translate';
import { FloorPlanMap } from '../map/FloorPlanMap';
import { GeoMap } from '../map/GeoMap';
import { DEFAULT_GOOGLE_MAP_ID } from '../../constants';
import { trigonometry } from 'axis-webtools-util';

const pointer_cursor_1x = require('src/assets/images/cursors/hand-pointer.png');
const pointer_cursor_2x = require('src/assets/images/cursors/hand-pointer_2x.png');
const pointer_cursor_svg = require('src/assets/images/cursors/hand-pointer.svg');
const add_cursor_1x = require('src/assets/images/cursors/hand-add.png');
const add_cursor_2x = require('src/assets/images/cursors/hand-add_2x.png');
const add_cursor_svg = require('src/assets/images/cursors/hand-add.svg');
const duplicate_cursor_1x = require('src/assets/images/cursors/hand-duplicate.png');
const duplicate_cursor_2x = require('src/assets/images/cursors/hand-duplicate_2x.png');
const duplicate_cursor_svg = require('src/assets/images/cursors/hand-duplicate.svg');

const googleMapContainerStyle: React.CSSProperties = {
    visibility: 'hidden',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '500px',
    width: '1300px',
    zIndex: -1,
};

const getMapStyle = (isFloorPlan: boolean): React.CSSProperties => ({
    position: 'relative',
    backgroundColor: `${isFloorPlan ? ColorsEnum.white : ColorsEnum.grey6}`,
    height: '100%',
    flexGrow: 1,
});

const mapContainerStyle = css`
    position: relative;
    display: flex;
    height: 100%;
    flex-grow: 1;
    z-index: 1;
    overflow: hidden;

    .leaflet-interactive {
        cursor: pointer;
        cursor:
            url(${pointer_cursor_1x}) 12 8,
            pointer; /* Legacy browsers */
        cursor:
            url(${pointer_cursor_svg}) 12 8,
            pointer; /* FF, Chrome */
        cursor:
            -webkit-image-set(url(${pointer_cursor_1x}) 1x, url(${pointer_cursor_2x}) 2x) 12 8,
            pointer; /* Modern hi-res */
    }

    [data-alt-pressed] .leaflet-marker-icon.leaflet-interactive {
        cursor: copy;
        cursor:
            url(${add_cursor_1x}) 12 8,
            copy; /* Legacy browsers */
        cursor:
            url(${add_cursor_svg}) 12 8,
            copy; /* FF, Chrome */
        cursor:
            -webkit-image-set(url(${add_cursor_1x}) 1x, url(${add_cursor_2x}) 2x) 12 8,
            copy; /* Modern hi-res */
    }

    [data-control-pressed][data-alt-pressed] .leaflet-marker-icon.leaflet-interactive {
        cursor: copy;
        cursor:
            url(${duplicate_cursor_1x}) 12 8,
            copy; /* Legacy browsers */
        cursor:
            url(${duplicate_cursor_svg}) 12 8,
            copy; /* FF, Chrome */
        cursor:
            -webkit-image-set(url(${duplicate_cursor_1x}) 1x, url(${duplicate_cursor_2x}) 2x) 12 8,
            copy; /* Modern hi-res */
    }
`;

export interface IMapContext {
    leafletMap: LeafletMap;
    floorPlan: IFloorPlanEntity;
}

export const MapContext = React.createContext<IMapContext | null>(null);

interface IMapProviderProps {
    floorPlan: IFloorPlanEntity | undefined;
    readOnly?: boolean;
    fitToMarkers?: boolean;
    googleMapDivId?: string;
    children: React.ReactNode;
}

/** Renders a map and provides the Leaflet map and selected floor plan as context to its' children.  */
export const MapProvider: React.FC<IMapProviderProps> = ({
    readOnly,
    fitToMarkers,
    floorPlan,
    googleMapDivId = DEFAULT_GOOGLE_MAP_ID,
    children,
}) => {
    const mapsActions = useService(MapsActionService);
    const itemDropService = useService(ItemDropService);
    const mapDivRef = React.useRef<HTMLDivElement>(null);
    const mapStyle = getMapStyle(!!floorPlan?.image);
    const onZoomLevelChanged = React.useCallback(
        (zoomLevel: number) => {
            !readOnly && mapsActions.setZoomLevel(zoomLevel);
        },
        [readOnly, mapsActions],
    );
    const onLocationChanged = React.useCallback(
        (location: ILatLng) => {
            !readOnly && mapsActions.setMapLocation(location);
        },
        [readOnly, mapsActions],
    );
    const leafletMap = useMap({
        mapDivRef,
        onZoomLevelChanged,
        onLocationChanged,
        googleMapDivId,
        readOnly,
    });

    const [mapReady, setReady] = React.useState(false);
    React.useEffect(() => {
        leafletMap && leafletMap.map.whenReady(() => setReady(true));
    }, [leafletMap]);

    // Add dropped cameras to the map
    useDrop({
        ref: mapDivRef,
        onDrop: async (e) => {
            if (!e.dataTransfer || !mapDivRef.current || !leafletMap) {
                return;
            }

            if (
                floorPlan &&
                FloorPlanService.isFloorPlanMapType(floorPlan) &&
                !floorPlan.image.bounds
            ) {
                toaster.warning(t.scaleWarningTitle, t.devicesScaleWarningMessage);
                return;
            }
            const data = JSON.parse(e.dataTransfer.getData('text')) as IMapsDragDropData;
            // Get map offset
            const dropPosition = getDropPosition(e, mapDivRef.current);
            const dropLocation = leafletMap.map.containerPointToLatLng(leaflet.point(dropPosition));
            if (floorPlan) {
                const rotationAngleDegrees = trigonometry.toDegrees(
                    floorPlan.image?.geoLocation?.angle ?? 0,
                );
                itemDropService.handleItemDropped(
                    data,
                    dropLocation,
                    floorPlan._id,
                    rotationAngleDegrees,
                );
            }
        },
    });

    const isFloorPlanMap =
        floorPlan && leafletMap && FloorPlanService.isFloorPlanMapType(floorPlan);
    const isGeoMap = floorPlan && leafletMap && FloorPlanService.isGeoMapType(floorPlan);

    const [contextValue, setContextValue] = React.useState<IMapContext>();
    React.useEffect(() => {
        if (!leafletMap || !floorPlan) {
            return;
        }
        setContextValue({ leafletMap, floorPlan });
    }, [leafletMap, floorPlan]);

    return (
        <div data-test-id="map_container" className={mapContainerStyle}>
            <div style={googleMapContainerStyle} id={googleMapDivId} />
            <div style={mapStyle} data-test-id="map_root" ref={mapDivRef} />
            {isFloorPlanMap && <FloorPlanMap leafletMap={leafletMap} floorPlan={floorPlan} />}
            {isGeoMap && (
                <GeoMap
                    readOnly={readOnly}
                    fitToMarkers={fitToMarkers}
                    leafletMap={leafletMap}
                    geoMap={floorPlan}
                />
            )}
            {contextValue && mapReady && (
                <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
            )}
        </div>
    );
};
