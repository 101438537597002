import { Id } from 'app/core/persistence';
import { IProduct, VendorEnum } from 'app/modules/common';
import { injectable } from 'inversify';

@injectable()
export class BillOfMaterialsService {
    public getBillOfMaterials(
        projectId: Id,
        customerInfo: string,
        countryCode: string | undefined,
        products: IProduct[],
    ): Blob {
        const fileContents = this.createFileContent(projectId, customerInfo, countryCode, products);
        const fileBlob = new Blob([fileContents], {
            type: 'application/json',
        });

        return fileBlob;
    }

    private createFileContent(
        projectId: Id,
        customerInfo: string,
        countryCode: string | undefined,
        products: IProduct[],
    ): string {
        const metadataText = `"metadata": { "source": "AXIS Site Designer", "id": "${projectId}", "customer": ${JSON.stringify(customerInfo) ?? ''}, "customerCountry":"${countryCode ?? 'unknown'}"}`;
        const bomProducts = products
            .filter(
                (product) =>
                    [VendorEnum.Axis, VendorEnum.twoN].includes(product.vendor) &&
                    product.partNumber,
            )
            .map(
                (product) =>
                    `{"quantity": ${product.quantity}, "partNumber": "${product.partNumber}"}`,
            )
            .join(', ');
        const productsText = `"products": [${bomProducts}]`;
        return `{${[metadataText, productsText].join(', ')}}`;
    }
}
