import { injectable } from 'inversify';
import type {
    ProjectZipType,
    IRecordingSettingsModel,
    IScenarioSettingsModel,
    IZipstreamSettingsModel,
    RecordingType,
    BandwidthVersion,
} from 'app/core/persistence';
import { VideoEncoding } from 'app/core/persistence';
import type { IPiaBandwidthCalculationProperties, ProductBandwidthProperties } from 'app/core/pia';
import { convert, UnreachableCaseError } from 'axis-webtools-util';
import type { IScenarioModel } from '../../../profile';
import { ProfileSupportService } from '../../../profile/services';
import type { Frequency } from '../../../models';
import type { IBandwidthCalculationResult, IBandwidthParams, IScalingCostInfo } from '../../models';
import { ScalingCostType } from '../../models';
import { getDynamicFpsMode, getGopMode, getZipStrengthValue } from '../../../../common/utils';
import { BandwidthEstimateCalculatorService } from './BandwidthEstimateCalculator.service';

@injectable()
export class BandwidthCalculatorService {
    private readonly expectedBadWeather = 0.3;

    /**
     *
     */
    constructor(private bandwidthCalculator: BandwidthEstimateCalculatorService) {}

    public getBandwidthEstimate(
        projectZipSetting: ProjectZipType,
        scenario: IScenarioModel,
        scenarioSettings: IScenarioSettingsModel,
        recordingSettings: IRecordingSettingsModel,
        zipstreamSettings: IZipstreamSettingsModel,
        audioEnabled: boolean,
        productProperties: ProductBandwidthProperties,
        frequency: Frequency,
        recordingType: RecordingType,
        projectBandwidthVersion: BandwidthVersion,
        isGenericCamera?: boolean,
    ): IBandwidthCalculationResult {
        const params = this.getParams(
            projectZipSetting,
            productProperties,
            scenario,
            scenarioSettings,
            recordingSettings,
            zipstreamSettings,
            frequency,
            recordingType,
            projectBandwidthVersion,
            isGenericCamera,
        );

        const isAudioEnabled = ProfileSupportService.isAudioSupportedAndEnabled(
            productProperties,
            audioEnabled,
        );

        const estimate = this.bandwidthCalculator.getBandwidthEstimate(
            params,
            isAudioEnabled,
            this.expectedBadWeather,
        );

        // We expect all values in bits but the algorithm returns
        // them as kilo bits.
        return {
            goodLight: convert.fromKilo(estimate.goodLight),
            goodLightIdle: convert.fromKilo(estimate.goodLightIdle),
            lowLight: convert.fromKilo(estimate.lowLight),
            lowLightIdle: convert.fromKilo(estimate.lowLightIdle),
        };
    }

    private getParams(
        projectZipSetting: ProjectZipType,
        productProperties: IPiaBandwidthCalculationProperties,
        scenario: IScenarioModel,
        scenarioSettings: IScenarioSettingsModel,
        recordingSettings: IRecordingSettingsModel,
        zipstreamSettings: IZipstreamSettingsModel,
        frequency: Frequency,
        recordingType: RecordingType,
        projectBandwidthVersion: BandwidthVersion,
        isGenericCamera?: boolean,
    ): IBandwidthParams {
        const frameRate = ProfileSupportService.getFrameRate(
            productProperties,
            recordingSettings,
            frequency,
            isGenericCamera,
        );
        const maxResolution = ProfileSupportService.getMaxResolution(productProperties);

        const scalingCostInfo = this.getScalingCost(recordingSettings, productProperties);

        // parameters dependent of projectZipSetting:
        const zipStrength = getZipStrengthValue(zipstreamSettings, projectZipSetting);
        const gopMode = getGopMode(zipstreamSettings, projectZipSetting);
        const fpsMode = getDynamicFpsMode(zipstreamSettings, projectZipSetting);

        return {
            scenario: {
                indoor: scenario.indoor,
                motionRef: scenario.motionRef,
                details: scenarioSettings.sceneDetails,
            },
            wdr: productProperties.WideDynamicRange ? true : false,
            cPlatform: productProperties.cPlatform,
            platform: productProperties.platform,
            compQPmap: this.getCompQPMap(recordingSettings, productProperties),
            scalingCostType: scalingCostInfo.scalingCostType,
            scalingCost: scalingCostInfo.scalingCost,
            compression: recordingSettings.compression,
            sizeOfAverageObject: scenario.averageObjectSize,
            zipStrength: ProfileSupportService.getZipstreamStrength(productProperties, zipStrength),
            zipProfile: zipstreamSettings.zipProfile,
            frameRate,
            maxResolution: maxResolution.getPixels(),
            resolution: ProfileSupportService.getCameraResolution(
                maxResolution,
                recordingSettings.resolution,
            ).getPixels(),
            nightLight: scenarioSettings.nightLighting,
            goodLightMotion: recordingSettings.dayMotion,
            lowLightMotion: recordingSettings.nightMotion,
            gopMode: gopMode,
            gop: zipstreamSettings.gopDefault,
            fpsMode: ProfileSupportService.getFpsMode(
                productProperties,
                zipStrength,
                fpsMode,
                recordingSettings.videoEncoding,
                recordingType,
            ),
            fpsMin: zipstreamSettings.minDynamicFps,
            goodLightMotionFrac: recordingSettings.dayTriggerTime / 100,
            lowLightMotionFrac: recordingSettings.nightTriggerTime / 100,
            nature: !scenario.indoor,
            numSamples: 100,
            version: projectBandwidthVersion,
        };
    }

    private getScalingCost(
        recordingSettings: IRecordingSettingsModel,
        productProperties: IPiaBandwidthCalculationProperties,
    ): IScalingCostInfo {
        const videoEncoding = ProfileSupportService.getVideoEncoding(
            productProperties,
            recordingSettings.videoEncoding,
        );

        switch (videoEncoding) {
            case VideoEncoding.h264:
                return {
                    scalingCostType: ScalingCostType.H264,
                    scalingCost: productProperties.scalingCost,
                };
            case VideoEncoding.h265:
                return {
                    scalingCostType: ScalingCostType.H265,
                    scalingCost: productProperties.scalingCostH265,
                };

            case VideoEncoding.mjpeg:
                return {
                    scalingCostType: ScalingCostType.MJPEG,
                    scalingCost: productProperties.scalingCost,
                };
            case VideoEncoding.av1:
                return {
                    scalingCostType: ScalingCostType.AV1,
                    scalingCost: productProperties.scalingCostAV1,
                };
            default:
                throw new UnreachableCaseError(videoEncoding);
        }
    }

    private getCompQPMap(
        recordingSettings: IRecordingSettingsModel,
        productProperties: IPiaBandwidthCalculationProperties,
    ): Record<string, number> | undefined {
        const videoEncoding = ProfileSupportService.getVideoEncoding(
            productProperties,
            recordingSettings.videoEncoding,
        );

        switch (videoEncoding) {
            case VideoEncoding.av1:
                return productProperties.compQPmapAV1;
            case VideoEncoding.h264:
            case VideoEncoding.h265:
            case VideoEncoding.mjpeg:
                return productProperties.compQPmap;
            default:
                throw new UnreachableCaseError(videoEncoding);
        }
    }
}
