import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    DropDown,
    DropDownMenuButton,
    Text,
    Icon,
    Border,
    IconButton,
    Clickable,
    Spacer,
} from 'app/components';
import { useService } from 'app/ioc';
import type {
    IContactInfo,
    IDetailedOrganization,
    IOrganizationOrResourceGroup,
} from 'app/core/persistence';
import { OrganizationsService } from 'app/core/persistence';
import {
    getFlattenedResourceTree,
    getOrganizations,
    getOrganization,
    getResourceGroup,
    getSelectedOrganization,
} from '../selectors';
import { OrganizationsActionService } from '../services';
import { useMount } from 'app/hooks';
import { t } from 'app/translate';
import { TokenService } from 'app/modules/common';
import { MemberInfoDialog } from 'app/modules/application';

export const OrganizationDropDown: React.FC = () => {
    const organizationsActionService = useService(OrganizationsActionService);
    const tokenService = useService(TokenService);
    const organizationService = useService(OrganizationsService);
    const organizations = useSelector(getOrganizations);

    const organization = useSelector(getOrganization);
    const resourceGroup = useSelector(getResourceGroup);

    const selectedOrganization = useSelector(getSelectedOrganization);
    const flattenedResourceGroups = useSelector(getFlattenedResourceTree);
    const [showOrganizations, setShowOrganizations] = React.useState(true);
    const [nbrMembers, setNbrMembers] = React.useState(0);
    const [memberInfo, setMemberInfo] = React.useState<IContactInfo[] | undefined>(undefined);

    useMount(() => {
        // Reset selected organization upon mount
        organizationsActionService.setSelectedOrganization(undefined);
        // Switch database upon mount
        // if (sharedProjectTreeInfo) {
        //     organizationsActionService.setSharedProjectInfo(sharedProjectTreeInfo.resourceGroup);
        // }
    });

    React.useEffect(() => {
        if (resourceGroup) {
            const members = resourceGroup?.adminPrincipalArns;
            setNbrMembers(members?.length ?? 0);
        }
    }, [nbrMembers, resourceGroup?.adminPrincipalArns, resourceGroup]);

    const toggleShowOrganizations = (value: boolean) => {
        setShowOrganizations(value);
    };

    const changeOrganization = (org: IDetailedOrganization) => {
        organizationsActionService.setSelectedOrganization(org);
        toggleShowOrganizations(false);
    };

    const setResourceGroup = (resource: IOrganizationOrResourceGroup) => {
        organizationsActionService.setSharedProjectInfo(resource);
    };

    const renderResourceGroups = () => {
        return (
            <>
                <Box alignItems="center" justifyContent="between" paddingRight="half">
                    <DropDownMenuButton
                        icon="company"
                        onClick={() => toggleShowOrganizations(true)}
                    >
                        <Box alignItems="center" spacing="cell">
                            {
                                <Text whiteSpace="nowrap">
                                    {selectedOrganization?.name ?? organization?.name}
                                </Text>
                            }
                            <IconButton icon="repeat" opaque color="blue" />
                        </Box>
                    </DropDownMenuButton>
                </Box>
                {flattenedResourceGroups?.map((resource) => {
                    return (
                        <Border key={resource.arn} bottomWidth={1} color="grey3">
                            <Clickable onClick={() => setResourceGroup(resource)}>
                                <Box spacing="quart" alignItems="center" padding="half">
                                    <Icon
                                        icon="shared_folder_outline"
                                        color="grey7"
                                        opaque={resourceGroup?.arn === resource.arn}
                                    />
                                    <Text
                                        color="grey9"
                                        style={
                                            resourceGroup?.arn === resource.arn
                                                ? 'semibold'
                                                : 'body'
                                        }
                                    >
                                        {resource.displayName}
                                    </Text>
                                </Box>
                            </Clickable>
                        </Border>
                    );
                })}
            </>
        );
    };

    const renderOrganizations = () => {
        return organizations.map((org) => (
            <DropDownMenuButton
                key={org.arn}
                icon="company"
                onClick={() => changeOrganization(org)}
                label={org.name}
            />
        ));
    };

    const onShowMembers = async () => {
        const accessToken = await tokenService.getAccessToken();

        // fetch resource groups access for selected resource group
        if (!accessToken || !resourceGroup?.adminPrincipalArns) return;

        // get principals for the admin users
        const principals = await organizationService.fetchPrincipals(
            accessToken,
            resourceGroup?.adminPrincipalArns,
        );
        const members = principals?.map((principal) => principal.contactInfo);

        if (members) {
            setMemberInfo(members);
        }
    };

    const onCloseMembers = () => {
        setMemberInfo(undefined);
    };

    return (
        <Box direction="column">
            <Text style="semibold" color="grey7">
                {organization?.name}
            </Text>
            <Box direction="column">
                <DropDown
                    openInPortal
                    trigger={
                        <Border color="yellow" bottomWidth={1}>
                            <Box spacing="quart" alignItems="center">
                                <Icon icon="shared_folder_outline" color="grey7" />
                                <Text color="grey9" style="title">
                                    {resourceGroup?.name ?? t.selectOrganization}
                                </Text>
                                <Icon icon="keyboard_arrow_down" color="grey7" />
                            </Box>
                        </Border>
                    }
                    contents={showOrganizations ? renderOrganizations() : renderResourceGroups()}
                />
                <Spacer spacing="halfQuart"></Spacer>
                <Clickable onClick={onShowMembers}>
                    <Text color="grey5" small>
                        {t.members(nbrMembers)}
                    </Text>
                </Clickable>
            </Box>
            {memberInfo && (
                <MemberInfoDialog
                    members={memberInfo}
                    title={resourceGroup?.name ?? ''}
                    onClose={onCloseMembers}
                />
            )}
        </Box>
    );
};

OrganizationDropDown.displayName = 'OrganizationDropDown';
