import type { IPiaItem, PiaId, IPiaRelationReference } from 'app/core/pia';
import { PiaRelationTypes } from 'app/core/pia';
import { createSelector } from 'reselect';
import type { IProduct } from '../../../IProduct';
import type { ProductWithAccessory } from '../types';
import { getProductsWithAllowlistProperty } from '../../getProductsWithAllowlistProperty';
import { getPiaItemsRecord } from '../../../../piaDevices/selectors/getPiaDevices';
/**
 * A function that gets the relations that are of type Includes and are of category acap.
 * @param piaItems - A record of piaItems
 * @param piaId - A piaId
 * @returns A list of relations that are of type Includes and are of category acap
 */
const getIncludesAcapsRelations = (
    piaItems: Record<number, IPiaItem>,
    piaId: PiaId | undefined,
): IPiaRelationReference[] => {
    // If piaId is undefined, return an empty array
    if (!piaId) return [];

    // Get the piaItem with the given piaId
    const piaItem = piaItems[piaId];

    // Check if the piaItem is undefined, if so, set an empty array
    const relations = piaItem?.relations ?? [];

    // Filter out relations that are not of type Includes and are not of category acap
    return relations.filter(
        (relation) =>
            relation.relationType === PiaRelationTypes.Includes &&
            piaItems[relation.id].category === 'acap',
    );
};

/**
 * A function that returns a record of the acaps that are included in the products
 * with key as the piaId of the product and value as a list of acaps that are included in the product.
 * @param piaItems - A record of piaItems
 * @param products - A list of products
 * @returns A record of the acaps that are included in the products
 * that are in the allowlist
 */
export const filterProductsWithIncludedAcaps = (
    products: IProduct[],
    piaItems: Record<number, IPiaItem>,
): ProductWithAccessory => {
    const result: ProductWithAccessory = {};
    // If products is empty, return an empty object
    if (!products.length) return result;

    // Loop through the filtered products and get the relations that are of type Includes and are of category acap.
    products.map((product) => {
        // If piaId is undefined, goto the next iteration
        if (!product.piaId) return;

        // Get the relations that are of type Includes and are of category acap
        const relations = getIncludesAcapsRelations(piaItems, product.piaId);

        // If the product is not of category software, assign the relations to the result object
        if (product.category !== 'software' && relations.length > 0) {
            // Assign the relations to the result object with the key as the piaId of the product
            result[product.piaId] = relations.map((relation) => piaItems[relation.id]);
        }
        // Goto the next iteration
        return;
    });
    return result;
};

/**
 * Get a record of the acaps that are included in the products
 * that are in the allowlist.
 *
 * @param piaItems - A record of piaItems
 * @param products - A list of products
 * @returns A record with product piaId as key and a list of acaps that are included in the product as value.
 * @example
 * includedAcapsByProduct  {
 *    "75436": [
 *       {
 *          "id": 56122,
 *          "name": "AXIS Live Privacy Shield",
 *          "category": "acap",
 *          "relations": [],
 *          ...
 *       },
 * ]}
 */
export const getAllProductsWithIncludedAcaps = createSelector(
    [getPiaItemsRecord, getProductsWithAllowlistProperty],
    (piaItems, products): ProductWithAccessory => {
        // Get the acaps that are included in the products
        return filterProductsWithIncludedAcaps(products, piaItems);
    },
);
