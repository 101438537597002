import { optional, oneOf, type Schema } from 'app/modules/common';

const LAT_LNG_SCHEMA: Schema = {
    lat: 'number',
    lng: 'number',
};

// TODO handle geo/floor plan maps
const MAP_IMAGE_SCHEMA: Schema = {
    key: 'string',
    image: optional('string'),
    imageUrl: optional('string'),
    width: optional('number'),
    height: optional('number'),
    imageBounds: optional({
        topLeft: LAT_LNG_SCHEMA,
        bottomRight: LAT_LNG_SCHEMA,
    }),
    geoLocation: optional({
        position: LAT_LNG_SCHEMA,
        width: 'number',
        height: 'number',
        angle: 'number',
    }),
};

const IP_IMAGE_SENSOR_SCHEMA: Schema = {
    index: 'number',
    targetDistance: 'number',
    targetHeight: 'number',
    rotation: 'number',
    horizontalFov: optional('number'),
    verticalFov: optional('number'),
    corridorFormat: 'boolean',
    devicePiaId: optional('number'),
};

const IP_RADAR_SCHEMA: Schema = {
    horizontalFieldOfDetection: optional('number'),
    rotation: 'number',
    targetDistance: 'number',
};

const IP_SPEAKER_SCHEMA: Schema = {
    horizontalSpeakerCoverage: optional('number'),
    rotation: 'number',
    targetDistance: 'number',
};

const INSTALLATION_POINT_SCHEMA: Schema = {
    id: optional('string'),
    linkedId: optional('string'),
    revision: optional('string'),
    itemId: 'string',
    color: 'string',
    location: LAT_LNG_SCHEMA,
    height: 'number',
    imageSensors: optional([IP_IMAGE_SENSOR_SCHEMA]),
    radars: optional([IP_RADAR_SCHEMA]),
    speakers: optional([IP_SPEAKER_SCHEMA]),
};

const BLOCKER_SCHEMA: Schema = {
    color: optional('string'),
    polyline: [LAT_LNG_SCHEMA],
};

export const MAP_SCHEMA: Schema = {
    id: optional('string'),
    revision: optional('string'),
    name: 'string',
    type: oneOf(['FloorPlan', 'StreetMap']),
    images: [MAP_IMAGE_SCHEMA],
    installationPoints: [INSTALLATION_POINT_SCHEMA],
    blockers: optional([BLOCKER_SCHEMA]),
    geoLocation: optional(LAT_LNG_SCHEMA),
};
