import { Modal, Box, Stack, Heading, Text, IconText } from 'app/components';
import type { IProduct } from 'app/modules/common';
import { t } from 'app/translate';
import DOMPurify from 'dompurify';
import i18next from 'i18next';
import React from 'react';
import { BillOfMaterialsExportToBOMFile } from './BillOfMaterialsExportToBOMFile.component';

interface IProjectPricingModalProps {
    products: IProduct[];
    onClose(): void;
    trackBOMExport(): void;
}

export const ProjectPricingModal: React.FC<IProjectPricingModalProps> = ({
    products,
    onClose,
    trackBOMExport,
}) => {
    const languageCode = i18next.language;

    /** Attempts to retrieve html file for currently chosen language. If not found for that language it defaults to English. When adding new languages remember to add a corresponding html file as well. */
    const getHtmlContent = () => {
        try {
            return require(`assets/projectPricing/projectPricing.${languageCode}.tpl.html`);
        } catch (error) {
            return require(`assets/projectPricing/projectPricing.en.tpl.html`);
        }
    };

    const getProjectPricingHtml =
        (): React.DOMAttributes<HTMLDivElement>['dangerouslySetInnerHTML'] => {
            const projectPricingHtml = getHtmlContent();
            return {
                __html: DOMPurify.sanitize(projectPricingHtml, { ADD_ATTR: ['target', 'rel'] }),
            };
        };

    return (
        <Modal onClose={onClose} title="">
            <Box width="450px" paddingX="panel" paddingBottom="panel" paddingTop="halfCell">
                <Stack spacing="base" vertical>
                    <Heading style="subheader">{t.projectPricingGROUP.mainHeadline}</Heading>
                    <Text>{t.projectPricingGROUP.intro}</Text>
                    <Box paddingTop="base">
                        <Stack justifyContent="start">
                            <Heading style="subheader">1.</Heading>
                            <Stack vertical alignItems="start">
                                <Heading style="subheader">
                                    {t.projectPricingGROUP.downloadBOMFile}
                                </Heading>
                                <BillOfMaterialsExportToBOMFile
                                    trackBOMExport={trackBOMExport}
                                    products={products}
                                >
                                    <IconText
                                        iconProps={{ opaque: true }}
                                        textProps={{ semiBold: true }}
                                        icon="file_download"
                                        color="blue"
                                    >
                                        {t.projectPricingGROUP.clickToDownload}
                                    </IconText>
                                </BillOfMaterialsExportToBOMFile>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box paddingTop="base">
                        <Stack justifyContent="start">
                            <Heading style="subheader">2.</Heading>
                            <Stack vertical>
                                <Heading style="subheader">
                                    {t.projectPricingGROUP.sendFile}
                                </Heading>
                                <Text>{t.projectPricingGROUP.includeBOMFileInRequest}</Text>
                                <Heading style="subheader">{t.projectPricingGROUP.or}</Heading>
                                <Heading style="subheader">
                                    {t.projectPricingGROUP.requestOnPartnerPages}
                                </Heading>
                                <Text onlyStyle>
                                    <div dangerouslySetInnerHTML={getProjectPricingHtml()} />
                                </Text>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box paddingTop="base">
                        <Stack justifyContent="start">
                            <Heading style="subheader">3.</Heading>
                            <Stack vertical>
                                <Heading style="subheader">
                                    {t.projectPricingGROUP.waitForConfirmation}
                                </Heading>
                                <Text>{t.projectPricingGROUP.awaitConfirmedPricing}</Text>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Modal>
    );
};

ProjectPricingModal.displayName = 'ProjectPricingModal';
