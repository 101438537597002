import { injectable } from 'inversify';
import {
    IPersistence,
    IScheduleEntity,
    Id,
    ITimeSerieEntity,
    ScheduleRepository,
    TimeSerieRepository,
} from '../userDataPersistence';
import type {
    IBaseScheduleModel,
    IScheduleModel,
    ITimeSerieModel,
    IBaseTimeSerieModel,
} from '../models';
import { Time24, ScheduleDays } from '../models';
import { ProjectService } from './Project.service';
import { getParentId } from '../utils';
import { t } from 'app/translate';

/**
 * The ScheduleModel service is used for providing schedule models including timeseries,
 * to other services using schedules to perform various calculations.
 * The schedules ui has been rewritten when moved to dashboard and use the ScheduleService,
 * to execute various ui operations as adding, removing schedules and time series.
 *
 * NOTE: The concept of using models instead of entities is deprecated, but a schedule is pretty much useless without its timeseries,
 * and the timeserie property is not part of the entity.
 * This is not a normal model-to-entity mapping, but rather a mapping of two entities into a model widely used in the application so we decided to leave it as is.
 */
@injectable()
export class ScheduleModelService {
    constructor(
        private scheduleRepository: ScheduleRepository,
        private timeSerieRepository: TimeSerieRepository,
        private projectService: ProjectService,
    ) {}

    public static mapToScheduleModel(
        entity: IPersistence<IScheduleEntity>,
        timeSeries: ITimeSerieModel[],
    ): IScheduleModel {
        return {
            id: entity._id,
            rev: entity._rev,
            type: entity.type,
            creationDate: new Date(entity.creationDate),
            updatedDate: new Date(entity.updatedDate),
            name: entity.name,
            timeSeries,
            entityVersion: entity.entityVersion,
            systemDefined: entity.systemDefined,
            locked: entity.locked,
        };
    }

    public static mapToTimeSeriesModels(
        scheduleId: Id,
        timeSeries: Array<IPersistence<ITimeSerieEntity>>,
    ): ITimeSerieModel[] {
        return timeSeries.map(
            (series): ITimeSerieModel => ({
                id: series._id,
                rev: series._rev,
                entityVersion: series.entityVersion,
                type: series.type,
                creationDate: new Date(series.creationDate),
                updatedDate: new Date(series.updatedDate),
                start: new Time24(series.start),
                end: new Time24(series.end),
                days: new ScheduleDays(series.days),
                locked: series.locked,
                isInverted: getParentId(series) !== scheduleId,
            }),
        );
    }

    public static mapToScheduleEntity(
        projectId: Id,
        schedule: IBaseScheduleModel,
        id: Id,
    ): IScheduleEntity {
        return {
            type: 'schedule',
            path: [projectId, id],
            name: schedule.name,
            systemDefined: schedule.systemDefined,
            locked: false,
            archived: false,
        } satisfies IScheduleEntity;
    }

    public static mapToTimeSeriesEntities(
        schedule: IScheduleEntity,
        timeSeries: IBaseTimeSerieModel[],
        ids: Id[],
    ) {
        const timeSeriesEntities = timeSeries.map(
            (series, index): ITimeSerieEntity => ({
                type: 'timeSerie',
                path: [...schedule.path, ids[index]],
                start: series.start.toPersistable(),
                end: series.end.toPersistable(),
                days: series.days.toPersistable(),
                locked: schedule.locked,
                archived: schedule.archived,
            }),
        );

        return timeSeriesEntities;
    }

    public static getDefaultScheduleModels(): IBaseScheduleModel[] {
        return [
            {
                name: t.schedulesSystemDefinedNamesAlways,
                systemDefined: true,
                timeSeries: [
                    {
                        start: new Time24(0),
                        end: new Time24(24),
                        days: new ScheduleDays([true, true, true, true, true, true, true]),
                        isInverted: false,
                    },
                ],
            },
            {
                name: t.schedulesSystemDefinedNamesOfficeHours,
                systemDefined: false,
                timeSeries: [
                    {
                        start: new Time24(8),
                        end: new Time24(17),
                        days: new ScheduleDays([true, true, true, true, true, false, false]),
                        isInverted: false,
                    },
                ],
            },
        ];
    }

    public async getProjectSchedules(projectId: Id): Promise<IScheduleModel[]> {
        const schedules = await this.scheduleRepository.getDescendants(projectId);

        return Promise.all(
            schedules.descendants.map(async (schedule) =>
                ScheduleModelService.mapToScheduleModel(
                    schedule,
                    await this.getScheduleTimeSeries(schedule._id),
                ),
            ),
        );
    }

    public async getSchedule(id: Id): Promise<IScheduleModel> {
        const schedule = await this.scheduleRepository.get(id);
        return ScheduleModelService.mapToScheduleModel(
            schedule,
            await this.getScheduleTimeSeries(schedule._id),
        );
    }

    public async addProjectSchedule(
        projectId: Id,
        schedule: IBaseScheduleModel,
    ): Promise<IScheduleModel> {
        const scheduleEntity: IScheduleEntity = {
            type: 'schedule',
            path: [projectId],
            name: schedule.name,
            systemDefined: schedule.systemDefined,
            locked: await this.projectService.isLocked(projectId),
            archived: await this.projectService.isArchived(projectId),
        };

        const scheduleResponse = await this.scheduleRepository.add(scheduleEntity);
        const timeSeriesResponse = await this.addTimeSeries(scheduleResponse, schedule.timeSeries);

        return ScheduleModelService.mapToScheduleModel(scheduleResponse, timeSeriesResponse);
    }

    public async addDefaultSchedules(projectId: Id): Promise<IScheduleModel> {
        const defaultSchedules = ScheduleModelService.getDefaultScheduleModels();
        let alwaysSchedule: IScheduleModel | undefined = undefined;

        for (const schedule of defaultSchedules) {
            const scheduleEntity: IScheduleEntity = {
                type: 'schedule',
                path: [projectId],
                name: schedule.name,
                systemDefined: schedule.systemDefined,
                locked: await this.projectService.isLocked(projectId),
                archived: await this.projectService.isArchived(projectId),
            };
            const scheduleResponse = await this.scheduleRepository.add(scheduleEntity);
            const timeSeriesResponse = await this.addTimeSeries(
                scheduleResponse,
                schedule.timeSeries,
            );
            if (schedule.systemDefined) {
                alwaysSchedule = ScheduleModelService.mapToScheduleModel(
                    scheduleResponse,
                    timeSeriesResponse,
                );
            }
        }

        if (!alwaysSchedule) {
            throw new Error('Always schedule not found');
        }

        return alwaysSchedule;
    }

    private async addTimeSeries(
        schedule: IPersistence<IScheduleEntity>,
        timeSeries: IBaseTimeSerieModel[],
    ): Promise<ITimeSerieModel[]> {
        const entities = timeSeries.map(
            (series): ITimeSerieEntity => ({
                type: 'timeSerie',
                path: [...schedule.path],
                start: series.start.toPersistable(),
                end: series.end.toPersistable(),
                days: series.days.toPersistable(),
                locked: schedule.locked,
                archived: schedule.archived,
            }),
        );

        const persistedTimeSeries = await Promise.all(
            entities.map(async (entity) => {
                return this.timeSerieRepository.add(entity);
            }),
        );

        return ScheduleModelService.mapToTimeSeriesModels(schedule._id, persistedTimeSeries);
    }

    private async getScheduleTimeSeries(scheduleId: Id): Promise<ITimeSerieModel[]> {
        const timeSeries = await this.timeSerieRepository.getDescendants(scheduleId);
        return ScheduleModelService.mapToTimeSeriesModels(scheduleId, timeSeries.descendants);
    }
}
