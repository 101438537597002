import * as React from 'react';
import { useSelector } from 'react-redux';
import type { IStoreState, ProjectStoreState } from 'app/store';
import { useService } from 'app/ioc';
import { t } from 'app/translate';
import { getUserSignedIn, SignInReminder, TableHeaderSort } from 'app/modules/common';
import type { IIdRevModel } from 'app/core/persistence';
import {
    LoadingIndicator,
    Box,
    Stack,
    Icon,
    Card,
    Positioned,
    Border,
    Button,
    Text,
    Spacer,
    IconText,
    LazyRender,
    Opacity,
} from 'app/components';
import { ProjectItem } from './ProjectItem';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import type { Property } from 'csstype';
import { eventTracking } from 'app/core/tracking';
import type { IProjectActionService, IProjectListSort } from '../models';
import { isCustomPouchError } from 'app/utils';
import { useMount } from 'app/hooks';
import {
    getCurrentView,
    getSelectedProjectIds,
    getSorting,
    getUserProjects,
    getVisibleUserProjects,
} from '../selectors';
import { ProjectProgressBar } from './ProjectProgressBar';
import { ArchiveHelpButton } from './ArchiveHelpButton';
import { ProjectLoadingTimerService } from '../services';
import { getMigrationProgress } from 'app/modules/application';

const transitionDuration = 300;

const stateToTranslation: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '100%',
    entering: '0',
    entered: '0',
    exiting: '100%',
    exited: '100%',
};

interface IProjectListProps {
    actionService: IProjectActionService;
    state: ProjectStoreState;
    loaded: boolean;
    error: Error | null;
}

export const ProjectsList: React.FC<IProjectListProps> = ({
    state,
    actionService,
    loaded,
    error,
}) => {
    const currentView = useSelector((storeState: IStoreState) => getCurrentView(storeState));
    const projects = useSelector((storeState: IStoreState) => getUserProjects(storeState, state));
    const visibleProjects = useSelector((storeState: IStoreState) =>
        getVisibleUserProjects(storeState, state),
    );
    const selectedSorting = useSelector((storeState: IStoreState) => getSorting(storeState, state));
    const selectedProjectIds = useSelector((storeState: IStoreState) =>
        getSelectedProjectIds(storeState, state),
    );
    const showMultiArchive = selectedProjectIds.every(
        (projectId) => projects.find(({ id }) => id === projectId)?.archived === false,
    );
    const showMultiUnarchive =
        selectedProjectIds.length > 0 &&
        selectedProjectIds.every(
            (projectId) => projects.find(({ id }) => id === projectId)?.archived === true,
        );
    const progress = useSelector((storeState: IStoreState) =>
        getMigrationProgress(storeState, state),
    );
    const isSignedIn = useSelector((storeState: IStoreState) => getUserSignedIn(storeState));
    const projectLoadingTimerService = useService(ProjectLoadingTimerService);
    const [deleteConfirmed, setDeleteConfirmed] = React.useState(false);

    useMount(() => {
        if (currentView === 'userprojects') {
            projectLoadingTimerService.startTimer();
        }
        setDeleteConfirmed(false);
    });

    React.useEffect(() => {
        if (selectedProjectIds.length < 1) {
            setDeleteConfirmed(false);
        }
    }, [selectedProjectIds.length]);

    React.useEffect(() => {
        if (loaded) {
            projectLoadingTimerService.stopTimer();
        }
    }, [projectLoadingTimerService, loaded]);

    const nodeRef = React.useRef<HTMLDivElement>(null);

    const onMultiDeleteProjects = () => {
        if (deleteConfirmed) {
            const projectsToDelete: IIdRevModel[] = projects
                .filter((project) => selectedProjectIds.includes(project.id))
                .map((project) => {
                    return { id: project.id, rev: project.rev };
                });

            actionService.deleteMultipleProjects(projectsToDelete);
            setDeleteConfirmed(false);
            onClearSelection();
        } else {
            setDeleteConfirmed(true);
        }
    };

    const onClearSelection = () => {
        actionService.clearSelection();
        setDeleteConfirmed(false);
    };

    const onSortingChanged = (sortProperty: IProjectListSort['sort']) => {
        if (
            !selectedSorting.sort.includes(sortProperty) ||
            selectedSorting.direction === 'descending'
        )
            actionService.setSortOrder({
                sort: sortProperty,
                direction: 'ascending',
            });
        else
            actionService.setSortOrder({
                sort: sortProperty,
                direction: 'descending',
            });

        eventTracking.logUserEvent('User Projects', 'Change Sort Order', sortProperty);
    };

    const onMultiExportProjects = () => {
        // Return early if somehow no projects are selected.
        if (selectedProjectIds.length === 0) return;

        eventTracking.logUserEvent(
            'User Projects',
            'Export Multiple Projects',
            `${selectedProjectIds.length} projects exported`,
        );

        if (selectedProjectIds.length > 1) {
            actionService.exportMultipleProjects(selectedProjectIds, isSignedIn === true);
        } else {
            // Use regular export if only one project is selected in order to get translations referring to a single project.
            actionService.exportProject(selectedProjectIds[0]);
        }
        onClearSelection();
    };

    const onMultiArchiveProjects = async (archive: boolean) => {
        // Return early if somehow no projects are selected.
        if (selectedProjectIds.length === 0) return;

        eventTracking.logUserEvent(
            'User Projects',
            `${archive ? 'Archive' : 'Unarchive'} Multiple Projects`,
            `${selectedProjectIds.length} projects ${archive ? 'archived' : 'unarchived'}`,
        );

        await actionService.archiveMultipleProjects(selectedProjectIds, archive);

        onClearSelection();
    };

    const isReplicating = progress > 0 && progress < 1;

    if (error) {
        return <div>{isCustomPouchError(error) ? error.reason : error.message}</div>;
    }
    if (visibleProjects.length < 1 && !loaded && !isReplicating) {
        return <LoadingIndicator message={t.projectWaitWhileLoadingProjects} />;
    }

    return (
        <Box
            testId="user_project_list"
            direction="column"
            flex="fullWidth"
            paddingBottom="doublePanel"
        >
            <Positioned sendForward position="sticky" top="113px">
                <Box direction="column" paddingY="half" color="grey1">
                    <Box direction="column" position="relative">
                        <Box spacing="half" alignItems="center">
                            <Box flex="none" width="45px" />
                            <Box flex="shrinkAndGrow">
                                <TableHeaderSort
                                    onClick={() => onSortingChanged('name')}
                                    direction={
                                        selectedSorting.sort === 'name'
                                            ? selectedSorting.direction
                                            : 'none'
                                    }
                                >
                                    <Text color="grey6">{t.name} </Text>
                                </TableHeaderSort>
                            </Box>
                            <Box flex="none" width="180px">
                                <TableHeaderSort
                                    onClick={() => onSortingChanged('updated')}
                                    direction={
                                        selectedSorting.sort === 'updated'
                                            ? selectedSorting.direction
                                            : 'none'
                                    }
                                >
                                    <Text color="grey6">{t.changed} </Text>
                                </TableHeaderSort>
                            </Box>
                            <Box flex="none" width="100px">
                                <TableHeaderSort
                                    onClick={() => onSortingChanged('devices')}
                                    direction={
                                        selectedSorting.sort === 'devices'
                                            ? selectedSorting.direction
                                            : 'none'
                                    }
                                >
                                    <Text color="grey6">{t.devices} </Text>
                                </TableHeaderSort>
                            </Box>
                            <Box flex="none" width="40px">
                                <Icon size="ms" icon="check" />
                            </Box>
                            <Box flex="none" width="178px" justifyContent="end" paddingRight="base">
                                <Opacity
                                    alpha={isReplicating ? 1 : 0}
                                    animation="opacity 200ms ease-in 1s"
                                >
                                    <Text color="grey4" align="right">
                                        {`${Math.round(progress * 100)} %`}
                                    </Text>
                                </Opacity>
                            </Box>
                        </Box>
                        <ProjectProgressBar progress={progress} />
                    </Box>
                </Box>
            </Positioned>
            {visibleProjects.length === 0 && loaded && (
                <Card color="grey2">
                    <Box padding="half">
                        <Box spacing="half" alignItems="center">
                            <Icon icon="list" size="lg" color="grey6" />
                            <Text semiBold color="grey6">
                                {t.emptyList}
                            </Text>
                        </Box>
                    </Box>
                </Card>
            )}
            {(visibleProjects.length > 0 || isReplicating) && (
                <Card hideOverflow={selectedProjectIds.length > 0} borderRadius="0 0 8px 8px">
                    <Box direction="column" lineBetweenColor="grey1" flex="fullWidth">
                        {visibleProjects.map((p, index) => (
                            <LazyRender
                                key={p.id}
                                initiallyVisible={index < 20}
                                heightEstimate={56}
                            >
                                <ProjectItem
                                    project={p}
                                    index={index}
                                    state={state}
                                    actionService={actionService}
                                />
                            </LazyRender>
                        ))}
                    </Box>
                </Card>
            )}

            {currentView === 'userprojects' && (
                <SignInReminder currentView={currentView} trackingCategory={'User Projects'} />
            )}
            {currentView === 'localprojects' && (
                <Box paddingY="panel" display="block">
                    <Box padding="base" alignItems="center" color="grey2">
                        <IconText
                            icon="warning"
                            spacing="cell"
                            textProps={{
                                large: true,
                            }}
                            iconProps={{
                                opaque: true,
                                color: 'blue',
                            }}
                        >
                            {t.myLocalProjectsWarning}
                        </IconText>
                    </Box>
                </Box>
            )}
            <Spacer spacing="doublePanel" />
            <Transition
                in={selectedProjectIds.length > 0}
                timeout={transitionDuration}
                mountOnEnter
                unmountOnExit
                nodeRef={nodeRef}
            >
                {(storeState) => (
                    <div ref={nodeRef}>
                        <Positioned
                            bringToFront
                            position="fixed"
                            right="0"
                            left="0"
                            bottom="0"
                            translateY={stateToTranslation[storeState]}
                            transition
                            duration={transitionDuration}
                        >
                            <Border color="grey3" shadow="rgba(0, 0, 0, 0.15) 0px 5px 12px">
                                <Box
                                    color="white"
                                    flex="fullWidth"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box paddingX="doublePanel" paddingY="panel" width="100%">
                                        <Stack flex="fullWidth" justifyContent="between">
                                            <Box></Box>
                                            <Stack>
                                                <Box
                                                    direction="column"
                                                    minWidth="135px"
                                                    alignItems="stretch"
                                                >
                                                    <Button
                                                        testId="delete_project"
                                                        primary
                                                        warning={deleteConfirmed}
                                                        onClick={onMultiDeleteProjects}
                                                    >
                                                        {deleteConfirmed
                                                            ? `${t.confirmDelete} (${selectedProjectIds.length})`
                                                            : `${t.delete} (${selectedProjectIds.length})`}
                                                    </Button>
                                                </Box>
                                                <Box
                                                    direction="column"
                                                    minWidth="135px"
                                                    alignItems="stretch"
                                                >
                                                    <Button primary onClick={onMultiExportProjects}>
                                                        {`${t.projectExport} (${selectedProjectIds.length})`}
                                                    </Button>
                                                </Box>
                                                {showMultiArchive && (
                                                    <Box
                                                        direction="column"
                                                        minWidth="135px"
                                                        alignItems="stretch"
                                                    >
                                                        <Button
                                                            primary
                                                            onClick={() =>
                                                                onMultiArchiveProjects(true)
                                                            }
                                                        >
                                                            {`${t.projectArchive} (${selectedProjectIds.length})`}
                                                        </Button>
                                                    </Box>
                                                )}
                                                {showMultiUnarchive && (
                                                    <Box
                                                        direction="column"
                                                        minWidth="135px"
                                                        alignItems="stretch"
                                                    >
                                                        <Button
                                                            primary
                                                            onClick={() =>
                                                                onMultiArchiveProjects(false)
                                                            }
                                                        >
                                                            {`${t.projectRestoreFromArchive} (${selectedProjectIds.length})`}
                                                        </Button>
                                                    </Box>
                                                )}

                                                {(showMultiArchive || showMultiUnarchive) && (
                                                    <ArchiveHelpButton
                                                        header={t.projectArchiveInfoHeader}
                                                        body={t.projectArchiveInfoBody}
                                                    />
                                                )}
                                            </Stack>
                                            <Button onClick={onClearSelection}>{t.cancel}</Button>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Border>
                        </Positioned>
                    </div>
                )}
            </Transition>
        </Box>
    );
};
