import { getItemLocalStorage } from './core/persistence/utils/localStorage';
import { isProduction } from './isProduction';

/**
 * Name of the feature that you want to group logic to.
 *
 * @todo 'unsaved_changes' - can be removed when https://jira.se.axis.com/browse/WT-3632 is done.
 */
type feature =
    | 'zip_profile' // https://jira.se.axis.com/browse/WT-7858
    | 'project_pricing' // https://jira.se.axis.com/browse/WT-9584
    | 'aoa_light_conditions' // https://jira.se.axis.com/browse/WT-6597,
    | 'export_bidcon' // Remove when securitas is ready to export bidcon
    | 'other_partner_products' // https://jira.se.axis.com/browse/WT-6097
    | 'import_settings_file' // https://jira.se.axis.com/browse/WT-9652
    | 'rtl_languages' // https://jira.se.axis.com/browse/WT-7371
    | 'acs_center' // https://jira.se.axis.com/browse/WT-8469 (acs_pro needs to be activated for this to work)
    | 'mocked_acs_center_licenses' // https://jira.se.axis.com/browse/WT-8109 // after pro was released 24-05-01 only center licenses are mocked
    | 'shared_projects' // https://jira.se.axis.com/browse/WT-6721
    | 'shared-projects-optimize' // https://jira.se.axis.com/browse/WT-9482
    | 'fluent_ui' // https://jira.se.axis.com/browse/WT-9086
    | 'distance_as_path'; // https://jira.se.axis.com/browse/WT-9724

/**
 * Add this method and what feature this logic belongs to to make it only show up in develop environments.
 *
 * @param _feature this parameter is used to group the logic to a feature. For now this does not do anything else.
 */
export function isFeatureEnabled(_feature: feature) {
    return (
        !isProduction() &&
        process.env.HIDE_FEATURE_FLAGGED_ON_DEV !== 'true' &&
        getItemLocalStorage('FeatureFlagsOff') !== 'true'
    );
}
