import { optional, oneOf, type Schema } from 'app/modules/common';

import {
    cameraCategoryValidator,
    doorStationCategoryValidator,
    mainUnitCategoryValidator,
    encoderCategoryValidator,
    decoderCategoryValidator,
    pacCategoryValidator,
    radarCategoryValidator,
    speakerCategoryValidator,
    peopleCounterCategoryValidator,
    alerterCategoryValidator,
    connectivityDeviceCategoryValidator,
    pagingConsoleCategoryValidator,
    bodyWornCameraCategoryValidator,
    dockingStationCategoryValidator,
    systemControllerCategoryValidator,
    cameraExtensionCategoryValidator,
    applicationCategoryValidator,
} from './customValidators';

import { PiaAccessoryCategory, PiaItemSoftwareCategory } from 'app/core/pia';

const ACCESSORY_CATEGORIES = Object.values(PiaAccessoryCategory);

const RECORDING_SETTINGS_SCHEMA: Schema = {
    compression: 'number',
    estimatedBandwidthKbit: 'number',
    estimatedStorageGB: 'number',
    fps: 'number',
    resolution: {
        horizontal: 'number',
        vertical: 'number',
    },
    scheduleId: 'string',
};

const ZIP_STREAM_SCHEMA: Schema = {
    fpsMode: 'string', //TODO 'fixed' | 'dynamic'
    gopDefault: 'number',
    gopMode: 'string',
    gopMax: 'number',
    minDynamicFps: 'number',
    profile: 'string',
    useProjectSetting: 'boolean',
    zipStrength: 'number',
};

const RECORDING_SCHEMA: Schema = {
    scenario: oneOf([
        'emergencyExit',
        'reception',
        'parking',
        'perimeter',
        'highway',
        'atm',
        'runningTrain',
        'streetCorner',
        'onboard',
        'intersection',
        'retail',
        'pedestrianZone',
        'busyStation',
        'checkoutLine',
        'mall',
    ]),
    motion: optional(RECORDING_SETTINGS_SCHEMA),
    continuous: optional(RECORDING_SETTINGS_SCHEMA),
    live: optional(RECORDING_SETTINGS_SCHEMA),
    storage: {
        retentionTime: 'number',
    },
    audio: {
        liveViewEnabled: 'boolean',
        recordingEnabled: 'boolean',
    },
    zipstream: optional(ZIP_STREAM_SCHEMA),
};

const APPLICATION_ITEM_BASE_SCHEMA: Schema = {
    id: 'string',
    revision: optional('string'),
    linkedId: optional('string'),
    name: 'string',
    quantity: optional('number'),
    description: optional('string'),
    notes: optional('string'),
};

const PIA_PRODUCT_SCHEMA: Schema = {
    piaId: 'number',
    modelName: optional('string'),
    productVariantName: optional('string'),
};

const APPLICATION_CATEGORY_SCHEMA = oneOf([PiaItemSoftwareCategory.ACAP]);
const APPLICATION_SCHEMA: Schema = {
    ...APPLICATION_ITEM_BASE_SCHEMA,
    piaId: applicationCategoryValidator,
    modelName: optional('string'),
    productVariantName: optional('string'),
    categories: [APPLICATION_CATEGORY_SCHEMA],
    category: APPLICATION_CATEGORY_SCHEMA,
    relationType: oneOf(['acap']),
    properties: {
        acapId: 'number',
        discontinued: 'boolean',
        isELicense: 'boolean',
        isIncluded: 'boolean',
        officialFullName: 'string',
        productId: applicationCategoryValidator,
        vendorName: 'string',
    },
};

const ITEM_BASE_SCHEMA: Schema = {
    id: 'string',
    revision: optional('string'),
    linkedId: optional('string'),
    name: 'string',
    quantity: optional('number'),
    color: optional('string'),
    description: optional('string'),
    notes: optional('string'),
    replaceWithBareboneId: optional('number'),
    networkSettings: optional([
        {
            dhcp: 'boolean',
            ipV4: optional({
                addresses: optional(['string']),
                subnetMask: optional('string'),
                defaultRouter: optional('string'),
            }),
        },
    ]),
    accessories: [], // Added later in this file
    applications: optional([APPLICATION_SCHEMA]),
    environment: optional({
        name: 'string',
        piaId: 'number',
    }),
};

const ACCESSORY_CATEGORY_SCHEMA = oneOf(ACCESSORY_CATEGORIES);
const ACCESSORY_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    categories: [ACCESSORY_CATEGORY_SCHEMA],
    category: ACCESSORY_CATEGORY_SCHEMA,
    relationType: oneOf(['accessory', 'deviceMount', 'primaryMount', 'environmentMount']),
};

// Create the circular references required to validate the recursive structure.
ITEM_BASE_SCHEMA.accessories = optional([ACCESSORY_SCHEMA]);
ACCESSORY_SCHEMA.accessories = optional([ACCESSORY_SCHEMA]);

const LENS_SCHEMA: Schema = {
    name: 'string',
    piaId: 'number',
    sensorIndex: 'number',
};

/**
 * Item schemas
 */
const CAMERA_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: cameraCategoryValidator,
    recording: optional(RECORDING_SCHEMA),
    lenses: optional([LENS_SCHEMA]),
};

const ANALOG_CAMERA_SCHEMA: Schema = {
    ...CAMERA_SCHEMA,
};
delete ANALOG_CAMERA_SCHEMA.piaId;
delete ANALOG_CAMERA_SCHEMA.modelName;

const DOOR_STATION_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: doorStationCategoryValidator,
    recording: optional(RECORDING_SCHEMA),
};

const MAIN_UNIT_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: mainUnitCategoryValidator,
    sensors: optional([CAMERA_SCHEMA]),
};

const ENCODER_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: encoderCategoryValidator,
    analogCameras: optional([ANALOG_CAMERA_SCHEMA]),
};

const DECODER_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: decoderCategoryValidator,
};

const DOOR_SIDE_SCHEMA: Schema = {
    rex: 'boolean',
    reader: optional(PIA_PRODUCT_SCHEMA),
};

const DOOR_SCHEMA: Schema = {
    nbrOfLocks: 'number',
    sideA: optional(DOOR_SIDE_SCHEMA),
    sideB: optional(DOOR_SIDE_SCHEMA),
};

const PHYSICAL_ACCESS_CONTROLLER_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: pacCategoryValidator,
    doors: optional([DOOR_SCHEMA]),
};

const RADAR_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: radarCategoryValidator,
};

const SPEAKER_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: speakerCategoryValidator,
    zones: optional(['string']),
};

const PEOPLE_COUNTER_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: peopleCounterCategoryValidator,
};

const ALERTER_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: alerterCategoryValidator,
};

const CONNECTIVITY_DEVICE_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: connectivityDeviceCategoryValidator,
};

const PAGING_CONSOLE_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: pagingConsoleCategoryValidator,
};

const BODY_WORN_CAMERA_PROFILE_SCHEMA: Schema = {
    activeRecordingInPercent: optional('number'),
    resolution: 'string',
    retentionTime: 'number',
    sceneId: oneOf(['indoorLow', 'indoorHigh', 'outdoor']),
    scheduleId: optional('string'),
};

const BODY_WORN_CAMERA_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: bodyWornCameraCategoryValidator,
    profile: BODY_WORN_CAMERA_PROFILE_SCHEMA,
};

const DOCKING_STATION_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: dockingStationCategoryValidator,
};

const SYSTEM_CONTROLLER_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: systemControllerCategoryValidator,
};

const CAMERA_EXTENSION_SCHEMA: Schema = {
    ...ITEM_BASE_SCHEMA,
    ...PIA_PRODUCT_SCHEMA,
    piaId: cameraExtensionCategoryValidator,
};

export const ITEMS_SCHEMA: Schema = {
    cameras: optional([CAMERA_SCHEMA]),
    doorStations: optional([DOOR_STATION_SCHEMA]),
    mainUnits: optional([MAIN_UNIT_SCHEMA]),
    encoders: optional([ENCODER_SCHEMA]),
    decoders: optional([DECODER_SCHEMA]),
    physicalAccessControllers: optional([PHYSICAL_ACCESS_CONTROLLER_SCHEMA]),
    radars: optional([RADAR_SCHEMA]),
    speakers: optional([SPEAKER_SCHEMA]),
    peopleCounters: optional([PEOPLE_COUNTER_SCHEMA]),
    alerters: optional([ALERTER_SCHEMA]),
    connectivityDevices: optional([CONNECTIVITY_DEVICE_SCHEMA]),
    pagingConsoles: optional([PAGING_CONSOLE_SCHEMA]),
    bodyWornCameras: optional([BODY_WORN_CAMERA_SCHEMA]),
    dockingStations: optional([DOCKING_STATION_SCHEMA]),
    systemControllers: optional([SYSTEM_CONTROLLER_SCHEMA]),
    cameraExtensions: optional([CAMERA_EXTENSION_SCHEMA]),
};
