import * as React from 'react';
import { t } from 'app/translate';
import {
    Button,
    SearchField,
    Checkbox,
    ContentHeaderBar,
    Spinner,
    UploadFile,
    Positioned,
    Box,
    Text,
} from 'app/components';
import { useService } from 'app/ioc';
import { UserProjectsActionService } from '../services';
import { eventTracking } from 'app/core/tracking';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIsCompactionAvailable, getUserSignedIn } from 'app/modules/common';
import { CouchDBCompactionModal } from 'app/modules/couchDBCompaction';
import {
    getCurrentView,
    getIsReplicating,
    getShowArchivedProjects,
    getUserProjectNameFilter,
} from '../../selectors';
import type { IStoreState } from 'app/store';
import { ACCEPTED_PROJECT_FILE_EXTENSIONS, SETTINGS_FILE_EXTENSION } from 'app/core/persistence';

export const UserProjectsContentHeader: React.FunctionComponent = () => {
    const userProjectActions = useService(UserProjectsActionService);
    const [showModal, setShowModal] = React.useState(false);
    const isSignedIn = useSelector(getUserSignedIn);
    const userProjectsFilter = useSelector((state: IStoreState) =>
        getUserProjectNameFilter(state, 'userProjects'),
    );
    const showArchivedProjects = useSelector((state: IStoreState) =>
        getShowArchivedProjects(state, 'userProjects'),
    );
    const replicating = useSelector((state: IStoreState) =>
        getIsReplicating(state, 'userProjects'),
    );
    const currentView = useSelector((state: IStoreState) => getCurrentView(state));
    const isCompactionAvailable = useSelector(getIsCompactionAvailable);
    const navigate = useNavigate();

    const importProject = (file: File) => {
        const fileType = file.name.slice(file.name.lastIndexOf('.'));
        if (fileType === SETTINGS_FILE_EXTENSION) {
            userProjectActions.importSettings(file);
        } else {
            eventTracking.logUserEvent('User Projects', 'Import Project');
            userProjectActions.importProject(file);
        }
    };

    const addProject = async () => {
        userProjectActions.addProject(navigate);
    };

    const optimizePerformance = () => {
        setShowModal(true);
    };

    const toggleShowArchivedProjects = (value: boolean) => {
        userProjectActions.showArchivedProjects(value);
    };

    const updateProjectNameFilter = (value: string) => {
        userProjectActions.userProjectFilterChange(value);
    };

    const showOptimizeButton = isSignedIn && isCompactionAvailable;

    return (
        <>
            {showModal && <CouchDBCompactionModal setShowModal={setShowModal} />}
            <Positioned sendForward position="sticky" top="0px">
                <Box width="100%" color="grey1" direction="column">
                    <Box paddingLeft="base" paddingTop="base" direction="column">
                        <Text style="title">{t.myProjects}</Text>
                    </Box>
                    <ContentHeaderBar>
                        <Box wrap spacing="base">
                            <Button testId="btn_add_project" primary onClick={addProject}>
                                {t.projectAddProject}
                            </Button>
                            <UploadFile
                                testId="npt_upload_file"
                                title={t.projectImportActionLabel}
                                onFileUploaded={importProject}
                                accept={ACCEPTED_PROJECT_FILE_EXTENSIONS.join(',')}
                            />
                            {currentView === 'userprojects' && showOptimizeButton && (
                                <Button
                                    testId="btn_optimize_performance"
                                    onClick={optimizePerformance}
                                >
                                    {t.optimizePerformance}
                                </Button>
                            )}
                        </Box>
                        <Box spacing="base">
                            {replicating && showArchivedProjects && <Spinner size={24} />}

                            <Checkbox
                                testId="archived_project_checkbox"
                                selected={showArchivedProjects}
                                onChange={toggleShowArchivedProjects}
                                slider
                                disabled={replicating}
                            >
                                {t.projectShowArchived}
                            </Checkbox>

                            <SearchField
                                testId="project_search_field"
                                value={userProjectsFilter}
                                onValueChange={updateProjectNameFilter}
                            />
                        </Box>
                    </ContentHeaderBar>
                </Box>
            </Positioned>
        </>
    );
};
