import { eventTracking } from 'app/core/tracking';
import { toaster } from 'app/toaster';
import { t } from 'app/translate';
import { isDefined } from 'axis-webtools-util';
import { injectable } from 'inversify';
import type {
    IDetailedOrganization,
    IOrganizationOrResourceGroup,
    IOrganizationResponse,
    IOrganizationsResponse,
    Arn,
    IPrincipals,
    IResourceGroupAccessResponse,
    IResourceGroupAccess,
    IPrincipalsResponse,
} from '../models/IOrganizations';
import { isErrorType } from '../utils';
import { ACXApiCommunicator } from './ACXApiCommunicator';

/** Service for retrieving organization data.
 *
 * The API is maintained in this repository: https://github.com/axteams-software/sp-acx-graphql-api/
 *
 * To read the official API documentation visit: https://docs.stage.cs.connect.axis.com/
 *
 * To set up your own organizations and resource groups visit: https://portal.iam.connect.axis.com/
 */
@injectable()
export class OrganizationsService {
    constructor(private acxApiCommunicator: ACXApiCommunicator) {}

    /** Retrieves detailed information of all organizations the user is connected to. */
    public async getAllDetailedOrganizations(
        accessToken: string,
    ): Promise<IDetailedOrganization[]> {
        const organizations = await this.fetchUserOrganizations(accessToken);
        if (!organizations || organizations.length === 0) return [];
        const organizationArns = organizations.map((org) => org.arn);
        return (
            await Promise.all(
                organizationArns.map((arn) => this.fetchOrganization(accessToken, arn)),
            )
        ).filter(isDefined);
    }

    /** Retrieves all organizations the user is connected to. */
    private async fetchUserOrganizations(
        accessToken: string,
    ): Promise<IOrganizationOrResourceGroup[]> {
        try {
            const response: IOrganizationsResponse = await this.acxApiCommunicator.fetchGraphQL(
                `
                    query organizations {
                        organizations {
                          organizations {
                            arn
                            name,
                        }
                    }
                }
                `,
                accessToken,
            );
            return response.organizations.organizations;
        } catch (error) {
            const errorMessage = isErrorType(error) ? error.message : '';
            eventTracking.logError(
                `Failed to fetch organizations. Error: ${errorMessage}`,
                'ACXService',
            );
            toaster.error(
                t.couldNotGetOrganizations,
                t.pleaseCheckYourNetworkConnection,
                'warning',
            );
            return [];
        }
    }

    /** Retrieves a specific organization by arn.
     * @param arn Unique id for the requested organization
     */
    private async fetchOrganization(
        accessToken: string,
        organizationArn: Arn,
    ): Promise<IDetailedOrganization | undefined> {
        try {
            const response: IOrganizationResponse = await this.acxApiCommunicator.fetchGraphQL(
                `
                    query organization($organizationArn: Arn!) {
                        organization(organizationArn: $organizationArn) {
                            arn
                            name,
                            resourceTree {
                                resourceTree
                            }
                    }
                }
                `,
                accessToken,
                { organizationArn },
            );
            return response.organization;
        } catch (error) {
            const errorMessage = isErrorType(error) ? error.message : '';
            eventTracking.logError(
                `Failed to fetch organization. Error: ${errorMessage}`,
                'ACXService',
            );
        }
    }

    /** Retrieves resourceGroups by arn including its accesses
     * @param accessToken
     * @param arn Unique id for the requested resourceGroup ex "arn:resource-group:<OrganizationId>>/<ResourceGroupId>"
     */
    public async fetchResourceGroupsWithAccess(
        accessToken: string,
        resourceGroupArns: Arn[],
    ): Promise<IResourceGroupAccess[] | undefined> {
        try {
            const response: IResourceGroupAccessResponse =
                await this.acxApiCommunicator.fetchGraphQL(
                    `
                    query resourceGroups($resourceGroupArns: [Arn!]!) {
                        resourceGroups(arns: $resourceGroupArns) {
                            appliedAccesses {
                                access {
                                    arn
                                    assignedPrincipals {
                                      arn
                                    }
                                    role {
                                      roleId
                                      roleName
                                    }
                                  }
                              }
                        }
                    }
                `,
                    accessToken,
                    { resourceGroupArns },
                );
            return response.resourceGroups;
        } catch (error) {
            const errorMessage = isErrorType(error) ? error.message : '';
            eventTracking.logError(
                `Failed to fetch resourceGroups. Error: ${errorMessage}`,
                'ACXService',
            );
        }
    }

    /** Retrieves principals for the given principal arns
     * @param accessToken
     * @param arn Unique id for the requested principal ex "arn:principal:<organizationId>/<principalId>"
     */
    public async fetchPrincipals(
        accessToken: string,
        principalArns: Arn[],
    ): Promise<IPrincipals[] | undefined> {
        try {
            const response: IPrincipalsResponse = await this.acxApiCommunicator.fetchGraphQL(
                `
                    query Principals($principalArns: [Arn!]!) {
                        principals(arns: $principalArns) {
                            ... on MyAxisPrincipal {
                                contactInfo {
                                  email
                                  name
                                }
                                arn
                              }
                        }
                    }
                `,
                accessToken,
                { principalArns },
            );
            return response.principals;
        } catch (error) {
            const errorMessage = isErrorType(error) ? error.message : '';
            eventTracking.logError(
                `Failed to fetch principals. Error: ${errorMessage}`,
                'ACXService',
            );
        }
    }
}
