import { injectable } from 'inversify';
import { IExportedCamera } from 'app/modules/exportProjectSettings';
import {
    CreateEntityService,
    defaultEncoderFilter,
    getDefaultProfileOverrideEntity,
    IAccessoryItemEntity,
    IAnalogCameraItemEntity,
    Id,
    IEncoderItemEntity,
    IEnvironmentItemEntity,
    IItemRelationEntity,
} from 'app/core/persistence';
import { ImportedEncoder } from '../../../models/ImportedProjectSettingTypes';
import { mapToItemEntity, getImportedAccessories } from './utils';
import { IProfileEntityWithId, ProfileImporterService } from '../profiles';
import { createRelationEntity } from './utils/createRelationEntity';

@injectable()
export class EncoderImporterService {
    constructor(
        private profileService: ProfileImporterService,
        private createEntityService: CreateEntityService,
    ) {}

    public import(
        projectId: Id,
        encoders: ImportedEncoder[],
        profiles: IProfileEntityWithId[],
    ): (
        | IEncoderItemEntity
        | IAccessoryItemEntity
        | IEnvironmentItemEntity
        | IItemRelationEntity
        | IAnalogCameraItemEntity
    )[] {
        const encoderEntities = encoders.map((encoder) =>
            this.mapToEncoderItemEntity(projectId, encoder),
        );
        const analogCameraAndRelationEntities: (IAnalogCameraItemEntity | IItemRelationEntity)[] =
            encoders
                .flatMap((encoder) =>
                    encoder.analogCameras.map((camera) =>
                        this.mapToAnalogCameraAndRelationEntity(
                            projectId,
                            encoder,
                            camera,
                            profiles,
                        ),
                    ),
                )
                .flat();

        const accessoryEntities = getImportedAccessories(projectId, encoders);
        return [...encoderEntities, ...analogCameraAndRelationEntities, ...accessoryEntities];
    }

    private mapToEncoderItemEntity(projectId: Id, encoder: ImportedEncoder): IEncoderItemEntity {
        const itemEntity = mapToItemEntity(projectId, encoder, encoder.piaId);
        return {
            ...itemEntity,
            properties: {
                encoder: { filter: defaultEncoderFilter },
            },
        };
    }

    private mapToAnalogCameraAndRelationEntity(
        projectId: string,
        encoder: ImportedEncoder,
        analogCamera: IExportedCamera,
        profiles: IProfileEntityWithId[],
    ): (IAnalogCameraItemEntity | IItemRelationEntity)[] {
        const itemEntity = mapToItemEntity(projectId, analogCamera);
        const analogCameraEntity: IAnalogCameraItemEntity = {
            ...itemEntity,
            path: [projectId, encoder.id, analogCamera.id],
            properties: {
                analogCamera: {
                    associatedProfile: this.profileService.getProfileIdForImportedCamera(
                        analogCamera,
                        profiles,
                    ),
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
            },
        };

        const relationEntityId = this.createEntityService.generateDatabaseId('itemRelation');
        const itemRelationEntity: IItemRelationEntity = createRelationEntity(
            relationEntityId,
            [projectId, encoder.id],
            analogCamera.id,
            'analogCamera',
        );

        return [analogCameraEntity, itemRelationEntity];
    }
}
