import { createSelector } from 'reselect';
import { getUserLocId, getUserSignedIn } from '../../authentication/selectors/getUserSignedIn';
import {
    NORTH_AMERICA_COUNTRY_CODES,
    SOUTH_AMERICA_COUNTRY_CODES,
    MIDDLE_AMERICA_COUNTRY_CODES,
    CARIBBEAN_COUNTRY_CODES,
} from '../countryCodes';
import { getLocationId } from './getCurrentProject';
import { isFeatureEnabled } from 'app/featureFlags';

const projectPricingCountryCodes = [
    ...NORTH_AMERICA_COUNTRY_CODES,
    ...SOUTH_AMERICA_COUNTRY_CODES,
    ...MIDDLE_AMERICA_COUNTRY_CODES,
    ...CARIBBEAN_COUNTRY_CODES,
];

/** Determines if "Request project pricing" button should be shown in Bill of Materials */
export const getShouldShowProjectPricing = createSelector(
    [getLocationId, getUserSignedIn, getUserLocId],
    (projectCountryId, isSignedIn, locId) => {
        if (isFeatureEnabled('project_pricing')) {
            return !!isSignedIn && locId !== undefined;
        }
        return projectPricingCountryCodes.includes(projectCountryId) && !!isSignedIn;
    },
);
