import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/css';
import { useService } from 'app/ioc';
import { isFeatureEnabled } from 'app/featureFlags';
import { t } from 'app/translate';
import { Box, Linkable, MenuButton, SidebarMenu, Text } from 'app/components';
import { CommonActionService, getIsProjectOperationInProgress } from 'app/modules/common';
import { ApplicationActionService } from 'app/modules/application';
import { useSelector } from 'react-redux';
import type { CurrentView } from 'app/core/persistence';
import { getIsReplicating } from '../selectors';

const textStyle = css`
    font-size: 10px;
    margin-left: 7px;
`;

const menuItemStyle = css`
    width: 100%;
`;

interface IUserProjectsLandingMenu {
    isProjectsMenuExpanded: boolean;
    onProjectsMenuChange(): void;
}

export const UserProjectsLandingMenu: React.FunctionComponent<IUserProjectsLandingMenu> = ({
    isProjectsMenuExpanded,
    onProjectsMenuChange,
}) => {
    const applicationActionService = useService(ApplicationActionService);
    const commonActionService = useService(CommonActionService);
    const { pathname } = useLocation();
    const replicating = useSelector(getIsReplicating);
    const isProjectOperationInProgress = useSelector(getIsProjectOperationInProgress);
    const isDatabaseOperationInProgress = replicating || isProjectOperationInProgress;

    React.useEffect(() => {
        commonActionService.unloadProject();
        commonActionService.unloadCurrentProject();
    }, [commonActionService]);

    const changeView = async (view: CurrentView) => {
        applicationActionService.setCurrentView(view);
    };

    const content = () => {
        return (
            <Box direction="column" width="100%" spacing="panel">
                <div aria-selected={pathname.includes('userprojects')} className={menuItemStyle}>
                    <Box paddingY="half" width="100%">
                        <Linkable
                            link={'userprojects/'}
                            onNavigate={() => changeView('userprojects')}
                        >
                            <MenuButton
                                icon="person"
                                text={t.myProjects}
                                selected={pathname.includes('userprojects')}
                                onClick={() => changeView('userprojects')}
                                iconOnly={!isProjectsMenuExpanded}
                                disabled={
                                    isDatabaseOperationInProgress &&
                                    !pathname.includes('userprojects')
                                }
                            >
                                <div className={textStyle} aria-label="description">
                                    <Text color="grey6" wordBreak="normal" testId="my_projects">
                                        {t.myProjectsDescription}
                                    </Text>
                                </div>
                            </MenuButton>
                        </Linkable>
                    </Box>
                </div>
                {isFeatureEnabled('shared_projects') && (
                    <div
                        aria-selected={pathname.includes('sharedprojects')}
                        className={menuItemStyle}
                    >
                        <Linkable
                            link={'sharedprojects/'}
                            onNavigate={() => changeView('sharedprojects')}
                        >
                            <MenuButton
                                icon="people"
                                text={t.sharedProjects}
                                selected={pathname.includes('sharedprojects')}
                                onClick={() => changeView('sharedprojects')}
                                iconOnly={!isProjectsMenuExpanded}
                                disabled={
                                    isDatabaseOperationInProgress &&
                                    !pathname.includes('sharedprojects')
                                }
                            >
                                <div className={textStyle} aria-label="description">
                                    <Text color="grey6" wordBreak="normal" testId="shared_projects">
                                        {t.sharedProjectsDescription}
                                    </Text>
                                </div>
                            </MenuButton>
                        </Linkable>
                    </div>
                )}
                <div aria-selected={pathname.includes('localprojects')} className={menuItemStyle}>
                    <Linkable
                        link={'localprojects/'}
                        onNavigate={() => changeView('localprojects')}
                    >
                        <MenuButton
                            testId={'local_projects_button'}
                            icon="cloud_disabled"
                            text={t.localProjects}
                            selected={pathname.includes('localprojects')}
                            onClick={() => changeView('localprojects')}
                            iconOnly={!isProjectsMenuExpanded}
                            disabled={
                                isDatabaseOperationInProgress && !pathname.includes('localprojects')
                            }
                        >
                            <div className={textStyle} aria-label="description">
                                <Text color="grey6" wordBreak="normal" testId="local_projects">
                                    {t.myLocalProjectsDescription}
                                </Text>
                            </div>
                        </MenuButton>
                    </Linkable>
                </div>
            </Box>
        );
    };

    return (
        <SidebarMenu
            content={content()}
            isMenuExpanded={isProjectsMenuExpanded}
            onExpandedChange={onProjectsMenuChange}
        />
    );
};
