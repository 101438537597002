import type { IStoreState, ProjectStoreState } from 'app/store';

export const getIsReplicating = (state: IStoreState, property?: ProjectStoreState): boolean => {
    if (!property) {
        // If no property is provided, check all relevant project stores
        return getIsReplicating(state, 'userProjects') || getIsReplicating(state, 'sharedProjects');
    }

    // If the property is provided, check the specific project store
    const projectStoreState = state[property];

    if ('replicating' in projectStoreState) {
        return projectStoreState.replicating;
    }

    return false;
};
