import * as React from 'react';
import { Stack, Box, Text, Textarea } from 'app/components';
import { t } from 'app/translate';

interface IDeviceInstallationNotesProps {
    notes: string;
    updateNotes(notes: string): void;
}

export const DeviceInstallationNotes: React.FunctionComponent<IDeviceInstallationNotesProps> = ({
    notes,
    updateNotes,
}) => (
    <Box direction="column" paddingY="panel">
        <Stack vertical spacing="base">
            <Text semiBold align="center" color="grey5">
                {t.notes}
            </Text>
            <Textarea rows={4} value={notes} onChange={updateNotes} />
        </Stack>
    </Box>
);

DeviceInstallationNotes.displayName = 'DeviceInstallationNotes';
