import * as React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import {
    Box,
    Text,
    Stack,
    DropDown,
    DropDownMenuButton,
    Icon,
    DropDownMenuDelete,
    IconButton,
    Clickable,
    EditableText,
    IconText,
} from 'app/components';
import type { Id, IProfileEntity, IPersistence } from 'app/core/persistence';
import { CoreProfileNameError, ProfileService, ProjectService } from 'app/core/persistence';
import { ColorsEnum } from 'app/styles';
import { ServiceLocator } from 'app/ioc';
import {
    getDefaultProfileId,
    getIsProjectProfileValidForRecordingSolution,
    getProfile,
    getProfileUsesCount,
    getScenarioModels,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { AppConstants } from 'app/AppConstants';
import { toaster } from 'app/toaster';

interface IProfileItemProps {
    profileId: Id;
    onEditProfile(profileId: Id): void;
}

export const ProfileItem: React.FC<IProfileItemProps> = ({ profileId, onEditProfile }) => {
    const [profileService] = React.useState(ServiceLocator.get(ProfileService));
    const [projectService] = React.useState(ServiceLocator.get(ProjectService));
    const defaultProfile = useSelector<IStoreState, Id | undefined>(getDefaultProfileId);
    const profile = useSelector<IStoreState, IPersistence<IProfileEntity> | undefined>(
        (storeState) => getProfile(storeState, profileId),
    );
    const isValidForRecordingSolution = useSelector<IStoreState, boolean>((store) =>
        getIsProjectProfileValidForRecordingSolution(store, profileId),
    );
    const usedBy = useSelector<IStoreState, number>(getProfileUsesCount(profileId));
    const isDefaultProfile = defaultProfile === profile?._id;
    const [profileName, setProfileName] = React.useState(profile?.name ?? '');

    React.useEffect(() => {
        profile ? setProfileName(profile?.name) : undefined;
    }, [profile]);

    if (!profile) {
        return null;
    }

    const scenarioImages = () =>
        getScenarioModels().reduce((imageMap: Map<string, string>, scenario) => {
            imageMap.set(scenario.id, scenario.image);
            return imageMap;
        }, new Map<string, string>());

    const onNameChange = async (newName: string) => {
        try {
            setProfileName(newName);
            await profileService.updateProfileEntity({
                ...profile,
                name: newName,
            });
        } catch (error) {
            if (error instanceof CoreProfileNameError) {
                setProfileName(profile.name);
                toaster.warning(
                    t.profilesNameChangeFailedToastHeader,
                    error.isNameEmptyString ? '' : t.profilesNameChangeFailedToastBody(newName),
                );
            } else {
                throw error;
            }
        }
    };

    const onDeleteProfile = () => {
        profileService.deleteProfile(profile._id, profile._rev);
    };

    const onDuplicateProfile = () => {
        profileService.duplicateProfile(profile._id);
    };

    const onSetDefaultProfile = () => {
        projectService.updateCurrentProject({
            defaultProfile: profile._id,
        });
    };

    return (
        <Box padding="half" height="76px" justifyContent="center">
            <Stack spacing="half" justifyContent="between" flex="shrinkAndGrow">
                <Clickable allowClickThrough={true} onClick={() => onEditProfile(profile._id)}>
                    <Stack flex="shrinkAndGrow">
                        <Clickable
                            allowClickThrough={false}
                            onClick={() => onEditProfile(profile._id)}
                        >
                            <Box testId={`${profileName}_image`} maxWidth="90px" minWidth="70px">
                                <img
                                    src={scenarioImages().get(profile.scenario.scenarioId)}
                                    style={{
                                        /* Bug-fix for IE11 */
                                        flexShrink: 0,
                                        maxWidth: '100%',
                                        height: 'auto',
                                        maxHeight: '74px',
                                        objectFit: 'contain',
                                        borderRadius: '4px',
                                        border: `1px solid ${ColorsEnum.grey3}`,
                                    }}
                                />
                            </Box>
                        </Clickable>
                        <Stack
                            vertical
                            spacing="quart"
                            testId={`${profileName}_${profile.scenario.scenarioId}`}
                        >
                            <Text onlyStyle style="semibold" color="grey9">
                                <EditableText
                                    value={profileName}
                                    placeholder={t.deviceDetailsProfileHeading}
                                    maxLength={AppConstants.profileNameMaxLength}
                                    changeCriteria="blur"
                                    onChange={onNameChange}
                                />
                            </Text>
                            <Clickable
                                allowClickThrough={false}
                                onClick={() => onEditProfile(profile._id)}
                            >
                                <Stack vertical spacing="quart">
                                    <Text color="grey7" whiteSpace="nowrap">
                                        {t.scenarioNamesGROUP[profile.scenario.scenarioId]}
                                    </Text>
                                    {!isValidForRecordingSolution && (
                                        <IconText
                                            testId="warning_text_project_overview"
                                            icon="warning"
                                            iconProps={{ color: 'yellow5', size: 'sm' }}
                                            textProps={{ color: 'grey7' }}
                                        >
                                            {t.profileNotCompatibleWithRecordingSolution}
                                        </IconText>
                                    )}
                                </Stack>
                            </Clickable>
                        </Stack>
                    </Stack>
                </Clickable>

                <Stack justifyContent="end" alignItems="center">
                    <Text
                        color={usedBy ? 'green' : 'grey4'}
                        align="right"
                        whiteSpace="nowrap"
                        testId={`${profileName}_${t.cameraCount(usedBy)}`}
                    >
                        {usedBy
                            ? usedBy === 1
                                ? t.cameraCountSingular(usedBy)
                                : t.cameraCount(usedBy)
                            : t.notUsed}
                    </Text>
                    {isDefaultProfile && <Icon opaque icon="star" color="blue" />}
                    {!isDefaultProfile && (
                        <IconButton opaque icon="star_border" onClick={onSetDefaultProfile} />
                    )}
                    <DropDown
                        trigger={<Icon icon="more_vert" color="grey6" />}
                        contents={[
                            <DropDownMenuButton
                                icon="edit"
                                label={t.profileEditProfile}
                                onClick={() => onEditProfile(profile._id)}
                            />,
                            <DropDownMenuButton
                                icon="star"
                                label={t.profilesMakeDefault}
                                onClick={onSetDefaultProfile}
                                disabled={isDefaultProfile}
                            />,
                            <DropDownMenuButton
                                icon="content_copy"
                                label={t.duplicate}
                                onClick={onDuplicateProfile}
                            />,
                            <DropDownMenuDelete
                                onDelete={onDeleteProfile}
                                disabled={isDefaultProfile || (usedBy ? usedBy > 0 : false)}
                            />,
                        ]}
                    />
                </Stack>
            </Stack>
        </Box>
    );
};
