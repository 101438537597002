import { ServiceLocator } from 'app/ioc';
import { ModalService } from 'app/modal';
import { t } from 'app/translate';

const localStorageKeys = {
    Asd1Data: '_LS_' as const,
    DebugMode: 'AED_DEBUG_MODE' as const,
    ServiceWorkersDisabled: 'AED_DISABLE_SERVICE_WORKERS' as const,
    EulaAccepted: 'AED_EULA_ACCEPTED' as const,
    EulaAcceptedVersion: 'AED_EULA_ACCEPTED_VERSION' as const,
    FeatureFlagsOff: 'AED_FEATURES_OFF_ON_DEV' as const,
    NeedHelpShown: 'AED_HAS_SHOWN_NEED_HELP' as const,
    LatestProjectView: 'AED_LAST_PROJECT_VIEW' as const,
    MapsHelpVideoShownVersion: 'AED_MAPS_HELP_VIDEO_VERSION_SHOWN' as const,
    ReleaseNotesAccepted: 'AED_RELEASE_NOTES_ACCEPTED' as const,
    ReleaseNotesShown: 'AED_RELEASE_NOTES_OPENED' as const,
    ReleaseVersion: 'AED_RELEASE_VERSION' as const,
    StorageVersion: 'AED_STORAGE_VERSION' as const,
    LatestSyncInfo: 'AED_SYNC_INFO_LS_KEY' as const,
    UserPreferences: 'AED_USER_PREFERENCES' as const,
    WelcomeShown: 'AED_WELCOME_FINISHED' as const,
    TermsAndConditionsAccepted: 'GEN_TERMS_AND_CONDITIONS_ACCEPTED' as const,
    IpRangesExpanded: 'IpRangesExpanded' as const,
    RecentlyAddedProducts: 'PreviouslyAddedProducts' as const,
    ReduxDebugLogger: 'reduxlogger' as const,
    SharedProjectInfo: 'SHARED_PROJECT_INFO' as const,
    ShowNotificationBanner: 'ShowNotificationBanner' as const,
} as const;

export type LocalStorageKey = keyof typeof localStorageKeys;

/** Retrieves JSON item from local storage and parses it */
export const getItemLocalStorageJSON = <T>(key: LocalStorageKey, defaultValue: T) => {
    return JSON.parse(getItemLocalStorage(key) || JSON.stringify(defaultValue)) as T;
};

/** Retrieves item from local storage */
export const getItemLocalStorage = <T>(key: LocalStorageKey) => {
    return localStorage.getItem(localStorageKeys[key]) as T;
};

/** Stringifies JSON item and stores it in local storage */
export const setItemLocalStorageJSON = async (key: LocalStorageKey, value: any) => {
    setItemLocalStorage(key, JSON.stringify(value));
};

/** Sets item in local storage */
export const setItemLocalStorage = async (key: LocalStorageKey, value: any) => {
    const modalService = ServiceLocator.get(ModalService);
    try {
        localStorage.setItem(localStorageKeys[key], value);
    } catch (error) {
        await modalService.createConfirmDialog({
            header: t.errorBoundaryHeader,
            body: t.applicationQuotaExceededError,
            confirmButtonText: t.close,
            warning: true,
        })();
    }
};

/** Removes item from local storage */
export const removeItemLocalStorage = (key: LocalStorageKey) => {
    localStorage.removeItem(localStorageKeys[key]);
};
