import { injectable } from 'inversify';
import {
    CreateEntityService,
    defaultDoorControllerFilter,
    IAccessoryItemEntity,
    IEnvironmentItemEntity,
    Id,
    IDoorControllerItemEntity,
    IDoorItemEntity,
    IItemRelationEntity,
    IPacItemEntity,
    defaultPacFilter,
} from 'app/core/persistence';
import {
    ImportedDoor,
    ImportedItemBase,
    ImportedPac,
    ImportedPiaProduct,
} from '../../../models/ImportedProjectSettingTypes';
import { mapToItemEntity, getImportedAccessories, getDevicePaletteColor } from './utils';
import { createRelationEntity } from './utils/createRelationEntity';
import { PiaDevicesService } from 'app/modules/common';
import { PiaItemPacCategory } from 'app/core/pia';
import { defaultColors } from 'app/core/common';

@injectable()
export class PacImporterService {
    constructor(
        private createEntityService: CreateEntityService,
        private piaDeviceService: PiaDevicesService,
    ) {}

    public import(
        projectId: Id,
        pacs: ImportedPac[],
    ): (
        | IPacItemEntity
        | IDoorControllerItemEntity
        | IDoorItemEntity
        | IAccessoryItemEntity
        | IEnvironmentItemEntity
        | IItemRelationEntity
    )[] {
        const {
            pacs: pacEntities,
            doorControllers: doorControllerEntities,
            doorsAndAccessories: entryEntities,
        } = pacs.reduce(
            (entities, pac) => {
                if (this.isDoorController(pac)) {
                    const doorControllerEntity = this.mapToDoorController(projectId, pac);
                    entities.doorControllers.push(doorControllerEntity);
                    entities.doorsAndAccessories.push(
                        ...pac.doors.flatMap((door) =>
                            this.mapToDoorEntities(projectId, pac.id, door, pac.color),
                        ),
                    );

                    return entities;
                }

                entities.pacs.push(this.mapToPac(projectId, pac));
                return entities;
            },
            {
                pacs: [] as IPacItemEntity[],
                doorControllers: [] as IDoorControllerItemEntity[],
                doorsAndAccessories: [] as (
                    | IDoorItemEntity
                    | IAccessoryItemEntity
                    | IItemRelationEntity
                )[],
            },
        );

        const accessoryEntities = getImportedAccessories(projectId, pacs);
        return [...pacEntities, ...doorControllerEntities, ...entryEntities, ...accessoryEntities];
    }

    private mapToPac(projectId: Id, pac: ImportedPac): IPacItemEntity {
        const itemEntity = mapToItemEntity(projectId, pac, pac.piaId);
        return {
            ...itemEntity,
            properties: {
                pac: { filter: defaultPacFilter },
            },
        };
    }

    private mapToDoorController(projectId: Id, pac: ImportedPac): IDoorControllerItemEntity {
        const itemEntity = mapToItemEntity(projectId, pac, pac.piaId);
        return {
            ...itemEntity,
            properties: {
                doorController: { filter: defaultDoorControllerFilter },
            },
        };
    }

    private mapToDoorEntities(
        projectId: Id,
        doorControllerId: Id,
        door: ImportedDoor,
        color?: string,
    ): (IDoorItemEntity | IAccessoryItemEntity | IItemRelationEntity)[] {
        const doorItemEntity = {
            ...mapToItemEntity(projectId, door),
            path: [projectId, doorControllerId, door.id],
            color: getDevicePaletteColor(color) ?? defaultColors.DEFAULT_DOOR_CONTROLLER_COLOR,
            properties: {
                door: {
                    nbrOfLocks: door.nbrOfLocks,
                },
            },
        } satisfies IDoorItemEntity;

        const doorRelationId = this.createEntityService.generateDatabaseId('itemRelation');
        const doorRelationEntity = createRelationEntity(
            doorRelationId,
            [projectId, doorControllerId],
            door.id,
            'door',
        );

        const sideAReaderEntities = door.sideA.readers?.flatMap((reader) => {
            return this.getReaderAndRelationEntities(
                projectId,
                doorControllerId,
                door.id,
                reader,
                doorItemEntity,
                'doorSideA',
            );
        });
        const sideBReadersEntities = door.sideB.readers?.flatMap((reader) => {
            return this.getReaderAndRelationEntities(
                projectId,
                doorControllerId,
                door.id,
                reader,
                doorItemEntity,
                'doorSideB',
            );
        });

        const sideARexEntity = door.sideA.rex
            ? this.getRexAndRelationEntities(projectId, doorControllerId, door.id, 'doorSideA')
            : undefined;
        const sideBRexEntity = door.sideB.rex
            ? this.getRexAndRelationEntities(projectId, doorControllerId, door.id, 'doorSideB')
            : undefined;

        return [
            doorItemEntity,
            doorRelationEntity,
            ...(sideAReaderEntities ?? []),
            ...(sideBReadersEntities ?? []),
            ...(sideARexEntity ?? []),
            ...(sideBRexEntity ?? []),
        ];
    }

    private getReaderAndRelationEntities(
        projectId: Id,
        doorControllerId: Id,
        doorId: Id,
        reader: ImportedItemBase & ImportedPiaProduct,
        doorItemEntity: IDoorItemEntity,
        relationType: 'doorSideA' | 'doorSideB',
    ): (IAccessoryItemEntity | IItemRelationEntity)[] {
        const readerEntity = this.mapToReaderEntities(projectId, doorControllerId, doorId, reader);
        const relationEntityId = this.createEntityService.generateDatabaseId('itemRelation');
        const relationEntity = createRelationEntity(
            relationEntityId,
            doorItemEntity.path,
            readerEntity.path[readerEntity.path.length - 1],
            relationType,
        );
        return [readerEntity, relationEntity];
    }

    private mapToReaderEntities(
        projectId: Id,
        doorControllerId: Id,
        doorId: Id,
        reader: ImportedItemBase & ImportedPiaProduct,
    ): IAccessoryItemEntity {
        const readerId = this.createEntityService.generateDatabaseId('item');
        const readerEntity = {
            ...mapToItemEntity(projectId, reader, reader.piaId),
            path: [projectId, doorControllerId, doorId, readerId],
            properties: {
                accessory: {
                    category: 'readers',
                },
            },
        } satisfies IAccessoryItemEntity;
        return readerEntity;
    }

    private getRexAndRelationEntities(
        projectId: Id,
        doorControllerId: Id,
        doorId: Id,
        relationType: 'doorSideA' | 'doorSideB',
    ) {
        const rexId = this.createEntityService.generateDatabaseId('item');
        const rexEntity = {
            name: '',
            description: '',
            notes: '',
            productId: null,
            quantity: 1,
            archived: false,
            type: 'item',
            path: [projectId, doorControllerId, doorId, rexId],
            properties: {
                accessory: {
                    category: 'rex',
                },
            },
        } satisfies IAccessoryItemEntity;

        const relationEntityId = this.createEntityService.generateDatabaseId('itemRelation');
        const relationEntity = createRelationEntity(
            relationEntityId,
            [projectId, doorControllerId, doorId],
            rexEntity.path[rexEntity.path.length - 1],
            relationType,
        );
        return [rexEntity, relationEntity];
    }

    private isDoorController(pac: ImportedPac): pac is ImportedPac & { doors: ImportedDoor[] } {
        const piaDevice = this.piaDeviceService.getPiaDevice(pac.piaId);
        return piaDevice?.categories.includes(PiaItemPacCategory.DOORCONTROLLERS) ?? false;
    }
}
