import React from 'react';

/**
 * Hook to determine if the mouse is hovering over an element.
 * On a touch device, the hover is triggered by a touch move event.
 */
export const useMouseHover = (ref: React.RefObject<HTMLElement>) => {
    const [isHovering, setIsHovering] = React.useState(false);

    React.useEffect(() => {
        const element = ref.current;
        if (!element) {
            return;
        }

        const onMouseEnter = () => setIsHovering(true);

        /**
         * Delay the mouse leave event to prevent flickering when moving the mouse between element.
         */
        const onMouseLeave = () => {
            const timer = setTimeout(() => setIsHovering(false), 250);
            element.addEventListener('mouseover', () => clearTimeout(timer), { once: true });
        };

        element.addEventListener('mouseover', onMouseEnter);
        element.addEventListener('touchstart', onMouseEnter);
        element.addEventListener('mouseout', onMouseLeave);

        return () => {
            element.removeEventListener('mouseover', onMouseEnter);
            element.removeEventListener('mouseout', onMouseLeave);
            element.removeEventListener('touchstart', onMouseEnter);
        };
    }, [ref]);

    return isHovering;
};
