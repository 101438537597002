import * as React from 'react';
import { useService } from 'app/ioc';
import { Box, Card, IconText, Text } from 'app/components';
import { UserProjectsContentHeader } from './components';
import { t } from 'app/translate';
import { ProjectDbOriginAsdUserData } from 'app/core/persistence';
import { getIsSeriousError } from 'app/modules/common';
import { useSelector } from 'react-redux';
import { UserProjectsActionService } from './services';
import { ProjectsList } from '../components';
import type { IStoreState } from 'app/store';
import { getError, getLoaded } from '../selectors';
import { useMount } from 'app/hooks';
import { ApplicationActionService } from 'app/modules/application';
import { getIsUserProjectLocalDbReady } from './selectors';

interface IUserProjectsProps {}

export const UserProjects: React.FunctionComponent<IUserProjectsProps> = () => {
    const userProjectsActionService = useService(UserProjectsActionService);
    const hasCouchDBCompactSeriousError = useSelector(getIsSeriousError);
    const loaded = useSelector((state: IStoreState) => getLoaded(state, 'userProjects'));
    const error = useSelector((state: IStoreState) => getError(state, 'userProjects'));
    const applicationActionService = useService(ApplicationActionService);
    const isLocalDbReady = useSelector(getIsUserProjectLocalDbReady);

    useMount(() => {
        applicationActionService.setUserPreferences({
            selectedView: 'userprojects',
        });
    });

    React.useEffect(() => {
        if (isLocalDbReady) {
            userProjectsActionService.syncUnarchived();
        }
    }, [isLocalDbReady, userProjectsActionService]);

    React.useEffect(() => {
        userProjectsActionService.changeProjectDbOrigin(ProjectDbOriginAsdUserData);
        userProjectsActionService.clearSelection();
    }, [userProjectsActionService]);

    if (hasCouchDBCompactSeriousError) {
        return (
            <Box justifyContent="center" paddingTop="doublePanel" maxWidth="80%">
                <Card borderColor="red" color="white" paddingX="panel" paddingY="panel">
                    <Box direction="column" spacing="base" alignItems="center">
                        <IconText
                            icon="error"
                            iconProps={{ opaque: true, color: 'red', size: 'lg' }}
                            textProps={{ style: 'title' }}
                        >
                            {t.inaccessibleDatabase}
                        </IconText>
                        <Box direction="column" spacing="half">
                            <Text style="semibold" color="red">
                                {t.seriousErrorMessage1}
                            </Text>
                            <Text style="semibold" color="red">
                                {t.seriousErrorMessage2}
                            </Text>
                            <Text style="semibold" color="red"></Text>
                        </Box>
                    </Box>
                </Card>
            </Box>
        );
    }

    return (
        <Box direction="column" spacing="base">
            <UserProjectsContentHeader />
            <Box paddingX="base">
                <ProjectsList
                    actionService={userProjectsActionService}
                    state="userProjects"
                    loaded={loaded}
                    error={error}
                />
            </Box>
        </Box>
    );
};
