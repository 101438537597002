import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isDefined } from 'axis-webtools-util';
import type { IStoreState } from 'app/store';
import type { IIdRevModel, IProjectEntity, IProjectNetworkSettings } from 'app/core/persistence';
import {
    ProjectZipType,
    deviceTypeCheckers,
    getParentId,
    DistanceUnit,
    QuotationProgress,
    getDefaultCameraFilterEntity,
    getDefaultSpeakerFilterEntity,
    ProjectDbOriginAsdLocalUserData,
} from 'app/core/persistence';

import { Frequency } from '../../models';
import { createCachedSelector } from 're-reselect';
import { getIdFromProps } from '../../selectors/getIdFromProps';
import {
    getCountryFromCountryCode,
    getCurrencyFromCountryCode,
    getLocaleFromCountryCode,
} from '../../prices/countries';
import { toCacheKey } from '../../cacheKey/toCacheKey';
import { creationDateReverseComparator } from 'app/utils';

const getCurrentProjectState = (state: IStoreState) => state.currentProject;
const getLocationsState = (state: IStoreState) => state.common.locations;
export const getCurrentProject = createSelector(
    [getCurrentProjectState],
    // We can set the project here as non-null because we have a guard in `Project.container.tsx`
    // that prevents the project from showing if the project state is null
    (project) => project.project!,
);

export function useProjectName(): string {
    return useSelector<IStoreState, IProjectEntity>((state) => getCurrentProject(state)).name;
}

export const getCurrentProjectQuotation = createSelector(
    [getCurrentProjectState],
    ({ quotations }) => {
        return quotations;
    },
);

export const getCurrentProjectQuotationProgress = createSelector(
    [getCurrentProjectState],
    (project) => project.project?.state ?? QuotationProgress.Designing,
);

export const getCurrentProjectId = createSelector([getCurrentProject], (project) => project?._id);

export const getLocationId = createSelector(
    [getCurrentProject],
    (project) => project?.installationPiaLocationId,
);

export const getCurrentProjectLocation = createSelector(
    [getLocationsState, getLocationId],
    (locations, locationId) => locations.find(({ id }) => id === locationId),
);

export const getCurrentProjectLocationName = createSelector(
    [getCurrentProject],
    (project) => project?.locationName,
);

export const getCurrentProjectRegions = createSelector(
    [getCurrentProject, getLocationsState],
    (project, locations) => {
        if (project === null) {
            return [];
        }

        const location = locations.find((l) => l.id === project.installationPiaLocationId);
        return location?.productVersions ?? [];
    },
);

export const getCurrentProjectItems = createSelector(
    [getCurrentProjectState],
    (project) => project.items,
);

export const getCurrentProjectPartnerItems = createSelector(
    [getCurrentProjectState],
    (project) => project.partnerItems,
);

export const getCurrentProjectPartnerApplicationItemsArray = createSelector(
    [getCurrentProjectPartnerItems],
    (items) =>
        Object.values(items)
            .filter(isDefined)
            .filter((item) => item.properties.application),
);

export const getCurrentProjectItem = createCachedSelector(
    [getCurrentProjectItems, getIdFromProps],
    (currentProjectItems, itemId) => {
        return itemId ? currentProjectItems[itemId] : undefined;
    },
)(toCacheKey);

export const getCurrentProjectItemProductId = createCachedSelector(
    [getCurrentProjectItems, getIdFromProps],
    (currentProjectItems, itemId) => {
        return itemId ? currentProjectItems[itemId]?.productId : undefined;
    },
)(toCacheKey);

export const getShouldHaveScenarioTab = createCachedSelector([getCurrentProjectItem], (item) => {
    return (
        !!item &&
        (deviceTypeCheckers.isAnalogCamera(item) ||
            deviceTypeCheckers.isCamera(item) ||
            deviceTypeCheckers.isSensorUnit(item))
    );
})(toCacheKey);

export const getShouldHaveWearableScenarioTab = createCachedSelector(
    [getCurrentProjectItem],
    (item) => {
        return !!item && deviceTypeCheckers.isBodyWornCamera(item);
    },
)(toCacheKey);

export const getCurrentProjectItemParent = createCachedSelector(
    [getCurrentProjectItem, getCurrentProjectItems],
    (item, currentProjectItems) => {
        const parentInPath = item ? getParentId(item) : undefined;
        return parentInPath && parentInPath.startsWith('item:')
            ? currentProjectItems[parentInPath]
            : undefined;
    },
)(toCacheKey);

export const getCurrentProjectItemRelations = createSelector(
    [getCurrentProjectState],
    (project) => project.itemRelations,
);

export const getCurrentProjectSchedules = createSelector(
    [getCurrentProjectState],
    (project) => project.schedules,
);

export const getCurrentProjectProfiles = createSelector(
    [getCurrentProjectState],
    (project) => project.profiles,
);

export const getCurrentProjectFloorPlans = createSelector(
    [getCurrentProjectState],
    (project) => project.floorPlans,
);

export const getCurrentProjectBlockers = createSelector(
    [getCurrentProjectState],
    (project) => project.blockers,
);

export const getCurrentProjectBlockersArray = createSelector(
    [getCurrentProjectBlockers],
    (blockers) => Object.values(blockers).filter(isDefined),
);

export const getCurrentProjectFloorPlan = createCachedSelector(
    [getCurrentProjectState, getIdFromProps],
    (project, floorPlanId) => (floorPlanId ? project.floorPlans[floorPlanId] : undefined),
)(toCacheKey);

export const getCurrentProjectInstallationPoints = createSelector(
    [getCurrentProjectState],
    (project) => project.installationPoints,
);

export const getCurrentProjectFreeTextPoints = createSelector(
    [getCurrentProjectState],
    (project) => project.freeTextPoints,
);

export const getCurrentProjectHasFloorPlans = createSelector(
    [getCurrentProjectFloorPlans],
    (floorPlans) => Object.keys(floorPlans).length > 0,
);

export const getCurrentProjectHasOnlyDefaultMap = createSelector(
    [getCurrentProjectFloorPlans],
    (floorPlans) =>
        Object.values(floorPlans).filter((floorPlan) => floorPlan?.isDefault).length === 0,
);

export const getCurrentProjectProfileIds = createSelector([getCurrentProjectProfiles], (profiles) =>
    Object.keys(profiles),
);

export const getCurrentProjectTimeSeries = createSelector(
    [getCurrentProjectState],
    (project) => project.timeSeries,
);

export const getCurrentProjectCurrency = createSelector([getCurrentProjectLocation], (location) =>
    getCurrencyFromCountryCode(location?.countryCode),
);

export const getCurrentProjectLocale = createSelector([getCurrentProjectLocation], (location) =>
    getLocaleFromCountryCode(location?.countryCode),
);

export const getCurrentProjectCountryCode = createSelector(
    [getCurrentProjectLocation],
    (location): string | undefined => getCountryFromCountryCode(location?.countryCode)?.countryCode,
);

export const getCurrentProjectInstallationLocation = createSelector(
    [getCurrentProject],
    (currentProject) => currentProject.location,
);

export const getCurrentProjectFrequency = createSelector([getCurrentProjectLocation], (location) =>
    location?.frequency60Hz ? Frequency.Hz60 : Frequency.Hz50,
);

export const getCurrentProjectBandwidthVersion = createSelector([getCurrentProject], (project) =>
    project ? project.bandwidthVersion : 2,
);

export const getCurrentProjectItemsArray = createSelector([getCurrentProjectItems], (items) =>
    Object.values(items).filter(isDefined),
);

/**
 * Get items that are direct children on the project based on path.
 *
 * For example if the item's id is 2 and project's id is 1 and the item's path is [1, 2] this will be included
 * but not if the item's path was [1, 3, 2] because it is no longer direct child of the project.
 */
export const getCurrentProjectDirectChildItems = createSelector(
    [getCurrentProject, getCurrentProjectItemsArray],
    (project, items) => {
        if (!project) {
            return [];
        }

        return items.filter((item) => {
            // An entity always has itself as last id in the path array.
            return getParentId(item) === project._id;
        });
    },
);

export const getCurrentProjectName = createSelector([getCurrentProject], (project) => {
    return project ? project.name : '';
});

export const getCurrentProjectNotes = createSelector([getCurrentProject], (project) => {
    return project ? project.notes : '';
});

export const getCurrentProjectInstallationNotes = createSelector(
    [getCurrentProject],
    (project): string => {
        return project?.installationReportNotes ?? '';
    },
);

export const getCurrentProjectUnitSystem = createSelector([getCurrentProject], (project) => {
    return project ? project.unitSystem : 'metric';
});

export const getCurrentProjectTemperatureScale = createSelector([getCurrentProject], (project) => {
    return project ? project.temperatureScale : 'celsius';
});

export const getCurrentProjectProjectZipSetting = createSelector([getCurrentProject], (project) => {
    return project ? project.projectZipSetting : ProjectZipType.off;
});

export const getCurrentProjectLocked = createSelector([getCurrentProject], (project) => {
    return Boolean(project?.locked);
});

export const getDefaultProfileId = createSelector([getCurrentProject], (project) =>
    project ? project.defaultProfile : undefined,
);

export const getCurrentProjectIdRev = createSelector([getCurrentProject], (project) => {
    return project ? ({ id: project._id, rev: project._rev } as IIdRevModel) : undefined;
});

export const getCurrentProjectDisplayUnit = createSelector(
    [getCurrentProjectUnitSystem],
    (unitSystem) => {
        return unitSystem === 'metric' ? DistanceUnit.Meter : DistanceUnit.Feet;
    },
);

export const getCurrentProjectProfilesArray = createSelector([getCurrentProjectState], (project) =>
    Object.values(project.profiles).filter(isDefined),
);

export const getCurrentProjectTimeSeriesArray = createSelector(
    [getCurrentProjectState],
    (project) => Object.values(project.timeSeries).filter(isDefined),
);

export const getCurrentProjectSchedulesArray = createSelector([getCurrentProjectState], (project) =>
    Object.values(project.schedules).filter(isDefined),
);

export const getCurrentProjectFloorPlansArray = createSelector(
    [getCurrentProjectFloorPlans],
    (floorPlans) => Object.values(floorPlans).filter(isDefined),
);

export const getCurrentProjectItemRelationsArray = createSelector(
    [getCurrentProjectItemRelations],
    (itemRelations) => Object.values(itemRelations).filter(isDefined),
);

export const getCurrentProjectInstallationPointsArray = createSelector(
    [getCurrentProjectInstallationPoints],
    (installationPoints) => Object.values(installationPoints).filter(isDefined),
);

export const getCurrentProjectFreeTextPointsArray = createSelector(
    [getCurrentProjectFreeTextPoints],
    (ftps) => Object.values(ftps).filter(isDefined),
);

/**
 * Selector that retrieves the project's custom default installation height.
 * The user can alter this value through the project settings menu.
 */
export const getCurrentProjectCustomInstallationHeight = createSelector(
    [getCurrentProject],
    (project) => {
        const { customInstallationHeight } = project;
        return customInstallationHeight;
    },
);

/**
 * Selector that retrieves the project's default camera filter,
 * taking the user's chosen default installation height into account.
 */
export const getCurrentProjectDefaultCameraFilter = createSelector(
    [getCurrentProjectCustomInstallationHeight, getCurrentProjectUnitSystem],
    (customInstallationHeight, unitSystem) =>
        getDefaultCameraFilterEntity(unitSystem, customInstallationHeight),
);

/**
 * Selector that retrieves the project's default speaker filter,
 * taking the user's chosen default installation height into account.
 */
export const getCurrentProjectDefaultSpeakerFilter = createSelector(
    [getCurrentProjectCustomInstallationHeight],
    (customInstallationHeight) => getDefaultSpeakerFilterEntity(customInstallationHeight),
);

export const getCurrentProjectCustomCameras = createSelector(
    [getCurrentProjectItems],
    (itemsRecord) => {
        const itemArray = Object.values(itemsRecord)
            .map((item) => item)
            .filter(isDefined);
        return itemArray.filter(
            (item) => item.properties.camera?.customCameraProperties?.activated,
        );
    },
);

export const getCurrentProjectNetworkSettings = createSelector(
    [getCurrentProject],
    (project): IProjectNetworkSettings | undefined => project.networkSettings,
);

export const getCurrentProjectCreationDate = createSelector(
    [getCurrentProject],
    // We can set the project here as non-null because we have a guard in `Project.container.tsx`
    // that prevents the project from showing if the project state is null
    (project) => new Date(project.creationDate),
);

export const getIsLocalProject = createSelector([getCurrentProject], (project): boolean => {
    if (!project) throw new Error('getIsLocalProject - Project can not be null');
    return project.projectDbOrigin === ProjectDbOriginAsdLocalUserData;
});

export const getCurrentProjectMapLocations = createSelector(
    [getCurrentProjectState],
    (currentProjectState) => {
        return currentProjectState.mapLocations;
    },
);

export const getCurrentProjectMapLocationsArray = createSelector(
    [getCurrentProjectMapLocations],
    (locations) => {
        return Object.values(locations).filter(isDefined).sort(creationDateReverseComparator);
    },
);

export const getCurrentProjectRetentionTime = createSelector([getCurrentProject], (project) => {
    return project?.recordingRetentionTimeInDays ?? 0;
});
