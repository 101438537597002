import type { IStoreState, ProjectStoreState } from 'app/store';

export const getMigrationProgress = (state: IStoreState, property: ProjectStoreState) => {
    if (property === 'sharedProjects') {
        return state.app.sharedProjectsMigrationState.progress;
    }

    if (property === 'userProjects') {
        return state.app.migrationState.progress;
    }

    return 0;
};
