import { injectable } from 'inversify';
import PouchDB from 'pouchdb-browser';
import { UserDataStorageCommunicator } from './UserDataStorage.communicator';
import { ConflictResolutionService } from './ConflictResolution.service';
import { OfflineService } from '../../services/Offline.service';
import { SyncService } from '../../services/Sync.service';
import { MigrationService, MigrationUnsupportedVersionError } from '../migration';
import { PersistenceDatabaseRepository } from '../repositories/persistence/PersistenceDatabase.repository';
import { EventEmitter } from 'events';
import {
    X_AXIS_USER_API_AUTH,
    UserApiCommunicator,
} from '../../services/user/services/UserApi.communicator';
import { EVENT_PROGRESS, EVENT_UPDATE_AVAILABLE } from './constants';
import type { Id } from '../entities';
import { EventEmitterService } from '../../services/EventEmitter.service';

type Event = 'updateAvailable' | 'progress';

// How many document to process at the same time
const BATCH_SIZE = 700;
// Special PouchDB error message
const POUCHDB_DESTROYED_ERROR_MESSAGE = 'database is destroyed';

/**
 * This selector ensures that the replication process includes both "archived=false"
 * records and those lacking the archived flag. This guarantees that we sync non-migrated
 * records so they can be migrated properly.
 */
const UNARCHIVED_ONLY = {
    $or: [
        {
            archived: false,
        },
        {
            archived: {
                $exists: false,
            },
            _deleted: {
                $exists: false,
            },
        },
    ],
};

@injectable()
export class ReplicationService {
    private localDb: PouchDB.Database | null = null;
    private remoteDb: PouchDB.Database | null = null;
    private emitter: EventEmitter;
    private replication: PouchDB.Replication.Replication<object> | null = null;
    private syncInbound: PouchDB.Replication.Replication<object> | null = null;
    private syncOutbound: PouchDB.Replication.Replication<object> | null = null;
    private replicationPromise: Promise<void> | null = null;
    private replicating = false;
    private incomingChangeRevs = new Set<string>();

    constructor(
        private conflictResolutionService: ConflictResolutionService,
        private userDataStorageCommunicator: UserDataStorageCommunicator,
        private offlineService: OfflineService,
        private syncService: SyncService,
        private migrationService: MigrationService,
        private persistenceDatabaseRepository: PersistenceDatabaseRepository,
        private userApiCommunicator: UserApiCommunicator,
        private eventEmitterService: EventEmitterService,
    ) {
        // Default to userprojects emitter
        this.emitter = this.eventEmitterService.getEventEmitter('userprojects');
    }

    public async setupReplication(localDb: PouchDB.Database): Promise<void> {
        this.localDb = localDb;
        await this.triggerMigration();

        // By default EventEmitters will print a warning if more
        // than 10 listeners are added for a particular event.
        // We currently need 14 though.
        localDb.setMaxListeners(14);
        localDb
            .changes({
                since: 'now',
                live: true,
            })
            .on('change', (info) => {
                // check if changed originated locally or was synced from remote
                if (
                    !this.replicating &&
                    !info.changes.every(({ rev }) => this.incomingChangeRevs.has(rev))
                ) {
                    // local change
                    this.onLocalChange();
                }

                // clear synced change revs
                info.changes.forEach(({ rev }) => this.incomingChangeRevs.delete(rev));
            });

        this.conflictResolutionService.setupConflictResolution(this.localDb);

        if (await this.offlineService.isOnline()) {
            await this.initializeRemoteStorage();
        } else {
            this.offlineService.once('online', () => this.initializeRemoteStorage());
        }
    }

    public pauseReplication() {
        this.cancelReplication();
    }

    public resumeReplication() {
        this.syncUnarchived();
    }

    public isChangesReplicated(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (!this.localDb || !this.remoteDb) {
                return resolve(false);
            }

            this.localDb.replicate
                .to(this.remoteDb, {
                    batch_size: BATCH_SIZE,
                    checkpoint: 'source',
                })
                .on('complete', () => {
                    resolve(true);
                })
                .on('error', () => {
                    resolve(false);
                });
        });
    }

    public on<T>(event: Event, fn: (arg: T) => any) {
        this.emitter.on(event, fn);
    }

    public once<T>(event: Event, fn: (arg: T) => any) {
        this.emitter.once(event, fn);
    }

    public off(event: Event, fn: () => any) {
        this.emitter.removeListener(event, fn);
    }

    public waitForReplication(): Promise<void> {
        return this.replicationPromise ?? Promise.resolve();
    }

    public async syncAll(): Promise<void> {
        if (!this.localDb || !this.remoteDb) {
            return;
        }

        await this.replicate(this.localDb, this.remoteDb);

        this.sync(this.localDb, this.remoteDb);
    }

    public async syncUnarchived(): Promise<void> {
        if (!this.localDb || !this.remoteDb) {
            return;
        }

        // This will generate an error after a compaction if app was not reloaded.
        // It is generated as an CustomPouchError but we are unfortunately not able to catch it as one,
        // since it is lacking name amongst others.
        // We need to handle it and reload the applications since the PouchDB database was destroyed in the compaction process.
        try {
            await this.replicate(this.localDb, this.remoteDb, UNARCHIVED_ONLY);
        } catch (e) {
            if (e instanceof Error && e.message === POUCHDB_DESTROYED_ERROR_MESSAGE) {
                window.location.reload();
            }
        }

        const syncSelector = {
            $or: [
                {
                    type: 'project',
                },
                {
                    ...UNARCHIVED_ONLY,
                },
            ],
        };

        this.sync(this.localDb, this.remoteDb, syncSelector);
    }

    public cancelReplication() {
        this.replication?.cancel();
    }

    public async syncProjectAndUserSettings(
        projectId: Id,
        { replicate }: { replicate: boolean },
    ): Promise<void> {
        if (!this.localDb || !this.remoteDb) {
            return;
        }

        const localDb = this.localDb;
        const remoteDb = this.remoteDb;

        const selector = {
            $or: [
                {
                    type: 'userSettings',
                },
                {
                    'path.0': projectId,
                },
            ],
        };

        if (replicate) {
            await this.replicate(localDb, remoteDb, selector);
        }

        this.sync(localDb, remoteDb, selector);
    }

    private async initializeRemoteStorage(): Promise<void> {
        await this.initializeRemoteStorageUserData();
    }

    private async initializeRemoteStorageUserData(): Promise<void> {
        const remoteDbContext = await this.userDataStorageCommunicator.tryToGetUserDb();

        if (remoteDbContext) {
            const tokens = await this.userApiCommunicator.fetchUserToken();
            this.remoteDb = new PouchDB(remoteDbContext.storageUri, {
                fetch: function (url, opts) {
                    const requestOptions = { ...opts };
                    requestOptions.headers = new Headers(opts?.headers);
                    requestOptions.headers.set(
                        X_AXIS_USER_API_AUTH,
                        `Bearer ${tokens?.accessToken}`,
                    );
                    return PouchDB.fetch(url, requestOptions);
                },
            });
        }
    }

    private async replicate(
        localDb: PouchDB.Database,
        remoteDb: PouchDB.Database,
        selector?: PouchDB.Find.Selector,
    ): Promise<void> {
        this.replication?.cancel();
        this.replicating = true;
        this.replicationPromise = new Promise<void>((resolve, reject) => {
            this.replication = localDb.replicate
                .from(remoteDb, {
                    batch_size: BATCH_SIZE,
                    checkpoint: 'source',
                    selector,
                })
                .on('change', (info) => {
                    const progress = info.docs_written / (info.docs_written + info.pending);
                    this.emitter.emit(EVENT_PROGRESS, progress);
                })
                .on('complete', async () => {
                    resolve();
                })
                .on('denied', (err) => {
                    reject(err);
                })
                .on('error', (err) => {
                    reject(err);
                });
        })
            .then(() => this.afterReplication())
            .then(() => {
                this.replicating = false;
            });
        return this.replicationPromise;
    }

    private async sync(
        localDb: PouchDB.Database,
        remoteDb: PouchDB.Database,
        selector?: PouchDB.Find.Selector,
    ) {
        if (this.syncInbound) {
            this.syncInbound.cancel();
            this.syncInbound = null;
        }

        this.syncInbound = localDb.replicate
            .from(remoteDb, {
                batch_size: BATCH_SIZE,
                checkpoint: 'source',
                live: true,
                retry: true,
                selector,
            })
            .on('change', (info) => {
                // add incoming revs
                info.docs.forEach(({ _rev }) => this.incomingChangeRevs.add(_rev));

                this.afterReplication();
            });

        if (this.syncOutbound) {
            this.syncOutbound.cancel();
            this.syncOutbound = null;
        }

        // the outbound sync doesn't have a selector => every local change is synced
        // to couch
        this.syncOutbound = localDb.replicate
            .to(remoteDb, {
                batch_size: BATCH_SIZE,
                checkpoint: 'source',
                live: true,
                retry: true,
            })
            .on('change', () => {
                this.onChangesSynced();
            });
    }

    private afterReplication() {
        this.syncService.onReplicationCompleted();
        return this.triggerMigration();
    }

    private async triggerMigration() {
        try {
            await this.migrationService.migrateAll(this.persistenceDatabaseRepository);
            await this.persistenceDatabaseRepository.viewCleanup();
        } catch (e) {
            if (e instanceof MigrationUnsupportedVersionError) {
                this.emitUpdateAppEvent();
            } else {
                throw e;
            }
        }
    }

    private emitUpdateAppEvent() {
        this.emitter.emit(EVENT_UPDATE_AVAILABLE);
    }

    private onChangesSynced() {
        this.syncService.onSync();
    }

    private onLocalChange() {
        this.syncService.onLocalChange();
    }
}
