import { ServiceLocator } from 'app/ioc';
import type { ILensItemEntity, IItemRelationEntity, Id } from 'app/core/persistence';
import { CreateEntityService } from 'app/core/persistence';
import type { ImportedCamera, ImportedLens } from '../../../../models/ImportedProjectSettingTypes';
import { createRelationEntity } from './createRelationEntity';

type LensItem = ILensItemEntity | IItemRelationEntity;

/**
 * Creates a lens item entity.
 * @param ancestors The ancestors of the lens.
 * @param importedLens The imported lens.
 * @returns The created lens entity
 */
const createLens = (
    ancestors: Id[],
    { name, piaId, sensorIndex }: ImportedLens,
): ILensItemEntity => {
    const createEntityService = ServiceLocator.get(CreateEntityService);
    const id = createEntityService.generateDatabaseId('item');

    return {
        type: 'item',
        path: [...ancestors, id],
        name: name,
        description: '',
        notes: '',
        archived: false,
        productId: piaId,
        properties: {
            lens: {
                sensorIndex,
            },
        },
        quantity: 1,
    };
};

/**
 * Gets the imported lenses and their relations.
 * @param projectId The project id.
 * @param items The imported cameras.
 * @returns The imported lenses and their relations.
 */
export const getImportedLenses = (projectId: Id, items: ImportedCamera[]): LensItem[] => {
    const createEntityService = ServiceLocator.get(CreateEntityService);

    return items.flatMap((item) => {
        const itemId = item.id;
        const path = [projectId, itemId];

        return (item.lenses ?? []).flatMap((lens) => {
            const lensEntity = createLens(path, lens);
            const lensId = lensEntity.path[lensEntity.path.length - 1];

            const relationId = createEntityService.generateDatabaseId('itemRelation');
            const relation = createRelationEntity(relationId, path, lensId, 'lenses');

            return [lensEntity, relation];
        });
    });
};
