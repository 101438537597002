import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Stack, Toggle, ToggleItem, Box, Border, Text, Clickable } from 'app/components';
import type { IInstallationPointModel } from 'app/core/persistence';
import {
    isCamera,
    isSensorUnit,
    DistanceUnit,
    getDefaultCameraFilterEntity,
} from 'app/core/persistence';
import type { IPiaCamera } from 'app/core/pia';
import {
    DistanceRange,
    getCurrentProjectCustomInstallationHeight,
    getCurrentProjectDisplayUnit,
    calculateSensors,
} from 'app/modules/common';
import { ServiceLocator } from 'app/ioc';
import {
    getDerotatedSelectedInstallationPoint,
    getSelectedSensorId,
    getSensorCoverageAreaInfo,
} from '../../../../selectors';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { ViewDetails } from './ViewDetails.container';
import { clamp } from 'lodash-es';
import { MapsActionService } from '../../../../services/MapsAction.service';
import type { ISensorCoverageAreaInfo } from '../../../../models';

interface ISensorSettingsContainerStateProps {
    installationPoint?: IInstallationPointModel;
    piaCamera: IPiaCamera | null;
    displayUnit: DistanceUnit.Feet | DistanceUnit.Meter;
    installationHeight: number;
    sensorId: number;
    isSensorUnitCategory: boolean;
    sensorCoverageAreaInfo?: ISensorCoverageAreaInfo;
}

type ISensorSettingsContainerProps = ISensorSettingsContainerStateProps;

interface ISensorSettingsContainerState {
    targetHeight: number;
    targetDistance: number;
}

const mapStateToProps = (storeState: IStoreState): ISensorSettingsContainerStateProps => {
    const installationPoint = getDerotatedSelectedInstallationPoint(storeState);
    const piaCamera = installationPoint?.parentPiaDevice ?? null;
    if (piaCamera && !(isCamera(piaCamera) || isSensorUnit(piaCamera))) {
        throw new Error(`Unsupported device category ${piaCamera.category}`);
    }
    const displayUnit = getCurrentProjectDisplayUnit(storeState);
    const installationHeight = getCurrentProjectCustomInstallationHeight(storeState);
    const sensorCount = installationPoint?.sensors.length ?? 0;
    const selectedSensorId = clamp(getSelectedSensorId(storeState) || 1, 1, sensorCount);
    const sensorCoverageAreaInfo = getSensorCoverageAreaInfo(storeState, selectedSensorId);

    const isSensorUnitCategory = isSensorUnit(piaCamera);

    return {
        installationPoint,
        piaCamera,
        displayUnit,
        installationHeight,
        sensorId: selectedSensorId,
        isSensorUnitCategory,
        sensorCoverageAreaInfo,
    };
};

class SensorSettingsContainer extends React.Component<
    ISensorSettingsContainerProps,
    ISensorSettingsContainerState
> {
    private mapsActionService: MapsActionService;

    constructor(props: ISensorSettingsContainerProps) {
        super(props);

        const defaultFilterValues = getDefaultCameraFilterEntity(
            this.props.displayUnit === DistanceUnit.Meter ? 'metric' : 'imperial',
            this.props.installationHeight,
        );

        this.mapsActionService = ServiceLocator.get(MapsActionService);
        const currentSensor = props.installationPoint
            ? props.installationPoint.sensors.find(
                  (sensor) => sensor.sensorId === this.props.sensorId,
              )
            : undefined;

        this.state = {
            targetHeight: currentSensor
                ? currentSensor.target.height
                : defaultFilterValues.targetHeight,
            targetDistance: currentSensor
                ? currentSensor.target.distance
                : defaultFilterValues.distanceToTarget,
        };
    }

    public componentDidUpdate(prevProps: ISensorSettingsContainerProps) {
        if (!this.props.installationPoint || !prevProps.installationPoint) {
            return;
        }

        const prevSensor = prevProps.installationPoint.sensors.find(
            (sensor) => sensor.sensorId === prevProps.sensorId,
        );

        const currentSensor = this.props.installationPoint.sensors.find(
            (sensor) => sensor.sensorId === this.props.sensorId,
        );

        if (!currentSensor || !prevSensor) {
            return;
        }

        if (
            prevProps.installationPoint.height === this.props.installationPoint.height &&
            prevSensor.target.distance === currentSensor.target.distance &&
            prevSensor.target.height === currentSensor.target.height
        ) {
            return;
        }

        this.setState({
            targetHeight: currentSensor.target.height,
            targetDistance: currentSensor.target.distance,
        });
    }

    public render() {
        if (!this.props.installationPoint) {
            return null;
        }
        const currentSensor = this.props.installationPoint.sensors.find(
            (sensor) => sensor.sensorId === this.props.sensorId,
        );
        if (!currentSensor) {
            return null;
        }
        const maxHeight = this.props.displayUnit === DistanceUnit.Meter ? 50 : 150;
        const supportCorridor = this.props.piaCamera
            ? this.props.piaCamera.properties.corridorFormat
            : !this.props.installationPoint.parentDevice.properties.camera?.filter.panoramaMode;

        const hasMultipleSensors =
            this.props.piaCamera?.properties.panCameraType === 'Multidirectional' ||
            this.props.installationPoint?.sensors.length > 1;

        return (
            <Stack vertical>
                {hasMultipleSensors && (
                    <Box
                        display="flex"
                        direction="row"
                        flex="shrinkAndGrow"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {this.renderSensorMarkers(this.props.sensorId)}
                    </Box>
                )}
                {hasMultipleSensors && <ViewDetails />}
                <DistanceRange
                    testId="target_height_npt"
                    color={'blue'}
                    displayUnit={this.props.displayUnit}
                    max={maxHeight}
                    min={0}
                    step={0.1}
                    showValue
                    showValueInLabel
                    label={t.targetHeight}
                    onChange={(value) => this.onTargetHeightChange(value)}
                    value={this.state.targetHeight}
                    decimals={1}
                    changeCriteria="key"
                />
                {supportCorridor && (
                    <Box display="block">
                        <Toggle width="80%">
                            <ToggleItem
                                testId="normal_fov_tgl"
                                fluid
                                title={t.cameraSelectorFieldOfViewNormal}
                                onClick={() => this.toggleCorridor(false)}
                                active={!currentSensor.settings.corridorFormat}
                            ></ToggleItem>
                            <ToggleItem
                                testId="corridor_fov_tgl"
                                fluid
                                title={t.cameraSelectorFieldOfViewCorridor}
                                onClick={() => this.toggleCorridor(true)}
                                active={currentSensor.settings.corridorFormat}
                            ></ToggleItem>
                        </Toggle>
                    </Box>
                )}
            </Stack>
        );
    }

    private renderSensorMarkers = (sensorId: number) =>
        this.props.installationPoint?.sensors?.map((sensor) => {
            return (
                <Box key={sensor.sensorId} padding="halfQuart">
                    <Clickable
                        key={`on-sensor-clicked${sensor.sensorId}`}
                        onClick={() => this.selectCameraCone(sensor.sensorId)}
                    >
                        <Border
                            key={sensor.sensorId}
                            color={sensor.sensorId === sensorId ? 'blue' : 'grey5'}
                            width={2}
                            radius="50%"
                        >
                            <Box
                                key={sensor.sensorId}
                                color={sensor.sensorId === sensorId ? 'blue' : 'white'}
                                padding="halfCell"
                                width="24px"
                                height="24px"
                                alignItems="center"
                            >
                                <Text
                                    key={sensor.sensorId}
                                    style="semibold"
                                    color={sensor.sensorId === sensorId ? 'white' : 'grey5'}
                                >
                                    {sensor.sensorId}
                                </Text>
                            </Box>
                        </Border>
                    </Clickable>
                </Box>
            );
        });

    private selectCameraCone(sensorId: number) {
        if (!this.props.installationPoint) {
            return;
        }
        this.mapsActionService.selectMapItem(
            this.props.installationPoint,
            'installationPoint',
            sensorId,
        );
    }

    private onTargetHeightChange(value: number) {
        this.setState({
            targetHeight: value,
        });

        if (!this.props.installationPoint) {
            return;
        }

        const changedSensor = this.props.installationPoint.sensors.find(
            (sensor) => sensor.sensorId === this.props.sensorId,
        );

        if (!changedSensor) {
            return;
        }

        const sensors = calculateSensors(this.props.installationPoint.sensors, {
            ...changedSensor,
            target: {
                ...changedSensor.target,
                height: value,
            },
        });

        this.mapsActionService.setDerotatedDraftInstallationPoint({
            ...this.props.installationPoint,
            sensors,
        });

        this.mapsActionService.debouncedUpdateInstallationPoint({
            ...this.props.installationPoint,
            sensors,
        });
    }

    private toggleCorridor = (corridorFormat: boolean) => {
        if (this.props.installationPoint) {
            const newInstallationPoint = { ...this.props.installationPoint };

            const currentSensor = newInstallationPoint.sensors.find(
                (sensor) => sensor.sensorId === this.props.sensorId,
            );
            if (!currentSensor) {
                return;
            }
            if (corridorFormat !== currentSensor.settings.corridorFormat) {
                currentSensor.settings.corridorFormat = corridorFormat;
                eventTracking.logUserEvent(
                    'Maps',
                    'Change sensor format',
                    currentSensor.settings.corridorFormat ? 'Corridor' : 'Normal',
                );

                this.mapsActionService.updateInstallationPoint(newInstallationPoint);
            }
        }
    };
}

export const SensorSettings = connect(mapStateToProps)(SensorSettingsContainer);
