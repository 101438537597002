import { injectable } from 'inversify';
import type { Action, Dispatch } from '@reduxjs/toolkit';
import type {
    IIdRevModel,
    EventEmitterType,
    IPersistence,
    IProjectEntity,
    IImportData,
    ImportSource,
} from 'app/core/persistence';
import {
    isErrorType,
    Id,
    ProjectImportService,
    ProjectExportService,
    ImportError,
    ProjectService,
    OfflineService,
    FloorPlanService,
    ProjectDbOrigin,
    DuplicationError,
    ProjectRepository,
    ReplicationService,
    ProjectDbOriginAsdLocalUserData,
    ProjectDbOriginAsdUserData,
} from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { IAction, ActionCreator, ThunkAction } from 'app/store';
import { UserProjectsService } from '../../services/UserProjects.service';
import { t } from 'app/translate';
import {
    getUserSignedIn,
    CommonActionService,
    InitializeDataStorageService,
    getIsStandalone,
    getCurrentProject,
    getImageQuota,
} from 'app/modules/common';
import { eventTracking } from 'app/core/tracking';
import { IProjectActionService, IProjectListItem, IProjectListSort } from '../../models';
import type { IProgressToast } from 'app/toaster';
import { toaster } from 'app/toaster';
import { ModalService } from 'app/modal';
import { NavigateFunction } from 'react-router-dom';
import { getCurrentView } from '../../selectors';
import { UserProjectsActions } from '../state';
import { ProjectSettingsImporterService } from 'app/modules/exportProjectSettings';
import { getFileReadError, getImportErrorMessage, isValidFileFormat, parseFile } from '../../utils';

interface IUserProjectsActionService extends IProjectActionService {}

@injectable()
export class UserProjectsActionService implements IUserProjectsActionService {
    constructor(
        private userProjectsService: UserProjectsService,
        private projectService: ProjectService,
        private projectImportService: ProjectImportService,
        private projectExportService: ProjectExportService,
        private modalService: ModalService,
        private offlineService: OfflineService,
        private commonActionService: CommonActionService,
        private initializeDataStorageService: InitializeDataStorageService,
        private projectRepository: ProjectRepository,
        private replicationService: ReplicationService,
        private projectSettingsImporterService: ProjectSettingsImporterService,
    ) {}

    @ActionCreator()
    public addProject(navigate: NavigateFunction, origin?: ProjectDbOrigin): ThunkAction {
        return async (dispatch: any, getState) => {
            const state = getState();
            const userInfo = state.common.user.user;
            const userAccountCountryCode = userInfo ? userInfo.country : null;
            // Switch to correct database repository before adding.
            const projectDbOrigin = origin || ProjectDbOriginAsdUserData;
            if (projectDbOrigin === ProjectDbOriginAsdLocalUserData) {
                eventTracking.logUserEvent('User Projects', 'Add Project', 'Local project');
            } else if (projectDbOrigin === ProjectDbOriginAsdUserData) {
                eventTracking.logUserEvent('User Projects', 'Add Project', 'User project');
            }
            await this.initializeDataStorageService.switchDataRepository(projectDbOrigin);

            const project = await this.userProjectsService.addProjectListItemWithEntity(
                userAccountCountryCode,
                projectDbOrigin,
            );

            dispatch({
                type: UserProjectsActions.ADD_PROJECT,
                payload: project,
            });

            toaster.success(t.projectSuccessToastHeader, t.projectSuccessToastBody);

            navigate(`/project/${project.id}/dashboard/`);
        };
    }

    @ActionCreator()
    public duplicateProject(projectId: Id, usedImageQuota?: number, totalImageQuota?: number) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            this.commonActionService.setIsProjectOperationInProgress(true);
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            const isOnline = await this.offlineService.isOnline();
            const hasFloorPlans = await this.projectHasFloorPlanMapType(projectId);
            const isLocalProject = await this.projectService.getIsLocalProject(projectId);
            const confirmDuplicateWithoutFloorPlans = this.modalService.createConfirmDialog({
                header: t.projectDuplicatingHeader,
                body: t.projectExportRequirementError,
                confirmButtonText: t.projectExportWithoutFloorPlans,
                cancelButtonText: t.cancel,
                warning: true,
            });
            let newProject;

            try {
                // only special case if project has floorplans, but user not online or signed-in
                if (hasFloorPlans && !isLocalProject && !(isSignedIn && isOnline)) {
                    const duplicateWithoutFloorPlans = await confirmDuplicateWithoutFloorPlans();

                    eventTracking.logUserEvent(
                        'User Projects',
                        'Duplicate Project',
                        'Confirm exclude floor plan',
                        +duplicateWithoutFloorPlans,
                    );

                    if (duplicateWithoutFloorPlans) {
                        toaster.info(t.projectDuplicatingHeader, t.projectDuplicatingMessage);

                        newProject =
                            await this.userProjectsService.duplicateProjectWithoutFloorPlans(
                                projectId,
                            );
                    } else {
                        // user aborted duplication without floor plans
                        return;
                    }
                } else {
                    // fetching floor plans will take time, better confirm action
                    if (hasFloorPlans) {
                        toaster.info(t.projectDuplicatingHeader, t.projectDuplicatingMessage);
                    }
                    newProject = await this.userProjectsService.duplicateProject(
                        projectId,
                        undefined,
                        usedImageQuota,
                        totalImageQuota,
                    );
                }

                dispatch({
                    type: UserProjectsActions.ADD_PROJECT,
                    payload: newProject,
                });

                toaster.success(
                    t.projectDuplicateSuccessHeader,
                    t.projectDuplicateSuccessMessage(newProject.name),
                );
            } catch (error) {
                console.error(error);
                if (error instanceof DuplicationError) {
                    toaster.error(t.projectDuplicateErrorHeader, t.errorQuota);
                    return;
                }
                toaster.error(t.projectDuplicateErrorHeader, t.projectDuplicateErrorMessage);
            }
            this.commonActionService.setIsProjectOperationInProgress(false);
        };
    }

    @ActionCreator()
    public userProjectFilterChange(value: string): IAction<string> {
        return {
            type: UserProjectsActions.PROJECTS_FILTER_CHANGE,
            payload: value,
        };
    }

    @ActionCreator()
    public deleteUserProject(projectId: Id, rev: string) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            const isOnline = await this.offlineService.isOnline();
            const isLocalProject = await this.projectService.getIsLocalProject(projectId);
            const hasFloorPlans = await this.projectHasFloorPlanMapType(projectId);
            if (hasFloorPlans && !isLocalProject && (!isOnline || !isSignedIn)) {
                toaster.error(t.deleteProjectErrorHeader, t.deleteProjectAuthErrorMessage);
                return;
            }

            dispatch({
                type: UserProjectsActions.DELETE_PROJECT,
                payload: this.userProjectsService
                    .deleteProject(projectId, rev)
                    .catch((error: any) => {
                        switch (error.message) {
                            case 'AuthError':
                                toaster.error(
                                    t.deleteProjectErrorHeader,
                                    t.deleteProjectAuthErrorMessage,
                                );
                                break;

                            case 'UnknownNetworkError':
                                toaster.error(
                                    t.deleteProjectErrorHeader,
                                    t.deleteProjectUnknownNetworkErrorMessage,
                                );
                                break;

                            default:
                                toaster.error(
                                    t.deleteProjectErrorHeader,
                                    t.deleteProjectUnknownError,
                                );
                        }
                    }),
            });
        };
    }

    @ActionCreator()
    public deleteMultipleProjects(projects: IIdRevModel[]) {
        let couldNotDeleteProject = false;

        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            this.commonActionService.setIsProjectOperationInProgress(true);
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            const isOnline = await this.offlineService.isOnline();

            const deletedProjects: string[] = [];
            await Promise.all(
                projects.map(async (project) => {
                    const hasFloorPlans = await this.projectHasFloorPlanMapType(project.id);
                    const isLocalProject = await this.projectService.getIsLocalProject(project.id);
                    if (hasFloorPlans && !isLocalProject && (!isOnline || !isSignedIn)) {
                        couldNotDeleteProject = true;
                    } else {
                        const deletedProject = await this.userProjectsService
                            .deleteProject(project.id, project.rev)
                            .catch((error: any) => {
                                switch (error.message) {
                                    case 'AuthError':
                                        toaster.error(
                                            t.deleteProjectErrorHeader,
                                            t.deleteProjectAuthErrorMessage,
                                        );
                                        break;

                                    case 'UnknownNetworkError':
                                        toaster.error(
                                            t.deleteProjectErrorHeader,
                                            t.deleteProjectUnknownNetworkErrorMessage,
                                        );
                                        break;

                                    default:
                                        toaster.error(
                                            t.deleteProjectErrorHeader,
                                            t.deleteProjectUnknownError,
                                        );
                                }
                            });
                        if (deletedProject) {
                            deletedProjects.push(deletedProject);
                        }
                    }
                }),
            );
            dispatch({
                type: UserProjectsActions.MULTI_DELETE_PROJECTS,
                payload: deletedProjects,
            });

            if (couldNotDeleteProject) {
                toaster.error(t.deleteProjectErrorHeader, t.deleteProjectAuthErrorMessage);
                dispatch({
                    type: UserProjectsActions.INITIALIZE,
                    payload: this.userProjectsService.initialize(),
                });
            }
            this.commonActionService.setIsProjectOperationInProgress(false);
        };
    }

    @ActionCreator()
    public syncUnarchived(): IAction<Promise<void>> {
        return {
            type: UserProjectsActions.REPLICATE,
            payload: this.replicationService.syncUnarchived(),
        };
    }

    @ActionCreator()
    public syncAll(): IAction<Promise<void>> {
        return {
            type: UserProjectsActions.REPLICATE,
            payload: this.initializeDataStorageService.syncAll(),
        };
    }

    @ActionCreator()
    public showArchivedProjects(value: boolean) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            if (value) {
                const isLocalProjectsSelected = getCurrentView(getState()) === 'localprojects';

                if (!isLocalProjectsSelected) {
                    // only trigger sync for non-local projects
                    this.syncAll();
                }
            }
            dispatch({
                type: UserProjectsActions.SHOW_ARCHIVED_PROJECTS,
                payload: value,
            });
        };
    }

    @ActionCreator()
    public setSortOrder(sortOrder: IProjectListSort): IAction<IProjectListSort> {
        return {
            type: UserProjectsActions.SET_ORDER,
            payload: sortOrder,
        };
    }

    @ActionCreator()
    public exportProject(projectId: Id) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            this.commonActionService.setIsProjectOperationInProgress(true);
            const storeState = getState();
            const isSignedIn = getUserSignedIn(storeState);
            const isOnline = await this.offlineService.isOnline();
            const hasFloorPlans = await this.projectHasFloorPlanMapType(projectId);
            const isLocalProject = await this.projectService.getIsLocalProject(projectId);
            const confirmIncludeFloorPlans = this.modalService.createConfirmDialog({
                header: t.projectExportModalTitle,
                body: t.projectExportModalMessage,
                confirmButtonText: t.projectExportWithFloorPlans,
                cancelButtonText: t.projectExportWithoutFloorPlans,
            });
            const confirmExportWithoutFloorPlans = this.modalService.createConfirmDialog({
                header: t.projectExportModalTitle,
                body: t.projectExportRequirementError,
                confirmButtonText: t.projectExportWithoutFloorPlans,
                cancelButtonText: t.cancel,
                warning: true,
            });
            let exportResult;

            let progressToast: IProgressToast | undefined = undefined;
            try {
                if (hasFloorPlans) {
                    if ((isOnline && isSignedIn) || isLocalProject) {
                        const includeFloorPlans = await confirmIncludeFloorPlans();

                        if (includeFloorPlans) {
                            toaster.info(t.projectExportingHeader, t.projectIsBeingExportedMessage);
                        }

                        eventTracking.logUserEvent(
                            'User Projects',
                            'Export Project',
                            'Confirm include floor plan',
                            +includeFloorPlans,
                        );
                        progressToast = toaster.progress(t.creatingProjectFile);
                        exportResult = includeFloorPlans
                            ? await this.projectExportService.exportProject(projectId)
                            : await this.projectExportService.exportProjectWithoutFloorPlans(
                                  projectId,
                              );
                    } else {
                        const exportWithoutFloorPlans = await confirmExportWithoutFloorPlans();

                        eventTracking.logUserEvent(
                            'User Projects',
                            'Export Project',
                            'Confirm exclude floor plan',
                            +exportWithoutFloorPlans,
                        );

                        if (exportWithoutFloorPlans) {
                            exportResult =
                                await this.projectExportService.exportProjectWithoutFloorPlans(
                                    projectId,
                                );
                        } else {
                            // user aborted export without floor plans
                            return;
                        }
                    }
                } else {
                    exportResult = await this.projectExportService.exportProject(projectId);
                }

                dispatch({
                    type: UserProjectsActions.EXPORT_PROJECT,
                    payload: exportResult,
                });

                progressToast?.setSuccess(
                    t.documentationSuccessfullDownloadTitle,
                    t.projectExportSuccessMessage,
                );
            } catch (error) {
                console.error(error);
                progressToast?.setError(t.couldNotDownloadFile);
            }
            this.commonActionService.setIsProjectOperationInProgress(false);
        };
    }

    public async exportMultipleProjects(projectIds: Id[], isSignedIn: boolean) {
        this.commonActionService.setIsProjectOperationInProgress(true);
        const isOnline = await this.offlineService.isOnline();

        /** Whether or not any selected project has floor plans */
        const haveFloorPlans: boolean = await Promise.all(
            projectIds.map(async (id) => this.projectHasFloorPlanMapType(id)),
        ).then((hasFloorPlan) => hasFloorPlan.some((has) => !!has));

        /** Whether or not only local projects are selected */
        const onlyLocalProjects: boolean = await Promise.all(
            projectIds.map(async (id) => this.projectService.getIsLocalProject(id)),
        ).then((isLocal) => isLocal.some((local) => !!local));

        const confirmIncludeFloorPlans = this.modalService.createConfirmDialog({
            header: t.projectExportModalTitle,
            body: t.projectMultiExportModalMessage,
            confirmButtonText: t.projectExportWithFloorPlans,
            cancelButtonText: t.projectExportWithoutFloorPlans,
        });
        const confirmExportWithoutFloorPlans = this.modalService.createConfirmDialog({
            header: t.projectExportModalTitle,
            body: t.projectMultiExportRequirementError,
            confirmButtonText: t.projectExportWithoutFloorPlans,
            cancelButtonText: t.cancel,
            warning: true,
        });

        try {
            if (haveFloorPlans) {
                if ((isOnline && isSignedIn) || onlyLocalProjects) {
                    const includeFloorPlans = await confirmIncludeFloorPlans();

                    if (includeFloorPlans) {
                        toaster.info(t.exportingProjects, t.projectsAreBeingExported);
                    }

                    eventTracking.logUserEvent(
                        'User Projects',
                        'Export Multiple Projects',
                        'Confirm include floor plan',
                        +includeFloorPlans,
                    );

                    await Promise.all(
                        projectIds.map((id) =>
                            includeFloorPlans
                                ? this.projectExportService.exportProject(id)
                                : this.projectExportService.exportProjectWithoutFloorPlans(id),
                        ),
                    );
                } else {
                    const exportWithoutFloorPlans = await confirmExportWithoutFloorPlans();

                    eventTracking.logUserEvent(
                        'User Projects',
                        'Export Multiple Projects',
                        'Confirm exclude floor plan',
                        +exportWithoutFloorPlans,
                    );

                    if (exportWithoutFloorPlans) {
                        await Promise.all(
                            projectIds.map((id) =>
                                this.projectExportService.exportProjectWithoutFloorPlans(id),
                            ),
                        );
                    } else {
                        // user aborted export without floor plans
                        return;
                    }
                }
            } else {
                await Promise.all(
                    projectIds.map((id) => this.projectExportService.exportProject(id)),
                );
            }

            toaster.success(t.projectsExported, t.projectsExportedSuccessfully);
        } catch (error) {
            console.error(error);
            toaster.error(t.projectExportErrorHeader, t.projectsFailedToExport);
        }
        this.commonActionService.setIsProjectOperationInProgress(false);
    }

    @ActionCreator()
    public importProject(file: File) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();

            const isStandalone = getIsStandalone(storeState);
            const eventEmitterType: EventEmitterType = isStandalone ? 'standalone' : 'userprojects';

            let hasFloorPlans = false;
            // will fail with invalid files, so must then abort import
            try {
                hasFloorPlans = await this.projectImportService.hasFloorPlans(file);
            } catch (error) {
                if (isErrorType(error)) {
                    this.handleImportError(error);
                }
                return;
            }
            let excludeFloorPlanMaps: boolean | null = false;
            if (hasFloorPlans) {
                excludeFloorPlanMaps = await this.shouldExcludeFloorPlans(storeState);
            }
            if (excludeFloorPlanMaps !== null) {
                dispatch({
                    type: UserProjectsActions.IMPORT_PROJECT,
                    payload: this.handleImportProject(
                        file,
                        excludeFloorPlanMaps,
                        ProjectDbOriginAsdUserData,
                        eventEmitterType,
                        storeState.common.quota.usedBytes,
                        storeState.common.quota.totalBytes,
                    ).then(
                        () => this.handleSuccessfulImport(hasFloorPlans),
                        this.handleImportError,
                    ),
                });
            }
        };
    }

    @ActionCreator()
    public mergeIntoProject(
        importSource: ImportSource,
        keepBackupProject: boolean,
        navigate: NavigateFunction,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): ThunkAction {
        return async (dispatch, getState: () => IStoreState) => {
            const storeState = getState();
            let backupProjectCopy = null;
            const projectId = getCurrentProject(storeState)._id;
            try {
                this.commonActionService.setIsProjectOperationInProgress(true);
                // Validate input file
                const isImportDataValid = await this.isImportDataValid(importSource);
                if (!isImportDataValid) {
                    throw getFileReadError(
                        importSource instanceof File ? importSource.name : importSource,
                    );
                }

                if (importSource) {
                    const hasImportFloorPlans = await this.hasImportFloorPlans(importSource);
                    if (hasImportFloorPlans) {
                        const canCopyFloorPlans = await this.canCopyFloorPlans(storeState);
                        if (!canCopyFloorPlans) {
                            // cancel merge if import data contains floor plans that cannot be copied
                            throw new ImportError('cannotCopyFloorPlans');
                        }
                    }
                }

                // Take a copy of project before merging
                backupProjectCopy = await this.createBackupOfProject(
                    projectId,
                    storeState,
                    usedImageQuota,
                    totalImageQuota,
                );

                if (!backupProjectCopy) {
                    //failed to backup project do not continue with merge
                    throw new ImportError('entityImportFailed');
                } else {
                    dispatch({
                        type: UserProjectsActions.ADD_PROJECT,
                        payload: backupProjectCopy,
                    });
                }

                // import data to current project
                const updatedMergedProjectId = await this.importIntoProject(
                    importSource,
                    storeState,
                );
                if (!updatedMergedProjectId) {
                    throw new ImportError('entityImportFailed');
                }
                if (backupProjectCopy && !keepBackupProject) {
                    // delete backup project if not requested to keep after merge if successful
                    this.deleteUserProject(backupProjectCopy.id, backupProjectCopy.rev);
                }
                this.handleSuccessfulImport(true);
                toaster.success(t.projectImportSuccessHeader);
            } catch (error) {
                if (isErrorType(error)) {
                    this.handleImportIntoProjectFailed(
                        error,
                        backupProjectCopy,
                        projectId,
                        navigate,
                    );
                }
            }

            this.commonActionService.setIsProjectOperationInProgress(false);
        };
    }

    @ActionCreator()
    public importSettings(file: File) {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            this.commonActionService.setIsProjectOperationInProgress(true);
            const progress = toaster.progress(
                t.projectImportImportingHeader,
                t.projectImportImportingMessage,
            );
            const storeState = getState();
            const currentView = getCurrentView(storeState);
            const imageQuota = getImageQuota(storeState);

            try {
                const settingsImportData =
                    await this.projectSettingsImporterService.getImportableProjectFromFile(file);

                const importedProjectId = await this.projectImportService.importSettingsProject(
                    settingsImportData.fileData,
                    settingsImportData.projectName,
                    ProjectDbOriginAsdUserData,
                    currentView,
                    imageQuota.usedBytes,
                    imageQuota.totalBytes,
                );

                progress.setSuccess(t.projectImportSuccessHeader);
                dispatch({
                    type: UserProjectsActions.IMPORT_PROJECT,
                    payload: importedProjectId,
                });
            } catch (error) {
                const errorMessage = getImportErrorMessage(error as Error);
                progress.setError(t.projectImportErrorHeader, errorMessage);
            } finally {
                this.commonActionService.setIsProjectOperationInProgress(false);
            }
        };
    }

    @ActionCreator()
    public selectProject(id: Id): IAction<any> {
        return {
            type: UserProjectsActions.SELECT_PROJECT,
            payload: id,
        };
    }

    @ActionCreator()
    public unselectProject(id: Id): IAction<any> {
        return {
            type: UserProjectsActions.UNSELECT_PROJECT,
            payload: id,
        };
    }

    @ActionCreator()
    public clearSelection(): IAction<any> {
        return {
            type: UserProjectsActions.CLEAR_SELECTED_PROJECTS,
            payload: null,
        };
    }

    @ActionCreator()
    public clearUserProjects(): IAction<IProjectListItem[]> {
        return {
            type: UserProjectsActions.CLEAR_PROJECTS,
            payload: [],
        };
    }

    @ActionCreator()
    public reloadProjects(): ThunkAction {
        return async (dispatch: Dispatch<Action>) => {
            return dispatch({
                type: UserProjectsActions.INITIALIZE,
                payload: this.userProjectsService.initialize(),
            });
        };
    }

    @ActionCreator()
    public changeProjectDbOrigin(dbOrigin: ProjectDbOrigin): ThunkAction {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const storeState = getState();
            const loading = storeState.userProjects.loading;
            const loaded = storeState.userProjects.loaded;
            const nbrLoadedUserProjects = storeState.userProjects.projects.length;
            const hasDbOriginChanged = dbOrigin !== this.projectRepository.getDbName();

            // No projects loaded - load projects for current dataSource.
            if (!loading && !loaded) {
                if (hasDbOriginChanged) {
                    await this.initializeDataStorageService.switchDataRepository(dbOrigin);
                }

                return dispatch({
                    type: UserProjectsActions.INITIALIZE,
                    payload: this.userProjectsService.initialize(),
                });
            }

            // View has changed - clear projects, change dataSource and
            // trigger new collection of projects for new dataSource, if needed.
            if (hasDbOriginChanged) {
                this.clearUserProjects();
                await this.initializeDataStorageService.switchDataRepository(dbOrigin);

                dispatch({
                    type: UserProjectsActions.INITIALIZE,
                    payload: this.userProjectsService.initialize(),
                });
            } else if (nbrLoadedUserProjects > 0) {
                // 1. New view is recent, no need change dataSource or fetch projects.
                // 2. Going from recent view, but we already have correct dataSource loaded.
                // 3. For the initial loading the view will always be set to 'asd-user-data'
                //    which is the same as project repository.
                //    We do not want the project to be set as loaded in that case so we check
                //    if state contains any projects
                dispatch({
                    type: UserProjectsActions.SET_PROJECTS_LOADED,
                    payload: true,
                });
            }
        };
    }

    public async archiveMultipleProjects(projectIds: Id[], archive: boolean) {
        this.commonActionService.setIsProjectOperationInProgress(true);
        for (const id of projectIds) {
            await this.userProjectsService.setArchived(id, archive);
        }
        this.commonActionService.setIsProjectOperationInProgress(false);
    }

    private async projectHasFloorPlanMapType(projectId: Id): Promise<boolean> {
        const entities = await this.projectService.getDescendants(projectId);
        return entities.some((entity) => FloorPlanService.isFloorPlanMapType(entity));
    }

    private async handleImportProject(
        file: File,
        excludeFloorPlanMaps: boolean,
        newProjectDbOrigin: ProjectDbOrigin,
        eventEmitterType: EventEmitterType,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<string> {
        const progressToast = toaster.progress(
            t.projectImportImportingHeader,
            t.projectImportImportingMessage,
        );
        try {
            const fileData = await parseFile(file);

            const isFileDataValid = isValidFileFormat(fileData);
            if (isFileDataValid) {
                if (excludeFloorPlanMaps) {
                    fileData.children = await FloorPlanService.filterOutFloorPlanMaps(
                        fileData.children,
                    );
                }

                const fileEntities = [fileData.project, ...fileData.children];
                const importData: IImportData = {
                    projectId: fileData.project._id,
                    projectName: file.name,
                    data: fileEntities,
                };
                return this.projectImportService
                    .importProject(
                        importData,
                        undefined,
                        excludeFloorPlanMaps,
                        newProjectDbOrigin,
                        eventEmitterType,
                        usedImageQuota,
                        totalImageQuota,
                    )
                    .then((id) => {
                        progressToast.setSuccess(t.projectImportSuccessHeader);
                        return id;
                    });
            } else {
                const fileReadError = getFileReadError(file.name);
                progressToast.deleteToast();
                throw fileReadError;
            }
        } catch {
            const fileReadError = getFileReadError(file.name);
            progressToast.deleteToast();
            throw fileReadError;
        }
    }

    /**
     *
     * @param importData Import data (file or project) into current project
     * @param currentProject currently open project
     * @param excludeFloorPlanMaps if floor plans should be excluded
     * @param newProjectDbOrigin db origin
     * @param eventEmitterType type of event emitter
     * @param usedImageQuota currently used image quota for user
     * @param totalImageQuota total available image quota for user
     * @returns Id of the merged project
     */
    private async handleImportIntoProject(
        importData: IImportData,
        currentProject: IPersistence<IProjectEntity> | undefined,
        newProjectDbOrigin: ProjectDbOrigin,
        eventEmitterType: EventEmitterType,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<Id> {
        const id = await this.projectImportService.importProject(
            importData,
            currentProject,
            false,
            newProjectDbOrigin,
            eventEmitterType,
            usedImageQuota,
            totalImageQuota,
        );
        return id;
    }

    private async handleSuccessfulImport(hasFloorPlans: boolean) {
        hasFloorPlans && this.commonActionService.getUserImageQuota();
    }

    private handleImportError(error: Error) {
        const errorMessage = getImportErrorMessage(error);
        toaster.error(t.projectImportErrorHeader, errorMessage);
    }

    /**
     * Called when import into project failed. Will display a valid error message, navigate back to project list and delete the failing project
     * @param error type of error
     * @param backupProjectCopy copy of original project
     * @param currentProjectProjectId Id of the current project
     * @param keepBackupProject true if user has chosen to keep backup project
     * @param navigate Navigate function
     */
    private async handleImportIntoProjectFailed(
        error: Error,
        backupProjectCopy: IProjectListItem | null,
        currentProjectProjectId: Id,
        navigate: NavigateFunction,
    ) {
        this.handleImportError(error);
        const currentProjectToDelete = await this.projectService.get(currentProjectProjectId);
        if (backupProjectCopy && currentProjectToDelete) {
            navigate(`/`);
            this.deleteUserProject(currentProjectToDelete._id, currentProjectToDelete._rev);
        }
    }

    /**
     * Check if import data is valid so import should continue
     * @param importSource File or projectId to import
     * @returns true if import should continue
     */
    private async isImportDataValid(importSource: ImportSource): Promise<boolean> {
        if (importSource instanceof File) {
            try {
                const fileData = await parseFile(importSource);
                const isFileDataValid = isValidFileFormat(fileData);
                return isFileDataValid;
            } catch {
                throw new ImportError('invalidFileFormat');
            }
        } else if (importSource) {
            return true;
        }
        return false;
    }

    /**
     * Retrieves import data from file or project
     * @param importSource File or projectId to import
     * @returns importData in IImportData format
     */
    private async getImportData(importSource: ImportSource): Promise<IImportData> {
        if (importSource instanceof File) {
            const fileData = await parseFile(importSource);
            return {
                projectId: fileData.project._id,
                projectName: importSource.name,
                data: [fileData.project, ...fileData.children],
            };
        } else if (importSource) {
            const projectAndDescendants =
                await this.projectRepository.getProjectAndAllDescendants(importSource);
            return {
                projectId: importSource,
                projectName: projectAndDescendants.project.name,
                data: [projectAndDescendants.project, ...projectAndDescendants.descendants],
            };
        }
        return Promise.reject('No file or project id provided');
    }

    /**
     * Returns true if import data contains floor plans
     * @param importSource Filer or projectId to import
     * @returns true if import data contains floor plans
     */
    private async hasImportFloorPlans(importSource: ImportSource): Promise<boolean> {
        if (importSource instanceof File) {
            return this.projectImportService.hasFloorPlans(importSource);
        } else if (importSource) {
            return this.projectHasFloorPlanMapType(importSource);
        }
        return false;
    }

    /**
     * create a backup of the project before merging if requested by user
     * @param projectId Id of project to backup
     * @param storeState store state
     * @param usedImageQuota currently used image quota for user
     * @param totalImageQuota total available image quota for user
     * @returns IProjectListItem of the new project or null if project could not be duplicated
     */
    private async createBackupOfProject(
        projectId: Id,
        storeState: IStoreState,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<IProjectListItem | null> {
        let newProject;

        try {
            // only special case if project has floorplans, but user not online or signed-in
            const hasFloorPlans = await this.projectHasFloorPlanMapType(projectId);

            const canCopyFloorPlans = hasFloorPlans
                ? await this.canCopyFloorPlans(storeState)
                : true;

            // project contains floor plans but these cannot be copied (user needs to  be logged in and connected to internet or project is local)
            if (!canCopyFloorPlans) {
                return Promise.reject(new ImportError('cannotCopyFloorPlans'));
            }

            newProject = await this.userProjectsService.duplicateProject(
                projectId,
                t.backup,
                usedImageQuota,
                totalImageQuota,
            );

            return newProject;
        } catch (error) {
            console.error(error);
            if (error instanceof DuplicationError) {
                toaster.error(t.projectDuplicateErrorHeader, t.errorQuota);
                return null;
            }
            toaster.error(t.projectDuplicateErrorHeader, t.projectDuplicateErrorMessage);
            return null;
        }
    }

    /**
     * import project or project file into current project
     * @param importSource file or project id to import
     * @param storeState store state
     * @param newProjectDbOrigin db origin
     * @returns Id of the merged project or null if import failed
     */
    private async importIntoProject(
        importSource: ImportSource,
        storeState: IStoreState,
    ): Promise<Id | null> {
        const currentProject = getCurrentProject(storeState);
        const isStandalone = getIsStandalone(storeState);
        const eventEmitterType: EventEmitterType = isStandalone ? 'standalone' : 'localprojects';

        try {
            const importData = await this.getImportData(importSource);

            const updatedProjectId = await this.handleImportIntoProject(
                importData,
                currentProject,
                ProjectDbOriginAsdLocalUserData,
                eventEmitterType,
                storeState.common.quota.totalBytes,
            );

            return updatedProjectId;
        } catch {
            throw new ImportError('fileContentsUnreadable');
        }
    }

    /**
     * Handle import of floor plans
     * @param storeState store state
     * @returns true if floor plans should be excluded, false if not, null if user aborted
     */
    private async shouldExcludeFloorPlans(storeState: IStoreState): Promise<boolean | null> {
        const confirmImportWithoutFloorPlans = this.modalService.createConfirmDialog({
            header: t.projectImportModalTitle,
            body: t.projectImportModalMessage,
            confirmButtonText: t.projectImportWithoutFloorPlans,
            cancelButtonText: t.cancel,
        });
        let excludeFloorPlanMaps = false;

        // special case when user tries to import project containing floor plans while off-line/ signed-out
        const isSignedIn = getUserSignedIn(storeState);
        const isOnline = await this.offlineService.isOnline();
        const currentView = getCurrentView(storeState);
        const newProjectIsLocalProject = currentView === 'localprojects';
        if (!newProjectIsLocalProject && !(isSignedIn && isOnline)) {
            excludeFloorPlanMaps = await confirmImportWithoutFloorPlans();

            // user aborted import without floor plans
            if (!excludeFloorPlanMaps) {
                return null;
            }
        }
        return excludeFloorPlanMaps;
    }

    /**
     * Handle import of floor plans
     * @param storeState store state
     * @returns true if floor plans can be copied, false if not
     */
    private async canCopyFloorPlans(storeState: IStoreState): Promise<boolean> {
        // special case when user tries to import project containing floor plans while off-line/ signed-out
        const isSignedIn = getUserSignedIn(storeState);
        const isOnline = await this.offlineService.isOnline();
        const currentView = getCurrentView(storeState);
        const newProjectIsLocalProject = currentView === 'localprojects';
        if (!newProjectIsLocalProject && !(isSignedIn && isOnline)) {
            return false;
        }
        return true;
    }
}
