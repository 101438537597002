import { injectable } from 'inversify';
import { BaseItemExporterService } from './BaseItemExporter.service';
import type {
    IPersistence,
    IAnalogCameraItemEntity,
    Id,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    CurrentProjectService,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import type { Frequency } from 'app/modules/common';
import {
    StorageCalculationService,
    ProfileOverrideService,
    ProfileSupportService,
    BandwidthCalculatorService,
    ScenarioService,
} from 'app/modules/common';

import type {
    IPiaDevice,
    ProductBandwidthProperties,
    IPiaEncoder,
    PiaCategory,
} from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type {
    IExportedEncoder,
    IExportedCamera,
    IExportablePersistedEntity,
} from '../../../models';
import { isDefined } from 'axis-webtools-util';
import { splitByQuantity } from '../../splitByQuantity';
import { toShareModelNames } from '../../mapToShareItemNames';
import { creationDateReverseComparator } from 'app/utils';

@injectable()
export class EncodersExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaDevice>,
        projectModelService: ProjectModelService,
        private currentProjectService: CurrentProjectService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedEncoders = async (
        projectZipSetting: ProjectZipType,
        projectId: Id,
        items: IExportablePersistedEntity[],
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
    ): Promise<IExportedEncoder[]> => {
        const allEncoders = items.filter(deviceTypeCheckers.isEncoder);

        const mappedEncoders = await Promise.all(
            allEncoders.map((encoder) =>
                this.mapEncoderToExportedEncoder(
                    projectZipSetting,
                    projectId,
                    encoder,
                    frequency,
                    projectBandwidthVersion,
                    projectRetentionTimeInDays,
                ),
            ),
        );

        return mappedEncoders.filter(isDefined);
    };

    private mapAnalogCamerasToExportedAnalogCamerasType = (
        items: IPersistence<IAnalogCameraItemEntity>[],
    ): IExportablePersistedEntity[] => {
        const exportedItems = items.map((item) => ({
            ...item,
            exportId: item._id,
        }));
        return exportedItems;
    };

    private mapEncoderToExportedEncoder = async (
        projectZipSetting: ProjectZipType,
        projectId: Id,
        encoderItem: IExportablePersistedEntity,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
    ): Promise<IExportedEncoder | null> => {
        if (!encoderItem.productId) {
            return null;
        }

        const encoderPiaDevice = this.getPiaDevice(encoderItem.productId) as IPiaEncoder;

        const analogCameras = await this.getAnalogCameras(projectId, encoderItem._id);
        const exportedAnalogCameras =
            this.mapAnalogCamerasToExportedAnalogCamerasType(analogCameras);
        const mappedAnalogCameras = await Promise.all(
            exportedAnalogCameras
                .sort(creationDateReverseComparator)
                .map(async (analogCamera) =>
                    this.mapAnalogCamerasToExportedCameras(
                        projectZipSetting,
                        analogCamera,
                        encoderPiaDevice.properties,
                        'analogCamera',
                        frequency,
                        projectBandwidthVersion,
                        projectRetentionTimeInDays,
                        projectId,
                    ),
                ),
        );
        const mappedBaseItem = await this.mapItemToExportedItemBase(encoderItem, projectId);

        return {
            ...mappedBaseItem,
            ...toShareModelNames(encoderPiaDevice.name),
            piaId: encoderItem.productId,
            analogCameras: splitByQuantity(mappedAnalogCameras),
        };
    };

    private mapAnalogCamerasToExportedCameras = async (
        projectZipSetting: ProjectZipType,
        analogCamera: IExportablePersistedEntity,
        encoderProperties: ProductBandwidthProperties,
        category: PiaCategory | 'analogCamera',
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
        projectId: Id,
    ): Promise<IExportedCamera> => {
        const mergedProfile = await this.profileOverrideService.getMergedProfileFromDevice(
            (analogCamera as IPersistence<IAnalogCameraItemEntity>).properties.analogCamera,
            projectRetentionTimeInDays,
        );
        return this.mapCameraToExportedCamera(
            projectZipSetting,
            analogCamera,
            mergedProfile,
            encoderProperties,
            category,
            frequency,
            projectBandwidthVersion,
            projectId,
            projectRetentionTimeInDays,
        );
    };

    private async getAnalogCameras(
        projectId: Id,
        encoderId: Id,
    ): Promise<IPersistence<IAnalogCameraItemEntity>[]> {
        const analogCameras = this.currentProjectService.isCurrentProjectLoaded()
            ? this.currentProjectService.getDeviceChildren(encoderId, 'analogCamera')
            : this.getDeviceChildren(projectId, encoderId, 'analogCamera');
        return analogCameras as IPersistence<IAnalogCameraItemEntity>[];
    }
}
