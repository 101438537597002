import type { PiaId } from 'app/core/pia';
import { PiaItemSoftwareCategory } from 'app/core/pia';
import type { IProduct } from '../../../IProduct';
import type { ProductWithAccessory } from '../types';

/**
 * Filter out products that are included in other products
 * @param products - all products
 * @param notIncludedAcaps - acaps that are not included in other products
 * @returns products that are not included in other products
 */
export const filterNotIncludedAcaps = (
    products: IProduct[],
    notIncludedAcaps: ProductWithAccessory,
    includeAcaps: ProductWithAccessory,
) => {
    // Filter out products that are included in other products
    return products
        .filter((product) => {
            if (
                product.piaCategory === PiaItemSoftwareCategory.ACAP &&
                product.piaId &&
                Object.keys(notIncludedAcaps).length > 0
            ) {
                // If the product is an ACAP and notIncludedAcaps is not empty,
                // check if the product is in the notIncludedAcaps.
                return isProductInProductWithAccessory(product.piaId, notIncludedAcaps);
            }
            return true;
        })
        .map((product) => {
            if (
                product.piaCategory === PiaItemSoftwareCategory.ACAP &&
                product.piaId &&
                isProductInProductWithAccessory(product.piaId, includeAcaps)
            ) {
                const newQuantity = getNewQuantityForProduct(product, products, notIncludedAcaps);
                return {
                    ...product,
                    quantity: newQuantity,
                };
            }
            return product;
        });
};

/**
 * A helper function to find the quantity of a product based on the piaId
 * @param products all products
 * @param piaId the piaId to find the quantity for.
 * @returns a number representing the quantity of the product.
 */
const findProductQuantityForPiaId = (products: IProduct[], piaId: number) => {
    return products.find((product) => product.piaId === piaId)?.quantity ?? 0;
};

/**
 * A helper function to calculate the quantity of the product based on the occurrences of the piaId in notIncludedAcaps
 * @param product the product to calculate the quantity for based on the occurrences of the piaId in notIncludedAcaps.
 * @param notIncludedAcaps the acaps that are not included in other products.
 * @returns a number representing the new quantity of the product, if scaleQuantity is greater than 1, the product.quantity is not changed.
 */
const getNewQuantityForProduct = (
    product: IProduct,
    products: IProduct[],
    notincludeAcaps: ProductWithAccessory,
) => {
    return Object.entries(notincludeAcaps).reduce(
        (totalQuantity, [key, piaItems]) => {
            const count = piaItems.filter((item) => item.id === product.piaId).length;
            const quantityForKey = count * findProductQuantityForPiaId(products, Number(key));
            return totalQuantity + quantityForKey;
        },
        0, // Initial value for the total quantity
    );
};

/**
 * A helper function to check if a product is included in the productWithAccessory
 * @param product the product to check
 * @param productWithAccessory accessory items aranged by product
 * @returns a boolean representing if the product is included in the notIncludedAcaps
 * @example
 * const product = { piaId: 1 };
 * const productWithAccessory = { 1: [{ id: 1 }] };
 * isProductInProductWithAccessory(product, productWithAccessory); // true
 */
const isProductInProductWithAccessory = (
    piaId: PiaId,
    productWithAccessory: ProductWithAccessory,
) =>
    Object.values(productWithAccessory).some((piaItems) =>
        piaItems.some((piaItem) => piaItem.id === piaId),
    );
