import { injectable } from 'inversify';
import { CleanupService, OfflineService, ReplicationService } from 'app/core/persistence';
import { t } from 'app/translate';
import { getPartnerBase } from 'app/partnerUrl';
import { ModalService } from 'app/modal';

@injectable()
export class LoginService {
    private confirmLogout = this.modalService.createConfirmDialog(t.confirmLogoutGROUP);
    private confirmUnsaved = this.modalService.createConfirmDialog(t.confirmLogoutUnsavedGROUP);
    private confirmOffline = this.modalService.createConfirmDialog(t.confirmLogoutOfflineGROUP);

    constructor(
        private cleanupService: CleanupService,
        private modalService: ModalService,
        private offlineService: OfflineService,
        private replicationService: ReplicationService,
    ) {}

    public login(url: string): void {
        this.redirect(url);
    }

    /**
     * Logout the user.
     *
     * This will not delete databases,
     * this is handled in initializeSharedDataStorageService.clearLocalDatabase()
     * and initializeDataStorageService.clearLocalDatabase().
     *
     * @param url The url to redirect to after logout.
     * @returns true if the user is logged out, false otherwise.
     */
    public async logout(url: string): Promise<boolean> {
        let confirmed = false;
        const isOnline = await this.offlineService.isOnline();
        if (!isOnline) {
            confirmed = !(await this.confirmOffline());
        } else if (isOnline && !(await this.replicationService.isChangesReplicated())) {
            confirmed = !(await this.confirmUnsaved());
        } else {
            confirmed = await this.confirmLogout();
        }

        if (!confirmed) {
            return false;
        }

        // This will not delete local databases,
        // this is handled in initializeSharedDataStorageService.clearLocalDatabase()
        // and initializeDataStorageService.clearLocalDatabase() in
        // src/app/modules/common/services/CommonAction.service.ts.
        await this.cleanupService.clearAllUserData();

        const href = window.location.origin + (getPartnerBase() || '');
        if (isOnline) {
            // if we are online we redirect to perform a real server side logout
            this.redirect(url);
        } else {
            // else we just refresh
            this.redirect(href);
        }
        return true;
    }

    private redirect(url: string, href = '') {
        window.location.href = url + href;
    }
}
