function addPrefix(actionName: string) {
    return `USER_PROJECTS_LIST_${actionName}`;
}

export const UserProjectsActions = {
    INITIALIZE: addPrefix('INITIALIZE'),
    GET_DEEP_PROJECT_DATA: addPrefix('GET_DEEP_PROJECT_DATA'),
    GET_MULTIPLE_DEEP_PROJECT_DATA: addPrefix('GET_MULTIPLE_DEEP_PROJECT_DATA'),
    ADD_PROJECT: addPrefix('ADD_PROJECT'),
    PROJECTS_FILTER_CHANGE: addPrefix('PROJECTS_FILTER_CHANGE'),
    DELETE_PROJECT: addPrefix('DELETE_PROJECT'),
    MULTI_DELETE_PROJECTS: addPrefix('MULTI_DELETE_PROJECTS'),
    MULTI_ARCHIVE_PROJECTS: addPrefix('MULTI_ARCHIVE_PROJECTS'),
    SHOW_ARCHIVED_PROJECTS: addPrefix('SHOW_ARCHIVED_PROJECTS'),
    IMPORT_PROJECT: 'IMPORT_PROJECT',
    EXPORT_PROJECT: 'EXPORT_PROJECT',
    SET_ORDER: addPrefix('SET_ORDER'),
    SELECT_PROJECT: addPrefix('SET_PROJECT_SELECTED'),
    UNSELECT_PROJECT: addPrefix('SET_PROJECT_UNSELECTED'),
    CLEAR_SELECTED_PROJECTS: addPrefix('CLEAR_SELECTED_PROJECTS'),
    CLEAR_PROJECTS: addPrefix('CLEAR_PROJECTS'),
    SET_PROJECTS_LOADED: addPrefix('SET_PROJECTS_LOADED'),
    SET_ORGANIZATIONS: addPrefix('SET_ORGANIZATIONS'),
    REPLICATE: addPrefix('REPLICATE'),
};
