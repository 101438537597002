import * as React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { SharedProjects } from '../sharedProjects';
import { LocalProjects } from '../localProjects';
import { UserProjects } from '../userProjects';
import { getItemLocalStorageJSON } from 'app/core/persistence';
import { isFeatureEnabled } from 'app/featureFlags';

export const UserProjectsRoutes: React.FC = () => {
    const navigate = useNavigate();
    const currentView = getItemLocalStorageJSON('LatestProjectView', 'userprojects');

    // Set routes depending on the current view
    React.useEffect(() => {
        if (currentView === 'userprojects') {
            navigate('userprojects/');
            return;
        }

        if (currentView === 'localprojects') {
            navigate('localprojects/');
            return;
        }

        if (isFeatureEnabled('shared_projects') && currentView === 'sharedprojects') {
            navigate('sharedprojects/');
            return;
        }
    }, [currentView, navigate]);

    return (
        <Routes>
            <Route path="userprojects/" element={<UserProjects />} />
            {isFeatureEnabled('shared_projects') && (
                <Route path="sharedprojects/" element={<SharedProjects />} />
            )}
            <Route path="localprojects/" element={<LocalProjects />} />
            <Route path="*" element={<Navigate replace to="userprojects" />} />
        </Routes>
    );
};
