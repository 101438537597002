import { ProjectDbOriginAsdLocalUserData } from 'app/core/persistence';
import { getCurrentProject } from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

const getCurrentView = (state: IStoreState) => state.app.currentView;

export const getIsLocalProjectOrView = createSelector(
    [getCurrentProject, getCurrentView],
    (project, currentView) =>
        project
            ? project.projectDbOrigin === ProjectDbOriginAsdLocalUserData
            : currentView === 'localprojects',
);
