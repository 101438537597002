import { injectable } from 'inversify';
import { t } from 'app/translate';
import { ModalService } from 'app/modal';
import {
    SharedPersistenceConfigurationService,
    ProjectRepository,
    ResourceGroupId,
    EventEmitterService,
} from 'app/core/persistence';
import { EVENT_UPDATE_AVAILABLE, EVENT_PROGRESS } from 'app/core/persistence/userDataPersistence';
import { AppStore, IAction } from 'app/store';
import { CommonActions } from '../actions';
import { CompactionStatus, CouchDBCompactionService } from '../couchDBCompaction';
import { SharedProjectsActions } from 'app/modules/userProjects/sharedProjects/state/SharedProjects.actions';

@injectable()
export class InitializeSharedDataStorageService {
    private emitter: EventEmitter;
    private onProgressCallbacks: Array<(progress: number) => any> = [];

    constructor(
        private sharedPersistenceConfigurationService: SharedPersistenceConfigurationService,
        private projectRepository: ProjectRepository,
        private modalService: ModalService,
        private couchDBCompactionService: CouchDBCompactionService,
        private appStore: AppStore,
        private eventEmitterService: EventEmitterService,
    ) {
        this.emitter = this.eventEmitterService.getEventEmitter('sharedprojects');
    }

    public async initializeStorage(resourceGroupId: ResourceGroupId): Promise<void> {
        // remove listeners if they exist
        this.removeListeners();
        this.emitter.on(EVENT_UPDATE_AVAILABLE, this.updateApp);
        // Block database access if ongoing compaction (multi-user case).
        // Block creation of new database when database is in 'SeriousError' after failed compaction.
        const status = await this.couchDBCompactionService.getCompactStatus();
        // Update store to make Optimize button appear if status is 'Available'.
        this.appStore.Store.dispatch<IAction<Promise<CompactionStatus | null>>>({
            type: CommonActions.UpdateCompactStatus,
            payload: Promise.resolve(status),
        });
        if (status !== 'Initiated' && status !== 'SeriousError') {
            await this.sharedPersistenceConfigurationService.initialize(resourceGroupId);
        }

        // Set proper dataSource based on state of userDataDB and last project view user selected.
        await this.switchDataRepository(resourceGroupId);
    }

    public switchDataRepository(
        resourceGroupId: ResourceGroupId,
    ): Promise<PouchDB.Database> | undefined {
        const currentDb = this.projectRepository.getDbName();

        // Set current database to the new one in redux store
        this.appStore.Store.dispatch<IAction<ResourceGroupId>>({
            type: SharedProjectsActions.SET_SHARED_PROJECT_DATABASE_NAME,
            payload: resourceGroupId,
        });

        // If we are already using the correct database, do nothing
        if (currentDb === resourceGroupId) return;

        // Check if we need to switch to a different configuration service
        return this.sharedPersistenceConfigurationService.initDatabase(resourceGroupId);
    }

    public async syncAll(): Promise<void> {
        return this.sharedPersistenceConfigurationService.syncAll();
    }

    public onProgress(fn: (progress: number) => any) {
        // Save fn in array to remove it later
        this.onProgressCallbacks.push(fn);
        this.emitter.on(EVENT_PROGRESS, fn);
    }

    public clearLocalDatabase() {
        return this.sharedPersistenceConfigurationService.clearLocalDatabase();
    }

    public getDbOrigin() {
        return this.sharedPersistenceConfigurationService.getDbOrigin();
    }

    private updateApp = async () => {
        await this.modalService.createConfirmDialog(t.updateAvailableGROUP)();
        window.location.reload();
    };

    private removeListeners() {
        // remove listener for update available
        this.emitter.removeListener(EVENT_UPDATE_AVAILABLE, this.updateApp);
        // remove listeners for all progress callbacks
        this.onProgressCallbacks.forEach((fn) => {
            this.emitter.removeListener(EVENT_PROGRESS, fn);
        });
    }
}
