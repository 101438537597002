import {
    getDefaultCameraFilterEntity,
    getDefaultProfileOverrideEntity,
    ICameraItemEntity,
    Id,
    UnitSystem,
} from 'app/core/persistence';
import { injectable } from 'inversify';
import { ImportedCamera } from '../../../models';
import { IProfileEntityWithId, ProfileImporterService } from '../profiles';
import { mapToItemEntity, getImportedAccessories, getImportedLenses } from './utils';
import { getImportedApplications } from './utils/getImportedApplications';

@injectable()
export class CamerasImporterService {
    constructor(private profileImporterService: ProfileImporterService) {}

    public import(
        projectId: Id,
        cameras: ImportedCamera[],
        profiles: IProfileEntityWithId[],
        unitSystem: UnitSystem,
    ) {
        const cameraEntities = cameras.map((camera) =>
            this.mapToCameraEntity(projectId, camera, profiles, unitSystem),
        );

        const accessoryEntities = getImportedAccessories(projectId, cameras);

        const applicationEntities = getImportedApplications(projectId, cameras);

        const lensEntities = getImportedLenses(projectId, cameras);

        return [...cameraEntities, ...accessoryEntities, ...lensEntities, ...applicationEntities];
    }

    private mapToCameraEntity = (
        projectId: Id,
        camera: ImportedCamera,
        profiles: IProfileEntityWithId[],
        unitSystem: UnitSystem,
    ) => {
        const itemEntity = mapToItemEntity(projectId, camera, camera.piaId);

        return {
            ...itemEntity,
            replaceWithBareboneId: camera.replaceWithBareboneId,
            properties: {
                camera: {
                    filter: getDefaultCameraFilterEntity(unitSystem, 3),
                    associatedProfile: this.profileImporterService.getProfileIdForImportedCamera(
                        camera,
                        profiles,
                    ),
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
            },
        } satisfies ICameraItemEntity;
    };
}
