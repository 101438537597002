import type {
    Id,
    IInstallationPointEntity,
    IFloorPlanEntity,
    IFreeTextPointEntity,
    IBlockerEntity,
} from 'app/core/persistence';
import {
    isLatLngWithinFloorPlan,
    isBlockerWithinFloorPlan,
    isGeoLocated,
    getFloorPlanArea,
    sortMapsByDistance,
    isGeoMap,
} from './floorPlan';
import { isGlobalEntity } from '../../../maps/utils/globalization';
import { groupBy } from 'lodash-es';

/**
 * Comparator to sort floor plans by area.
 * @param a The first floor plan.
 * @param b The second floor plan.
 * @returns -1 if a is smaller than b, 1 if a is larger than b, 0 if they are equal.
 */
const byArea = (a: IFloorPlanEntity, b: IFloorPlanEntity): number => {
    if (isGeoLocated(a) && isGeoLocated(b)) {
        return (getFloorPlanArea(a) ?? 0) - (getFloorPlanArea(b) ?? 0);
    }
    return 0;
};

/**
 * Check whether an installation point is a child of another installation point,
 * which is on the floor plan.
 * @param installationPoint The installation point to check.
 * @param installationPoints All installation points.
 * @param floorPlan The floor plan to check.
 * @returns True if the installation point's parent is on the floor plan, false otherwise.
 */
const isParentOnFloorPlan = (
    installationPoint: IInstallationPointEntity,
    installationPoints: IInstallationPointEntity[],
    floorPlan: IFloorPlanEntity,
): boolean => {
    const parent =
        installationPoint.parentId &&
        installationPoints.find((ip) => ip._id === installationPoint.parentId);
    if (!parent) {
        return false;
    }

    return isInstallationPointOnFloorPlan(parent, installationPoints, floorPlan);
};

/**
 * Check whether an installation point is on the floor plan.
 * @param installationPoint The installation point to check.
 * @param installationPoints All installation points.
 * @param floorPlan The floor plan to check.
 * @returns True if the installation point is on the floor plan, false otherwise.
 */
const isInstallationPointOnFloorPlan = (
    installationPoint: IInstallationPointEntity,
    installationPoints: IInstallationPointEntity[],
    floorPlan: IFloorPlanEntity,
): boolean => {
    return (
        isLatLngWithinFloorPlan(floorPlan)(installationPoint.location) ||
        isParentOnFloorPlan(installationPoint, installationPoints, floorPlan)
    );
};

/**
 * Determine on which floor plan the installation point is located.
 * @param installationPoint The installation point to check.
 * @param installationPoints The installation points to check.
 * @param floorPlans The floor plans to check.
 * @returns The floor plan on which the installation point is located or undefined if
 * it is not located on any floor plan.
 */
export const mapInstallationPointToFloorPlan = <T extends IInstallationPointEntity>(
    installationPoint: T,
    installationPoints: T[],
    floorPlans: IFloorPlanEntity[],
): IFloorPlanEntity | undefined => {
    return floorPlans.find((floorPlan) =>
        isGeoLocated(floorPlan)
            ? isInstallationPointOnFloorPlan(installationPoint, installationPoints, floorPlan)
            : floorPlan._id === installationPoint.floorPlanId,
    );
};

/**
 * Map installation points to floor plans.
 * @param installationPoints The installation points to map.
 * @param floorPlans The floor plans to map to.
 * @returns An object with the floor plan id as key and an array of installation points as value.
 */
export const mapInstallationPointsToFloorPlan = <T extends IInstallationPointEntity>(
    installationPoints: T[],
    floorPlans: IFloorPlanEntity[],
): Record<Id, T[]> => {
    // We want to find the smallest floor plan that contains the installation point
    // so we sort the floor plans by size ascending first.
    const sortedFloorPlans = floorPlans.sort(byArea);

    // use lodash groupBy to group installation points by floor plan id
    const grouped = groupBy(installationPoints, (installationPoint) => {
        const floorPlan = mapInstallationPointToFloorPlan(
            installationPoint,
            installationPoints,
            sortedFloorPlans,
        );
        return floorPlan?._id;
    });

    // remove undefined keys
    delete grouped.undefined;

    return grouped;
};

/**
 * Determine to which geomap the installation point belongs by comparing the distance
 * @param installationPoint The installation point to check.
 * @param geoMaps The geomaps to check.
 * @returns The geomap to which the installation point belongs or undefined if
 * it is not geo located.
 */
const mapInstallationPointToGeoMap = <T extends IInstallationPointEntity>(
    installationPoint: T,
    geoMaps: IFloorPlanEntity[],
): IFloorPlanEntity | undefined => {
    if (!isGlobalEntity(installationPoint)) {
        return undefined;
    }

    const sortedGeoMaps = sortMapsByDistance(geoMaps, installationPoint.location);

    return sortedGeoMaps[0];
};

/**
 * Map installation points to geomaps.
 * @param installationPoints The installation points to map.
 * @param geoMaps The geomaps to map to.
 * @returns An object with the geomap id as key and an array of installation points as value.
 */
export const mapInstallationPointsToGeoMap = <T extends IInstallationPointEntity>(
    installationPoints: T[],
    geoMaps: IFloorPlanEntity[],
): Record<Id, T[]> => {
    // use lodash groupBy to group installation points by geomap id
    const onlyGeoMaps = geoMaps.filter(isGeoMap);
    const grouped = groupBy(installationPoints, (installationPoint) => {
        const geoMap = mapInstallationPointToGeoMap(installationPoint, onlyGeoMaps);
        return geoMap?._id;
    });

    // remove undefined keys (installation points that are not geo located)
    delete grouped.undefined;

    return grouped;
};

/**
 * Determine on which floor plan the free text point is located.
 * @param freeTextPoint The free text point to check.
 * @param floorPlans The floor plans to check.
 * @returns The floor plan on which the free text point is located or undefined if
 * it is not located on any floor plan.
 */
const mapFreeTextPointToFloorPlan = (
    freeTextPoint: IFreeTextPointEntity,
    floorPlans: IFloorPlanEntity[],
): IFloorPlanEntity | undefined => {
    return floorPlans.find((floorPlan) =>
        isGeoLocated(floorPlan)
            ? isGlobalEntity(freeTextPoint) &&
              isLatLngWithinFloorPlan(floorPlan)(freeTextPoint.location)
            : floorPlan._id === freeTextPoint.path[1],
    );
};

/**
 * Map free text points to floor plans. Free text points that are not located on any floor plan
 * are mapped to undefined.
 * @param freeTextPoints The free text points to map.
 * @param floorPlans The floor plans to map to.
 * @returns An object with the floor plan id as key and an array of free text points as value.
 */
export const mapFreeTextPointsToFloorPlan = (
    freeTextPoints: IFreeTextPointEntity[],
    floorPlans: IFloorPlanEntity[],
): Record<Id, IFreeTextPointEntity[]> => {
    // We want to find the smallest floor plan that contains the installation point
    // so we sort the floor plans by size ascending first.
    const sortedFloorPlans = floorPlans.sort(byArea);

    // use lodash groupBy to group free text points by floor plan id
    const grouped = groupBy(freeTextPoints, (freeTextPoint) => {
        const floorPlan = mapFreeTextPointToFloorPlan(freeTextPoint, sortedFloorPlans);
        return floorPlan?._id;
    });

    return grouped;
};

/**
 * Determine to which geomap the free text point belongs by comparing the distance
 * @param freeTextPoint The free text point to check.
 * @param geoMaps The geomaps to check.
 * @returns The geomap to which the free text point belongs or undefined if
 * it is not geo located.
 */
const mapFreeTextPointToGeoMap = (
    freeTextPoint: IFreeTextPointEntity,
    geoMaps: IFloorPlanEntity[],
): IFloorPlanEntity | undefined => {
    if (!isGlobalEntity(freeTextPoint)) {
        return undefined;
    }

    const sortedGeoMaps = sortMapsByDistance(geoMaps, freeTextPoint.location);

    return sortedGeoMaps[0];
};

/**
 * Map free text points to geomaps.
 * @param freeTextPoints The free text points to map.
 * @param geoMaps The geomaps to map to.
 * @returns An object with the geomap id as key and an array of free text points as value.
 */
export const mapFreeTextPointsToGeoMap = (
    freeTextPoints: IFreeTextPointEntity[],
    geoMaps: IFloorPlanEntity[],
): Record<Id, IFreeTextPointEntity[]> => {
    // use lodash groupBy to group free text points by geomap id
    const onlyGeoMaps = geoMaps.filter(isGeoMap);
    const grouped = groupBy(freeTextPoints, (freeTextPoint) => {
        const geoMap = mapFreeTextPointToGeoMap(freeTextPoint, onlyGeoMaps);
        return geoMap?._id;
    });

    delete grouped.undefined;

    return grouped;
};

/**
 * Determine on which floor plan the blocker is located.
 * @param blocker The blocker to check.
 * @param floorPlans The floor plans to check.
 * @returns The floor plan on which the blocker is located or undefined if
 * it is not located on any floor plan.
 */
const mapBlockerToFloorPlan = (
    blocker: IBlockerEntity,
    floorPlans: IFloorPlanEntity[],
): IFloorPlanEntity | undefined => {
    return floorPlans.find((floorPlan) =>
        isGeoLocated(floorPlan)
            ? isGlobalEntity(blocker) && isBlockerWithinFloorPlan(floorPlan)(blocker.latLngs)
            : floorPlan._id === blocker.path[1],
    );
};

/**
 * Map blockers to floor plans.
 * @param blockers The blockers to map.
 * @param floorPlans The floor plans to map to.
 * @returns An object with the floor plan id as key and an array of blockers as value.
 */
export const mapBlockersToFloorPlan = (
    blockers: IBlockerEntity[],
    floorPlans: IFloorPlanEntity[],
): Record<Id, IBlockerEntity[]> => {
    // We want to find the smallest floor plan that contains the installation point
    // so we sort the floor plans by size ascending first.
    const sortedFloorPlans = floorPlans.sort(byArea);

    // use lodash groupBy to group installation points by floor plan id
    const grouped = groupBy(blockers, (blocker) => {
        const floorPlan = mapBlockerToFloorPlan(blocker, sortedFloorPlans);
        return floorPlan?._id;
    });

    // remove undefined keys
    delete grouped.undefined;

    return grouped;
};

/**
 * Determine to which geomap the blocker belongs by comparing the distance
 * @param blocker The blocker to check.
 * @param geoMaps The geomaps to check.
 * @returns The geomap to which the blocker belongs or undefined if
 * it is not geo located.
 */
const mapBlockerToGeoMap = (
    blocker: IBlockerEntity,
    geoMaps: IFloorPlanEntity[],
): IFloorPlanEntity | undefined => {
    if (!isGlobalEntity(blocker)) {
        return undefined;
    }

    // Use the first point as location. Good enough!
    const location = blocker.latLngs[0];
    const sortedGeoMaps = sortMapsByDistance(geoMaps, location);

    return sortedGeoMaps[0];
};

/**
 * Map blockers to geomaps.
 * @param blockers The blockers to map.
 * @param geoMaps The geomaps to map to.
 * @returns An object with the geomap id as key and an array of blockers as value.
 */
export const mapBlockersToGeoMap = (
    blockers: IBlockerEntity[],
    geoMaps: IFloorPlanEntity[],
): Record<Id, IBlockerEntity[]> => {
    // use lodash groupBy to group blockers by geomap id
    const onlyGeoMaps = geoMaps.filter(isGeoMap);
    const grouped = groupBy(blockers, (blocker) => {
        const geoMap = mapBlockerToGeoMap(blocker, onlyGeoMaps);
        return geoMap?._id;
    });

    // remove undefined keys (blockers that are not geo located)
    delete grouped.undefined;

    return grouped;
};
