const addPrefix = (type: string) => {
    return `COMMON_${type}`;
};

export const CommonActions = {
    GetUser: addPrefix('GET_USER'),
    GetContactDetails: addPrefix('GET_CONTACT_DETAILS'),
    UpdateContactDetails: addPrefix('UPDATE_CONTACT_DETAILS'),
    GetIsAuthenticated: addPrefix('GET_IS_AUTHENTICATED'),
    Login: addPrefix('LOGIN'),
    Logout: addPrefix('LOGOUT'),
    Authorize: addPrefix('AUTHORIZE'),
    Reauthorize: addPrefix('REAUTHORIZE'),
    GetAccessToken: addPrefix('GET_ACCESS_TOKEN'),
    UpdateTokens: addPrefix('UPDATE_TOKENS'),
    SetAuthLoading: addPrefix('SET_AUTH_LOADING'),
    SetAuthFailure: addPrefix('SET_AUTH_FAILURE'),
    GetPiaDevices: addPrefix('GET_PIA_DEVICES'),
    GetCurrentProject: addPrefix('GET_CURRENT_PROJECT'),
    SetProjectServiceRate: addPrefix('SET_PROJECT_SERVICE_RATE'),
    GetLocations: addPrefix('GET_LOCATIONS'),
    GetMsrp: addPrefix('GET_MSRP'),
    GetCurrencies: addPrefix('GET_CURRENCIES'),
    GetDistributors: addPrefix('GET_DISTRIBUTORS'),
    PricesNotAvailable: addPrefix('PRICES_NOT_AVAILABLE'),
    UnloadProject: addPrefix('UNLOAD_PROJECT'),
    UnloadCurrentProject: addPrefix('UNLOAD_CURRENT_PROJECT'),
    ShowModal: addPrefix('SHOW_MODAL'),
    HideModal: addPrefix('HIDE_MODAL'),
    UpdateProjectCustomerInfo: addPrefix('UPDATE_PROJECT_CUSTOMER_INFO'),
    GetMsrpAuthStatus: addPrefix('GET_MSRP_AUTH_STATUS'),
    LoadProjectItems: addPrefix('LOAD_PROJECT_ITEMS'),
    UnloadProjectItems: addPrefix('UNLOAD_PROJECT_ITEMS'),
    GetPartnerConfig: addPrefix('GET_PARTNER_CONFIG'),
    UseAllowlistChanged: addPrefix('USE_ALLOWLIST_CHANGED'),
    ShowOverlayPanel: addPrefix('SHOW_OVERLAY_PANEL'),
    GetUserImageQuota: addPrefix('GET_USER_IMAGE_QUOTA'),
    GetCouchDBInfo: addPrefix('GET_COUCHDB_INFO'),
    UpdateCompactStatus: addPrefix('UPDATE_COMPACTION_STATUS'),
    SetCompactionInitiated: addPrefix('SET_COMPACTION_INITIATED'),
    ToastItemsChanged: addPrefix('TOAST_ITEMS_CHANGED'),
    UpdateInstallationPoint: addPrefix('UPDATE_INSTALLATION_Point'),
    ConfirmBrokenProject: addPrefix('CONFIRM_BROKEN_PROJECT'),
    LoadArchived: addPrefix('LOAD_ARCHIVED'),
    SetHasCouchDB: addPrefix('SET_HAS_COUCHDB'),
    SetIsProjectOperationInProgress: addPrefix('SET_IS_PROJECT_OPERATION_IN_PROGRESS'),
};
