import { defaultCameraExtensionFilter, Id } from 'app/core/persistence';
import { ImportedCameraExtension } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class CameraExtensionImporterService {
    public import(projectId: Id, items: ImportedCameraExtension[]) {
        const cameraExtensions = items.map((item) => ({
            ...mapToItemEntity(projectId, item, item.piaId),
            properties: {
                cameraExtension: {
                    filter: defaultCameraExtensionFilter,
                },
            },
        }));

        const accessoryEntities = getImportedAccessories(projectId, items);
        return [...cameraExtensions, ...accessoryEntities];
    }
}
