import { t } from 'app/translate';
import * as React from 'react';

import {
    Box,
    UploadFile,
    Text,
    Spinner,
    Checkbox,
    Button,
    Modal,
    Positioned,
} from 'app/components';
import { useService } from 'app/ioc';

import { useSelector } from 'react-redux';
import { getImageQuota, getIsProjectOperationInProgress } from 'app/modules/common';
import { DashboardActionService } from '../services';
import { SelectProjectListModal } from './SelectProjectListModal';
import { eventTracking } from 'app/core/tracking';
import { getProjectActionService, getProjectStoreState } from 'app/modules/userProjects';
import { useNavigate } from 'react-router-dom';

export const MergeProjectModal: React.FC<{}> = () => {
    const ACCEPTED_MERGE_PROJECT_FILE_EXTENSIONS = ['.aedp', '.asdpx'];

    const dashboardActionService = useService(DashboardActionService);

    const projectOperationInProgress = useSelector(getIsProjectOperationInProgress);
    const projectStoreState = useSelector(getProjectStoreState);
    const imageQuota = useSelector(getImageQuota);
    const projectsActionService = useSelector(getProjectActionService);
    const [backupProject, setBackupProject] = React.useState(true);
    const [openSelectProject, setOpenSelectProject] = React.useState(false);

    const [ongoingMerge, setOngoingMerge] = React.useState(false);
    const navigate = useNavigate();
    const mergeProjects = async (file: File) => {
        eventTracking.logUserEvent('Merge project', 'From file');
        if (projectsActionService) {
            projectsActionService.mergeIntoProject(
                file,
                backupProject,
                navigate,
                imageQuota.usedBytes,
                imageQuota.totalBytes,
            );
        }
    };

    const onChangeBackupProject = () => {
        setBackupProject(!backupProject);
    };

    const onClickSelectProject = () => {
        setOpenSelectProject(!openSelectProject);
    };

    const onCloseMergeProjectsModal = () => {
        dashboardActionService.showMergeProject(false);
    };

    const onCloseSelectProject = () => {
        setOpenSelectProject(false);
    };

    React.useEffect(() => {
        if (projectOperationInProgress) {
            setOngoingMerge(true);
        } else {
            if (ongoingMerge) {
                dashboardActionService.showMergeProject(false);
            }
            setOngoingMerge(false);
        }
    }, [dashboardActionService, ongoingMerge, projectOperationInProgress]);

    return openSelectProject && projectStoreState ? (
        <SelectProjectListModal
            backupProject={backupProject}
            onClose={onCloseSelectProject}
            projectStoreState={projectStoreState}
        />
    ) : (
        <Modal
            title={t.projectImportMerge}
            onClose={projectOperationInProgress ? undefined : onCloseMergeProjectsModal}
            color="white"
        >
            <Box position="relative" testId="merge_project_modal">
                {projectOperationInProgress && (
                    <Positioned position="absolute" left={0} right={0} top={0} bottom={0}>
                        <Box
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            spacing="base"
                        >
                            <Spinner size={'35px'} />
                            <Text style="body">{t.projectImportMergeStatus}</Text>
                        </Box>
                    </Positioned>
                )}
                <Box direction="column" spacing="base" hidden={projectOperationInProgress}>
                    <Box alignItems="start">
                        <Box direction="column" alignItems="start" spacing="base">
                            <Box width="60ch">
                                <Text color="grey8">{t.projectImportMergeDescription}</Text>
                            </Box>
                            <Checkbox selected={backupProject} onChange={onChangeBackupProject}>
                                {t.projectImportMergeKeep}
                            </Checkbox>
                        </Box>
                    </Box>
                    <Box justifyContent="end" spacing="half">
                        <Button
                            text
                            onClick={onCloseMergeProjectsModal}
                            disabled={projectOperationInProgress}
                        >
                            {t.close}
                        </Button>

                        <UploadFile
                            testId="select_file"
                            accept={ACCEPTED_MERGE_PROJECT_FILE_EXTENSIONS.join(',')}
                            onFileUploaded={mergeProjects}
                        >
                            <Button primary>{t.selectFile}</Button>
                        </UploadFile>
                        <Button primary onClick={onClickSelectProject}>
                            {t.selectProject}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

MergeProjectModal.displayName = 'MergeProject';
