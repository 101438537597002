import type { IBounds } from '../models';
import type { IIdRev, IMapLocationEntity, IPersistence, Id } from '../userDataPersistence';
import { MapLocationRepository } from './../userDataPersistence/repositories/MapLocation.repository';
import { injectable } from 'inversify';
import { ProjectService } from './Project.service';
import { CurrentProjectService } from './CurrentProject.service';
import { FloorPlanService } from './FloorPlan.service';
import { getOffset, mul, offset } from 'axis-webtools-util';

@injectable()
export class MapLocationsService {
    constructor(
        private mapLocationRepository: MapLocationRepository,
        private projectService: ProjectService,
        private currentProjectService: CurrentProjectService,
        private floorPlanService: FloorPlanService,
    ) {}

    public async addMapLocation(
        name: string,
        bounds: IBounds,
    ): Promise<IPersistence<IMapLocationEntity>> {
        const projectId = this.currentProjectService.getProjectId();
        const mapLocation: IMapLocationEntity = {
            type: 'mapLocation',
            path: [projectId],
            name,
            bounds,
            locked: await this.projectService.isLocked(projectId),
            archived: await this.projectService.isArchived(projectId),
        };

        const isFirstLocation =
            this.currentProjectService.getAllEntitiesOfType('mapLocation').length === 0;
        if (isFirstLocation) {
            this.updateDefaultMapLocationFromBounds(bounds);
        }

        const addedMapLocation = await this.mapLocationRepository.add(mapLocation, true, true);
        await this.projectService.updateHasFloorPlanOrMapLocation(projectId);
        return addedMapLocation;
    }

    public async deleteMapLocation(idRev: IIdRev): Promise<Id> {
        const deletedMapLocationId = await this.mapLocationRepository.delete(
            idRev._id,
            idRev._rev,
            true,
            true,
        );
        await this.projectService.updateHasFloorPlanOrMapLocation(
            this.currentProjectService.getProjectId(),
        );
        return deletedMapLocationId;
    }

    public async updateMapLocation(
        updatedMapLocation: IPersistence<IMapLocationEntity>,
    ): Promise<IPersistence<IMapLocationEntity>> {
        const persistedMapLocation = await this.mapLocationRepository.get(updatedMapLocation._id);
        if (!persistedMapLocation) {
            throw new Error(`Map location with id ${updatedMapLocation._id} not found`);
        }

        const onlyOneLocation =
            this.currentProjectService.getAllEntitiesOfType('mapLocation').length === 1;
        if (onlyOneLocation) {
            this.updateDefaultMapLocationFromBounds(updatedMapLocation.bounds);
        }

        const mapLocation: IPersistence<IMapLocationEntity> = {
            ...persistedMapLocation,
            name: updatedMapLocation.name.trim(),
            bounds: updatedMapLocation.bounds,
        };

        return this.mapLocationRepository.update(mapLocation, true, true);
    }

    private async updateDefaultMapLocationFromBounds(bounds: IBounds) {
        const location = this.getLocationFromBounds(bounds);
        await this.floorPlanService.updateDefaultMapLocation(location);
    }

    private getLocationFromBounds(bounds: IBounds) {
        return offset(bounds.topLeft)(mul(getOffset(bounds.topLeft)(bounds.bottomRight), 0.5));
    }
}
