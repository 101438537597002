import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppHeaderComponent } from './AppHeader.component';
import {
    getHeaderStyle,
    getPartnerLogo,
    getProgressPercent,
    getShowAxisLogo,
    getUserSignedIn,
} from 'app/modules/common';
import { useParams } from 'react-router-dom';
import { useService } from 'app/ioc';
import { getNotificationBannerVisibility } from '../notificationBanner/selectors';
import { NotificationBannerService } from '../notificationBanner/services';

export const AppHeader: React.FC = () => {
    const isSignedIn = useSelector(getUserSignedIn);
    const partnerLogo = useSelector(getPartnerLogo);
    const showAxisLogo = useSelector(getShowAxisLogo);
    const progressPercent = useSelector(getProgressPercent);
    const headerStyle = useSelector(getHeaderStyle);
    const notificationBannerVisibility = useSelector(getNotificationBannerVisibility);
    const notificationBannerService = useService(NotificationBannerService);

    const showBanner = () => {
        notificationBannerService.setNotificationBannerVisibility('visible');
    };

    return (
        <AppHeaderComponent
            basePath={useParams().pathname ?? '/'}
            isSignedIn={isSignedIn}
            partnerLogo={partnerLogo}
            showAxisLogo={showAxisLogo}
            progressPercent={progressPercent}
            partnerHeaderStyle={headerStyle}
            notificationBannerVisibility={notificationBannerVisibility}
            onShowBanner={showBanner}
        />
    );
};

AppHeader.displayName = 'AppHeader';
