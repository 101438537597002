import { trigonometry, UnreachableCaseError } from 'axis-webtools-util';
import type { IPiaCameraProperties, IPiaItem, IPiaSensorDevice, PiaCategory } from 'app/core/pia';
import {
    PiaItemAlerterCategory,
    PiaItemCameraCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemDecoderCategory,
    PiaItemDetectorCategory,
    PiaItemPacCategory,
    PiaItemPagingConsoleCategory,
    PiaItemPeopleCounterCategory,
    PiaItemWearablesCategory,
} from 'app/core/pia';

import { getPowerForCameraFromMaxRating, priceHighToLow, priceLowToHigh } from 'app/modules/common';
import type { IPiaItemWithPrice } from 'app/modules/common';
import type { SortOrder } from './SortOrder';
import { CHUNK_SIZE } from './SortOrder';
import type { IProductGroup } from '../IProductGroup';
import { t } from 'app/translate';
import { piaItemComparator } from 'app/utils';

const AXIS_ALL_CAPS = 'AXIS';
const AXIS_CAPITALIZED = 'Axis';
const EXPLOSION_PROTECTED_GROUP_NAME: string = 'Ex Series';
const SERIES_NAME = 'Series';
const WEARABLES_GROUP_NAME = 'W';
const UNDEFINED_SORTING_PRIORITY = 1000;

type Vendor = 'AXIS' | '2N' | 'Other';
interface ISensorWithFoVGroup extends IPiaItemWithPrice<IPiaSensorDevice> {
    fovGroup: number;
}
export enum Platforms {
    ARTPEC_8 = 'ARTPEC-8',
    ARTPEC_7 = 'ARTPEC-7',
}

export enum GroupNames {
    NON_PRIORITY = 'Non-priority devices',
    SINGLE_SENSOR_DLPU = 'singleSensorDLPU',
    MULTI_SENSOR_DLPU = 'multiSensorDLPU',
    SINGLE_SENSOR_NON_DLPU = 'singleSensorNonDLPU',
}

export class ProductSorter {
    public static sort(
        filteredProducts: Array<IPiaItemWithPrice<IPiaItem>>,
        sortOrder: SortOrder,
        isOtherProducts: boolean,
        horizontalFOVRadians?: number,
        otherVendor?: Vendor,
    ): IProductGroup[] {
        switch (sortOrder) {
            case 'alphabetical':
            case 'byName':
                return ProductSorter.sortAlphabetically(filteredProducts);
            case 'byFov':
                return ProductSorter.sortByFoV(
                    filteredProducts as Array<IPiaItemWithPrice<IPiaSensorDevice>>,
                    horizontalFOVRadians ?? 0,
                );
            case 'bySeries':
                return ProductSorter.sortBySeries(filteredProducts, isOtherProducts, otherVendor);
            case 'byPriceLowToHigh':
                return ProductSorter.sortByPrice(filteredProducts);
            case 'byPriceHighToLow':
                filteredProducts.sort(priceHighToLow);
                return ProductSorter.sortByPrice(filteredProducts, false);
            case 'byPowerConsumption':
                return ProductSorter.sortByPowerConsumption(filteredProducts);
            default:
                throw new UnreachableCaseError(sortOrder, 'Invalid speaker sort order');
        }
    }

    private static sortAlphabetically(items: Array<IPiaItemWithPrice<IPiaItem>>): IProductGroup[] {
        if (items.length < 1) {
            return [];
        }

        const sortedArray: IProductGroup[] = [];

        sortedArray.push({
            group: '',
            products: items.sort(piaItemComparator),
        });

        return sortedArray;
    }

    /**
     * Retrieves number representing the sorting priority of a device's category.
     * @param category Category of the item
     * @returns Sorting index. Lower means higher sorting priority.
     */
    private static getTwoNSortOrder = (category: PiaCategory) => {
        switch (category) {
            case PiaItemPacCategory.DOORSTATIONS:
                return 0;
            case PiaItemPacCategory.ANSWERINGUNIT:
                return 1;
            case PiaItemPacCategory.ACCESSSERVER:
                return 2;
            case PiaItemPacCategory.NETWORKREADER:
                return 3;
            default:
                return 4;
        }
    };

    private static sortBySeries(
        items: Array<IPiaItemWithPrice<IPiaItem>>,
        isOtherProducts: boolean,
        otherVendor?: Vendor,
    ) {
        const piaItemMap = new Map<string, Array<IPiaItemWithPrice<IPiaItem>>>();
        const sortedArray: IProductGroup[] = [];

        items.forEach((item) => {
            const key = ProductSorter.getSeriesKey(
                item.piaItem.properties.series,
                item.piaItem.category,
                item.piaItem.categories,
                isOtherProducts,
                otherVendor,
            );

            const existingKey = piaItemMap.get(key);

            if (!existingKey) {
                piaItemMap.set(key, [item]);
            } else {
                existingKey.push(item);
            }
        });

        // Map to IProductGroup
        piaItemMap.forEach((productsInGroup, key) => {
            sortedArray.push({
                group: key,
                // Sort cameras by name within each group
                products: productsInGroup.sort((prev, next) => {
                    const prevName = prev.piaItem.name.toUpperCase();
                    const nextName = next.piaItem.name.toUpperCase();
                    return prevName.localeCompare(nextName);
                }),
            });
        });

        // Sort on group
        return sortedArray.sort((prev, next) => {
            if (
                otherVendor === '2N' &&
                prev.products[0].piaItem.category !== next.products[0].piaItem.category
            ) {
                // For 2N products we want to group series by product categories
                // according to 2N's requested order. Since all products in a series
                // share the same category, checking the first index is sufficient.
                return (
                    this.getTwoNSortOrder(prev.products[0].piaItem.category) -
                    this.getTwoNSortOrder(next.products[0].piaItem.category)
                );
            }

            const prevTitle = prev.group.toUpperCase();
            const nextTitle = next.group.toUpperCase();

            return prevTitle.localeCompare(nextTitle);
        });
    }

    private static getSeriesKey = (
        series: string | undefined,
        category: PiaCategory,
        categories: PiaCategory[],
        isOtherProducts: boolean,
        otherVendor?: Vendor,
    ) => {
        if (otherVendor === '2N') {
            return series ?? '';
        }

        // this is to make sure Oxxo (Q1656-DLE) appear below radars in other products and not get an own
        // header as Axis Q16 series
        if (isOtherProducts && categories.includes(PiaItemDetectorCategory.RADARDETECTORS)) {
            return ProductSorter.getAxisSeriesString(
                t.otherProjectDeviceSelectorCategoriesGROUP.radarDetectors,
            );
        }

        switch (category) {
            case PiaItemCameraCategory.CAMERAEX:
                // Exchange group label for EX-cameras because of legal terms
                return EXPLOSION_PROTECTED_GROUP_NAME;
            case PiaItemDecoderCategory.DECODER:
                return ProductSorter.getAxisSeriesString(
                    t.otherProjectDeviceSelectorCategoriesGROUP.videoDecoders,
                );
            case PiaItemPacCategory.DOORCONTROLLERS:
                return ProductSorter.getAxisSeriesString(
                    t.otherProjectDeviceSelectorCategoriesGROUP.doorControllers,
                );
            case PiaItemPacCategory.DOORSTATIONS:
                return ProductSorter.getAxisSeriesString(t.doorStations);
            case PiaItemPacCategory.IORELAYS:
                return ProductSorter.getAxisSeriesString(
                    t.otherProjectDeviceSelectorCategoriesGROUP.ioRelayModules,
                );
            case PiaItemDetectorCategory.RADARDETECTORS:
                return ProductSorter.getAxisSeriesString(
                    t.otherProjectDeviceSelectorCategoriesGROUP.radarDetectors,
                );
            case PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES:
                return ProductSorter.getAxisSeriesString(
                    t.otherProjectDeviceSelectorCategoriesGROUP.connectivityDevices,
                );
            case PiaItemPeopleCounterCategory.PEOPLECOUNTERS:
                return ProductSorter.getAxisSeriesString(
                    t.otherProjectDeviceSelectorCategoriesGROUP.peopleCounters,
                );
            case PiaItemWearablesCategory.CAMERAS:
            case PiaItemWearablesCategory.CONTROLLER:
            case PiaItemWearablesCategory.DOCKING:
                return ProductSorter.getAxisSeriesString(WEARABLES_GROUP_NAME, true, true);
            case PiaItemAlerterCategory.ALERTERS:
                return ProductSorter.getAxisSeriesString(
                    t.otherProjectDeviceSelectorCategoriesGROUP.audioVisualAlerters,
                );
            case PiaItemPagingConsoleCategory.PAGINGCONSOLE:
                return ProductSorter.getAxisSeriesString(
                    t.otherProjectDeviceSelectorCategoriesGROUP.pagingConsoles,
                );

            default:
                return ProductSorter.getAxisSeriesString(series || '', true, true);
        }
    };

    private static getAxisSeriesString = (
        seriesName: string,
        includeSeriesInString?: boolean,
        axisInAllCaps?: boolean,
    ) => {
        const axisString = `${axisInAllCaps ? AXIS_ALL_CAPS : AXIS_CAPITALIZED} `;
        const seriesString = includeSeriesInString ? ` ${SERIES_NAME}` : '';
        return `${axisString}${seriesName}${seriesString}`;
    };

    public static sortByFoV(
        sensorDevices: Array<IPiaItemWithPrice<IPiaSensorDevice>>,
        horizontalFOVRadians: number,
    ) {
        const map = new Map<string, Array<IPiaItemWithPrice<IPiaSensorDevice>>>();
        const sortedArray: IProductGroup[] = [];

        sensorDevices.forEach((sensor) => {
            const key = ProductSorter.getFovGroup(sensor.piaItem, horizontalFOVRadians).toString();
            const existingKey = map.get(key);

            if (!existingKey) {
                map.set(key, [sensor]);
            } else {
                existingKey.push(sensor);
            }
        });

        // Map to IProductGroup
        map.forEach((productsInGroup, key) => {
            sortedArray.push({
                group: key,
                // Sort on 'sortingPriority' within each group
                products: productsInGroup.sort((prev, next) => {
                    return (
                        (prev.piaItem.properties.sortingPriority || UNDEFINED_SORTING_PRIORITY) -
                        (next.piaItem.properties.sortingPriority || UNDEFINED_SORTING_PRIORITY)
                    );
                }),
            });
        });

        // Sort on title that is the fov match
        return sortedArray.sort((prev, next) => {
            return Number(prev.group) - Number(next.group);
        });
    }

    public static sortByAOA(
        sensorDevices: Array<IPiaItemWithPrice<IPiaSensorDevice>>,
        horizontalFOVRadians: number,
    ): IProductGroup[] {
        const singleSensorDLPUDevices: ISensorWithFoVGroup[] = [];
        const multiSensorDLPUDevices: ISensorWithFoVGroup[] = [];
        const singleSensorNonDLPUDevices: ISensorWithFoVGroup[] = [];
        const nonPriorityDevices: ISensorWithFoVGroup[] = [];
        const allDeviceGroups = [
            singleSensorDLPUDevices,
            multiSensorDLPUDevices,
            singleSensorNonDLPUDevices,
            nonPriorityDevices,
        ];

        sensorDevices.forEach((sensor) => {
            const isSingleSensor =
                sensor.piaItem.properties.imageSensors === 1 ||
                sensor.piaItem.properties.imageSensors === undefined;
            const cameraHasDLPU = sensor.piaItem.properties.analyticsComputeCapability === 'DLPU';
            const fovGroup = ProductSorter.getFovGroup(sensor.piaItem, horizontalFOVRadians);

            if (isSingleSensor && cameraHasDLPU) {
                singleSensorDLPUDevices.push({ ...sensor, fovGroup });
            } else if (cameraHasDLPU) {
                multiSensorDLPUDevices.push({ ...sensor, fovGroup });
            } else if (isSingleSensor) {
                singleSensorNonDLPUDevices.push({ ...sensor, fovGroup });
            } else {
                nonPriorityDevices.push({ ...sensor, fovGroup });
            }
        });
        //* Sort groups internally by FoV and sorting priority
        allDeviceGroups.forEach((group) =>
            group.sort((prev, next) => {
                if (prev.fovGroup === next.fovGroup) {
                    return this.sortBySortingPriority(prev, next);
                }
                return prev.fovGroup - next.fovGroup;
            }),
        );

        return [
            { group: GroupNames.SINGLE_SENSOR_DLPU, products: singleSensorDLPUDevices },
            { group: GroupNames.MULTI_SENSOR_DLPU, products: multiSensorDLPUDevices },
            { group: GroupNames.SINGLE_SENSOR_NON_DLPU, products: singleSensorNonDLPUDevices },
            { group: GroupNames.NON_PRIORITY, products: nonPriorityDevices },
        ];
    }

    public static sortByAPD(
        sensorDevices: Array<IPiaItemWithPrice<IPiaSensorDevice>>,
        horizontalFOVRadians: number,
    ): IProductGroup[] {
        const ARTPEC8Devices: ISensorWithFoVGroup[] = [];
        const ARTPEC7Devices: ISensorWithFoVGroup[] = [];
        const nonPriorityDevices: ISensorWithFoVGroup[] = [];
        const allDeviceGroups = [ARTPEC8Devices, ARTPEC7Devices, nonPriorityDevices];

        sensorDevices.forEach((sensor) => {
            const hasARTPEC8 = sensor.piaItem.properties.platform?.includes(Platforms.ARTPEC_8);
            const hasARTPEC7 = sensor.piaItem.properties.platform?.includes(Platforms.ARTPEC_7);
            const fovGroup = ProductSorter.getFovGroup(sensor.piaItem, horizontalFOVRadians);

            if (hasARTPEC8) {
                ARTPEC8Devices.push({ ...sensor, fovGroup });
            } else if (hasARTPEC7) {
                ARTPEC7Devices.push({ ...sensor, fovGroup });
            } else {
                nonPriorityDevices.push({ ...sensor, fovGroup });
            }
        });

        //* Sort groups internally by FoV and sorting priority
        allDeviceGroups.forEach((group) =>
            group.sort((prev, next) => {
                if (prev.fovGroup === next.fovGroup) {
                    return this.sortBySortingPriority(prev, next);
                }
                return prev.fovGroup - next.fovGroup;
            }),
        );

        return [
            { group: Platforms.ARTPEC_8, products: ARTPEC8Devices },
            { group: Platforms.ARTPEC_7, products: ARTPEC7Devices },
            { group: GroupNames.NON_PRIORITY, products: nonPriorityDevices },
        ];
    }

    private static sortBySortingPriority = (
        prev: IPiaItemWithPrice<IPiaSensorDevice>,
        next: IPiaItemWithPrice<IPiaSensorDevice>,
    ) =>
        (prev.piaItem.properties.sortingPriority || UNDEFINED_SORTING_PRIORITY) -
        (next.piaItem.properties.sortingPriority || UNDEFINED_SORTING_PRIORITY);

    private static sortByPrice(
        items: Array<IPiaItemWithPrice<IPiaItem>>,
        lowToHigh = true,
    ): IProductGroup[] {
        const sortedArray: IProductGroup[] = [];
        const sortedItems = [...items.sort(lowToHigh ? priceLowToHigh : priceHighToLow)];

        // If more than 30 items - create groups of items to speed up loading
        if (sortedItems.length > CHUNK_SIZE) {
            const groupLength = Math.ceil(sortedItems.length / CHUNK_SIZE);
            for (let index = 0; index < groupLength - 1; index++) {
                sortedArray.push({
                    group: '0.' + index.toString(),
                    products: sortedItems.splice(0, CHUNK_SIZE),
                });
            }
        }
        sortedArray.push({
            group: 'last',
            products: sortedItems.splice(0),
        });

        return sortedArray;
    }

    public static sortByPowerConsumption(
        items: Array<IPiaItemWithPrice<IPiaItem>>,
    ): IProductGroup[] {
        const sortedArray: IProductGroup[] = [];
        const sortedItems = items.sort((prev, next) => {
            let prevValue = getPowerForCameraFromMaxRating(
                prev.piaItem.properties as IPiaCameraProperties,
            );
            let nextValue = getPowerForCameraFromMaxRating(
                next.piaItem.properties as IPiaCameraProperties,
            );
            // power value 0 means the value is missing, then we set value to max integer to make
            // the item be placed last in power sorting.
            prevValue = prevValue === 0 ? Number.MAX_SAFE_INTEGER : prevValue;
            nextValue = nextValue === 0 ? Number.MAX_SAFE_INTEGER : nextValue;

            return prevValue - nextValue;
        });
        sortedArray.push({
            group: '',
            products: sortedItems,
        });
        return sortedArray;
    }

    private static getFovGroup(sensor: IPiaSensorDevice, currentFovRadians: number): number {
        return (
            Math.max(
                0,
                currentFovRadians - trigonometry.toRadians(sensor.properties.maxHorizontalFOV),
            ) +
            Math.max(
                0,
                trigonometry.toRadians(sensor.properties.minHorizontalFOV) - currentFovRadians,
            )
        );
    }
}
