import { injectable } from 'inversify';
import { defaultColors } from 'app/core/common';
import {
    CreateEntityService,
    defaultMainUnitFilter,
    getDefaultCameraFilterEntity,
    getDefaultProfileOverrideEntity,
    Id,
    IItemRelationEntity,
    IMainUnitItemEntity,
    ISensorUnitItemEntity,
    UnitSystem,
} from 'app/core/persistence';
import { ImportedAxisCamera, ImportedMainUnit } from '../../../models/ImportedProjectSettingTypes';
import { createRelationEntity, getImportedAccessories, getDevicePaletteColor } from './utils';
import { IProfileEntityWithId, ProfileImporterService } from '../profiles';

@injectable()
export class MainUnitImporterService {
    constructor(
        private createEntityService: CreateEntityService,
        private profileImporterService: ProfileImporterService,
    ) {}

    public import(
        projectId: Id,
        mainUnits: ImportedMainUnit[],
        profiles: IProfileEntityWithId[],
        unitSystem: UnitSystem,
    ) {
        const mainUnitEntities = mainUnits.map((mainUnit) =>
            this.mapToMainUnitEntity(projectId, mainUnit),
        );
        const sensorUnitAndRelationEntities = mainUnits
            .flatMap((mainUnit) => {
                const sensorUnits = mainUnit.sensorUnits.map((sensorUnit) =>
                    this.mapToSensorUnitAndRelationEntity(
                        projectId,
                        mainUnit,
                        sensorUnit,
                        profiles,
                        unitSystem,
                    ),
                );

                const sensorUnitAccessories = getImportedAccessories(
                    projectId,
                    mainUnit.sensorUnits,
                );
                return [...sensorUnits, ...sensorUnitAccessories];
            })
            .flat();

        const mainUnitAccessories = getImportedAccessories(projectId, mainUnits);
        return [...mainUnitEntities, ...sensorUnitAndRelationEntities, ...mainUnitAccessories];
    }

    private mapToMainUnitEntity(projectId: Id, mainUnit: ImportedMainUnit): IMainUnitItemEntity {
        return {
            name: mainUnit.name,
            type: 'item',
            path: [projectId, mainUnit.id],
            archived: false,
            properties: {
                mainUnit: { filter: defaultMainUnitFilter },
            },
            color: getDevicePaletteColor(mainUnit.color) ?? defaultColors.DEFAULT_DEVICE_COLOR,
            description: mainUnit.description,
            productId: mainUnit.piaId,
            notes: mainUnit.notes,
            quantity: 1,
        };
    }

    private mapToSensorUnitAndRelationEntity(
        projectId: string,
        mainUnit: ImportedMainUnit,
        sensorUnit: ImportedAxisCamera,
        profiles: IProfileEntityWithId[],
        unitSystem: UnitSystem,
    ): (ISensorUnitItemEntity | IItemRelationEntity)[] {
        const sensorUnitEntity: ISensorUnitItemEntity = {
            name: sensorUnit.name,
            type: 'item',
            path: [projectId, mainUnit.id, sensorUnit.id],
            archived: false,
            properties: {
                sensorUnit: {
                    filter: getDefaultCameraFilterEntity(unitSystem, 3),
                    associatedProfile: this.profileImporterService.getProfileIdForImportedCamera(
                        sensorUnit,
                        profiles,
                    ),
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
            },
            color: getDevicePaletteColor(mainUnit.color) ?? defaultColors.DEFAULT_DEVICE_COLOR,
            description: sensorUnit.description,
            productId: sensorUnit.piaId,
            notes: sensorUnit.notes,
            quantity: 1,
        };
        const relationEntityId = this.createEntityService.generateDatabaseId('itemRelation');
        const itemRelationEntity: IItemRelationEntity = createRelationEntity(
            relationEntityId,
            [projectId, mainUnit.id],
            sensorUnit.id,
            'sensorUnit',
        );

        return [sensorUnitEntity, itemRelationEntity];
    }
}
