import type {
    ICustomItemSort,
    IFilteredMapLayers,
    IProjectDeviceSort,
    IProjectSettings,
    IUserPreferences,
    IMapType,
    RecordingCategoriesFilter,
} from '../models';

export const defaultCustomItemSortOrder: ICustomItemSort = {
    sortBy: 'name',
    direction: 'ascending',
};

export const defaultDeviceListSortOrder: IProjectDeviceSort = {
    sort: 'name',
    direction: 'ascending',
};

export const defaultRecordingCategory: RecordingCategoriesFilter = 'servers';

export const defaultFilteredMapLayers: IFilteredMapLayers = {
    deviceLayers: [],
    coverageAreaLayers: [],
    colors: [],
    textBoxesLayer: false,
    labelsLayer: false,
};

export const defaultMapType: IMapType = {
    useTinyIcons: false,
    streetModeOn: false,
};

export const defaultProjectSettings: IProjectSettings = {
    mapSettings: {
        filteredMapLayers: defaultFilteredMapLayers,
        mapType: defaultMapType,
    },
};

export const defaultUserPreferences: IUserPreferences = {
    leftMenuExpanded: true,
    addProductExpanded: false,
    showOnlyDevicesOnMap: true,
    customItemListSorting: defaultCustomItemSortOrder,
    deviceListSorting: defaultDeviceListSortOrder,
    customizeSolutionRecordingCategory: defaultRecordingCategory,
    projectSettings: {},
    selectedView: 'userprojects',
};
