import { defaultDockingStationFilter, Id } from 'app/core/persistence';
import { ImportedDockingStation } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class DockingStationImporterService {
    public import(projectId: Id, items: ImportedDockingStation[]) {
        const dockingStations = items.map((item) => ({
            ...mapToItemEntity(projectId, item, item.piaId),
            properties: {
                dockingStation: {
                    filter: defaultDockingStationFilter,
                },
            },
        }));

        const accessoryEntities = getImportedAccessories(projectId, items);
        return [...dockingStations, ...accessoryEntities];
    }
}
