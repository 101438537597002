import { reducerBuilder } from 'app/store';
import { PersistenceActions } from 'app/core/persistence';
import type {
    Id,
    IDetailedOrganization,
    IProjectDeletedPayload,
    IProjectUpdatedPayload,
} from 'app/core/persistence';
import { mapGeneratedTokenToProjectSettingToken } from '../../selectors/getProjectSettingToken';
import { SharedProjectsActions } from './SharedProjects.actions';
import type { IProjectListItem, ProjectsSortOrder } from '../../models';
import type { ISharedProjectsState } from './ISharedProjectsState';
import type { ISharedProjectTreeInfo } from '../models';

const initialState: ISharedProjectsState = {
    projects: [],
    loading: false,
    loaded: false,
    error: null,
    replicating: false,
    projectsFilter: '',
    duplicatedProjectId: null,
    showArchivedProjects: false,
    sortOrder: { sort: 'updated', direction: 'ascending' },
    selectedProjectIds: [],
    organizations: [],
    sharedProjectTreeInfo: undefined,
    selectedOrganization: undefined,
    selectedResourceGroup: undefined,
    currentDatabaseName: undefined,
};

export const sharedProjectsReducer = reducerBuilder<ISharedProjectsState>()
    .setInitialState(initialState)
    .onAsyncAction(SharedProjectsActions.INITIALIZE, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                loading: true,
                loaded: false,
            }))
            .onFulfilled<IProjectListItem[]>((state, action) => ({
                ...state,
                projects: action.payload,
                loading: false,
                loaded: true,
                error: null,
                duplicatedProjectId: null,
            }))
            .onRejected<Error>((state, action) => ({
                ...state,
                loading: false,
                loaded: true,
                error: action.payload,
            }));
    })
    .onAction<string>(SharedProjectsActions.PROJECTS_FILTER_CHANGE, (state, action) => {
        return {
            ...state,
            projectsFilter: action.payload,
        };
    })
    .onAction<Id>(SharedProjectsActions.SELECT_PROJECT, (state, action) => {
        return {
            ...state,
            selectedProjectIds: [...state.selectedProjectIds, action.payload],
        };
    })
    .onAction<Id>(SharedProjectsActions.UNSELECT_PROJECT, (state, action) => {
        return {
            ...state,
            selectedProjectIds: state.selectedProjectIds.filter((id) => id !== action.payload),
        };
    })
    .onAction<any>(SharedProjectsActions.CLEAR_SELECTED_PROJECTS, (state) => {
        return {
            ...state,
            selectedProjectIds: [],
        };
    })
    .onAction<any>(SharedProjectsActions.SET_ORGANIZATIONS, (state, action) => ({
        ...state,
        organizations: action.payload,
    }))
    .onAction<IProjectDeletedPayload>(PersistenceActions.PROJECT_DELETED, (state, action) => {
        const { projectId, projectDbOrigin, currentView } = action.payload;

        // We only want to remove the project from the state if we are in the shared projects view, see PersistenceActions.PROJECT_DELETED.
        // Also, we only want to remove the project if it belongs to the selected resource group.
        if (currentView !== 'sharedprojects' || projectDbOrigin !== state.selectedResourceGroup) {
            return state;
        }

        const userProjects = state.projects.filter((project) => projectId !== project.id);
        if (userProjects.length === state.projects.length) {
            // optimization: don't update state if no change
            // We tend to get lots of tombstones from pouch and don't want the state
            // to mutate the redux state if we don't have anything to delete
            return state;
        }

        return {
            ...state,
            projects: userProjects,
            selectedProjectIds: state.selectedProjectIds.filter((id) => projectId !== id),
        };
    })
    .onAction<IProjectUpdatedPayload>(PersistenceActions.PROJECT_UPDATED, (state, action) => {
        const { projectData, currentView } = action.payload;
        let updated = false;

        // We only want to update the state if the current view is sharedprojects, see PersistenceActions.PROJECT_UPDATED.
        // Also, we only want to update the project if it belongs to the selected resource group.
        if (
            currentView !== 'sharedprojects' ||
            projectData.projectDbOrigin !== state.selectedResourceGroup
        ) {
            return state;
        }

        // if we already have the project in the state we update it
        const userProjects: IProjectListItem[] = state.projects.map((project) => {
            if (project.id === projectData._id) {
                updated = true;
                return {
                    ...project,
                    name: projectData.name,
                    shareToken: mapGeneratedTokenToProjectSettingToken(projectData.shareToken),
                    updatedDate: new Date(projectData.updatedDate),
                    rev: projectData._rev,
                    archived: Boolean(projectData.archived),
                    locked: projectData.locked ?? project.locked,
                    state: projectData.state ?? project.state,
                    lastExportedDate: new Date(projectData.lastExportedDate),
                    devicesQuantity: projectData.devicesQuantity ?? project.devicesQuantity,
                    hasFloorPlanOrMapLocation:
                        projectData.hasFloorPlanOrMapLocation ?? project.hasFloorPlanOrMapLocation,
                };
            } else {
                return project;
            }
        });

        if (!updated) {
            // we now know that the project isn't in the state already so we can safely Append it
            userProjects.push({
                ...projectData,
                name: projectData.name,
                shareToken: mapGeneratedTokenToProjectSettingToken(projectData.shareToken),
                creationDate: new Date(projectData.creationDate),
                updatedDate: new Date(projectData.updatedDate),
                id: projectData._id,
                rev: projectData._rev,
                archived: Boolean(projectData.archived),
                state: projectData.state,
                lastExportedDate: new Date(projectData.lastExportedDate),
            });
        }

        return {
            ...state,
            projects: userProjects,
        };
    })
    .onAction<Array<Id>>(SharedProjectsActions.MULTI_DELETE_PROJECTS, (state, action) => ({
        ...state,
        projects: state.projects.filter((project) => !action.payload.includes(project.id)),
        selectedProjectIds: state.selectedProjectIds.filter((id) => !action.payload.includes(id)),
    }))
    .onAsyncAction(SharedProjectsActions.DELETE_PROJECT, (handler) => {
        handler
            .onFulfilled<string>((state, action) => ({
                ...state,
                projects: state.projects.filter((project) => project.id !== action.payload),
            }))
            .onRejected<undefined>((state) => ({
                ...state,
                loaded: false,
            }));
    })
    .onAction<IProjectListItem>(SharedProjectsActions.ADD_PROJECT, (state, action) => {
        const exists = state.projects.some(({ id }) => id === action.payload.id);
        if (exists) {
            // Project already exists in state, do nothing
            return state;
        }

        return {
            ...state,
            projects: state.projects.concat(action.payload),
        };
    })
    .onAction<any>(SharedProjectsActions.CLEAR_PROJECTS, (state) => {
        return {
            ...state,
            projects: [],
        };
    })
    .onAction<boolean>(SharedProjectsActions.SHOW_ARCHIVED_PROJECTS, (state, action) => ({
        ...state,
        showArchivedProjects: action.payload,
    }))
    .onAction<ProjectsSortOrder>(SharedProjectsActions.SET_ORDER, (state, action) => ({
        ...state,
        sortOrder: action.payload,
    }))
    .onAction<boolean>(SharedProjectsActions.SET_PROJECTS_LOADED, (state, action) => ({
        ...state,
        loaded: action.payload,
    }))
    .onAction<ISharedProjectTreeInfo>(
        SharedProjectsActions.SET_SHARED_PROJECT_INFO,
        (state, action) => ({
            ...state,
            sharedProjectTreeInfo: action.payload,
        }),
    )
    .onAction<IDetailedOrganization | undefined>(
        SharedProjectsActions.SET_SELECTED_ORGANIZATION,
        (state, action) => ({
            ...state,
            selectedOrganization: action.payload,
        }),
    )
    .onAction<string>(SharedProjectsActions.SET_SELECTED_RESOURCE_GROUP_ID, (state, action) => {
        return {
            ...state,
            selectedResourceGroup: action.payload,
        };
    })
    .onAction<string>(SharedProjectsActions.GET_SELECTED_RESOURCE_GROUP_ID, (state, action) => {
        return {
            ...state,
            selectedResourceGroup: action.payload,
        };
    })
    .onAction<string>(SharedProjectsActions.SET_SHARED_PROJECT_DATABASE_NAME, (state, action) => ({
        ...state,
        currentDatabaseName: action.payload,
    }))
    .onAsyncAction(SharedProjectsActions.REPLICATE, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                replicating: true,
            }))
            .onFulfilled((state) => ({
                ...state,
                replicating: false,
            }))
            .onRejected<Error>((state) => ({
                ...state,
                replicating: false,
            }));
    })
    .create();
