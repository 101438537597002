import * as React from 'react';
import { t } from 'app/translate';
import type { IProduct } from 'app/modules/common';
import {
    getCurrentProjectCurrency,
    getCurrentProjectLocale,
    getProductsExcludeAxisIncludedAcaps,
    getProductsWithPrices,
    TotalPrice,
} from 'app/modules/common';
import { quotationCostCalculator } from '../../util/quotationCostCalculator';
import type { IStoreState } from 'app/store';
import { connect } from 'react-redux';

export type ProductsType = 'products' | 'services';

interface IQuotationSectionTotalPropsOwnProps {
    productType: ProductsType;
    editMode?: boolean;
    hideIfZero?: boolean;
}

interface IQuotationSectionTotalProps {
    productType: ProductsType;
    products: IProduct[];
    currency: string;
    locale: string;
    editMode?: boolean;
    hideIfZero?: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IQuotationSectionTotalPropsOwnProps,
): IQuotationSectionTotalProps => {
    const products = getProductsWithPrices(storeState);
    return {
        editMode: ownProps.editMode,
        productType: ownProps.productType,
        hideIfZero: ownProps.hideIfZero,
        locale: getCurrentProjectLocale(storeState),
        currency: getCurrentProjectCurrency(storeState),
        products: getProductsExcludeAxisIncludedAcaps(storeState, products),
    };
};

class QuotationSectionTotalContainer extends React.Component<IQuotationSectionTotalProps> {
    public render() {
        const { productType, products, currency, locale, editMode, hideIfZero } = this.props;

        if (productType === 'services' && products.length === 0) {
            return null;
        }

        const amount =
            this.props.productType === 'services'
                ? 0
                : quotationCostCalculator.calculateTotalCost(this.props.products);

        if (productType === 'services' && hideIfZero && amount === 0) {
            return null;
        }

        const defaultTotal =
            productType === 'services' ? t.quotationTotalServices : t.quotationSummary;

        return (
            <TotalPrice
                editMode={editMode}
                amount={amount}
                currency={currency.toUpperCase()}
                locale={locale}
                totalText={defaultTotal}
            />
        );
    }
}
export const QuotationSectionTotal = connect(mapStateToProps)(QuotationSectionTotalContainer);
