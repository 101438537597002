import { injectable } from 'inversify';
import { MigrationProviderBase } from '../MigrationProviderBase';
import { isDoorStation } from '../../../utils/piaDeviceTypeCheckers';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import { defaultPacFilter } from '../../entities';

/**
 * Some old doorstations in old projects seems to be added with only property camera
 * This migration will check door stations that are available today in pia sync and
 * add pac default filter to those doorstations that miss pac properties
 */
@injectable()
export class Migration51To52 extends MigrationProviderBase {
    public from: number = 51;
    public to: number = 52;

    constructor(private piaItemService: PiaItemService<IPiaItem>) {
        super();
    }

    public provideMigration() {
        // all Axis door stations synced to pia 24-12-12
        const AXIS_DOORSTATIONS_241212 = [
            19463, 22719, 44902, 62354, 62429, 76690, 76693, 92466, 92468, 96900,
        ];
        return async (entity: any): Promise<any> => {
            if (
                AXIS_DOORSTATIONS_241212.includes(entity.productId) &&
                entity.type === 'item' &&
                !entity.properties.pac
            ) {
                const piaItem = entity.productId
                    ? this.piaItemService.get(entity.productId).first()
                    : null;

                if (isDoorStation(piaItem)) {
                    entity.properties['pac'] = { filter: defaultPacFilter };
                }
            }
            return entity;
        };
    }
}
