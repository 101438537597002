import { injectable } from 'inversify';
import {
    PersistenceDatabaseRepository,
    ProjectDbOrigin,
    ProjectDbOriginAsdUserData,
} from '../userDataPersistence';
import { ProjectCustomerInfoService } from './ProjectCustomerInfo.service';
import { UserPreferencesService } from './UserPreferences.service';
import { PriceService } from './Price.service';
import { ContactDetailsService } from './ContactDetails.service';
import { UserService } from './user';
import { PartnerConfigService } from './partnerConfig';
import { removeItemLocalStorage } from '../utils';
import { SharedProjectsService } from './sharedProjects/SharedProjects.service';

@injectable()
export class CleanupService {
    constructor(
        private projectCustomerInfoService: ProjectCustomerInfoService,
        private userPreferencesService: UserPreferencesService,
        private persistenceRepository: PersistenceDatabaseRepository,
        private userService: UserService,
        private priceService: PriceService,
        private contactDetailsService: ContactDetailsService,
        private partnerConfigService: PartnerConfigService,
        private sharedProjectsService: SharedProjectsService,
    ) {}

    public async clearAllUserData(): Promise<void> {
        this.userPreferencesService.clear();
        removeItemLocalStorage('RecentlyAddedProducts');

        // first delete all local data
        await Promise.all([
            this.projectCustomerInfoService.clearCustomerInfo(),
            this.persistenceRepository.clear(),
            this.priceService.clearMsrpData(),
            this.contactDetailsService.clear(),
            this.partnerConfigService.clear(),
        ]);

        // Finally delete user data where we store the last logged in user data
        // This is where the hid of the previously logged-in user is stored.
        // If the previousUserHid differs from the current user's hid upon login we
        // know that the previous user's data hasn't been properly cleared and call
        // clearAllUserData() again.
        await this.userService.clearUserInfo();

        // Clear shared projects info
        await this.sharedProjectsService.clearSharedProjectInfo();
    }

    /**
     * Clear data for the given projectDbOrigin.
     * @param projectDbOrigin The projectDbOrigin to clear data for.
     * @returns true if data was cleared, false otherwise.
     */
    public async clearData(projectDbOrigin: ProjectDbOrigin): Promise<boolean> {
        if (projectDbOrigin === ProjectDbOriginAsdUserData) {
            return this.clearUserData();
        }
        return false; // No old data to clear
    }

    /**
     * Clear user data if the previous user is different from the current user.
     */
    private async clearUserData(): Promise<boolean> {
        const hasUserChanged = await this.userService.hasUserChanged();
        if (hasUserChanged) {
            // We are logged in with a different user - start cleaning
            await this.clearAllUserData();
            return true;
        }

        // Logged in user is same as previous user - do nothing
        return false;
    }
}
