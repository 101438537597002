import { injectable } from 'inversify';
import { eventTracking } from 'app/core/tracking';
import type { ISharedProjectInfo, OrganizationId, ResourceGroupId } from '../../models';
import { getItemLocalStorageJSON, setItemLocalStorageJSON } from '../../utils';

export interface ISharedProjectInfoLocalStorage {
    current?: ISharedProjectInfo;
    previous?: ISharedProjectInfo;
}

// Regexp to parse "arn:organization:e3ed8967-10c6-4d53-b3a0-e93374836aa5" into "e3ed8967-10c6-4d53-b3a0-e93374836aa5"
const ARN_REGEX = /arn:organization:(.*)/;

@injectable()
export class SharedProjectRepository {
    /**
     * Get current organization id from the shared project info.
     * @returns organizationId as string "e3ed8967-10c6-4d53-b3a0-e93374836aa5".
     */
    public getSelectedOrganizationId = async (): Promise<OrganizationId | undefined> => {
        const sharedProjectInfo = await this.getCurrentSharedProjectInfo();
        if (!sharedProjectInfo) {
            return undefined;
        }
        const arnMatch = sharedProjectInfo.organization.arn.match(ARN_REGEX);
        if (arnMatch) {
            return arnMatch[1];
        }
        return undefined;
    };

    /**
     * Get the resource group id from the shared project info
     * @returns resourceGroupId as string "e3ed8967-10c6-4d53-b3a0-e93374836aa5".
     */
    public getSelectedResourceGroupId = async (): Promise<ResourceGroupId | undefined> => {
        const sharedProjectInfo = await this.getCurrentSharedProjectInfo();
        if (!sharedProjectInfo) {
            return undefined;
        }
        return sharedProjectInfo.resourceGroup.arn;
    };

    /**
     * Get current shared project info.
     * @returns shared project info.
     * @throws Error if retrieving shared project info failed.
     */
    public getSelectedSharedProjectInfo = async (): Promise<ISharedProjectInfo | undefined> => {
        try {
            const sharedProjectInfo = await this.getCurrentSharedProjectInfo();
            return sharedProjectInfo;
        } catch (error) {
            eventTracking.logError(
                'Retrieving shared project info failed.',
                'SharedProjectRepository',
            );
            throw new Error('Retrieving shared project info failed.');
        }
    };

    /**
     * Clear shared project info.
     */
    public clearSharedProjectInfo = async () => {
        setItemLocalStorageJSON('SharedProjectInfo', {});
    };

    /**
     * Get the previous shared project info.
     * @returns previous shared project info.
     * @throws Error if retrieving previous shared project info failed.
     */
    public async getPreviousSelectedSharedProjectInfo(): Promise<ISharedProjectInfo | undefined> {
        try {
            const previousOrganizationOrResourceGroup = await this.getPreviousSharedProjectInfo();
            if (!previousOrganizationOrResourceGroup) {
                const organizationOrResourceGroup = await this.getSelectedSharedProjectInfo();
                return organizationOrResourceGroup;
            }
            return previousOrganizationOrResourceGroup;
        } catch {
            eventTracking.logError(
                'Retrieving previous shared project info failed.',
                'SharedProjectRepository',
            );
            throw new Error('Retrieving previous shared project info failed.');
        }
    }

    /**
     * Set current shared project info.
     * @param sharedProjectInfo - shared project info to be stored.
     * @throws Error if storing shared project info failed.
     */
    public setSelectedSharedProjectInfo = async (
        sharedProjectInfo: ISharedProjectInfo,
    ): Promise<void> => {
        try {
            const sharedProjectInfoLocalStorage: ISharedProjectInfoLocalStorage = {
                current: sharedProjectInfo,
                previous: await this.getCurrentSharedProjectInfo(),
            };
            setItemLocalStorageJSON('SharedProjectInfo', sharedProjectInfoLocalStorage);
        } catch (error) {
            eventTracking.logError(
                'Storing shared project info failed.',
                'SharedProjectRepository',
            );
            throw new Error('Storing shared project info failed.');
        }
    };

    /**
     * Set previous shared project info.
     * @param organizationOrResourceGroup - previous shared project info to be stored.
     * @throws Error if storing previous shared project info failed.
     */
    public async setPreviousSelectedSharedProjectInfo(
        organizationOrResourceGroup: ISharedProjectInfo,
    ): Promise<void> {
        try {
            const sharedProjectInfoLocalStorage: ISharedProjectInfoLocalStorage = {
                current: await this.getCurrentSharedProjectInfo(),
                previous: organizationOrResourceGroup,
            };
            setItemLocalStorageJSON('SharedProjectInfo', sharedProjectInfoLocalStorage);
        } catch {
            eventTracking.logError(
                'Storing  previous shared project info failed.',
                'SharedProjectRepository',
            );
            throw new Error('Storing  previous shared project info failed.');
        }
    }

    private getCurrentSharedProjectInfo = async (): Promise<ISharedProjectInfo | undefined> => {
        const sharedProjectInfoLocalStorage =
            getItemLocalStorageJSON<ISharedProjectInfoLocalStorage>('SharedProjectInfo', {});
        if (sharedProjectInfoLocalStorage.current) {
            return sharedProjectInfoLocalStorage.current;
        }
        return undefined;
    };

    private getPreviousSharedProjectInfo = async (): Promise<ISharedProjectInfo | undefined> => {
        const sharedProjectInfoLocalStorage =
            getItemLocalStorageJSON<ISharedProjectInfoLocalStorage>('SharedProjectInfo', {});
        if (sharedProjectInfoLocalStorage.previous) {
            return sharedProjectInfoLocalStorage.previous;
        }
        return undefined;
    };
}
