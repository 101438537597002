import {
    defaultPacFilter,
    getDefaultCameraFilterEntity,
    getDefaultProfileOverrideEntity,
    IAccessoryItemEntity,
    Id,
    IDoorStationItemEntity,
    IEnvironmentItemEntity,
    IItemRelationEntity,
    UnitSystem,
} from 'app/core/persistence';
import { injectable } from 'inversify';
import { ImportedDoorStation } from '../../../models';
import { IProfileEntityWithId, ProfileImporterService } from '../profiles';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class DoorStationsImporterService {
    constructor(private profileImporterService: ProfileImporterService) {}

    public import(
        projectId: Id,
        doorStations: ImportedDoorStation[],
        profiles: IProfileEntityWithId[],
        unitSystem: UnitSystem,
    ): (
        | IDoorStationItemEntity
        | IAccessoryItemEntity
        | IEnvironmentItemEntity
        | IItemRelationEntity
    )[] {
        const doorStationEntities = doorStations.map((doorStation) =>
            this.mapToDoorStationEntity(projectId, doorStation, profiles, unitSystem),
        );

        const accessoryEntities = getImportedAccessories(projectId, doorStations);
        return [...doorStationEntities, ...accessoryEntities];
    }

    private mapToDoorStationEntity = (
        projectId: Id,
        doorStation: ImportedDoorStation,
        profiles: IProfileEntityWithId[],
        unitSystem: UnitSystem,
    ) => {
        const itemEntity = mapToItemEntity(projectId, doorStation, doorStation.piaId);

        return {
            ...itemEntity,
            replaceWithBareboneId: doorStation.replaceWithBareboneId,
            properties: {
                camera: {
                    filter: getDefaultCameraFilterEntity(unitSystem, 3),
                    associatedProfile: this.profileImporterService.getProfileIdForImportedCamera(
                        doorStation,
                        profiles,
                    ),
                    profileOverride: getDefaultProfileOverrideEntity(),
                },
                pac: { filter: defaultPacFilter },
            },
        } satisfies IDoorStationItemEntity;
    };
}
