import { injectable } from 'inversify';
import type {
    IItemEntity,
    IPersistence,
    IDoorStationItemEntity,
    ProjectZipType,
    BandwidthVersion,
    Id,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import type { Frequency } from 'app/modules/common';
import {
    StorageCalculationService,
    ProfileOverrideService,
    ProfileSupportService,
    BandwidthCalculatorService,
    ScenarioService,
} from 'app/modules/common';

import type { IExportablePersistedEntity, IExportedDoorStation } from '../../../models';
import { isDefined } from 'axis-webtools-util';
import { BaseItemExporterService } from './BaseItemExporter.service';
import type { IPiaDevice, IPiaDoorStation } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import { toShareModelNames } from '../../mapToShareItemNames';

@injectable()
export class DoorStationsExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaDevice>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedDoorStations = async (
        projectZipSetting: ProjectZipType,
        items: IExportablePersistedEntity[],
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
        projectId: Id,
    ): Promise<IExportedDoorStation[]> => {
        const allDoorStations = items.filter(this.isDoorStationItemEntity);
        const mappedCameras = await Promise.all(
            allDoorStations.map(async (doorStation) =>
                this.mapDoorStationToExportedDoorStation(
                    projectZipSetting,
                    doorStation,
                    frequency,
                    projectBandwidthVersion,
                    projectRetentionTimeInDays,
                    projectId,
                ),
            ),
        );

        return mappedCameras.filter(isDefined);
    };

    private mapDoorStationToExportedDoorStation = async (
        projectZipSetting: ProjectZipType,
        doorStationItem: IExportablePersistedEntity,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
        projectId: Id,
    ): Promise<IExportedDoorStation | null> => {
        if (!doorStationItem.productId) {
            return null;
        }
        const doorStationPiaDevice = this.getPiaDevice(
            doorStationItem.productId,
        ) as IPiaDoorStation;

        const mergedProfile = await this.profileOverrideService.getMergedProfileFromDevice(
            (doorStationItem as IDoorStationItemEntity).properties.camera,
            projectRetentionTimeInDays,
        );

        const exportedDoorStation = await this.mapCameraToExportedCamera(
            projectZipSetting,
            doorStationItem,
            mergedProfile,
            doorStationPiaDevice.properties,
            doorStationPiaDevice.category,
            frequency,
            projectBandwidthVersion,
            projectId,
        );

        return {
            ...exportedDoorStation,
            ...toShareModelNames(doorStationPiaDevice.name),
            piaId: doorStationItem.productId,
        };
    };

    private isDoorStationItemEntity(
        item: IPersistence<IItemEntity>,
    ): item is IPersistence<IDoorStationItemEntity> {
        return deviceTypeCheckers.isDoorStation(item);
    }
}
