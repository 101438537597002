import * as React from 'react';
import { ServiceLocator } from 'app/ioc';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import {
    Heading,
    Box,
    Stack,
    IconButton,
    EditableText,
    Modal,
    Card,
    Textarea,
    Text,
    Positioned,
    OverlayPanel,
    EditableParagraph,
    Clickable,
    Button,
} from 'app/components';
import { ProjectLocation } from './ProjectLocation.container';
import { EditProjectSettings } from './EditProjectSettings.container';
import { EditProjectLocation } from './EditProjectLocation.component';
import { ProjectStats } from './ProjectStats.container';
import type { IProjectSettingToken } from 'app/modules/exportProjectSettings';
import { ExportProject } from 'app/modules/exportProjectSettings';
import { t } from 'app/translate';
import type { ILocation } from 'app/modules/common';
import {
    CommonActionService,
    getCurrentProjectName,
    getCurrentProjectNotes,
    getCurrentProjectLocked,
    getCurrentProject,
    getOverlayPanelOpen,
    ProjectNetworkModal,
} from 'app/modules/common';
import { ProfilesList, ProfileEditor } from 'app/modules/profiles';
import type { IBounds, Id, SelectedView } from 'app/core/persistence';
import { MapLocationsService, ProjectService } from 'app/core/persistence';
import { SpacingsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { AppConstants } from 'app/AppConstants';
import { SchedulesEditor, SchedulesList } from 'app/modules/schedules';
import { DashboardActionService } from '../services';
import { debounce } from 'lodash-es';
import { QuotationProgress } from 'app/modules/quotation';
import { getProjectSettingToken } from 'app/modules/userProjects';
import { MergeProjectModal } from './MergeProjectModal';
import { eventTracking } from 'app/core/tracking';
import { getSelectedView } from 'app/modules/application';

export interface IDashboardContainerProps {
    project: IStoreState['currentProject']['project'];
    projectName: string;
    projectNotes: string;
    projectLocked: boolean;
    preparedFor: string;
    showEditLocation: boolean;
    showEditSettings: boolean;
    showExport: boolean;
    showMerge: boolean;
    showProjectNetwork: boolean;
    panelOpen: boolean;
    projectShareToken: IProjectSettingToken | null;
    selectedView: SelectedView;
}

export interface IDashboardContainerState {
    editProfileId?: Id;
    editScheduleId?: Id;
    color?: React.CSSProperties['color'];
}

const itemContainerStyle = css`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: start;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const itemListStyle = css`
    width: 49%;
    padding-bottom: 16px;

    @media screen and (max-width: 1024px) {
        width: 100%;
        padding-bottom: 32px;
    }
`;

const mapStateToProps = (storeState: IStoreState): IDashboardContainerProps => {
    return {
        projectName: getCurrentProjectName(storeState),
        projectNotes: getCurrentProjectNotes(storeState),
        projectLocked: getCurrentProjectLocked(storeState),
        preparedFor: storeState.common.projectCustomerInfo,
        showEditSettings: storeState.dashboard.showEditProjectSettings,
        showEditLocation: storeState.dashboard.showEditProjectLocation,
        showExport: storeState.dashboard.showShare,
        showMerge: storeState.dashboard.showMerge,
        showProjectNetwork: storeState.dashboard.showProjectNetwork,
        panelOpen: getOverlayPanelOpen(storeState),
        project: getCurrentProject(storeState),
        projectShareToken: getProjectSettingToken(storeState),
        selectedView: getSelectedView(storeState),
    };
};

export class DashboardContainer extends React.Component<
    IDashboardContainerProps,
    IDashboardContainerState
> {
    private commonActionService: CommonActionService;
    private actions: DashboardActionService;
    private projectService: ProjectService;
    private mapLocationService: MapLocationsService;

    constructor(props: IDashboardContainerProps) {
        super(props);
        this.commonActionService = ServiceLocator.get(CommonActionService);
        this.actions = ServiceLocator.get(DashboardActionService);
        this.projectService = ServiceLocator.get(ProjectService);
        this.mapLocationService = ServiceLocator.get(MapLocationsService);
        this.state = {
            editProfileId: undefined,
            editScheduleId: undefined,
        };
    }

    public componentWillUnmount() {
        this.setState({
            editProfileId: undefined,
            editScheduleId: undefined,
        });
        this.hidePanel();
    }

    public render() {
        return !this.props.project ? null : (
            <Box testId="panel_dashboard" height="100%" position="relative" overflowX="hidden">
                <OverlayPanel
                    active={this.props.panelOpen}
                    maxWidth="70%"
                    minWidth="775px"
                    onInactivate={this.hidePanel}
                >
                    {this.state.editProfileId ? (
                        <ProfileEditor
                            profileId={this.state.editProfileId}
                            onClose={this.hidePanel}
                        />
                    ) : (
                        <SchedulesEditor onClose={this.hidePanel} />
                    )}
                </OverlayPanel>
                <Box
                    position="relative"
                    width="100%"
                    height="100%"
                    overflowY="auto"
                    justifyContent="center"
                >
                    <Box width="100%" maxWidth="1300px" padding="panel" position="relative">
                        <Stack vertical spacing="panel">
                            <Stack justifyContent="between" flex="dontShrink">
                                <Heading style="headline" width="100%">
                                    <EditableText
                                        value={this.props.projectName}
                                        changeCriteria="blur"
                                        onChange={this.changeProjectName}
                                        maxLength={AppConstants.projectNameMaxLength}
                                        placeholder={t.projectProjectName}
                                        testId={this.props.projectName + '_PName'}
                                        required
                                    />
                                </Heading>
                                <Stack spacing="panel">
                                    <IconButton
                                        testId="btn_location"
                                        onClick={this.toggleEditLocation}
                                        icon="place"
                                        disabled={this.props.projectLocked}
                                        text={t.location}
                                        wrapText={false}
                                    />
                                    <IconButton
                                        testId="btn_share"
                                        onClick={this.toggleShare}
                                        opaque
                                        icon="export"
                                        text={t.exportProject}
                                        wrapText={false}
                                    />
                                    <IconButton
                                        testId="btn_share"
                                        onClick={this.toggleMerge}
                                        opaque
                                        icon="download_file"
                                        text={t.projectImportMerge}
                                        wrapText={false}
                                    />
                                    <IconButton
                                        testId="btn_settings"
                                        onClick={this.toggleEditSettings}
                                        opaque
                                        icon="settings"
                                        text={t.installationReportSettingsTitle}
                                        wrapText={false}
                                    />
                                    <IconButton
                                        testId="btn_IP_address"
                                        onClick={this.toggleProjectNetwork}
                                        opaque
                                        icon="recording_bw_xs"
                                        wrapText={false}
                                        text={t.ipAddresses}
                                    />
                                </Stack>
                            </Stack>

                            <Box maxHeight="320px" flex="dontShrink">
                                <Clickable
                                    onClick={
                                        this.props.projectLocked
                                            ? undefined
                                            : this.toggleEditLocation
                                    }
                                >
                                    <ProjectLocation />
                                </Clickable>

                                <Positioned
                                    position="absolute"
                                    top="110px"
                                    insetInlineStart={SpacingsEnum.doublePanel} //Logical property for RTL support
                                >
                                    <Card
                                        notFullWidth
                                        minWidth="223px"
                                        paddingX="base"
                                        paddingY="half"
                                        shadow
                                    >
                                        <Stack vertical spacing="half">
                                            <Text style="semibold" color="grey5">
                                                {t.projectProgress}
                                            </Text>
                                            <QuotationProgress />
                                        </Stack>
                                    </Card>
                                </Positioned>

                                <Box
                                    direction="column"
                                    width="320px"
                                    paddingLeft="base"
                                    justifyContent="between"
                                >
                                    <Text onlyStyle color="grey7" style="semibold">
                                        <EditableParagraph
                                            placeholder={t.labelPreparedFor}
                                            testId="prepared_for"
                                            value={this.props.preparedFor}
                                            onChange={debounce(this.setPreparedFor, 800)}
                                            rows={6}
                                            disabled={this.props.projectLocked}
                                        />
                                    </Text>
                                    <Card paddingX="base">
                                        <Stack vertical spacing="half">
                                            <Text style="semibold" color="grey5">
                                                {t.notes}
                                            </Text>
                                            <Textarea
                                                noPadding
                                                testId="note"
                                                rows={9}
                                                onChange={this.changeNotes}
                                                noBorder
                                                value={this.props.projectNotes}
                                            />
                                        </Stack>
                                    </Card>
                                </Box>
                            </Box>

                            <Box paddingY="panel" justifyContent="center" flex="dontShrink">
                                <ProjectStats />
                            </Box>

                            <div className={itemContainerStyle}>
                                <div className={itemListStyle}>
                                    <ProfilesList
                                        onEditProfile={(profileId) => {
                                            if (
                                                this.props.panelOpen &&
                                                this.state.editProfileId === profileId
                                            ) {
                                                this.hidePanel();
                                            } else {
                                                this.showPanel();
                                            }

                                            this.setState({
                                                editScheduleId: undefined,
                                                editProfileId: profileId,
                                            });
                                        }}
                                    />
                                </div>

                                <div className={itemListStyle}>
                                    <SchedulesList
                                        onEditSchedule={(scheduleId) => {
                                            if (
                                                this.props.panelOpen &&
                                                this.state.editScheduleId === scheduleId
                                            ) {
                                                this.hidePanel();
                                            } else {
                                                this.showPanel();
                                            }

                                            this.setState({
                                                editScheduleId: scheduleId,
                                                editProfileId: undefined,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </Stack>
                        {this.props.showEditLocation && (
                            <Modal title={t.location} onClose={this.toggleEditLocation}>
                                <EditProjectLocation
                                    preparedFor={this.props.preparedFor}
                                    locationId={this.props.project?.installationPiaLocationId ?? 0}
                                    locationName={this.props.project?.locationName}
                                    defaultLocation={this.props.project?.location}
                                    defaultBounds={this.props.project?.bounds}
                                    onSave={this.saveLocation}
                                    onCancel={this.toggleEditLocation}
                                />
                            </Modal>
                        )}
                        {this.props.showEditSettings && (
                            <Modal
                                title={t.installationReportSettingsTitle}
                                onClose={this.toggleEditSettings}
                            >
                                <EditProjectSettings />
                            </Modal>
                        )}
                        {this.props.showExport && (
                            <Modal
                                title={t.exportProject}
                                onClose={this.toggleShare}
                                color="white"
                                maxWidth="900px"
                            >
                                <ExportProject
                                    projectId={this.props.project._id}
                                    projectSettingToken={this.props.projectShareToken}
                                />
                                <Box justifyContent="end">
                                    <Button text onClick={this.toggleShare}>
                                        {t.close}
                                    </Button>
                                </Box>
                            </Modal>
                        )}
                        {this.props.showMerge && <MergeProjectModal />}
                        {this.props.showProjectNetwork && (
                            <ProjectNetworkModal onClose={this.toggleProjectNetwork} />
                        )}
                    </Box>
                </Box>
            </Box>
        );
    }

    private showPanel = () => {
        this.commonActionService.showOverlayPanel(true);
    };

    private hidePanel = () => {
        this.commonActionService.showOverlayPanel(false);
    };

    private setPreparedFor = async (preparedFor: string) => {
        this.commonActionService.updateProjectCustomerInfo(preparedFor);
    };

    private toggleEditLocation = () => {
        this.actions.editProjectLocation(!this.props.showEditLocation);
    };

    private toggleEditSettings = () => {
        this.actions.editProjectSettings(!this.props.showEditSettings);
    };

    private toggleShare = () => {
        this.actions.showShareProject(!this.props.showExport);
    };

    private toggleMerge = () => {
        eventTracking.logUserEvent('Merge project', 'Modal opened');
        this.actions.showMergeProject(!this.props.showMerge);
    };

    private changeProjectName = (name: string) => {
        if (name.trim()) {
            this.projectService.updateCurrentProject({ name });
        }
    };

    private changeNotes = (notes: string) => {
        this.projectService.updateCurrentProject({ notes });
    };

    private toggleProjectNetwork = () => {
        this.actions.showProjectNetwork(!this.props.showProjectNetwork);
    };

    private saveLocation = (
        preparedFor: string,
        location?: ILocation,
        bounds?: IBounds,
        locationName?: string,
        installationPiaLocationId?: number,
    ) => {
        this.projectService.updateCurrentProject({
            location,
            bounds,
            locationName,
            installationPiaLocationId,
        });
        this.commonActionService.updateProjectCustomerInfo(preparedFor);
        bounds &&
            locationName &&
            this.mapLocationService.addMapLocation(
                locationName.split('\n')[0] ?? t.newLocation,
                bounds,
            );
        this.toggleEditLocation();
    };
}

export const Dashboard = connect(mapStateToProps)(DashboardContainer);
