type callback<A extends any[], R> = (...args: A) => R;

/**
 * Memoizes a callback function with a cache of the given size.
 */
export function memoize<A extends any[], R>(
    callbackFunction: callback<A, R>,
    size: number,
): callback<A, R> {
    const cache = new Map<string, any>();

    return (...args: A) => {
        const key = JSON.stringify(args);
        if (cache.has(key)) {
            return cache.get(key);
        }

        const result = callbackFunction(...args);
        cache.set(key, result);

        if (cache.size > size) {
            cache.delete(cache.keys().next().value as string);
        }

        return result;
    };
}
