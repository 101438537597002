import * as React from 'react';
import { useSelector } from 'react-redux';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { Box, IconButton, NoPrint, Positioned, Spacer } from 'app/components';
import type { DeviceGroup, IDeviceGroupInfo } from './models';
import { DeviceSelectorActionService } from './services';
import { FilterPanelSelector, ProductSelector, DeviceTabs } from './components';
import { getDeviceGroup, getDeviceGroups, getSelectedDeviceGroup } from './selectors';
import { getCurrentProjectItem } from 'app/modules/common';
import type { PiaCategory } from 'app/core/pia';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import type { Property } from 'csstype';
import { isPageRTL, t } from 'app/translate';
import { css } from '@emotion/css';
import { AppConstants } from 'app/AppConstants';

export interface IDeviceSelectorViewProps {
    /**
     * Device id for device to be edited
     */
    editDeviceId?: Id;
    /**
     * DeviceGroup to render when selecting a new device.
     */
    urlDeviceGroup?: DeviceGroup;
    /**
     * Id of the parent device (main unit)
     */
    parentId?: Id;
    /**
     * If all tabs should be shown
     */
    showAll?: boolean;
    /**
     * If defined selected category of deviceGroup to show
     */
    category?: PiaCategory;
}

export const DeviceSelector: React.FunctionComponent<IDeviceSelectorViewProps> = ({
    editDeviceId,
    urlDeviceGroup,
    parentId,
    showAll,
    category,
}) => {
    const [actionService] = React.useState<DeviceSelectorActionService>(
        ServiceLocator.get(DeviceSelectorActionService),
    );

    const selectedDeviceGroup = useSelector(getDeviceGroup);
    const deviceGroup = urlDeviceGroup ?? selectedDeviceGroup;

    const deviceGroups = useSelector<IStoreState, Record<DeviceGroup, IDeviceGroupInfo>>((state) =>
        getDeviceGroups(state),
    );

    const editDeviceItem = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>(
        (state) => (editDeviceId ? getCurrentProjectItem(state, editDeviceId) : undefined),
    );

    const editDeviceGroupInfo = useSelector<IStoreState, IDeviceGroupInfo | undefined>((state) =>
        editDeviceId ? getSelectedDeviceGroup(state, editDeviceId) : undefined,
    );

    const isViewExpanded = useSelector<IStoreState, boolean>(
        (store) => store.deviceSelector.isFilterPanelExpanded,
    );

    const onExpandFilterPanel = () => {
        actionService.setFilterPanelExpanded(true);
    };

    const isRTL = isPageRTL();

    const stateToPanelTranslation: Record<TransitionStatus, Property.Translate<string>> = {
        unmounted: '-100%',
        entering: '-100%',
        entered: '0',
        exiting: '-100%',
        exited: '-100%',
    };

    //RTL support
    const stateToPanelTranslationRTL: Record<TransitionStatus, Property.Translate<string>> = {
        unmounted: '100%',
        entering: '100%',
        entered: '0',
        exiting: '100%',
        exited: '100%',
    };

    const stateToExpandButtonTranslation: Record<TransitionStatus, Property.Translate<string>> = {
        unmounted: '0',
        entering: '0',
        entered: '-100%',
        exiting: '0',
        exited: '0',
    };

    //RTL support
    const stateToExpandButtonTranslationRTL: Record<
        TransitionStatus,
        Property.Translate<string>
    > = {
        unmounted: '0',
        entering: '100%',
        entered: '100%',
        exiting: '100%',
        exited: '0',
    };

    const stateToWidth: Record<TransitionStatus, Property.Width<string>> = {
        unmounted: '25px',
        entering: '25px',
        entered: '440px',
        exiting: '25px',
        exited: '25px',
    };

    const filterPaneldeviceTabStyle = css`
        z-index: ${AppConstants.deviceTabDepth};
    `;

    React.useEffect(() => {
        const info = editDeviceGroupInfo
            ? editDeviceGroupInfo
            : deviceGroup
              ? deviceGroups[deviceGroup]
              : deviceGroups['cameras'];
        if (category) {
            info.categories = [category];
        }
        actionService.setDeviceGroupInfo(info);
        actionService.setEditDeviceId(editDeviceId ?? null);
        actionService.setParentId(parentId ?? null);
    }, [
        actionService,
        category,
        deviceGroup,
        deviceGroups,
        editDeviceGroupInfo,
        editDeviceId,
        parentId,
    ]);

    React.useEffect(() => {
        actionService.setDefaultFilters(editDeviceItem);
        return () => {
            actionService.resetFilters();
        };
    }, [actionService, editDeviceItem]);

    return (
        <Box testId="device_selector_container" width="100%" minHeight="100%" color="white">
            <Transition in={isViewExpanded} timeout={400} nodeRef={React.useRef(null)}>
                {(state) => (
                    <>
                        <Box
                            position="relative"
                            __htmlAttributes={{ className: filterPaneldeviceTabStyle }}
                        >
                            <Positioned
                                position="fixed"
                                translateX={
                                    isRTL
                                        ? stateToPanelTranslationRTL[state]
                                        : stateToPanelTranslation[state]
                                }
                                transition
                            >
                                <Box height="calc(100vh - 60px)">
                                    <FilterPanelSelector />
                                </Box>
                            </Positioned>

                            <Positioned
                                position="fixed"
                                translateX={
                                    isRTL
                                        ? stateToExpandButtonTranslationRTL[state]
                                        : stateToExpandButtonTranslation[state]
                                }
                                transition
                                duration={1200}
                            >
                                <NoPrint>
                                    <IconButton
                                        icon={
                                            isRTL ? 'keyboard_arrow_left' : 'keyboard_arrow_right'
                                        }
                                        title={t.expandFilterPanel}
                                        onClick={onExpandFilterPanel}
                                    />
                                </NoPrint>
                            </Positioned>
                        </Box>

                        <Spacer
                            horizontal
                            customSpacing={stateToWidth[state]}
                            transition="all 400ms ease"
                        />
                    </>
                )}
            </Transition>
            <Box spacing="none" flex="shrinkAndGrow" direction="column" minWidth="0">
                <Positioned
                    __htmlAttributes={{ className: filterPaneldeviceTabStyle }}
                    position="sticky"
                    top="0"
                >
                    <DeviceTabs showAll={showAll} deviceGroups={deviceGroups} />
                </Positioned>
                <Box direction="column" flex="grow" paddingRight="half" color="white">
                    <ProductSelector />
                    <Spacer customSpacing={deviceGroup === 'cameras' ? '340px' : '50px'} />
                </Box>
            </Box>
        </Box>
    );
};

DeviceSelector.displayName = 'DeviceSelector';
