import { defaultRadarFilter } from 'app/core/persistence';
import type {
    IAccessoryItemEntity,
    Id,
    IEnvironmentItemEntity,
    IItemRelationEntity,
    IRadarItemEntity,
} from 'app/core/persistence';
import { ImportedRadar } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { defaultColors } from 'app/core/common';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class RadarImporterService {
    public import(
        projectId: Id,
        items: ImportedRadar[],
    ): (IRadarItemEntity | IAccessoryItemEntity | IEnvironmentItemEntity | IItemRelationEntity)[] {
        const radars = items.map((item) => this.mapToRadarEntity(projectId, item));

        const accessoryEntities = getImportedAccessories(projectId, items);
        return [...radars, ...accessoryEntities];
    }

    private mapToRadarEntity(projectId: Id, radar: ImportedRadar): IRadarItemEntity {
        const importedItemEntity = mapToItemEntity(
            projectId,
            radar,
            radar.piaId,
            defaultColors.DEFAULT_RADAR_COLOR,
        );
        return {
            ...importedItemEntity,
            properties: {
                radarDetector: { filter: defaultRadarFilter },
            },
        };
    }
}
