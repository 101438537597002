import { ProjectDefaultsService } from 'app/modules/common';
import { injectable } from 'inversify';
import {
    Id,
    IProfileEntity,
    IScheduleModel,
    VideoEncoding,
    Resolution,
    IBaseProfileModel,
    IScenarioSettingsModel,
    IRecordingSettingsModel,
    IZipstreamSettingsModel,
    mapToRecordingSettingsEntity,
    ScenarioId,
} from 'app/core/persistence';
import type {
    ExportedVideoEncoding,
    IExportedAxisCamera,
    ImportedAudionSettings,
    ImportedCamera,
    ImportedCameraRecordingSettings,
    ImportedItems,
    ImportedRecordingSettings,
    ImportedStorageSettings,
    ImportedZipstreamSettings,
} from '../../../models';
import { isEqual } from 'lodash-es';
import { t } from 'app/translate';
import {
    CreateEntityService,
    IAudioSettingsEntity,
    IRecordingSettingsEntity,
    IScenarioEntity,
    IStorageSettingsEntity,
    IZipstreamSettingsEntity,
} from 'app/core/persistence/userDataPersistence';
import { UnreachableCaseError } from 'axis-webtools-util';

export interface IProfileEntityWithId extends IProfileEntity {
    id: Id;
}

@injectable()
export class ProfileImporterService {
    constructor(
        private createEntityService: CreateEntityService,
        private projectDefaultsService: ProjectDefaultsService,
    ) {}

    public importProfiles(
        projectId: Id,
        items: ImportedItems,
        alwaysScheduleId: Id,
    ): IProfileEntityWithId[] {
        const recordingSettings: ImportedCameraRecordingSettings[] = [];
        for (const key of Object.keys(items) as (keyof ImportedItems)[]) {
            const itemTypeRecordingSettings = items[key]
                ? items[key].reduce((recordings, item) => {
                      if (this.hasRecordings(item)) {
                          recordings.push(item.recording);
                          item.additionalRecordings &&
                              recordings.push(...item.additionalRecordings);
                      }
                      return recordings;
                  }, [] as ImportedCameraRecordingSettings[])
                : [];
            recordingSettings.push(...itemTypeRecordingSettings);
        }

        const aggregatedRecordingSettings = this.aggregateRecordingSettings(recordingSettings);

        if (!aggregatedRecordingSettings.length) {
            return [this.getDefaultProfileEntity(projectId, alwaysScheduleId)];
        }

        return aggregatedRecordingSettings.map((recording, index) =>
            this.mapToProfileEntity(projectId, recording, index, alwaysScheduleId),
        );
    }

    public getProfileIdForImportedCamera(
        camera: ImportedCamera | IExportedAxisCamera,
        profiles: IProfileEntityWithId[],
    ): Id {
        if (!profiles.length) {
            throw new Error('No profiles found');
        }
        const cameraProfile = profiles.find((profile) => this.profileMatch(camera, profile));
        return cameraProfile ? cameraProfile.id : profiles[0].id;
    }

    private profileMatch(
        camera: Partial<ImportedCamera | IExportedAxisCamera>,
        profile: IProfileEntityWithId,
    ): boolean {
        if (!camera.recording) {
            return false;
        }
        const cameraProfile = this.mapToProfileEntity('', camera.recording, 0, '');

        return (
            cameraProfile.triggeredRecording.frameRate === profile.triggeredRecording.frameRate &&
            cameraProfile.triggeredRecording.videoEncoding ===
                profile.triggeredRecording.videoEncoding &&
            cameraProfile.triggeredRecording.compression ===
                profile.triggeredRecording.compression &&
            isEqual(
                cameraProfile.triggeredRecording.resolution,
                profile.triggeredRecording.resolution,
            ) &&
            cameraProfile.continuousRecording.frameRate === profile.continuousRecording.frameRate &&
            cameraProfile.continuousRecording.videoEncoding ===
                profile.continuousRecording.videoEncoding &&
            cameraProfile.continuousRecording.compression ===
                profile.continuousRecording.compression &&
            isEqual(
                cameraProfile.continuousRecording.resolution,
                profile.continuousRecording.resolution,
            ) &&
            cameraProfile.liveView.frameRate === profile.liveView.frameRate &&
            cameraProfile.liveView.videoEncoding === profile.liveView.videoEncoding &&
            cameraProfile.liveView.compression === profile.liveView.compression &&
            isEqual(cameraProfile.liveView.resolution, profile.liveView.resolution) &&
            isEqual(cameraProfile.storage, profile.storage) &&
            isEqual(cameraProfile.zipstream, profile.zipstream) &&
            isEqual(cameraProfile.audio, profile.audio) &&
            isEqual(cameraProfile.scenario, profile.scenario)
        );
    }

    private hasRecordings(item: any): item is {
        recording: ImportedCameraRecordingSettings;
        additionalRecordings?: ImportedCameraRecordingSettings[];
    } {
        return item.recording !== undefined || item.additionalRecordings !== undefined;
    }

    private aggregateRecordingSettings(
        recordingSettings: ImportedCameraRecordingSettings[],
    ): ImportedCameraRecordingSettings[] {
        return recordingSettings.reduce((aggregatedRecordings, recording) => {
            const existingRecording = aggregatedRecordings.some((rec) => isEqual(rec, recording));
            if (!existingRecording) {
                aggregatedRecordings.push(recording);
            }

            return aggregatedRecordings;
        }, [] as ImportedCameraRecordingSettings[]);
    }

    private getDefaultProfileEntity(projectId: Id, alwaysScheduleId: Id): IProfileEntityWithId {
        const profileId = this.createEntityService.generateDatabaseId('profile');
        const defaultProfile = this.getDefaultProfile('retail', alwaysScheduleId);

        return {
            id: profileId,
            name: `${t.profilesNewProfileName}`,
            type: 'profile',
            path: [projectId, profileId],
            scenario: this.mapToScenarioEntity(defaultProfile.scenario),
            triggeredRecording: mapToRecordingSettingsEntity(defaultProfile.triggeredRecording),
            continuousRecording: mapToRecordingSettingsEntity(defaultProfile.continuousRecording),
            liveView: mapToRecordingSettingsEntity(defaultProfile.liveView),
            storage: defaultProfile.storage,
            zipstream: defaultProfile.zipstream,
            audio: defaultProfile.audio,
            archived: false,
        } satisfies IProfileEntityWithId;
    }

    private mapToProfileEntity(
        projectId: string,
        recording: ImportedCameraRecordingSettings,
        index: number,
        alwaysScheduleId: Id,
    ): IProfileEntityWithId {
        const profileId = this.createEntityService.generateDatabaseId('profile');
        const defaultProfile = this.getDefaultProfile(recording.scenario, alwaysScheduleId);
        const namingIndex = index > 0 ? ` (${index + 1})` : '';
        return {
            id: profileId,
            name: `${t.profilesNewProfileName}${namingIndex}`,
            type: 'profile',
            path: [projectId, profileId],
            scenario: this.mapToScenarioEntity(defaultProfile.scenario),
            triggeredRecording: this.mapToRecordingSettingsEntity(
                recording.motion,
                defaultProfile.triggeredRecording,
            ),
            continuousRecording: this.mapToRecordingSettingsEntity(
                recording.continuous,
                defaultProfile.continuousRecording,
            ),
            liveView: this.mapToRecordingSettingsEntity(recording.live, defaultProfile.liveView),
            storage: this.mapToStorageSettingsEntity(recording.storage),
            zipstream: this.mapToZipstreamSettingsEntity(
                recording.zipstream,
                defaultProfile.zipstream,
            ),
            audio: this.mapToAudioSettingsEntity(recording.audio),
            archived: false,
        } satisfies IProfileEntityWithId;
    }

    private getDefaultProfile(scenario: ScenarioId, alwaysScheduleId: Id): IBaseProfileModel {
        const defaultProfileSettings = this.projectDefaultsService.getDefaultProfileSettings(
            '',
            { id: alwaysScheduleId } as IScheduleModel,
            scenario,
        );
        return defaultProfileSettings;
    }

    private mapToScenarioEntity(scenario: IScenarioSettingsModel): IScenarioEntity {
        return {
            scenarioId: scenario.scenarioId,
            sceneDetails: scenario.sceneDetails,
            lightStart: scenario.lightStart.toPersistable(),
            lightEnd: scenario.lightEnd.toPersistable(),
            nightLighting: scenario.nightLighting,
        } satisfies IScenarioEntity;
    }

    private mapToRecordingSettingsEntity(
        recordingSettings: ImportedRecordingSettings | null,
        defaultRecordingSettings: IRecordingSettingsModel,
    ): IRecordingSettingsEntity {
        if (!recordingSettings) {
            return {
                frameRate: defaultRecordingSettings.frameRate,
                videoEncoding: defaultRecordingSettings.videoEncoding,
                compression: defaultRecordingSettings.compression,
                resolution: defaultRecordingSettings.resolution.toPersistable(),
                schedule: null,
                dayMotion: defaultRecordingSettings.dayMotion,
                nightMotion: defaultRecordingSettings.nightMotion,
                dayTriggerTime: defaultRecordingSettings.dayTriggerTime,
                nightTriggerTime: defaultRecordingSettings.nightTriggerTime,
                useAverageBitrate: defaultRecordingSettings.useAverageBitrate,
            } satisfies IRecordingSettingsEntity;
        }

        return {
            frameRate: recordingSettings.fps,
            videoEncoding: this.mapToVideoEncoding(recordingSettings.videoEncoding),
            compression: recordingSettings.compression,
            resolution: new Resolution(
                recordingSettings.resolution.horizontal,
                recordingSettings.resolution.vertical,
            ).toPersistable(),
            schedule: recordingSettings.scheduleId,
            dayMotion: defaultRecordingSettings.dayMotion,
            nightMotion: defaultRecordingSettings.nightMotion,
            dayTriggerTime: defaultRecordingSettings.dayTriggerTime,
            nightTriggerTime: defaultRecordingSettings.nightTriggerTime,
            useAverageBitrate: defaultRecordingSettings.useAverageBitrate,
        } satisfies IRecordingSettingsEntity;
    }

    private mapToVideoEncoding(encoding: ExportedVideoEncoding): VideoEncoding {
        switch (encoding) {
            case 'H.264':
                return VideoEncoding.h264;
            case 'H.265':
                return VideoEncoding.h265;
            case 'MJPEG':
                return VideoEncoding.mjpeg;
            case 'AV1':
                return VideoEncoding.av1;
            default:
                throw new UnreachableCaseError(encoding);
        }
    }

    private mapToStorageSettingsEntity(
        storageSettings: ImportedStorageSettings,
    ): IStorageSettingsEntity {
        return {
            retentionTime: storageSettings.retentionTime,
            useProjectSetting: false,
        };
    }

    private mapToZipstreamSettingsEntity(
        zipstreamSettings: ImportedZipstreamSettings | null,
        defaultZipstreamSettings: IZipstreamSettingsModel,
    ): IZipstreamSettingsEntity {
        if (!zipstreamSettings) {
            return defaultZipstreamSettings;
        }
        return {
            gopMode: zipstreamSettings.gopMode,
            gopDefault: zipstreamSettings.gopDefault,
            gopMax: zipstreamSettings.gopMax,
            zipStrength: zipstreamSettings.zipStrength,
            fpsMode: zipstreamSettings.fpsMode,
            useProjectSetting: false,
            minDynamicFps:
                zipstreamSettings.minDynamicFps ?? defaultZipstreamSettings.minDynamicFps,
            zipProfile: zipstreamSettings.profile,
        } satisfies IZipstreamSettingsEntity;
    }

    private mapToAudioSettingsEntity(audioSettings: ImportedAudionSettings): IAudioSettingsEntity {
        return {
            liveViewEnabled: audioSettings.liveViewEnabled,
            recordingEnabled: audioSettings.recordingEnabled,
        };
    }
}
