import { injectable } from 'inversify';
import { UserService, type IProjectEntity } from 'app/core/persistence';
import { ImportedProjectSettingsFile } from '../../../models/ImportedProjectSettingTypes';
import { BandwidthVersion } from 'app/core/persistence/userDataPersistence';
import { getBaseProjectEntity, getUserCountryCode } from 'app/modules/common';
import { IPiaLocation, PiaLocationService } from 'app/core/pia';
import { AppConstants } from 'app/AppConstants';

@injectable()
export class ProjectImporterService {
    constructor(
        private piaLocationService: PiaLocationService<IPiaLocation>,
        private userService: UserService,
    ) {}

    public getImportedProject = async (
        settingsFile: ImportedProjectSettingsFile,
    ): Promise<IProjectEntity> => {
        const userInfo = await this.userService.getUser();
        const userAccountCountryCode = userInfo ? userInfo.country : null;
        const piaLocation = this.piaLocationService
            .getByCountryCode(
                userAccountCountryCode ||
                    (await getUserCountryCode()) ||
                    AppConstants.defaultLocation,
            )
            .first();
        const defaultProjectEntity = getBaseProjectEntity(piaLocation, 'asd-user-data');

        const projectEntity: IProjectEntity = {
            ...defaultProjectEntity,
            name: settingsFile.settings.name,
            notes: settingsFile.settings.notes,
            path: [settingsFile.settings.id],
            lastExportedDate: settingsFile.settings.exportDate,
            bandwidthVersion: BandwidthVersion.latest,
            type: 'project',
        };

        return Promise.resolve(projectEntity);
    };
}
