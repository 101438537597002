import { defaultPagingConsoleFilter } from 'app/core/persistence';
import type {
    IAccessoryItemEntity,
    IEnvironmentItemEntity,
    IItemRelationEntity,
    IPagingConsoleItemEntity,
    Id,
} from 'app/core/persistence';
import type { ImportedPagingConsole } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { mapToItemEntity, getImportedAccessories } from './utils';
import { defaultColors } from 'app/core/common';

@injectable()
export class PagingConsoleImporterService {
    public import(
        projectId: Id,
        items: ImportedPagingConsole[],
    ): (
        | IPagingConsoleItemEntity
        | IAccessoryItemEntity
        | IEnvironmentItemEntity
        | IItemRelationEntity
    )[] {
        const pagingConsoles = items.map((item) => ({
            ...mapToItemEntity(projectId, item, item.piaId, defaultColors.DEFAULT_SPEAKER_COLOR),
            properties: {
                pagingConsole: {
                    filter: defaultPagingConsoleFilter,
                },
            },
        }));

        const accessoryEntities = getImportedAccessories(projectId, items);
        return [...pagingConsoles, ...accessoryEntities];
    }
}
