import * as React from 'react';
import { t } from 'app/translate';
import { Stack, Button, Text } from 'app/components';
import { eventTracking } from 'app/core/tracking';
import type { Id } from 'app/core/persistence';
import { useService } from 'app/ioc';
import { UserProjectsService } from '../../userProjects/services';
import { UserProjectsActionService } from '../../userProjects/userProjects/services';

interface IDownloadProjectFileProps {
    projectId: Id;
}

export const DownloadProjectFile: React.FC<IDownloadProjectFileProps> = ({ projectId }) => {
    const userProjectsService = useService(UserProjectsService);
    const userProjectsListActions = useService(UserProjectsActionService);

    const downloadProjectFile = () => {
        eventTracking.logUserEvent('User Projects', 'Download Project file');
        userProjectsService.updateExportDate(projectId);
        userProjectsListActions.exportProject(projectId);
    };

    return (
        <Stack vertical alignItems="start">
            <Text style="subheader">{t.downloadProjectToFile}</Text>
            <Stack vertical spacing="half" alignItems="start">
                <Text>{t.downloadProjectToFileInfo}</Text>
                <Button onClick={downloadProjectFile}>{t.downloadProjectFile}</Button>
            </Stack>
        </Stack>
    );
};

DownloadProjectFile.displayName = 'DownloadProjectFile';
