import { Module } from 'app/ioc';
import { UserProjectsActionService, userProjectsReducer } from './userProjects';
import {
    UserProjectsService,
    LocationQueriesService,
    ProjectLoadingTimerService,
} from './services';
import {
    OrganizationsActionService,
    SharedProjectsActionService,
    sharedProjectsReducer,
} from './sharedProjects';
import { LocalProjectsActionService, localProjectsReducer } from './localProjects';

@Module({
    binder: (binder) => {
        binder.bindService(LocationQueriesService);
        binder.bindService(UserProjectsService);
        binder.bindService(UserProjectsActionService);
        binder.bindService(LocalProjectsActionService);
        binder.bindService(SharedProjectsActionService);
        binder.bindService(ProjectLoadingTimerService);
        binder.bindService(OrganizationsActionService);
    },
    reducers: {
        userProjects: userProjectsReducer,
        sharedProjects: sharedProjectsReducer,
        localProjects: localProjectsReducer,
    },
})
export class UserProjectsModule {}
