import { ImportError } from 'app/core/persistence';
import type { ImportErrorReason } from 'app/core/persistence';
import { t } from 'app/translate';

export const getImportErrorMessage = (error: Error) => {
    const importErrors: Record<ImportErrorReason, string> = {
        fileContentsUnreadable: t.projectImportErrorsFileContentsUnreadable,
        invalidFileFormat: t.projectImportErrorsInvalidFileFormat,
        entityImportFailed: t.projectImportErrorsEntityImportFailed,
        imageQuotaFull: t.errorQuota,
        diskSpaceFull: t.applicationQuotaExceededError,
        cannotCopyFloorPlans: t.projectImportIntoProjectFloorPlanFailed,
    };

    if (error instanceof ImportError) {
        console.error(
            `Import error - reason: ${error.reason} - message: ${
                error.optionalMessage || error.message
            }`,
        );
        return importErrors[error.reason] || t.projectImportErrorsUnknownError;
    } else {
        console.error(error);
        return t.projectImportErrorsUnknownError;
    }
};
