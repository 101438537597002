type SchemaPrimitive = 'string' | 'number' | 'boolean';

type SchemaValue =
    | SchemaPrimitive
    | SchemaOptional
    | SchemaEnum
    | SchemaCustom
    | Schema
    | SchemaArray;

type SchemaArray = SchemaValue[];

export type SchemaOptional = {
    _type: 'optional';
    _schema: SchemaValue;
};

export type SchemaEnum = {
    _type: 'enum';
    _values: string[];
};

type SchemaCustom = {
    _type: 'custom';
    _function: (value: any) => boolean;
};

type ValidationResult<T> =
    | {
          valid: false;
          errors: string[];
      }
    | {
          valid: true;
          data: T;
      };

export type Schema = {
    [key: string]: SchemaValue;
};

/**
 * Creates an error message describing where and why the validation failed
 */
const createErrorMessage = (path: string[], value: any, typeString: string): string => {
    return `Expected ${path.join('.')} to be of type ${typeString}, but received ${value}`;
};

/**
 * Creates an error message for custom validation
 */
const createCustomErrorMessage = (path: string[], value: any): string => {
    return `Custom validation failed for ${path.join('.')}, received ${value}`;
};

/**
 * Type guards
 */

const isSchema = (value: SchemaValue): value is Schema =>
    !Array.isArray(value) && Object(value) === value;

const isSchemaValueArray = (value: SchemaValue): value is SchemaArray => Array.isArray(value);

const isOptional = (value: SchemaValue): value is SchemaOptional =>
    typeof value === 'object' && !Array.isArray(value) && value._type === 'optional';

const isEnum = (value: SchemaValue): value is SchemaEnum =>
    typeof value === 'object' && !Array.isArray(value) && value._type === 'enum';

const isCustom = (value: SchemaValue): value is SchemaCustom =>
    typeof value === 'object' && !Array.isArray(value) && value._type === 'custom';

/**
 * Validates an enum value
 * @param value
 * @param values
 */
const validateEnum = (value: any, values: string[], path: string[]): string[] => {
    const result = values.includes(value);

    if (result) {
        return [];
    } else {
        return [createErrorMessage(path, value, values.join('|'))];
    }
};

/**
 * Validates a custom validation
 * @param value
 * @param fn the validation function
 */
const validateCustom = (value: any, fn: (value: any) => boolean, path: string[]): string[] => {
    if (fn(value)) {
        return [];
    } else {
        return [createCustomErrorMessage(path, value)];
    }
};

/**
 * Validates a generic type
 * @param value
 * @param type
 * @param path
 */
const validateType = (
    value: any,
    type: SchemaValue | SchemaValue[],
    path: string[] = [],
): string[] => {
    if (isOptional(type)) {
        if (value == undefined) {
            return [];
        } else {
            return validateType(value, type._schema, path);
        }
    }

    if (isEnum(type)) {
        return validateEnum(value, type._values, path);
    }

    if (isCustom(type)) {
        return validateCustom(value, type._function, path);
    }

    if (isSchemaValueArray(type)) {
        return validateArray(value, type, path);
    }

    if (isSchema(type)) {
        return validateObject(value as object, type, path);
    }

    if (value === undefined || typeof value !== type) {
        return [createErrorMessage(path, value, type)];
    }

    return [];
};

/**
 * Validates an array
 * @param arr
 * @param type
 * @param path
 */
const validateArray = (arr: object[], type: SchemaArray, path: string[]): string[] => {
    if (!Array.isArray(arr)) {
        return [createErrorMessage(path, arr, 'Array')];
    }
    return arr.flatMap((item) => {
        return validateType(item, type[0], [...path, '[]']);
    });
};

/**
 * Validates an object
 * @param obj
 * @param schema
 * @param path
 */
const validateObject = (obj: any, schema: Schema, path: string[] = []): string[] => {
    if (!obj || typeof obj !== 'object') {
        return [createErrorMessage(path, obj, 'Object')];
    }

    return Object.entries(schema).flatMap(([key, schemaValue]) => {
        const value = obj[key];
        return validateType(value, schemaValue, [...path, key]);
    });
};

/**
 * Creates a schema for an optional value
 * @param schema
 * @returns the optional schema
 */
export const optional = (schema: SchemaValue): SchemaOptional => ({
    _type: 'optional',
    _schema: schema,
});

/**
 * Creates a schema for an enum
 * @param values
 * @returns the enum schema
 */
export const oneOf = (values: string[]): SchemaEnum => ({
    _type: 'enum',
    _values: values,
});

/**
 * Creates a schema for a custom validation
 * @param function the validation function
 * @returns the function validation schema
 */
export const custom = (fn: (value: any) => boolean): SchemaCustom => ({
    _type: 'custom',
    _function: fn,
});

/**
 * Validates an object against a schema
 * @param obj the object to validate
 * @param schema the schema to validate against
 * @returns the validation result
 */
export const validateSchema = <T>(obj: T, schema: Schema): ValidationResult<T> => {
    const errors = validateObject(obj, schema);
    if (errors.length > 0) {
        return {
            valid: false,
            errors,
        };
    } else {
        return {
            valid: true,
            data: obj,
        };
    }
};
