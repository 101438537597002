import React from 'react';
import { css } from '@emotion/css';
import type { IInstallationPointModel } from 'app/core/persistence';
import { getMultiSelectPositionRecord } from '../../../selectors';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import leaflet from 'leaflet';
import { useSelector } from 'react-redux';
import type { LeafletMap } from '../LeafletMap';
import type { BaseCone } from './BaseCone';

const svgArrowStyle = css`
    margin: 0px -5px;
`;

const arrowStyle = (color: Colors) => css`
    fill: ${ColorsEnum[color]};
`;

interface IDirectionalArrowProps {
    map: LeafletMap;
    color: Colors;
    coverageArea: BaseCone;
    isOpaque: boolean;
    installationPoint: IInstallationPointModel;
}

/**
 * Component responsible for creating the target handle, and line from installation point to target handle
 */
export const DirectionalArrow: React.FC<IDirectionalArrowProps> = ({
    map,
    color,
    coverageArea,
    isOpaque,
    installationPoint,
}) => {
    const multiSelectPositionRecord = useSelector(getMultiSelectPositionRecord);

    const [directionalArrow] = React.useState<leaflet.Marker>(
        leaflet.marker(installationPoint.location, {
            interactive: false,
            draggable: false,
            icon: new leaflet.DivIcon({
                className: svgArrowStyle,
                html: `<svg width="11" height="50" viewBox="0 0 11 50" xmlns="http://www.w3.org/2000/svg">
                <path class="${arrowStyle(color)}" d="M5.5 50L0 42L5.5 42.9412L11 42L5.5 50Z"/>
                <path class="${arrowStyle(color)}" d="M4 24H7V43H4V24Z"/>
                </svg>
                `,
                iconSize: undefined, // bug! Leaflet defaults to 12x12 otherwise
            }),
            rotationAngle: coverageArea.horizontalAngle,
            rotationOrigin: 'top center',
            opacity: 0.2,
        }),
    );

    React.useEffect(() => {
        directionalArrow.addTo(map.map);

        return () => {
            directionalArrow.removeFrom(map.map);
        };
    }, [directionalArrow, map.map]);

    React.useEffect(() => {
        if (isOpaque) {
            directionalArrow.setOpacity(1);
        } else {
            directionalArrow.setOpacity(0.2);
        }
    }, [isOpaque, directionalArrow]);

    React.useEffect(() => {
        directionalArrow.setIcon(
            new leaflet.DivIcon({
                className: svgArrowStyle,
                html: `<svg width="11" height="50" viewBox="0 0 11 50" xmlns="http://www.w3.org/2000/svg">
                <path class="${arrowStyle(color)}" d="M5.5 50L0 42L5.5 42.9412L11 42L5.5 50Z"/>
                <path class="${arrowStyle(color)}" d="M4 24H7V43H4V24Z"/>
                </svg>
                `,
                iconSize: undefined, // bug! Leaflet defaults to 12x12 otherwise
            }),
        );
    }, [color, directionalArrow]);

    // Update the rotation angle of the directional arrow if the horizontal angle of
    // the coverage area changes
    React.useMemo(() => {
        directionalArrow.setRotationAngle(coverageArea.horizontalAngle);
    }, [coverageArea.horizontalAngle, directionalArrow]);

    React.useMemo(() => {
        directionalArrow.setLatLng(installationPoint.location);
    }, [directionalArrow, installationPoint.location]);

    // Update arrow position when installation point is multi selected and moved
    React.useEffect(() => {
        if (!installationPoint || !multiSelectPositionRecord[installationPoint._id]) {
            return undefined;
        }
        directionalArrow.setLatLng(multiSelectPositionRecord[installationPoint._id]);
    }, [directionalArrow, installationPoint, multiSelectPositionRecord]);

    return null;
};
