import type { IExportFileV1 } from 'app/core/persistence';
import { ImportError } from 'app/core/persistence';
import { readFileToJson } from 'app/modules/common';

export const parseFile = async (file: File): Promise<IExportFileV1> => {
    try {
        const fileData = await readFileToJson(file);
        return fileData as IExportFileV1;
    } catch {
        console.error(`Parsing file failed: ${file.name}`);
        throw getFileReadError(file.name);
    }
};

export const getFileReadError = (filename: string): ImportError => {
    const errorMessage = `Unable to read from the file '${filename}'`;
    return new ImportError('fileContentsUnreadable', errorMessage);
};

/**
 * Right now we only have 1 version to handle which makes
 * the verification simpler here. When more formats are
 * added the verification has to be more thorough.
 *
 * We validate all criteria in the file to ensure that it
 * is not corrupt, or some newer file format is added in a
 * newer version.
 */
export const isValidFileFormat = (fileData: any): boolean => {
    return (
        fileData.fileVersion === 1 &&
        fileData.fileType === 'exportedProject' &&
        fileData.application === 'SiteDesigner2' &&
        fileData.project != null &&
        Array.isArray(fileData.children)
    );
};
