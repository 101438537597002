import { injectable } from 'inversify';
import { last } from 'lodash-es';
import type { ILatLng } from '../models';
import { ProjectService } from './Project.service';
import { ItemService } from './item/Item.service';
import { NameGenerationService } from './NameGeneration.service';
import { EntityImportService } from './importExport/EntityImport.service';
import type {
    IEntity,
    Id,
    IInstallationPointEntity,
    IPersistence,
    IItemEntity,
    IItemRelationEntity,
} from '../userDataPersistence';
import {
    ProjectRepository,
    PersistenceMemoryRepository,
    InstallationPointRepository,
    EntitySettings,
} from '../userDataPersistence';
import { AppConstants } from 'app/AppConstants';
import { FloorPlanService } from './FloorPlan.service';
import { CurrentProjectService } from './CurrentProject.service';
import { DuplicationError } from './errors/DuplicationError';
import { PartnerItemService } from './PartnerItem.service';
export interface ICopyItemId {
    oldItemId: Id;
    newItemId: Id;
}
@injectable()
export class DuplicationService {
    constructor(
        private itemService: ItemService,
        private projectService: ProjectService,
        private projectRepository: ProjectRepository,
        private entitySettings: EntitySettings,
        private entityImportService: EntityImportService,
        private nameGenerationService: NameGenerationService,
        private floorPlanService: FloorPlanService,
        private currentProjectService: CurrentProjectService,
        private installationPointRepository: InstallationPointRepository,
        private partnerItemService: PartnerItemService,
        private persistenceMemoryRepository: PersistenceMemoryRepository,
    ) {}

    /**
     * Duplicates a project and returns the id of the new duplicated project
     *
     * @param projectId the id of the project to duplicate
     */
    public async duplicateProject(
        projectId: Id,
        projectNameSuffix?: string,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<Id> {
        return this.duplicate(projectId, false, projectNameSuffix, usedImageQuota, totalImageQuota);
    }

    /**
     * Duplicates a project excluding floor plans and returns the id of the new duplicated project
     *
     * @param projectId the id of the project to duplicate
     */
    public async duplicateProjectWithoutFloorPlans(
        projectId: Id,
        projectNameSuffix?: string,
    ): Promise<Id> {
        return this.duplicate(projectId, true, projectNameSuffix);
    }

    public async copyItem(itemId: Id, copyCount: number): Promise<IPersistence<IItemEntity>[]> {
        const projectId = this.currentProjectService.getProjectId();
        const items = await this.projectService.getChildItems(projectId);
        const item = await this.itemService.getItem(itemId);

        if (!item) {
            throw Error(`Could not find device to duplicate ${itemId}`);
        }

        const itemsToCreate = this.getItemsWithGeneratedNames(items, item, copyCount);

        // Add items
        const addedItems = await this.itemService.addItems([projectId], itemsToCreate);

        // Copy relations
        const copies = addedItems.map(async (copy) => {
            const relations = this.currentProjectService
                .getAllEntitiesOfType('itemRelation')
                .filter((rel) => rel.parentId === itemId);

            await this.copyAllRelationsForEntities(relations, [projectId, copy._id]);
            return this.itemService.getItem(copy._id);
        });

        return Promise.all(copies);
    }

    /**
     * copy the item (without children)
     * @param itemId id of item to copy
     * @returns the new copy item IPersistence<IItemEntity>
     */
    public async copyItemWithoutRelations(itemId: Id): Promise<IPersistence<IItemEntity>> {
        const projectId = this.currentProjectService.getProjectId();
        const items = await this.projectService.getChildItems(projectId);
        const item = await this.itemService.getItem(itemId);

        if (!item) {
            throw Error(`Could not find device to duplicate ${itemId}`);
        }
        const itemsToCreate = this.getItemsWithGeneratedNames(items, item, 1);

        // Add items
        const addedItem = await this.itemService.addItem([projectId], itemsToCreate[0]);

        return addedItem;
    }

    /**
     * Copy all child items for itemId
     * @param addedItem item to copy children for
     * @param itemId the id of the parent
     * @returns ICopyItemId that includes both old and new item id
     */
    public async copyItemRelations(
        addedItem: IPersistence<IItemEntity>,
        itemId: Id,
    ): Promise<ICopyItemId[]> {
        const projectId = this.currentProjectService.getProjectId();

        // Copy relations

        const relations = this.currentProjectService
            .getAllEntitiesOfType('itemRelation')
            .filter((rel) => rel.parentId === itemId);

        return this.copyAllRelationsForEntities(relations, [projectId, addedItem._id]);
    }

    public async copyItemInCurrentProject(
        itemId: Id,
        copyCount: number,
    ): Promise<IPersistence<IItemEntity>[]> {
        const item = this.currentProjectService.getEntity(itemId, 'item');

        if (!item) {
            throw Error(`Could not find device to duplicate`);
        }

        return this.copyItem(itemId, copyCount);
    }

    public async duplicateInstallationPoint(
        installationPointId: Id,
        parentId?: Id,
        location?: ILatLng,
    ): Promise<Id> {
        const oldInstallationPoint =
            await this.installationPointRepository.get(installationPointId);

        const newInstallationPoint = await this.installationPointRepository.add({
            ...oldInstallationPoint,
            path: oldInstallationPoint.path.slice(0, -1),
            parentId,
            name: oldInstallationPoint.name,
            serialNumber: undefined,
            location: location ?? oldInstallationPoint.location,
        });

        return newInstallationPoint._id;
    }

    public async duplicateInstallationPointToDevice(
        installationPointId: Id,
        deviceId: Id,
        installationPointName: string,
        location?: ILatLng,
    ): Promise<Id> {
        const oldInstallationPoint =
            await this.installationPointRepository.get(installationPointId);
        const newInstallationPoint = await this.installationPointRepository.add({
            ...oldInstallationPoint,
            path: [oldInstallationPoint.path[0], deviceId],
            name: installationPointName,
            serialNumber: undefined,
            location: location ?? oldInstallationPoint.location,
        });
        return newInstallationPoint._id;
    }

    public async duplicateInstallationPointChildToDevice(
        originalInstallationPoint: IPersistence<IInstallationPointEntity>,
        path: string[],
        parentId: Id,
        location: ILatLng,
    ): Promise<Id> {
        const newInstallationPoint = await this.installationPointRepository.add({
            ...originalInstallationPoint,
            path,
            parentId,
            location,
            name: originalInstallationPoint.name,
            serialNumber: undefined,
        });

        return newInstallationPoint._id;
    }

    private async duplicate(
        projectId: Id,
        shouldExcludeFloorPlanMaps: boolean,
        projectNameSuffix?: string,
        usedImageQuota?: number,
        totalImageQuota?: number,
    ): Promise<Id> {
        const { project, descendants } =
            await this.projectRepository.getProjectAndAllDescendants(projectId);
        let processedDescendants: IEntity[];
        let projectHasFloorPlanMaps = false;
        // do not export genetecProjectId or selected solution type since we only want one genetec project per Site Designer project
        project.genetecProjectId = undefined;

        if (shouldExcludeFloorPlanMaps) {
            processedDescendants = await FloorPlanService.filterOutFloorPlanMaps(descendants);
        } else {
            processedDescendants =
                (await Promise.all(
                    descendants.map((entity): Promise<IEntity> => {
                        if (FloorPlanService.isFloorPlanMapType(entity)) {
                            projectHasFloorPlanMaps = true;
                            return this.floorPlanService.getExportableFloorPlan(entity, projectId);
                        }
                        return Promise.resolve(entity);
                    }),
                )) || [];
        }

        const descendantSize = FloorPlanService.getApproximateSizeInBytes(processedDescendants);

        if (
            projectHasFloorPlanMaps &&
            !shouldExcludeFloorPlanMaps &&
            usedImageQuota != undefined &&
            totalImageQuota != undefined
        ) {
            const notEnoughSpace = totalImageQuota - usedImageQuota - descendantSize < 0;
            if (notEnoughSpace) {
                throw new DuplicationError('imageQuotaFull');
            }
        }

        this.persistenceMemoryRepository.initialize(
            [project, ...processedDescendants],
            this.entitySettings,
        );

        const projects = await this.projectRepository.getAll();
        const newProjectName = projectNameSuffix
            ? `${project.name} (${projectNameSuffix})`
            : this.nameGenerationService.getName(
                  project.name,
                  projects.map((p) => p.name),
                  AppConstants.projectNameMaxLength,
              );

        /*
         * Since duplicating involves a lot of logic, data shuffling, and some
         * error handling, so we want to avoid building a new implementation
         * just for duplication. Instead we re-use the export/import code.
         *
         * There shouldn't be any real issues with this approach more than it
         * may look weird to the reader. Creating its own flow for duplication
         * would essentially generate the same code.
         */
        return this.entityImportService.importProject(
            project._id,
            undefined,
            this.persistenceMemoryRepository,
            newProjectName,
            project.projectDbOrigin,
        );
    }

    private getItemsWithGeneratedNames(
        items: IPersistence<IItemEntity>[],
        item: IPersistence<IItemEntity>,
        copyCount: number,
    ) {
        const itemsWithGeneratedName = new Array<IPersistence<IItemEntity>>();
        for (let i = 0; i < copyCount; ++i) {
            const allNames = [
                ...items.map(({ name }) => name),
                ...itemsWithGeneratedName.map(({ name }) => name),
            ];
            const newName = this.nameGenerationService.getName(
                item.name,
                allNames,
                AppConstants.deviceNameMaxLength,
            );
            const newItemEntity = {
                ...item,
                name: newName,
                quantity: 1,
            };
            itemsWithGeneratedName.push(newItemEntity);
        }

        return itemsWithGeneratedName;
    }

    /**
     * Copy all relations for the sourceRelations
     * @param sourceRelations relations to copy
     * @param parentPath path for the parent item
     * @returns array of ICopyItemId, which includes both old itemId and newItemId
     */
    private async copyAllRelationsForEntities(
        sourceRelations: ReadonlyArray<IItemRelationEntity>,
        parentPath: Id[],
    ): Promise<ICopyItemId[]> {
        return Promise.all(
            sourceRelations.map(async (relation) => {
                const parentId = last(parentPath)!;
                const copiedItem =
                    relation.relationType === 'partnerAcap'
                        ? await this.partnerItemService.addByParentId(
                              parentId,
                              this.currentProjectService.getEntity(relation.childId, 'partnerItem'),
                          )
                        : await this.itemService.addByParentId(
                              parentId,
                              this.currentProjectService.getEntity(relation.childId, 'item'),
                          );

                await this.itemService.addItemRelation(
                    parentId,
                    copiedItem._id,
                    relation.relationType,
                );
                const childItemRelations = this.currentProjectService.getItemRelations(
                    relation.childId,
                );

                await this.copyAllRelationsForEntities(childItemRelations, [
                    ...parentPath,
                    copiedItem._id,
                ]);

                return { oldItemId: relation.childId, newItemId: copiedItem._id };
            }),
        );
    }
}
