import { createSelector } from 'reselect';
import {
    getCurrentProjectInstallationPoints,
    getCurrentProjectItemRelationsArray,
    getIdFromProps,
    getInstallationPointsArray,
    mapInstallationEntityToModel,
    toCacheKey,
    mapInstallationPointToFloorPlan,
    transformInstallationPoint,
    getCurrentProjectItem,
    getIdFromPropsRequired,
} from 'app/modules/common';
import { getCurrentProjectItems } from './getCurrentProjectItems';
import { isDefined } from 'axis-webtools-util';
import { getParentId, isSensorDevice } from 'app/core/persistence';
import type {
    IInstallationPointEntity,
    IInstallationPointModel,
    IPersistence,
    IFloorPlanEntity,
    Id,
} from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { creationDateReverseComparator, idComparator } from 'app/utils';
import { createCachedSelector } from 're-reselect';
import { getMapsState } from './getMapsState';
import {
    getDerotationTransform,
    getDerotationAngle,
    getDerotationTransformForFloorPlan,
} from './geolocations';
import { memoize, sortBy } from 'lodash-es';

export const getMapsSelectionInfo = createSelector([getMapsState], (state) => {
    return state.selected;
});

export const getInstallationPoints = createSelector(
    [
        getCurrentProjectInstallationPoints,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
    ],
    (installationPoints, items, relations) => {
        return Object.keys(installationPoints).reduce(
            (acc, key) => {
                const installationPoint = installationPoints[key];
                if (installationPoint) {
                    const installationPointModel = mapInstallationEntityToModel(
                        installationPoint,
                        items,
                        relations,
                    );
                    if (installationPointModel) {
                        acc[key] = installationPointModel;
                    }
                }
                return acc;
            },
            {} as Record<Id, IInstallationPointModel>,
        );
    },
);

export const getDerotatedInstallationPoints = createSelector(
    [getInstallationPoints, getDerotationTransform, getDerotationAngle],
    (ips, transform, angle) => {
        return Object.keys(ips).reduce(
            (acc, key) => {
                const ip = ips[key];
                if (ip) {
                    acc[key] = transformInstallationPoint(transform, angle, ip);
                }
                return acc;
            },
            {} as ReturnType<typeof getInstallationPoints>,
        );
    },
);

export const getDerotatedInstallationPointsArray = createSelector(
    [getDerotatedInstallationPoints],
    (ips) => {
        return Object.values(ips);
    },
);

export const getInstallationPointsForItemDerotated = createCachedSelector(
    [getCurrentProjectItem, getDerotatedInstallationPointsArray],
    (item, installationPoints) => {
        if (!item) {
            return [];
        }
        return installationPoints.filter((ip) => getParentId(ip) === item._id);
    },
)(toCacheKey);

const getInstallationPointFromId = createCachedSelector(
    [getDerotatedInstallationPoints, getIdFromProps],
    (installationPoints, id) => {
        return id && installationPoints[id];
    },
)(toCacheKey);

const getMapRecord = (state: IStoreState) => state.currentProject.floorPlans;

export const getMapToInstallationPointModel = createSelector(
    [getCurrentProjectItems, getCurrentProjectItemRelationsArray],
    (items, relations) => {
        return memoize((ip: IInstallationPointEntity) => {
            return mapInstallationEntityToModel(ip, items, relations);
        });
    },
);

export const getProjectInstallationPoints = createSelector(
    [getInstallationPointsArray, getMapToInstallationPointModel],
    (installationPoints, mapToIpModel) => installationPoints.map(mapToIpModel).filter(isDefined),
);

export const getProjectSensorDeviceInstallationPoints = createSelector(
    [getInstallationPointsArray, getCurrentProjectItems, getCurrentProjectItemRelationsArray],
    (installationPoints, items, relations) =>
        installationPoints
            .map((ip) => mapInstallationEntityToModel(ip, items, relations))
            .filter((ipModel) => isSensorDevice(ipModel?.parentPiaDevice)),
);

export const getGeoLocatedInstallationPoints = createSelector(
    [getProjectInstallationPoints],
    (installationPoints) =>
        installationPoints.filter(({ floorPlanId }) => floorPlanId === undefined),
);

export const getSelectedMapItem = createSelector(
    [getMapsSelectionInfo],
    (selected) => selected?.mapItem,
);

/**
 * Get the derotated installation point currently selected in the map if any
 */
export const getSelectedDerotatedInstallationPoint = createSelector(
    [getDerotatedInstallationPoints, getSelectedMapItem],
    (installationPoints, selectedMapItem) => {
        if (!selectedMapItem) return undefined;
        return installationPoints[selectedMapItem?.id];
    },
);

export const getInstallationPointForMapItem = createCachedSelector(
    [getInstallationPointFromId, getSelectedMapItem, getIdFromProps],
    (installationPoint, selectedMapItem, id) => {
        if (!installationPoint || !installationPoint.parentDevice) return undefined;

        return id === selectedMapItem?.id && selectedMapItem
            ? ({
                  ...installationPoint,
                  location: selectedMapItem.location,
                  height: selectedMapItem.height ?? installationPoint?.height,
              } as IInstallationPointModel)
            : installationPoint;
    },
)(toCacheKey);

export const getInstallationPointsForDeviceSortByCreationFactory = createSelector(
    [getProjectInstallationPoints],
    (installationPoints) => (deviceId: string) => {
        return installationPoints
            .filter((ip) => ip.parentDevice._id === deviceId)
            .sort(idComparator)
            .sort(creationDateReverseComparator);
    },
);
/**
 * Will return a record of all floor plans including street map.
 */
export const getFloorPlansRecord = createSelector([getMapRecord], (mapRecord) => {
    return Object.values(mapRecord).reduce(
        (floorPlans, map) => {
            if (map?.mapType === 'FloorPlan' || map?.mapType === 'StreetMap') {
                floorPlans[map._id] = map;
                return floorPlans;
            }
            return floorPlans;
        },
        {} as Record<Id, IPersistence<IFloorPlanEntity>>,
    );
});

const getInstallationPointIdFromProps = (
    _state: IStoreState,
    _floorPlanId: Id,
    installationPointId: Id,
) => installationPointId;

/**
 *
 * Get installation point on a floor plan with correct position.
 * Installation points that are placed on a floor plan that is rotated will be rotated back to their original position.
 *
 * @param floorPlanId,
 * @param installationPointId,
 * @returns installation point on floor plan
 */
export const getRotatedInstallationPointOnFloorPlan = createCachedSelector(
    [
        getDerotationTransformForFloorPlan,
        getProjectInstallationPoints,
        getFloorPlansRecord,
        getIdFromPropsRequired,
        getInstallationPointIdFromProps,
    ],
    (
        transform,
        installationPoints,
        floorPlans,
        floorPlanId,
        ipId,
    ): IInstallationPointModel | undefined => {
        const installationPoint = installationPoints.find((ip) => ip._id === ipId);
        const angle = floorPlans[floorPlanId].image?.geoLocation?.angle;

        if (!installationPoint || !angle) return installationPoint;

        return transformInstallationPoint(transform, -(angle ?? 0), installationPoint);
    },
)(toCacheKey);

export const getInstallationPointsOnFloorPlans = (
    installationPoints: IInstallationPointEntity[],
    floorPlansRecord: Record<string, IPersistence<IFloorPlanEntity>>,
    deviceId: Id,
) => {
    // Sort floor plans by mapType
    const floorPlans = sortBy(Object.values(floorPlansRecord), 'mapType');
    // Get installation points for the device and sort by creation date
    const installationPointsOnFloorPlan = installationPoints
        .filter(
            (ip) =>
                mapInstallationPointToFloorPlan(ip, installationPoints, floorPlans) &&
                getParentId(ip) === deviceId,
        )
        .sort(creationDateReverseComparator);

    const ipWithMapType = installationPointsOnFloorPlan.map((ip) => {
        // Get the floor plan for the installation point
        const floorPlan = mapInstallationPointToFloorPlan(ip, installationPoints, floorPlans);

        // Add the mapType to the installation point
        return {
            ...ip,
            mapType: floorPlan?.mapType ?? 'StreetMap',
        };
    });
    return ipWithMapType;
};
