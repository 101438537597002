import { createSelector } from 'reselect';
import { getCurrentProjectDevices } from './getCurrentProjectDevices';
import { getChildrenForDevice } from './getDeviceChildren';
import { getCurrentProjectRelationsRecord } from '../../relations';
import { getCurrentProjectItems } from '../../project';
import type { DeviceCount } from 'app/core/persistence';
import { deviceTypeCheckers, isCustomCamera } from 'app/core/persistence';
import { getPiaItemsRecord } from '../../piaDevices';

export const getProjectTotalDeviceCount = createSelector(
    [getCurrentProjectDevices, getCurrentProjectRelationsRecord, getCurrentProjectItems],
    (devices, currentProjectRelationsRecord, currentProjectItems) =>
        devices.reduce((prev, curr) => {
            const children = getChildrenForDevice(
                currentProjectRelationsRecord[curr._id],
                currentProjectItems,
            ).filter((child) => !child.properties.door);

            const childQuantity = children.reduce((prevChild, currChild) => {
                return (prevChild += currChild.quantity);
            }, 0);

            return (prev += curr.quantity + curr.quantity * childQuantity);
        }, 0),
);

export const getProjectDeviceCount = createSelector(
    [
        getCurrentProjectDevices,
        getCurrentProjectRelationsRecord,
        getPiaItemsRecord,
        getCurrentProjectItems,
    ],
    (devices, relationRecord, piaItemsRecord, currentProjectItems) => {
        const deviceCount = {
            accessServer: 0,
            analogCamera: 0,
            answeringUnit: 0,
            alerter: 0,
            camera: 0,
            decoder: 0,
            doorcontroller: 0,
            doorstation: 0,
            encoder: 0,
            mainUnit: 0,
            networkReader: 0,
            iorelay: 0,
            pac: 0,
            radardetector: 0,
            sensorUnit: 0,
            speaker: 0,
            peopleCounter: 0,
            relayexpmodules: 0,
            connectivitydevice: 0,
            bodyWornCamera: 0,
            pagingconsole: 0,
        } as DeviceCount;

        for (const device of devices) {
            const piaItem = device.productId ? piaItemsRecord[device.productId] : null;
            if (!isCustomCamera(device) && device.productId === null) {
                continue;
            } else if (piaItem?.category === 'doorstations') {
                deviceCount.doorstation += device.quantity;
            } else if (piaItem?.category === 'doorcontrollers') {
                deviceCount.doorcontroller += device.quantity;
            } else if (piaItem?.category === 'answeringUnits') {
                deviceCount.answeringUnit += device.quantity;
            } else if (piaItem?.category === 'accessServers') {
                deviceCount.accessServer += device.quantity;
            } else if (piaItem?.category === 'alerters') {
                deviceCount.alerter += device.quantity;
            } else if (piaItem?.category === 'iorelays') {
                deviceCount.iorelay += device.quantity;
            } else if (piaItem?.category === 'networkReaders') {
                deviceCount.networkReader += device.quantity;
            } else if (deviceTypeCheckers.isCamera(device)) {
                deviceCount.camera += device.quantity;
            } else if (deviceTypeCheckers.isDecoder(device)) {
                deviceCount.decoder += device.quantity;
            } else if (deviceTypeCheckers.isEncoder(device)) {
                deviceCount.encoder += device.quantity;
            } else if (deviceTypeCheckers.isMainUnit(device)) {
                deviceCount.mainUnit += device.quantity;
            } else if (deviceTypeCheckers.isPac(device)) {
                deviceCount.pac += device.quantity;
            } else if (deviceTypeCheckers.isRadarDetector(device)) {
                deviceCount.radardetector += device.quantity;
            } else if (deviceTypeCheckers.isSpeaker(device)) {
                deviceCount.speaker += device.quantity;
            } else if (deviceTypeCheckers.isPeopleCounter(device)) {
                deviceCount.peopleCounter += device.quantity;
            } else if (deviceTypeCheckers.isConnectivityDevice(device)) {
                deviceCount.connectivitydevice += device.quantity;
            } else if (deviceTypeCheckers.isPagingConsole(device)) {
                deviceCount.pagingconsole += device.quantity;
            } else if (deviceTypeCheckers.isBodyWornCamera(device)) {
                deviceCount.bodyWornCamera += device.quantity;
            }
            const relations = relationRecord[device._id] ?? [];

            for (const relation of relations) {
                const childItem = currentProjectItems[relation.childId];

                if (childItem && childItem.productId) {
                    if (relation.relationType === 'analogCamera') {
                        deviceCount.analogCamera += device.quantity * childItem.quantity;
                    } else if (relation.relationType === 'sensorUnit') {
                        deviceCount.sensorUnit += device.quantity * childItem.quantity;
                    } else if (relation.relationType === 'iorelays') {
                        deviceCount.iorelay += device.quantity * childItem.quantity;
                    }
                }
            }
        }

        return deviceCount;
    },
);
