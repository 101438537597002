import { Module } from 'app/ioc';
import {
    ExportProjectActionService,
    ProjectSettingsExporterService,
    CamerasExporterService,
    EncodersExporterService,
    MainUnitsExporterService,
    ScheduleExporterService,
    ProjectSettingsCommunicator,
    SpeakersExporterService,
    ZonesExporterService,
    RadarsExporterService,
    PacsExporterService,
    DoorStationsExporterService,
    DecoderExporterService,
    MapsExporterService,
    PeopleCountersExporterService,
    AlertersExporterService,
    ConnectivityDevicesExporterService,
    PagingConsolesExporterService,
    ProjectSettingsValidator,
    BodyWornExporterService,
} from './services';
import { exportProjectSettingsReducer } from './ExportProjectSettingsReducer';
import { ProjectSettingsImporterService } from './services/ProjectSettingsImporter.service';
import {
    AlerterImporterService,
    BodyWornCameraImporterService,
    CameraExtensionImporterService,
    CamerasImporterService,
    ConnectivityDeviceImporterService,
    DecoderImporterService,
    DockingStationImporterService,
    DoorStationsImporterService,
    EncoderImporterService,
    MainUnitImporterService,
    PacImporterService,
    PagingConsoleImporterService,
    PeopleCounterImporterService,
    ProfileImporterService,
    ProjectImporterService,
    RadarImporterService,
    ScheduleImporterService,
    SpeakersImporterService,
    SystemControllerImporterService,
    FloorPlanImporterService,
} from './services/importers';

@Module({
    binder: (binder) => {
        binder.bindService(ExportProjectActionService);
        binder.bindService(ProjectSettingsExporterService);
        binder.bindService(CamerasExporterService);
        binder.bindService(EncodersExporterService);
        binder.bindService(MainUnitsExporterService);
        binder.bindService(ScheduleExporterService);
        binder.bindService(ProjectSettingsCommunicator);
        binder.bindService(SpeakersExporterService);
        binder.bindService(ZonesExporterService);
        binder.bindService(RadarsExporterService);
        binder.bindService(PacsExporterService);
        binder.bindService(DoorStationsExporterService);
        binder.bindService(DecoderExporterService);
        binder.bindService(MapsExporterService);
        binder.bindService(PeopleCountersExporterService);
        binder.bindService(AlertersExporterService);
        binder.bindService(ConnectivityDevicesExporterService);
        binder.bindService(PagingConsolesExporterService);
        binder.bindService(BodyWornExporterService);
        binder.bindService(ProjectSettingsValidator);
        binder.bindService(ProjectSettingsImporterService);
        binder.bindService(ProjectImporterService);
        binder.bindService(ScheduleImporterService);
        binder.bindService(CamerasImporterService);
        binder.bindService(PacImporterService);
        binder.bindService(ProfileImporterService);
        binder.bindService(SpeakersImporterService);
        binder.bindService(MainUnitImporterService);
        binder.bindService(PeopleCounterImporterService);
        binder.bindService(AlerterImporterService);
        binder.bindService(RadarImporterService);
        binder.bindService(EncoderImporterService);
        binder.bindService(SystemControllerImporterService);
        binder.bindService(DoorStationsImporterService);
        binder.bindService(BodyWornCameraImporterService);
        binder.bindService(ConnectivityDeviceImporterService);
        binder.bindService(PagingConsoleImporterService);
        binder.bindService(CameraExtensionImporterService);
        binder.bindService(DecoderImporterService);
        binder.bindService(DockingStationImporterService);
        binder.bindService(FloorPlanImporterService);
    },
    reducers: {
        exportProjectSettings: exportProjectSettingsReducer,
    },
})
export class ProjectSettingsShareModule {}
