import { injectable } from 'inversify';
import { ThunkAction, ActionCreator } from 'app/store';

import { ShareActions } from '../actions';
import { Id } from 'app/core/persistence';
import { ProjectSettingsExporterService } from './ProjectSettingsExporter.service';
import { IProjectSettingToken } from '../models/IProjectSettingToken';

@injectable()
export class ExportProjectActionService {
    constructor(private projectSettingsExporterService: ProjectSettingsExporterService) {}

    @ActionCreator()
    public generateToken(projectId: Id, shareToken: IProjectSettingToken | null): ThunkAction {
        return async (dispatch) => {
            dispatch({
                type: ShareActions.GenerateAccessCode,
                payload: this.projectSettingsExporterService.uploadSettingsFile(
                    projectId,
                    shareToken ? shareToken.token : null,
                ),
            });
        };
    }

    @ActionCreator()
    public revokeToken(projectId: Id, shareToken: IProjectSettingToken | null): ThunkAction {
        return async (dispatch) => {
            // If no code return
            if (!shareToken) {
                return;
            }

            dispatch({
                type: ShareActions.RevokeAccessCode,
                payload: this.projectSettingsExporterService.revokeTokenAndUpdateProject(
                    projectId,
                    shareToken.token,
                ),
            });
        };
    }

    @ActionCreator()
    public saveSettingsToFile(projectId: Id): ThunkAction {
        return (dispatch) => {
            dispatch({
                type: ShareActions.SaveSettingsToFile,
                payload: this.projectSettingsExporterService.createAndSaveSettingsFile(projectId),
            });
        };
    }
}
