import * as React from 'react';
import { IconButton, Modal, Box, Text, LinkIconButton } from 'app/components';
import type { IAutoTestable } from 'app/components';

interface IHelpButtonProps extends IAutoTestable {
    modalTitle: string;
    modalInfo: string;
    manualLink: string;
    linkText: string;
    hoverText?: string;
    productInfo?: string;
    productManualLink?: string;
    additionalInfo?: { informationText: string; linkText: string; link: string }[];
}

export const HelpButton: React.FC<IHelpButtonProps> = ({
    modalTitle,
    modalInfo,
    manualLink,
    linkText,
    hoverText,
    productInfo,
    productManualLink,
    additionalInfo,
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const getInfo = (infoText: string, linkString: string, link: string) => {
        return (
            <>
                <Box paddingY="half">
                    <Text>{infoText}</Text>
                </Box>
                <Box justifyContent="end" padding="half">
                    <LinkIconButton
                        text={linkString}
                        size="sm"
                        icon="arrow_forward"
                        iconRight={true}
                        external
                        link={link}
                    />
                </Box>
            </>
        );
    };

    return (
        <>
            <IconButton
                testId="btn_help"
                icon="help"
                size="ms"
                onClick={() => setShowModal(true)}
                title={hoverText}
            />
            {showModal && (
                <Modal onClose={() => setShowModal(false)} title={modalTitle} maxWidth="465px">
                    {getInfo(modalInfo, linkText, manualLink)}
                    {productInfo &&
                        productManualLink &&
                        getInfo(productInfo, linkText, productManualLink)}
                    {additionalInfo &&
                        additionalInfo.map((info) =>
                            getInfo(info.informationText, info.linkText, info.link),
                        )}
                </Modal>
            )}
        </>
    );
};

HelpButton.displayName = 'HelpButton';
