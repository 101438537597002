import { createSelector } from 'reselect';
import {
    getSelectedCoverageAreaInfo,
    getSelectedMapItemId,
    getSelectedSensorId,
} from './getSelectedId';
import { getSelectedDevice } from './getSelectedDevice';
import type { IInstallationPointModel, IInstallationPointSensorModel } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { createCachedSelector } from 're-reselect';
import type { ISensorCoverageAreaInfo } from '../models';
import { isSensorCoverageAreaInfo } from '../models';
import { toCacheKey } from 'app/modules/common';
import { getInstallationPointsPerMap } from './floorPlanMapping';
import { getSelectedMapOrDefault } from './getFloorPlans';
import {
    getDerotatedInstallationPoints,
    getProjectInstallationPoints,
} from './getProjectInstallationPoints';

/**
 * Get the selected installation point if any installation point is selected.
 */
export const getSelectedInstallationPoint = createSelector(
    [getSelectedMapItemId, getProjectInstallationPoints],
    (id, ips) => ips.find((ip) => ip._id === id),
);

/**
 * Get the selected installation point if any installation point is selected.
 * If the selected map is geolocated, derotate the installationPoint
 */
export const getDerotatedSelectedInstallationPoint = createSelector(
    [getSelectedMapItemId, getDerotatedInstallationPoints],
    (id, installationPoints) => (id ? installationPoints[id] : undefined),
);

/**
 * Get the selected installation point within the currently selected map, or the installation point with the lowest number in name (e.g. 1/7).
 */
export const getSelectedInstallationPointWithinMap = createSelector(
    [getSelectedMapItemId, getSelectedMapOrDefault, getSelectedDevice, getInstallationPointsPerMap],
    (id, selectedMap, mapsDevice, ipsPerMap) => {
        const installationPointsForMap = selectedMap ? ipsPerMap[selectedMap._id] : undefined;

        if (!installationPointsForMap) return undefined;

        // Return the installation point with lowest number in name (e.g. 1/7) if no installation point is selected
        return (
            installationPointsForMap.find((ip) => ip._id === id) ??
            installationPointsForMap
                .filter((ip) => ip.parentDevice._id === mapsDevice?.id)
                .sort(installationPointNameComparator)[0]
        );
    },
);
/**
 * Get the selected sensor. If the selected map is geolocated, use the derotated the sensor.
 */
export const getSelectedDerotatedSensor = createSelector(
    [getDerotatedSelectedInstallationPoint, getSelectedSensorId],
    (installationPoint, selectedSensorId) => {
        if (!installationPoint) return undefined;

        return (
            installationPoint?.sensors.find(({ sensorId }) => sensorId === selectedSensorId) ??
            installationPoint?.sensors[0]
        );
    },
);

export const getSelectedSensor = createSelector(
    [getSelectedInstallationPoint, getSelectedSensorId],
    (installationPoint, selectedSensorId) => {
        if (!installationPoint) return undefined;

        return (
            installationPoint?.sensors.find(({ sensorId }) => sensorId === selectedSensorId) ??
            installationPoint?.sensors[0]
        );
    },
);

const getCoverageAreaInfoFromSensor = createCachedSelector(
    [
        getDerotatedSelectedInstallationPoint,
        (_state: IStoreState, sensorId: number | undefined) => sensorId,
    ],
    (installationPoint, sensorId) => {
        if (!sensorId) return undefined;
        const sensor = installationPoint?.sensors[sensorId - 1];

        return getSensorCoverageAreaFromInstallationPointSensor(sensor);
    },
)(toCacheKey);

export const getSensorCoverageAreaInfo = createCachedSelector(
    [getSelectedCoverageAreaInfo, getCoverageAreaInfoFromSensor],
    (selectedCoverageAreaInfo, sensorCoverageAreaInfo) => {
        return isSensorCoverageAreaInfo(selectedCoverageAreaInfo)
            ? ({
                  ...selectedCoverageAreaInfo,
                  corridorFormat: sensorCoverageAreaInfo?.corridorFormat,
              } as ISensorCoverageAreaInfo)
            : sensorCoverageAreaInfo;
    },
)(toCacheKey);

const getSensorCoverageAreaFromInstallationPointSensor = (
    sensor: IInstallationPointSensorModel | undefined,
) => {
    return sensor
        ? {
              corridorFormat: sensor.settings.corridorFormat,
              fovLimits: sensor.fovLimits,
              horizontalAngle: sensor.target.horizontalAngle,
              horizontalFov: sensor.settings.horizontalFov,
              sensorId: sensor.sensorId,
              targetDistance: sensor.target.distance,
              targetHeight: sensor.target.height,
          }
        : undefined;
};

const installationPointNameComparator = (
    a: IInstallationPointModel,
    b: IInstallationPointModel,
): number => {
    const aName = a.name || a.parentDevice.name;
    const bName = b.name || b.parentDevice.name;
    return aName.toLowerCase().localeCompare(bName.toLowerCase(), undefined, { numeric: true });
};
