import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { Stack, Box, DataWidget } from 'app/components';
import { t } from 'app/translate';
import {
    getProjectTotalDeviceCount,
    getBandwidthTotal,
    getBandwidthUsed,
    getFormattedBandwidth,
    getStorageTotal,
    getStorageUsed,
    getFormattedStorage,
    getCurrentProjectBasePath,
} from 'app/modules/common';

interface IProjectStatsProps {
    devicesInstalled: number;
    storageUsed: number;
    storageTotal: number;
    formattedStorage: string;
    bandwidthUsed: number;
    bandwidthTotal: number;
    formattedBandwidth: string;
    basePath: string;
}

const mapStateToProps = (storeState: IStoreState): IProjectStatsProps => {
    const devicesInstalled = getProjectTotalDeviceCount(storeState);
    const storageUsed = getStorageUsed(storeState);
    const storageTotal = getStorageTotal(storeState);
    const formattedStorage = getFormattedStorage(storeState);
    const bandwidthUsed = getBandwidthUsed(storeState);
    const bandwidthTotal = getBandwidthTotal(storeState);
    const formattedBandwidth = getFormattedBandwidth(storeState);
    const basePath = getCurrentProjectBasePath(storeState);

    return {
        devicesInstalled,
        storageUsed,
        storageTotal,
        formattedStorage,
        bandwidthUsed,
        bandwidthTotal,
        formattedBandwidth,
        basePath,
    };
};

export const ProjectStatsContainer: React.FunctionComponent<IProjectStatsProps> = ({
    devicesInstalled,
    storageUsed,
    storageTotal,
    formattedStorage,
    bandwidthUsed,
    bandwidthTotal,
    formattedBandwidth,
    basePath,
}) => (
    <Stack justifyContent="center" spacing="doublePanel">
        <DataWidget
            testId="devices_installed"
            text={devicesInstalled}
            description={t.devicesInstalled}
            icon="device"
            link={`${basePath}devices/`}
        />
        <Box paddingX="doublePanel">
            <DataWidget
                testId="estimated_storage"
                text={formattedStorage}
                description={t.storageUsed}
                icon={storageTotal ? undefined : 'storage_xs'}
                chart={
                    storageTotal
                        ? {
                              chartType: 'pie',
                              value: storageUsed,
                              maxValue: storageTotal,
                          }
                        : undefined
                }
                link={`${basePath}recording/`}
            />
        </Box>
        <DataWidget
            testId="estimated_bandwidth"
            text={formattedBandwidth}
            description={t.bandwidthConsumed}
            icon={bandwidthTotal ? undefined : 'recording_bw_xs'}
            chart={
                bandwidthTotal
                    ? {
                          chartType: 'bar',
                          value: bandwidthUsed,
                          maxValue: bandwidthTotal,
                      }
                    : undefined
            }
            link={`${basePath}recording/`}
        />
    </Stack>
);

export const ProjectStats = connect(mapStateToProps)(ProjectStatsContainer);

ProjectStats.displayName = 'ProjectStats';
