import { ProjectPricingApiCommunicator } from './ProjectPricingApi.communicator';
import { injectable } from 'inversify';

@injectable()
export class ProjectPricingService {
    constructor(private projectPricingApiCommunicator: ProjectPricingApiCommunicator) {}

    public async openProjectPricingUrl(blob: any): Promise<void> {
        const link = await this.projectPricingApiCommunicator.fetchProjectPricingUrl(blob);
        window.open(link, '_blank');
    }
}
