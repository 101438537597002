import { custom } from 'app/modules/common';

import { ServiceLocator } from 'app/ioc';

import type { PiaCategory } from 'app/core/pia';
import {
    PiaItemMainUnitCategory,
    PiaItemEncoderCategory,
    PiaItemDecoderCategory,
    PiaItemPacCategory,
    PiaItemDetectorCategory,
    PiaItemSpeakerCategory,
    PiaItemPeopleCounterCategory,
    PiaItemAlerterCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
    PiaItemWearablesCategory,
    getAllCameraCategories,
    PiaItemService,
    PiaItemSoftwareCategory,
} from 'app/core/pia';

const ISO_8601_DATE = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
const TIME_24_HOURS = /^(?:[01]\d|2[0-3]):[0-5]\d|24:00$/;
const SCHEMA_VERSION = 1.7;

const CAMERA_CATEGORIES = getAllCameraCategories();

/**
 * Creates a custom validator that validates if the PIA item is of the allowed categories
 * @param allowedCategories
 */
const createPiaCategoryValidator = (allowedCategories: PiaCategory[]) =>
    custom((piaId) => {
        if (typeof piaId !== 'number') {
            return false;
        }

        const piaItemService = ServiceLocator.get(PiaItemService);
        const piaProduct = piaItemService.get(piaId).first();

        if (piaProduct === null) {
            return false;
        }

        if (!allowedCategories.includes(piaProduct.category)) {
            return false;
        }

        return true;
    });

export const cameraCategoryValidator = createPiaCategoryValidator(CAMERA_CATEGORIES);
export const doorStationCategoryValidator = createPiaCategoryValidator([
    PiaItemPacCategory.DOORSTATIONS,
]);
export const mainUnitCategoryValidator = createPiaCategoryValidator([
    PiaItemMainUnitCategory.MAINUNIT,
]);
export const encoderCategoryValidator = createPiaCategoryValidator([
    PiaItemEncoderCategory.ENCODER,
]);
export const decoderCategoryValidator = createPiaCategoryValidator([
    PiaItemDecoderCategory.DECODER,
]);
export const pacCategoryValidator = createPiaCategoryValidator([
    PiaItemPacCategory.DOORCONTROLLERS,
    PiaItemPacCategory.IORELAYS,
    PiaItemPacCategory.DOORSTATIONS,
    PiaItemPacCategory.NETWORKREADER,
]);
export const radarCategoryValidator = createPiaCategoryValidator([
    PiaItemDetectorCategory.RADARDETECTORS,
]);
export const speakerCategoryValidator = createPiaCategoryValidator([
    PiaItemSpeakerCategory.SPEAKER,
]);
export const peopleCounterCategoryValidator = createPiaCategoryValidator([
    PiaItemPeopleCounterCategory.PEOPLECOUNTERS,
]);
export const alerterCategoryValidator = createPiaCategoryValidator([
    PiaItemAlerterCategory.ALERTERS,
]);
export const connectivityDeviceCategoryValidator = createPiaCategoryValidator([
    PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES,
]);
export const pagingConsoleCategoryValidator = createPiaCategoryValidator([
    PiaItemPagingConsoleCategory.PAGINGCONSOLE,
]);
export const bodyWornCameraCategoryValidator = createPiaCategoryValidator([
    PiaItemWearablesCategory.CAMERAS,
]);
export const dockingStationCategoryValidator = createPiaCategoryValidator([
    PiaItemWearablesCategory.DOCKING,
]);
export const systemControllerCategoryValidator = createPiaCategoryValidator([
    PiaItemWearablesCategory.CONTROLLER,
]);
export const cameraExtensionCategoryValidator = createPiaCategoryValidator([
    PiaItemWearablesCategory.CAMERAEXTENSIONS,
]);
export const applicationCategoryValidator = createPiaCategoryValidator([
    PiaItemSoftwareCategory.ACAP,
]);

export const dateValidator = custom(
    (value) => typeof value === 'string' && ISO_8601_DATE.test(value),
);
export const timeValidator = custom(
    (value) => typeof value === 'string' && TIME_24_HOURS.test(value),
);
export const fileVersionValidator = custom((value) => Number(value) <= SCHEMA_VERSION);
