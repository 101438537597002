import * as React from 'react';
import type { IProduct } from 'app/modules/common';
import { Clickable, type IWithChildren } from 'app/components';
import { useService } from 'app/ioc';
import { BillOfMaterialsActionService } from '../services';

export interface IExportBOM extends IWithChildren {
    products: IProduct[];
    trackBOMExport(): void;
}

export const BillOfMaterialsExportToBOMFile: React.FC<IExportBOM> = ({
    products,
    trackBOMExport,
    children,
}) => {
    const actions = useService(BillOfMaterialsActionService);

    const downloadFile = () => {
        trackBOMExport();
        actions.generateBomFile(products);
    };

    return <Clickable onClick={downloadFile}>{children}</Clickable>;
};
