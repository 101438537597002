import type { IItemNetworkSettings } from 'app/core/persistence';
import type { IExportedItemNetworkSettings } from '../../../../models';

export const getImportedItemNetworkSettings = (
    networkSettings: IExportedItemNetworkSettings[] | undefined,
): IItemNetworkSettings[] | undefined => {
    if (!networkSettings) return undefined;
    return networkSettings.map((networkSetting) => ({
        dhcp: networkSetting.dhcp,
        addresses: networkSetting.ipV4?.addresses ?? [],
        subnetMask: networkSetting.ipV4?.subnetMask,
        defaultRouter: networkSetting.ipV4?.defaultRouter,
    }));
};
