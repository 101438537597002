import { Id } from 'app/core/persistence';
import type { ISpeakerItemEntity, UnitSystem } from 'app/core/persistence';
import { ImportedSpeaker } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { defaultColors } from 'app/core/common';
import { mapToItemEntity, getImportedAccessories } from './utils';
import { getDefaultSpeakerFilter } from 'app/modules/common';

@injectable()
export class SpeakersImporterService {
    constructor() {}

    public import(projectId: Id, speakers: ImportedSpeaker[], unitSystem: UnitSystem) {
        const speakersEntities = speakers.map((speaker) =>
            this.mapToSpeakerEntity(projectId, speaker, unitSystem),
        );

        const accessoryEntities = getImportedAccessories(projectId, speakers);
        return [...speakersEntities, ...accessoryEntities];
    }

    private mapToSpeakerEntity(
        projectId: Id,
        speaker: ImportedSpeaker,
        unitSystem: UnitSystem,
    ): ISpeakerItemEntity {
        const itemEntity = mapToItemEntity(
            projectId,
            speaker,
            speaker.piaId,
            defaultColors.DEFAULT_SPEAKER_COLOR,
        );

        return {
            ...itemEntity,
            properties: {
                speaker: {
                    filter: {
                        ...getDefaultSpeakerFilter(unitSystem),
                        installationHeight: speaker.installationHeight,
                    },
                },
            },
        };
    }
}
