import type { IStoreState, ProjectStoreState } from 'app/store';
import type { ISharedProjectsState } from '../sharedProjects';
import type { IUserProjectsState } from '../userProjects';

export const getLoaded = (state: IStoreState, property: ProjectStoreState) => {
    const userProjects = state[property] as IUserProjectsState;
    const sharedProjects = state[property] as ISharedProjectsState;

    if (userProjects) {
        return userProjects.loaded && !userProjects.replicating;
    }

    if (sharedProjects) {
        return sharedProjects.loaded && !sharedProjects.replicating;
    }

    return false;
};
