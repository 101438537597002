import * as React from 'react';
import { t } from 'app/translate';
import {
    Button,
    SearchField,
    Checkbox,
    ContentHeaderBar,
    UploadFile,
    Positioned,
    Box,
    Text,
} from 'app/components';
import { eventTracking } from 'app/core/tracking';
import { useSelector } from 'react-redux';
import { getShowArchivedProjects, getUserProjectNameFilter } from '../../selectors';
import type { IStoreState } from 'app/store';
import { useNavigate } from 'react-router-dom';
import { LocalProjectsActionService } from '../services';
import { useService } from 'app/ioc';
import { ACCEPTED_PROJECT_FILE_EXTENSIONS, SETTINGS_FILE_EXTENSION } from 'app/core/persistence';

export const LocalProjectsContentHeader: React.FC = () => {
    const localProjectsActionService = useService(LocalProjectsActionService);
    const projectsFilter = useSelector((state: IStoreState) =>
        getUserProjectNameFilter(state, 'localProjects'),
    );
    const showArchivedProjects = useSelector((state: IStoreState) =>
        getShowArchivedProjects(state, 'localProjects'),
    );
    const navigate = useNavigate();

    const importProject = (file: File) => {
        eventTracking.logUserEvent('Local Projects', 'Import Project');
        const fileType = file.name.slice(file.name.lastIndexOf('.'));
        if (fileType === SETTINGS_FILE_EXTENSION) {
            localProjectsActionService.importSettings(file);
        } else {
            eventTracking.logUserEvent('User Projects', 'Import Project');
            localProjectsActionService.importProject(file);
        }
    };

    const addProject = async () => {
        localProjectsActionService.addProject(navigate);
    };

    const toggleShowArchivedProjects = (value: boolean) => {
        localProjectsActionService.showArchivedProjects(value);
    };

    const updateProjectNameFilter = (value: string) => {
        localProjectsActionService.userProjectFilterChange(value);
    };

    return (
        <Positioned sendForward position="sticky" top="0px">
            <Box width="100%" color="grey1" direction="column">
                <Box paddingLeft="base" paddingTop="base" direction="column">
                    <Text style="title">{t.localProjects}</Text>
                </Box>
                <ContentHeaderBar>
                    <Box wrap spacing="base">
                        <Button testId="btn_add_project" primary onClick={addProject}>
                            {t.projectAddProject}
                        </Button>
                        <UploadFile
                            testId="npt_upload_file"
                            title={t.projectImportActionLabel}
                            onFileUploaded={importProject}
                            accept={ACCEPTED_PROJECT_FILE_EXTENSIONS.join(',')}
                        />
                    </Box>
                    <Box spacing="base">
                        <Checkbox
                            testId="archived_project_checkbox"
                            selected={showArchivedProjects}
                            onChange={toggleShowArchivedProjects}
                            slider
                        >
                            {t.projectShowArchived}
                        </Checkbox>

                        <SearchField
                            testId="project_search_field"
                            value={projectsFilter}
                            onValueChange={updateProjectNameFilter}
                        />
                    </Box>
                </ContentHeaderBar>
            </Box>
        </Positioned>
    );
};
