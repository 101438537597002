import * as React from 'react';
import { Heading, ContentPanel, Stack } from 'app/components';
import { ServiceLocator } from 'app/ioc';
import type { IProductEstimate } from '../common';
import { GenericEstimatesService, getCurrentProjectId, ProjectDefaultsService } from '../common';
import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import { css } from '@emotion/css';
import { UserProjectsService } from 'app/modules/userProjects';

const textLeftStyle = css`
    th {
        text-align: left;
    }
`;

export const EstimatesPageComponent: React.FC = () => {
    const [genericsEstimateService] = React.useState<GenericEstimatesService>(
        ServiceLocator.get(GenericEstimatesService),
    );

    const [projectDefaultsService] = React.useState<ProjectDefaultsService>(
        ServiceLocator.get(ProjectDefaultsService),
    );

    const [userProjectsService] = React.useState<UserProjectsService>(
        ServiceLocator.get(UserProjectsService),
    );

    const projectId = useSelector<IStoreState, Id>(getCurrentProjectId);

    React.useEffect(() => {
        (async function addDefaults() {
            await projectDefaultsService.addProjectDefaults(projectId);
            const projectBandwidthVersion =
                await userProjectsService.getProjectBandwidthVersion(projectId);
            const estimate = await genericsEstimateService.getGenericEstimates(
                projectId,
                projectBandwidthVersion,
            );
            setEstimates(estimate);
        })();
    }, [genericsEstimateService, projectDefaultsService, projectId, userProjectsService]);

    const [estimates, setEstimates] = React.useState<IProductEstimate[]>([]);

    return (
        <ContentPanel userSelect color="grey1">
            <Stack vertical>
                <Heading>{'Bandwidth Estimates'}</Heading>
                {estimates && (
                    <table className={textLeftStyle}>
                        <thead>
                            <tr>
                                <th>Model</th>
                                <th>Pia Id</th>
                                <th>Bandwidth H264 (bit/sec)</th>
                                <th>H264 using fallback</th>
                                <th>Bandwidth H265 (bit/sec)</th>
                                <th>H265 using fallback</th>
                                <th>Bandwidth AV1 (bit/sec)</th>
                                <th>AV1 using fallback</th>
                            </tr>
                        </thead>
                        <tbody>
                            {estimates.map((estimate) => {
                                return (
                                    <tr key={estimate.piaId}>
                                        <td>{estimate.piaModel}</td>
                                        <td>{estimate.piaId}</td>
                                        <td>{estimate.bandwidth264?.toFixed(2) ?? ''}</td>
                                        <td>{estimate.hasH264ScalingCost ? '' : 'Yes'}</td>
                                        <td>{estimate.bandwidth265?.toFixed(2) ?? ''}</td>
                                        <td>
                                            {/* Never display scaling cost/fallback if camera has no H265 encoding */}
                                            {!estimate.bandwidth265 || estimate.hasH265ScalingCost
                                                ? ''
                                                : 'Yes'}
                                        </td>

                                        <td>{estimate.bandwidthAV1?.toFixed(2) ?? ''}</td>
                                        <td>
                                            {/* Never display scaling cost/fallback if camera has no AV1 encoding */}
                                            {!estimate.bandwidthAV1 || estimate.hasAV1ScalingCost
                                                ? ''
                                                : 'Yes'}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </Stack>
        </ContentPanel>
    );
};
