import {
    IMapBlocker,
    IMapInstallationPoint,
    ImportedFloorPlanMap,
    ImportedMap,
    ImportedPiaProduct,
    ImportedStreetMap,
} from './../../../models';
import {
    CreateEntityService,
    IBlockerBaseEntity,
    Id,
    IFloorPlanExportEntity,
    IInstallationPointBaseEntity,
    IP_LABEL_OFFSET,
    MapType,
} from 'app/core/persistence';
import { injectable } from 'inversify';

export type FloorPlanBaseEntity = Omit<
    IFloorPlanExportEntity,
    'creationDate' | 'updatedDate' | 'entityVersion' | '_id' | '_rev'
>;

@injectable()
export class FloorPlanImporterService {
    constructor(private createEntityService: CreateEntityService) {}

    public import(
        projectId: Id,
        maps: ImportedMap[],
        piaProducts: Record<Id, ImportedPiaProduct>,
    ): (FloorPlanBaseEntity | IBlockerBaseEntity | IInstallationPointBaseEntity)[] {
        let isDefaultMapImported = false;

        return maps.reduce(
            (importedEntities, mapToImport) => {
                const installationPoints = this.shouldImportInstallationPoints(mapToImport)
                    ? this.mapToInstallationPointEntities(
                          projectId,
                          mapToImport.id,
                          mapToImport.type,
                          mapToImport.installationPoints,
                          piaProducts,
                      )
                    : [];

                const blockers = this.mapToBlockerEntities(
                    projectId,
                    mapToImport.id,
                    mapToImport.type,
                    mapToImport.blockers,
                );

                const map =
                    mapToImport.type === 'FloorPlan'
                        ? this.mapToFloorPlan(mapToImport, projectId)
                        : isDefaultMapImported
                          ? undefined
                          : this.mapToGeoMap(mapToImport, projectId);

                if (map?.mapType === 'StreetMap') {
                    // Only one street map should be imported
                    isDefaultMapImported = true;
                }

                return [
                    ...importedEntities,
                    ...(map ? [map] : []),
                    ...installationPoints,
                    ...blockers,
                ];
            },
            [] as (FloorPlanBaseEntity | IBlockerBaseEntity | IInstallationPointBaseEntity)[],
        );
    }

    private shouldImportInstallationPoints(mapToImport: ImportedMap) {
        return (
            mapToImport.type === 'StreetMap' ||
            !mapToImport.images.every((image) => image.geoLocation)
        );
    }

    private mapToFloorPlan(mapToImport: ImportedFloorPlanMap, projectId: string) {
        const image = mapToImport.images[0];

        return {
            type: 'floorPlan',
            archived: false,
            name: mapToImport.name,
            mapType: 'FloorPlan',
            image: {
                name: '', // Empty since we do not have the image name
                key: mapToImport.images[0].key,
                base64: image.image,
                opacity: 0.5,
                dimensions: {
                    height: image.height,
                    width: image.width,
                },
                ...(image.imageBounds
                    ? {
                          bounds: {
                              bottomRight: image.imageBounds.bottomRight,
                              topLeft: image.imageBounds.topLeft,
                          },
                      }
                    : {}),
                ...(image.geoLocation ? { geoLocation: image.geoLocation } : {}),
            },
            path: [projectId, mapToImport.id],
            locked: false,
        } satisfies FloorPlanBaseEntity;
    }

    private mapToGeoMap(mapToImport: ImportedStreetMap, projectId: string) {
        return {
            name: mapToImport.name,
            mapType: 'StreetMap',
            path: [projectId, mapToImport.id],
            isDefault: true,
            archived: false,
            locked: false,
            type: 'floorPlan',
            location: mapToImport.geoLocation,
        } satisfies FloorPlanBaseEntity;
    }

    private mapToBlockerEntities(
        projectId: Id,
        mapId: Id,
        mapType: MapType,
        blockers: IMapBlocker[],
    ): IBlockerBaseEntity[] {
        return blockers.map((blocker) => {
            const blockerId = this.createEntityService.generateDatabaseId('blocker');
            return {
                type: 'blocker',
                floorLevel: 0, // Defaults to 0
                latLngs: blocker.polyline,
                locked: false,
                mapOrigin: mapId,
                path:
                    mapType === 'FloorPlan'
                        ? [projectId, mapId, blockerId]
                        : [projectId, blockerId],
                archived: false,
            };
        });
    }

    private mapToInstallationPointEntities(
        projectId: Id,
        mapId: Id,
        mapType: MapType,
        installationPoints: IMapInstallationPoint[],
        piaProducts: Record<Id, ImportedPiaProduct>,
    ): IInstallationPointBaseEntity[] {
        return installationPoints.map((installationPoint) => {
            const path = installationPoint.parentItemId
                ? [
                      projectId,
                      installationPoint.parentItemId,
                      installationPoint.itemId,
                      installationPoint.id,
                  ]
                : [projectId, installationPoint.itemId, installationPoint.id];
            const parentId = installationPoint.parentItemId
                ? installationPoints.find((ip) => ip.itemId === installationPoint.parentItemId)?.id
                : undefined;
            const installationPointBaseEntity: IInstallationPointBaseEntity = {
                type: 'installationPoint',
                mapOrigin: mapId,
                floorPlanId: mapType === 'FloorPlan' ? mapId : undefined,
                linkedId: installationPoint.linkedId,
                name: installationPoint.name,
                parentId,
                path,
                archived: false,
                locked: false,
                height: installationPoint.height,
                sensors: installationPoint.imageSensors.map((imageSensor) => ({
                    parentPiaDeviceId: imageSensor.devicePiaId,
                    isVirtual:
                        imageSensor.devicePiaId !== null &&
                        imageSensor.devicePiaId !== piaProducts[installationPoint.itemId]?.piaId,
                    sensorId: imageSensor.index,
                    target: {
                        height: imageSensor.targetHeight,
                        distance: imageSensor.targetDistance,
                        horizontalAngle: imageSensor.rotation,
                    },
                    settings: {
                        corridorFormat: imageSensor.corridorFormat,
                        horizontalFov: imageSensor.horizontalFov,
                    },
                })),
                radar: installationPoint.radars.map((radar) => ({
                    target: {
                        height: 3.5,
                        distance: radar.targetDistance,
                        horizontalAngle: radar.rotation,
                    },
                }))[0],
                speaker: installationPoint.speakers.map((speaker) => ({
                    target: {
                        height: 3.5,
                        distance: speaker.targetDistance,
                        horizontalAngle: speaker.rotation,
                    },
                    settings: {
                        outdoor: false,
                        basicSolution: true,
                    },
                }))[0],
                location: installationPoint.location,
                labelOffset: IP_LABEL_OFFSET,
            };
            return installationPointBaseEntity;
        });
    }
}
