import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    getFloorPlansInstallationPointsFilteredByLayersAndBounds,
    getRadarsWithCoexistenceWarning,
    getSelectedMapOrDefault,
    getShowRadarCoexistenceWarning,
    getDraftInstallationPoints,
} from '../../selectors';
import { InstallationPointMapItem } from './InstallationPointMapItem';
import type { Id } from 'app/core/persistence';
import { useService } from 'app/ioc';
import type { IStoreState } from 'app/store';
import { MapsActionService } from '../../services';
import { useMapContext } from '../context';

export const InstallationPoints: React.FC = React.memo(() => {
    const installationPointsFiltered = useSelector(
        getFloorPlansInstallationPointsFilteredByLayersAndBounds,
    );
    const draftInstallationPoints = useSelector(getDraftInstallationPoints);

    const installationPoints = installationPointsFiltered.map((ip) => {
        const draftInstallationPoint = draftInstallationPoints[ip._id];
        if (draftInstallationPoint) {
            const mergedInstallationPoint = {
                ...ip,
                location: draftInstallationPoint.location,
                radar: draftInstallationPoint.radar,
                speaker: draftInstallationPoint.speaker,
                panRange: draftInstallationPoint.panRange,
                height: draftInstallationPoint.height,
                sensors: ip.sensors.map((sensor, i) => ({
                    ...sensor,
                    target: draftInstallationPoint.sensors[i].target,
                })),
            };

            return mergedInstallationPoint;
        }
        return ip;
    });

    const mapsActionService = useService(MapsActionService);
    const showCoexistingWarning = useSelector(getShowRadarCoexistenceWarning);
    const isRadarWarningDismissed = useSelector(getSelectedMapOrDefault)?.isRadarWarningDismissed;
    const { leafletMap } = useMapContext();
    const coexistenceIds = useSelector<IStoreState, Record<Id, boolean>>((state) =>
        getRadarsWithCoexistenceWarning(state, leafletMap.map),
    );

    // coexistingIds contains all coexistingIds for the current floorPlan.
    // If Ids exist (i.e if the record is not empty) we should show a warning
    React.useEffect(() => {
        const radarsWithCoexistingWarning = Object.keys(coexistenceIds).length > 0;
        if (radarsWithCoexistingWarning && !showCoexistingWarning) {
            mapsActionService.setRadarCoexistenceShowWarning(true);
        } else if (!radarsWithCoexistingWarning && showCoexistingWarning) {
            mapsActionService.setRadarCoexistenceShowWarning(false);
        }
    }, [mapsActionService, coexistenceIds, showCoexistingWarning, isRadarWarningDismissed]);

    if (installationPoints.length === 0) return null;

    return (
        <>
            {installationPoints.map((ip) => (
                <InstallationPointMapItem key={ip._id} installationPoint={ip} />
            ))}
        </>
    );
});

InstallationPoints.displayName = 'InstallationPoints';
