export const constants = {
    motionLevel: {
        sampleDistributionWeights: [0.5, 0.4, 0.1],
        motionMax: 1,
        motionMin: 0,
        dfpsFactors: [0.005, 0.01, 0.04, 0.06, 0.1, 0.25, 0.45, 0.65, 0.85, 0.9, 0.95, 1, 1],
        dfpsMotionLevels: [
            0, 0.001, 0.002, 0.005, 0.01, 0.023, 0.05, 0.1, 0.25, 0.56, 1.25, 3, 128,
        ].map((level) => {
            return (
                level /
                // Scaling factor
                (256 / 3)
            );
        }),
        minFpsFactor: 0.5,
        maxFpsFactor: 2,
        stdThreshold: 3,
        stdScale: 3,
        adjustmentTerm: 0.23,
        timeInMotionMax: 0.99,
        timeInMotionMin: 0.01,
    },
    qPRef: 28,
    fpsRef: 30,
    aRef: 0.5,
    resolutionRef: 2073600,
    weatherTime: 0.2,
    weatherMotionLevel: 0.02,
    weatherPNoise: 25,
    backgroundLevelRef: 0.3,
    natureFactor: 1.25,
    wdrFactor: 1.35,
    storageBFrameFactor: 0.5,
    zipStrengthQpGop: {
        0: { QPI: 0, QPP: 0, gopFactor: 1 },
        10: { QPI: 3, QPP: 5, gopFactor: 4 },
        20: { QPI: 4, QPP: 6, gopFactor: 6 },
        25: { QPI: 5, QPP: 7, gopFactor: 8 },
        30: { QPI: 8, QPP: 10, gopFactor: 10 },
        40: { QPI: 12, QPP: 15, gopFactor: 15 },
        50: { QPI: 15, QPP: 20, gopFactor: 20 },
    },
    lightingFactor: {
        1: 0.4,
        2: 0.6,
        3: 1,
    },
    pNoise: {
        // noise at different levels of light
        1: 200, // indoor AND outdoor low light
        2: 75, // indoor AND outdoor medium light
        i3: 1.17, // high lighting indoor
        o3: 7.89, // high lighting outdoor
    },
    sizeOfAverageObject: {
        1: 1.25,
        2: 1,
        3: 0.5,
    },
    refCam: {
        compQPmap: {
            20: 23,
            25: 26,
            30: 28,
            35: 29,
            40: 30,
            45: 31,
            50: 32,
        },
        scalingCost: {
            // H264
            1: {
                bI: 11.79,
                bP: 14.305,
                cI: 0.164,
                cP: 0.265,
            },
            2: {
                bI: 9.475,
                bP: 16.423,
                cI: 0.105,
                cP: 0.388,
            },
            i3: {
                bI: 9.202,
                bP: 16.05,
                cI: 0.09,
                cP: 0.51,
            },
            o3: {
                bI: 9.083,
                bP: 15.1,
                cI: 0.088,
                cP: 0.425,
            },
        },
        scalingCostH265: {
            1: {
                bI: 10.419,
                bP: 13.058,
                cI: 0.147,
                cP: 0.287,
            },
            2: {
                bI: 8.689,
                bP: 8.727,
                cI: 0.106,
                cP: 0.2,
            },
            o3: {
                bI: 8.75,
                bP: 8.483,
                cI: 0.1,
                cP: 0.229,
            },
            i3: {
                bI: 8.811,
                bP: 8.239,
                cI: 0.093,
                cP: 0.257,
            },
        },
        cPlatform: 0.9,
        AV1: {
            // Currently same as H265 and handled separately because don't know if these are needed for AV1 and if so what values.
            // Maybe not needed because AV1 products need both scalingCostAV1 and compQPmapAV1 to be present on PIA product else fallback to H264 for both.
            compQPmap: {
                20: 23,
                25: 26,
                30: 28,
                35: 29,
                40: 30,
                45: 31,
                50: 32,
            },
            scalingCost: {
                // Currently same as H265
                1: {
                    bI: 10.419,
                    bP: 13.058,
                    cI: 0.147,
                    cP: 0.287,
                },
                2: {
                    bI: 8.689,
                    bP: 8.727,
                    cI: 0.106,
                    cP: 0.2,
                },
                o3: {
                    bI: 8.75,
                    bP: 8.483,
                    cI: 0.1,
                    cP: 0.229,
                },
                i3: {
                    bI: 8.811,
                    bP: 8.239,
                    cI: 0.093,
                    cP: 0.257,
                },
            },
            cPlatform: 0.9,
        },
    },
} as const;
