import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
    Closeable,
    Icon,
    IconButton,
    NoPrint,
    Positioned,
    Stack,
    Text,
} from 'app/components';
import { getCompareHasDiscontinued, getProductIdsToCompare } from '../../selectors/getCompare';
import type { IPiaItem, PiaId } from 'app/core/pia';
import { isDiscontinued } from 'app/core/pia';
import { getPiaItemsRecord, ProductName } from 'app/modules/common';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import type { Property } from 'csstype';
import { ServiceLocator } from 'app/ioc';
import { DeviceSelectorActionService } from '../../services';
import { t } from 'app/translate';
import type { IProductLensRecord } from '../../selectors';
import { getCompareProductLenses, getDeviceGroup } from '../../selectors';
import type { DeviceGroup } from '../../models';
import { MAX_COMPARE_COUNT } from '../../state';
import { DeviceWithLens } from '../DeviceWithLens.component';

const timeout = {
    appear: 0,
    enter: 0,
    exit: 400,
};

const transitionDuration = 400;

const stateToTranslation: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '100%',
    entering: '100%',
    // Move a bit up so it is not docked to the bottom
    entered: '-20px',
    exiting: '100%',
    exited: '100%',
};

export const CompareBar: React.FC = () => {
    const [actionService] = React.useState(ServiceLocator.get(DeviceSelectorActionService));
    const productsToCompare = useSelector<IStoreState, PiaId[]>(getProductIdsToCompare);
    const piaItems = useSelector<IStoreState, Record<PiaId, IPiaItem>>(getPiaItemsRecord);
    const deviceGroup = useSelector<IStoreState, DeviceGroup>(getDeviceGroup);
    const lensesForProducts = useSelector<IStoreState, IProductLensRecord>(getCompareProductLenses);
    const anyDiscontinued = useSelector<IStoreState, boolean>(getCompareHasDiscontinued);

    React.useEffect(() => {
        actionService.clearCompareSelection();
        return () => {
            actionService.clearCompareSelection();
        };
    }, [actionService, deviceGroup]);

    const renderEmptyCompareSlot = (index: number) => (
        <Stack key={index} vertical notFullWidth justifyContent="start" alignItems="center">
            <Box justifyContent="center" alignItems="center" minWidth="135px" height="80px">
                <Icon color="grey4" icon="device" size="xl" />
            </Box>
            <Text whiteSpace="nowrap">{t.productName}</Text>
        </Stack>
    );

    const renderDiscontinued = (productId: number) =>
        anyDiscontinued && (
            <Box alignItems="end" justifyContent="end" height="17px">
                {isDiscontinued(piaItems[productId]) && (
                    <Text color="red" align="center">
                        {t.discontinued}
                    </Text>
                )}
            </Box>
        );

    return (
        <Transition
            in={productsToCompare.length > 0}
            timeout={timeout}
            mountOnEnter
            unmountOnExit
            nodeRef={React.useRef(null)}
        >
            {(state) => (
                <Closeable close={() => {}} customShadow="rgb(0 0 0 / 20%) 0px 4px 10px">
                    <NoPrint>
                        <Positioned
                            centerHorizontally
                            position="sticky"
                            bottom="0"
                            translateY={stateToTranslation[state]}
                            transition
                            duration={transitionDuration}
                        >
                            <Box
                                paddingBottom="panel"
                                paddingTop="base"
                                paddingX="panel"
                                color="white"
                                borderRadius="rounded"
                            >
                                <Stack spacing="doublePanel">
                                    <Stack vertical notFullWidth>
                                        <Box justifyContent="center">
                                            <Text style="heading" whiteSpace="nowrap">
                                                {t.productsToCompare}
                                            </Text>
                                        </Box>
                                        <Stack justifyContent="start" spacing="doublePanel">
                                            {productsToCompare.map((productId) => (
                                                <Stack
                                                    key={productId}
                                                    vertical
                                                    notFullWidth
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <DeviceWithLens
                                                        mainDevicePiaId={productId}
                                                        lensName={
                                                            lensesForProducts[productId]?.name
                                                        }
                                                        lensPiaId={lensesForProducts[productId]?.id}
                                                    />
                                                    <Box direction="column" height="37px">
                                                        <ProductName
                                                            large
                                                            name={piaItems[productId].name}
                                                        />
                                                    </Box>
                                                    {renderDiscontinued(productId)}
                                                    <IconButton
                                                        opaque={false}
                                                        color="red"
                                                        icon="close_circle_outline"
                                                        onClick={() =>
                                                            actionService.removeProductFromCompare(
                                                                productId,
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            ))}
                                            {Array.from(
                                                Array(MAX_COMPARE_COUNT - productsToCompare.length),
                                            ).map((_, index) => renderEmptyCompareSlot(index))}
                                        </Stack>
                                    </Stack>
                                    <Stack vertical>
                                        <Button
                                            primary
                                            disabled={productsToCompare.length < 2}
                                            onClick={() => actionService.showComparison(true)}
                                            testId={'show_compare_modal'}
                                        >
                                            {t.compare}
                                        </Button>
                                        <Button text onClick={actionService.clearCompareSelection}>
                                            {t.clear}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Positioned>
                    </NoPrint>
                </Closeable>
            )}
        </Transition>
    );
};

CompareBar.displayName = 'CompareBar';
