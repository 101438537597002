import { injectable } from 'inversify';
import { ActionCreator, IAction, ThunkAction } from 'app/store';
import { TokenService } from 'app/modules/common';
import type {
    Arn,
    IResourceGroupInfo,
    ISharedProjectInfo,
    ResourceGroupType,
} from 'app/core/persistence';
import {
    IOrganizationOrResourceGroup,
    OrganizationsService,
    SharedProjectRepository,
    IDetailedOrganization,
} from 'app/core/persistence';
import { cloneDeep, isEmpty } from 'lodash-es';
import { getOrganizations, getSelectedOrganization } from '../selectors';
import { SharedProjectsActions } from '../state/SharedProjects.actions';

@injectable()
export class OrganizationsActionService {
    constructor(
        private acxService: OrganizationsService,
        private tokenService: TokenService,
        private sharedProjectRepository: SharedProjectRepository,
    ) {}

    @ActionCreator()
    public fetchOrganizations(): ThunkAction {
        return async (dispatch) => {
            const accessToken = await this.tokenService.getAccessToken();
            const response = await this.acxService.getAllDetailedOrganizations(accessToken);
            const organizations = this.getResorceTreeWithArns(response);

            dispatch({
                type: SharedProjectsActions.SET_ORGANIZATIONS,
                payload: organizations,
            });
        };
    }

    /**
     * ONLY used for initialization to set information in state without switching/initializing databases etc.
     */
    @ActionCreator()
    public setInitialSharedProjectInfo(): ThunkAction {
        return async (dispatch, getState) => {
            const state = getState();
            const organizations = getOrganizations(state);
            if (organizations.length < 1) return;

            const sharedProjectInfo =
                await this.sharedProjectRepository.getSelectedSharedProjectInfo();
            let resourceGroup: IResourceGroupInfo | undefined;
            let organization = organizations.find(
                (org: IOrganizationOrResourceGroup) =>
                    org.arn === sharedProjectInfo?.organization.arn,
            );
            // If not organization found, find the first one that has a resource group
            if (!organization) {
                organization = organizations.find((org) => {
                    return Object.values(org.resourceTree.resourceTree).some(
                        (group) => !isEmpty(group.children),
                    );
                });

                resourceGroup =
                    organization && this.getFirstResourceGroupFromOrganization(organization);
            } else {
                const flattendedResourceTree = this.getFlattenedResourceTree(
                    organization.resourceTree.resourceTree,
                );
                resourceGroup = flattendedResourceTree.find(
                    (group) => group.arn === sharedProjectInfo?.resourceGroup.arn,
                );
            }

            if (resourceGroup && organization) {
                resourceGroup.adminPrincipalArns = await this.getAdminPrincipalArns(
                    organization.arn,
                    resourceGroup.arn,
                );
            }

            // No need to set info in state if we don't have both params
            if (!resourceGroup) return;

            dispatch({
                type: SharedProjectsActions.SET_SHARED_PROJECT_INFO,
                payload: { organization, resourceGroup },
            });
        };
    }

    @ActionCreator()
    public setSelectedOrganization(
        organization?: IDetailedOrganization,
    ): IAction<IDetailedOrganization | undefined> {
        return {
            type: SharedProjectsActions.SET_SELECTED_ORGANIZATION,
            payload: organization,
        };
    }

    /**
     *
     * @param organizationArn: arn for the organization
     * @param resourceGroupArn: arn for the resource group
     * @returns Admin principal arns for selected resourceGroupArn
     */
    //TODO: when resourceTree is replaced with children, resourceGroup will already have the correct format including organization
    // and organizationArn is not needed as in parameter and no modification of arn is needed
    private async getAdminPrincipalArns(
        organizationArn: Arn,
        resourceGroupArn: Arn,
    ): Promise<Arn[]> {
        const accessToken = await this.tokenService.getAccessToken();
        const organizationArnPart = organizationArn.replace('arn:organization:', '');

        const accessResourceGroups = await this.acxService.fetchResourceGroupsWithAccess(
            accessToken,
            [`arn:resource-group:${organizationArnPart}/${resourceGroupArn}`],
        );
        let adminPrincipalArns: Arn[] = [];
        accessResourceGroups?.forEach((resourceGroupAccess) =>
            resourceGroupAccess.appliedAccesses.access.forEach((access) => {
                if (access.role.roleName === 'Administrator') {
                    const principalArns = access.assignedPrincipals.map(
                        (principal) => principal.arn,
                    );
                    adminPrincipalArns = principalArns;
                }
            }),
        );
        return adminPrincipalArns;
    }

    // Call when user changes resource group within current or other organization.
    @ActionCreator()
    public setSharedProjectInfo(resourceGroup: IOrganizationOrResourceGroup): ThunkAction {
        return async (dispatch, getState) => {
            const state = getState();
            const organization = getSelectedOrganization(state);
            if (!organization) {
                return Promise.reject('Organization is not selected');
            }

            if (resourceGroup.arn) {
                resourceGroup.adminPrincipalArns = await this.getAdminPrincipalArns(
                    organization.arn,
                    resourceGroup.arn,
                );
                dispatch({
                    type: SharedProjectsActions.SET_SELECTED_RESOURCE_GROUP_ID,
                    payload: resourceGroup.arn,
                });
            }

            const sharedProjectInfo: ISharedProjectInfo = {
                organization,
                resourceGroup,
            };
            await this.sharedProjectRepository.setSelectedSharedProjectInfo(sharedProjectInfo);

            dispatch({
                type: SharedProjectsActions.SET_SHARED_PROJECT_INFO,
                payload: sharedProjectInfo,
            });
            dispatch({
                type: SharedProjectsActions.SET_SELECTED_ORGANIZATION,
                payload: organization,
            });
        };
    }

    private getFirstResourceGroupFromOrganization(organization: IDetailedOrganization) {
        const resourceTree: ResourceGroupType = organization.resourceTree.resourceTree;
        const firstEntry =
            resourceTree && !isEmpty(resourceTree)
                ? resourceTree[Object.keys(resourceTree)[0]].children
                : undefined;

        return firstEntry && Object.values(firstEntry)[0];
    }

    private getFlattenedResourceTree(resourceGroup: Record<string, IResourceGroupInfo>) {
        const flattenedGroups: IOrganizationOrResourceGroup[] = [];
        for (const [key, value] of Object.entries(resourceGroup)) {
            flattenedGroups.push({
                name: value.name,
                arn: key,
            });
            if (value.children) {
                flattenedGroups.push(...this.getFlattenedResourceTree(value.children));
            }
        }
        return flattenedGroups;
    }

    // Add arn to each resource group for each organization
    private getResorceTreeWithArns(organizations: IDetailedOrganization[]) {
        const clonedOrganizations = cloneDeep(organizations);
        clonedOrganizations.forEach((org) => {
            org.resourceTree = this.updateResourceTree(
                JSON.parse(String(org.resourceTree.resourceTree)),
            );
        });
        return clonedOrganizations;
    }

    // Add arn to each resource group
    private updateResourceTree(resourceGroup: Record<string, IResourceGroupInfo>) {
        const newResourceGroup = { ...resourceGroup };
        for (const [key, value] of Object.entries(newResourceGroup)) {
            value.arn = key;
            if (value.children) {
                this.updateResourceTree(value.children);
            }
        }
        return { resourceTree: newResourceGroup };
    }
}
