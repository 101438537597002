import type { IPersistenceRepository } from './models';
import type { IEntity, Id } from '../../entities';
import type { IIdRev } from '../../models';
import { EntitySettings } from './EntitySettings';
import type { EventEmitter } from 'events';
import { injectable } from 'inversify';
import { EventEmitterType, EventEmitterService } from '../../../services/EventEmitter.service';

/**
 * Compared to the normal PersistenceDatabaseRepository this repository uses
 * an in-memory store of all data.
 */
@injectable()
export class PersistenceMemoryRepository implements IPersistenceRepository {
    private emitter: EventEmitter;
    private entities: IEntity[] = [];
    private entitySettings: EntitySettings = new EntitySettings();

    constructor(private eventEmitterService: EventEmitterService) {
        this.emitter = this.eventEmitterService.getEventEmitter('memory');
    }

    /**
     * Initializes the repository, making it ready to handle repository actions.
     * @param entities the entities to initialize the repository with.
     * @param entitySettings the settings for the entities.
     * @param eventEmitterType the type of event emitter to use, defaults to 'memory'.
     */
    public initialize(
        entities: IEntity[],
        entitySettings: EntitySettings,
        eventEmitterType: EventEmitterType = 'memory',
    ): void {
        this.entities = entities;
        this.entitySettings = entitySettings;
        this.emitter = this.eventEmitterService.getEventEmitter(eventEmitterType);
    }

    /**
     * Clears all revs from the entities.
     */
    public clearAllRevs() {
        this.entities.forEach((entity) => {
            entity._rev = '';
        });
    }

    public get(id: Id): Promise<IEntity> {
        const matchingEntity = this.getEntity(id);

        if (!matchingEntity) {
            throw Error(`Entity with ${id} was not found`);
        }

        return Promise.resolve(matchingEntity);
    }

    /**
     * Check if an entity exists.
     * @param id the id of the entity.
     */
    public async exists(id: Id): Promise<boolean> {
        try {
            await this.get(id);
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Gets all items of a type based on the idPrefix
     * @param idPrefix the type for which all objects are queried
     */
    public getAll(idPrefix?: string): Promise<IEntity[]> {
        // Add the database delimiter if necessary
        if (idPrefix && !idPrefix.endsWith(this.entitySettings.databaseDelimiter)) {
            idPrefix += this.entitySettings.databaseDelimiter;
        }

        const matchingEntities = this.entities.filter(
            (entity) => !idPrefix || entity._id.startsWith(idPrefix),
        );

        return Promise.resolve(matchingEntities);
    }

    /**
     * Gets all descendants of an entity including itself. It will always return itself as first in the array.
     * @param id the id of the entity we want the descendants for.
     */
    public async getDescendants(id: Id): Promise<IEntity[]> {
        const matchingEntity = await this.get(id);
        const descendants = this.entities.filter(
            (entity) => entity._id !== id && entity.path.includes(id),
        );

        return Promise.resolve([matchingEntity, ...descendants]);
    }

    /**
     * Adds an item.
     * @param entity the entity to add.
     */
    public add(entity: IEntity): Promise<IEntity> {
        const matchingEntity = this.getEntity(entity._id);

        if (matchingEntity) {
            throw Error(`Entity with id ${entity._id} already exist`);
        }

        entity._rev = '1';
        this.entities.push(entity);
        this.emitter.emit('change', entity);
        return Promise.resolve(entity);
    }

    /**
     * Updates an item.
     * @param entity the updated entity.
     * @throws if the item does not exist.
     */
    public update(entity: IEntity): Promise<IEntity> {
        const index = this.getEntityIndex(entity._id);

        if (index === -1) {
            throw Error(`Entity with id ${entity._id} was not found`);
        }

        // If this.entities[index]._rev is not a number e.g. '4-7fc60066a2cb7a37c9579536ae9ebdc8'
        // then new _rev will become 'NaN'.
        entity._rev = String(Number(this.entities[index]._rev) + 1);

        this.entities[index] = entity;
        if (entity._rev !== 'NaN') {
            // Only emit change if the _rev is valid.
            this.emitter.emit('change', entity);
        }
        return Promise.resolve(entity);
    }

    /**
     * Deletes an item. Any references to the item will not be updated.
     * @param doc the entity to delete.
     */
    public delete(doc: IIdRev): Promise<IIdRev> {
        const entityToDelete = this.entities.find((entityItem) => entityItem._id === doc._id);
        if (entityToDelete) {
            this.entities = this.entities.filter((entity) => entity._id !== doc._id);
            this.emitter.emit('delete', entityToDelete);
        }
        return Promise.resolve(doc);
    }

    /**
     * Adds items in bulk.
     */
    public async bulkAdd(newEntities: IEntity[]): Promise<IEntity[]> {
        for (const entity of newEntities) {
            await this.add(entity);
        }

        return newEntities;
    }

    /**
     * Updates items in bulk.
     */
    public async bulkUpdate(updatedEntities: IEntity[]): Promise<IEntity[]> {
        for (const entity of updatedEntities) {
            await this.update(entity);
        }

        return updatedEntities;
    }

    /**
     * Deletes items in bulk.
     */
    public async bulkDelete(docs: IIdRev[]): Promise<IIdRev[]> {
        for (const doc of docs) {
            await this.delete(doc);
        }

        return docs;
    }

    private getEntity(id: Id): IEntity | undefined {
        const index = this.getEntityIndex(id);

        if (index === -1) {
            return undefined;
        }

        return this.entities[index];
    }

    private getEntityIndex(id: Id): number {
        return this.entities.findIndex((existingEntity) => existingEntity._id === id);
    }
}
