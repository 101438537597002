import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';

const COLOR_MAP: Record<string, Colors> = {
    [ColorsEnum.devicePalette1]: 'devicePalette1',
    [ColorsEnum.devicePalette2]: 'devicePalette2',
    [ColorsEnum.devicePalette3]: 'devicePalette3',
    [ColorsEnum.devicePalette4]: 'devicePalette4',
    [ColorsEnum.devicePalette5]: 'devicePalette5',
    [ColorsEnum.devicePalette6]: 'devicePalette6',
    [ColorsEnum.devicePalette7]: 'devicePalette7',
    [ColorsEnum.devicePalette8]: 'devicePalette8',
    [ColorsEnum.devicePalette9]: 'devicePalette9',
};

export const getDevicePaletteColor = (color?: string): Colors | undefined => {
    if (color && color in COLOR_MAP) {
        return COLOR_MAP[color];
    }
};
