import { injectable } from 'inversify';
import { AuthenticationService } from '../../services/Authentication.service';
import type { IUserDBError } from './UserDBError';
import { UserDBError } from './UserDBError';
import type { IUserDBContext } from './UserDataStorage.communicator';
import { SharedProjectRepository } from '../../services/sharedProjects/SharedProject.repository';
import {
    AUTHORIZATION_HEADER,
    X_ORGANIZATION_ID_HEADER,
    X_RESOURCE_GROUP_ID_HEADER,
} from './constants';

const API_URL = `/userdata-storage-shared-projects`;
const SYNC_URL = `${window.location.origin}/userdata-storage-shared-projects/sync/`;

// Load access token from session storage and return 401 to trigger refresh if it's expired or missing.
interface IAuthTokens {
    accessToken: string;
}

@injectable()
export class SharedUserDataCommunicator {
    constructor(
        private authService: AuthenticationService,
        private sharedProjectRepository: SharedProjectRepository,
    ) {}

    public async tryToGetUserDb(): Promise<IUserDBContext | null> {
        const authenticated = await this.authService.isAuthenticated();

        if (!authenticated) {
            return null;
        }

        return this.getOrCreateUserDb().catch((err) => {
            console.error('Could not establish user data db', err);
            return null;
        });
    }

    /**
     * Return the access token from session storage or an empty string if it is missing or invalid.
     * The is 'auth' same as in src/app/modules/common/utils/auth/authTokens.ts
     * @returns The access token from session storage or an empty string if it is missing or invalid.
     */
    public getAccessToken(): string {
        const auth = sessionStorage.getItem('auth');
        if (auth) {
            const tokens: IAuthTokens = JSON.parse(auth);
            return tokens.accessToken;
        }
        return '';
    }

    /**
     * Get the headers for the shared projects user data db request.
     * @returns The headers for the shared projects user data db request.
     * @throws {Error} If the resource group id or organization id is missing.
     * @throws {Error} If the access token is missing.
     */
    public async getHeaders(): Promise<Headers> {
        const accessToken = this.getAccessToken();
        const organizationId = await this.sharedProjectRepository.getSelectedOrganizationId();
        const resourceGroupId = await this.sharedProjectRepository.getSelectedResourceGroupId();

        if (!resourceGroupId || !organizationId) {
            return Promise.reject(new Error('Resource group id or organization id is missing.'));
        }

        const headers = new Headers();
        headers.append(AUTHORIZATION_HEADER, `Bearer ${accessToken}`);
        headers.append(X_ORGANIZATION_ID_HEADER, organizationId);
        headers.append(X_RESOURCE_GROUP_ID_HEADER, resourceGroupId);
        return headers;
    }

    /**
     * Get user db
     * @returns The user data db context.
     * @throws {UserDBError} If the user data db could not be retrieved.
     * @throws {Error} If the response status is not 200.
     */
    private async getUserDb(): Promise<IUserDBContext> {
        const headers = await this.getHeaders();
        const response = await fetch(API_URL, {
            credentials: 'include',
            headers,
        });

        const body = await response.json();

        if (response.status !== 200) {
            return Promise.reject(new UserDBError(response.status, body as IUserDBError));
        } else {
            return {
                storageUri: `${SYNC_URL}${body.storageUri}`,
                application: body.application,
            };
        }
    }

    /**
     * Create a new user data db.
     * @returns The user data db context.
     * @throws {UserDBError} If the user data db could not be created.
     * @throws {Error} If the response status is not 201.
     */
    private async createUserDb(): Promise<IUserDBContext> {
        const headers = await this.getHeaders();
        const response = await fetch(API_URL, {
            method: 'POST',
            credentials: 'include',
            headers,
        });

        const body = await response.json();

        if (response.status !== 201) {
            return Promise.reject(new UserDBError(response.status, body as IUserDBError));
        } else {
            return {
                storageUri: `${SYNC_URL}${body.storageUri}`,
                application: body.application,
            };
        }
    }

    /**
     * Get or create the user data db.
     * @returns The user data db context.
     * @throws {UserDBError} If the user data db could not be retrieved or created.
     * @throws {Error} If the response status is not 200 or 201.
     */
    private getOrCreateUserDb(): Promise<IUserDBContext> {
        return this.getUserDb().catch((error) => {
            if (error.statusCode === 404) {
                return this.createUserDb();
            } else {
                throw error;
            }
        });
    }
}
