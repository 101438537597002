import { getCurrentView } from './getCurrentView';
import type { ProjectStoreState } from 'app/store';
import { createSelector } from 'reselect';

export const getProjectStoreState = createSelector(
    [getCurrentView],
    (currentView): ProjectStoreState | undefined => {
        if (currentView === 'localprojects') {
            return 'localProjects';
        } else if (currentView === 'userprojects') {
            return 'userProjects';
        } else if (currentView === 'sharedprojects') {
            return 'sharedProjects';
        }
        return undefined;
    },
);
