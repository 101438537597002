import * as React from 'react';
import { t } from 'app/translate';
import { Login } from './Login.component';
import { UndoRedo } from './UndoRedo';
import {
    Box,
    DropDownMenu,
    DropDownMenuButton,
    Modal,
    Text,
    ProgressBar,
    Linkable,
    IconButton,
    Opacity,
} from 'app/components';
import { AppConstants } from 'app/AppConstants';
import { AppTitle } from './AppTitle.container';
import { About } from '../about/About.component';
import { FeedbackModal } from './FeedbackModal.component';
import { LanguageSelector } from './LanguageSelector.component';
import { eventTracking } from 'app/core/tracking';
import { ColorsEnum, colorToRgba, SpacingsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { NeedHelpMenu } from './NeedHelpMenu';
import type { IPartnerHeaderStyle } from 'app/core/persistence';
import { isErrorType } from 'app/core/persistence';
import { VersionUpdateNotifier } from './VersionUpdateNotifier';
import { saveAs } from 'file-saver';
import type { BannerVisibility } from '../notificationBanner/models';
import { NotificationBanner } from '../notificationBanner/components';

const logo = require('src/assets/images/axislogo@2x.png');
const TERMS_OF_USE_PATH = '/terms-of-use/';

export interface IAppHeaderComponentProps {
    basePath: string;
    isSignedIn?: boolean;
    partnerLogo?: string;
    showAxisLogo: boolean;
    progressPercent: number;
    partnerHeaderStyle?: IPartnerHeaderStyle;
    notificationBannerVisibility: BannerVisibility;
    onShowBanner: () => void;
}

export const AppHeaderComponent: React.FC<IAppHeaderComponentProps> = (props) => {
    const [showAboutModal, setShowAboutModal] = React.useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);

    const headerStyle = css`
        display: flex;
        width: 100%;
        align-content: space-between;
        box-shadow: 0 1px 3px 1px rgba(70, 70, 70, 0.12);
        color: ${props.partnerHeaderStyle?.foregroundColor};
        min-height: 60px;
        background-color: ${props.partnerHeaderStyle?.backgroundColor || ColorsEnum.white};
        z-index: ${AppConstants.headerDepth};

        @media print {
            display: none !important;
        }
    `;

    const userSettingsStyle = css`
        background-color: ${props.partnerHeaderStyle?.backgroundColor || ColorsEnum.yellow};
        display: flex;
        padding-inline-end: ${SpacingsEnum.base};
        align-items: center;

        &:before {
            content: ' ';
            width: 60px;
            height: 100%;
            background: linear-gradient(
                to bottom right,
                ${props.partnerHeaderStyle?.backgroundColor || 'white 49%'},
                ${props.partnerHeaderStyle?.backgroundColor
                        ? colorToRgba(props.partnerHeaderStyle?.backgroundColor, 0)
                        : 'rgba(255, 255, 255, 0)'}
                    50%
            );
            [dir='rtl'] & {
                background: linear-gradient(
                    to bottom left,
                    ${props.partnerHeaderStyle?.backgroundColor || 'white 49%'},
                    ${props.partnerHeaderStyle?.backgroundColor
                            ? colorToRgba(props.partnerHeaderStyle?.backgroundColor, 0)
                            : 'rgba(255, 255, 255, 0)'}
                        50%
                );
            }
        }
    `;

    const showAbout = () => {
        eventTracking.logUserEvent('Application', 'Show About');
        setShowAboutModal(true);
    };

    const closeAbout = () => setShowAboutModal(false);

    const showFeedback = () => {
        eventTracking.logUserEvent('Application', 'Show Feedback');
        setShowFeedbackModal(true);
    };

    const closeFeedback = () => setShowFeedbackModal(false);

    const saveLearnMore = async () => {
        eventTracking.logUserEvent('Application', 'Show Learn More');
        const fetchString = 'assets/pdf/AXIS-Site-Designer-Product-Overview.pdf';
        try {
            await fetch(fetchString, {
                method: 'GET',
            })
                .then((response) => {
                    return response.blob();
                })
                .then((file) => {
                    saveAs(file, `AXIS-Site-Designer-Product-Overview.pdf`);
                });
        } catch (error) {
            if (isErrorType(error)) {
                throw error;
            }
        }
    };

    const showTermsOfUse = () => {
        eventTracking.logUserEvent('Application', 'Show Terms Of Use');
    };

    return (
        <>
            {showAboutModal && (
                <Modal title={'AXIS Site Designer'} onClose={closeAbout}>
                    <About />
                </Modal>
            )}

            {showFeedbackModal && <FeedbackModal sendButtonDisabled onClose={closeFeedback} />}
            <Box direction="column" alignItems="center" width="100%">
                <header className={headerStyle}>
                    <Box
                        alignItems="center"
                        justifyContent="between"
                        flex="shrinkAndGrow"
                        paddingX="base"
                        minWidth="0"
                    >
                        <AppTitle />

                        <Opacity
                            alpha={props.notificationBannerVisibility === 'hidden' ? 0.8 : 0}
                            animation="opacity 0.6s ease-in"
                        >
                            <Box paddingRight="panel">
                                <IconButton
                                    size="ml"
                                    icon="info"
                                    color="red"
                                    onClick={props.onShowBanner}
                                />
                            </Box>
                        </Opacity>

                        {props.partnerLogo && (
                            <Box spacing="base" flex="grow" testId="partner_page">
                                <Box alignItems="center">
                                    <Text
                                        style="semibold"
                                        colorOverride={props.partnerHeaderStyle?.foregroundColor}
                                    >
                                        {t.partnerConfigurationPartnerSiteFor}
                                    </Text>
                                </Box>
                                <img src={props.partnerLogo} height={AppConstants.logoHeight} />
                            </Box>
                        )}
                        {props.showAxisLogo && <img height={AppConstants.logoHeight} src={logo} />}
                    </Box>
                    <div className={userSettingsStyle}>
                        <Box spacing="base" alignItems="center">
                            <UndoRedo />
                            <NeedHelpMenu />
                            <Login />
                            <LanguageSelector />
                            <VersionUpdateNotifier />
                            <DropDownMenu
                                testId="select_ddm_hdr_more"
                                testIdChild="ddm_hdr_more"
                                icon="more_vert"
                                iconProps={{
                                    opaque: true,
                                    color: 'yellowText',
                                    colorOverride: props.partnerHeaderStyle?.foregroundColor,
                                }}
                            >
                                <DropDownMenuButton
                                    testId="btn_about_in_ddm_hdr_more"
                                    label={t.about}
                                    icon="info"
                                    iconProps={{
                                        color: 'black',
                                    }}
                                    onClick={showAbout}
                                />
                                <DropDownMenuButton
                                    label={t.feedback}
                                    icon="feedback"
                                    iconProps={{
                                        color: 'black',
                                    }}
                                    onClick={showFeedback}
                                />
                                <DropDownMenuButton
                                    label={t.learnMore}
                                    icon="description"
                                    iconProps={{
                                        color: 'black',
                                    }}
                                    onClick={saveLearnMore}
                                />
                                <Linkable link={TERMS_OF_USE_PATH}>
                                    <DropDownMenuButton
                                        testId="terms_of_use_in_ddm"
                                        label={t.termsOfUse}
                                        icon="grading"
                                        iconProps={{
                                            color: 'black',
                                        }}
                                        onClick={showTermsOfUse}
                                    />
                                </Linkable>
                            </DropDownMenu>
                        </Box>
                    </div>

                    <ProgressBar percent={props.progressPercent} />
                </header>
                <NotificationBanner />
            </Box>
        </>
    );
};
AppHeaderComponent.displayName = 'AppHeaderComponent';
