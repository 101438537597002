import { defaultConnectivityDeviceFilter, Id } from 'app/core/persistence';
import type { ImportedConnectivityDevice } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class ConnectivityDeviceImporterService {
    public import(projectId: Id, items: ImportedConnectivityDevice[]) {
        const connectivityDevices = items.map((item) => ({
            ...mapToItemEntity(projectId, item, item.piaId),
            properties: {
                connectivityDevice: {
                    filter: defaultConnectivityDeviceFilter,
                },
            },
        }));

        const accessoryEntities = getImportedAccessories(projectId, items);
        return [...connectivityDevices, ...accessoryEntities];
    }
}
