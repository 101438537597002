export const Icons = [
    '3d_rotation',
    'accessibility',
    'account_circle',
    'add',
    'add_box',
    'add_circle',
    'add_circle_outline',
    'add_place',
    'analog_camera',
    'angle',
    'apps',
    'archive',
    'arrow_back',
    'arrow_down',
    'arrow_down_special',
    'arrow_downward',
    'arrow_forward',
    'arrow_left',
    'arrow_right',
    'arrow_up',
    'arrow_upward',
    'box_drawings_light_up_and_right',
    'box_drawings_light_vertical_and_right',
    'cancel',
    'category',
    'check',
    'check_circle',
    'circle_tool',
    'close',
    'close_circle_outline',
    'cloud',
    'cloud_disabled',
    'cloud_disabled_small',
    'cloud_done',
    'cloud_done_small',
    'cloud_off',
    'cloud_off_small',
    'cloud_queue',
    'cloud_queue_small',
    'cloud_warning_small',
    'color_fill',
    'color_palette',
    'color_text',
    'company',
    'compare',
    'compress',
    'contact_support',
    'content_copy',
    'dashboard',
    'delete',
    'delete_forever',
    'delete_small',
    'description',
    'device',
    'directions_car',
    'directions_walk',
    'dome',
    'dome_wall',
    'door_controller',
    'door_front',
    'dori_off',
    'dori_on',
    'download_doc',
    'download_file',
    'drag_marker',
    'eco',
    'edit',
    'edit_notes',
    'encoder',
    'error',
    'exit_to_app',
    'export',
    'extension',
    'feedback',
    'file_download',
    'file_upload',
    'filter_list',
    'fit',
    'flag',
    'floorplan',
    'floorplan_add',
    'floorplan_geo',
    'foggy',
    'foggy_heavy',
    'grading',
    'handshake',
    'height',
    'help',
    'home',
    'import_contacts',
    'info',
    'joystick',
    'keyboard',
    'keyboard_arrow_down',
    'keyboard_arrow_left',
    'keyboard_arrow_right',
    'keyboard_arrow_up',
    'label',
    'label_off',
    'language',
    'layers',
    'lens',
    'line_tool',
    'list',
    'list_alt',
    'live_bw',
    'live_bw_xs',
    'lock_black',
    'lock_open',
    'main_unit',
    'make_unique',
    'map',
    'maximise',
    'minimise',
    'more_horiz',
    'more_vert',
    'move_location',
    'movie_filter',
    'nightlight',
    'open',
    'open_in_full',
    'open_in_new',
    'open_with',
    'pac',
    'pac_extension',
    'pagingconsole',
    'people',
    'person',
    'person_outline',
    'place',
    'play',
    'play_outline',
    'print',
    'profiles',
    'question_answer',
    'radar',
    'recent',
    'recording',
    'recording_bw',
    'recording_bw_xs',
    'rectangle_tool',
    'redo',
    'remove',
    'remove_circle',
    'remove_circle_outline',
    'repeat',
    'reset',
    'rightwards_arrow',
    'ruler',
    'ruler_off',
    'satellite',
    'satellite_off',
    'schedule',
    'screen_rotation',
    'search',
    'sensor_unit',
    'settings',
    'share',
    'shared_folder_outline',
    'smartphone',
    'speaker',
    'speaker_ceiling',
    'speaker_wall',
    'split',
    'star',
    'star_border',
    'storage',
    'storage_xs',
    'sunny',
    'sunny_50',
    'sunny_75',
    'text',
    'text_off',
    'total_bw',
    'total_bw_xs',
    'unarchive',
    'undo',
    'unfold_more',
    'updates',
    'upload_file',
    'vehicle_person',
    'videocam',
    'visibility',
    'visibility_off',
    'warning',
    'warning_small',
] as const;
export type Icons = (typeof Icons)[number];
