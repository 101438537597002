import type { IEntity, IFloorPlanEntity } from '../../userDataPersistence';

/** Checks if some entity is a map location or a non default floor plan. */
export const containsFloorPlanOrMapLocation = (entities: IEntity[]): boolean => {
    return entities.some(
        (entity) =>
            entity.type === 'mapLocation' ||
            (entity.type === 'floorPlan' && !(entity as IFloorPlanEntity).isDefault === true),
    );
};
