import * as React from 'react';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import { Box } from 'app/components';
import { SharedProjectsContentHeader, SharedProjectsWelcome } from './components';
import { OrganizationsActionService, SharedProjectsActionService } from './services';
import { ProjectsList } from '../components';
import {
    getCurrentSelectedResourceGroup,
    getHasOrganizations,
    getCurrentDatabaseName,
} from './selectors';
import { getUserSignedIn } from 'app/modules/common';
import { getError, getLoaded } from '../selectors';
import type { IStoreState } from 'app/store';
import { ApplicationActionService } from 'app/modules/application';
import { useMount } from 'app/hooks';
import { getIsSharedProjectLocalDbReady } from './selectors/getIsSharedProjectLocalDbReady';

interface ISharedProjectsProps {}

export const SharedProjects: React.FunctionComponent<ISharedProjectsProps> = () => {
    const sharedProjectsActionService = useService(SharedProjectsActionService);
    const hasOrganizations = useSelector(getHasOrganizations);
    const organizationsActionService = useService(OrganizationsActionService);
    const isSignedIn = useSelector(getUserSignedIn);
    const loaded = useSelector((state: IStoreState) => getLoaded(state, 'sharedProjects'));
    const error = useSelector((state: IStoreState) => getError(state, 'sharedProjects'));
    const currentSelectedResourceGroup = useSelector(getCurrentSelectedResourceGroup);
    const currentDatabaseName = useSelector(getCurrentDatabaseName);
    const applicationActionService = useService(ApplicationActionService);
    const isLocalDbReady = useSelector(getIsSharedProjectLocalDbReady);

    useMount(() => {
        applicationActionService.setUserPreferences({
            selectedView: 'sharedprojects',
        });
        sharedProjectsActionService.getSelectedResourceGroupId();
    });

    React.useEffect(() => {
        if (isLocalDbReady) {
            sharedProjectsActionService.syncUnarchived();
        }
    }, [isLocalDbReady, sharedProjectsActionService]);

    React.useEffect(() => {
        if (currentSelectedResourceGroup && currentDatabaseName !== currentSelectedResourceGroup) {
            // currentSelectedResourceGroup has changed, so we need to initialize the storage
            sharedProjectsActionService.initializeStorage(currentSelectedResourceGroup);
            sharedProjectsActionService.changeProjectDbOrigin(currentSelectedResourceGroup);
            sharedProjectsActionService.clearSelection();
        }
    }, [sharedProjectsActionService, currentSelectedResourceGroup, currentDatabaseName]);

    React.useEffect(() => {
        if (hasOrganizations) {
            organizationsActionService.setInitialSharedProjectInfo();
        }
    }, [hasOrganizations, organizationsActionService]);

    React.useEffect(() => {
        if (isSignedIn && !hasOrganizations) {
            organizationsActionService.fetchOrganizations();
        }
    }, [hasOrganizations, isSignedIn, organizationsActionService]);

    if (!hasOrganizations) {
        return <SharedProjectsWelcome />;
    }

    return (
        <Box direction="column" spacing="base">
            <SharedProjectsContentHeader />
            <Box paddingX="base">
                <ProjectsList
                    actionService={sharedProjectsActionService}
                    state="sharedProjects"
                    loaded={loaded}
                    error={error}
                />
            </Box>
        </Box>
    );
};
