import * as React from 'react';
import { connect } from 'react-redux';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import type { IPiaMainUnit, IPiaEncoder, IPiaPac } from 'app/core/pia';
import { Stack, Text, Button, Box } from 'app/components';
import type {
    DistanceUnit,
    IPersistence,
    IProjectEntity,
    Id,
    ICameraPropertiesFilterEntity,
    IInstallationPointModel,
} from 'app/core/persistence';
import {
    ItemService,
    getDeviceTypeLogging,
    InstallationPointService,
    isExpandableIoRelay,
} from 'app/core/persistence';

import type { IMapsDevice } from '../../../../models';
import { getSelectedDevice, getDerotatedSelectedInstallationPoint } from '../../../../selectors';
import { DeviceDetails } from './DeviceDetails.container';
import { CameraContextItem } from './CameraContextItem.container';
import { MainUnitContextItem } from './MainUnitContextItem.container';
import { SpeakerContextItem } from './SpeakerContextItem.container';
import { DeviceInstallationNotes } from '../device';
import { EncoderContextItem } from './EncoderContextItem.container';
import { DoorControllerContextItem } from './DoorControllerContextItem.container';
import { ModalService } from 'app/modal';
import {
    getCurrentProject,
    getCurrentProjectDefaultCameraFilter,
    getCurrentProjectDisplayUnit,
    getCurrentProjectLocked,
} from 'app/modules/common';
import { eventTracking } from 'app/core/tracking';
import { MapsActionService } from '../../../../services/MapsAction.service';
import { EditableNameContextItem } from './EditableNameContextItem';
import { IoRelayContextItem } from './IoRelayContextItem.container';

interface IInstallationPointContextMenuContainerStateProps {
    installationPoint?: IInstallationPointModel;
    displayUnit: DistanceUnit.Feet | DistanceUnit.Meter;
    sensorId: number;
    project: IPersistence<IProjectEntity> | null;
    device?: IMapsDevice;
    projectIsLocked: boolean;
    defaultFilterValues: ICameraPropertiesFilterEntity;
}

type IInstallationPointContextMenuContainerProps = IInstallationPointContextMenuContainerStateProps;

type IInstallationPointContextMenuContainerState = {
    installationHeight: number;
    targetHeight: number;
    targetDistance: number;
};

const mapStateToProps = (
    storeState: IStoreState,
): IInstallationPointContextMenuContainerStateProps => {
    const installationPoint = getDerotatedSelectedInstallationPoint(storeState);
    const device = getSelectedDevice(storeState);
    const project = getCurrentProject(storeState);
    const displayUnit = getCurrentProjectDisplayUnit(storeState);
    const projectIsLocked = getCurrentProjectLocked(storeState);
    const defaultFilterValues = getCurrentProjectDefaultCameraFilter(storeState);

    return {
        installationPoint,
        displayUnit,
        sensorId: 1,
        project,
        device,
        projectIsLocked,
        defaultFilterValues,
    };
};

class InstallationPointContextMenuContainer extends React.PureComponent<
    IInstallationPointContextMenuContainerProps,
    IInstallationPointContextMenuContainerState
> {
    private mapsActionService: MapsActionService;
    private installationPointService: InstallationPointService;
    private modalService: ModalService;
    private itemService: ItemService;

    constructor(props: IInstallationPointContextMenuContainerProps) {
        super(props);
        this.mapsActionService = ServiceLocator.get(MapsActionService);
        this.installationPointService = ServiceLocator.get(InstallationPointService);
        this.modalService = ServiceLocator.get(ModalService);
        this.itemService = ServiceLocator.get(ItemService);

        const { defaultFilterValues } = this.props;

        const currentSensor = props.installationPoint
            ? props.installationPoint.sensors.find(
                  (sensor) => sensor.sensorId === this.props.sensorId,
              )
            : undefined;

        this.state = {
            installationHeight:
                props.installationPoint?.height ?? defaultFilterValues.installationHeight,
            targetHeight: currentSensor?.target.height ?? defaultFilterValues.targetHeight,
            targetDistance: currentSensor?.target.distance ?? defaultFilterValues.distanceToTarget,
        };
    }

    public componentDidMount() {
        if (!this.props.installationPoint && !this.props.device) {
            this.mapsActionService.deselectItem();
        }
    }

    public componentDidUpdate(prevProps: IInstallationPointContextMenuContainerProps) {
        if (!this.props.installationPoint && !this.props.device) {
            this.mapsActionService.deselectItem();
        }

        if (!this.props.installationPoint || !prevProps.installationPoint) {
            return;
        }

        if (prevProps.installationPoint.height !== this.props.installationPoint.height) {
            this.setState({
                installationHeight: this.props.installationPoint.height,
            });
        }

        const prevSensor = prevProps.installationPoint.sensors.find(
            (sensor) => sensor.sensorId === prevProps.sensorId,
        );

        const currentSensor = this.props.installationPoint.sensors.find(
            (sensor) => sensor.sensorId === this.props.sensorId,
        );

        if (!currentSensor || !prevSensor) {
            return;
        }

        if (
            prevSensor.target.distance === currentSensor.target.distance &&
            prevSensor.target.height === currentSensor.target.height
        ) {
            return;
        }

        this.setState({
            targetHeight: currentSensor.target.height,
            targetDistance: currentSensor.target.distance,
        });
    }

    public render() {
        const { installationPoint, device } = this.props;
        if (!installationPoint && !device) {
            return null;
        }

        const selectedDevice = this.getSelectedDeviceString(installationPoint);
        const parentDeviceProperties = installationPoint?.parentDevice.properties;

        return (
            <>
                <Box testId="installation_point_context_menu_container" flex="dontShrink">
                    <Stack vertical spacing="panel">
                        <Stack vertical alignItems="center">
                            <DeviceDetails />
                        </Stack>
                        {parentDeviceProperties && (
                            <Stack vertical alignItems="center">
                                <Text style="semibold" color="grey5">
                                    {selectedDevice}
                                </Text>
                                {(parentDeviceProperties.camera ||
                                    parentDeviceProperties.sensorUnit ||
                                    parentDeviceProperties.analogCamera) &&
                                    this.renderCamera(installationPoint)}
                                {parentDeviceProperties.mainUnit &&
                                    this.renderMainUnit(
                                        installationPoint.parentDevice._id,
                                        (installationPoint.parentPiaDevice as IPiaMainUnit | null)
                                            ?.properties.channels ?? 0,
                                        installationPoint.name,
                                    )}
                                {parentDeviceProperties.pac &&
                                    isExpandableIoRelay(device?.piaProduct) &&
                                    this.renderExpandableIORelay(
                                        installationPoint.parentDevice._id,
                                        (installationPoint.parentPiaDevice as IPiaPac | null)
                                            ?.properties.nbrSupportedExpansionModules ?? 0,
                                        installationPoint.name,
                                    )}
                                {parentDeviceProperties.encoder &&
                                    this.renderEncoder(
                                        installationPoint.parentDevice._id,
                                        (installationPoint.parentPiaDevice as IPiaEncoder | null)
                                            ?.properties.channels ?? 0,
                                        installationPoint.name,
                                    )}
                                {parentDeviceProperties.doorController &&
                                    this.renderDoorController(
                                        installationPoint.parentDevice._id,
                                        installationPoint.name,
                                    )}
                                {parentDeviceProperties.speaker &&
                                    this.renderSpeaker(installationPoint)}
                                {(parentDeviceProperties.radarDetector ||
                                    parentDeviceProperties.peopleCounter ||
                                    parentDeviceProperties.alerter ||
                                    parentDeviceProperties.decoder ||
                                    parentDeviceProperties.systemController ||
                                    parentDeviceProperties.dockingStation ||
                                    parentDeviceProperties.connectivityDevice ||
                                    (parentDeviceProperties.pac &&
                                        //* Door stations/intercoms count as cameras which have their own name field
                                        //* io relays with possible expansion modules have their own context menu with name
                                        device?.deviceType !== 'doorstation' &&
                                        !isExpandableIoRelay(device?.piaProduct))) &&
                                    this.renderEditableName(installationPoint.name ?? '')}
                            </Stack>
                        )}
                    </Stack>
                </Box>
                <Stack vertical spacing="half" flex="none">
                    <DeviceInstallationNotes
                        updateNotes={this.updateNotes}
                        notes={
                            this.props.installationPoint
                                ? this.props.installationPoint?.parentDevice.notes || ''
                                : this.props.device?.notes || ''
                        }
                    />
                    <Button
                        testId="device_delete_btn"
                        onClick={this.removeDevice}
                        disabled={
                            this.props.installationPoint?.parentDevice.locked ||
                            this.props.projectIsLocked
                        }
                    >
                        {t.delete}
                    </Button>
                    <Button
                        testId="installation_point_remove_btn"
                        primary
                        disabled={
                            (this.props.installationPoint &&
                                this.props.installationPoint.parentId !== undefined) ||
                            !this.props.installationPoint
                        }
                        onClick={this.removeInstallationPoint}
                    >
                        {t.removeFromMap}
                    </Button>
                </Stack>
            </>
        );
    }

    private renderCamera = (installationPoint: IInstallationPointModel) => (
        <CameraContextItem
            installationPoint={installationPoint}
            installationHeight={this.state.installationHeight}
            onInstallationHeightChange={(value) => this.onInstallationHeightChange(value)}
            onNameChange={(name) => this.onNameChange(name)}
        />
    );

    private renderMainUnit = (deviceId: Id, maxChannels: number, ipName?: string) => {
        return (
            <MainUnitContextItem
                itemId={deviceId}
                maxChannels={maxChannels}
                ipName={ipName}
                onNameChange={(name) => this.onNameChange(name)}
            />
        );
    };

    private renderExpandableIORelay = (deviceId: Id, maxChannels: number, ipName?: string) => {
        return (
            <IoRelayContextItem
                itemId={deviceId}
                maxConnections={maxChannels}
                ipName={ipName}
                onNameChange={(name) => this.onNameChange(name)}
            />
        );
    };

    private renderEncoder = (deviceId: Id, maxChannels: number, ipName?: string) => {
        return (
            <EncoderContextItem
                itemId={deviceId}
                maxChannels={maxChannels}
                ipName={ipName}
                onNameChange={(name) => this.onNameChange(name)}
            />
        );
    };

    private renderEditableName = (ipName: string) => {
        return (
            <EditableNameContextItem
                ipName={ipName}
                onNameChange={(name) => this.onNameChange(name)}
            />
        );
    };

    private renderDoorController = (deviceId: Id, ipName?: string) => {
        return (
            <DoorControllerContextItem
                itemId={deviceId}
                name={ipName}
                onNameChange={(name) => this.onNameChange(name)}
            />
        );
    };

    private renderSpeaker = (installationPoint: IInstallationPointModel) => (
        <SpeakerContextItem
            installationPoint={installationPoint}
            installationHeight={this.state.installationHeight}
            onInstallationHeightChange={(value) => this.onInstallationHeightChange(value)}
            ipName={installationPoint.name}
            onNameChange={(name) => this.onNameChange(name)}
        />
    );

    private removeDevice = async () => {
        if (!this.props.installationPoint?.parentDevice && !this.props.device) {
            return;
        }

        const name = this.props.installationPoint
            ? this.props.installationPoint?.parentDevice.name
            : this.props.device?.name;
        const itemIdToDelete = this.props.installationPoint
            ? this.props.installationPoint?.parentDevice._id
            : this.props.device?.id;

        if (!name || !itemIdToDelete) {
            return;
        }

        const remove = await this.modalService.createConfirmDialog({
            header: t.deleteHeader(name),
            body: '',
            confirmButtonText: t.delete,
            cancelButtonText: t.cancel,
        })();

        if (remove) {
            if (this.props.installationPoint) {
                eventTracking.logUserEvent(
                    'Maps',
                    'Remove device',
                    getDeviceTypeLogging(this.props.installationPoint.parentDevice),
                );
            } else if (this.props.device) {
                eventTracking.logUserEvent('Maps', 'Remove device', this.props.device.deviceType);
            }

            this.itemService.deleteItem(itemIdToDelete);
        }
    };

    private removeInstallationPoint = () => {
        if (this.props.installationPoint) {
            eventTracking.logUserEvent(
                'Maps',
                'Remove installation point',
                getDeviceTypeLogging(this.props.installationPoint.parentDevice),
            );
            this.mapsActionService.removeInstallationPoint(this.props.installationPoint);
        }
    };

    private getSelectedDeviceString = (installationPoint?: IInstallationPointModel): string => {
        if (!installationPoint) {
            return t.deviceNotOnMap;
        }
        if (installationPoint.parentDevice.properties.camera) {
            const category = installationPoint.parentPiaDevice?.category.toString();
            if (category) {
                if (
                    category === 'doorstations' ||
                    category === 'doorcontrollers' ||
                    category === 'iorelays' ||
                    category === 'readers'
                )
                    return t.selectedDoorstation;
            }
            return t.selectedCamera;
        } else if (installationPoint.parentDevice.properties.mainUnit) {
            return t.selectedMainUnit;
        } else if (installationPoint.parentDevice.properties.sensorUnit) {
            return t.selectedSensorUnit;
        } else if (installationPoint.parentDevice.properties.encoder) {
            return t.selectedEncoder;
        } else if (installationPoint.parentDevice.properties.analogCamera) {
            return t.selectedAnalogCamera;
        } else if (installationPoint.parentDevice.properties.speaker) {
            return t.selectedSpeaker;
        } else if (installationPoint.parentDevice.properties.radarDetector) {
            return t.selectedRadarDetector;
        } else if (installationPoint.parentDevice.properties.doorController) {
            return t.selectedDoorController;
        } else if (installationPoint.parentDevice.properties.peopleCounter) {
            return t.selectedPeopleCounter;
        } else if (installationPoint.parentDevice.properties.door) {
            return '';
        } else if (installationPoint.parentDevice.properties.alerter) {
            return t.selectedAudioVisualAlerter;
        } else if (installationPoint.parentDevice.properties.decoder) {
            return t.selectedDecoder;
        } else if (installationPoint.parentDevice.properties.dockingStation) {
            return t.selectedDockingStation;
        } else if (installationPoint.parentDevice.properties.systemController) {
            return t.selectedSystemController;
        } else if (installationPoint.parentDevice.properties.connectivityDevice) {
            return t.selectedConnectivityDevice;
        }

        return t.selectedMapItem;
    };

    private updateNotes = (notes: string) => {
        if (!this.props.installationPoint) return;
        const idToUpdate = this.props.installationPoint.parentDevice._id;
        idToUpdate && this.itemService.updateItem(idToUpdate, { notes });
    };

    private onNameChange = (newName: string) => {
        if (!this.props.installationPoint) return;
        this.installationPointService.updateInstallationPointDebounced(
            this.props.installationPoint._id,
            {
                ...this.props.installationPoint,
                name: newName,
            },
        );
    };

    private onInstallationHeightChange = (value: number) => {
        this.setState({
            installationHeight: value,
        });

        if (!this.props.installationPoint) {
            return;
        }

        this.mapsActionService.setDerotatedDraftInstallationPoint({
            ...this.props.installationPoint,
            height: value,
        });

        this.mapsActionService.debouncedUpdateInstallationPoint({
            ...this.props.installationPoint,
            height: value,
        });
    };
}

export const InstallationPointContextMenu = connect(mapStateToProps)(
    InstallationPointContextMenuContainer,
);
