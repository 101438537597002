import * as React from 'react';
import { Box } from 'app/components';
import { LocalProjectsContentHeader } from './components';
import { ProjectsList } from '../components';
import { LocalProjectsActionService } from './services';
import { useService } from 'app/ioc';
import { ProjectDbOriginAsdLocalUserData } from 'app/core/persistence';
import { ApplicationActionService } from 'app/modules/application';
import { useMount } from 'app/hooks';

export const LocalProjects: React.FC = () => {
    const localProjectsActionService = useService(LocalProjectsActionService);
    const applicationActionService = useService(ApplicationActionService);

    useMount(() => {
        applicationActionService.setUserPreferences({
            selectedView: 'localprojects',
        });
    });
    React.useEffect(() => {
        localProjectsActionService.changeProjectDbOrigin(ProjectDbOriginAsdLocalUserData);
    }, [localProjectsActionService]);
    return (
        <Box direction="column" spacing="base">
            <LocalProjectsContentHeader />
            <Box paddingX="base">
                <ProjectsList
                    actionService={localProjectsActionService}
                    state="localProjects"
                    loaded
                    error={null}
                />
            </Box>
        </Box>
    );
};
