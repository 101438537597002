import * as React from 'react';
import { Box, Button, LinkIconButton, Text } from 'app/components';

import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import { CommonActionService, getUserSignedIn } from 'app/modules/common';
import { useService } from 'app/ioc';

const sharedProjectsSvg = require('assets/images/shared-projects-welcome.svg');

export const SharedProjectsWelcome: React.FC = () => {
    const isSignedIn = useSelector(getUserSignedIn);
    const commonActionService = useService(CommonActionService);
    const login = () => {
        commonActionService.login();
    };

    return (
        <Box paddingTop="doublePanel" width={'100%'} justifyContent="center">
            <Box
                paddingTop="doublePanel"
                direction="column"
                spacing="base"
                maxWidth={'440px'}
                alignItems="center"
            >
                <img src={sharedProjectsSvg} height={'110px'} />
                <Text style="title"> {t.welcomeSharedProjects}</Text>
                {!isSignedIn && (
                    <Box direction="column" spacing="base">
                        <Text> {t.sharedProjectsSignIn}</Text>
                        <Button primary onClick={login}>
                            {t.userSignIn}
                        </Button>
                    </Box>
                )}

                {isSignedIn && (
                    <>
                        <Box direction="column" spacing="base">
                            {/* TODO WT-8457 - Replace with correct text */}
                            <Text>
                                Welcome to shared projects! Here will projects shared with other
                                people of your organization be listed.
                            </Text>
                            <Text>
                                In order to share projects with others you need to be a part of a
                                organization.
                            </Text>
                            <Text>Find your organization and resource group.</Text>
                            <Text>
                                If your organization has not yet created a resource group you can
                                create a new one in My Systems.
                            </Text>
                        </Box>
                        <Box color="blue" width={'220px'} paddingY="half" justifyContent="center">
                            {/* TODO  WT-8457 - Replace with correct link */}
                            <LinkIconButton
                                color="white"
                                size="md"
                                text={t.goToMySystems}
                                icon="open_in_new"
                                iconRight
                                external
                                link={`https://iam.mysystems.axis.com/`}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};
