import * as React from 'react';
import type { DeviceType } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { useSelector } from 'react-redux';
import { getFilteredDeviceColors, getFilteredDeviceTypes } from '../selectors';
import { isDeviceFilteredOut } from '../utils';

export const useIsLayerHidden = (
    deviceType: DeviceType | undefined,
    deviceColor: Colors | undefined,
): boolean => {
    const filteredDeviceTypes = useSelector(getFilteredDeviceTypes);
    const filteredDeviceColors = useSelector(getFilteredDeviceColors);

    const isLayerHidden = React.useMemo(() => {
        return isDeviceFilteredOut(
            deviceType,
            deviceColor,
            filteredDeviceTypes,
            filteredDeviceColors,
        );
    }, [deviceColor, deviceType, filteredDeviceColors, filteredDeviceTypes]);

    return isLayerHidden;
};
