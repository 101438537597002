import * as React from 'react';
import type { Id } from 'app/core/persistence';
import { ProjectDashboard } from './ProjectDashboard.component';
import { ProjectAccessories } from './ProjectAccessories.component';
import { ProjectCustomItem } from './ProjectCustomItem.component';
import { ProjectRecordingSolution } from './ProjectRecordingSolution.component';
import { ProjectDocumentation } from './ProjectDocumentation.component';
import { ProjectDevices } from './ProjectDevices.component';
import { ProjectDeviceSelectorEdit } from './projectDeviceSelectorEdit.component';
import { ProjectMaps } from './ProjectMaps.component';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ProjectDeviceSelectorAdd } from './ProjectDeviceSelectorAdd.component';
import { ProjectDeviceSelectorAddChild } from './ProjectDeviceSelectorAddChild.component';
import { ProjectDeviceSelectorEditChild } from './ProjectDeviceSelectorEditChild.component';
import { PrivateRoute } from 'app/routes/components';
import { Reports } from 'app/routes/reports';

interface IProjectRoutesProps {
    projectId: Id;
    isSharedProject: boolean;
    projectLocked?: boolean;
}

export const ProjectRoutes: React.FunctionComponent<IProjectRoutesProps> = ({
    projectId,
    isSharedProject,
    projectLocked,
}) => {
    const projectDevicesPath = isSharedProject
        ? `/sharedproject/${projectId}/devices/`
        : `/project/${projectId}/devices/`;

    return (
        <Routes>
            <Route path={'devices/'} element={<ProjectDevices />} />
            <Route
                path="device-selector/edit/parent/:itemId/item/:subItemId/"
                element={
                    <PrivateRoute
                        component={<ProjectDeviceSelectorEditChild />}
                        condition={!projectLocked}
                        fallbackRoute={projectDevicesPath}
                    />
                }
            />
            <Route
                path={`device-selector/edit/:itemId/`}
                element={
                    <PrivateRoute
                        component={<ProjectDeviceSelectorEdit />}
                        condition={!projectLocked}
                        fallbackRoute={projectDevicesPath}
                    />
                }
            />
            <Route
                path="device-selector/:deviceGroup/parent/:itemId/"
                element={
                    <PrivateRoute
                        component={<ProjectDeviceSelectorAddChild />}
                        condition={!projectLocked}
                        fallbackRoute={projectDevicesPath}
                    />
                }
            />
            <Route
                path="device-selector/:deviceGroup/:category?"
                element={
                    <PrivateRoute
                        component={<ProjectDeviceSelectorAdd />}
                        condition={!projectLocked}
                        fallbackRoute={projectDevicesPath}
                    />
                }
            />
            <Route
                path="device-selector/"
                element={
                    <PrivateRoute
                        component={<ProjectDeviceSelectorAdd />}
                        condition={!projectLocked}
                        fallbackRoute={projectDevicesPath}
                    />
                }
            />
            <Route path={`recording/`} element={<ProjectRecordingSolution />} />
            <Route path={`documentation/`} element={<ProjectDocumentation />} />
            <Route path={`dashboard/`} element={<ProjectDashboard />} />
            <Route path={`accessories/`} element={<ProjectAccessories />} />
            <Route path={`customitems/`} element={<ProjectCustomItem />} />
            <Route path={`reports/*`} element={<Reports />} />
            <Route path={`maps/`} element={<ProjectMaps />} />
            {/* //* Redirect to dashboard when first opening project and when having non-matching urls */}
            <Route path="*" element={<Navigate to="dashboard/" />} />
        </Routes>
    );
};

ProjectRoutes.displayName = 'ProjectRoutes';
