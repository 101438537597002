import { injectable } from 'inversify';
import { ActionCreator, IAction } from 'app/store';
import { DashboardActions } from '../state/Dashboard.actions';

@injectable()
export class DashboardActionService {
    @ActionCreator()
    public editProjectSettings(isOpen: boolean): IAction<boolean> {
        return {
            type: DashboardActions.EditProjectSettings,
            payload: isOpen,
        };
    }

    @ActionCreator()
    public editProjectLocation(isOpen: boolean): IAction<boolean> {
        return {
            type: DashboardActions.EditProjectLocation,
            payload: isOpen,
        };
    }

    @ActionCreator()
    public showShareProject(isOpen: boolean): IAction<boolean> {
        return {
            type: DashboardActions.ShowShareProject,
            payload: isOpen,
        };
    }

    @ActionCreator()
    public showMergeProject(isOpen: boolean): IAction<boolean> {
        return {
            type: DashboardActions.ShowMergeProject,
            payload: isOpen,
        };
    }

    @ActionCreator()
    public showProjectNetwork(isOpen: boolean): IAction<boolean> {
        return {
            type: DashboardActions.ShowProjectNetwork,
            payload: isOpen,
        };
    }
}
