import { injectable } from 'inversify';
import type {
    IItemEntity,
    ICameraItemEntity,
    ProjectZipType,
    BandwidthVersion,
    Id,
    IVirtualProductItemEntity,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import type { Frequency } from 'app/modules/common';
import {
    ProfileOverrideService,
    ProfileSupportService,
    BandwidthCalculatorService,
    ScenarioService,
    StorageCalculationService,
    getVirtualRelatedItems,
    getDeviceVirtualChildren,
} from 'app/modules/common';
import type {
    IExportablePersistedEntity,
    IExportableVirtualItem,
    IExportedCamera,
} from '../../../models';
import { isDefined } from 'axis-webtools-util';
import { BaseItemExporterService } from './BaseItemExporter.service';
import type { IPiaDevice, IPiaCamera } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import { AppStore } from 'app/store';
import { splitEntitiesByQuantity } from '../../splitByQuantity';

@injectable()
export class CamerasExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaDevice>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
        private appStore: AppStore,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedAxisCameras = async (
        projectZipSetting: ProjectZipType,
        items: IExportablePersistedEntity[],
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
        projectId: Id,
    ): Promise<IExportedCamera[]> => {
        const allCameras = items.filter(this.isCameraItemEntity);

        const mappedCameras = await Promise.all(
            allCameras.map(
                async (camera) =>
                    camera &&
                    this.mapCameraToExportedAxisCamera(
                        projectZipSetting,
                        camera,
                        frequency,
                        projectBandwidthVersion,
                        projectRetentionTimeInDays,
                        projectId,
                    ),
            ),
        );

        return mappedCameras.filter(isDefined);
    };

    private mapCameraToExportedAxisCamera = async (
        projectZipSetting: ProjectZipType,
        cameraItem: IExportablePersistedEntity,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectRetentionTimeInDays: number,
        projectId: Id,
    ): Promise<IExportedCamera | null> => {
        if (!cameraItem.productId || !cameraItem.properties.camera) {
            return null;
        }
        const cameraPiaDevice = this.getPiaDevice(cameraItem.productId) as IPiaCamera;
        const storeState = this.appStore.Store.getState();
        const virtualPiaItems: IPiaCamera[] = getVirtualRelatedItems(
            storeState,
            cameraPiaDevice?.id,
        );
        const virtualItems = getDeviceVirtualChildren(storeState, cameraItem._id);
        const virtualChildEntities = splitEntitiesByQuantity(virtualItems);
        const allVirtualCameras = virtualChildEntities.filter(this.isVirtualCameraItemEntity);

        const virtualItemsToExport: IExportableVirtualItem[] = (
            await Promise.all(
                allVirtualCameras
                    .map(async (virtualItem) => {
                        if (virtualItem.properties.virtualProduct) {
                            const mergedProfile =
                                await this.profileOverrideService.getMergedProfileFromDevice(
                                    virtualItem.properties.virtualProduct,
                                    projectRetentionTimeInDays,
                                );
                            const piaProduct = virtualPiaItems.find(
                                (piaItem) => piaItem.id === virtualItem.productId,
                            );
                            if (piaProduct) {
                                return {
                                    item: virtualItem,
                                    mergedProfile,
                                    piaProduct,
                                };
                            }
                        }
                    })
                    .filter(isDefined),
            )
        ).filter(isDefined);

        const mergedProfile = await this.profileOverrideService.getMergedProfileFromDevice(
            cameraItem.properties.camera,
            projectRetentionTimeInDays,
        );

        const exportedCamera = await this.mapCameraToExportedCamera(
            projectZipSetting,
            cameraItem,
            mergedProfile,
            cameraPiaDevice.properties,
            cameraPiaDevice.category,
            frequency,
            projectBandwidthVersion,
            projectId,
            cameraItem.properties.camera.profileOverride.customBandwidth,
            virtualItemsToExport,
        );

        const cameraPiaDeviceShortName = cameraPiaDevice.name
            .replace(/\s?(50|60)\s?hz\s?/i, ' ')
            .trim();

        return {
            ...exportedCamera,
            modelName: cameraPiaDeviceShortName,
            productVariantName: cameraPiaDevice.name,
            modelShortName: cameraPiaDeviceShortName,
            piaId: cameraItem.productId,
        };
    };

    private isCameraItemEntity(item: IItemEntity): item is ICameraItemEntity {
        return deviceTypeCheckers.isCamera(item) && !deviceTypeCheckers.isDoorStation(item);
    }

    private isVirtualCameraItemEntity(item: IItemEntity): item is IVirtualProductItemEntity {
        return deviceTypeCheckers.isVirtualProduct(item);
    }
}
