import type { Id, PanoramaModes, SpeakerPlacement, DeviceType } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import type { IPiaDevice } from 'app/core/pia';

export interface IMapsBaseDevice {
    id: Id;
    rev: string;
    name: string;
    notes: string;
    model: string;
    productId: number | null;
    deviceType: DeviceType;
    quantity: number;
    locked?: boolean;
    color: Colors;
    piaProduct?: IPiaDevice;
    creationDate: Date;
    isFilteredOut: boolean;
}

export interface IMapsCamera extends IMapsBaseDevice {
    pixelDensity: number | undefined;
    panoramaMode: PanoramaModes;
    canChangePanoramaMode: boolean;
}

export interface IMapsSpeaker extends IMapsBaseDevice {
    placement: SpeakerPlacement;
    canChangePlacement: boolean;
}

export type IMapsDevice = IMapsCamera | IMapsSpeaker;

export const isMapsCamera = (device?: IMapsDevice): device is IMapsCamera =>
    !!device && ['camera', 'analogCamera', 'doorstation', 'sensorUnit'].includes(device.deviceType);

export const isMapsSpeaker = (device?: IMapsDevice): device is IMapsSpeaker =>
    !!device && ['speaker'].includes(device.deviceType);

export const ensureCameraOrUndefined = (device?: IMapsDevice): IMapsCamera | undefined =>
    isMapsCamera(device) ? device : undefined;

export const ensureSpeakerOrUndefined = (device?: IMapsDevice): IMapsSpeaker | undefined =>
    isMapsSpeaker(device) ? device : undefined;
