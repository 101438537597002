import { appVersion } from './AppConstants.generated';

export const AppConstants = {
    appVersion,
    modalRootId: 'modal-root',
    deselectedArrowDepth: -1,
    deselectedMarkerDepth: 0,
    productItemDepth: 1,
    tableFooterDepth: 1,
    tableHeaderDepth: 2,
    productSelectorHeaderDepth: 2,
    topRecommendedProductDepth: 3,
    deviceTabDepth: 4,
    mainMenuDepth: 19,
    contentMaxDepth: 20,
    headerDepth: 21,
    modalDepth: 30,
    portraitWarningDepth: 50,
    selectedArrowDepth: 100,
    mapUIDepth: 401, // Leaflet has hard-coded z-index: 400. This allows UI to be displayed above contents in the map
    toastDepth: 500,
    selectedMarkerDepth: 1000,
    targetHandleDepth: 1010, // to be on top of other cameras
    fovHandleDepth: 1011, // to be on top of target handle and other cameras
    mapMaxDepth: 1012,

    projectNameMaxLength: 64,
    deviceNameMaxLength: 64,
    modelNameMaxLength: 64,
    scheduleNameMaxLength: 64,
    profileNameMaxLength: 64,
    freeTextMaxLength: 64,
    mapNameMaxLength: 64,
    componentQuantityMax: 9999,
    defaultLocation: 'us',
    logoHeight: 29,
    ipAddressMaxLength: 39,
};
