import * as React from 'react';
import { connect } from 'react-redux';
import type { IStoreState } from 'app/store';
import { BillOfMaterialsComponent } from './BillOfMaterials.component';
import type { IProduct } from 'app/modules/common';
import {
    getCurrentProjectName,
    getCurrentProjectId,
    getCurrentProjectLocation,
    getProductsExcludeAxisIncludedAcaps,
    getProductsWithAllowlistProperty,
} from 'app/modules/common';
import { EmptyReport } from '../../components';
import { t } from 'app/translate';
import type { IPiaLocation } from 'app/core/pia';
import type { Id } from 'app/core/persistence';

interface IBillOfMaterialsProps {
    projectId: Id;
    projectName: string;
    products: IProduct[];
    location?: IPiaLocation;
    excludedProductsMessage?: string;
}

const mapStateToProps = (storeState: IStoreState): IBillOfMaterialsProps => {
    const products = getProductsWithAllowlistProperty(storeState);

    return {
        projectId: getCurrentProjectId(storeState),
        projectName: getCurrentProjectName(storeState),
        location: getCurrentProjectLocation(storeState),
        products: getProductsExcludeAxisIncludedAcaps(storeState, products),
        excludedProductsMessage: storeState.common.partnerConfig.allowlist
            ? storeState.common.partnerConfig.allowlist.excludedProductsMessage
            : undefined,
    };
};

class BillOfMaterialsContainer extends React.Component<IBillOfMaterialsProps> {
    public render() {
        if (this.props.products.length === 0) {
            return this.renderEmpty();
        } else {
            return this.renderTable();
        }
    }

    private renderTable() {
        const { projectId, projectName, products, location, excludedProductsMessage } = this.props;

        return (
            <BillOfMaterialsComponent
                projectId={projectId}
                projectName={projectName}
                products={products}
                locationName={location?.name ?? ''}
                excludedProductsMessage={excludedProductsMessage}
            />
        );
    }

    private renderEmpty() {
        return (
            <EmptyReport
                projectId={this.props.projectId}
                projectName={this.props.projectName}
                text={t.billOfMaterialsNoDevices}
            />
        );
    }
}

export const BillOfMaterials = connect(mapStateToProps)(BillOfMaterialsContainer);
