import { useSelector } from 'react-redux';
import * as React from 'react';
import { useMemo, useCallback } from 'react';
import * as leaflet from 'leaflet';
import type {
    IInstallationPoint,
    IInstallationPointModel,
    IInstallationPointSensorModel,
    ILatLng,
} from 'app/core/persistence';
import { InstallationPointService, deviceTypeCheckers, getDeviceType } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IStoreState } from 'app/store';
import { ServiceLocator, useService } from 'app/ioc';
import { MapsActionService, MapsService, LeafletItemFactory } from '../../services';
import { CoverageArea } from './cone/CoverageArea';
import { DragCopyMapItem } from './cone/DragCopyMapItem';
import { InstallationPointLabel } from './InstallationPointLabel';
import {
    getIsFocused,
    getInstallationPointColor,
    getParentLocation,
    getUseBigImages,
    getPressedModifierKeys,
    getDuplicationInfo,
    getLabelsLayerVisible,
    getIsInstallationPointSelected,
    getUseTinyIcons,
    getDeviceTypesWithFilteredCoverageArea,
    getMultiSelectedInstallationPointsModels,
    getMultiSelectPositionRecord,
    getParentLocationForId,
    getDerotationTransform,
    getDerotationAngle,
    getRotationTransform,
} from '../../selectors';
import {
    useDragEndEvent,
    useDragEvent,
    useDragStartEvent,
    useMouseUpEvent,
} from '../../hooks/useLeafletMouseEvent';
import { useKeyDown } from 'app/hooks';
import type { BaseCone } from './cone/BaseCone';
import {
    getDefaultImageForDeviceId,
    getHasExternalIRIllumination,
    getImageForDeviceId,
    getInstallationPoint,
    getImageIconForDeviceId,
    calculateSensors,
    transformInstallationPoint,
} from 'app/modules/common';
import { getIsUserOnline } from 'app/modules/application';
import { useMapContext } from '../context';
import { debounce, merge } from 'lodash-es';

const DeleteKey = 'Delete';
const ArrowLeftKey = 'ArrowLeft';
const ArrowRightKey = 'ArrowRight';
const ArrowUpKey = 'ArrowUp';
const ArrowDownKey = 'ArrowDown';
type MoveDistance = {
    left: number;
    right: number;
    up: number;
    down: number;
};
const zeroMoveDistance: MoveDistance = { left: 0, right: 0, up: 0, down: 0 };
const distanceToMoveInPx = 5;
const LeafletItemClass = 'leaflet';

interface IInstallationPointMapItem {
    installationPoint: IInstallationPointModel;
}

/**
 * // This installation point will be a 'ghost' during drag to copy/duplicate
 */
export const InstallationPointMapItem: React.FC<IInstallationPointMapItem> = React.memo(
    ({ installationPoint: rawInstallationPoint }) => {
        const derotateLatLng = useSelector(getDerotationTransform);
        const derotationAngle = useSelector(getDerotationAngle);
        const rotateLatLng = useSelector(getRotationTransform);

        // Transform the raw installation point to match the floor plan rotation if necessary
        const installationPoint = useMemo(() => {
            return transformInstallationPoint(
                derotateLatLng,
                derotationAngle,
                rawInstallationPoint,
            );
        }, [derotateLatLng, derotationAngle, rawInstallationPoint]);
        const { leafletMap } = useMapContext();
        const installationPointService = useService(InstallationPointService);
        const mapsService = useService(MapsService);

        const multiSelectedInstallationPoints = useSelector(
            getMultiSelectedInstallationPointsModels,
        );

        const multiSelectedPositionRecord = useSelector(getMultiSelectPositionRecord);

        const parentLocationForMultiSelected = useSelector<IStoreState, ILatLng | undefined>(
            (state) => getParentLocationForId(state, installationPoint.parentId),
        );

        const installationPointEntity = useSelector<IStoreState, IInstallationPoint | undefined>(
            (state) => getInstallationPoint(state, installationPoint._id),
        );
        const useBigIcon = useSelector(getUseBigImages);
        const itemImage = useSelector<IStoreState, string>((state) =>
            getImageForDeviceId(state, installationPoint.parentDevice._id),
        );
        const itemIcon = useSelector<IStoreState, string>((state) =>
            getImageIconForDeviceId(state, installationPoint.parentDevice._id),
        );
        const itemFallbackImage = useSelector<IStoreState, string>((state) =>
            getDefaultImageForDeviceId(state, installationPoint.parentDevice._id),
        );
        const isOnline = useSelector(getIsUserOnline);
        const parentLocation = useSelector<IStoreState, ILatLng | undefined>((state) => {
            return getParentLocation(state, installationPoint.parentId);
        });

        const derotatedParentLocation = React.useMemo(() => {
            return parentLocation ? derotateLatLng(parentLocation) : undefined;
        }, [derotateLatLng, parentLocation]);

        const color = useSelector<IStoreState, Colors>((state) =>
            getInstallationPointColor(state, installationPoint._id),
        );
        const colorRef = React.useRef(color);
        colorRef.current = color;
        const isSelected = useSelector<IStoreState, boolean>((state) =>
            getIsInstallationPointSelected(state, installationPoint?._id),
        );
        const labelsLayerVisible = useSelector(getLabelsLayerVisible);
        const isFocused = useSelector<IStoreState, boolean>((state) =>
            getIsFocused(state, installationPoint._id),
        );
        const modifierKeys = useSelector(getPressedModifierKeys);
        const duplicationInfo = useSelector(getDuplicationInfo);
        const deviceTypesWithFilteredCoverageArea = useSelector(
            getDeviceTypesWithFilteredCoverageArea,
        );
        const hasExternalIlluminator = useSelector<IStoreState, boolean>((state) =>
            getHasExternalIRIllumination(state, installationPoint?.parentDevice._id),
        );
        const useTinyIcons = useSelector(getUseTinyIcons);
        const [factory] = React.useState(ServiceLocator.get(LeafletItemFactory));
        const [actions] = React.useState(ServiceLocator.get(MapsActionService));
        const [cones, setCones] = React.useState<BaseCone[] | null>(null);
        const [deviceImage, setDeviceImage] = React.useState(
            isOnline ? (useTinyIcons ? itemIcon : itemImage) : itemFallbackImage,
        );
        const [draggableMarker] = React.useState(
            factory.createInteractiveItem(
                installationPoint?.location,
                useBigIcon
                    ? factory.createBigDeviceIcon(deviceImage, color)
                    : factory.createSmallDeviceIcon(deviceImage, color),
                !leafletMap.readOnly,
            ),
        );

        const [parentLine] = React.useState(factory.createDashedPolyline([], color));

        const [isDragging, setIsDragging] = React.useState(false);
        const originalLocation = React.useRef<ILatLng>(draggableMarker.getLatLng());

        const [showCoverageArea, setShowCoverageArea] = React.useState(false);

        const isBeingDuplicated = duplicationInfo?.installationPointId === installationPoint._id;
        const isOpaque = isBeingDuplicated ? false : isFocused;

        // Active leaflet items (no child items e.g sensor units etc.) can be removed.
        const removeInstallationPoint = () => {
            if (
                isSelected &&
                installationPoint &&
                installationPoint?.parentId === undefined &&
                document.activeElement?.className.includes(LeafletItemClass)
            ) {
                installationPointService.removeInstallationPointDebounced(
                    installationPoint._id,
                    installationPoint._rev,
                );
            }
        };

        const moveInstallationPoint = (moveDistance: Partial<MoveDistance>) => {
            if (!isSelected || !installationPoint) return;
            const moveDirections = { ...zeroMoveDistance, ...moveDistance };
            const projectionPoint = leafletMap.map.project(
                installationPoint.location,
                leafletMap.map.getZoom(),
            );
            const newLocationPoint: leaflet.Point = new leaflet.Point(
                projectionPoint.x - moveDirections.left + moveDirections.right,
                projectionPoint.y - moveDirections.up + moveDirections.down,
            );
            const location = leafletMap.map.unproject(newLocationPoint, leafletMap.map.getZoom());
            // Update position of selected item
            // Update position in database debounced to avoid many writes to the database
            // when continuously pressing a move key
            updateSelectedMapItemDebounced({ ...installationPoint, location });
        };

        const rotateInstallationPoint = (degrees: number) => {
            if (!cones?.length || !installationPointEntity) return;
            const rotationOffset = degrees;
            const rotatedInstallationPoint = cones.reduce((mergedInstallationPoint, cone) => {
                return merge(
                    mergedInstallationPoint,
                    cone.getRotatedTarget(mergedInstallationPoint, rotationOffset),
                ) as IInstallationPointModel;
            }, installationPointEntity);

            return installationPointService.updateInstallationPoint(
                installationPoint._id,
                rotatedInstallationPoint,
            );
        };

        const updateSelectedMapItemDebounced = React.useMemo(
            () =>
                debounce(
                    (installationPointToUpdate: IInstallationPointModel) =>
                        actions.updateInstallationPoint(installationPointToUpdate),
                    150,
                ),
            [actions],
        );

        useKeyDown((e) => {
            if (!installationPoint || !isSelected) {
                return;
            }

            if (
                [DeleteKey, ArrowLeftKey, ArrowRightKey, ArrowUpKey, ArrowDownKey].includes(e.key)
            ) {
                e.stopPropagation();
            }

            if (
                modifierKeys.isShiftDown &&
                [ArrowRightKey, ArrowUpKey, ArrowLeftKey, ArrowDownKey].includes(e.key)
            ) {
                const rotationOffset = [ArrowRightKey, ArrowUpKey].includes(e.key) ? 90 : -90;
                return rotateInstallationPoint(rotationOffset);
            }

            if (e.key === DeleteKey) removeInstallationPoint();
            if (e.key === ArrowLeftKey) moveInstallationPoint({ left: distanceToMoveInPx });
            if (e.key === ArrowRightKey) moveInstallationPoint({ right: distanceToMoveInPx });
            if (e.key === ArrowUpKey) moveInstallationPoint({ up: distanceToMoveInPx });
            if (e.key === ArrowDownKey) moveInstallationPoint({ down: distanceToMoveInPx });
        }, leafletMap.map.getContainer());

        const onDragStart = useCallback(() => {
            // Save the original position, since this might turn into a copy-drag operation
            originalLocation.current = draggableMarker.getLatLng();

            setIsDragging(true);
            if (multiSelectedInstallationPoints.length > 0) {
                actions.multiSelect(installationPoint, true);
            }
            // Always select the dragged installation point when dragging
            // Even when multi selecting, the dragged installation point should be the primary
            actions.selectMapItem(installationPoint, 'installationPoint');
            draggableMarker.setZIndexOffset(1000);
        }, [actions, draggableMarker, installationPoint, multiSelectedInstallationPoints]);

        const onDrag = useCallback(
            (e: leaflet.LeafletMouseEvent) => {
                if (!installationPoint) return;

                const newIp = {
                    ...installationPoint,
                    location: (e as leaflet.LeafletMouseEvent).latlng,
                };
                actions.setDerotatedDraftInstallationPoint(newIp);

                const offsetLat = e.latlng.lat - originalLocation.current.lat;
                const offsetLng = e.latlng.lng - originalLocation.current.lng;

                multiSelectedInstallationPoints.forEach((selectedInstallationPoint) => {
                    // Calculate the new location of the multi selected installation points
                    // taking into account the floor plan rotation
                    const transformedLocation = derotateLatLng(selectedInstallationPoint.location);
                    const newLat = transformedLocation.lat + offsetLat;
                    const newLng = transformedLocation.lng + offsetLng;
                    actions.multiSelect(
                        {
                            ...selectedInstallationPoint,
                            location: { lat: newLat, lng: newLng },
                        },
                        true,
                    );
                });
            },
            [actions, installationPoint, multiSelectedInstallationPoints, derotateLatLng],
        );

        const onDragEnd = useCallback(() => {
            setIsDragging(false);
            if (!installationPoint) return;

            if (modifierKeys.isAltDown && multiSelectedInstallationPoints.length === 0) {
                actions.duplicateMapItem(installationPoint, modifierKeys.isControlDown);
            } else if (multiSelectedInstallationPoints.length > 0) {
                const updatedInstallationPoints: IInstallationPointModel[] = [];

                // Calculate the difference between the original location and the new location
                // taking into account the rotation of the floor plan
                const originLatLng = rotateLatLng(originalLocation.current);
                const targetLatLng = rotateLatLng(draggableMarker.getLatLng());
                const diff = {
                    lat: targetLatLng.lat - originLatLng.lat,
                    lng: targetLatLng.lng - originLatLng.lng,
                };

                multiSelectedInstallationPoints.forEach((otherInstallationPoint) => {
                    const newLocation = {
                        lat: otherInstallationPoint.location.lat + diff.lat,
                        lng: otherInstallationPoint.location.lng + diff.lng,
                    };

                    updatedInstallationPoints.push({
                        ...otherInstallationPoint,
                        location: newLocation,
                    });
                });
                installationPointService.updateInstallationPoints(updatedInstallationPoints);
            } else {
                actions.updateInstallationPoint(installationPoint);
            }
        }, [
            modifierKeys,
            actions,
            installationPointService,
            draggableMarker,
            installationPoint,
            multiSelectedInstallationPoints,
            rotateLatLng,
        ]);

        useDragStartEvent(draggableMarker, onDragStart);
        useDragEvent(draggableMarker, onDrag);
        useDragEndEvent(draggableMarker, onDragEnd);
        useMouseUpEvent(draggableMarker, () => {
            if (leafletMap.readOnly || isDragging) return;

            modifierKeys.isShiftDown && installationPoint?._id
                ? actions.multiSelect(installationPoint)
                : actions.selectMapItem(installationPoint, 'installationPoint');
        });

        /** Add/remove installation point to map */
        React.useEffect(() => {
            draggableMarker.addTo(leafletMap.map);
            parentLine?.addTo(leafletMap.map);

            return () => {
                draggableMarker.removeFrom(leafletMap.map);
                parentLine?.removeFrom(leafletMap.map);
            };
        }, [draggableMarker, leafletMap.map, parentLine]);

        // Remove ghost item if alt is released
        React.useEffect(() => {
            if (duplicationInfo && !modifierKeys.isAltDown) {
                actions.setDuplicationInfo(null);
            }
        }, [actions, duplicationInfo, modifierKeys.isAltDown]);

        // This repositions the installationPointMapItem after a copy-drag operation
        React.useEffect(() => {
            if (!isDragging && installationPoint?.location) {
                draggableMarker.setLatLng(installationPoint.location);
            }
        }, [draggableMarker, installationPoint?.location, isDragging]);

        React.useEffect(() => {
            if (parentLine && derotatedParentLocation && installationPoint) {
                duplicationInfo &&
                duplicationInfo.installationPointId === installationPoint.parentId
                    ? parentLine.setLatLngs([
                          installationPoint.location,
                          duplicationInfo.originalLocation,
                      ])
                    : parentLine.setLatLngs([installationPoint.location, derotatedParentLocation]);
            }
        }, [installationPoint, derotatedParentLocation, parentLine, duplicationInfo]);

        const tinyIconRotationAngle = useMemo(() => {
            const isCamera =
                installationPoint?.parentDevice &&
                deviceTypeCheckers.isCamera(installationPoint?.parentDevice);

            return isCamera ? installationPoint?.sensors[0]?.target.horizontalAngle + 90 : 0;
        }, [installationPoint?.sensors, installationPoint?.parentDevice]);

        // Create icons for the draggable marker
        const tinyIcon = useMemo(() => {
            if (!deviceImage || !useTinyIcons) return undefined;

            return factory.createTinyDeviceIcon(deviceImage, tinyIconRotationAngle);
        }, [deviceImage, factory, tinyIconRotationAngle, useTinyIcons]);

        const smallIcon = useMemo(() => {
            if (!deviceImage || useBigIcon) return undefined;

            return factory.createSmallDeviceIcon(deviceImage, color);
        }, [color, deviceImage, factory, useBigIcon]);

        const bigIcon = useMemo(() => {
            if (!deviceImage || !useBigIcon) return undefined;

            return factory.createBigDeviceIcon(deviceImage, color);
        }, [color, deviceImage, factory, useBigIcon]);

        /* Update the icon of the draggable marker */
        React.useEffect(() => {
            if (tinyIcon) {
                draggableMarker.setIcon(tinyIcon);
            } else if (bigIcon) {
                draggableMarker.setIcon(bigIcon);
            } else if (smallIcon) {
                draggableMarker.setIcon(smallIcon);
            }
        }, [bigIcon, draggableMarker, smallIcon, tinyIcon, useBigIcon, useTinyIcons]);

        // Update draggable marker if it is multi selected and the primary draggable marker is moved
        React.useEffect(() => {
            if (
                !installationPoint ||
                !multiSelectedPositionRecord[installationPoint._id] ||
                multiSelectedInstallationPoints.length === 0
            ) {
                return undefined;
            }
            draggableMarker.setLatLng(multiSelectedPositionRecord[installationPoint._id]);
        }, [
            draggableMarker,
            installationPoint,
            multiSelectedPositionRecord,
            multiSelectedInstallationPoints,
        ]);

        // Update parent line when dragging multiple installation points
        React.useEffect(() => {
            if (!installationPoint || multiSelectedInstallationPoints.length === 0) return;

            const childLatLng =
                multiSelectedPositionRecord[installationPoint._id] ?? installationPoint?.location;

            if (parentLine && parentLocationForMultiSelected) {
                parentLine.setLatLngs([childLatLng, parentLocationForMultiSelected]);
            }
        }, [
            installationPoint,
            multiSelectedPositionRecord,
            multiSelectedInstallationPoints.length,
            parentLine,
            parentLocationForMultiSelected,
        ]);

        /** Changes the color of the dotted line to its' parent */
        React.useEffect(() => {
            if (parentLine) {
                parentLine.setStyle({
                    color: ColorsEnum[color],
                });
            }
        }, [color, parentLine]);

        React.useEffect(() => {
            if (isOpaque) {
                draggableMarker?.getElement()?.removeAttribute('data-blurred');
                parentLine?.setStyle({ opacity: 1 });
            } else {
                draggableMarker?.getElement()?.setAttribute('data-blurred', 'true');
                parentLine?.setStyle({ opacity: 0.4 });
            }
        }, [draggableMarker, isOpaque, parentLine]);

        React.useEffect(() => {
            if (isSelected) {
                draggableMarker?.setZIndexOffset(1000);
            } else {
                draggableMarker?.setZIndexOffset(0);
            }
        }, [draggableMarker, isSelected]);

        // Remove draft installation point on unselect
        React.useEffect(() => {
            if (!isSelected) {
                actions.removeDraftInstallationPoint(installationPoint._id);
            }
        }, [actions, isSelected, installationPoint._id]);

        /** Re-create coverage areas when sensors change */
        React.useLayoutEffect(() => {
            if (!installationPoint) return;
            setCones(
                factory.createCones(
                    installationPoint,
                    leafletMap,
                    colorRef.current,
                    hasExternalIlluminator,
                ),
            );
        }, [factory, installationPoint, leafletMap, hasExternalIlluminator]);

        React.useEffect(() => {
            const deviceType = installationPoint && getDeviceType(installationPoint.parentDevice);
            // Do not show coverage area for devices without a device type
            if (!deviceType || !installationPoint) return setShowCoverageArea(false);

            // Always show coverage area for selected installation point
            if (isSelected) return setShowCoverageArea(true);

            const shouldShowCoverageArea =
                !deviceTypesWithFilteredCoverageArea.includes(deviceType);
            setShowCoverageArea(shouldShowCoverageArea);
        }, [installationPoint, deviceTypesWithFilteredCoverageArea, isSelected]);

        React.useEffect(() => {
            if (isOnline) {
                setDeviceImage(useTinyIcons ? itemIcon : itemImage);
            }
        }, [isOnline, itemIcon, itemImage, useTinyIcons]);

        if (!installationPoint || !cones) return null;

        const setSensors = (newSensors: IInstallationPointSensorModel[]) => {
            const newIp = {
                ...installationPoint,
                sensors: newSensors,
            };
            actions.setDerotatedDraftInstallationPoint(newIp);
        };

        const onTargetChange = (
            angle: number,
            distance: number,
            sensor: IInstallationPointSensorModel,
        ) => {
            const newSensors = calculateSensors(installationPoint.sensors, {
                ...sensor,
                target: {
                    ...sensor.target,
                    horizontalAngle: angle,
                    distance,
                },
                settings: {
                    ...sensor.settings,
                },
            });

            setSensors(newSensors);
        };

        const onFovChange = (fov: number, sensor: IInstallationPointSensorModel) => {
            const newSensors = calculateSensors(installationPoint.sensors, {
                ...sensor,
                target: {
                    ...sensor.target,
                },
                settings: {
                    ...sensor.settings,
                    horizontalFov: fov,
                },
            });

            setSensors(newSensors);
        };

        const onCoverageAreaDragEnd = (cone: BaseCone) => {
            if (cone.sensor) {
                mapsService.updateSensors(installationPoint._id, installationPoint.sensors);
            } else {
                const newCoverageArea = cone.getNonSensorCoverageAreaInfo();

                mapsService.updateCoverageArea(installationPoint._id, newCoverageArea);

                // Update the draft installation point with the new coverage area
                const newIp = {
                    ...installationPoint,
                    ...newCoverageArea,
                };
                actions.setDerotatedDraftInstallationPoint(newIp);
            }
        };

        return (
            <>
                {showCoverageArea &&
                    cones.map((cone, index) => (
                        <CoverageArea
                            key={index}
                            map={leafletMap}
                            cone={cone}
                            installationPoint={installationPoint}
                            color={color}
                            isOpaque={isOpaque}
                            onTargetChange={(angle: number, distance: number) => {
                                cone.sensor && onTargetChange(angle, distance, cone.sensor);
                            }}
                            onFovChange={(fov: number) => {
                                cone.sensor && onFovChange(fov, cone.sensor);
                            }}
                            onDragEnd={() => {
                                onCoverageAreaDragEnd(cone);
                            }}
                        />
                    ))}
                {labelsLayerVisible && !isBeingDuplicated && (
                    <InstallationPointLabel
                        installationPoint={installationPoint}
                        map={leafletMap}
                        color={color}
                    />
                )}
                {isDragging &&
                    modifierKeys.isAltDown &&
                    multiSelectedInstallationPoints.length === 0 && (
                        <DragCopyMapItem
                            installationPoint={{
                                ...installationPoint,
                                location: originalLocation.current,
                            }}
                            map={leafletMap}
                        />
                    )}
            </>
        );
    },
);

InstallationPointMapItem.displayName = 'InstallationPointMapItem';
