import { createCachedSelector } from 're-reselect';
import { isDefined } from 'axis-webtools-util';
import { createSelector } from 'reselect';
import { getSelectedMapOrDefault } from './getFloorPlans';
import { getCurrentProjectItems } from './getCurrentProjectItems';
import { getInstallationPointsPerFloorPlan } from './floorPlanMapping';
import { getOriginFilter } from './getOriginFilter';
import {
    getCurrentProjectInstallationPointsArray,
    getCurrentProjectItemRelationsArray,
    getIdFromProps,
    getPiaItemsRecord,
    isGeoLocated,
    mapInstallationEntityToModel,
    toCacheKey,
} from 'app/modules/common';
import type { DeviceType, Id, IInstallationPointModel } from 'app/core/persistence';
import { getDeviceType } from 'app/core/persistence';
import { getGeoLocatedInstallationPoints } from './getProjectInstallationPoints';
import type { IPiaItem } from 'app/core/pia';
import type { Colors } from 'app/styles';
import { getFilteredDeviceColors, getFilteredDeviceTypes } from './getFilteredMapLayers';
import { getBoundsFilter } from './bounds';

export const getSelectedFloorPlansInstallationPoints = createSelector(
    [
        getCurrentProjectInstallationPointsArray,
        getSelectedMapOrDefault,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
    ],
    (installationPoints, currentFloorPlan, items, relations) =>
        currentFloorPlan
            ? installationPoints
                  .filter((ip) =>
                      isGeoLocated(currentFloorPlan)
                          ? !ip.floorPlanId
                          : ip.floorPlanId === currentFloorPlan._id,
                  )
                  .map((ip) => mapInstallationEntityToModel(ip, items, relations))
                  .filter(isDefined)
            : [],
);

export const getFloorPlansInstallationPointIds = createCachedSelector(
    [getCurrentProjectInstallationPointsArray, getIdFromProps],
    (installationPoints, floorPlanId): Id[] => {
        if (!floorPlanId) return [];
        return installationPoints
            .filter((ip) => ip.floorPlanId === floorPlanId)
            .map((ip) => ip._id)
            .sort();
    },
)(toCacheKey);

/**
 * Returns the installation points that are on or around the current floor plan
 * if it is geo located. Otherwise returns the installation points on the current
 * (non-geo located) floor plan.
 */
export const getInstallationPointsOnOrAroundCurrentMap = createSelector(
    [getInstallationPointsPerFloorPlan, getGeoLocatedInstallationPoints, getSelectedMapOrDefault],
    (ipsPerFloorPlan, geoLocatedIps, floorPlan): IInstallationPointModel[] => {
        if (isGeoLocated(floorPlan)) {
            return geoLocatedIps;
        } else {
            return (floorPlan && ipsPerFloorPlan[floorPlan._id]) ?? [];
        }
    },
);

/**
 * Returns the installation points that are on the current floor plan and match the current filters.
 */
const getFloorPlansInstallationPointsFilteredByLayers = createSelector(
    [
        getInstallationPointsOnOrAroundCurrentMap,
        getPiaItemsRecord,
        getFilteredDeviceTypes,
        getFilteredDeviceColors,
        getOriginFilter,
    ],
    (
        installationPoints,
        piaItems,
        deviceFilter,
        colorFilter,
        originFilter,
    ): IInstallationPointModel[] => {
        return installationPoints.filter((ip) => {
            return (
                filterByParentDeviceType(deviceFilter, ip, installationPoints, piaItems) &&
                filterByParentColor(colorFilter, ip, installationPoints) &&
                (!originFilter || ip.mapOrigin === originFilter)
            );
        });
    },
);

/**
 * Returns the installation points that are on the current floor plan, match the current filters
 * and are within the current map view bounds.
 */
export const getFloorPlansInstallationPointsFilteredByLayersAndBounds = createSelector(
    [getFloorPlansInstallationPointsFilteredByLayers, getBoundsFilter],
    (installationPoints, boundsFilter) => {
        return installationPoints.filter(boundsFilter);
    },
);

export const getFloorPlansInstallationPointIdsFilteredByLayersAndBounds = createSelector(
    [getFloorPlansInstallationPointsFilteredByLayersAndBounds],
    (installationPoints) => installationPoints.map((ip) => ip._id),
);

/**
 * Checks if the installation point is allowed by the device type filter.
 * @param deviceTypeFilter Filtered device types
 * @param installationPoint The installation point to filter
 * @param installationPoints All installation points (used to find parent installation point)
 * @param piaItems Record of all PIA items
 * @returns True if the installation point is allowed by the filter, false otherwise
 */
const filterByParentDeviceType = (
    deviceTypeFilter: DeviceType[],
    installationPoint: IInstallationPointModel,
    installationPoints: IInstallationPointModel[],
    piaItems: Record<number, IPiaItem>,
): boolean => {
    if (!installationPoint) {
        return false;
    }

    const piaItem = installationPoint.parentDevice?.productId
        ? piaItems[installationPoint.parentDevice?.productId]
        : undefined;
    let deviceType = getDeviceType(installationPoint.parentDevice, piaItem);
    if (installationPoint?.parentId) {
        const parentInstallationPoint = installationPoints.find(
            (ip) => ip._id === installationPoint.parentId,
        );
        deviceType =
            parentInstallationPoint?.parentDevice &&
            getDeviceType(parentInstallationPoint?.parentDevice, piaItem);
    }

    if (deviceType === undefined) {
        // Filter out installation points with no device type
        return false;
    }

    return !deviceTypeFilter.includes(deviceType);
};

/**
 * Checks if the installation point is allowed by the color filter.
 * @param colorFilter Filtered colors
 * @param installationPoint The installation point to filter
 * @param installationPoints All installation points (used to find parent installation point)
 * @returns True if the installation point is allowed by the filter, false otherwise
 */
const filterByParentColor = (
    colorFilter: Colors[],
    installationPoint: IInstallationPointModel,
    installationPoints: IInstallationPointModel[],
): boolean => {
    if (!installationPoint) {
        return false;
    }

    let deviceColor = installationPoint?.parentDevice.color;
    if (installationPoint?.parentId) {
        const parentInstallationPoint = installationPoints.find(
            (ip) => ip._id === installationPoint.parentId,
        );
        deviceColor = parentInstallationPoint?.parentDevice.color;
    }

    if (deviceColor === undefined) {
        // Filter out installation points with no color
        return false;
    }

    return !colorFilter.includes(deviceColor);
};
