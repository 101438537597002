import { createSelector } from 'reselect';
import {
    getAllProductsWithIncludedAcaps,
    getSelectedAcapsByProduct,
    filterNotIncludedAcaps,
    removeAllIncludedAcaps,
} from './helpers';
import type { ProductWithAccessory } from './types';
import type { IStoreState } from 'app/store';
import type { IProduct } from '../../IProduct';

/**
 * A function that gets the acaps that are not included in the products
 * @param includedAcapsByProduct - acaps that are included in other products
 * @param selectedAcapsByProduct - acaps that are selected
 * @returns acaps that are not included in other products
 * @example
 *  includedAcapsByProduct  {
 *      "75436": [
 *          {
 *              "id": 56122,
 *              "name": "AXIS Live Privacy Shield",
 *              "category": "acap",
 *              "relations": [],
 *              ...
 *          },
 *      ]
 *  }
 *  selectedAcapsByProduct  {
 *      "75436": [
 *          {
 *              "id": 45010,
 *              "name": "AXIS License Plate Verifier eLicense",
 *              "category": "acap",
 *              "relations": [],
 *              ...
 *          }
 *      ]
 *  }
 */
const getNotIncludedAcapsByProduct = createSelector(
    [getAllProductsWithIncludedAcaps, getSelectedAcapsByProduct],
    (includedAcapsByProduct, selectedAcapsByProduct): ProductWithAccessory =>
        removeAllIncludedAcaps(selectedAcapsByProduct, includedAcapsByProduct),
);

const getProductsFromProps = (_state: IStoreState, products: IProduct[]) => products;

/**
 * A function that gets the products that do not include acaps that are included in other products
 *
 * @example
 *  const filteredProducts = getProductsExcludeAxisIncludedAcaps(storeState, products);
 */
export const getProductsExcludeAxisIncludedAcaps = createSelector(
    [getProductsFromProps, getNotIncludedAcapsByProduct, getAllProductsWithIncludedAcaps],
    (products, notIncludedAcapsByProduct, allProductsWithIncludedAcaps) =>
        filterNotIncludedAcaps(products, notIncludedAcapsByProduct, allProductsWithIncludedAcaps),
);
