import { injectable } from 'inversify';
import { isDefined } from 'axis-webtools-util';
import { AppStore } from 'app/store';
import type {
    ICustomItemEntity,
    IPersistence,
    IInstallationPointEntity,
    IFreeTextPointEntity,
    IBaseEntity,
    IFloorPlanEntity,
    IItemRelationEntity,
    IItemEntity,
    IProfileEntity,
    IScheduleEntity,
    ITimeSerieEntity,
    IProjectEntity,
    IProjectQuotationEntity,
    Id,
    IProjectNetworkSettings,
    IPartnerItemEntity,
    RecordingSolutionType,
    IMapLocationEntity,
} from '../userDataPersistence';
import {
    ProjectRepository,
    RepositoryConflictError,
    ProjectDbOriginAsdLocalUserData,
} from '../userDataPersistence';
import type { BandwidthVersion, ItemRelationType, PanoramaModes } from '../models';
import { DistanceUnit } from '../models';
import { getDefaultCameraFilterEntity } from '../utils';

export const PAC_DEFAULT_INSTALLATION_HEIGHT = 1.4;

@injectable()
export class CurrentProjectService {
    constructor(
        private appStore: AppStore,
        private projectRepository: ProjectRepository,
    ) {}

    public getEntity(id: Id, type: 'installationPoint'): IPersistence<IInstallationPointEntity>;
    public getEntity(id: Id, type: 'freeTextPoint'): IPersistence<IFreeTextPointEntity>;
    public getEntity(id: Id, type: 'customItem'): IPersistence<ICustomItemEntity>;
    public getEntity(id: Id, type: 'floorPlan'): IPersistence<IFloorPlanEntity>;
    public getEntity(id: Id, type: 'itemRelation'): IPersistence<IItemRelationEntity>;
    public getEntity(id: Id, type: 'item'): IPersistence<IItemEntity>;
    public getEntity(id: Id, type: 'profile'): IPersistence<IProfileEntity>;
    public getEntity(id: Id, type: 'schedule'): IPersistence<IScheduleEntity>;
    public getEntity(id: Id, type: 'timeSerie'): IPersistence<ITimeSerieEntity>;
    public getEntity(id: Id, type: 'partnerItem'): IPersistence<IPartnerItemEntity>;
    public getEntity(id: Id, type: string): IPersistence<IBaseEntity> {
        let entity: IPersistence<IBaseEntity> | undefined;
        const currentProject = this.getCurrentProjectState();

        switch (type) {
            case 'customItem':
                entity = currentProject.customItems[id];
                break;
            case 'installationPoint':
                entity = currentProject.installationPoints[id];
                break;
            case 'freeTextPoint':
                entity = currentProject.freeTextPoints[id];
                break;
            case 'floorPlan':
                entity = currentProject.floorPlans[id];
                break;
            case 'itemRelation':
                entity = currentProject.itemRelations[id];
                break;
            case 'item':
                entity = currentProject.items[id];
                break;
            case 'profile':
                entity = currentProject.profiles[id];
                break;
            case 'schedule':
                entity = currentProject.schedules[id];
                break;
            case 'timeSerie':
                entity = currentProject.timeSeries[id];
                break;
            case 'partnerItem':
                entity = currentProject.partnerItems[id];
                break;
            default:
                throw new Error(
                    `Type: ${type} is not supported. Add this type to the getItemFromCurrentProject method.`,
                );
        }

        if (!entity) {
            throw new Error(
                `No entity with this id was found in current project state. Id: ${id}.`,
            );
        }
        if (entity.type !== type) {
            throw new Error(
                `Entity is not of type: ${type}. Was of type: ${entity.type} Id: ${id}.`,
            );
        }
        return entity;
    }

    public isCurrentProjectLoaded(): boolean {
        const { project } = this.getCurrentProjectState();
        return !!project;
    }

    public getProjectEntity(): IPersistence<IProjectEntity> {
        const { project } = this.getCurrentProjectState();
        if (!project) {
            throw new Error('No project was loaded in the current project state.');
        }
        return project;
    }

    public getProjectDefaultProfile(): string {
        return this.getProjectEntity().defaultProfile;
    }

    public getProjectBandwidthVersion(): BandwidthVersion {
        return this.getProjectEntity().bandwidthVersion;
    }

    /**
     * Retrieves the project's custom default installation height.
     * The user can alter this value through the project settings menu.
     */
    public getProjectCustomInstallationHeight() {
        return this.getProjectEntity().customInstallationHeight;
    }

    /**
     * Retrieves the chosen unit system for the project.
     * The user can alter between metric and imperial units in the project settings menu.
     */
    public getProjectUnitSystem() {
        return this.getProjectEntity().unitSystem;
    }

    /**
     * Retrieves the chosen distance unit for the project.
     * The user can alter between metric and imperial units in the project settings menu.
     */
    public getProjectDistanceUnit() {
        return this.getProjectEntity().unitSystem === 'metric'
            ? DistanceUnit.Meter
            : DistanceUnit.Feet;
    }

    /**
     * Retrieves the project's default camera filter,
     * taking the user's chosen default installation height into account.
     * @param {PanoramaModes} [panoramaMode] Optional panorama mode for the device
     */
    public getProjectDefaultCameraFilter(
        isDoorStation: boolean = false,
        panoramaMode?: PanoramaModes,
    ) {
        return getDefaultCameraFilterEntity(
            this.getProjectUnitSystem(),
            isDoorStation
                ? PAC_DEFAULT_INSTALLATION_HEIGHT
                : this.getProjectCustomInstallationHeight(),
            panoramaMode,
        );
    }

    public getProjectId(): Id {
        return this.getProjectEntity()._id;
    }

    public getProjectRev(): Id {
        return this.getProjectEntity()._rev;
    }

    public getQuotationEntity(): IPersistence<IProjectQuotationEntity> {
        const { quotations } = this.getCurrentProjectState();
        if (!quotations) {
            throw new Error('No quotation was loaded in the current project state.');
        }
        return quotations;
    }

    public getAllEntitiesOfType(
        type: 'installationPoint',
    ): IPersistence<IInstallationPointEntity>[];
    public getAllEntitiesOfType(type: 'customItem'): IPersistence<ICustomItemEntity>[];
    public getAllEntitiesOfType(type: 'floorPlan'): IPersistence<IFloorPlanEntity>[];
    public getAllEntitiesOfType(type: 'freeTextPoint'): IPersistence<IFreeTextPointEntity>[];
    public getAllEntitiesOfType(type: 'itemRelation'): IPersistence<IItemRelationEntity>[];
    public getAllEntitiesOfType(type: 'item'): IPersistence<IItemEntity>[];
    public getAllEntitiesOfType(type: 'profile'): IPersistence<IProfileEntity>[];
    public getAllEntitiesOfType(type: 'schedule'): IPersistence<IScheduleEntity>[];
    public getAllEntitiesOfType(type: 'timeSerie'): IPersistence<ITimeSerieEntity>[];
    public getAllEntitiesOfType(type: 'partnerItem'): IPersistence<IPartnerItemEntity>[];
    public getAllEntitiesOfType(type: 'mapLocation'): IPersistence<IMapLocationEntity>[];

    public getAllEntitiesOfType(type: string): IPersistence<IBaseEntity>[] {
        let entities: (IPersistence<IBaseEntity> | undefined)[];
        const currentProject = this.getCurrentProjectState();

        switch (type) {
            case 'customItem':
                entities = Object.values(currentProject.customItems);
                break;
            case 'installationPoint':
                entities = Object.values(currentProject.installationPoints);
                break;
            case 'freeTextPoint':
                entities = Object.values(currentProject.freeTextPoints);
                break;
            case 'floorPlan':
                entities = Object.values(currentProject.floorPlans);
                break;
            case 'itemRelation':
                entities = Object.values(currentProject.itemRelations);
                break;
            case 'item':
                entities = Object.values(currentProject.items);
                break;
            case 'profile':
                entities = Object.values(currentProject.profiles);
                break;
            case 'schedule':
                entities = Object.values(currentProject.schedules);
                break;
            case 'timeSerie':
                entities = Object.values(currentProject.timeSeries);
                break;
            case 'partnerItem':
                entities = Object.values(currentProject.partnerItems);
                break;
            case 'mapLocation':
                entities = Object.values(currentProject.mapLocations);
                break;
            default:
                throw new Error(
                    `Type: ${type} is not supported. Add this type to the getAllEntitiesForCurrentProject method.`,
                );
        }

        return entities.filter(isDefined);
    }

    public getCurrentProjectQuotations(): IPersistence<IProjectQuotationEntity> | null {
        const currentProject = this.getCurrentProjectState();
        return currentProject.quotations;
    }

    /** Touches current project to renew updatedTime without any additional changes */
    public async touchCurrentProjectEntity() {
        try {
            return await this.projectRepository.update(this.getProjectEntity());
        } catch (error) {
            if (error instanceof RepositoryConflictError) {
                // This can happen if the project entity has been updated during the
                // debounce timeout. In this case we know that the project entity was
                // changed and got a new updatedDate, so we can safely ignore the error.
            } else {
                throw error;
            }
        }
    }

    public getDeviceChildren(
        itemId: Id,
        relationType: ItemRelationType | ItemRelationType[],
    ): IPersistence<IItemEntity>[] {
        const relations = this.getItemRelations(itemId, relationType);
        const deviceChildren = relations.map((rel) => this.getEntity(rel.childId, 'item'));
        return deviceChildren;
    }

    public getItemRelations(
        itemId: Id,
        relationType?: ItemRelationType | ItemRelationType[],
    ): IPersistence<IItemRelationEntity>[] {
        const relations = this.getAllEntitiesOfType('itemRelation').filter(
            (item) => item.parentId === itemId,
        );
        if (relationType && !Array.isArray(relationType)) {
            return relations.filter((rel) => rel.relationType === relationType);
        } else if (relationType && Array.isArray(relationType)) {
            return relations.filter((rel) => relationType.includes(rel.relationType));
        } else {
            return relations;
        }
    }

    /**
     * Get current project network settings.
     * @returns Network settings for current project or undefined.
     */
    public getCurrentProjectNetworkSettings(): IProjectNetworkSettings | undefined {
        return this.getProjectEntity().networkSettings;
    }

    /**
     * Get current project recording solution.
     * @returns Recording solution type for current project or undefined.
     */
    public getCurrentRecordingSolutionType(): RecordingSolutionType | undefined {
        return this.getProjectEntity().recordingSolutionType;
    }

    /**
     * Get current project always schedule.
     * @returns always schedule.
     */
    public getCurrentProjectAlwaysSchedule(): IPersistence<IScheduleEntity> {
        const currentProjectSchedulesRecord = this.getCurrentProjectState().schedules;
        const currentProjectSchedules = Object.values(currentProjectSchedulesRecord).filter(
            isDefined,
        );
        const systemDefinedSchedules = currentProjectSchedules.filter(
            (schedule) => schedule.systemDefined,
        );

        if (systemDefinedSchedules.length !== 1) {
            throw new Error(`Expected 1 systemDefined schedule but got:
        ${systemDefinedSchedules.length}`);
        }

        const alwaysSchedule = systemDefinedSchedules[0];

        if (!alwaysSchedule) {
            throw new Error('The system defined always schedule is missing');
        }

        return alwaysSchedule;
    }

    public getIsLocalProject(): boolean {
        try {
            return this.getProjectEntity().projectDbOrigin === ProjectDbOriginAsdLocalUserData;
        } catch (error) {
            throw new Error('No project loaded');
        }
    }

    public getIsProjectLocked(): boolean {
        try {
            return this.getProjectEntity().locked === true;
        } catch (error) {
            throw new Error('No project loaded');
        }
    }

    public getIsProjectArchived(): boolean {
        try {
            return this.getProjectEntity().archived === true;
        } catch (error) {
            throw new Error('No project loaded');
        }
    }

    private getCurrentProjectState() {
        return this.appStore.Store.getState().currentProject;
    }
}
