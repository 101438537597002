import { ServiceLocator } from 'app/ioc';
import type {
    IAccessoryItemEntity,
    IEnvironmentItemEntity,
    IItemRelationEntity,
    Id,
} from 'app/core/persistence';
import { CreateEntityService } from 'app/core/persistence';
import type {
    ImportedItemBase,
    ImportedAccessory,
} from '../../../../models/ImportedProjectSettingTypes';
import { createRelationEntity } from './createRelationEntity';

type AccessoryItem = IAccessoryItemEntity | IEnvironmentItemEntity | IItemRelationEntity;

/**
 * Creates an accessory item entity.
 * @param ancestors The ancestors of the accessory.
 * @param importedAccessory The imported accessory.
 * @returns The created accessory.
 */
const createAccessory = (
    ancestors: Id[],
    { id, name, description, notes, piaId }: ImportedAccessory,
): IAccessoryItemEntity => {
    return {
        type: 'item',
        path: [...ancestors, id],
        name: name,
        description: description,
        notes: notes,
        archived: false,
        productId: piaId,
        properties: {
            accessory: {},
        },
        quantity: 1,
    };
};

/**
 * Creates an environment from a name and pia id
 * @param ancestors The ancestors of the accessory.
 * @param name The name of the accessory.
 * @param piaId The pia id of the accessory.
 * @returns The created accessory.
 */
export const createEnvironmentFromNameAndPiaId = (
    ancestors: Id[],
    name: string,
    piaId: number,
): IEnvironmentItemEntity => {
    const createEntityService = ServiceLocator.get(CreateEntityService);
    const itemId = createEntityService.generateDatabaseId('item');

    return {
        type: 'item',
        path: [...ancestors, itemId],
        name: name,
        description: '',
        notes: '',
        archived: false,
        productId: piaId,
        properties: {
            environment: {},
        },
        quantity: 1,
    };
};

/**
 * Recursively gets all accessories and relations for the imported items.
 * @param ancestors The ancestors of the imported items.
 * @param items The imported items.
 * @returns The accessories and relations.
 */
export const getAccessories = (ancestors: Id[], items: ImportedItemBase[]): AccessoryItem[] => {
    const createEntityService = ServiceLocator.get(CreateEntityService);
    return items.flatMap((item) => {
        const itemId = item.id;
        const path = [...ancestors, itemId];

        const accessoryItems = (item.accessories ?? []).flatMap((accessory) => {
            const relationId = createEntityService.generateDatabaseId('itemRelation');
            return [
                createAccessory(path, accessory),
                createRelationEntity(relationId, path, accessory.id, accessory.relationType),
                ...getAccessories(path, [accessory]),
            ];
        });

        const environmentItems = [];
        if (item.environment) {
            const environment = createEnvironmentFromNameAndPiaId(
                path,
                item.environment.name,
                item.environment.piaId,
            );

            const environmentId = environment.path[environment.path.length - 1];
            const relationId = createEntityService.generateDatabaseId('itemRelation');
            const relation = createRelationEntity(relationId, path, environmentId, 'environment');

            environmentItems.push(environment, relation);
        }

        return [...environmentItems, ...accessoryItems];
    });
};

export const getImportedAccessories = (
    projectId: Id,
    items: ImportedItemBase[],
): AccessoryItem[] => {
    return getAccessories([projectId], items);
};
