import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import { getCurrentProjectItemRelationsArray, getCurrentProjectProfiles } from '../../project';

import { isDefined } from 'axis-webtools-util';
import { getIsMilestoneRecordingSolutionType, getProfileIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';
import { countUses, nameComparator } from 'app/utils';
import { getMergedProfile } from '../../item';

const getItems = (state: IStoreState) => state.currentProject.items;

const getProfiles = (state: IStoreState) => state.currentProject.profiles;

export const getSortedProfiles = createSelector([getProfiles], (profiles) =>
    Object.values(profiles).filter(isDefined).sort(nameComparator),
);

export const getProfileIdsSortedByProfileName = createSelector([getSortedProfiles], (profiles) => {
    return profiles.map((profile) => profile._id);
});

export const getProfileUsesCount = (profileId: string) =>
    createSelector([getItems, getCurrentProjectItemRelationsArray], (items, relations) => {
        return countUses(profileId, items, relations);
    });

export const getProfile = createCachedSelector(
    [getCurrentProjectProfiles, getProfileIdFromProps],
    (currentProfiles, profileId) => {
        if (!profileId) {
            return undefined;
        }
        return currentProfiles[profileId];
    },
)(toCacheKey);

export const getProfilesUseProjectSetting = createCachedSelector(
    [getSortedProfiles],
    (currentProfiles) => {
        if (!currentProfiles) {
            return false;
        }
        return currentProfiles.some((profile) => profile.zipstream.useProjectSetting);
    },
)(toCacheKey);

export const getProfileOptions = createSelector([getSortedProfiles], (profiles) =>
    profiles.map(({ _id, name }) => ({
        value: _id,
        text: name,
    })),
);

const getIsMultipleProfilesActiveForItem = createCachedSelector(
    [getMergedProfile],
    (profile) => profile?.continuousRecording.schedule && profile.triggeredRecording.schedule,
)(toCacheKey);

/** If Milestone is selected as recording solution then item profiles with
 * recording on multiple streams are considered invalid (both continuous and motion recordings active)
 * */
export const getIsItemProfileValidForRecordingSolution = createCachedSelector(
    [getIsMilestoneRecordingSolutionType, getIsMultipleProfilesActiveForItem],
    (isMilestone, hasMultipleProfilesActive) => {
        return !isMilestone || !hasMultipleProfilesActive;
    },
)(toCacheKey);

const getIsMultipleProfilesActive = createCachedSelector(
    [getProfile],
    (profile) => profile?.continuousRecording.schedule && profile.triggeredRecording.schedule,
)(toCacheKey);

/** If Milestone is selected as recording solution then profiles with
 * recording on multiple streams are considered invalid (both continuous and motion recordings active)
 * */
export const getIsProjectProfileValidForRecordingSolution = createCachedSelector(
    [getIsMilestoneRecordingSolutionType, getIsMultipleProfilesActive],
    (isMilestone, hasMultipleProfilesActive): boolean => {
        return !isMilestone || !hasMultipleProfilesActive;
    },
)(toCacheKey);
