import * as React from 'react';
import { Box, DateField, Text, Icon, Heading, Clickable } from 'app/components';

import type { IProjectListItem } from 'app/modules/userProjects';
import type { Id } from 'app/core/persistence';

interface ISelectableProjectItemProps {
    project: IProjectListItem;
    selectedId: Id | null;
    onProjectSelected: (projectId: Id) => void;
}

export const SelectableProjectItem: React.FC<ISelectableProjectItemProps> = ({
    project,
    selectedId,
    onProjectSelected,
}) => {
    const onToggleItem = (projectId: Id) => {
        onProjectSelected(selectedId === projectId ? '' : projectId);
    };

    return (
        <Clickable onClick={() => onToggleItem(project.id)}>
            <Box
                width="100%"
                color={selectedId === project.id ? 'yellow1' : 'transparent'}
                minHeight="56px"
            >
                <Box flex="fullWidth" spacing="half" alignItems="center">
                    <Box
                        flex="none"
                        width="40px"
                        justifyContent="end"
                        alignItems="start"
                        paddingY="base"
                    >
                        <Icon icon="description" size="ms" />
                    </Box>
                    <Box flex="shrinkAndGrow">
                        <Heading style="semibold" width="100%">
                            <Text>{project.name}</Text>
                        </Heading>
                    </Box>

                    <Box flex="none" justifyContent="start" width="180px">
                        <Box spacing="base">
                            <Text inline>
                                <DateField date={project.updatedDate} display="date" />
                            </Text>
                            <Text inline color="grey4">
                                <DateField date={project.updatedDate} display="time" />
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Clickable>
    );
};
