import { Module } from 'app/ioc';
import { ApplicationActionService, FeedbackService } from './services';
import { appReducer } from './App.reducer';
import { NotificationBannerService } from './notificationBanner/services';

@Module({
    binder: (binder) => {
        binder.bindService(ApplicationActionService);
        binder.bindService(FeedbackService);
        binder.bindService(NotificationBannerService);
    },
    reducers: {
        app: appReducer,
    },
})
export class ApplicationModule {}
