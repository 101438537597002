import { getIdFromProps, toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import {
    getInstallationPoints,
    getDerotatedInstallationPoints,
} from './getProjectInstallationPoints';
import { getMultiSelectPositionRecord } from './getMultiSelected';
import { getDraftInstallationPoints } from './getDraftInstallationPoints';

export const getParentLocation = createCachedSelector(
    [getInstallationPoints, getDraftInstallationPoints, getIdFromProps],
    (installationPoints, draftInstallationPoints, parentInstallationPointId) => {
        if (!parentInstallationPointId) return undefined;

        const draftInstallationPoint = draftInstallationPoints[parentInstallationPointId];
        const parentInstallationPoint = installationPoints[parentInstallationPointId];

        const installationPoint = draftInstallationPoint ?? parentInstallationPoint;
        return installationPoint?.location;
    },
)(toCacheKey);

export const getParentLocationForId = createCachedSelector(
    [getDerotatedInstallationPoints, getMultiSelectPositionRecord, getIdFromProps],
    (installationPoints, multiSelectedRecord, parentInstallationPointId) => {
        if (!parentInstallationPointId) return undefined;

        return (
            multiSelectedRecord[parentInstallationPointId] ??
            installationPoints[parentInstallationPointId]?.location
        );
    },
)(toCacheKey);
