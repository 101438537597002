import type {
    IPersistence,
    IProjectEntity,
    Id,
    IEntity,
    IItemEntity,
    IProject,
} from '../userDataPersistence';
import {
    BandwidthVersion,
    ProjectRepository,
    QuotationProgress,
    ProjectDbOriginAsdLocalUserData,
} from '../userDataPersistence';
import { injectable } from 'inversify';
import { ItemService } from './item/Item.service';
import type { IAction } from 'app/store';
import { AppStore } from 'app/store';
import { PersistenceActions } from '../PersistenceActions';
import * as moment from 'moment';
import { CurrentProjectService } from './CurrentProject.service';
import { ProjectQuotationService } from './ProjectQuotation.service';
import { ModalService } from 'app/modal';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { IProjectUpdatedPayload } from './ProjectModel.service';
@injectable()
export class ProjectService {
    constructor(
        private projectRepository: ProjectRepository,
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        private projectQuotationService: ProjectQuotationService,
        private appStore: AppStore,
        private modalService: ModalService,
    ) {}

    public async get(projectId: Id): Promise<IPersistence<IProjectEntity>> {
        return this.projectRepository.get(projectId);
    }

    /**
     * Gets the latest change date in a project. Because we save the updated date on every doc it is not
     * enough to look at the project entity's updated date to get latest change in a project.
     *
     * @param projectId the project id to get latest change from
     */
    public async getLatestChanged(projectId: Id): Promise<Date> {
        return (await this.getLatestChangedWithItemEntities(projectId)).latestChanged;
    }

    public async getDescendants(projectId: Id): Promise<IEntity[]> {
        const { descendants } = await this.projectRepository.getProjectAndAllDescendants(projectId);
        return descendants;
    }

    public async getLatestChangedWithItemEntities(projectId: Id) {
        const { project, allDescendants, items, relations } =
            await this.projectRepository.getProjectAndAllDescendantsWithItems(projectId);

        const latestChanged = this.getLatestChangedFromEntities(
            project.updatedDate,
            allDescendants,
        );
        let projectUpdateRev = project._rev;

        if (latestChanged && latestChanged > new Date(project.updatedDate)) {
            // a child has a newer latestChanged date. Update parent accordingly
            const updatedProject = await this.projectRepository.setUpdatedDate(
                projectId,
                latestChanged,
            );
            projectUpdateRev = updatedProject._rev;
        }

        return {
            latestChanged: this.getLatestChangedFromEntities(project.updatedDate, allDescendants),
            itemEntities: items,
            relations,
            projectUpdateRev,
        };
    }

    public async getHasAnyFloorPlanOrMapLocation(projectId: Id) {
        return this.projectRepository.getHasAnyFloorPlanOrMapLocation(projectId);
    }

    public async getChildItems(projectId: Id): Promise<IPersistence<IItemEntity>[]> {
        const entities = await this.itemService.getEntityDescendants<IPersistence<IProjectEntity>>(
            projectId,
            true,
        );

        return entities.descendants;
    }

    public async getIsLocalProject(projectId: Id) {
        return (await this.get(projectId)).projectDbOrigin === ProjectDbOriginAsdLocalUserData;
    }

    public async deleteProject(id: Id, rev: string): Promise<Id> {
        eventTracking.logApplicationEvent('User Projects', 'Deleting project', `projectId: ${id}`);
        return this.projectRepository.delete(id, rev);
    }

    public async updateLockStatus(id: Id, locked: boolean): Promise<void> {
        return this.projectRepository.setLock(id, locked);
    }

    public async updateArchivedStatus(id: Id, archived: boolean): Promise<void> {
        return this.projectRepository.setArchived(id, archived);
    }

    public async isLocked(id: Id): Promise<boolean> {
        const project = await this.get(id);
        return !!project.locked;
    }

    public async isArchived(id: Id): Promise<boolean> {
        const project = await this.get(id);
        return Boolean(project.archived);
    }

    public async showUpgradeBandwidthDialog(): Promise<boolean> {
        const confirm = await this.modalService.createConfirmDialog({
            header: t.bandwidthVersionUpdateTitle,
            body: t.bandwidthVersionUpdateMessage,
            confirmButtonText: t.upgrade,
            cancelButtonText: t.cancel,
        })();

        if (confirm) {
            await this.updateCurrentProject({
                bandwidthVersion: BandwidthVersion.latest,
            });
        }

        return confirm;
    }

    public async add(
        project: Omit<IProject, 'bandwidthVersion'>,
    ): Promise<IPersistence<IProjectEntity>> {
        const savedProject = await this.projectRepository.add({
            type: 'project',
            name: project.name,
            notes: project.notes,
            unitSystem: project.unitSystem,
            powerCalcMethod: project.powerCalcMethod,
            temperatureScale: project.temperatureScale,
            installationPiaLocationId: project.installationPiaLocationId,
            path: [],
            defaultProfile: project.defaultProfile,
            archived: project.archived,
            state: QuotationProgress.Designing,
            origin: project.origin,
            customInstallationHeight: project.customInstallationHeight,
            networkSettings: project.networkSettings,
            bandwidthVersion: BandwidthVersion.latest,
            projectZipSetting: project.projectZipSetting,
            recordingRetentionTimeInDays: project.recordingRetentionTimeInDays,
            projectDbOrigin: project.projectDbOrigin,
            lastExportedDate: project.lastExportedDate,
            powerIlluminatorTimeInHours: project.powerIlluminatorTimeInHours,
            devicesQuantity: 0,
            hasFloorPlanOrMapLocation: false,
        });

        // Add project quotation entity
        await this.projectQuotationService.add(savedProject._id);

        return savedProject;
    }

    public async getAllProjectEntities(): Promise<IPersistence<IProjectEntity>[]> {
        return this.projectRepository.getAll();
    }

    public async updateCurrentProject(
        props: Partial<IProject>,
    ): Promise<IPersistence<IProjectEntity>> {
        return this.update(this.currentProjectService.getProjectId(), props);
    }

    public async clearRecordingSolutionType(): Promise<void> {
        const projectId = this.currentProjectService.getProjectId();
        const projectInDatabase = await this.projectRepository.get(projectId);
        await this.projectRepository.update({
            ...projectInDatabase,
            recordingSolutionType: undefined,
        });
    }

    public async updateHasFloorPlanOrMapLocation(projectId: Id) {
        const hasFloorPlanOrMapLocation = await this.getHasAnyFloorPlanOrMapLocation(projectId);
        return this.update(projectId, { hasFloorPlanOrMapLocation });
    }

    public async update(projectId: Id, props: Partial<IProject>) {
        const projectInDatabase = await this.projectRepository.get(projectId);
        const keepUpdatedDate = this.shouldKeepUpdatedDate(props);

        const updatedProject = await this.projectRepository.update(
            {
                _id: projectInDatabase._id,
                _rev: projectInDatabase._rev,
                archived: props.archived ?? projectInDatabase.archived,
                defaultProfile: props.defaultProfile ?? projectInDatabase.defaultProfile,
                installationPiaLocationId:
                    props.installationPiaLocationId ?? projectInDatabase.installationPiaLocationId,
                name:
                    props.name && props.name.trim() !== ''
                        ? props.name.trim()
                        : projectInDatabase.name,
                notes: props.notes?.trim() ?? projectInDatabase.notes,
                shareToken: 'shareToken' in props ? props.shareToken : projectInDatabase.shareToken,
                state: props.state ?? projectInDatabase.state,
                unitSystem: props.unitSystem ?? projectInDatabase.unitSystem,
                powerCalcMethod: props.powerCalcMethod ?? projectInDatabase.powerCalcMethod,
                temperatureScale: props.temperatureScale ?? projectInDatabase.temperatureScale,
                location: props.location ?? projectInDatabase.location,
                bounds: props.bounds ?? projectInDatabase.bounds,
                locationName: props.locationName ?? projectInDatabase.locationName,
                customInstallationHeight:
                    props.customInstallationHeight ?? projectInDatabase.customInstallationHeight,
                creationDate: projectInDatabase.creationDate,
                entityVersion: projectInDatabase.entityVersion,
                locked: projectInDatabase.locked,
                path: projectInDatabase.path,
                type: projectInDatabase.type,
                updatedDate: projectInDatabase.updatedDate,
                origin: projectInDatabase.origin,
                bandwidthVersion: props.bandwidthVersion ?? projectInDatabase.bandwidthVersion,
                networkSettings:
                    'networkSettings' in props
                        ? props.networkSettings
                        : projectInDatabase.networkSettings,
                devicesQuantity: props.devicesQuantity ?? projectInDatabase.devicesQuantity,
                hasFloorPlanOrMapLocation:
                    props.hasFloorPlanOrMapLocation ?? projectInDatabase.hasFloorPlanOrMapLocation,
                projectZipSetting: props.projectZipSetting ?? projectInDatabase.projectZipSetting,
                recordingRetentionTimeInDays:
                    props.recordingRetentionTimeInDays ??
                    projectInDatabase.recordingRetentionTimeInDays,
                recordingSolutionType:
                    props.recordingSolutionType ?? projectInDatabase.recordingSolutionType,
                projectDbOrigin: projectInDatabase.projectDbOrigin,
                lastExportedDate: props.lastExportedDate ?? projectInDatabase.lastExportedDate,
                installationReportNotes:
                    props.installationReportNotes ?? projectInDatabase.installationReportNotes,
                genetecProjectId: props.genetecProjectId ?? projectInDatabase.genetecProjectId,
                genetecRecommendationType:
                    props.genetecRecommendationType ?? projectInDatabase.genetecRecommendationType,
                selectedRecordingVendor:
                    'selectedRecordingVendor' in props
                        ? props.selectedRecordingVendor
                        : projectInDatabase.selectedRecordingVendor,
                selectedCameraStationType:
                    props.selectedCameraStationType ?? projectInDatabase.selectedCameraStationType,
                selectedCameraStationCenterType:
                    props.selectedCameraStationCenterType ??
                    projectInDatabase.selectedCameraStationCenterType,
                powerIlluminatorTimeInHours:
                    props.powerIlluminatorTimeInHours ??
                    projectInDatabase.powerIlluminatorTimeInHours,
            },
            true,
            false,
            true,
            keepUpdatedDate,
        );

        // We need to be able to set genetecProjectId to undefined
        if (props.genetecProjectId === null) {
            delete updatedProject.genetecProjectId;
        }

        this.onProjectUpdated(updatedProject);

        return updatedProject;
    }

    /** Checks if either archive status or last export date is the only property that has changed. */
    private shouldKeepUpdatedDate = (props: Partial<IProject>) => {
        if (
            (props.archived !== undefined || props.lastExportedDate !== undefined) &&
            Object.keys(props).length === 1
        ) {
            return true;
        }
        return false;
    };

    private getLatestChangedFromEntities(projectUpdateDate: string, descendants: IEntity[]) {
        let changed = moment(projectUpdateDate);

        descendants.forEach((descendant) => {
            const updated = moment(descendant.updatedDate);
            if (changed.isBefore(updated)) {
                changed = updated;
            }
        });
        return changed.toDate();
    }

    /**
     * We still need this for the user project list to update correctly
     * because this is the only place in the application we don't have
     * a project loaded.
     */
    private onProjectUpdated(project: IPersistence<IProjectEntity>) {
        const action: IAction<IProjectUpdatedPayload> = {
            type: PersistenceActions.PROJECT_UPDATED,
            payload: {
                projectData: project,
                currentView: this.appStore.Store.getState().app.currentView,
            },
        };

        this.appStore.Store.dispatch(action);
    }
}
