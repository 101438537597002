import type { IUploadImageOptions } from '../../models';
import * as pdfjsLib from 'pdfjs-dist';
// This is a workaround to fix the issue with the workerSrc not being set correctly
const workerSrc = require('pdfjs-dist/build/pdf.worker.min.mjs').default;

export async function convertPdfToPngBlob(options: IUploadImageOptions) {
    // Setup the PDF document
    pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

    const objectUrl = URL.createObjectURL(options.file);
    // Removed name because of typescript error when adding name { name: 'convertPdfToPngBlob', } to PDFWorker,
    // https://github.com/mozilla/pdfjs-dist/blob/master/types/src/display/api.d.ts#L1319
    const pdfToPngWorker = new pdfjsLib.PDFWorker();

    const pdf = await pdfjsLib.getDocument({
        url: objectUrl,
        worker: pdfToPngWorker,
    }).promise;

    const page = await pdf.getPage(options?.selectedPage || 1);

    // The default DPI for PDFs is 72. So given scale=1 you will end up with 72 DPI.
    const scale = (options?.selectedDPI || 150) / 72;

    const viewport = page.getViewport({ scale });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
        throw Error('No canvas context 2d could be found');
    }

    // Prepare canvas using PDF page dimensions
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    await page.render({ canvasContext: context, viewport: viewport }).promise;

    // Convert the canvas to a PNG blob
    const imageType = 'image/png';
    const blobBin = atob(canvas.toDataURL(imageType).split(',')[1]);
    const array = [];
    for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
    }

    // Clean up
    pdf.destroy();
    pdfToPngWorker.destroy();
    URL.revokeObjectURL(objectUrl);

    return new Blob([new Uint8Array(array)], { type: imageType });
}
