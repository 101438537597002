import { defaultDecoderFilter, Id } from 'app/core/persistence';
import { ImportedDecoder } from '../../../models/ImportedProjectSettingTypes';
import { injectable } from 'inversify';
import { mapToItemEntity, getImportedAccessories } from './utils';

@injectable()
export class DecoderImporterService {
    constructor() {}

    public import(projectId: Id, items: ImportedDecoder[]) {
        const decoders = items.map((item) => ({
            ...mapToItemEntity(projectId, item, item.piaId),
            properties: {
                decoder: { filter: defaultDecoderFilter },
            },
        }));

        const accessoryEntities = getImportedAccessories(projectId, items);
        return [...decoders, ...accessoryEntities];
    }
}
