import { last } from 'lodash-es';
import type {
    Id,
    IPersistence,
    IItemRelationEntity,
    ICommand,
    ICreateOperation,
    IUpdateOperation,
    IDeleteOperation,
} from 'app/core/persistence';

export const UNDO_HISTORY_LENGTH = 30;
const UNDO_MERGE_TIMEOUT = 1000;

const isRelation = (
    entity: ICreateOperation['props'],
): entity is IPersistence<IItemRelationEntity> => 'parentId' in entity;

const getTypePrio = (operation: ICreateOperation) =>
    operation.props.type === 'itemRelation' ? 1 : 0;

// sort descending
const byCreateOrder = (a: ICreateOperation, b: ICreateOperation) =>
    getTypePrio(b) - getTypePrio(a) || b.props.path.length - a.props.path.length;

/*
 * Update ids of create operations
 */
export const updateCreateOperations = (
    operations: ICreateOperation[],
    idMap: Map<Id, Id>,
): ICreateOperation[] =>
    operations.map((item) => {
        const newItem = {
            ...item,
            props: {
                ...item.props,
                path: item.props.path.map(
                    (pathComponent) => idMap.get(pathComponent) ?? pathComponent,
                ),
            },
        };
        if (isRelation(newItem.props)) {
            newItem.props.parentId = idMap.get(newItem.props.parentId) ?? newItem.props.parentId;
            newItem.props.childId = idMap.get(newItem.props.childId) ?? newItem.props.childId;
        }

        return newItem;
    });

/*
 * Update ids of update operations
 */
export const updateUpdateOperations = (
    operations: IUpdateOperation[],
    idMap: Map<Id, Id>,
): IUpdateOperation[] =>
    operations.map((item) => {
        const updatedItem = {
            ...item,
            id: idMap.get(item.id) ?? item.id,
        };

        return updatedItem;
    });

/*
 * Update ids of delete operations
 */
export const updateDeleteOperations = (
    operations: IDeleteOperation[],
    idMap: Map<Id, Id>,
): IDeleteOperation[] =>
    operations.map((item) => {
        const remappedItem = {
            ...item,
            id: idMap.get(item.id) ?? item.id,
        };

        return remappedItem;
    });

/*
 * Update ids of a command
 */
export const updateCommandIds = (command: ICommand, idMap: Map<Id, Id>): ICommand => ({
    updates: updateUpdateOperations(command.updates ?? [], idMap),
    deletes: updateDeleteOperations(command.deletes ?? [], idMap),
    creates: updateCreateOperations(command.creates ?? [], idMap),
});

/*
 * Update ids of a queue
 */
export const updateCommandQueueIds = (queue: ICommand[], idMap: Map<Id, Id>) =>
    queue.map((command) => updateCommandIds(command, idMap));

/*
 * Add a command to a queue merging temporally close commands
 */
export const addCommand = (queue: ICommand[], command: ICommand) => {
    const newQueue = [...queue];
    const lastOp = last(newQueue);
    const now = Date.now();
    if (lastOp && (lastOp?.timeStamp ?? 0) + UNDO_MERGE_TIMEOUT > now) {
        //merge with last operation
        newQueue.pop();
        newQueue.push({
            updates: [...(lastOp.updates ?? []), ...(command.updates ?? [])],
            creates: [...(lastOp.creates ?? []), ...(command.creates ?? [])].sort(byCreateOrder),
            deletes: [...(lastOp.deletes ?? []), ...(command.deletes ?? [])],
            timeStamp: now,
        });
    } else {
        //append new operation
        newQueue.push({
            ...command,
            timeStamp: now,
        });
    }
    return newQueue.slice(-UNDO_HISTORY_LENGTH);
};
// Tests that value is /partner/<number>/ or /partner/<number> where number consists of one or many digits
const partnerConfigRegExp = new RegExp(/\/partner\/\d+\/?$/);

export const canUndoNavigation = () => window.location.pathname.includes('device-selector');
export const isUndoRedoDisabled = () =>
    ['/', '/localprojects/', '/userprojects/', '/sharedprojects/'].includes(
        window.location.pathname,
    ) || partnerConfigRegExp.test(window.location.pathname);
