import type { IStoreState } from 'app/store';

export const getSharedProjectTreeInfo = (state: IStoreState) =>
    state.sharedProjects.sharedProjectTreeInfo;

export const getOrganization = (state: IStoreState) =>
    state.sharedProjects.sharedProjectTreeInfo?.organization;

export const getResourceGroup = (state: IStoreState) =>
    state.sharedProjects.sharedProjectTreeInfo?.resourceGroup;
